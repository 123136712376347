/* eslint-disable max-len */
export const AccountRelatedPersonsTr = {
  relatedPersons: 'İlgili Kişiler',
  manageYourRelatedPersonsList: 'İlgili kişiler listenizi yönetin',
  addNewPerson: 'Yeni Kişi Ekle',
  personList: 'Kişi Listesi',
  subscription: 'Abonelik',
  licence: 'Lisans',
  storageDelivery: 'Depo Teslim',
  eArchive: 'e-Arşiv',
  finance: 'Finans',
  technicalService: 'Teknik Servis',
  searchInTable: 'Tabloda ara',
  person: 'Kişi',
  name: 'Adı',
  surname: 'Soyadı',
  phoneNumber: 'Telefon Numarası',
  email: 'E-posta',
  personCategory: 'Kişi Kategorisi',
  phone: 'Telefon',
  optional: 'Opsiyonel',
  gender: 'Cinsiyet',
  selectGender: 'Cinsiyet seçimi yapınız',
  savePerson: 'Kullanıcı Kaydet',
  infoText:
    'Burada seçilen ismin depoya ürün teslim almaya gelirken <strong>kimlik kartını</strong> ibraz etmesi gerekmektedir. Aksi durumda veya farklı bir kişiye ürün teslimatı <strong>yapılmayacaktır.</strong>',
  man: 'Erkek',
  woman: 'Kadın',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  theEmailFormatIsInvalid: 'E-posta biçimi geçersiz.',
  edit: 'Güncelle',
  delete: 'Sil',
  cancel: 'İptal',
  sureToDeletePerson: '<strong>{{name}} {{lastName}}</strong> Kişisini Silmek istediğinizden emin misiniz ?',
  authorizedUpsertForm: 'Abonelik/Lisans için Yetkili Ekleme/Düzenleme Formu',
  companyTitle: 'Firma Ünvan',
  company: 'Firma',
  address: 'Adres',
  addressPlaceholder: 'Adres girin',
  emailPlaceholder: 'E-posta adresinizi girin',
  city: 'Şehir',
  cityPlaceholder: 'Şehir seçin',
  postCode: 'Posta Kodu',
  postCodePlaceholder: 'Posta kodu girin',
  authorizedInCompany: 'Firmamdaki Yetkili',
  selectAuthorized: 'Yetkili seçin',
  whoShouldSeeInPenta: 'Pentada Kim Görsün',
  selectPersonPlaceholder: 'Kişi seçin',
  district: 'İlçe',
  districtPlaceholder: 'İlçe girin',
  activeSubscriptionLicense: 'Aktif abonelik/lisans',
  subauthorizedUpsertForm: 'Abonelik/Lisans Alt Yetkili Ekleme/Düzenleme Formu',
  type: 'Türü',
  typePlaceholder: 'Tür seçin',
  status: 'Durumu',
  statusPlaceholder: 'Durumu seçin',
  subscriptionLicenceList: 'Abonelik/Lisans Listesi',
  nameSurnameTitle: 'Ad Soyad/Ünvan',
  everybody: 'Herkes',
  onlyMyCustomerRepresentative: 'Sadece Müşteri Temsilcim',
  contractManager: 'Kontrat Yöneticisi',
  softwareCoordinator: 'Software Koordinator',
  add: 'Ekle',
  update: 'Düzenle',
  makePassive: 'Pasif Yap',
  makeActive: 'Aktif Yap',
  eArchiveCheckbox: 'E-Arşiv faturalarımın aşağıdaki tanımlı e-posta adreslerime gönderilmesini istiyorum. Kağıt fatura istemiyorum.',
};
