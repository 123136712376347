import { useTheme } from '@emotion/react';
import { Box, Grid, Typography, styled, useMediaQuery } from '@mui/material';
import { APIProvider, Map, Marker, useMarkerRef } from '@vis.gl/react-google-maps';
import PDivider from 'components/Divider/PDivider';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import PTextFieldWithMenu from 'components/textField/PTextFieldWithMenu';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addZeroToNumber } from 'utils/HelperFunctions';
import Validator from 'validatorjs';
import {
  getAddressFromLatLon,
  getCities,
  getDetailsOfAddress,
  getDistricts,
  getLatlonFromCity,
  upsertDeliveryAddress,
} from '../api/DeliveryAddressApi';
import { upsertDeliveryAddressValidationMessagesKeys } from './UpsertDeliveryAddressValidationMessagesKeys';
const DeliveryAddressContainer = styled(Box)(() => ({
  padding: '0 20px',
  display: 'flex',
  flexDirection: 'column',
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  paddingLeft: '15px',
  gap: '15px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  padding: '24px 0',
}));

const UpsertDeliveryAddress = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [markerRef] = useMarkerRef();
  const [isLoading, setIsLoading] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [addressItems, setAddressItems] = useState([]);
  const [mapPoint, setMapPoint] = useState({ lat: 38.9637, lon: 35.2433 });
  const [markerLocation, setMarkerLocation] = useState({ markerLat: 38.9637, markerLon: 35.2433 });
  const [zoom, setZoom] = useState(5);
  const [formData, setFormData] = useState({
    city: '',
    district: '',
    delivery: {
      deliveryType: '',
      deliveryValue: '',
    },
    doorNumber: '',
    postCode: '',
    email: '',
    fax: '',
    name: '',
    phone: '',
    address: '',
    mobile: '',
    coordinate: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    city: '',
    district: '',
    email: '',
    phone: '',
    mobile: '',
    address: '',
    postCode: '',
  });

  const getUpdatedFormFields = () => {
    if (location.state) {
      setIsLoading(true);
      getDistricts(Number(location.state.city))
        .then((res) => {
          setDistrictData(res.data.data.map((item) => ({ key: item.id, value: item.districtName, ...item })));
          const foundDistrict = res.data.data.find((item) => item.districtName === location.state.district);
          if (location.state.coordinate) {
            setMarkerLocation({
              markerLat: Number(location.state.coordinate.split(',')[0]),
              markerLon: Number(location.state.coordinate.split(',')[1]),
            });
            setMapPoint({ lat: Number(location.state.coordinate.split(',')[0]), lon: Number(location.state.coordinate.split(',')[1]) });
          }

          setFormData((prevState) => ({
            ...prevState,
            city: Number(location.state.city),
            address: location.state.address1,
            name: location.state.name,
            doorNumber: location.state.houseNumber,
            district: foundDistrict?.id,
            delivery: {
              deliveryType: foundDistrict?.deliveryType,
              deliveryValue: foundDistrict?.deliveryValue,
            },
            coordinate: location.state.coordinate,
            fax: location.state.fax,
            email: location.state.receiverMail,
            postCode: location.state.postCode,
            mobile: `+${location.state.mobile}`,
            phone: `+${location.state.phone}`,
          }));
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getCities()
      .then((res) => {
        setCityData(res.data.data.map((city) => ({ key: city.cityCode, value: city.cityName })));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
        getUpdatedFormFields();
      });
  }, []);

  const onSelectChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'city') {
      setZoom(15);
      const cityName = cityData.find((item) => item.key === value);
      getDistricts(value)
        .then((res) => {
          setDistrictData(res.data.data.map((item) => ({ key: item.id, value: item.districtName, ...item })));
          setFormData((prevState) => ({ ...prevState, [name]: value, district: '' }));
          getLatlonFromCity(cityName.value).then((res) => {
            const { lat, lng } = res.data.results[0].geometry.location;
            setMapPoint({ lat: lat, lon: lng });
            setMarkerLocation({ markerLat: lat, markerLon: lng });
            setFormData((prevState) => ({ ...prevState, coordinate: `${lat},${lng}` }));
          });
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    } else if (name === 'district') {
      const temp = districtData.find((item) => item.key === value);
      setFormData((prevState) => ({
        ...prevState,
        delivery: { deliveryType: temp.deliveryType, deliveryValue: temp.deliveryValue },
        postCode: temp.zipCode || '',
        [name]: value,
      }));
    }
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const formDataFromAddress = (infoAddress) => {
    getDistricts(infoAddress[9])
      .then((res) => {
        setIsLoading(true);
        setDistrictData(res.data.data.map((item) => ({ key: item.id, value: item.districtName, ...item })));
        const foundDistrict = res.data.data.find((item) => item.districtName === infoAddress[5]);
        setFormData((prevState) => ({
          ...prevState,
          city: infoAddress[9],
          district: foundDistrict?.id,
          doorNumber: infoAddress[17],
          address: infoAddress[0],
          delivery: {
            deliveryType: foundDistrict?.deliveryType,
            deliveryValue: foundDistrict?.deliveryValue,
          },
          postCode: foundDistrict?.zipCode,
          coordinate: `${infoAddress[8]},${infoAddress[7]}`,
        }));
        setMapPoint((prevState) => ({ ...prevState, lat: infoAddress[8], lon: infoAddress[7] }));
        setMarkerLocation((prevState) => ({ ...prevState, markerLat: infoAddress[8], markerLon: infoAddress[7] }));
        setZoom(15);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeAddress = (event) => {
    const { name, value } = event.target;
    if (value.length >= 5) {
      getDetailsOfAddress(value).then((res) => {
        const addressMenuItem = [];
        const { data } = res;
        data.forEach((address, index) => {
          addressMenuItem.push({
            id: index,
            content: (
              <div>
                <div>Adres:{address[0]}</div>
                <div>Mahalle:{address[4]}</div>
                <div>İl:{address[6]}</div>
                <div>İlçe:{address[5]}</div>
              </div>
            ),
            onClick: () => formDataFromAddress(address),
          });
        });
        setAddressItems(addressMenuItem);
      });
    }
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onInputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const validateData = () => {
    const rules = {
      address: 'required',
      name: 'required',
      city: 'required',
      district: 'required',
      email: 'required|email',
      phone: 'required',
      mobile: 'required',
      postCode: ['regex:/^\\d{5}$/'],
    };
    let mobile = formData.mobile.substring(1);
    mobile = mobile.replace(/\s/g, '');
    let phone = formData.phone?.substring(1);
    phone = phone?.replace(/\s/g, '');

    const validationData = {
      name: formData.name,
      city: formData.city,
      district: formData.district,
      email: formData.email,
      phone,
      mobile,
      address: formData.address,
      postCode: formData.postCode,
    };
    const validation = new Validator(validationData, rules, upsertDeliveryAddressValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        address: validation.errors.first('address'),
        name: validation.errors.first('name'),
        city: validation.errors.first('city'),
        district: validation.errors.first('district'),
        email: validation.errors.first('email'),
        phone: validation.errors.first('phone'),
        mobile: validation.errors.first('mobile'),
        postCode: validation.errors.first('postCode'),
      }));
    }
  };

  const upsertAddress = () => {
    if (validateData()) {
      let mobile = formData.mobile?.substring(1);
      mobile = mobile?.replace(/\s/g, '');
      let phone = formData.phone?.substring(1);
      phone = phone?.replace(/\s/g, '');
      const localDistrict = districtData.find((item) => item.key === formData.district);
      const data = {
        city: addZeroToNumber(formData.city),
        district: localDistrict.value,
        address: formData.address,
        doorNumber: formData.doorNumber,
        postCode: formData.postCode?.toString() || '',
        phone: phone,
        mobile: mobile,
        fax: formData.fax,
        delivery: formData.delivery.deliveryValue,
        email: formData.email,
        isUpdate: location.state ? true : false,
        name: formData.name,
        requestNo: 0,
        coordinate: formData.coordinate,
      };
      if (location.state) {
        data.addressNumber = location.state.addressNumber;
        data.receiver = location.state.receiver;
      }
      upsertDeliveryAddress(data)
        .then(() => {
          toast.success(t('deliveryAddress:savedSuccessfully'));
          navigate(`${paths.customer}${paths.myDeliveryAdresses}`);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  };

  const onMarkerDrag = (event) => {
    setMarkerLocation((prevState) => ({
      ...prevState,
      markerLat: Number(event.latLng.lat()),
      markerLon: Number(event.latLng.lng()),
    }));
  };

  const onMarkerDragEnd = (event) => {
    getAddressFromLatLon(event.latLng.lat(), event.latLng.lng()).then((res) => {
      const findPostalCodeType = res.data.results[0].address_components.find((result) => result.types.includes('postal_code'));
      setFormData((prevState) => ({
        ...prevState,
        address: res.data.results[0].formatted_address,
        coordinate: `${event.latLng.lat()},${event.latLng.lng()}`,
        postCode: findPostalCodeType?.short_name,
      }));
    });
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <SecondaryLayout
        path={`${paths.customer}${paths.myDeliveryAdresses}`}
        title={t('deliveryAddress:addDeliveryAddress')}>
        <Grid
          container
          spacing={2}
          sx={{ paddingLeft: 3, paddingRight: 2, paddingBottom: 2, margin: 0 }}>
          <Grid
            item
            xs={12}>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('deliveryAddress:basicKnowledge')}
            </Typography>
          </Grid>
        </Grid>
        <PDivider />
        <DeliveryAddressContainer>
          <Grid
            container
            spacing={2}
            sx={{ paddingRight: 2, paddingBottom: 2, margin: 0 }}>
            <Grid
              item
              xs={6}>
              <PTextFieldWithMenu
                label={t('deliveryAddress:address')}
                name="address"
                value={formData.address}
                onChange={onChangeAddress}
                menuItems={addressItems}
                error={!!errors.address}
                errorMessage={t(errors.address, { field: t('deliveryAddress:address') })}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <PTextField
                label={t('deliveryAddress:companyName')}
                name="name"
                onChange={onInputChangeHandler}
                value={formData.name}
                error={!!errors.name}
                errorMessage={t(errors.name, { field: t('deliveryAddress:companyName') })}
              />
            </Grid>
            <Grid
              item
              xs={3}>
              <PSelect
                menuItems={cityData}
                label={t('deliveryAddress:city')}
                name="city"
                value={formData.city}
                onChange={onSelectChangeHandler}
                error={!!errors.city}
                errorMessage={t(errors.city, { field: t('deliveryAddress:city') })}
              />
            </Grid>
            <Grid
              item
              xs={3}>
              <PSelect
                menuItems={districtData}
                name="district"
                label={t('deliveryAddress:district')}
                value={formData.district}
                onChange={onSelectChangeHandler}
                error={!!errors.district}
                errorMessage={t(errors.district, { field: t('deliveryAddress:district') })}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              {!location.state && (
                <TelInput
                  label={t('deliveryAddress:mobile')}
                  value={formData.mobile}
                  onChange={(value) => {
                    setFormData((prevState) => ({ ...prevState, mobile: value }));
                    setErrors((prevState) => ({ ...prevState, mobile: '' }));
                  }}
                  error={!!errors.mobile}
                  errorMessage={t(errors.mobile, { field: t('deliveryAddress:mobile') })}
                />
              )}
              {location.state && (
                <PTextField
                  onChange={onInputChangeHandler}
                  label={t('deliveryAddress:mobile')}
                  name="mobile"
                  value={formData.mobile}
                  error={!!errors.mobile}
                  errorMessage={t(errors.mobile, { field: t('deliveryAddress:mobile') })}
                />
              )}
            </Grid>
            <Grid
              item
              xs={3}>
              <PTextField
                label={`${t('deliveryAddress:doorNo')} (${t('deliveryAddress:optional')})`}
                value={formData.doorNumber}
                name="doorNumber"
                onChange={onInputChangeHandler}
              />
            </Grid>
            <Grid
              item
              xs={3}>
              <PTextField
                label={`${t('deliveryAddress:postCode')} (${t('deliveryAddress:optional')})`}
                value={formData.postCode}
                name="postCode"
                onChange={onInputChangeHandler}
                error={!!errors.postCode}
                errorMessage={t('deliveryAddress:regexFailed')}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              {!location.state && (
                <TelInput
                  label={t('deliveryAddress:phone')}
                  value={formData.phone}
                  onChange={(value) => {
                    setFormData((prevState) => ({ ...prevState, phone: value }));
                    setErrors((prevState) => ({ ...prevState, phone: '' }));
                  }}
                  error={!!errors.phone}
                  errorMessage={t(errors.phone, { field: t('deliveryAddress:phone') })}
                />
              )}
              {location.state && (
                <PTextField
                  label={t('deliveryAddress:phone')}
                  value={formData.phone}
                  name="phone"
                  onChange={onInputChangeHandler}
                  error={!!errors.phone}
                  errorMessage={t(errors.phone, { field: t('deliveryAddress:phone') })}
                />
              )}
            </Grid>
          </Grid>
          <GridContainer>
            <Box style={{ gridRowStart: 1, gridRowEnd: 'span 3' }}>
              <APIProvider apiKey={process.env.REACT_APP_MAPS_KEY}>
                <Map
                  style={{ width: '100%', minHeight: '267px' }}
                  defaultCenter={{ lat: mapPoint.lat, lng: mapPoint.lon }}
                  center={{ lat: mapPoint.lat, lng: mapPoint.lon }}
                  defaultZoom={5}
                  zoom={zoom}
                  onZoomChanged={(event) => {
                    setZoom(event.detail.zoom);
                  }}
                  onBoundsChanged={(event) => {
                    const { center } = event.detail;
                    setMapPoint((prevState) => ({ ...prevState, lat: center.lat, lon: center.lng }));
                    setZoom(event.detail.zoom);
                  }}
                  disableDefaultUI={true}>
                  <Marker
                    ref={markerRef}
                    draggable
                    onDrag={onMarkerDrag}
                    position={{ lat: markerLocation.markerLat, lng: markerLocation.markerLon }}
                    onDragEnd={onMarkerDragEnd}
                  />
                </Map>
              </APIProvider>
            </Box>
            <Box>
              <PTextField
                label={`${t('deliveryAddress:fax')} (${t('deliveryAddress:optional')})`}
                value={formData.fax}
                name="fax"
                onChange={onInputChangeHandler}
              />
            </Box>
            <Box>
              <PTextField
                label={t('deliveryAddress:email')}
                value={formData.email}
                name="email"
                onChange={onInputChangeHandler}
                error={!!errors.email}
                errorMessage={t(errors.email, { field: t('deliveryAddress:email') })}
              />
            </Box>
            <Box>
              <PTextField
                disabled
                label={t('deliveryAddress:shipment')}
                value={formData.delivery.deliveryType}
              />
            </Box>
            <InfoAlert label={t('deliveryAddress:mapInfo')} />
          </GridContainer>
          <Footer>
            <PButton
              width={matchesSm ? '100%' : 'auto'}
              onClick={upsertAddress}>
              {t('deliveryAddress:saveAddress')}
            </PButton>
          </Footer>
        </DeliveryAddressContainer>
      </SecondaryLayout>
    </>
  );
};
export default UpsertDeliveryAddress;
