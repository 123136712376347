import MoneyIcon from '@mui/icons-material/Money';
import { TableRow } from '@mui/material';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import { PTableRowCell } from 'components/simpleTable/SimpleTable';
import UnitInput from 'components/unitInput/UnitInput';
import { splitWithDot } from 'utils/HelperFunctions';

const StorageRows = ({ rows, setModalData, setSelectedRowHeader, inputChange, addToCart }) => {
  return (
    <>
      {rows.map((row) => (
        <TableRow
          key={row.id}
          sx={{ background: '#ffffff' }}>
          <PTableRowCell
            component="th"
            scope="row">
            {Number(row.productNumber)}
          </PTableRowCell>
          <PTableRowCell>{Number(row.orderId)}</PTableRowCell>
          <PTableRowCell align="center">{row.entryNumber}</PTableRowCell>
          <PTableRowCell>{row.shortDesc}</PTableRowCell>
          <PTableRowCell align="right">
            <UnitInput
              value={row.netPrice}
              unit={row.currency}
            />
          </PTableRowCell>
          <PTableRowCell align="right">{row.age}</PTableRowCell>
          <PTableRowCell align="right">{splitWithDot(row.count, 0)}</PTableRowCell>
          <PTableRowCell align="center">
            {row.manufacturerSerialNumber.length > 0 ? (
              <MoneyIcon
                className="cursor-pointer"
                onClick={() => {
                  setModalData(row.manufacturerSerialNumber);
                  setSelectedRowHeader(Number(row.productNumber));
                }}
              />
            ) : (
              <></>
            )}
          </PTableRowCell>
          <PTableRowCell>
            <InlineEditNumberInput
              disabled={row.count <= 0}
              value={row.amount}
              showIcon
              onChange={(value) => inputChange(value, row)}
              onIconClick={() => addToCart(row)}
            />
          </PTableRowCell>
        </TableRow>
      ))}
    </>
  );
};

export default StorageRows;
