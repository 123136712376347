import { PTableHeaderCell } from 'components/simpleTable/SimpleTable';

const StorageColumns = ({ columns }) => {
  return (
    <>
      {columns.map((item) => {
        return (
          <PTableHeaderCell
            align={item.align || 'left'}
            key={item.headerName}
            sx={{ width: item.width || 'auto', minWidth: item.minWidth || 'auto' }}>
            {item.headerName}
          </PTableHeaderCell>
        );
      })}
    </>
  );
};

export default StorageColumns;
