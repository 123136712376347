import { Box, Tab, Typography, styled } from '@mui/material';
import TabPanel from 'components/tabs/PTabPanel';
import PTabs from 'components/tabs/PTabs';
import { primaryColors } from 'config/theme/Colors';
import MainContainer from 'layout/container/MainContainer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { personTabNumbers } from './Constants';
import PersonList from './PersonList';
import SubscriptionList from './subscriptionList/SubscriptionList';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '15px',
  },
}));
const AccountRelatedPersonsListContainer = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(personTabNumbers.personList);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <MainContainer>
      <Box className="d-flex flex-column gap-16">
        <HeaderContainer>
          <Box>
            <Box>
              <Typography
                variant="titleLarge"
                color={primaryColors.primary}>
                {t('accountRelatedPersons:relatedPersons')}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {t('accountRelatedPersons:manageYourRelatedPersonsList')}
              </Typography>
            </Box>
          </Box>
        </HeaderContainer>
        <PTabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto">
          <Tab label={t('accountRelatedPersons:personList')} />
          <Tab label={`${t('accountRelatedPersons:subscription')}/${t('accountRelatedPersons:licence')}`} />
        </PTabs>
        <TabPanel
          value={tabValue}
          index={personTabNumbers.personList}>
          <PersonList />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={personTabNumbers.subscriptionList}>
          <SubscriptionList />
        </TabPanel>
      </Box>
    </MainContainer>
  );
};

export default AccountRelatedPersonsListContainer;
