import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useState } from 'react';
import PTextField from './PTextField';
const PPasswordTextField = ({ label, value, onChange, name, sx, autoComplete = 'on', id, placeholder, error = false, errorMessage = '' }) => {
  const [type, setType] = useState('password');

  const changeVisiblity = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <PTextField
      id={id}
      autoComplete={autoComplete}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      sx={sx}
      placeholder={placeholder}
      endIcon=<VisibilityOutlinedIcon
        className="cursor-pointer"
        onClick={changeVisiblity}
      />
      error={error}
      errorMessage={errorMessage}
    />
  );
};
export default PPasswordTextField;
