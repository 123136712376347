import { Box, styled } from '@mui/material';

export const MarginContainer = styled(Box)(({ theme }) => ({
  margin: '0 32px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    margin: '0 16px',
  },
}));

export const SpecialPageContainer = styled(Box)(({ style }) => ({
  padding: '16px',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  marginBottom: '16px',
  ...style,
}));
