import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { primaryColors, slateColors } from 'config/theme/Colors';
import { Link } from 'react-router-dom';

const CustomLink = styled(Link)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  color: slateColors.slate950,
  textDecoration: 'none',
  lineHeight: '20px',
  letterSpacing: '0.10000000149011612px',
}));
const NavigationLink = ({ to, label, onClick }) => {
  return (
    <CustomLink
      onClick={onClick}
      to={to}>
      <Typography
        variant="titleMedium"
        color={primaryColors.primary}>
        {label}
      </Typography>
    </CustomLink>
  );
};

export default NavigationLink;
