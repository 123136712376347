import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { paths } from 'constants/Paths';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changeActivePassive } from '../api/DeliveryAddressApi';
const DeliveryListTableMenu = ({ row, loaderFunction }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    navigate(`${paths.add}${paths.customer}${paths.myDeliveryAdresses}`, { state: row });
    handleClose();
  };

  const makeActivePassive = () => {
    let isActive = null;

    if (!row.loevm) {
      isActive = false;
    } else {
      isActive = true;
    }
    changeActivePassive(isActive, row.receiver)
      .then(() => {
        loaderFunction();
        toast.success(t('deliveryAddress:successfullyUpdated'));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <>
      <PIconButton
        inputtype="text"
        onClick={handleClick}>
        <IconResolver iconName="MoreVert" />
      </PIconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <Typography variant="labelLarge">{t('deliveryAddress:edit')}</Typography>
        </MenuItem>
        <MenuItem onClick={makeActivePassive}>
          <Typography variant="labelLarge">{!row.loevm ? t('deliveryAddress:makePassive') : t('deliveryAddress:makeActive')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DeliveryListTableMenu;
