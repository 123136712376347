import { Box, styled, Typography } from '@mui/material';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import PIconButton from 'components/button/PIconButton';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PTooltip from 'components/tooltip/PTooltip';
import { blueColors, primaryColors, redColors } from 'config/theme/Colors';
import calendar from 'media/assets/calendar.png';
import { deleteItemFromCart } from 'pages/pay/api/PayApi';
import { addToCartEnums } from 'pages/pay/constants/AddToCartEnums';
import LicenceModal from 'pages/pay/tabs/subscriptionCart/components/LicenceModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatPrice } from 'utils/HelperFunctions';
import LIcon from '../media/LIcon.png';
import LIconRedDot from '../media/LIconRedDot.png';

const ProductCard = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  overflowY: 'auto',
}));

const PrimaryPart = styled(Box)(() => ({
  padding: '12px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'auto',
}));

const ImageWithBorder = styled('img')(() => ({
  width: '40px',
  height: '40px',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '4px',
}));

const ProductDetailsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const LinkText = styled(Box)(() => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  color: blueColors.blue500,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const SecondaryPart = styled(Box)(() => ({
  padding: '12px',
  width: '100%',
  display: 'flex',
  overflow: 'auto',
}));

const SecondaryPartContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 52px',
  gap: '24px',
  width: '100%',
}));

const SecondaryPartTextContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  minHeight: '44px',
}));

const ProductItem = ({ product, getActiveCartItemsFn, updateProductQuantity, activeBasket }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handleQuantityChange = (newQuantity) => {
    updateProductQuantity(product.cartItemId, newQuantity);
  };

  const getTransactionLabel = (value) => {
    let label = '';
    if (value === addToCartEnums.addProduct) {
      label = 'Add Product';
    } else if (value === addToCartEnums.addSeat) {
      label = 'Add Seat';
    } else if (value === addToCartEnums.renewable) {
      label = 'Renewable';
    }
    return label;
  };

  const goToDetailPage = (givenNumber) => {
    const number = Number(givenNumber);
    loginForBayinet()
      .then((res) => {
        window.open(`${process.env.REACT_APP_BAYINET_URL}/Product/ProductDetail?ProductId=${number}`, '_blank');
      })
      .catch((err) => {});
  };

  const deleteCartItem = (cartItemId) => {
    setLoading(true);
    deleteItemFromCart(cartItemId)
      .then(() => {
        getActiveCartItemsFn();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModalFn = (fields, title, cartItemId) => {
    setOpenModal({ fields, title, cartItemId });
  };

  const toogleModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  return (
    <ProductCard>
      {loading && <LoadingSpinner />}
      {openModal && (
        <LicenceModal
          getActiveCartItemsFn={getActiveCartItemsFn}
          licenceInfo={openModal}
          onClose={toogleModal}
        />
      )}
      <PrimaryPart>
        <Box
          className="d-flex gap-12"
          sx={{ width: '62%' }}>
          <ImageWithBorder
            src={calendar}
            alt="Product Image"
          />
          <ProductDetailsContainer>
            <Typography variant="titleMedium">{product.productDefinition}</Typography>
            <Box className="d-flex align-items-center gap-12">
              <LinkText onClick={() => goToDetailPage(product.productNumber)}>{`#${product.productNumber}`}</LinkText>
              {product.licence === 'X' && (
                <Box>
                  {product.licences.some((licence) => licence.isFieldRequired === 'X') && (
                    <PTooltip
                      placement="bottom-start"
                      title={
                        <Box>
                          {product.licences.map((licence) => {
                            if (licence.isFieldRequired === 'X') {
                              return <Box key={licence.fieldName}>{t('pay:dataInformationIsRequired', { field: licence.fieldName })}</Box>;
                            } else {
                              return <></>;
                            }
                          })}
                        </Box>
                      }>
                      <img
                        onClick={() => openModalFn(product.licences, product.productNumber, product.cartItemId)}
                        className="cursor-pointer"
                        width={20}
                        height={20}
                        src={LIconRedDot}
                        alt="icon"
                      />
                    </PTooltip>
                  )}
                  {product.licences.every((licence) => !licence.isFieldRequired) && (
                    <PTooltip
                      placement="bottom-start"
                      title={t('pay:clickToDefineLicenseFormInformation')}>
                      <img
                        onClick={() => openModalFn(product.licences, product.productNumber, product.cartItemId)}
                        className="cursor-pointer"
                        width={20}
                        height={20}
                        src={LIcon}
                        alt="icon"
                      />
                    </PTooltip>
                  )}
                </Box>
              )}
            </Box>
          </ProductDetailsContainer>
        </Box>
        <Box className="d-flex gap-16">
          <InlineEditNumberInput
            disabled={activeBasket?.isProject}
            width="74px"
            value={product.quantity}
            onChange={handleQuantityChange}
          />
          <Box className="d-flex align-items-center">
            <Box className="d-flex flex-column gap-4">
              <Box className="d-flex flex-column gap-4 align-items-end">
                <Typography variant="bodySmall">{t('pay:grandTotal')}</Typography>
                <Typography variant="titleMedium">{`${formatPrice(product.netAmount)} ${product.salesAndDistributionDocumentCurrency}`}</Typography>
              </Box>
            </Box>
            <Box sx={{ width: '50px' }}>
              <PIconButton
                inputtype="text"
                onClick={() => deleteCartItem(product.cartItemId)}>
                <IconResolver
                  iconName="DeleteOutline"
                  sx={{ color: redColors.red600 }}
                />
              </PIconButton>
            </Box>
          </Box>
        </Box>
      </PrimaryPart>
      <PDivider marginBottom="0" />
      <SecondaryPart>
        <SecondaryPartContainer>
          <SecondaryPartTextContainer>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {`${t('pay:process')} / ${t('pay:subscription')}`}
            </Typography>
            <Typography variant="titleSmall">{`${getTransactionLabel(product.type)} - ${product.subscriptionId}`}</Typography>
          </SecondaryPartTextContainer>
          <SecondaryPartTextContainer>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('pay:projectNo')}
            </Typography>
            <Typography variant="titleSmall">{product.projectNo}</Typography>
          </SecondaryPartTextContainer>
          <SecondaryPartTextContainer>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('pay:maturity')}
            </Typography>
            <Typography variant="titleSmall">{product.paymentTermText}</Typography>
          </SecondaryPartTextContainer>
          <SecondaryPartTextContainer>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('pay:unitPrice')}
            </Typography>
            <Typography variant="titleSmall">
              {`${formatPrice(product.netAmountAsDocument)} ${product.salesAndDistributionDocumentCurrency}`}
            </Typography>
          </SecondaryPartTextContainer>
        </SecondaryPartContainer>
      </SecondaryPart>
    </ProductCard>
  );
};

export default ProductItem;
