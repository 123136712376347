import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { Container, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import { primaryColors, redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { SpecialPageContainer } from 'layout/container/MarginContainer';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
const Forbidden403 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container
      maxWidth="md"
      sx={{ marginTop: '94px' }}>
      <SpecialPageContainer style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div>
          <CancelIcon
            fontSize="large"
            sx={{ color: redColors.red600 }}
          />
        </div>
        <div className="text-center d-flex flex-column gap-4">
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {t('forbidden403:youDoNotHaveAccessToThisPage')}
          </Typography>
          {location.state && (
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {location.state.description}
            </Typography>
          )}
        </div>
        <div>
          <PButton
            onClick={() => navigate(paths.protectedHome)}
            inputtype="outline"
            startIcon={<ArrowBackIcon />}>
            {t('forbidden403:goBack')}
          </PButton>
        </div>
      </SpecialPageContainer>
    </Container>
  );
};
export default Forbidden403;
