import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { Box, LinearProgress, Typography, styled } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import { EXCEL_TYPES } from 'components/fileUpload/constant/FileTypeConstants';
import { greenColors, primaryColors, redColors } from 'config/theme/Colors';
import { useMemo, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const CustomProgressBar = styled(LinearProgress)(({ issuccess = null }) => {
  let progressBarColor = primaryColors.primary;

  if (issuccess === false) {
    progressBarColor = redColors.red600;
  }
  if (issuccess === true) {
    progressBarColor = greenColors.green600;
  }

  return {
    width: '100%',
    '&.MuiLinearProgress-root': {
      backgroundColor: primaryColors.primary50,
    },
    '.MuiLinearProgress-bar': {
      backgroundColor: progressBarColor,
    },
  };
});

const FileUploadContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '8px',
  padding: '16px 24px',
  flexDirection: 'column',
}));

const SingleFileUpload = ({
  onChange,
  deleteFile,
  dragAndDrop,
  progress,
  file = null,
  texMessage = '',
  acceptTypes = EXCEL_TYPES,
  isSuccess = null,
  mbSize = 30,
}) => {
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const acceptedFileTypesString = useMemo(() => {
    return Object.keys(acceptTypes).flat().join(',');
  }, [acceptTypes]);

  const maxSize = useMemo(() => {
    return mbSize * 1024 * 1024;
  }, [mbSize]);

  const handleFileInputClick = () => {
    if (!file) {
      fileInputRef.current.click();
    }
  };

  const handleOnChange = (event) => {
    const files = event.target.files;
    const singleFile = files[0];
    if (singleFile) {
      if (!acceptedFileTypesString.includes(singleFile.type)) {
        toast.error(t('fileUpload:thisFileTypeUnsupported'));
        return;
      }
      if (singleFile?.size > maxSize) {
        toast.error(t('fileUpload:fileSizeMaxSize', { number: mbSize }));
      } else {
        onChange(event);
      }
    }
  };

  const convertSizeToMb = (size) => {
    return (size / 1024 / 1024).toFixed(2);
  };

  const handleOnDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      if (fileRejections[0].file.size > maxSize) {
        toast.error(t('fileUpload:fileSizeMaxSize', { number: mbSize }));
      } else if (!acceptedFileTypesString.includes(fileRejections[0].file.type)) {
        toast.error(t('fileUpload:thisFileTypeUnsupported'));
        return;
      }
    } else {
      dragAndDrop(acceptedFiles);
    }
  };

  return (
    <FileUploadContainer
      onClick={handleFileInputClick}
      className={!file && 'cursor-pointer'}>
      {!file && (
        <Dropzone
          maxSize={maxSize}
          noClick
          maxFiles={1}
          accept={acceptTypes}
          onDrop={(acceptedFiles, fileRejections) => handleOnDrop(acceptedFiles, fileRejections)}>
          {({ getRootProps, getInputProps }) => (
            <>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ backgroundColor: primaryColors.primary50, padding: '4px', borderRadius: '4px' }}>
                <UploadFileOutlinedIcon />
              </div>
              <div {...getRootProps()}>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary800}>
                  <input
                    {...getInputProps()}
                    type="file"
                    ref={fileInputRef}
                    hidden
                    accept={acceptedFileTypesString}
                    onChange={handleOnChange}
                  />
                  <b>{t('fileUpload:clickToInstall')}</b> {t('fileUpload:orDragTheFileHere')}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="bodySmall"
                  color={primaryColors.primary800}>
                  {texMessage}
                </Typography>
              </div>
            </>
          )}
        </Dropzone>
      )}
      {file && (
        <div className="w100">
          <div className="d-flex justify-content-between w100 gap-8">
            <div>
              <InsertDriveFileOutlinedIcon />
            </div>
            <div className="flex-column w100">
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {file.name}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {convertSizeToMb(file.size)} MB
              </Typography>
            </div>
            {!isSuccess && (
              <PIconButton
                disabled={!(progress !== 0 || progress <= 100)}
                inputtype="text"
                onClick={deleteFile}>
                <DeleteOutlineOutlinedIcon sx={{ color: redColors.red600 }} />
              </PIconButton>
            )}
          </div>
          <div
            className="d-flex align-items-center"
            style={{ gap: '40px' }}>
            <CustomProgressBar
              issuccess={isSuccess}
              variant="determinate"
              value={progress}
            />
            <Typography
              variant="labelSmall"
              color={primaryColors.primary700}>
              {progress}%
            </Typography>
          </div>
        </div>
      )}
    </FileUploadContainer>
  );
};

export default SingleFileUpload;
