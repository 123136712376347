/* eslint-disable max-len */
export const ContractAgreementEn = {
  canChange:
    'If you confirm the contract, you will be considered to have read the contract and agreed to the terms written in it. You can change this decision later from the "My Contracts" page.',
  cantChange:
    'If you approve the contract, you will be deemed to have read it and agreed to be bound by the terms written in the contract. This decision cannot be changed later.',
  approve: 'I approve',
  reject: 'I reject',
  ok: 'Ok',
  remindLater: 'Remind me later',
};
