/* eslint-disable max-len */
import { Menu, MenuItem, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const INSIGHT_URL =
  'https://app.powerbi.com/groups/3fbce28d-3198-4b4c-9e68-612223f95332/reports/' +
  '44d326e9-c0f8-4df7-b211-74020f4d42b6/ReportSection73e7e806053ce04e892e?experience=power-bi';

const USAGE_URL =
  'https://app.powerbi.com/groups/3fbce28d-3198-4b4c-9e68-612223f95332/reports/' +
  '44d326e9-c0f8-4df7-b211-74020f4d42b6/ReportSection5c9c2fd1d601666d7e2f?experience=power-bi';

const ReportTableMenu = ({ row, refreshFunction }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openInsightMetrics = useCallback(() => {
    handleClose();
    const filter = `AutodeskInsightsTable/customerCSN eq '${row.endCustomerCsnNo}' and AutodeskUsagesTable/EndCustomerCSN eq '${row.endCustomerCsnNo}'`;
    window.open(`${INSIGHT_URL}&filter=${filter}`, '_blank');
  }, [row.endCustomerCsnNo]);

  const openUsageReports = useCallback(() => {
    handleClose();
    const filter = `AutodeskUsagesTable/EndCustomerCSN eq '${row.endCustomerCsnNo}' and AutodeskUsagesTable/Usage Date Normalized lt '${dayjs(row.requestDate).format('YYYY-MM-DD')}' and AutodeskInsightsTable/customerCSN eq '${row.endCustomerCsnNo}'`;
    window.open(`${USAGE_URL}&filter=${filter}`, '_blank');
  }, [row.endCustomerCsnNo, row.requestDate]);

  return (
    <>
      <PIconButton
        inputtype="text"
        onClick={handleClick}>
        <IconResolver iconName="MoreVert" />
      </PIconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {row.getInsightStatus === 'Completed' && (
          <MenuItem onClick={openInsightMetrics}>
            <Typography variant="labelLarge">{t('pay:getInsightStatus')}</Typography>
          </MenuItem>
        )}
        {row.getUsageStatus === 'Completed' && (
          <MenuItem onClick={openUsageReports}>
            <Typography variant="labelLarge">{t('pay:getUsageStatus')}</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ReportTableMenu;
