import axios from 'axios';
import { createContracts } from 'components/contractAgreement/reducer/ContractAgreementReducer';
import i18n from 'config/i18n/i18n';
import { store } from 'config/store/Store';
import { statusCodes } from 'constants/HttpStatusCodes';
import { LOCAL_STORAGE_KEY } from 'constants/Keys';
import { paths } from 'constants/Paths';
import { logout } from 'pages/login/api/LoginApi';
import { toast } from 'react-toastify';
import globalRouter from 'utils/GlobalRouter';
export const httpRequest = axios.create();

httpRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['current-language'] = i18n.language;
    }
    return config;
  },
  (error) => {
    Promise.reject(error.response || error.message);
  },
);

httpRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const status = err.response?.status || statusCodes.internalServerError;
    switch (status) {
      // authentication (token related issues)
      case statusCodes.unauthorized: {
        const token = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!token) {
          localStorage.clear();
          globalRouter.navigate(paths.home);
          return;
        }
        logout()
          .then(() => {
            localStorage.clear();
            globalRouter.navigate(paths.home);
            toast.error(err.response.data.Message);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            localStorage.clear();
            globalRouter.navigate(paths.home);
          });
        return Promise.reject(err);
      }

      // forbidden (permission related issues)
      case statusCodes.forbidden: {
        const token = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (token) {
          const redirectFlag = err?.response?.data?.redirect;
          if (redirectFlag) {
            const pathName = window.location.pathname;
            if (pathName.startsWith(paths.storage)) {
              globalRouter.navigate(`${paths.storage}${paths.index}`);
            } else if (pathName.startsWith(paths.subscriptions)) {
              globalRouter.navigate(`${paths.subscriptions}${paths.index}`);
            } else if (pathName.startsWith(paths.wizard)) {
              globalRouter.navigate(`${paths.wizard}${paths.index}`);
            } else {
              globalRouter.navigate(paths.forbidden);
            }
          } else {
            globalRouter.navigate(paths.forbidden);
          }
        }

        return Promise.reject(err);
      }
      // bad request
      case statusCodes.badRequest: {
        return Promise.reject(err);
      }

      // not found
      case statusCodes.notFound: {
        return Promise.reject(err);
      }
      // Precondition Failed
      case statusCodes.preconditionFailed: {
        const { contractIds } = err.response.data;
        store.dispatch(createContracts(contractIds));
        return Promise.reject(err);
      }

      // Locked
      case statusCodes.locked: {
        const { errorMessage } = err.response.data;
        globalRouter.navigate(paths.forbidden, { state: { description: errorMessage } });
        return Promise.reject(err);
      }
      // generic api error (server related) unexpected
      default: {
        return Promise.reject(err);
      }
    }
  },
);
