import styled from '@emotion/styled';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PDivider from 'components/Divider/PDivider';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { getLogDetails } from 'pages/pentaIntegration/api/IntegrationApi';
import { integrationServiceEnum } from 'pages/pentaIntegration/constants/IntegrationServiceEnum';
import { productServicesTabNumbers } from 'pages/pentaIntegration/constants/TabConstants';
import CollapsableContainer from 'pages/pentaIntegration/pages/transactionDetail/component/CollapsableContainer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const GridContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
}));

const GridTitle = styled(Box)(() => ({
  gridColumnStart: 2,
  gridColumnEnd: 12,
}));

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridColumnStart: 2,
  gridColumnEnd: 12,
  gap: '28px',
}));

const InformationContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '16px',
  '&>div': {
    minWidth: '280px',
    overflowWrap: 'break-word',
  },
}));

const TransactionDetail = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [returnPage, setReturnPage] = useState(`${paths.integration}${paths.productServices}`);
  const [incomingData, setIncomingData] = useState({
    requestBody: '',
    responseBody: '',
    queryString: '',
    clientIp: '',
    httpStatusCode: '',
    httpMethod: '',
    sapCustomerId: '',
    serviceId: '',
    serviceName: '',
    endpointName: '',
    isAuditable: false,
    path: '',
    createdDate: '',
    id: '',
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const returnPageKey = searchParams.get('rp') || '';
    if (returnPageKey) {
      const numberKey = Number(returnPageKey);
      if (numberKey === integrationServiceEnum.productServices) {
        setReturnPage(`${paths.integration}${paths.productServices}`);
      } else if (numberKey === integrationServiceEnum.orderService) {
        setReturnPage(`${paths.integration}${paths.orderServices}`);
      } else if (numberKey === integrationServiceEnum.storageService) {
        setReturnPage(`${paths.integration}${paths.storageServices}`);
      } else if (numberKey === integrationServiceEnum.payService) {
        setReturnPage(`${paths.integration}${paths.payServices}`);
      }
    }
    setLoading(true);
    getLogDetails(params.id)
      .then((res) => {
        setIncomingData((prevState) => ({ ...prevState, ...res.data.data }));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onBackButtonHandler = () => {
    navigate(returnPage, { state: productServicesTabNumbers.transactionHistory });
  };

  const getRequestBody = useMemo(() => {
    if (incomingData.requestBody) {
      return JSON.stringify(JSON.parse(incomingData.requestBody), null, 1);
    }
    if (incomingData.queryString) {
      return incomingData.queryString;
    }
    return JSON.stringify(JSON.parse('{}'), null, 1);
  }, [incomingData.requestBody, incomingData.queryString]);

  const getResponseBody = useMemo(() => {
    if (incomingData.responseBody) {
      return JSON.stringify(JSON.parse(incomingData.responseBody), null, 1);
    }
    return JSON.stringify(JSON.parse('{}'), null, 1);
  }, [incomingData.responseBody]);

  return (
    <Box className="d-flex flex-column">
      {loading && <LoadingSpinner />}
      <GridContainer>
        <GridTitle>
          <Box className="d-flex align-items-center">
            <PIconButton
              inputtype="text"
              onClick={onBackButtonHandler}>
              <ChevronLeftIcon />
            </PIconButton>
            <Typography
              variant="titleLarge"
              color={neutralColors.neutral950}>
              {t('integration:nameRequestDetail', { name: `${incomingData.serviceName} - ${incomingData.endpointName}` })}
            </Typography>
          </Box>
        </GridTitle>
      </GridContainer>
      <PDivider marginBottom="24px" />
      <GridContainer>
        <Content>
          <InformationContainer>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodySmall"
                color={primaryColors.primary800}>
                {t('integration:requestInformation')}
              </Typography>
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {incomingData.endpointName}
              </Typography>
            </Box>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodySmall"
                color={primaryColors.primary800}>
                {t('integration:dateAndTime')}
              </Typography>
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {incomingData.createdDate}
              </Typography>
            </Box>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodySmall"
                color={primaryColors.primary800}>
                {t('integration:requestResult')}
              </Typography>
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {`${incomingData.httpMethod} ${incomingData.httpStatusCode}`}
              </Typography>
            </Box>
          </InformationContainer>
          <CollapsableContainer
            label={t('integration:httpRequest')}
            content={getRequestBody}
          />
          <CollapsableContainer
            label={t('integration:httpResponse')}
            content={getResponseBody}
          />
        </Content>
      </GridContainer>
    </Box>
  );
};
export default TransactionDetail;
