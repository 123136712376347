import { Box, styled, Typography } from '@mui/material';
import CustomerCreditCard from 'components/CustomerCreditCard';
import IconResolver from 'components/iconResolver/IconResolver';
import PTooltip from 'components/tooltip/PTooltip';
import { primaryColors, redColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { getPaymentResult } from 'pages/configMaster/api/ConfigMasterApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/HelperFunctions';

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px',
  rowGap: '20px',
}));

const PaymentDetails = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
}));

const PaymentDetailRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: ' space-between',
  marginBottom: 16,
  borderBottom: `1px solid ${slateColors.slate200}`,
  paddingBottom: 16,
  '&>*:nth-of-type(1)': {
    width: '50%',
  },
  '&>*:nth-of-type(2)': {
    width: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
  },
  '&:last-child': {
    border: 'none',
  },
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 24,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const WithCardInfoFail = () => {
  const [paymentDetail, setPaymentDetails] = useState(null);
  useEffect(() => {
    getPaymentDetail();
  }, []);

  const getPaymentDetail = () => {
    // eslint-disable-next-line no-restricted-globals
    const queryParams = new URLSearchParams(location.search);
    const keyValue = queryParams.get('key');
    getPaymentResult(keyValue)
      .then((res) => {
        setPaymentDetails(res.data.data);
      })
      .catch();
  };

  const { t } = useTranslation();
  return (
    <SecondaryLayout
      path={`${paths.wizard}${paths.fbw}`}
      title={t('configMaster:orderDetails')}>
      <ResponseContainer>
        <Box className="flex-column d-flex">
          <IconResolver
            iconName="Cancel"
            sx={{ color: redColors.red600, width: '48px', height: '48px' }}
          />
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {t('configMaster:couldNotCreateOrder')}
          </Typography>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {t('configMaster:paymentTransactionCompletedUnsuccessfully')}
          </Typography>
        </Box>
        <Content>
          <Box>
            <CustomerCreditCard
              bank={paymentDetail?.bank}
              cardNumber={paymentDetail?.cardNumber}
              cardHolder={paymentDetail?.cardHolder}
              installment={paymentDetail?.installmentInformation}
            />
          </Box>

          <PaymentDetails>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:paymentNo')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {paymentDetail?.paymentNo}
              </Typography>
            </PaymentDetailRow>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:erpPaymentNo')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {paymentDetail?.paymentRefNo}
              </Typography>
            </PaymentDetailRow>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:dateAndTime')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {paymentDetail?.date && dayjs(paymentDetail?.date).format('DD/MM/YYYY HH:mm:ss')}
              </Typography>
            </PaymentDetailRow>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:customer')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {paymentDetail?.customerId}
              </Typography>
            </PaymentDetailRow>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:user')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {paymentDetail?.user}
              </Typography>
            </PaymentDetailRow>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:amount')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {`${formatPrice(paymentDetail?.amount)} TL`}
              </Typography>
            </PaymentDetailRow>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:explanation')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                <PTooltip
                  placement="bottom-start"
                  title={paymentDetail?.description}>
                  {paymentDetail?.description}
                </PTooltip>
              </Typography>
            </PaymentDetailRow>
          </PaymentDetails>
        </Content>
      </ResponseContainer>
    </SecondaryLayout>
  );
};

export default WithCardInfoFail;
