import { Box, Typography, styled } from '@mui/material';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import PButton from 'components/button/PButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { greenColors, primaryColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

const ResultContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '16px 16px 24px 16px',
}));

const DynamicContainer = styled(Box)(() => ({
  paddingTop: '12px',
  '& > *': {
    borderBottom: `1px solid ${slateColors.slate200}`,
  },
  '&>*:nth-of-type(1)': {
    padding: 0,
    paddingBottom: '12px',
  },
  'div:last-of-type': {
    borderBottom: 0,
  },
}));

const Item = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 0',
  width: '100%',
}));

const PayGetAnOfferResult = () => {
  const { t } = useTranslation();
  const [resultData, setResultData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      const { projectNos } = location?.state;
      const uniqueArray = Array.from(new Map(projectNos.map((item) => [item.sdProjectNumber, item])).values());
      setResultData(uniqueArray);
    }
  }, []);

  if (!location.state) {
    return <Navigate to={paths.home} />;
  }

  const navigateToBayinet = () => {
    loginForBayinet()
      .then((res) => {
        window.open(`${process.env.REACT_APP_BAYINET_URL}/Order/ProjectList`, '_blank');
      })
      .catch((err) => {});
  };

  return (
    <SecondaryLayout
      path={`${paths.subscriptions}${paths.byProducts}`}
      title={t('pay:transactionResult')}>
      <ResultContainer>
        <Box className="d-flex flex-column gap-8">
          <IconResolver
            iconName="CheckCircle"
            sx={{ color: greenColors.green500, width: '48px', height: '48px' }}
          />
          <Typography variant="titleLarge">{t('pay:transactionCreatedSuccessfully')}</Typography>
          <Typography variant="bodyMedium">
            {t('pay:youanFollowYourTransactionFromThePriceRequestListWithTheRelevantProjectNumberInformation')}
          </Typography>
        </Box>
        <DynamicContainer>
          <Box>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary}>
              {t('pay:projectNo')}
            </Typography>
          </Box>
          {resultData.map((result) => {
            return (
              <Item key={result.sdProjectNumber}>
                <Box>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary}>
                    {result.sdProjectNumber}
                  </Typography>
                </Box>
              </Item>
            );
          })}
        </DynamicContainer>
        <Box className="d-flex justify-flex-end">
          <PButton
            onClick={navigateToBayinet}
            width="auto">
            {t('pay:goToQuoteList')}
          </PButton>
        </Box>
      </ResultContainer>
    </SecondaryLayout>
  );
};
export default PayGetAnOfferResult;
