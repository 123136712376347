import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, styled, Typography } from '@mui/material';
import PClientTable from 'components/table/PClientTable';
import { greenColors, primaryColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { formatPrice } from 'utils/HelperFunctions';

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px',
  rowGap: '24px',
}));

const PaymentDetails = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
}));

const PaymentDetailRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: ' space-between',
  marginBottom: 16,
  borderBottom: `1px solid ${slateColors.slate200}`,
  paddingBottom: 16,
  '&>*:nth-of-type(1)': {
    width: '50%',
  },
  '&>*:nth-of-type(2)': {
    width: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
  },

  '&:last-of-type': {
    borderBottom: 0,
  },
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 24,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const WithoutCardInfoSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (location?.state?.products) {
      const candidate = location.state.products.map((item, index) => ({
        id: index,
        entryNumber: item.entryNumber,
        productNumber: item.productNumber,
        productName: item.productName,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        vat: item.vat,
        totalAmount: item.totalAmount,
        currency: item.currency,
      }));
      setRows(candidate);
    }
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'productNumber',
        headerName: t('configMaster:productCode'),
        disableColumnMenu: true,
        minWidth: 96,
      },
      {
        field: 'productName',
        headerName: t('configMaster:productName'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 224,
      },
      {
        field: 'quantity',
        headerName: t('configMaster:piece'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        width: 92,
      },
      {
        field: 'unitPrice',
        headerName: t('configMaster:unitPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 128,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.unitPrice)} ${row.currency}`}</Box>;
        },
      },
      {
        field: 'vat',
        headerName: 'KDV',
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 128,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.vat)} ${row.currency}`}</Box>;
        },
      },
      {
        field: 'totalAmount',
        headerName: t('configMaster:taxIncludedPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 128,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.totalAmount)} ${row.currency}`}</Box>;
        },
      },
    ];
  }, [t]);
  return (
    <SecondaryLayout
      path={`${paths.wizard}${paths.fbw}`}
      title={t('configMaster:orderDetails')}>
      <ResponseContainer>
        <Box className="flex-column d-flex">
          <CheckCircleIcon sx={{ color: greenColors.green500, width: '48px', height: '48px' }} />
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {t('configMaster:orderCreatedSuccessfully')}
          </Typography>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {t('configMaster:youCanViewOrderDetailsInTheTableBelow')}
          </Typography>
        </Box>
        <Content>
          <PaymentDetails>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:orderNo')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {location?.state?.orderNo}
              </Typography>
            </PaymentDetailRow>
            <PaymentDetailRow>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {t('configMaster:shipmentType')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {location?.state?.deliveryType}
              </Typography>
            </PaymentDetailRow>
            {location?.state?.amounts.map((item) => {
              return (
                <PaymentDetailRow key={item.amountType}>
                  <Typography
                    variant="titleSmall"
                    color={primaryColors.primary}>
                    {item.amountType}
                  </Typography>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary}>
                    {`${formatPrice(item.amount)} ${item.currency}`}
                  </Typography>
                </PaymentDetailRow>
              );
            })}
          </PaymentDetails>
        </Content>
        <Box>
          <PClientTable
            dataLength={`${rows.length} ${t('configMaster:piece')}`}
            headerTitle={t('configMaster:orderItems')}
            columns={columns}
            rows={rows}
            getRowHeight={() => 'auto'}
          />
        </Box>
      </ResponseContainer>
    </SecondaryLayout>
  );
};

export default WithoutCardInfoSuccess;
