import { useTheme } from '@emotion/react';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CurrencyLiraOutlinedIcon from '@mui/icons-material/CurrencyLiraOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PCenterModal from 'components/modal/PCenterModal';
import PSelect from 'components/select/PSelect';
import PClientTable from 'components/table/PClientTable';
import UnitInput from 'components/unitInput/UnitInput';
import { blueColors, primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import PentaStorageCards from 'pages/pentaStorage/commonComponents/PentaStorageCards';
import PentaStorageTabContainer from 'pages/pentaStorage/commonComponents/PentaStorageTabContainer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { splitWithDot } from 'utils/HelperFunctions';
import StorageCostsDetail from './StorageCostsDetailContainer';
const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const StorageCosts = ({ tableData, periodFilterClickHandler, userSeeResult, headers }) => {
  const { t } = useTranslation();
  const [detailData, setDetailData] = useState(null);
  const [period, setPeriod] = useState({
    periodYear: '',
    periodMonth: '',
  });
  const theme = useTheme();
  const smallerSm = useMediaQuery(theme.breakpoints.down('sm'));

  const monthNames = useMemo(
    () => [
      {
        key: '0',
        value: t('month:january'),
      },
      {
        key: '1',
        value: t('month:february'),
      },
      {
        key: '2',
        value: t('month:march'),
      },
      {
        key: '3',
        value: t('month:april'),
      },
      {
        key: '4',
        value: t('month:may'),
      },
      {
        key: '5',
        value: t('month:june'),
      },
      {
        key: '6',
        value: t('month:july'),
      },
      {
        key: '7',
        value: t('month:august'),
      },
      {
        key: '8',
        value: t('month:september'),
      },
      {
        key: '9',
        value: t('month:october'),
      },
      {
        key: '10',
        value: t('month:november'),
      },
      {
        key: '11',
        value: t('month:december'),
      },
    ],
    [t],
  );

  const yearData = [
    { value: dayjs().year() - 2, key: dayjs().year() - 2 },
    { value: dayjs().year() - 1, key: dayjs().year() - 1 },
    { value: dayjs().year(), key: dayjs().year() },
  ];

  const goToStorageDetail = (row) => {
    setDetailData(row);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'date',
        headerName: t('storage:date'),
        minWidth: 120,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => dayjs(row.date).format('DD.MM.YYYY'),
      },
      {
        field: 'desiCost',
        headerName: t('storage:desi'),
        minWidth: 130,
        type: 'number',
        headerClassName: 'text-end',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ value }) => `${splitWithDot(Math.round(value), 0)} ${t('storage:desi')}`,
      },
      {
        field: 'desiCostAmount',
        headerName: t('storage:desiCost'),
        minWidth: 160,
        type: 'number',
        headerClassName: 'text-end',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row, value }) => (
          <UnitInput
            value={value}
            unit={row.currency}
          />
        ),
      },
      {
        field: 'ownDesi',
        type: 'number',
        headerClassName: 'text-end',
        headerName: t('storage:ownDesi'),
        minWidth: 160,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ value }) => `${splitWithDot(Math.round(value), 0)} ${t('storage:desi')}`,
      },
      {
        field: 'ownDesiTtr',
        type: 'number',
        headerClassName: 'text-end',
        headerName: t('storage:ownDesiCost'),
        disableColumnMenu: true,
        minWidth: 150,
        flex: 1,
        renderCell: ({ row, value }) => (
          <UnitInput
            value={value}
            unit={row.currency}
          />
        ),
      },
      {
        field: 'differentProductCostCount',
        headerName: t('storage:differentProduct'),
        minWidth: 160,
        flex: 1,
        type: 'number',
        headerClassName: 'text-end',
        disableColumnMenu: true,
        renderCell: ({ value }) => (
          <UnitInput
            value={value}
            unit={t('storage:piece')}
            precision={0}
          />
        ),
      },
      {
        field: 'differentProductCostAmount',
        headerName: t('storage:differentProductCosts'),
        minWidth: 160,
        flex: 1,
        type: 'number',
        headerClassName: 'text-end',
        disableColumnMenu: true,
        renderCell: ({ row, value }) => (
          <UnitInput
            value={value}
            unit={row.currency}
          />
        ),
      },
      {
        field: 'cargoCount',
        headerName: t('storage:shipment'),
        minWidth: 160,
        flex: 1,
        type: 'number',
        headerClassName: 'text-end',
        disableColumnMenu: true,
        renderCell: ({ value }) => (
          <UnitInput
            value={value}
            unit={t('storage:piece')}
            precision={0}
          />
        ),
      },
      {
        field: 'cargoCostAmount',
        headerName: t('storage:shippingCost'),
        minWidth: 160,
        flex: 1,
        type: 'number',
        headerClassName: 'text-end',
        disableColumnMenu: true,
        renderCell: ({ row, value }) => (
          <UnitInput
            value={value}
            unit={row.currency}
          />
        ),
      },
      {
        field: 'prepairingTtr',
        headerName: t('storage:preperationCost'),
        minWidth: 160,
        flex: 1,
        disableColumnMenu: true,
        type: 'number',
        headerClassName: 'text-end',
        renderCell: ({ row, value }) => (
          <UnitInput
            value={value}
            unit={row.currency}
          />
        ),
      },
      {
        field: 'totalCost',
        headerName: t('storage:overallCost'),
        minWidth: 160,
        flex: 1,
        disableColumnMenu: true,
        type: 'number',
        headerClassName: 'text-end',
        renderCell: ({ row }) => (
          <UnitInput
            value={row.totalCost}
            unit={row.currency}
          />
        ),
      },
      {
        field: '',
        headerName: '',
        width: 100,
        sortable: false,
        type: 'number',
        headerClassName: 'text-end',
        disableColumnMenu: true,
        renderCell: ({ row }) => <ChevronRightOutlinedIcon onClick={() => goToStorageDetail(row)} />,
      },
    ];
  }, [t]);

  useEffect(() => {
    setPeriod((prevState) => ({ ...prevState, periodYear: dayjs().year(), periodMonth: dayjs().month() }));
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setPeriod((prevState) => ({ ...prevState, [name]: value }));
  };

  const onFilterClick = () => {
    periodFilterClickHandler(period);
  };

  return (
    <PentaStorageTabContainer>
      <Box
        style={{ flexDirection: smallerSm ? 'column' : 'row' }}
        className="d-flex align-items-center gap-16">
        <PSelect
          width="250px"
          label={t('storage:periodYear')}
          onChange={onChangeHandler}
          value={period.periodYear}
          menuItems={yearData}
          name="periodYear"
        />
        <PSelect
          width="250px"
          label={t('storage:periodMonth')}
          onChange={onChangeHandler}
          value={period.periodMonth}
          menuItems={monthNames}
          name="periodMonth"
        />
        <PButton
          width="auto"
          onClick={onFilterClick}>
          {t('storage:filterPeriod')}
        </PButton>
      </Box>
      {userSeeResult && (
        <Box className="d-flex gap-24 flex-column">
          {tableData?.length > 0 && (
            <>
              <GridContainer>
                <div style={{ gridRowStart: 1, gridRowEnd: 'span 2' }}>
                  <PentaStorageCards>
                    <div>
                      <Typography
                        color={primaryColors.primary800}
                        variant="bodyMedium">
                        {t('storage:customerOwnProducts')}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        color={primaryColors.primary}
                        variant="titleLarge">
                        <UnitInput
                          value={headers.customerTotalAmount}
                          unit={headers.currency}
                        />
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      <ShowChartOutlinedIcon sx={{ color: blueColors.blue500 }} />
                      <Typography
                        color={primaryColors.primary800}
                        variant="labelLarge">
                        {splitWithDot(Math.round(headers?.customerDesi), 0)} {t('storage:desi')}
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      <LocalShippingOutlinedIcon sx={{ color: blueColors.blue500 }} />
                      <Typography
                        color={primaryColors.primary800}
                        variant="labelLarge">
                        <UnitInput
                          value={headers?.customerCargoCount}
                          unit={`${t('storage:piece')} ${t('storage:cargo')}`}
                          precision={0}
                        />
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      <CurrencyLiraOutlinedIcon sx={{ color: blueColors.blue500 }} />
                      <Typography
                        color={primaryColors.primary800}
                        variant="labelLarge">
                        <UnitInput
                          startUnit={t('storage:desiAmount')}
                          value={headers?.customerDesiAmount}
                          unit={headers.currency}
                        />
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      <CurrencyLiraOutlinedIcon sx={{ color: blueColors.blue500 }} />
                      <Typography
                        color={primaryColors.primary800}
                        variant="labelLarge">
                        <UnitInput
                          startUnit={t('storage:shippingAmount')}
                          value={headers?.customerCargoCostsAmount}
                          unit={headers.currency}
                        />
                      </Typography>
                    </div>
                  </PentaStorageCards>
                </div>
                <div>
                  <PentaStorageCards>
                    <div>
                      <Typography
                        color={primaryColors.primary800}
                        variant="bodyMedium">
                        {t('storage:desiCosts')}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        color={primaryColors.primary}
                        variant="titleLarge">
                        <UnitInput
                          value={headers.totalDesiCostsAmount}
                          unit={headers.currency}
                        />
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      <ShowChartOutlinedIcon sx={{ color: blueColors.blue500 }} />
                      <Typography
                        color={primaryColors.primary800}
                        variant="labelLarge">
                        {splitWithDot(Math.round(headers?.desiCostsSubValue), 0)} {t('storage:desi')}
                      </Typography>
                    </div>
                  </PentaStorageCards>
                </div>
                <div>
                  <PentaStorageCards>
                    <div>
                      <Typography
                        color={primaryColors.primary800}
                        variant="bodyMedium">
                        {t('storage:differentProductCost')}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        color={primaryColors.primary}
                        variant="titleLarge">
                        <UnitInput
                          value={headers.differentProductCostsAmount}
                          unit={headers.currency}
                        />
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      <ShowChartOutlinedIcon sx={{ color: blueColors.blue500 }} />
                      <Typography
                        color={primaryColors.primary800}
                        variant="labelLarge">
                        <UnitInput
                          precision={0}
                          value={headers.differentProductCostsCount}
                          unit={t('storage:piece')}
                        />
                      </Typography>
                    </div>
                  </PentaStorageCards>
                </div>
                <div>
                  <PentaStorageCards>
                    <div>
                      <Typography
                        color={primaryColors.primary800}
                        variant="bodyMedium">
                        {t('storage:shippingCosts')}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        color={primaryColors.primary}
                        variant="titleLarge">
                        <UnitInput
                          value={headers?.cargoCostsAmount}
                          unit={headers?.currency}
                        />
                      </Typography>
                    </div>
                    <div className="d-flex align-items-center">
                      <ShowChartOutlinedIcon sx={{ color: blueColors.blue500 }} />
                      <Typography
                        color={primaryColors.primary800}
                        variant="labelLarge">
                        <UnitInput
                          value={headers?.cargoCount}
                          unit={t('storage:piece')}
                          precision={0}
                        />
                      </Typography>
                    </div>
                  </PentaStorageCards>
                </div>
                <div>
                  <PentaStorageCards>
                    <div>
                      <Typography
                        color={primaryColors.primary800}
                        variant="bodyMedium">
                        {t('storage:preparationFeeCost')}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        color={primaryColors.primary}
                        variant="titleLarge">
                        <UnitInput
                          value={headers.prepareCosts}
                          unit={headers?.currency}
                        />
                      </Typography>
                    </div>
                  </PentaStorageCards>
                </div>
                <div>
                  <PentaStorageCards background={blueColors.blue50}>
                    <div>
                      <Typography
                        color={primaryColors.primary800}
                        variant="bodyMedium">
                        {t('storage:totalCosts')}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        color={primaryColors.primary}
                        variant="titleLarge">
                        <UnitInput
                          value={headers.totalCosts}
                          unit={headers?.currency}
                        />
                      </Typography>
                    </div>
                  </PentaStorageCards>
                </div>
              </GridContainer>

              <PClientTable
                headerTitle={t('storageCost:costTable')}
                columns={columns}
                rows={tableData}
              />
              {detailData && (
                <PCenterModal
                  onClose={() => setDetailData(null)}
                  showClose
                  width="75%"
                  title={t('storageCost:costAndShippingDetailsDate', { date: dayjs(detailData?.date).format('DD-MM-YYYY') })}>
                  <StorageCostsDetail detailData={detailData} />
                </PCenterModal>
              )}
            </>
          )}
          {tableData?.length === 0 && (
            <Box className="text-center">
              <Typography
                color={primaryColors.primary400}
                variant="titleMedium">
                {t('storageCost:noResultsFound')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </PentaStorageTabContainer>
  );
};
export default StorageCosts;
