export const CalculatorTr = {
  productToBeAddedToTheCalculation: 'Hesaplamaya Eklenecek Ürün',
  piece: 'Adet',
  daysToStore: 'Depolanacak Gün',
  add: 'Ekle',
  calculateByIncludingProductsFromPentaStorage: 'Penta Depom’daki ürünleri dahil ederek hesapla',
  calculate: 'Hesapla',
  productsAddedToTheCalculation: 'Hesaplamaya Eklenen Ürünler',
  stockCode: 'Stok Kodu',
  itemName: 'Ürün Adı',
  numberOfDaysToStore: 'Depolanacak Gün Sayısı',
  storage: 'Depolama',
  storageCost: 'Depolama Maliyeti',
  transport: 'Nakliye',
  cargo: 'Kargo',
  shipment: 'Sevkiyat',
  total: 'Toplam',
  sameLocation: 'Aynı Konum',
  differentLocation: 'Farklı Konum',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  theProductYouWantToAddIsAvailableInTheTable: 'Eklemek istediğiniz ürün tabloda mevcuttur!',
};
