import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import LoginContainer from 'layout/loginLayout/container/LoginContainer';
import PentaUserModal from 'pages/login/pentaUserModal/PentaUserModal';
import ResetPassword from 'pages/resetPassword/ResetPassword';
import { useState } from 'react';
import { toast } from 'react-toastify';
import GoogleAuthenticator from './GoogleAuthenticator';
import Login from './Login';
import MailOtp from './MailOtp';
import MobileOtp from './MobileOtp';
import { getOTPCode } from './api/LoginApi';

const UserLoginContainer = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [formField, setFormField] = useState({
    sapCustomerId: '',
    email: '',
    password: '',
  });
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const onLoginHandler = () => {
    if (formField.sapCustomerId === '') {
      setErrorMessage('login:pleaseEnterYourCustomerCode');
    } else if (formField.email === '') {
      setErrorMessage('login:pleaseEnterYourEmailAddress');
    } else if (formField.password === '') {
      setErrorMessage('login:pleaseEnterYourPassword');
    } else {
      setErrorMessage('');
      const data = {
        sapCustomerId: formField.sapCustomerId,
        emailOrPhone: formField.email,
        password: formField.password,
      };
      setIsLoading(true);
      getOTPCode(data)
        .then((res) => {
          if (res?.data?.data?.token) {
            setToken(res.data.data.token);
            if (res.data.data.user.isBayinetUser) {
              setShowModal(res.data.data.user);
            } else {
              setPage(3);
            }
          } else {
            if (formField.email.includes('@')) {
              setPage(2);
            } else {
              setPage(1);
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onChangeHandler = (event) => {
    let { value, name } = event.target;

    if (name === 'email') {
      let valueTest = value.replace(/\s/g, ''); // Boşlukları kaldır

      if (valueTest === '' || valueTest === '90' || valueTest === '9') {
        value = '';
      } else if (valueTest.startsWith('90') && valueTest.length < 4) {
        value = valueTest;
      } else if (valueTest.startsWith('0')) {
        value = '90' + valueTest.slice(1);
      } else if (/^[a-zA-Z]/.test(valueTest)) {
        value = valueTest;
      } else if (!valueTest.startsWith('90') && valueTest.length === 10) {
        value = '90' + valueTest;
      } else if (/^\d+$/.test(valueTest) && !valueTest.includes('@') && !valueTest.includes('.')) {
        if (valueTest.startsWith('90') || (valueTest.startsWith('9') && event.nativeEvent.inputType === 'deleteContentBackward')) {
          value = valueTest;
        } else if (!valueTest.startsWith('90')) {
          value = '90' + valueTest;
        }
      }
    }

    setFormField((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {page === 0 && (
        <Login
          formField={formField}
          errorMessage={errorMessage}
          onChangeHandler={onChangeHandler}
          onLoginHandler={onLoginHandler}
        />
      )}
      {page !== 0 && (
        <LoginContainer maxWidth="xs">
          {page === 1 && (
            <MobileOtp
              setFormField={setFormField}
              formField={formField}
              setIsLoading={setIsLoading}
              setPage={setPage}
            />
          )}
          {page === 2 && (
            <MailOtp
              setFormField={setFormField}
              formField={formField}
              setIsLoading={setIsLoading}
              setPage={setPage}
            />
          )}
          {page === 3 && (
            <ResetPassword
              token={token}
              emailOrPhone={formField.email}
              setPage={setPage}
              setLoginFormField={setFormField}
              setIsLoading={setIsLoading}
            />
          )}

          {page === 4 && (
            <GoogleAuthenticator
              setFormField={setFormField}
              formField={formField}
              setIsLoading={setIsLoading}
              setPage={setPage}
            />
          )}
        </LoginContainer>
      )}
      {showModal && (
        <PentaUserModal
          user={showModal}
          setShowModal={setShowModal}
          setPage={setPage}
          setToken={setToken}
          token={token}
          setFormField={setFormField}
        />
      )}
    </>
  );
};

export default UserLoginContainer;
