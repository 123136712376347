import { Box, styled, Typography } from '@mui/material';
import ErrorAlert from 'components/alert/ErrorAlert';
import PButton from 'components/button/PButton';
import PInfoChip from 'components/chip/PInfoChip';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { primaryColors } from 'config/theme/Colors';
import { addToCart, simulateBeforeCart } from 'pages/configMaster/api/ConfigMasterApi';
import { tabNumbers } from 'pages/configMaster/constants/TabNumbers';
import { CustomTypographyWithLineThrough } from 'pages/configMaster/tabs/configMaster/components/accordion/ConfigMasterAccordionSummary';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatPrice } from 'utils/HelperFunctions';

const ContainerParts = styled(Box)(() => ({
  padding: '0 15px',
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '28px 0',
}));

const ConfigMasterSummaryPart = ({
  resetAfterAddToCart,
  selectedParts,
  count,
  setCount,
  configSummaryPart,
  setComponents,
  components,
  requiredFields,
  setTabValue,
  showUpdateCartButton,
  setShowUpdateCartButton,
  customerDefaultCurrency,
  pricesAfterSimulate,
  setPricesAfterSimulate,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [priceObject, setPriceObject] = useState([]);
  const [insufficientStockError, setInsufficientStockError] = useState(false);
  const [showMandatoryError, setShowMandatoryError] = useState(false);
  const [maturity, setMaturity] = useState({ maturityPeriod: null, maturityPeriodDescription: null });
  useEffect(() => {
    setPriceObject(selectedParts);
    setShowUpdateCartButton(true);
    setShowMandatoryError(false);
  }, [selectedParts]);

  const calculatePrice = ({ withTax, showUpdateCartButton, type }) => {
    let price = 0;
    if (showUpdateCartButton) {
      if (withTax) {
        priceObject.forEach((item) => (price = price + item?.count * item?.price?.amount));
      } else {
        priceObject.forEach((item) => (price = price + item?.count * item?.price?.amountWithoutVatRate));
      }
      price = price * count;
      return `${formatPrice(price)} ${priceObject[0]?.price?.currencyCode}`;
    } else {
      if (type === 'sub') {
        const foundPrice = pricesAfterSimulate.find((item) => item.amountType === 'Ara Toplam');
        return `${formatPrice(foundPrice.amount)} ${foundPrice.currency}`;
      } else if (type === 'total') {
        const foundPrice = pricesAfterSimulate.find((item) => item.amountType === 'Genel Toplam');
        return `${formatPrice(foundPrice.amount)} ${foundPrice.currency}`;
      }
    }
  };

  const calculateOldPrice = ({ withTax }) => {
    const copyPrice = [...priceObject];
    let price = 0;
    if (withTax) {
      copyPrice.forEach((item) => (price = price + item?.count * item?.price?.oldAmount));
    } else {
      copyPrice.forEach((item) => (price = price + item?.count * item?.price?.oldAmount));
    }
    price = price * count;
    if (!withTax) {
      price = (price * 20) / 100;
    }
    if (price > 0) {
      if (!withTax) {
        return `${formatPrice(price)} ${copyPrice[0]?.price?.currencyCode} + KDV`;
      } else {
        return `${formatPrice(price)} ${copyPrice[0]?.price?.currencyCode}`;
      }
    } else {
      return '';
    }
  };

  const getConfiguration = useMemo(() => {
    return configSummaryPart.map((item) => item.configName).join(', ');
  }, [configSummaryPart]);

  const checkAllRequiredFieldsFilled = () => {
    const componentsUpper = requiredFields.map((component) => component.toUpperCase());

    const allComponentsPresent = componentsUpper.every((comp) => priceObject.some((item) => item.component === comp));
    return allComponentsPresent;
  };

  const controlAndSetCount = (value) => {
    if (checkAllRequiredFieldsFilled() && priceObject.length > 0) {
      setCount(value);
      setShowUpdateCartButton(true);
    } else {
      setShowMandatoryError(true);
    }
  };

  const simulate = () => {
    if (!checkAllRequiredFieldsFilled()) {
      setShowMandatoryError(true);
      return;
    }

    const products = priceObject.map((item) => ({
      productId: item.value,
      // amount: item.price.amount,
      amount: item.sapPrice,
      quantity: item.count * count,
      stock: item.stock,
      currency: item.price.currencyCode,
    }));
    setLoading(true);
    simulateBeforeCart(
      configSummaryPart.map((item) => item.configId),
      products,
      customerDefaultCurrency,
    )
      .then((res) => {
        const incomingDatas = res.data.data;
        const copyAllData = [...components.allData];
        const copyPrices = [...priceObject];
        incomingDatas.products.forEach((incomingData) => {
          const productId = incomingData.productId;
          copyPrices.forEach((copyPrice) => {
            if (copyPrice.value === productId) {
              copyPrice.price = incomingData.price;
              copyPrice.maturity = incomingDatas.maturity;
              copyPrice.maturityText = incomingDatas.maturityText;
            }
          });
          copyAllData.forEach((group) => {
            group.components.forEach((component) => {
              if (component.id === productId) {
                component.price = incomingData.price;
                component.stock = incomingData.insufficientStock ? 0 : incomingData.stock;
                component.maturity = incomingDatas.maturity;
                component.maturityText = incomingDatas.maturityText;
                if (incomingData.insufficientStock) {
                  setShowUpdateCartButton(true);
                  setInsufficientStockError(true);
                  return;
                } else {
                  setShowUpdateCartButton(false);
                  setInsufficientStockError(false);
                }
              }
            });
          });
        });
        setPricesAfterSimulate(incomingDatas.amounts);
        setMaturity({ maturityPeriod: incomingDatas.maturity, maturityPeriodDescription: incomingDatas.maturityText });
        setPriceObject(copyPrices);
        setComponents((prevState) => ({ ...prevState, allData: copyAllData, visibleData: copyAllData }));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addToCartFn = () => {
    const cartItems = priceObject.map((item) => ({
      stockCode: item.value,
      itemName: item.name,
      quantity: item.count,
      unitPrice: item.price.unitPrice,
      stock: item.stock,
      currency: item.price.currencyCode,
      maturityPeriod: item.maturity,
      maturityPeriodDescription: item.maturityText,
    }));

    setLoading(true);

    addToCart(
      count,
      maturity.maturityPeriod,
      maturity.maturityPeriodDescription,
      cartItems,
      configSummaryPart.map((item) => item.configId),
      customerDefaultCurrency,
    )
      .then((res) => {
        resetAfterAddToCart();
        toast.success(res.data.message);
        setTabValue(tabNumbers.configMasterCart);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <ContainerParts>
        <CardHeader>
          <Box className="d-flex gap-8 align-items-center">
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('configMaster:selectionSummary')}
            </Typography>
            <PInfoChip label={`${priceObject.length} ${t('configMaster:component')}`} />
          </Box>
        </CardHeader>
      </ContainerParts>
      <PDivider />
      <ContainerParts>
        <Box className="d-flex justify-content-between align-items-center">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {t('configMaster:piece')}
          </Typography>
          <InlineEditNumberInput
            width="74px"
            value={count}
            onChange={controlAndSetCount}
          />
        </Box>
        {insufficientStockError && (
          <Box
            className="d-flex justify-content-between align-items-center"
            sx={{ margin: '8px 0' }}>
            <ErrorAlert label={t('configMaster:insufficientStockError')} />
          </Box>
        )}
        {!insufficientStockError && <PDivider sx={{ marginTop: '8px' }} />}
        <Box className="d-flex justify-content-between align-items-center">
          <Typography
            sx={{ width: '100%' }}
            variant="bodyMedium"
            color={primaryColors.primary}>
            {t('configMaster:configuration')}
          </Typography>
          {priceObject.length > 0 && (
            <Box className="text-end w100">
              <Typography
                sx={{ width: '100%' }}
                variant="titleSmall"
                color={primaryColors.primary}>
                {getConfiguration}
              </Typography>
            </Box>
          )}
        </Box>
        <PDivider sx={{ marginTop: '8px' }} />
        <Box className="d-flex justify-content-between align-items-center">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {t('configMaster:subtotal')}
          </Typography>
          <Box>
            {priceObject.length > 0 && !showUpdateCartButton && (
              <CustomTypographyWithLineThrough> {`${calculateOldPrice({ withTax: false })}`}</CustomTypographyWithLineThrough>
            )}

            {priceObject.length > 0 && (
              <Typography
                sx={{ width: '100%' }}
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${calculatePrice({ withTax: false, showUpdateCartButton, type: 'sub' })} + KDV`}
              </Typography>
            )}
          </Box>
        </Box>
        <PDivider sx={{ marginTop: '8px' }} />
        <Box className="d-flex justify-content-between align-items-center">
          <Box className="d-flex flex-column">
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {t('configMaster:grandTotal')}
            </Typography>
            <Typography
              variant="bodySmall"
              color={primaryColors.primary}>
              {`%20 KDV ${t('configMaster:including')}`}
            </Typography>
          </Box>
          <Box>
            {priceObject.length > 0 && !showUpdateCartButton && (
              <CustomTypographyWithLineThrough> {`${calculateOldPrice({ withTax: true })}`}</CustomTypographyWithLineThrough>
            )}
            {priceObject.length > 0 && (
              <Typography
                sx={{ width: '100%' }}
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${calculatePrice({ withTax: true, showUpdateCartButton, type: 'total' })}`}
              </Typography>
            )}
          </Box>
        </Box>
        {showMandatoryError && (
          <Box sx={{ margin: '40px 0 2px 0 ' }}>
            <ErrorAlert label={t('configMaster:mandatoryFieldsError')} />
          </Box>
        )}
        <Box sx={{ margin: '44px 0 16px 0 ' }}>
          {showUpdateCartButton && (
            <PButton
              disabled={priceObject.length === 0}
              startIcon={<IconResolver iconName="RefreshOutlined" />}
              onClick={simulate}>
              {t('configMaster:updateBasket')}
            </PButton>
          )}
          {!showUpdateCartButton && (
            <PButton
              onClick={addToCartFn}
              disabled={priceObject.length === 0}>
              {t('configMaster:addToCart')}
            </PButton>
          )}
        </Box>
      </ContainerParts>
    </>
  );
};
export default ConfigMasterSummaryPart;
