import { Box, Grid, styled } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';
import { subSubcriptionEnum } from '../accountRelatedPersonsList/subscriptionList/SubSubcriptionEnum';
import { addSubuserToInterluctor, updateSubuser } from '../api/AcountRelatedPersonsApi';
import { upsertRelatedPersonsValidationMessagesKeys } from '../upsertRelatedPersons/UpsertRelatedPersonsValidationMessagesKeys';

const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '20px',
  gap: '16px',
}));
const SubscriptionSubpermission = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    interlocutorNumber: '',
    name: '',
    surname: '',
    phone: '',
    email: '',
    type: 1,
    status: true,
  });
  const [errors, setErrors] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
  });

  useEffect(() => {
    if (location?.state?.actionType === subSubcriptionEnum.create) {
      setFormData((prevState) => ({ ...prevState, interlocutorNumber: location.state.interlocutorNumber }));
    } else if (location?.state?.actionType === subSubcriptionEnum.update) {
      setFormData((prevState) => ({
        ...prevState,
        phone: `+${location.state.phone}`,
        interlocutorNumber: location.state.interlocutorNumber,
        email: location.state.email,
        name: location.state.firstName,
        type: location.state.interlocutorRole === 'Z1' ? 1 : 2,
        surname: location.state.lastName,
        status: location.state.deletionFlag,
      }));
    }
  }, []);

  const typeData = useMemo(() => {
    return [
      { key: 1, value: t('accountRelatedPersons:contractManager') },
      { key: 2, value: t('accountRelatedPersons:softwareCoordinator') },
    ];
  }, [t]);

  const onFormChangeHandler = (event) => {
    const { value, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onCheckboxChangeHandler = (event) => {
    const { checked, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: checked }));
  };

  const validateForm = () => {
    const rules = {
      name: 'required',
      surname: 'required',
      phoneNumber: 'required',
      email: 'required|email',
    };
    let phoneNumber = formData.phone.substring(1);
    phoneNumber = phoneNumber.replace(/\s/g, '');
    const copyData = { ...formData, phoneNumber };
    const validation = new Validator(copyData, rules, upsertRelatedPersonsValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        name: validation.errors.first('name'),
        surname: validation.errors.first('surname'),
        phone: validation.errors.first('phoneNumber'),
        email: validation.errors.first('email'),
      }));
    }
  };

  const onUpsertHandler = () => {
    if (validateForm()) {
      let phone = formData.phone.substring(1);
      phone = phone.replace(/\s/g, '');
      setIsLoading(true);
      if (location?.state?.actionType === subSubcriptionEnum.create) {
        addSubuserToInterluctor({
          interlocutorNumber: formData.interlocutorNumber,
          name: formData.name,
          surname: formData.surname,
          phone: phone,
          email: formData.email,
          status: formData.status,
          subUserType: formData.type,
        })
          .then((res) => {
            toast.success(res.data.message);
            navigate(`${paths.accounting}${paths.accountRelatedPersons}`);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      if (location?.state?.actionType === subSubcriptionEnum.update) {
        updateSubuser({
          interlocutorCode: formData.interlocutorNumber,
          name: formData.name,
          lastname: formData.surname,
          phone: phone,
          emailAddress: formData.email,
          status: formData.status,
        })
          .then((res) => {
            toast.success(res.data.message);
            navigate(`${paths.accounting}${paths.accountRelatedPersons}`);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  if (!location.state) {
    return <Navigate to={paths.home} />;
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <SecondaryLayout
        path={`${paths.accounting}${paths.accountRelatedPersons}`}
        title={t('accountRelatedPersons:subauthorizedUpsertForm')}>
        <ItemContainer>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={t('accountRelatedPersons:name')}
                placeholder={t('accountRelatedPersons:name')}
                onChange={onFormChangeHandler}
                name="name"
                value={formData.name}
                error={!!errors.name}
                errorMessage={t(errors.name, { field: t('accountRelatedPersons:name') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={t('accountRelatedPersons:surname')}
                placeholder={t('accountRelatedPersons:surname')}
                onChange={onFormChangeHandler}
                name="surname"
                value={formData.surname}
                error={!!errors.surname}
                errorMessage={t(errors.surname, { field: t('accountRelatedPersons:surname') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              {location?.state?.actionType === subSubcriptionEnum.update && (
                <PTextField
                  label={t('accountRelatedPersons:phone')}
                  onChange={onFormChangeHandler}
                  name="phone"
                  value={formData.phone}
                  error={!!errors.phone}
                  errorMessage={t(errors.phone, { field: t('accountRelatedPersons:phone') })}
                />
              )}
              {location?.state?.actionType === subSubcriptionEnum.create && (
                <TelInput
                  label={t('accountRelatedPersons:phone')}
                  value={formData.phone}
                  onChange={(value) => setFormData((prevState) => ({ ...prevState, phone: value }))}
                  error={!!errors.phone}
                  errorMessage={t(errors.phone, { field: t('accountRelatedPersons:phone') })}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={t('accountRelatedPersons:email')}
                placeholder={t('accountRelatedPersons:emailPlaceholder')}
                onChange={onFormChangeHandler}
                name="email"
                value={formData.email}
                error={!!errors.email}
                errorMessage={t(errors.email, { field: t('accountRelatedPersons:email') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PSelect
                label={t('accountRelatedPersons:type')}
                placeholder={t('accountRelatedPersons:typePlaceholder')}
                menuItems={typeData}
                onChange={onFormChangeHandler}
                name="type"
                value={formData.type}
                disabled={location?.state?.actionType === subSubcriptionEnum.update}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="d-flex align-items-center">
              <PCheckbox
                disabled={location?.state?.actionType === subSubcriptionEnum.create}
                checked={formData.status}
                onChange={onCheckboxChangeHandler}
                name="status"
                label={t('accountRelatedPersons:activeSubscriptionLicense')}
              />
            </Grid>
          </Grid>
          <Box className="d-flex justify-flex-end">
            <PButton
              width="auto"
              onClick={onUpsertHandler}>
              {t('accountRelatedPersons:savePerson')}
            </PButton>
          </Box>
        </ItemContainer>
      </SecondaryLayout>
    </>
  );
};
export default SubscriptionSubpermission;
