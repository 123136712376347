import PClientTable from 'components/table/PClientTable';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

const TransactionHistory = ({ row }) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        field: 'type',
        headerName: t('pay:process'),
        disableColumnMenu: true,
        width: 96,
      },
      {
        field: 'historyDate',
        headerName: t('pay:date'),
        disableColumnMenu: true,
        width: 140,
        renderCell: ({ row }) => dayjs(row.historyDate).format('DD.MM.YYYY'),
      },
      {
        field: 'explanation',
        headerName: t('pay:description'),
        disableColumnMenu: true,
        flex: 1,
      },
    ];
  }, []);

  return (
    <PClientTable
      headerTitle={t('pay:transactionHistory')}
      columns={columns}
      rows={row.historyList}
      getRowId={() => uuidv4()}
    />
  );
};
export default TransactionHistory;
