import { Box, Typography } from '@mui/material';
import PCenterModal from 'components/modal/PCenterModal';
import { useTranslation } from 'react-i18next';

const ProductInfoModal = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <PCenterModal
      showClose
      width="632px"
      title={t('integration:productServices')}
      onClose={onClose}>
      <Box>
        <ol>
          <li>
            <Typography variant="titleMedium">GetProduct </Typography>
            <div>
              <Typography variant="bodyMedium">{t('integration:productInfoFirstItem')}</Typography>
            </div>
          </li>
        </ol>
      </Box>
    </PCenterModal>
  );
};

export default ProductInfoModal;
