import { Accordion, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import React, { useEffect, useState } from 'react';

const CustomAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  border: `1px solid ${primaryColors.primary200}`,
  minHeight: '76px',
  margin: '0 !important',
  '&:before': {
    opacity: 0,
  },
}));

const ConfigMasterAccordion = ({
  children,
  selectedParts,
  componentGroups,
  justShowInStock,
  selectedConfigs,
  setShowUpdateCartButton,
  setExpandedAccordionNameAndIds,
}) => {
  const [expanded, setExpanded] = useState(false);

  const onHandleExpanded = () => {
    if (componentGroups?.components.length > 0) {
      setExpandedAccordionNameAndIds((prev) => {
        const isExpanded = expanded;
        if (isExpanded) {
          return prev.filter((item) => item.key !== componentGroups.componentGroupType);
        } else {
          setShowUpdateCartButton(true);
          return [
            ...prev,
            {
              key: componentGroups.componentGroupType,
              ids: componentGroups.components.map((item) => item.id),
            },
          ];
        }
      });
      setExpanded((prev) => !prev);
    }
  };

  useEffect(() => {
    setExpanded(false);
  }, [justShowInStock, selectedParts, selectedConfigs]);

  return (
    <CustomAccordion
      slotProps={{ transition: { mountOnEnter: true, unmountOnExit: true } }}
      expanded={expanded}
      onChange={onHandleExpanded}>
      {children}
    </CustomAccordion>
  );
};
export default React.memo(ConfigMasterAccordion);
