import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import LandingPage from '../LandingPage';

export const landingPageRoutes = {
  path: `${paths.protectedHome}`,
  element: (
    <ProtectedRoute>
      <ChildLayout>
        <LandingPage />
      </ChildLayout>
    </ProtectedRoute>
  ),
};
