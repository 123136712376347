import { paths } from 'constants/Paths';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import MissionVision from '../MissionVision';

export const missionVisionRoutes = {
  path: `${paths.account}${paths.missionVision}`,
  element: (
    <LoginLayout>
      <MissionVision />
    </LoginLayout>
  ),
};
