import { Box, Grid, styled } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { getCities } from 'pages/deliveryAddress/api/DeliveryAddressApi';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addZeroToNumber } from 'utils/HelperFunctions';
import Validator from 'validatorjs';
import { createInterluctor, getActiveUsers, updateInterluctor } from '../api/AcountRelatedPersonsApi';
import { upsertRelatedPersonsValidationMessagesKeys } from '../upsertRelatedPersons/UpsertRelatedPersonsValidationMessagesKeys';

const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '20px',
  gap: '16px',
}));
const SubscriptionPermission = () => {
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [authorizedData, setAuthorizedData] = useState([]);
  const [formData, setFormData] = useState({
    company: '',
    address: '',
    email: '',
    city: '',
    postCode: '',
    authorized: 'everybody',
    phone: '',
    visibleTo: false,
    district: '',
    activePassive: true,
  });
  const [errors, setErrors] = useState({
    company: '',
    address: '',
    city: '',
    district: '',
    email: '',
    phone: '',
  });

  const visibleToData = useMemo(() => {
    return [
      { key: true, value: t('accountRelatedPersons:onlyMyCustomerRepresentative') },
      { key: false, value: t('accountRelatedPersons:everybody') },
    ];
  }, [t]);

  const getSelectData = async () => {
    setIsLoading(true);
    try {
      const activeUser = await getActiveUsers();
      const copy = [...authorizedData];
      copy.push({ key: 'everybody', value: t('accountRelatedPersons:everybody') });
      activeUser.data.data.forEach((item) => {
        copy.push({ key: item.email, value: item.email });
      });
      setAuthorizedData(copy);
      const cities = await getCities();
      setCityData(cities.data.data.map((city) => ({ key: addZeroToNumber(city.cityCode), value: city.cityName })));
      if (location.state) {
        setFormData((prevState) => ({
          ...prevState,
          company: location.state.interlocutorName,
          address: location.state.address,
          email: location.state.emailAddress,
          city: location.state.cityCode,
          postCode: location.state.postalCode,
          phone: `+${location.state.phone}`,
          district: location.state.district,
          visibleTo: location.state.onlyMTVisible ? true : false,
          activePassive: location.state.deletionFlag,
          authorized: location.state.authorizedB2BUser ? location.state.authorizedB2BUser : 'everybody',
        }));
      }
    } catch (err) {
      toast.error(err.response.data.Message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSelectData();
  }, []);

  const onFormChangeHandler = (event) => {
    const { value, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onCheckboxChangeHandler = (event) => {
    const { checked, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: checked }));
  };
  const validateForm = () => {
    const rules = {
      company: 'required',
      address: 'required',
      city: 'required',
      district: 'required',
      email: 'email',
      postCode: ['regex:/^\\d{5}$/'],
      phone: 'required',
    };
    let phone = formData.phone.substring(1);
    phone = phone.replace(/\s/g, '');
    const validation = new Validator({ ...formData, phone }, rules, upsertRelatedPersonsValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        company: validation.errors.first('company'),
        address: validation.errors.first('address'),
        city: validation.errors.first('city'),
        district: validation.errors.first('district'),
        email: validation.errors.first('email'),
        postCode: validation.errors.first('postCode'),
        phone: validation.errors.first('phone'),
      }));
    }
  };

  const onUpsertHandler = () => {
    if (validateForm()) {
      setIsLoading(true);
      let phone = formData.phone.substring(1);
      phone = phone.replace(/\s/g, '');
      if (location.state) {
        updateInterluctor({
          interlocutorCode: location.state.interlocutorNumber,
          name: formData.company,
          address: formData.address,
          district: formData.district,
          city: formData.city,
          phone: phone,
          emailAddress: formData.email,
          postCode: formData.postCode,
          authorizedB2bUserMailAddress: formData.authorized === 'everybody' ? '' : formData.authorized,
          isVisibleToCustomerRepresentativeOnly: formData.visibleTo,
          deletionFlag: formData.activePassive,
        })
          .then((res) => {
            toast.success(res.data.message);
            navigate(`${paths.accounting}${paths.accountRelatedPersons}`);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        createInterluctor({
          name: formData.company,
          address: formData.address,
          district: formData.district,
          city: formData.city,
          phone: phone,
          emailAddress: formData.email,
          postCode: formData.postCode,
          authorizedB2bUserMailAddress: formData.authorized === 'everybody' ? '' : formData.authorized,
          isVisibleToCustomerRepresentativeOnly: formData.visibleTo,
        })
          .then((res) => {
            toast.success(res.data.message);
            navigate(`${paths.accounting}${paths.accountRelatedPersons}`);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <SecondaryLayout
        path={`${paths.accounting}${paths.accountRelatedPersons}`}
        title={t('accountRelatedPersons:authorizedUpsertForm')}>
        <ItemContainer>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}
              lg={12}>
              <PTextField
                label={t('accountRelatedPersons:companyTitle')}
                placeholder={t('accountRelatedPersons:company')}
                onChange={onFormChangeHandler}
                name="company"
                value={formData.company}
                error={!!errors.company}
                errorMessage={t(errors.company, { field: t('accountRelatedPersons:companyTitle') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}>
              <PTextField
                label={t('accountRelatedPersons:address')}
                placeholder={t('accountRelatedPersons:addressPlaceholder')}
                onChange={onFormChangeHandler}
                name="address"
                value={formData.address}
                error={!!errors.address}
                errorMessage={t(errors.address, { field: t('accountRelatedPersons:address') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={t('accountRelatedPersons:email')}
                placeholder={t('accountRelatedPersons:emailPlaceholder')}
                onChange={onFormChangeHandler}
                name="email"
                value={formData.email}
                error={!!errors.email}
                errorMessage={t(errors.email, { field: t('accountRelatedPersons:email') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PSelect
                label={t('accountRelatedPersons:city')}
                placeholder={t('accountRelatedPersons:cityPlaceholder')}
                menuItems={cityData}
                onChange={onFormChangeHandler}
                name="city"
                value={formData.city}
                error={!!errors.city}
                errorMessage={t(errors.city, { field: t('accountRelatedPersons:city') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={`${t('accountRelatedPersons:postCode')} (${t('accountRelatedPersons:optional')})`}
                placeholder={t('accountRelatedPersons:postCodePlaceholder')}
                onChange={onFormChangeHandler}
                name="postCode"
                value={formData.postCode}
                error={!!errors.postCode}
                errorMessage={t('deliveryAddress:regexFailed')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PSelect
                label={t('accountRelatedPersons:authorizedInCompany')}
                placeholder={t('accountRelatedPersons:selectAuthorized')}
                menuItems={authorizedData}
                onChange={onFormChangeHandler}
                name="authorized"
                value={formData.authorized}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              {!location.state && (
                <TelInput
                  label={t('accountRelatedPersons:phone')}
                  value={formData.phone}
                  onChange={(value) => {
                    setFormData((prevState) => ({ ...prevState, phone: value }));
                    setErrors((prevState) => ({ ...prevState, phone: '' }));
                  }}
                  error={!!errors.phone}
                  errorMessage={t(errors.phone, { field: t('accountRelatedPersons:phone') })}
                />
              )}
              {location.state && (
                <PTextField
                  label={t('accountRelatedPersons:phone')}
                  onChange={onFormChangeHandler}
                  name="phone"
                  value={formData.phone}
                  error={!!errors.phone}
                  errorMessage={t(errors.phone, { field: t('accountRelatedPersons:phone') })}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PSelect
                label={t('accountRelatedPersons:whoShouldSeeInPenta')}
                placeholder={t('accountRelatedPersons:selectPersonPlaceholder')}
                menuItems={visibleToData}
                onChange={onFormChangeHandler}
                name="visibleTo"
                value={formData.visibleTo}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={t('accountRelatedPersons:district')}
                placeholder={t('accountRelatedPersons:districtPlaceholder')}
                onChange={onFormChangeHandler}
                name="district"
                value={formData.district}
                error={!!errors.district}
                errorMessage={t(errors.district, { field: t('accountRelatedPersons:district') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="d-flex align-items-center">
              <PCheckbox
                disabled={!location.state}
                checked={formData.activePassive}
                onChange={onCheckboxChangeHandler}
                name="activePassive"
                label={t('accountRelatedPersons:activeSubscriptionLicense')}
              />
            </Grid>
          </Grid>
          {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
            <Box className="d-flex justify-flex-end">
              <PButton
                width="auto"
                onClick={onUpsertHandler}>
                {t('accountRelatedPersons:savePerson')}
              </PButton>
            </Box>
          )}
        </ItemContainer>
      </SecondaryLayout>
    </>
  );
};
export default SubscriptionPermission;
