import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import PTextField from 'components/textField/PTextField';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

const TableSearchTextField = ({
  onChange,
  placeholder,
  sx,
  name,
  value = '',
  label = '',
  width = 'auto',
  showLabel = false,
  showHelperContainer = false,
  debounceTime = 350,
}) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debouncedChangeHandler = useCallback(
    debounce((event) => {
      onChange(event);
    }, debounceTime),
    [],
  );

  useEffect(() => {
    setDebouncedValue(value);
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setDebouncedValue(newValue);
    debouncedChangeHandler(event);
  };

  return (
    <PTextField
      showLabel={showLabel}
      showHelperContainer={showHelperContainer}
      name={name}
      label={label}
      value={debouncedValue}
      onChange={handleChange}
      placeholder={placeholder}
      startIcon={<SearchOutlinedIcon />}
      sx={sx}
      width={width}
    />
  );
};

export default TableSearchTextField;
