export const MyShipmentsTr = {
  excelPrintout: 'Excel Çıktısı Al',
  searchByCustomerNumber: 'Müşteri sipariş numarası ile ara...',
  list: 'Listele',
  selectStartingDate: 'Başlangıç tarihi seçin',
  selectEndDate: 'Bitiş tarihi seçin',
  date: 'Tarih',
  shipmentNumber: 'Sevkiyat No',
  shippingCompany: 'Nakliye Firması',
  shipmentType: 'Sevkiyat Türü',
  buyer: 'Alıcı',
  status: 'Durumu',
  deliveryInformation: 'Teslim Bilgisi',
  volume: 'Hacim',
  transportationCost: 'Taşıma Bedeli',
  customerOrderNumber: 'Müşteri Sipariş No',
  cargoTracking: 'Kargo Takip',
  noResultsFound: 'Sonuç Bulunamadı',
  productNo: 'Ürün No',
  productName: 'Ürün Adı',
  referenceDocument: 'Referans Belge',
  referencePen: 'Referans Kalem',
  amount: 'Miktar',
  piece: 'Adet',
  // eslint-disable-next-line quotes
  shipmentProductsNo: "{{number}} No'lu Sevkiyat Ürünleri",
  serialNo: 'Seri No',
  referenceRegion: 'Referans Bölge',
  header: 'Başlık',
  entryNo: 'Kalem',
  serialNumberList: 'Seri Numarası Listesi',
  shipmentReferenceNo: 'Sevkiyat Kalem No',
  doSearch: 'Arama yap...',
  emptyComponentTitle: 'Tarih aralığı seçin veya sipariş numarası girin',
  emptyComponentSubTitle: 'Tarih aralığı seçerek veya sipariş numarası girerek sevkiyatlara ulaşabilirsiniz.',
};
