import { Box } from '@mui/material';
import PTextField from 'components/textField/PTextField';
import { useTranslation } from 'react-i18next';

const PayLeftPart = ({ formData, inputChangeHandler }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <PTextField
          placeholder={t('pay:orderDescriptionPlaceholder')}
          value={formData.orderDescription}
          label={t('pay:orderDescription')}
          name="orderDescription"
          onChange={inputChangeHandler}
        />
      </Box>
      <Box>
        <PTextField
          placeholder={t('pay:invoiceDescriptionPlaceholder')}
          value={formData.invoiceDescription}
          label={t('pay:invoiceDescription')}
          name="invoiceDescription"
          onChange={inputChangeHandler}
        />
      </Box>
      <Box>
        <PTextField
          placeholder={t('pay:deliveryNoteDescriptionPlaceholder')}
          value={formData.deliveryNoteDescription}
          label={t('pay:deliveryNoteDescription')}
          name="deliveryNoteDescription"
          onChange={inputChangeHandler}
        />
      </Box>
    </>
  );
};

export default PayLeftPart;
