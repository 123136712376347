/* eslint-disable max-len */
export const PayTr = {
  pentaSubscriptionManagement: 'Penta Abonelik Yönetimi',
  manageYourOwnAndEnUsersSubscriptions: 'Kendinizin ve son kullanıcıların aboneliklerini yönetin',
  subscriptions: 'Abonelikler',
  subscriptionCart: 'Abonelik Sepeti',
  endUserReports: 'Son Kullanıcı Raporları',
  subscriptionDetail: 'Abonelik Detayı',
  lastNthDays: 'Son {{day}} Gün',
  all: 'Hepsi',
  manuel: 'Manuel',
  automatic: 'Otomatik',
  activeOnes: 'Aktif Olanlar',
  showByProducts: 'Ürünlere göre göster',
  showBySubscriptions: 'Aboneliklere göre göster',
  renewalDates: 'Yenileme Tarihleri',
  startDateLabel: 'Başlangıç',
  endDateLabel: 'Bitiş',
  renewalType: 'Yenileme Tipi',
  brand: 'Marka',
  searchPlaceholder: 'Arama yap...',
  endUser: 'Son Kullanıcı',
  subscriberNo: 'Penta Abone No',
  detail: 'Detay',
  contractNo: 'Kontrat No',
  status: 'Durum',
  list: 'Listele',
  enterSubscriberNo: 'Abone no girin',
  enterContractNo: 'Kontrat no girin',
  productsList: 'Ürünler Listesi',
  piece: 'Adet',
  product: 'Ürün',
  renewalDate: 'Yenileme Tarihi',
  selectSubscriptionDetails: 'Abonelik detaylarını seçin',
  youCanListByEnteringSubscriptionDetails: 'Abonelik detaylarını girerek listeleyebilirsiniz.',
  subscriptionList: 'Abonelik Listesi',
  subscription: 'Abonelik',
  welcomeMessage: 'Penta Abonelik Yönetimi’ne Hoş Geldiniz!',
  introductionFirstParagraph:
    'Penta Abonelik Yönetimi, Penta’dan satın alınmış olan abonelik ürünlerinin, durumlarının kontrol edilebildiği, yenileme dönemi geldiğinde ürün yenileme işlemlerinin yapılabildiği ve aynı zamanda ilgili abonelik içerisine yeni ürün ekleme işlemlerinin yapılabildiği platformdur.',
  firstTitle: 'Penta Abonelik Yönetimi ekranlarını kimler kullanabilir?',
  secondTitle: 'Penta Abonelik Yönetimi ekranlarında abonelikleri filtreleme nasıl yapılır?',
  thirdTitle: 'Penta Abonelik Yönetimi ekranlarında kontrat yenileme işlemi nasıl yapılır?',
  firstTitleFirstParagraph: 'Abonelik ürünlerini satmak isteyen tüm bayilerin kullanımına uygundur.',
  searchByManufacturerContractNumber: 'Üretici Kontrat Numarası İle Arama',
  secondTitleFirstParagraph:
    ' “Aboneliklerim” sekmesine girip “Kontrat No” alanına kontrat numarasını(VIP numarası) yazıp, “Durum” alanında Hepsi seçeneğini işaretledikten sonra arama yapılır ve girilen bilgilere uygun kayıtlar listelenir.',
  searchByPentaSubscriptionNumber: 'Penta Abonelik Numarası İle Arama',
  secondTitleSecondParagraph:
    ' Aboneliklerim” sekmesine girip “Abone No” alanına abone numarasını yazıp, “Durum” alanında Hepsi seçeneğini işaretledikten sonra arama yapılır ve girilen bilgilere uygun kayıtlar listelenir.',
  thirdTitleFirstParagraph:
    ' Kontratı bulduktan sonra aşağıdaki ekran görüntüsünde yer aldığı gibi “İşlemler” sekmesine tıklayıp “Yenile” butonuna basılır.',
  thirdTitleSecondParagraph: 'Ürün adedini belirledikten sonra yenileme yapılacak ürün seçilip “Ekle” butonuna tıklanır.',
  thirdTitleThirdParagraph: 'Abonelik sepeti sekmesine gidilir ve sipariş ver butonuna tıklayarak sipariş tamamlanır.',
  reportView: 'Rapor Görüntüleme',
  addNewReport: 'Yeni Rapor',
  endCustomerCsnNo: 'End Customer CSN',
  endCustomerName: 'End Customer Name',
  report: 'Rapor',
  refresh: 'Yenile',
  create: 'Oluştur',
  createUserReport: 'Son Kullanıcı Raporu Oluştur',
  endUserList: 'Son Kullanıcı Listesi',
  resellerCSN: 'Reseller CSN',
  insightMetrics: 'Insight Metrics',
  usageReports: 'Usage Reports',
  successful: 'Başarılı',
  date: 'Tarih',
  requestMailAddress: 'Talebi Oluşturan',
  resellerCsnNo: 'Reseller CSN',
  getInsightStatus: 'Insight Metrics',
  getUsageStatus: 'Usage Reports',
  noRecordFound: 'Kayıt bulunamadı',
  productList: 'Ürün Listesi',
  refreshType: 'Yenileme Tipi',
  startDate: 'Başlangıç Tarihi',
  endDate: 'Sonlanma Tarihi',
  notSelect: 'Seçim Yapılmadı',
  selectedWaitingAutodesk: 'Autodesk servisinden yanıt bekleniyor',
  completed: 'Tamamlandı',
  newReportSuccessMessage: 'Rapor talebi başarıyla işlendi.',
  manage: 'Yönet',
  addProduct: 'Ürün Ekle',
  cancel: 'İptal Et',
  stop: 'Durdur',
  change: 'Adet Değiştir',
  manualRenewal: 'Manuel Yenileme',
  automaticRenewal: 'Otomatik Yenileme',
  updateCart: 'Sepeti Güncelle',
  subscriptionProducts: 'Abonelik Ürünleri',
  process: 'İşlem',
  projectNo: 'Proje No',
  shipmentType: 'Sevkiyat Türü',
  maturity: 'Vade',
  unitPrice: 'Birim Fiyat',
  grandTotal: 'Genel Toplam',
  insufficientStock: 'Yetersiz Stok',
  licenseProducts: 'Lisans Ürünleri',
  individual: 'Bireysel',
  common: 'Ortak',
  orderSummary: 'Sipariş Özeti',
  subscriptionProductsLower: 'Abonelik ürünleri',
  licenseProductsLower: 'Lisans ürünleri',
  eArchive: 'E-Arşiv',
  invoicesWillBeSentToYourDefinedEMailAddresses: 'e-arşiv faturalarınız tanımlı e-posta adreslerinize gönderilecektir.',
  getAnOffer: 'Teklif Al',
  order: 'Sipariş Ver',
  availableCreditLimit: 'Kullanılabilir kredi limiti',
  orderDetails: 'Sipariş Detayları',
  informationAndExplanations: 'Bilgilendirme ve Açıklamalar',
  enterInformationAndDescriptions: 'Bilgilendirmeleri ve açıklamaları girin',
  orderDescription: 'Sipariş Açıklaması',
  orderDescriptionPlaceholder: 'Sipariş açıklaması',
  invoiceDescription: 'Fatura Açıklaması',
  invoiceDescriptionPlaceholder: 'Fatura açıklaması',
  deliveryNoteDescription: 'İrsaliye Açıklaması',
  deliveryNoteDescriptionPlaceholder: 'İrsaliye açıklaması',
  paymentOptions: 'Ödeme Seçenekleri',
  chooseShippingOptionsAndNotificationMethods: 'Sevkiyat seçeneklerini ve bilgilendirme yöntemlerini belirleyin',
  openAccount: 'Açık hesap',
  bankTransfer: 'Banka transfer',
  onlinePayment: 'Online ödeme',
  pleaseSelectPaymentType: 'Lütfen ödeme yöntemi seçin.',
  orderCreatedSuccessfully: 'Sipariş başarıyla oluşturuldu!',
  youCanViewOrderDetailsInTheTableBelow: 'Sipariş detaylarını aşağıdaki tablodan görüntüleyebilirsiniz.',
  orderItems: 'Sipariş Kalemleri',
  itemNumber: 'Kalem No',
  productName: 'Ürün Adı',
  taxIncludedPrice: 'KDV Dahil Toplam',
  transactionResult: 'İşlem Sonucu',
  couldNotCreateOrder: 'Sipariş oluşturulamadı',
  document: 'Doküman',
  contactInformation: 'İletişim Bilgileri',
  productHistoryNoParams: ' {{number}} No’lu Ürün Geçmişi',
  billingHistory: 'Faturalama Geçmişi',
  transactionHistory: 'İşlem Geçmişi',
  documentNo: 'Belge No',
  billingDocument: 'Faturalama Belgesi',
  quantity: 'Miktar',
  amount: 'Tutar',
  description: 'Açıklama',
  addingSubscriptionProduct: 'Abonelik Ürün Ekleme',
  productWillBeAddedToYourSubscription: 'Aboneliğinize ürün eklenecektir.',
  productCodeOrName: 'Ürün Kodu veya Adı',
  productQuantity: 'Ürün Adeti',
  add: 'Ekle',
  productContractRenewal: 'Ürün Kontrat Yenileme',
  selectTheProductsYouWantToRenew: 'Yenilemek istediğiniz ürünleri seçiniz.',
  productContractTermination: 'Ürün Kontrat Sonlandırma',
  theContractWillBeTerminatedForThisProduct: 'Bu ürün için kontrat sonlandırma işlemi yapılacaktır. Kontratı sonlandırmak istiyor musunuz?',
  cancelSinlgeWord: 'İptal',
  terminate: 'Sonlandır',
  itemContractTermination: 'Ürün Sözleşme Sonlandırma',
  theContractWillBeTerminatedForThisItem: 'Bu ürün için sözleşme sonlandırma işlemi yapılacaktır. Sözleşmeyi sonlandırmak istiyor musunuz?',
  changeContractProductQuantity: 'Kontrat Ürün Adedi Değiştir',
  productNoAndName: 'Ürün No ve Adı',
  thisProductCurrentlyAppearsToBeNumberUnits:
    'Bu ürün şu anda {{number}} adet olarak gözükmektedir.<br/>Ürün arttırımı yaptığınızda sepete yönlendirileceksiniz.<br/>Azaltma işlemi yapmak isterseniz direk bu ekranda uygulanacaktır.',
  startingDate: 'Başlama Tarihi',
  requestForOffer: 'Teklif Talebi',
  selectRequestOptions: 'Talep seçeneklerini belirleyin',
  getAnOfferInfoAlertLabel:
    'İşleminiz gerçekleştikten sonra, seçmiş olduğunuz ürünler ile ilgili talebi, fiyat talebi listesinden takip edebilirsiniz.',
  createQuickRequest: 'Hızlı Talep Oluştur',
  createDetailedRequest: 'Detaylı Talep Oluştur',
  requestName: 'Talep Adı',
  clearShippedItemsFromCart: 'Gönderilen ürünleri sepetten temizle',
  endingDate: 'Bitiş Tarihi',
  optional: 'Opsiyonel',
  username: 'Kullanıcı Adı',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  MaxLength: '{{field}} alanı maksimum {{char}} karakterdir.',
  DateFormat: '{{field}} alanı tarih değildir.',
  note: 'Not',
  itemUpdate: 'Kalem Güncelleme',
  itemNote: 'Ürün Notu',
  update: 'Güncelle',
  singleCancel: 'İptal',
  approveDecreseModalBodyText: 'Bu abonelik için sözleşme değiştirme işlemi yapılacaktır. Sözleşmeyi değiştirmek istiyor musunuz?',
  clickToDefineLicenseFormInformation: 'Lisans form bilgilerini tanımlamak için tıklayın',
  doYouConfirmYourOrder: 'Siparişinizi onaylıyor musunuz?',
  save: 'Kaydet',
  dataInformationIsRequired: '{{field}} bilgisi zorunludur.',
  productLicenseInformationNo: ' {{number}} No’lu Ürün Lisans Bilgileri',
  saveSimilarFormsWithTheSameInformation: 'Benzer formları da aynı bilgiler ile kaydet',
  transactionCreatedSuccessfully: 'İşlem başarıyla oluşturuldu',
  youanFollowYourTransactionFromThePriceRequestListWithTheRelevantProjectNumberInformation:
    'İşleminizi fiyat talebi listesinden, ilgili proje no bilgisi ile takip edebilirsiniz.',
  goToQuoteList: 'Fiyat Talebi Listesine Git',
  subscriptionDetailWithoutI: 'Abonelik Detay',
  subscriptionDetailList: 'Abonelik Detay Listesi',
  subscriptionActions: 'Abonelik İşlemleri',
  productActions: 'Ürün İşlemleri',
  subscriptionAdd: 'Sözleşmenize {{number}} adet abonelik eklenecektir.',
  subscriptionDelete: 'Sözleşmenizden {{number}} adet abonelik silinecektir.',
  clear: 'Temizle',
  productHistory: 'Geçmişi',
  subscriptionDocuments: 'Dökümanlar',
};
