import { paths } from 'constants/Paths';
import MainLayout from 'layout/mainLayout/MainLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import MyContractsList from '../MyContractsList';

export const myContractsListRoutes = {
  path: `${paths.accounting}${paths.myContracts}`,
  element: (
    <ProtectedRoute>
      <MainLayout>
        <MyContractsList />
      </MainLayout>
    </ProtectedRoute>
  ),
};
