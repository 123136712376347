import { Box, styled, Typography } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import PDivider from 'components/Divider/PDivider';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PRadio from 'components/radio/PRadio';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { createAnOffer, getActiveCartItems } from 'pages/pay/api/PayApi';
import { payTabNumbers } from 'pages/pay/constants/PayTabNumbers';
import DetailRequest from 'pages/pay/pages/getAnOffer/components/DetailRequest';
import FastRequest from 'pages/pay/pages/getAnOffer/components/FastRequest';
import { createAnOfferValidationMessagesKeys } from 'pages/pay/pages/getAnOffer/constants/CreateAnOfferValidationMessagesKeys';
import { requestTypeEnums } from 'pages/pay/pages/getAnOffer/constants/RequestTypeEnums';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';

const MainContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingBottom: '24px',
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 16px 0 16px',
}));

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 16px',
}));

const RadioContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > *': {
      width: '100% !important',
    },
  },
}));

const RadioItemContainer = styled(Box)(({ selected }) => ({
  border: `1px solid ${primaryColors.primary200}`,
  paddingLeft: '12px',
  borderRadius: '6px',
  minWidth: '242px',
  backgroundColor: selected ? primaryColors.primary50 : 'transparent',
}));

const PayGetAnOffer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [productItems, setProductItems] = useState([]);
  const [backupItem, setBackupItem] = useState([]);
  const [formData, setFormData] = useState({
    selectedRadio: requestTypeEnums.fastRequest,
    fastRequestName: '',
    detailRequestName: '',
    startDate: dayjs(),
    endDate: dayjs().add(15, 'days'),
    clearBasket: true,
    username: '',
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setLoading(true);
    getActiveCartItems(location?.state?.activeBasketId)
      .then((res) => {
        const incomingDatas = res.data.data;
        const foundOfferData = incomingDatas.find((incomingData) => incomingData.cartGroup === location.state.selectedRadio);
        const allProducts = foundOfferData.byGroups.flatMap((group) => group.products);
        const updatedAllProducts = allProducts.map((item) => {
          if (item.licences && item.licences.length > 0) {
            const entryNumberWeb = item.licences[0].entryNumberWeb;
            return { ...item, entryNumberWeb, productName: item.productDefinition, productDefinition: '' };
          }
          return { ...item, productName: item.productDefinition, productDefinition: '' };
        });
        setProductItems([...updatedAllProducts]);
        setBackupItem([...updatedAllProducts]);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!location.state) {
    return <Navigate to={paths.home} />;
  }

  const onRadioChange = (event) => {
    const { name } = event.target;
    setFormData((prevState) => ({ ...prevState, selectedRadio: name }));
  };

  const onInputChange = (event) => {
    const { value, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onCheckboxChange = (event) => {
    const { checked, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: checked }));
  };

  const validateForm = () => {
    const rules = {};

    if (formData.selectedRadio === requestTypeEnums.fastRequest) {
      rules.fastRequestName = 'required';
    }

    if (formData.selectedRadio === requestTypeEnums.detailRequest) {
      rules.username = 'max:100';
      rules.startDate = 'required|date';
      rules.endDate = 'required|date';
      rules.detailRequestName = 'required';
    }
    const validation = new Validator({ ...formData }, rules, createAnOfferValidationMessagesKeys);

    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        fastRequestName: validation.errors.first('fastRequestName'),
        username: validation.errors.first('username'),
        endDate: validation.errors.first('endDate'),
        startDate: validation.errors.first('startDate'),
        detailRequestName: validation.errors.first('detailRequestName'),
      }));
    }
  };

  const createOffer = () => {
    if (validateForm()) {
      const sendData = {};

      if (formData.selectedRadio === requestTypeEnums.fastRequest) {
        sendData.description = formData.fastRequestName;
        sendData.cleanBasket = formData.clearBasket;
        sendData.startDate = dayjs();
        sendData.endDate = dayjs().add(15, 'days');
        sendData.lastUser = null;
        sendData.projectItems = backupItem.map((item) => ({
          cartItemId: item.cartItemId.toString(),
          productNumber: item.productNumber,
          quantity: item.quantity,
          netAmount: item.netAmount,
          currencyCode: item.salesAndDistributionDocumentCurrency,
          productDefinition: item.productDefinition,
          type: item.type.toString(),
          entryNumberWeb: item.entryNumberWeb,
          subscriptionId: item.subscriptionId,
          penNumber: item.penNumber,
        }));
        const array = [];
        backupItem.forEach((backup) => {
          backup.licences.forEach((item) => {
            array.push(item);
          });
        });
        sendData.licenceField = array;
      } else {
        sendData.description = formData.detailRequestName;
        sendData.startDate = dayjs(formData.startDate);
        sendData.endDate = dayjs(formData.endDate);
        sendData.lastUser = formData.username;
        sendData.cleanBasket = formData.clearBasket;
        sendData.projectItems = productItems.map((productItem) => ({
          cartItemId: productItem.cartItemId.toString(),
          productNumber: productItem.productNumber,
          quantity: productItem.quantity,
          netAmount: productItem.netAmountAsDocument,
          currencyCode: productItem.salesAndDistributionDocumentCurrency,
          productDefinition: productItem.productDefinition,
          type: productItem.type.toString(),
          entryNumberWeb: productItem.entryNumberWeb,
          subscriptionId: productItem.subscriptionId,
          penNumber: productItem.penNumber,
        }));

        const array = [];
        productItems.forEach((productItem) => {
          productItem.licences.forEach((item) => {
            array.push(item);
          });
        });
        sendData.licenceField = array;
      }

      setLoading(true);
      createAnOffer(sendData)
        .then((res) => {
          navigate(`${paths.subscriptions}${paths.getAnofferResult}`, { state: res.data.data });
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const goToCart = () => {
    navigate(`${paths.subscriptions}${paths.byProducts}`, { state: payTabNumbers.subscriptionCart });
  };

  return (
    <SecondaryLayout
      title={t('pay:getAnOffer')}
      onClick={goToCart}>
      {loading && <LoadingSpinner />}
      <MainContainer>
        <Header>
          <Typography variant="titleMedium">{t('pay:requestForOffer')}</Typography>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary700}>
            {t('pay:selectRequestOptions')}
          </Typography>
        </Header>
        <PDivider marginBottom={0} />
        <Content>
          {formData.selectedRadio === requestTypeEnums.fastRequest && <InfoAlert label={t('pay:getAnOfferInfoAlertLabel')} />}
          <RadioContainer>
            <RadioItemContainer selected={formData.selectedRadio === requestTypeEnums.fastRequest}>
              <PRadio
                label={t('pay:createQuickRequest')}
                checked={formData.selectedRadio === requestTypeEnums.fastRequest}
                onChange={onRadioChange}
                name="fastRequest"
              />
            </RadioItemContainer>
            <RadioItemContainer selected={formData.selectedRadio === requestTypeEnums.detailRequest}>
              <PRadio
                label={t('pay:createDetailedRequest')}
                checked={formData.selectedRadio === requestTypeEnums.detailRequest}
                onChange={onRadioChange}
                name="detailRequest"
              />
            </RadioItemContainer>
          </RadioContainer>
          <Box>
            {formData.selectedRadio === requestTypeEnums.fastRequest && (
              <FastRequest
                formData={formData}
                onInputChange={onInputChange}
                onCheckboxChange={onCheckboxChange}
                errors={errors}
              />
            )}
            {formData.selectedRadio === requestTypeEnums.detailRequest && (
              <DetailRequest
                setProductItems={setProductItems}
                productItems={productItems}
                formData={formData}
                onInputChange={onInputChange}
                setFormData={setFormData}
                errors={errors}
              />
            )}
          </Box>
          <Box className="d-flex justify-flex-end">
            <PButton
              width="auto"
              onClick={createOffer}>
              {t('pay:create')}
            </PButton>
          </Box>
        </Content>
      </MainContainer>
    </SecondaryLayout>
  );
};

export default PayGetAnOffer;
