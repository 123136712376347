import { styled } from '@mui/material';
import { blueColors } from 'config/theme/Colors';
import PChip from './PChip';

const CustomChip = styled(PChip)(({ fontSize }) => ({
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '24px',
  padding: '2px, 8px',
  height: '20px',
  color: blueColors.blue700,
  backgroundColor: blueColors.blue100,
  border: `1px solid ${blueColors.blue300}`,
  '& .MuiChip-label': {
    fontSize: fontSize,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
}));

const PInfoChip = ({ label, fontSize = '14px', variant = 'filled', ...rest }) => {
  return (
    <CustomChip
      variant={variant}
      label={label}
      fontSize={fontSize}
      {...rest}
    />
  );
};

export default PInfoChip;
