import { InputLabel, styled, Typography } from '@mui/material';
import { CustomFormControl, HelperContainer, HelperText } from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import { MuiTelInput } from 'mui-tel-input';

const CustomTelInput = styled(MuiTelInput)(({ value }) => ({
  backgroundColor: '#ffffff',
  height: '42px',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '& .MuiInputBase-root': {
    borderRadius: '8px',
    border: `1px solid ${primaryColors.primary400}`,
    fontSize: 16,
    width: '100%',
    color: primaryColors.primary,
    padding: '8px 12px',
    '& input:-internal-autofill-selected': {
      backgroundColor: 'transparent',
    },
  },
  '& .Mui-focused': {
    border: `1px solid ${primaryColors.primary700}`,
  },
  '& .Mui-disabled': {
    backgroundColor: primaryColors.primary100,
    WebkitTextFillColor: `${primaryColors.primary600} !important`,
  },
  '.MuiOutlinedInput-notchedOutline ': {
    display: 'none',
  },
  '& .MuiInputBase-input': {
    padding: 0,
    width: '100%',
  },

  '& input:-internal-autofill-selected': {
    WebkitBoxShadow: '0 0 0 50px white inset',
  },
}));

const CustomInputLabel = styled(InputLabel)(({ visibility }) => ({
  position: 'relative',
  transform: 'none',
  height: '23px',
  visibility: visibility ? 'visible' : 'hidden',
}));

const TelInput = ({ value, onChange, label, errorMessage = '', error = false, disabled, placeholder = '(5__)-___-____' }) => {
  return (
    <CustomFormControl>
      <CustomInputLabel visibility={label}>
        <Typography
          variant="labelLarge"
          color={primaryColors.primary800}>
          {label}
        </Typography>
      </CustomInputLabel>
      <CustomTelInput
        autoComplete="new-off"
        defaultCountry="TR"
        forceCallingCode
        value={value}
        onChange={onChange}
        error={error}
        disabled={disabled}
        placeholder={placeholder}
      />

      <HelperContainer visibility={errorMessage}>{errorMessage && error && <HelperText error>{errorMessage}</HelperText>}</HelperContainer>
    </CustomFormControl>
  );
};

export default TelInput;
