export const MyContractsTr = {
  myContracts: 'Sözleşmelerim',
  manageYourContractList: 'Sözleşme listenizi yönetin',
  contractList: 'Sözleşme Listesi',
  piece: 'Adet',
  contractName: 'Sözleşme Adı',
  contractVersion: 'Sözleşme Versiyonu',
  startEndDate: 'Başlangıç - Bitiş Tarihi',
  status: 'Durum',
  approvalStatus: 'Onay Durumu',
  searchPlaceholder: 'Ara...',
  active: 'Aktif',
  passive: 'Pasif',
  waiting: 'Bekliyor',
  appoved: 'Onaylandı',
  declined: 'Reddedildi',
  remindLater: 'Daha Sonra Hatırlat',
};
