import { MenuItem, Select } from '@mui/material';

const RowPerPage = ({ value, onChange, menuItems }) => {
  return (
    <Select value={value} onChange={onChange} size="small">
      {menuItems.map((item) => (
        <MenuItem key={item.key} value={item.key}>
          {item.value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RowPerPage;
