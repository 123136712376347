import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, USER_API } from 'constants/Keys';

const APPROVE_UPDATE_EMAIL = '/approve-update-email';
const USER = '/user';
const APPROVE_UPDATE_MOBILE = '/approve-update-mobile';

export const checkUpdateMailValid = (token) =>
  httpRequest.post(`${USER_API}${API}${USER}${APPROVE_UPDATE_EMAIL}`, {
    token,
  });

export const checkUpdateMobileValid = (token) =>
  httpRequest.post(`${USER_API}${API}${USER}${APPROVE_UPDATE_MOBILE}`, {
    token,
  });
