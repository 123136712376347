import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { neutralColors, primaryColors } from 'config/theme/Colors';

const CustomButton = styled(Button)(({ inputtype, width, sx }) => {
  const commonProperties = {
    height: '40px',
    borderRadius: 999,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.1,
    wordWrap: 'break-word',
    width,
  };

  if (inputtype === 'filled') {
    return {
      ...commonProperties,
      padding: '10px 24px',
      color: neutralColors.neutral50,
      backgroundColor: primaryColors.primary,

      '&:hover': {
        backgroundColor: primaryColors.primary900,
        color: neutralColors.neutral50,
      },
      '&:active': {
        backgroundColor: primaryColors.primary800,
        color: neutralColors.neutral50,
      },
      '&:disabled': {
        color: neutralColors.neutral400,
        backgroundColor: neutralColors.neutral200,
      },
      ...sx,
    };
  } else if (inputtype === 'outline') {
    return {
      ...commonProperties,
      padding: '10px 24px',
      border: `1px solid ${primaryColors.primary700}`,

      color: primaryColors.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        border: `1px solid ${primaryColors.primary700}`,
        backgroundColor: primaryColors.primary50,
        color: primaryColors.primary,
      },
      '&:active': {
        border: `1px solid ${primaryColors.primary700}`,
        backgroundColor: primaryColors.primary100,
        color: primaryColors.primary,
      },
      '&:disabled': {
        color: primaryColors.primary500,
        border: `1px solid ${primaryColors.primary100}`,
      },
      ...sx,
    };
  } else if (inputtype === 'text') {
    return {
      ...commonProperties,
      padding: '10px 12px',
      border: '1px solid transparent',
      color: primaryColors.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: primaryColors.primary50,
        color: primaryColors.primary,
      },
      '&:active': {
        backgroundColor: primaryColors.primary100,
        color: primaryColors.primary,
      },
      '&:disabled': {
        color: primaryColors.primary500,
      },
      ...sx,
    };
  } else if (inputtype === 'tonal') {
    return {
      ...commonProperties,
      padding: '10px 24px',
      color: primaryColors.primary,
      backgroundColor: primaryColors.primary100,
      '&:hover': {
        backgroundColor: primaryColors.primary100,
        color: primaryColors.primary,
      },
      '&:active': {
        backgroundColor: primaryColors.primary300,
        color: primaryColors.primary,
      },
      '&:disabled': {
        color: neutralColors.neutral400,
        backgroundColor: neutralColors.neutral200,
      },
      ...sx,
    };
  }
});

const PButton = ({ inputtype = 'filled', disabled = false, endIcon = null, onClick = () => {}, children, width = '100%', ...rest }) => {
  return (
    <CustomButton
      width={width}
      inputtype={inputtype}
      disabled={disabled}
      endIcon={endIcon}
      onClick={onClick}
      {...rest}>
      <Typography variant="labelLarge"> {children}</Typography>
    </CustomButton>
  );
};

export default PButton;
