import { Grid } from '@mui/material';
import PButton from 'components/button/PButton';
import PCenterModal from 'components/modal/PCenterModal';
import PTextField from 'components/textField/PTextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createCart, updateCartName } from './api/StorageCartApi';

const AddCart = ({ onClose, getAllCarts, cardInfo, setCardInfo, operation }) => {
  const { t } = useTranslation();
  const [cartName, setCartName] = useState('');

  const onChangeHandler = (event) => {
    setCartName(event.target.value);
  };

  useEffect(() => {
    if (cardInfo?.id) {
      setCartName(cardInfo.cartName);
    }
  }, []);

  const saveOrUpdate = () => {
    if (cardInfo?.id) {
      updateCartName(cardInfo.id, cartName)
        .then((res) => {
          operation({ cartName: cartName, id: cardInfo.id });
          toast.success(res.data.message);
          handleClose();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    } else {
      createCart(cartName)
        .then((res) => {
          toast.success(res.data.message);
          getAllCarts();
          handleClose();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  };

  const handleClose = () => {
    setCardInfo({ id: null, cartName: '' });
    onClose();
  };

  return (
    <PCenterModal onClose={handleClose}>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}>
          <PTextField
            label={t('storageCart:cartName')}
            value={cartName}
            onChange={onChangeHandler}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}>
          <PButton onClick={onClose}>{t('storageCart:cancel')}</PButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <PButton
            onClick={saveOrUpdate}
            disabled={!cartName}>
            {t('storageCart:save')}
          </PButton>
        </Grid>
      </Grid>
    </PCenterModal>
  );
};
export default AddCart;
