import { Grid } from '@mui/material';
import PCheckbox from 'components/checkbox/PCheckbox';
import PTextField from 'components/textField/PTextField';
import { useTranslation } from 'react-i18next';

const FastRequest = ({ formData, onInputChange, onCheckboxChange, errors }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={4}
      rowSpacing={1}>
      <Grid
        item
        xs={12}
        lg={6}
        md={6}>
        <PTextField
          label={t('pay:requestName')}
          placeholder={t('pay:requestName')}
          name="fastRequestName"
          value={formData.fastRequestName}
          onChange={onInputChange}
          error={!!errors.fastRequestName}
          errorMessage={t(errors.fastRequestName, { field: t('pay:requestName') })}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        md={6}
      />
      <Grid
        item
        xs={12}
        lg={6}
        md={6}>
        <PCheckbox
          label={t('pay:clearShippedItemsFromCart')}
          name="clearBasket"
          checked={formData.clearBasket}
          onChange={onCheckboxChange}
        />
      </Grid>
    </Grid>
  );
};

export default FastRequest;
