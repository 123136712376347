import { Accordion, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

const CustomAccordion = styled(Accordion)(({ sx }) => ({
  boxShadow: 'none',
  backgroundColor: '#ffffff',
  borderRadius: '8px !important',
  border: `1px solid ${primaryColors.primary200}`,
  minHeight: '76px',
  justifyContent: 'space-between',
  margin: '0 !important',
  '&:before': {
    opacity: 0,
  },
  ...sx,
}));

const PAccordion = ({ children, sx, defaultExpanded = false }) => {
  return (
    <CustomAccordion
      slotProps={{ transition: { mountOnEnter: true, unmountOnExit: true } }}
      defaultExpanded={defaultExpanded}
      sx={sx}>
      {children}
    </CustomAccordion>
  );
};

export default PAccordion;
