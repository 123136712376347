import styled from '@emotion/styled';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoneyIcon from '@mui/icons-material/Money';
import { Box } from '@mui/material';
import WarningAlert from 'components/alert/WarningAlert';
import ApproveModal from 'components/approveModal/ApproveModal';
import PButton from 'components/button/PButton';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import PClientTable from 'components/table/PClientTable';
import PTooltip from 'components/tooltip/PTooltip';
import { blueColors, primaryColors, redColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import PentaStorageTabContainer from 'pages/pentaStorage/commonComponents/PentaStorageTabContainer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SerialNumberModal from '../productsInStorage/components/SerialNumberModal';
import AddCart from './AddCart';
import {
  deleteCartOption,
  deleteSelectedItem,
  getAllBaskets,
  getCartProducts,
  getStorageItemCount,
  setCartAsCurrent,
  simulateUpdateCart,
} from './api/StorageCartApi';
import StorageCartBasket, { BasketOptions } from './components/StorageCartBasket';

const BasketOptionContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const IconButtonContainer = styled('button')(({ disabled }) => ({
  margin: 0,
  padding: 0,
  height: 'auto',
  width: 'auto',
  outline: 0,
  border: 0,
  background: 'transparent',
  cursor: disabled ? 'normal' : 'pointer',
}));

const IconBasketContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '32px',
  alignItems: 'center',
  overflow: 'hidden',
}));

const BasketContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '16px',
  overflow: 'hidden',
}));

const StorageCart = ({ callApi, setCartItemNumber, setShowDeliveryDetails, tabValue }) => {
  const { t } = useTranslation();
  const [selectValue, setSelectValue] = useState('');
  const [selectData, setSelectData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rowDeleteModal, setRowDeleteModal] = useState(false);
  const [deleteBasketModal, setDeleteBasketModal] = useState(false);
  const [upsertCart, setUpsertCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basketOptions, setBasketOptions] = useState([]);
  const [updateCardInfo, setUpdateCardInfo] = useState({ id: null, cartName: '' });
  const basketOptionRef = useRef();
  const [scrollable, setScrollable] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedRowHeader, setSelectedRowHeader] = useState(null);
  useEffect(() => {
    setScrollable(basketOptionRef.current?.scrollWidth > basketOptionRef.current?.clientWidth);
  }, [basketOptionRef.current?.clientWidth, basketOptionRef.current?.scrollWidth, basketOptions.length]);

  const fillSelect = () => {
    setLoading(true);
    getCartProducts()
      .then((res) => {
        const localSelectData = [];
        res.data.data.carts.forEach((item) => {
          localSelectData.push({ key: item.storageCode, value: `${item.storageCode} ${t('storageCart:storageNo')}` });
        });
        setTableData(res.data.data.carts);
        setSelectData(localSelectData);
        setSelectValue(localSelectData[0]?.key || '');
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (callApi) {
      getBasketOptions();
      fillSelect();
    }
  }, [callApi]);

  const getBasketOptions = () => {
    getAllBaskets()
      .then((res) => {
        const incomingResponse = res.data.data;
        const currentlyUsingCartId = incomingResponse.currentlyUsingCartId;

        incomingResponse.carts.forEach((cart) => {
          cart.isCurrentlyUsing = cart.id === currentlyUsingCartId;
        });
        const orderedByDate = incomingResponse.carts.sort((a, b) => dayjs(b.createdDate) - dayjs(a.createdDate));
        const orderedBaskets = orderedByDate.sort((a, b) => b.isDefaultCart - a.isDefaultCart);
        setBasketOptions(orderedBaskets);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const selectChange = (event) => {
    handleUpdateCart(selectValue);
    setSelectValue(event.target.value);
  };

  const inputChange = (value, row) => {
    const localData = [...tableData];

    const foundStorage = localData.find((data) => data.storageCode === selectValue);
    const foundRow = foundStorage?.products.find((item) => item.id === row.id);

    if (value > foundRow.stockRemainingCount) {
      toast.error(t('storageCart:cannotSendMoreThanTheStock'));
    } else {
      foundRow.quantity = value;
      setTableData(localData);
    }
  };

  const onRowDeleteHandler = (rowId) => {
    setRowDeleteModal(rowId);
  };

  const onCloseRowModalHandler = () => {
    setRowDeleteModal(false);
  };

  const onApproveDeleteRow = () => {
    const local = structuredClone(tableData);
    deleteSelectedItem(rowDeleteModal)
      .then((res) => {
        for (let i = 0; i < local.length; i++) {
          const products = local[i].products;
          const index = products.findIndex((product) => product.id === rowDeleteModal);
          if (index !== -1) {
            products.splice(index, 1);
            if (products.length === 0) {
              local.splice(i, 1);
              fillSelect();
            }
          }
        }
        setTableData(local);
        setRowDeleteModal(false);
        setCartItemNumber((prevState) => prevState - 1);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const onBasketDeleteHandler = (event, id) => {
    event.stopPropagation();
    setDeleteBasketModal(id);
  };

  const onCloseDeleteBasketModalHandler = () => {
    setDeleteBasketModal(false);
  };

  const onApproveDeleteBasket = () => {
    deleteCartOption(deleteBasketModal)
      .then((res) => {
        toast.success(res.data.message);
        setDeleteBasketModal(false);
        getBasketOptions();
        fillSelect();
        getStorageItemCount()
          .then((res) => {
            setCartItemNumber(res.data.data.itemCount);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const toogleCartModal = () => {
    setUpsertCart((prevState) => !prevState);
  };

  const setCartAsActive = (basket) => {
    if (!basket.isCurrentlyUsing) {
      setCartAsCurrent(basket.id)
        .then(() => {
          const copyOfBasketOptions = [...basketOptions];
          copyOfBasketOptions.forEach((basketOption) => {
            basketOption.isCurrentlyUsing = false;
            if (basketOption.id === basket.id) {
              basketOption.isCurrentlyUsing = true;
            }
          });
          getStorageItemCount()
            .then((res) => {
              setCartItemNumber(res.data.data.itemCount);
              setBasketOptions(copyOfBasketOptions);
              fillSelect();
            })
            .catch((err) => {
              toast.error(err.response.data.Message);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  };

  const handleUpdateCart = (currentSelectValue) => {
    setLoading(true);
    const local = structuredClone(tableData);
    const visibleData = local?.find((item) => item?.storageCode === currentSelectValue);
    const simulateItem = {
      storagePlace: visibleData.storageCode,
      cartItems: visibleData.products,
    };
    simulateUpdateCart(simulateItem)
      .then((res) => {
        setTableData(res.data.data.carts);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateDelivery = (currentSelectValue) => {
    setLoading(true);
    const local = structuredClone(tableData);
    const visibleData = local?.find((item) => item?.storageCode === currentSelectValue);
    const simulateItem = {
      storagePlace: visibleData.storageCode,
      cartItems: visibleData.products,
    };
    simulateUpdateCart(simulateItem)
      .then((res) => {
        setTableData(res.data.data.carts);
        setShowDeliveryDetails({
          tableData: res.data.data.carts?.find((item) => item?.storageCode === selectValue)?.products,
          incomingData: res.data.data,
          storagePlace: selectValue,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onBasketUpdateHandler = (event, id, cartName) => {
    event.stopPropagation();
    setUpdateCardInfo({ id, cartName });
    toogleCartModal();
  };

  const updateValuesOperation = (updateValues) => {
    const copyOfBasketOptions = [...basketOptions];
    copyOfBasketOptions.forEach((basketOption) => {
      if (basketOption.id === updateValues.id) {
        basketOption.cartName = updateValues.cartName;
      }
    });
    setBasketOptions(copyOfBasketOptions);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'stockCode',
        headerName: t('storageCart:stockCode'),
        width: 132,
        disableColumnMenu: true,
      },
      {
        field: 'error',
        headerName: ' ',
        width: 60,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <PTooltip
            placement="bottom-end"
            title={t('storageCart:cannotShipMoreThanStorageStock')}>
            <ErrorOutlineIcon sx={{ color: redColors.red500, display: row.quantity > row.stockRemainingCount ? 'inline-block' : 'none' }} />
          </PTooltip>
        ),
      },
      {
        field: 'itemName',
        headerName: t('storageCart:product'),
        minWidth: 548,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'orderNo',
        headerName: t('storageCart:orderNo'),
        width: 132,
        disableColumnMenu: true,
      },
      {
        field: 'entryNumber',
        headerName: t('storageCart:penNo'),
        headerAlign: 'center',
        align: 'center',
        width: 120,
        disableColumnMenu: true,
      },
      {
        field: 'stockRemainingCount',
        headerName: t('storageCart:stockStatus'),
        disableColumnMenu: true,
        width: 120,
        align: 'center',
        headerAlign: 'center',
      },

      {
        field: 'serials',
        headerName: t('storageCart:serialNo'),
        disableColumnMenu: true,
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
          if (row.serials.length > 0) {
            return (
              <MoneyIcon
                className="cursor-pointer"
                onClick={() => {
                  const data = row.serials.map((item) => {
                    return {
                      manufacturerSerialNumber: item.serialNumber,
                    };
                  });
                  setModalData(data);
                  setSelectedRowHeader(Number(row.stockCode));
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
      {
        field: 'quantity',
        headerName: t('storageCart:amount'),
        width: 164,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => (
          <InlineEditNumberInput
            width="74px"
            value={row.quantity}
            onChange={(value) => inputChange(value, row)}
          />
        ),
      },
      {
        field: '',
        width: 80,
        sortable: false,
        align: 'center',
        headerAlign: 'center',

        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <DeleteOutlineOutlinedIcon
            sx={{ color: redColors.red500, cursor: 'pointer' }}
            onClick={() => onRowDeleteHandler(row.id)}
          />
        ),
      },
    ];
  }, [t, inputChange]);

  const scrollToRight = () => {
    basketOptionRef.current.scrollLeft += 100;
  };

  const scrollToLeft = () => {
    basketOptionRef.current.scrollLeft -= 100;
  };

  return (
    <PentaStorageTabContainer>
      {loading && <LoadingSpinner />}
      <BasketOptionContainer>
        <BasketOptions
          onClick={toogleCartModal}
          backgroundColor="#ffffff"
          borderColor={primaryColors.primary700}>
          <AddOutlinedIcon sx={{ display: 'flex', alignItems: 'center', height: '100%' }} />
        </BasketOptions>
        <IconBasketContainer>
          <IconButtonContainer
            disabled={!scrollable}
            onClick={scrollToLeft}>
            <ArrowBackIosNewIcon />
          </IconButtonContainer>
          <BasketContainer ref={basketOptionRef}>
            {basketOptions.map((basketOption) => {
              return (
                <StorageCartBasket
                  key={basketOption.id}
                  basketOption={basketOption}
                  onBasketUpdateHandler={(event) => onBasketUpdateHandler(event, basketOption.id, basketOption.cartName)}
                  onBasketDeleteHandler={(event) => onBasketDeleteHandler(event, basketOption.id)}
                  onClick={() => setCartAsActive(basketOption)}
                  borderColor={basketOption.isCurrentlyUsing ? blueColors.blue500 : primaryColors.primary300}
                  backgroundColor={basketOption.isCurrentlyUsing ? blueColors.blue50 : '#ffffff'}
                />
              );
            })}
          </BasketContainer>
          <IconButtonContainer
            disabled={!scrollable}
            onClick={scrollToRight}>
            <ArrowForwardIosIcon />
          </IconButtonContainer>
        </IconBasketContainer>
      </BasketOptionContainer>
      <ActionContainer>
        <Box className="d-flex align-items-end gap-16">
          <PSelect
            width="200px"
            value={selectValue}
            menuItems={selectData}
            onChange={selectChange}
            label={t('storageCart:selectStorage')}
            showHelperContainer={false}
          />
          {selectData.length > 1 && (
            <WarningAlert
              sx={{ height: '40px' }}
              label={t('storageCart:thereAreItemsFromDifferentStorage')}
            />
          )}
        </Box>
      </ActionContainer>
      {tabValue === 3 && (
        <PClientTable
          dataLength={`${tableData?.find((item) => item?.storageCode === selectValue)?.products.length || 0} ${t('storageCart:piece')}`}
          headerTitle={t('storageCart:itemsInBasket')}
          columns={columns}
          rows={tableData?.find((item) => item?.storageCode === selectValue)?.products}
          noRowsMessageKey="storageCart:thereAreNoProductsInYourCart"
          headerRightSide={
            <Box className="d-flex gap-16 flex-wrap">
              <PButton
                disabled={tableData.length <= 0}
                onClick={() => handleUpdateCart(selectValue)}
                inputtype="outline"
                sx={{ width: '160px' }}>
                {t('storageCart:updateCart')}
              </PButton>
              <PButton
                onClick={() => handleCreateDelivery(selectValue)}
                sx={{ width: '160px' }}
                disabled={tableData.length <= 0}>
                {t('storageCart:createDelivery')}
              </PButton>
            </Box>
          }
        />
      )}
      {rowDeleteModal && (
        <ApproveModal
          onClose={onCloseRowModalHandler}
          approveClick={onApproveDeleteRow}
          bodyText={t('storageCart:removeSelectedRow')}
        />
      )}
      {deleteBasketModal && (
        <ApproveModal
          onClose={onCloseDeleteBasketModalHandler}
          approveClick={onApproveDeleteBasket}
          bodyText={t('storageCart:removeSelectedRow')}
        />
      )}
      {upsertCart && (
        <AddCart
          setCardInfo={setUpdateCardInfo}
          cardInfo={updateCardInfo}
          getAllCarts={getBasketOptions}
          onClose={toogleCartModal}
          operation={updateValuesOperation}
        />
      )}
      {modalData && (
        <SerialNumberModal
          onClose={() => setModalData(null)}
          allData={modalData}
          selectedRowHeader={selectedRowHeader}
        />
      )}
    </PentaStorageTabContainer>
  );
};

export default StorageCart;
