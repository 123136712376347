import { Box, Grid } from '@mui/material';
import PButton from 'components/button/PButton';
import BoxSpinner from 'components/loadingSpinner/BoxSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import PSelect from 'components/select/PSelect';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import PTextFieldWithMenu from 'components/textField/PTextFieldWithMenu';
import { getCities, getDetailsOfAddress, getDistricts } from 'pages/deliveryAddress/api/DeliveryAddressApi';
import { upsertDeliveryAddressValidationMessagesKeys } from 'pages/deliveryAddress/upsertDeliveryAddress/UpsertDeliveryAddressValidationMessagesKeys';
import { createAddressFromCart } from 'pages/pentaStorage/Tabs/storageCart/api/StorageCartApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { addZeroToNumber } from 'utils/HelperFunctions';
import Validator from 'validatorjs';

const AddAddress = ({ onClose, storagePlace, setValues }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    address: '',
    name: '',
    city: '',
    mobile: '',
    district: '',
    phone: '',
    doorNumber: '',
    fax: '',
    postCode: '',
    email: '',
  });
  const [errors, setErrors] = useState({
    address: '',
    name: '',
    city: '',
    mobile: '',
    district: '',
    phone: '',
    postCode: '',
    email: '',
  });
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [addressItems, setAddressItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCities()
      .then((res) => {
        setCityData(res.data.data.map((city) => ({ key: city.cityCode, value: city.cityName })));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formDataFromAddress = (infoAddress) => {
    getDistricts(infoAddress[9])
      .then((res) => {
        setIsLoading(true);
        setDistrictData(res.data.data.map((item) => ({ key: item.id, value: item.districtName, ...item })));
        const foundDistrict = res.data.data.find((item) => item.districtName === infoAddress[5]);
        setFormData((prevState) => ({
          ...prevState,
          city: infoAddress[9],
          district: foundDistrict?.id,
          doorNumber: infoAddress[17],
          address: infoAddress[0],
          postCode: foundDistrict?.zipCode,
        }));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeAddress = (event) => {
    const { name, value } = event.target;
    if (value.length >= 5) {
      getDetailsOfAddress(value).then((res) => {
        const addressMenuItem = [];
        const { data } = res;
        data.forEach((address, index) => {
          addressMenuItem.push({
            id: index,
            content: (
              <div>
                <div>Adres:{address[0]}</div>
                <div>Mahalle:{address[4]}</div>
                <div>İl:{address[6]}</div>
                <div>İlçe:{address[5]}</div>
              </div>
            ),
            onClick: () => formDataFromAddress(address),
          });
        });
        setAddressItems(addressMenuItem);
      });
    }
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onInputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onSelectChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'city') {
      getDistricts(value).then((res) => {
        setDistrictData(res.data.data.map((item) => ({ key: item.id, value: item.districtName, ...item })));
        setFormData((prevState) => ({ ...prevState, [name]: value, district: '' }));
      });
    } else if (name === 'district') {
      const temp = districtData.find((item) => item.key === value);
      setFormData((prevState) => ({
        ...prevState,
        postCode: temp.zipCode || '',
        [name]: value,
      }));
    }
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const validate = () => {
    const rules = {
      address: 'required',
      name: 'required',
      city: 'required',
      district: 'required',
      email: 'required|email',
      phone: 'required',
      mobile: 'required',
      postCode: ['regex:/^\\d{5}$/'],
    };
    let mobile = formData.mobile.substring(1);
    mobile = mobile.replace(/\s/g, '');
    let phone = formData.phone?.substring(1);
    phone = phone?.replace(/\s/g, '');

    const validationData = {
      name: formData.name,
      city: formData.city,
      district: formData.district,
      email: formData.email,
      phone,
      mobile,
      address: formData.address,
      postCode: formData.postCode,
    };
    const validation = new Validator(validationData, rules, upsertDeliveryAddressValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        address: validation.errors.first('address'),
        name: validation.errors.first('name'),
        city: validation.errors.first('city'),
        district: validation.errors.first('district'),
        email: validation.errors.first('email'),
        phone: validation.errors.first('phone'),
        mobile: validation.errors.first('mobile'),
        postCode: validation.errors.first('postCode'),
      }));
    }
  };

  const createAddress = () => {
    if (validate()) {
      let mobile = formData.mobile?.substring(1);
      mobile = mobile?.replace(/\s/g, '');
      let phone = formData.phone?.substring(1);
      phone = phone?.replace(/\s/g, '');
      const localDistrict = districtData.find((item) => item.key === formData.district);
      const data = {
        city: addZeroToNumber(formData.city),
        district: localDistrict.value,
        address: formData.address,
        doorNumber: formData.doorNumber,
        postCode: formData.postCode?.toString() || '',
        phone: phone,
        mobile: mobile,
        fax: formData.fax,
        email: formData.email,
        name: formData.name,
        storagePlace,
      };
      setIsLoading(true);
      createAddressFromCart(data)
        .then((res) => {
          toast.success(t('deliveryDetails:addressSavedSuccessfully'));
          setValues(res.data.data);
          onClose();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <PCenterModal
      width="35%"
      onClose={onClose}
      showClose
      title={t('deliveryDetails:addDeliveryAddress')}
      subTitle={t('deliveryDetails:addAddressDescription')}>
      {isLoading && <BoxSpinner />}

      <Box className="d-flex flex-column gap-20">
        <Grid
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextFieldWithMenu
              label={t('deliveryDetails:address')}
              name="address"
              value={formData.address}
              onChange={onChangeAddress}
              menuItems={addressItems}
              error={!!errors.address}
              errorMessage={t(errors.address, { field: t('deliveryDetails:address') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextField
              label={t('deliveryDetails:companyName')}
              name="name"
              value={formData.name}
              onChange={onInputChangeHandler}
              error={!!errors.name}
              errorMessage={t(errors.name, { field: t('deliveryDetails:companyName') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PSelect
              menuItems={cityData}
              label={t('deliveryDetails:city')}
              name="city"
              value={formData.city}
              onChange={onSelectChangeHandler}
              error={!!errors.city}
              errorMessage={t(errors.city, { field: t('deliveryDetails:city') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <TelInput
              label={t('deliveryDetails:mobile')}
              value={formData.mobile}
              onChange={(value) => {
                setFormData((prevState) => ({ ...prevState, mobile: value }));
                setErrors((prevState) => ({ ...prevState, mobile: '' }));
              }}
              error={!!errors.mobile}
              errorMessage={t(errors.mobile, { field: t('deliveryDetails:mobile') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PSelect
              menuItems={districtData}
              onChange={onSelectChangeHandler}
              label={t('deliveryDetails:district')}
              name="district"
              value={formData.district}
              error={!!errors.district}
              errorMessage={t(errors.district, { field: t('deliveryDetails:district') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <TelInput
              label={t('deliveryDetails:phone')}
              value={formData.phone}
              onChange={(value) => {
                setFormData((prevState) => ({ ...prevState, phone: value }));
                setErrors((prevState) => ({ ...prevState, phone: '' }));
              }}
              error={!!errors.phone}
              errorMessage={t(errors.phone, { field: t('deliveryDetails:phone') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextField
              label={`${t('deliveryDetails:doorNo')} (${t('deliveryDetails:optional')})`}
              name="doorNumber"
              value={formData.doorNumber}
              onChange={onInputChangeHandler}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextField
              label={`${t('deliveryDetails:fax')} (${t('deliveryDetails:optional')})`}
              name="fax"
              value={formData.fax}
              onChange={onInputChangeHandler}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextField
              label={`${t('deliveryDetails:postCode')} (${t('deliveryDetails:optional')})`}
              name="postCode"
              value={formData.postCode}
              onChange={onInputChangeHandler}
              error={!!errors.postCode}
              errorMessage={t('deliveryAddress:regexFailed')}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextField
              label={t('deliveryDetails:email')}
              name="email"
              value={formData.email}
              onChange={onInputChangeHandler}
              error={!!errors.email}
              errorMessage={t(errors.email, { field: t('deliveryDetails:email') })}
            />
          </Grid>
        </Grid>
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={createAddress}>
            {t('deliveryDetails:save')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default AddAddress;
