import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Typography, styled } from '@mui/material';
import { primaryColors, yellowColors } from 'config/theme/Colors';

const WarningContainer = styled(Box)(({ theme, sx }) => ({
  border: `1px solid ${yellowColors.yellow300}`,
  backgroundColor: yellowColors.yellow50,
  padding: '10px 12px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  minHeight: '40px',
  wordBreak: 'break-word',
  ...sx,
}));

const WarningAlert = ({ label, sx }) => {
  return (
    <WarningContainer sx={sx}>
      <WarningAmberIcon sx={{ color: yellowColors.yellow600 }} />
      <Typography
        variant="bodyMedium"
        color={primaryColors.primary}>
        {label}
      </Typography>
    </WarningContainer>
  );
};
export default WarningAlert;
