import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, COMMON_API } from 'constants/Keys';

const CONTRACT_PERSON = '/contract-person';

const GET_INTERLOCUTORS = '/get-interlocutors';

const CREATE_INTERLUCTOR = '/create-interluctor';

const UPDATE_INTERLUCTOR = '/update-interluctor';

const GET_ACTIVE_USERS = '/get-active-company-users';

const ADD_SUBUSER_TO_INTERLUCTOR = '/add-subuser-to-interluctor';

const CHECK_EARCHIVE_STATUS = '/check-earchive-status';

const UPDATE_SUBUSER = '/update-subuser';

const UPDATE_EARCHIVE_STATUS = '/update-earchive-status';

export const upsertRelatedPerson = ({
  firstName,
  lastName,
  phoneNumber,
  emailAddress,
  relatedPersonNumber,
  isUpdate,
  contractPersonType,
  invoiceTransmissionType,
}) =>
  httpRequest.post(`${COMMON_API}${API}${CONTRACT_PERSON}`, {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    relatedPersonNumber,
    isUpdate,
    contractPersonType,
    invoiceTransmissionType,
  });

export const getRelatedPersons = (contractPersonType) =>
  httpRequest.get(`${COMMON_API}${API}${CONTRACT_PERSON}?contractPersonType=${contractPersonType}`);

export const deleteRelatedPerson = (personNumber) => httpRequest.delete(`${COMMON_API}${API}${CONTRACT_PERSON}?personNumber=${personNumber}`);

export const getAllInterlocutor = () => httpRequest.get(`${COMMON_API}${API}${CONTRACT_PERSON}${GET_INTERLOCUTORS}`);

export const createInterluctor = ({
  name,
  address,
  district,
  city,
  phone,
  emailAddress,
  postCode,
  authorizedB2bUserMailAddress,
  isVisibleToCustomerRepresentativeOnly,
}) =>
  httpRequest.post(`${COMMON_API}${API}${CONTRACT_PERSON}${CREATE_INTERLUCTOR}`, {
    name,
    address,
    district,
    city,
    phone,
    emailAddress,
    postCode,
    authorizedB2bUserMailAddress,
    isVisibleToCustomerRepresentativeOnly,
  });

export const updateInterluctor = ({
  interlocutorCode,
  name,
  address,
  district,
  city,
  phone,
  emailAddress,
  postCode,
  authorizedB2bUserMailAddress,
  isVisibleToCustomerRepresentativeOnly,
  deletionFlag,
}) =>
  httpRequest.put(`${COMMON_API}${API}${CONTRACT_PERSON}${UPDATE_INTERLUCTOR}`, {
    interlocutorCode,
    name,
    address,
    district,
    city,
    phone,
    emailAddress,
    postCode,
    authorizedB2bUserMailAddress,
    isVisibleToCustomerRepresentativeOnly,
    deletionFlag,
  });

export const updateSubuser = ({ interlocutorCode, name, lastname, phone, emailAddress, status }) =>
  httpRequest.put(`${COMMON_API}${API}${CONTRACT_PERSON}${UPDATE_SUBUSER}`, {
    interlocutorCode,
    name,
    lastname,
    phone,
    emailAddress,
    status,
  });

export const getActiveUsers = () => httpRequest.get(`${COMMON_API}${API}${CONTRACT_PERSON}${GET_ACTIVE_USERS}`);

export const addSubuserToInterluctor = ({ interlocutorNumber, name, surname, phone, email, subUserType, status }) =>
  httpRequest.post(`${COMMON_API}${API}${CONTRACT_PERSON}${ADD_SUBUSER_TO_INTERLUCTOR}`, {
    interlocutorNumber,
    name,
    surname,
    phone,
    email,
    subUserType,
    status,
  });

export const checkEarchiveStatus = () => httpRequest.get(`${COMMON_API}${API}${CONTRACT_PERSON}${CHECK_EARCHIVE_STATUS}`);

export const updateEarchiveStatus = ({ invoiceTransmissionType }) =>
  httpRequest.put(`${COMMON_API}${API}${CONTRACT_PERSON}${UPDATE_EARCHIVE_STATUS}`, {
    invoiceTransmissionType,
  });
