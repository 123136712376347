import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, styled } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import PRadio from 'components/radio/PRadio';
import PSelect from 'components/select/PSelect';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import ConfigMasterDeliveryFromStorageOption from 'pages/configMaster/orderDetail/components/ConfigMasterDeliveryFromStorageOption';
import ConfigMasterPentaShipmentOption from 'pages/configMaster/orderDetail/components/ConfigMasterPentaShipmentOption';
import { deliveryTypeEnum } from 'pages/pentaStorage/constants/DeliveryTypeEnum';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const RadioItemContainer = styled(Box)(({ background = '#ffffff' }) => ({
  border: `1px solid ${primaryColors.primary200}`,
  paddingLeft: '12px',
  borderRadius: '6px',
  width: '100%',
  backgroundColor: background,
}));

const OrderDetailMain = ({
  formData,
  onRadioChange,
  userHasStoragePermission,
  ownStorageData,
  onStorageChangeHandler,
  errors,
  shipmentTypeData,
  onShipmentTypeChangeHandler,
  incomingData,
  additionalInfo,
  handleChange,
  onAddPersonHandler,
  shippingDateData,
  onShippingDateChange,
  inputChangeHandler,
  onChangeReceiveSms,
  handleAddTelInput,
  dynamicTelInput,
  handleDynamicTelChange,
  handleDynamicTelDelete,
}) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.currentUser.currentUser);

  return (
    <>
      <Box className="d-flex gap-16">
        <RadioItemContainer background={formData.selectedRadio === 'createOrder' ? primaryColors.primary50 : null}>
          <PRadio
            name="createOrder"
            checked={formData.selectedRadio === 'createOrder'}
            label={t('configMaster:createOrder')}
            onChange={onRadioChange}
          />
        </RadioItemContainer>
        {userHasStoragePermission && (
          <RadioItemContainer background={formData.selectedRadio === 'sendToStorage' ? primaryColors.primary50 : null}>
            <PRadio
              name="sendToStorage"
              checked={formData.selectedRadio === 'sendToStorage'}
              label={t('configMaster:sendToPentaStorage')}
              onChange={onRadioChange}
            />
          </RadioItemContainer>
        )}
      </Box>
      <Box>
        {formData.selectedRadio === 'sendToStorage' && (
          <PSelect
            menuItems={ownStorageData}
            label={t('configMaster:storage')}
            placeholder={t('configMaster:selectStorage')}
            value={formData.storage}
            onChange={onStorageChangeHandler}
            errorMessage={t(errors.storage, { field: t('configMaster:storage') })}
            error={!!errors.storage}
          />
        )}
        {formData.selectedRadio === 'createOrder' && (
          <PSelect
            name="shipmentType"
            value={formData.shipmentType}
            menuItems={shipmentTypeData}
            onChange={onShipmentTypeChangeHandler}
            placeholder={t('configMaster:selectShipmentType')}
            label={t('configMaster:shipmentType')}
            errorMessage={t(errors.shipmentType, { field: t('configMaster:shipmentType') })}
            error={!!errors.shipmentType}
          />
        )}
      </Box>
      {formData.selectedRadio === 'createOrder' && formData.shipmentType === deliveryTypeEnum.pickUpFromStorage && (
        <ConfigMasterDeliveryFromStorageOption
          contracts={incomingData.contracts}
          additionalInfo={additionalInfo}
          handleChange={handleChange}
          onAddPersonHandler={onAddPersonHandler}
          errorMessage={t(errors.additionalInfo, { field: t('configMaster:relatedPerson') })}
          error={!!errors.additionalInfo}
        />
      )}
      {formData.selectedRadio === 'createOrder' && formData.shipmentType && formData.shipmentType !== deliveryTypeEnum.pickUpFromStorage && (
        <>
          <ConfigMasterPentaShipmentOption
            deliveryOptions={incomingData.deliveryOptions}
            additionalInfo={additionalInfo}
            handleChange={handleChange}
            errorMessage={t(errors.additionalInfo, { field: t('configMaster:deliveryAddress') })}
            error={!!errors.additionalInfo}
          />
          <Box>
            <PSelect
              value={formData.shippingDate}
              menuItems={shippingDateData}
              label={t('configMaster:shipmentDate')}
              placeholder={t('configMaster:selectShipmentDate')}
              onChange={onShippingDateChange}
            />
          </Box>
        </>
      )}
      <Box>
        <PTextField
          placeholder={t('configMaster:enterDescription')}
          value={formData.orderDescription}
          label={t('configMaster:orderDescription')}
          onChange={inputChangeHandler}
          name="orderDescription"
        />
      </Box>
      <Box>
        <PTextField
          placeholder={t('configMaster:enterInvoiceDescription')}
          value={formData.invoiceDescription}
          label={t('configMaster:invoiceDescription')}
          onChange={inputChangeHandler}
          name="invoiceDescription"
        />
      </Box>
      <Box>
        <PTextField
          placeholder={t('configMaster:enterTrackingNumber')}
          value={formData.trackNumber}
          onChange={inputChangeHandler}
          label={t('configMaster:trackingNumber')}
          name="trackNumber"
        />
      </Box>
      <Box>
        <PCheckbox
          onChange={onChangeReceiveSms}
          name="receiveSms"
          checked={formData.receiveSms}
          label={t('configMaster:checkboxInfo')}
        />
      </Box>
      <Box className="d-flex align-items-center gap-20">
        <TelInput
          label={t('deliveryDetails:numberToSendSms')}
          disabled
          value={`+${currentUser.user.phone}`}
        />
        <PIconButton
          disabled={!formData.receiveSms}
          inputtype="tonal"
          onClick={handleAddTelInput}>
          <AddIcon />
        </PIconButton>
      </Box>
      {dynamicTelInput.map((telInput, index) => (
        <Box className="d-flex align-items-center gap-20">
          <TelInput
            label={t('deliveryDetails:numberToSendSms')}
            name="telInput"
            value={telInput.phone}
            onChange={(value) => handleDynamicTelChange(value, index)}
          />
          <PIconButton
            inputtype="tonal"
            onClick={() => handleDynamicTelDelete(index)}>
            <DeleteOutlineIcon />
          </PIconButton>
        </Box>
      ))}
    </>
  );
};
export default OrderDetailMain;
