import { Box, styled, Typography } from '@mui/material';
import WarningAlert from 'components/alert/WarningAlert';
import PButton from 'components/button/PButton';
import PTooltip from 'components/tooltip/PTooltip';
import { primaryColors, slateColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/HelperFunctions';

const OrderContainer = styled(Box)(() => ({
  padding: '0 16px',
}));

const OrderItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  padding: '20px 0',
  justifyContent: 'space-between',
}));

const CreditLimit = styled(Box)(() => {
  return {
    background: 'linear-gradient(92.38deg, #F6FAE4 0%, #F7FBED 49.98%, #F4FBF5 74.99%, #F4F8E9 100%)',

    width: '100%',
    padding: '16px 12px',
    borderRadius: '8px',
  };
});

const DynamicContainer = styled(Box)(() => ({
  paddingTop: '20px',
  '& > *': {
    borderBottom: `1px solid ${slateColors.slate200}`,
  },
  '&>*:nth-of-type(1)': {
    padding: 0,
    paddingBottom: '24px',
  },
  'div:last-of-type': {
    borderBottom: 0,
  },
}));

const PayRightPart = ({ showOrderDetail, formData, toogleApproveModal }) => {
  const { t } = useTranslation();
  return (
    <OrderContainer>
      <DynamicContainer>
        {showOrderDetail.amounts.map((summary) => (
          <OrderItemContainer key={summary.amountType}>
            <Box>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {summary.amountType}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${formatPrice(summary.amount)} ${summary.currency}`}
              </Typography>
            </Box>
          </OrderItemContainer>
        ))}
      </DynamicContainer>
      <Box className="d-flex flex-column gap-16">
        <CreditLimit>
          <Box className="d-flex flex-column">
            <Typography
              variant="bodySmall"
              color={primaryColors.primary800}>
              {`${t('pay:availableCreditLimit')}:`}
            </Typography>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {showOrderDetail.creditLimit}
            </Typography>
          </Box>
        </CreditLimit>
        {showOrderDetail.matrixTxt && <WarningAlert label={showOrderDetail.matrixTxt} />}
        <PTooltip
          placement="bottom-end"
          title={!formData.selectedPayment && t('pay:pleaseSelectPaymentType')}>
          <PButton
            disabled={!formData.selectedPayment}
            onClick={toogleApproveModal}>
            {t('pay:order')}
          </PButton>
        </PTooltip>
      </Box>
    </OrderContainer>
  );
};

export default PayRightPart;
