import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
const LoginContainer = styled(Box)(({ maxWidth = 'xs' }) => {
  let calculatedWidth = '442px';

  if (maxWidth === 'xs') {
    calculatedWidth = '442px';
  } else if (maxWidth === 'lg') {
    calculatedWidth = '1280px';
  }

  return {
    maxWidth: calculatedWidth,
    width: '100%',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  };
});

export default LoginContainer;
