/* eslint-disable max-len */
export const PentaIntegrationTr = {
  pentaIntegration: 'Penta Entegrasyon',
  makeYourWorkflowMoreEfficientByIntegratingDifferentServices: 'Farklı servisleri entegre ederek iş akışınızı daha etkili hale getirin',
  productServices: 'Ürün Servisleri',
  orderServices: 'Sipariş Servisleri',
  storageServices: 'Penta Depom Servisleri',
  subscriptionManagementServices: 'Penta Abonelik Yönetimi Servisleri',
  configurationPreferences: 'Konfigürasyon Tercihleri',
  capitalIntegrations: 'ENTEGRASYONLAR',
  capitalManagement: 'YÖNETİM',
  integration: 'Entegrasyon',
  transactionHistory: 'İşlem Geçmişi',
  developerLink: 'Developer Sayfası',
  clickToGetDetailedInformationAboutProductServicesIntegration: 'Ürün Servisleri Entegrasyonu hakkında detaylı bilgiye ulaşmak için tıklayın.',
  activeRequest: 'Aktif Talep',
  piece: 'Adet',
  pastRequests: 'Geçmiş Talepler',
  searchPlaceholder: 'Arama yap...',
  requestNumber: 'Talep Numarası',
  requester: 'Talep Eden',
  requestDateAndTime: 'Talep Tarihi ve Saati',
  status: 'Durum',
  request: 'İstek',
  dateAndTime: 'Tarih ve Saat',
  httpRequest: 'Request',
  httpResponse: 'Response',
  result: 'Sonuç',
  clickToGetDetailedInformationAboutOrderServicesIntegration: 'Sipariş Servisleri Entegrasyonu hakkında detaylı bilgiye ulaşmak için tıklayın.',
  clickToGetDetailedInformationAboutStorageServicesIntegration: 'Penta Depom Servisleri Entegrasyonu hakkında detaylı bilgiye ulaşmak için tıklayın.',
  clickToGetDetailedInformationAboutPayServicesIntegration: 'Penta Abonelik Yönetimi Entegrasyonu hakkında detaylı bilgiye ulaşmak için tıklayın.',
  thereIsNoActiveRequestYet: 'Henüz aktif bir talep bulunmuyor.',
  pleaseCreateANewRequest: 'Lütfen yeni talep oluşturun.',
  createRequest: 'Talep Oluştur',
  createProductServiceIntegrationTestRequest: 'Ürün Servisi Entegrasyon Test Talebi Oluştur',
  createOrderServiceIntegrationTestRequest: 'Sipariş Servisi Entegrasyon Test Talebi Oluştur',
  createPayServiceIntegrationTestRequest: 'Penta Abonelik Yönetimi Entegrasyon Test Talebi Oluştur',
  createStorageServiceIntegrationTestRequest: 'Penta Depom Entegrasyon Test Talebi Oluştur',
  testIpAddressess: 'Test IP Adresleri',
  description: 'Açıklama',
  enterDescription: 'Açıklama girin',
  emailAddressess: 'E-posta Adresleri',
  create: 'Oluştur',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  theEmailFormatIsInvalid: 'E-posta biçimi geçersiz.',
  emailPlaceholder: 'E-posta adresi ekle',
  ipAddresses: 'IP Adresleri',
  emailAddresses: 'E-posta Adresleri',
  integrationRequestNumber: '{{number}} numaralı entegrasyon talebi',
  ipAddressesWithNumber: '{{number}} IP adresi',
  emailAddressesWithNumber: '{{number}}  E-posta adresi',
  completeTheTest: 'Testi Tamamla',
  goLive: 'Canlıya Geç',
  testSecretKey: 'Test Gizli Anahtarı',
  liveSecretKey: 'Canlı Gizli Anahtarı',
  thereIsNoSecretKey: 'Gizli anahtar bulunmuyor.',
  secretKeyCreated: 'Gizli anahtar oluşturuldu.',
  changeKey: 'Anahtar Değiştir',
  liveIpAddressess: 'Canlı IP Adresleri',
  save: 'Kaydet',
  networkAndCommunicationPreferences: 'Ağ ve İletişim Tercihleri',
  enterInformationAndDescriptions: 'Bilgilendirmeleri ve açıklamaları girin',
  testCompleted: 'Test tamamlandı',
  requestCanceled: 'Talep iptal edildi',
  createProductServiceIntegrationLiveRequest: 'Ürün Servisi Entegrasyon Canlı Talebi Oluştur',
  createOrderServiceIntegrationLiveRequest: 'Sipariş Servisi Entegrasyon Canlı Talebi Oluştur',
  createPayServiceIntegrationLiveRequest: 'Penta Abonelik Yönetimi Entegrasyon Canlı Talebi Oluştur',
  createStorageServiceIntegrationLiveRequest: 'Penta Depom Entegrasyon Canlı Talebi Oluştur',
  productInfoFirstItem:
    ' Bu servis, ürünlerin kategori, fiyat, stok durumu, marka, garanti süresi, abonelik ürünleri gibi temel bilgilerini ve resim, teknik özellikler, boyut, ağırlık gibi detaylı bilgilerini barındırır.',
  orderInfoFirstTitle: 'CreateOrder - Sipariş Oluşturma & Değiştirme & Silme',
  orderInfoFirstItem:
    'CreateOrder methodu kullanılarak Penta Teknoloji ERP sistemine otomatik sipariş kaydı gerçekleştirilebilmektedir. Aynı method ile sipariş değişikliği ve silme işlemleri de yapılabilmektedir. Sipariş değiştirme / silme işlemleri faturası kesilmemiş ve mal çıkışı yapılmamış siparişlerde geçerli olmaktadır. İsteklerinizi POST metodu ile göndermeniz gerekmektedir.  ',
  orderInfoSecondTitle: 'GetOrders - Sipariş Takip ve Sorgulama Servisi',
  orderInfoSecondItem: 'GetOrders methodu kullanılarak Penta Teknoloji ERP sisteminde bulunan siparişlerin bilgilerine ulaşılabilmektedir.',
  storageInfoFirstTitle: 'CreateOrder2890 - PentaDepom Sipariş Servisi',
  storageInfoFirstItem:
    'Firmanın PentaDepom Uygulamasında bulunan ürünlerinin istedikleri adrese teslim edilmesini sağlayan servistir. Bu servisin kullanılabilmesi için PentaDepom sözleşmesinin imzalanmış olması ve firma izninin aktif olması gerekmektedir. ',
  storageInfoSecondTitle: 'Get2890Product - PentaDepom Ürün Bilgileri Servisi',
  storageInfoSecondItem:
    'Firmanın PentaDepom Uygulamasında bulunan ürünlerin bilgilerinin alındığı servistir. Bu servisin kullanılabilmesi için PentaDepom sözleşmesinin imzalanmış olması ve firma izninin aktif olması gerekmektedir. ',
  storageInfoThirdTitle: 'GetDelivery2890 - PentaDepom Sipariş Teslimat Takip Servisi (POST)',
  storageInfoThirdItem:
    'Firmanın PentaDepom Uygulamasında bulunan ürünlerini gönderdikten sonra teslimat durumlarını ve bilgilerini alabildiği servistir. İki tarih arasındaki tüm siparişler listenebileceği gibi, istenirse daha önce sipariş verirken gönderilen Müşteri Sipariş Numarası parametresi ile ilgili siparişte görüntülenebilir. ',
  payInfoFirstTitle: 'GetSozlesme',
  payInfoFirstItem: 'Müşteriye ait tüm abonelikleri ve abonelik detaylarını içeren listeyi sağlayan bir servistir.',
  payInfoSecondTitle: 'SozlesmeChange',
  payInfoSecondItem:
    'Abonelik iptali, ürün ekleme, yenileme, ürün adedi artırma/azaltma ve ürün durdurma gibi abonelik değişiklik işlemlerini gerçekleştiren bir servistir.',
  payInfoThirdTitle: 'YakınYenilemeler Servisi',
  payInfoThirdItem: 'Yakın Yenilemeler servisi, belirtilen gün sayısı içinde yenilenmesi gereken kalemlerin listesini sağlayan bir servistir.',
  ipAddress: 'IP Adresi',
  serviceName: 'Servis Adı',
  nameRequestDetail: '{{name}} İstek Detayı',
  requestInformation: 'İstek Bilgisi',
  requestResult: 'İstek Sonucu',
  networkPreferencesInfo: 'Entegrasyon ile ilgili süreci takip etmek için E-posta adresinizi girmeniz gerekmektedir.',
  keyCopied: 'Kod kopyalandı.',
};
