import { Container, Tab } from '@mui/material';
import TabPanel from 'components/tabs/PTabPanel';
import PTabs from 'components/tabs/PTabs';
import MainContainer from 'layout/container/MainContainer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShippingCostTab from './detailTabs/ShippingCostTab';
import StorageCostTab from './detailTabs/StorageCostTab';

const StorageCostsDetail = ({ detailData }) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [shippingCostData, setShippingCostData] = useState([]);
  const [renderScreen, setRenderScreen] = useState(false);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const copyOfProductionDetail = [...detailData.productDetails];
    const copyOfShippingDetals = [...detailData.shippingDetails];
    copyOfProductionDetail.forEach((productionDetail, index) => (productionDetail.id = index + 1));
    copyOfShippingDetals.forEach((shippingDetail, index) => (shippingDetail.id = index + 1));
    setTableData(copyOfProductionDetail);
    setShippingCostData(copyOfShippingDetals);
    setRenderScreen(true);
  }, []);

  return (
    <MainContainer>
      {renderScreen && (
        <>
          <Container maxWidth="lg">
            <div>
              <PTabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ padding: ' 20px 0' }}>
                <Tab label={t('storageCost:storageCosts')} />
                <Tab label={t('storageCost:shippingCosts')} />
              </PTabs>
              <TabPanel
                value={tabValue}
                index={0}>
                <div style={{ width: '100%', borderRadius: '12px', backgroundColor: '#ffffff', padding: '16px' }}>
                  <StorageCostTab tableData={tableData} />
                </div>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={1}>
                <div style={{ width: '100%', borderRadius: '12px', backgroundColor: '#ffffff', padding: '16px' }}>
                  <ShippingCostTab tableData={shippingCostData} />
                </div>
              </TabPanel>
            </div>
          </Container>
        </>
      )}
    </MainContainer>
  );
};

export default StorageCostsDetail;
