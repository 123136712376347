import { paths } from 'constants/Paths';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import GeneralManager from '../GeneralManager';

export const generalManagerRoutes = {
  path: `${paths.account}${paths.generalManagerMessage}`,
  element: (
    <LoginLayout>
      <GeneralManager />
    </LoginLayout>
  ),
};
