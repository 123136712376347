import { styled } from '@mui/material';
import { primaryColors, slateColors } from 'config/theme/Colors';
import { MuiOtpInput } from 'mui-one-time-password-input';

const CustomOtpInput = styled(MuiOtpInput)(({ value }) => ({
  fontWeight: 400,
  fontSize: 16,
  width: '100%',
  '.MuiOutlinedInput-root:hover': {
    borderColor: slateColors.slate500,
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
    color: primaryColors.primary,
    borderBottom: value ? `2px solid ${primaryColors.primary700}` : `2px solid ${primaryColors.primary500}`,
    borderRadius: 0,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  '.MuiInputBase-input': {
    backgroundColor: primaryColors.primary50,
    color: `${primaryColors.primary} !important`,
  },
}));

const OtpInput = ({ value, onChange, length = 6 }) => {
  return <CustomOtpInput value={value} onChange={onChange} length={length} />;
};
export default OtpInput;
