/* eslint-disable max-len */
import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, STORAGE_API } from 'constants/Keys';

const STORAGE = '/storage';

const COSTS = '/costs';

const CART = '/cart';

const CHECK_DELIVERY_TYPE = '/check-delivery-type';

export const getAllCosts = ({ sapCustomerId, year, month }) =>
  httpRequest.get(`${STORAGE_API}${API}${STORAGE}${COSTS}?Year=${year}&Month=${month}&SapCustomerId=${sapCustomerId}`);

export const getDeliveryType = ({ storagePlace, deliveryType, receiver, contactPerson }) =>
  httpRequest.get(
    `${STORAGE_API}${API}${STORAGE}${CART}${CHECK_DELIVERY_TYPE}?StoragePlace=${storagePlace}&DeliveryType=${deliveryType}&Receiver=${receiver}&ContactPerson=${contactPerson}`,
  );
