import { useTheme } from '@emotion/react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import ApproveModal from 'components/approveModal/ApproveModal';
import PButton from 'components/button/PButton';
import PInfoChip from 'components/chip/PInfoChip';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import { blueColors, primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import { changeActiveCart, deleteCart, getCartItems, getCarts, simulateInCart } from 'pages/configMaster/api/ConfigMasterApi';
import BasketItem from 'pages/configMaster/components/basketItem/BasketItem';
import CartBasket from 'pages/configMaster/components/cartBasket/CartBasket';
import { tabNumbers } from 'pages/configMaster/constants/TabNumbers';
import UpdateCart from 'pages/configMaster/tabs/configMaster/components/updateCart/UpdateCart';
import OrderSummary from 'pages/configMaster/tabs/configMasterCart/OrderSummary';
import PentaStorageTabContainer from 'pages/pentaStorage/commonComponents/PentaStorageTabContainer';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
const BasketOptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const IconButtonContainer = styled('button')(({ disabled }) => ({
  margin: 0,
  padding: 0,
  height: 'auto',
  width: 'auto',
  outline: 0,
  border: 0,
  background: 'transparent',
  cursor: disabled ? 'normal' : 'pointer',
}));

const IconBasketContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '32px',
  alignItems: 'center',
  overflow: 'hidden',
}));

const BasketContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '16px',
  overflow: 'hidden',
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const BigGridItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridColumnStart: 1,
  gridColumnEnd: 10,
  gap: '20px',
}));

const SmallGridItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridColumnStart: 10,
  gridColumnEnd: 13,
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
  height: 'min-content',
}));

const ConfigMasterCart = ({ setShowOrderDetail, tabValue, setCartItemNumber }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const basketOptionRef = useRef();
  const [basketOptions, setBasketOptions] = useState([]);
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState(false);
  const [updateCardInfo, setUpdateCardInfo] = useState({ id: null, cartName: '' });
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [orderSummaryPart, setOrderSummaryPart] = useState([]);
  const [basketItems, setBasketItems] = useState([]);
  const [deleteBasketModal, setDeleteBasketModal] = useState(false);
  const [customerDefaultCurrency, setCustomerDefaultCurrency] = useState('');
  const [currencyOptions, setCurrencyOptions] = useState([]);
  useEffect(() => {
    getBasketOptions();
  }, [tabValue]);

  useEffect(() => {
    handleActiveCartOperations();
  }, [basketOptions]);

  useEffect(() => {
    if (items.length > 0 && tabValue === tabNumbers.configMasterCart) {
      simulate();
    }
  }, [items]);

  const getBasketOptions = async () => {
    setLoading(true);
    try {
      const incomingData = await getCarts();
      const orderedByDate = incomingData.data.data.sort((a, b) => dayjs(b.createdDate) - dayjs(a.createdDate));
      setBasketOptions(orderedByDate);
      if (orderedByDate.length <= 0) {
        setItems([]);
        setOrderSummaryPart([]);
        setCartItemNumber(0);
      }
    } catch (err) {
      toast.error(err.response.data.Message);
    } finally {
      setLoading(false);
    }
  };

  const scrollToRight = () => {
    basketOptionRef.current.scrollLeft += 100;
  };

  const scrollToLeft = () => {
    basketOptionRef.current.scrollLeft -= 100;
  };

  const onBasketDeleteHandler = (event, id) => {
    event.stopPropagation();
    setDeleteBasketModal(id);
  };

  const onBasketUpdateHandler = (event, id, cartName) => {
    event.stopPropagation();
    setUpdateCardInfo({ id, cartName });
    setIsUpsertModalOpen((prevState) => !prevState);
  };

  const setCartAsActive = (basket) => {
    if (!basket.isDefaultCart) {
      changeActiveCart(basket.id)
        .then(() => {
          const copyOfBasketOptions = [...basketOptions];
          copyOfBasketOptions.forEach((basketOption) => {
            basketOption.isDefaultCart = false;
            if (basketOption.id === basket.id) {
              basketOption.isDefaultCart = true;
            }
          });
          setBasketOptions(copyOfBasketOptions);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  };

  const simulate = (currency = null) => {
    const activeBasket = basketOptions.find((option) => option.isDefaultCart);
    if (activeBasket) {
      const copyOfItems = [...items];
      setLoading(true);

      const apiCurrency = currency ? currency : customerDefaultCurrency;

      simulateInCart(
        activeBasket.maturityPeriod,
        activeBasket.maturityPeriodDescription,
        activeBasket.configIds,
        copyOfItems.map((item) => ({
          productIdInCart: item.id,
          productId: item.stockCode,
          amount: item.unitPrice,
          quantity: item.quantity,
          stock: item.stock,
          currency: item.currency,
          maturity: item.maturityPeriod,
          maturityText: item.maturityPeriodDescription,
        })),
        apiCurrency,
      )
        .then((res) => {
          const basketItemsCandidate = [];
          const products = res.data.data.products;
          const localCurrencies = res.data.data.currencies;
          setCurrencyOptions(localCurrencies.map((localCurrency) => ({ key: localCurrency.currency, value: localCurrency.currency })));
          copyOfItems.forEach((copyItem) => {
            const productId = copyItem.stockCode;
            const foundProduct = products.find((product) => product.productId === productId);
            basketItemsCandidate.push({
              ...copyItem,
              unitPrice: foundProduct.price.unitPrice,
              totalPrice: foundProduct.price.amountWithoutVatRate,
              currency: foundProduct.price.currencyCode,
              stock: foundProduct.insufficientStock ? 0 : foundProduct.stock,
              stockDisplay: foundProduct.stockDisplay,
            });
          });

          setBasketItems(basketItemsCandidate);
          setOrderSummaryPart(res.data?.data?.amounts || []);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleActiveCartOperations = () => {
    if (basketOptions.length > 0) {
      const activeBasket = basketOptions.find((option) => option.isDefaultCart);
      if (activeBasket) {
        setLoading(true);
        getCartItems(activeBasket.id)
          .then((res) => {
            setCartItemNumber(res.data.totalCount);
            const candidateItems = res.data.data.map((item) => ({
              stockCode: item.stockCode,
              productBayinetUrl: item.productBayinetUrl,
              itemName: item.itemName,
              quantity: item.quantity,
              unitPrice: item.unitPrice,
              totalPrice: item.totalPrice,
              stock: item.stock,
              currency: item.currency,
              maturityPeriodDescription: item.maturityPeriodDescription,
              maturityPeriod: item.maturityPeriod,
              cartId: item.cartId,
              id: item.id,
            }));
            setItems(candidateItems);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
            setCartItemNumber(0);
          })
          .finally(() => {
            setLoading(false);
          });
        setCustomerDefaultCurrency(activeBasket.customerDefaultCurrency);
      }
    }
  };

  const updateCart = () => {
    simulate();
  };

  const onApproveDeleteBasket = () => {
    deleteCart(deleteBasketModal)
      .then((res) => {
        toast.success(res.data.message);
        setDeleteBasketModal(false);
        getBasketOptions();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const onCloseDeleteBasketModalHandler = () => {
    setDeleteBasketModal(false);
  };

  const onCurrencyChangeHandler = (event) => {
    const { value } = event.target;
    setCustomerDefaultCurrency(value);
    simulate(value);
  };

  return (
    <PentaStorageTabContainer>
      <BasketOptionContainer>
        <IconBasketContainer>
          <IconButtonContainer onClick={scrollToLeft}>
            <ArrowBackIosNewIcon />
          </IconButtonContainer>
          <BasketContainer ref={basketOptionRef}>
            {basketOptions.map((basketOption) => {
              return (
                <CartBasket
                  key={basketOption.id}
                  basketOption={basketOption}
                  onBasketUpdateHandler={(event) => onBasketUpdateHandler(event, basketOption.id, basketOption.cartName)}
                  onBasketDeleteHandler={(event) => onBasketDeleteHandler(event, basketOption.id)}
                  onClick={() => setCartAsActive(basketOption)}
                  borderColor={basketOption.isDefaultCart ? blueColors.blue500 : primaryColors.primary300}
                  backgroundColor={basketOption.isDefaultCart ? blueColors.blue50 : '#ffffff'}
                />
              );
            })}
          </BasketContainer>
          <IconButtonContainer onClick={scrollToRight}>
            <ArrowForwardIosIcon />
          </IconButtonContainer>
        </IconBasketContainer>
      </BasketOptionContainer>
      <GridContainer>
        <BigGridItem>
          <PSelect
            width={matchesSm ? '100%' : '280px'}
            value={customerDefaultCurrency}
            label={t('configMaster:currencySelection')}
            placeholder={t('configMaster:currencySelection')}
            menuItems={currencyOptions}
            onChange={onCurrencyChangeHandler}
          />
          <Box className="d-flex justify-content-between align-items-center flex-wrap gap-16">
            <Box className="d-flex align-items-center gap-8">
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {t('configMaster:basketDetail')}
              </Typography>
              <PInfoChip label={`${items.length} ${t('configMaster:product')}`} />
            </Box>
            <PButton
              width={matchesSm ? '100%' : 'auto'}
              inputtype="outline"
              onClick={updateCart}
              disabled={basketOptions.length === 0}
              startIcon={<IconResolver iconName="RefreshOutlined" />}>
              {t('configMaster:updateBasket')}
            </PButton>
          </Box>
          {basketOptions.length === 0 && (
            <Box className="d-flex justify-content-center">
              <Typography
                color={primaryColors.primary400}
                variant="titleMedium">
                {t('configMaster:thereAreNoProductsInYourCart')}
              </Typography>
            </Box>
          )}
          {basketOptions.length > 0 &&
            basketItems.map((item) => {
              return (
                <BasketItem
                  key={item.id}
                  stockCode={item.stockCode}
                  itemName={item.itemName}
                  quantity={item.quantity}
                  unitPrice={item.unitPrice}
                  totalPrice={item.totalPrice}
                  stock={item.stock}
                  currency={item.currency}
                  maturityPeriodDescription={item.maturityPeriodDescription}
                  productBayinetUrl={item.productBayinetUrl}
                  stockDisplay={item.stockDisplay}
                />
              );
            })}
        </BigGridItem>
        <SmallGridItem>
          <OrderSummary
            orderSummaryPart={orderSummaryPart}
            setShowOrderDetail={setShowOrderDetail}
            basketItems={basketItems}
            basketOptions={basketOptions}
            items={items}
            customerDefaultCurrency={customerDefaultCurrency}
            setLoading={setLoading}
          />
        </SmallGridItem>
      </GridContainer>
      {isUpsertModalOpen && (
        <UpdateCart
          cardInfo={updateCardInfo}
          getAllCarts={getBasketOptions}
          setIsUpsertModalOpen={setIsUpsertModalOpen}
          setLoading={setLoading}
        />
      )}
      {loading && <LoadingSpinner />}
      {deleteBasketModal && (
        <ApproveModal
          onClose={onCloseDeleteBasketModalHandler}
          approveClick={onApproveDeleteBasket}
          bodyText={t('configMaster:removeSelectedRow')}
        />
      )}
    </PentaStorageTabContainer>
  );
};
export default ConfigMasterCart;
