import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import PButton from 'components/button/PButton';
import PErrorChip from 'components/chip/PErrorChip';
import PSuccessChip from 'components/chip/PSuccessChip';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PMultiSelectAutocomplete from 'components/select/PMultiSelectAutocomplete';
import PSelect from 'components/select/PSelect';
import PClientTable from 'components/table/PClientTable';
import PTextField from 'components/textField/PTextField';
import { blueColors, primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import MainContainer from 'layout/container/MainContainer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addZeroToNumber } from 'utils/HelperFunctions';
import { getAllAdress, getCities } from '../api/DeliveryAddressApi';
import DeliveryListTableMenu from './DeliveryListTableMenu';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'baseline',
  },
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&>*': {
      width: '100% !important',
    },
  },
}));

const ShowLoadedExcel = styled(Box)(({ theme }) => ({
  border: `1px solid ${blueColors.blue300}`,
  backgroundColor: blueColors.blue50,
  padding: '12px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    height: '60px',
  },
}));

const AddButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

const DeliveryAddressList = () => {
  const [cityData, setCityData] = useState([]);
  const [data, setData] = useState({ allData: [], visibleData: [] });
  const [branchCode, setBranchCode] = useState('');
  const [activePassive, setActivePassive] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setIsLoading(true);
    getAllAdress()
      .then((addresses) => {
        const local = [];
        addresses.data.data.address.forEach((address) => {
          address.id = `${address.addressNumber} ${address.receiver}`;
          local.push(address);
        });
        setData({ allData: local, visibleData: local });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
    getCities()
      .then((res) => {
        setCityData(res.data.data.map((city) => ({ key: addZeroToNumber(city.cityCode), value: city.cityName })));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'receiver',
        headerName: t('deliveryAddress:tableBranchCode'),
        minWidth: 190,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'name',
        headerName: t('deliveryAddress:branch'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'address1',
        headerName: t('deliveryAddress:address'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'phone',
        headerName: t('deliveryAddress:phone'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'loevm',
        headerName: t('deliveryAddress:status'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ value }) => {
          if (!value) {
            return <PSuccessChip label={t('userManagement:active')} />;
          } else {
            return <PErrorChip label={t('userManagement:passive')} />;
          }
        },
      },
      {
        field: 'isAdmin',
        headerName: '',
        width: 72,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        renderCell: ({ row }) => (
          <DeliveryListTableMenu
            row={row}
            loaderFunction={getDataFromFilter}
          />
        ),
      },
    ];
  }, [t]);

  const activePassiveItems = useMemo(() => {
    return [
      { key: 'all', value: t('deliveryAddress:all') },
      { key: 'active', value: t('deliveryAddress:active') },
      { key: 'passive', value: t('deliveryAddress:passive') },
    ];
  }, [t]);

  const addDeliveryAddress = () => {
    navigate(`${paths.add}${paths.customer}${paths.myDeliveryAdresses}`);
  };

  const onCityChangeHandler = (_event, newValue) => {
    setSelectedCities(newValue);
  };

  const onChangeActivePassive = (event) => {
    setActivePassive(event.target.value);
  };

  const onCityDeleteHandler = (value) => {
    const copyOfSelectedCities = [...selectedCities];
    const removedNumberArray = copyOfSelectedCities.filter((city) => city.key !== value.key);
    setSelectedCities(removedNumberArray);
  };

  const getDataFromFilter = () => {
    setIsLoading(true);
    getAllAdress()
      .then((addresses) => {
        let local = [];
        addresses.data.data.address.forEach((address) => {
          address.id = `${address.addressNumber} ${address.receiver}`;
          local.push(address);
        });

        if (activePassive !== 'all') {
          let copyActivePassive = activePassive;
          if (copyActivePassive === 'passive') {
            copyActivePassive = 'X';
          } else {
            copyActivePassive = '';
          }
          local = local.filter((data) => data.loevm === copyActivePassive);
        }
        if (branchCode) {
          local = local.filter((data) => data.receiver === branchCode);
        }

        if (selectedCities.length > 0) {
          local = local.filter((item) => {
            const cityKey = item.city.toString();
            return selectedCities.some((option) => option.key.toString() === cityKey);
          });
        }

        setData({ allData: local, visibleData: local });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
    setSearchValue('');
  };

  const onSearchHandler = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({ receiver: e.receiver, name: e.name, address1: e.address1, phone: e.phone })
          .join(' ')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };

  const onBranchCodeChange = (event) => {
    const value = event.target.value;

    setBranchCode(value);
  };

  const addExcelModalHandler = () => {
    navigate(`${paths.customer}${paths.myDeliveryAdresses}${paths.createBulk}`);
  };

  const openUploadedExcel = () => {
    navigate(`${paths.customer}${paths.myDeliveryAdresses}${paths.checkExcel}`);
  };

  return (
    <MainContainer>
      <Box className="gap-16 d-flex flex-column">
        <HeaderContainer>
          <Box>
            <div>
              <Typography
                variant="titleLarge"
                color={primaryColors.primary}>
                {t('deliveryAddress:myDeliveryAddresses')}
              </Typography>
            </div>
            <div>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {t('deliveryAddress:manageYourDeliveryAddresses')}
              </Typography>
            </div>
          </Box>
          <AddButtonContainer>
            {userPermissions.find((permission) => permission.permissionType === 'Permissions.DeliveryAddress.AddBulkDeliverAddress') && (
              <PButton
                width={matchesSm ? '100%' : 'auto'}
                inputtype="outline"
                onClick={addExcelModalHandler}
                startIcon={<PlaylistAddIcon />}>
                {t('deliveryAddress:addBulkAddress')}
              </PButton>
            )}
            <PButton
              width={matchesSm ? '100%' : 'auto'}
              startIcon={<AddIcon />}
              onClick={addDeliveryAddress}>
              {t('deliveryAddress:addNewAddress')}
            </PButton>
          </AddButtonContainer>
        </HeaderContainer>
        <PDivider sx={{ margin: '0' }} />
        <ActionContainer className="d-flex gap-16 align-items-end flex-wrap">
          <Box sx={{ width: !matchesMd ? 'auto' : '210px' }}>
            <PTextField
              value={branchCode}
              label={t('deliveryAddress:branchCode')}
              placeholder={t('deliveryAddress:branchCode')}
              onChange={onBranchCodeChange}
              showHelperContainer={false}
            />
          </Box>
          <Box>
            <PMultiSelectAutocomplete
              preventMessage={selectedCities.length === 3 && t('deliveryAddress:youCanOnlyMakeNumberChoices', { number: 3 })}
              noOptionsText={t('deliveryAddress:noResultFound')}
              onDeleteHandler={onCityDeleteHandler}
              value={selectedCities}
              onChange={onCityChangeHandler}
              label={t('deliveryAddress:selectCity')}
              placeholder={t('deliveryAddress:addCity')}
              menuItems={cityData}
              showHelperContainer={false}
            />
          </Box>
          <Box sx={{ width: !matchesMd ? 'auto' : '210px' }}>
            <PSelect
              label={t('deliveryAddress:activePassive')}
              value={activePassive}
              menuItems={activePassiveItems}
              onChange={onChangeActivePassive}
              showHelperContainer={false}
            />
          </Box>
          <Box>
            <PButton
              inputtype="tonal"
              onClick={getDataFromFilter}>
              {t('deliveryAddress:fetchAddresses')}
            </PButton>
          </Box>
        </ActionContainer>
        <Box sx={{ maxWidth: '428px' }}>
          <ShowLoadedExcel>
            <Box className="d-flex align-items-center gap-4">
              <CloudUploadOutlinedIcon
                sx={{ color: blueColors.blue600 }}
                fontSize="small"
              />
              <Typography variant="bodyMedium"> {t('deliveryAddress:theProcessingOfBulkAdded')}</Typography>
            </Box>
            <PButton
              sx={{ height: '20px' }}
              width="auto"
              inputtype="text"
              onClick={openUploadedExcel}
              startIcon={<VisibilityOutlinedIcon />}>
              {t('deliveryAddress:view')}
            </PButton>
          </ShowLoadedExcel>
        </Box>
        <PClientTable
          headerRightSide={
            <TableSearchTextField
              value={searchValue}
              onChange={onSearchHandler}
              placeholder={t('deliveryAddress:searchAddress')}
            />
          }
          dataLength={`${data.allData.length} ${t('deliveryAddress:piece')}`}
          headerTitle={t('deliveryAddress:myRegisteredAddresses')}
          loading={isLoading}
          columns={columns}
          rows={data.visibleData}
          noRowsMessageKey="deliveryAddress:noMatchingRecordsFound"
          getRowHeight={() => 'auto'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'receiver', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </MainContainer>
  );
};

export default DeliveryAddressList;
