import styled from '@emotion/styled';
import { Tabs } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

const CustomTabs = styled(Tabs)(() => ({
  '.MuiTabs-flexContainer': {
    '&>*:nth-of-type(1)': {
      padding: 0,
    },
  },
  '.MuiButtonBase-root': {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.10000000149011612px',
    color: primaryColors.primary,
  },
  '.Mui-selected': {
    color: `${primaryColors.primary} !important`,
  },
  '.MuiTabs-indicator': {
    height: '3px',
    backgroundColor: primaryColors.primary,
    borderRadius: ' 100px 100px 0px 0px',
  },
  '.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.38) !important',
  },
  '.MuiTabs-scroller': {
    borderBottom: `1px solid ${primaryColors.primary200}`,
  },
}));

const PTabs = ({ value, onChange, children, ...rest }) => {
  return (
    <CustomTabs
      value={value}
      onChange={onChange}
      {...rest}>
      {children}
    </CustomTabs>
  );
};

export default PTabs;
