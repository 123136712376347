import { Grid, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PPasswordTextField from 'components/textField/PPasswordTextField';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import MainContainer from 'layout/container/MainContainer';
import { logout } from 'pages/login/api/LoginApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import globalRouter from 'utils/GlobalRouter';
import { changePassword } from './api/ChangePasswordApi';

const ChangePassword = () => {
  const { t } = useTranslation();

  const [passwordField, setPasswordField] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
  });

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setPasswordField((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    if (passwordField.newPassword !== passwordField.newPasswordRepeat) {
      toast.error(t('changePassword:newPasswordsMustBeSame'));
    } else {
      changePassword({
        currentPassword: passwordField.oldPassword,
        newPassword: passwordField.newPassword,
        newPassword2: passwordField.newPasswordRepeat,
      })
        .then((res) => {
          toast.success(res.data.message);
          logout()
            .then(() => {
              localStorage.clear();
              globalRouter.navigate(paths.home);
            })
            .catch((err) => {
              toast.error(err.response.data.Message);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  };

  return (
    <MainContainer>
      <Typography variant="titleLarge"> {t('changePassword:passwordOperations')}</Typography>
      <br />
      <form>
        <Grid
          container
          spacing={2}
          sx={{
            paddingRight: 2,
            paddingBottom: 2,
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            margin: 0,
            border: `1px solid ${primaryColors.primary200}`,
          }}>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <Typography variant="titleMedium"> {t('changePassword:youCanChangeYourPasswordFromThisPage')}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <PPasswordTextField
              autoComplete="new-password"
              sx={{ width: '250px' }}
              value={passwordField.oldPassword}
              name="oldPassword"
              label={t('changePassword:oldPassword')}
              onChange={onChangeHandler}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <PPasswordTextField
              autoComplete="new-password"
              sx={{ width: '250px' }}
              value={passwordField.newPassword}
              name="newPassword"
              label={t('changePassword:newPassword')}
              onChange={onChangeHandler}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <PPasswordTextField
              autoComplete="new-password"
              sx={{ width: '250px' }}
              value={passwordField.newPasswordRepeat}
              name="newPasswordRepeat"
              label={t('changePassword:newPasswordRepeat')}
              onChange={onChangeHandler}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ marginBottom: '16px' }}>
            <PButton
              disabled={!(passwordField.oldPassword && passwordField.newPassword && passwordField.newPasswordRepeat)}
              sx={{ width: '250px' }}
              onClick={validateForm}>
              {t('changePassword:changePassword')}
            </PButton>
          </Grid>
        </Grid>
      </form>
    </MainContainer>
  );
};

export default ChangePassword;
