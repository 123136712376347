import { Grid, Typography } from '@mui/material';
import { createCurrentUser } from 'common/reducers/CurrentUserReducer';
import PButton from 'components/button/PButton';
import OtpInput from 'components/otpInput/OtpInput';
import { slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resendOtp, verifyOtp } from './api/LoginApi';
import SendOtpToPhone from './components/SendOtpToPhone';

const MailOtp = ({ formField, setIsLoading, setFormField, setPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  useEffect(() => {
    let timerId;

    if (runTimer) {
      setButtonDisable(true);
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
      setButtonDisable(false);
    }
  }, [countDown, runTimer]);

  const getToken = () => {
    if (otpValue?.length === 6) {
      setIsLoading(true);
      verifyOtp({ emailOrPhone: formField.email, otp: otpValue })
        .then((res) => {
          dispatch(createCurrentUser(res.data.data));

          navigate(paths.protectedHome);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      toast.error(t('login:authenticationFailedPleaseTryAgain'));
    }
  };

  const hideMail = () => {
    let parts = formField.email.split('@');
    let username = parts[0];
    let firstChar = username.charAt(0);
    let lastChar = username.charAt(username.length - 1);
    let hiddenUsername = firstChar + '*'.repeat(username.length - 2) + lastChar;
    return hiddenUsername + '@' + parts[1];
  };

  const resendOtpHandler = () => {
    if (!buttonDisable) {
      setOtpValue('');
      setIsLoading(true);
      const data = {
        sapCustomerId: formField.sapCustomerId,
        emailOrPhone: formField.email,
      };
      resendOtp(data)
        .then(() => {
          setButtonDisable(true);
          setRunTimer(true);
          setCountDown(60 * 5);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const modalOpenHandler = () => {
    setOpenModal((prevState) => !prevState);
  };
  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  return (
    <Grid
      container
      spacing={2}
      sx={{ paddingRight: 2, paddingBottom: 2, backgroundColor: '#ffffff', borderRadius: '12px' }}>
      <Grid
        item
        xs={12}>
        <Typography
          variant="headlineSmall"
          sx={{ color: slateColors.slate950 }}>
          {t('login:enterVerificationCode')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}>
        <Typography
          variant="bodyMedium"
          sx={{ color: slateColors.slate600 }}>
          {t('login:verificationCodeSendToMail', { mail: hideMail() })}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}>
        <Typography
          variant="labelLarge"
          sx={{ color: slateColors.slate600 }}>
          <b>
            {t('login:remainingTime')} : {minutes}:{seconds}
          </b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}>
        <OtpInput
          value={otpValue}
          onChange={setOtpValue}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className="text-end cursor-pointer"
        onClick={resendOtpHandler}>
        <Typography
          variant="labelLarge"
          sx={{ color: buttonDisable ? 'rgba(0,0,0, 0.38)' : slateColors.slate600 }}>
          <b>{t('login:resendCode')}</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className="text-end cursor-pointer">
        <PButton onClick={getToken}>{t('login:verify')}</PButton>
      </Grid>
      <Grid
        item
        xs={12}
        className="text-center cursor-pointer">
        <Typography
          variant="labelLarge"
          sx={{ color: slateColors.slate600 }}
          onClick={modalOpenHandler}>
          <b>{t('login:sendCodeToPhoneNumbber')}</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className="text-center cursor-pointer">
        <Typography
          variant="labelLarge"
          sx={{ color: slateColors.slate600 }}
          onClick={() => setPage(4)}>
          <b>{t('login:signInWithGoogle')}</b>
        </Typography>
      </Grid>
      {openModal && (
        <SendOtpToPhone
          modalOpenHandler={modalOpenHandler}
          setIsLoading={setIsLoading}
          setFormField={setFormField}
          formField={formField}
          setPage={setPage}
        />
      )}
    </Grid>
  );
};

export default MailOtp;
