import { Box } from '@mui/system';
import Tags from 'components/tagTextField/Tags';
import PTextField from 'components/textField/PTextField';
import { useEffect, useRef, useState } from 'react';

const TagTextField = ({ placeholder, tags, onChange, label, helpertext, maxLenght = 100, error, errorMessage }) => {
  const [localTags, setLocalTags] = useState([]);
  const tagRef = useRef();

  useEffect(() => {
    setLocalTags(tags);
  }, [tags]);

  const handleDelete = (value) => {
    const newlocalTags = localTags.filter((val) => val !== value);
    setLocalTags(newlocalTags);
    onChange(newlocalTags);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (maxLenght === tags.length) {
      return;
    }
    if (tagRef.current.value.length > 0) {
      setLocalTags([...localTags, tagRef.current.value]);
      onChange([...localTags, tagRef.current.value]);
      tagRef.current.value = '';
    }
  };
  return (
    <form onSubmit={handleOnSubmit}>
      <PTextField
        showPlaceholder={localTags.length === 0 ? true : false}
        inputWidth="auto"
        sx={{ height: 'auto' }}
        label={label}
        showLength={false}
        inputRef={tagRef}
        placeholder={localTags.length === 0 ? placeholder : ''}
        helpertext={helpertext}
        error={error}
        errorMessage={errorMessage}
        InputProps={{
          startAdornment: (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', width: 'auto', gap: '8px' }}>
              {localTags.map((data, index) => {
                return (
                  <Tags
                    data={data}
                    handleDelete={handleDelete}
                    key={index}
                  />
                );
              })}
            </Box>
          ),
        }}
      />
    </form>
  );
};

export default TagTextField;
