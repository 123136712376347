import { Grid, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PCenterModal from 'components/modal/PCenterModal';
import { slateColors } from 'config/theme/Colors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getMobileOrMail, resendOtp } from '../api/LoginApi';

const SendOtpToPhone = ({ modalOpenHandler, setIsLoading, setFormField, setPage, formField }) => {
  const [incomingData, setIncomingData] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    setIsLoading(true);
    getMobileOrMail(formField.email)
      .then((res) => {
        setIncomingData(res.data.data.phone);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        closeModal();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const closeModal = () => {
    modalOpenHandler();
    setIsLoading(false);
  };

  const sendOtpCode = () => {
    setIsLoading(true);
    const data = {
      sapCustomerId: formField.sapCustomerId,
      emailOrPhone: incomingData,
    };
    resendOtp(data)
      .then(() => {
        setFormField((prevState) => ({ ...prevState, email: incomingData }));
        closeModal();
        setPage(1);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PCenterModal onClose={closeModal}>
      {incomingData && (
        <Grid
          container
          spacing={2}>
          <Grid
            item
            xs={12}>
            <Typography
              variant="bodyMedium"
              sx={{ color: slateColors.slate600 }}>
              {t('login:theVerificationCodeWillBeSentToThePhoneNumber', { phone: `********${incomingData.slice(-2)}` })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PButton onClick={sendOtpCode}>{t('login:yes')}</PButton>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PButton onClick={closeModal}>{t('login:no')}</PButton>
          </Grid>
        </Grid>
      )}
    </PCenterModal>
  );
};

export default SendOtpToPhone;
