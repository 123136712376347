import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import { styled, Typography } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';

const PaymentItemContainer = styled('button')(({ selected, disabled }) => {
  const background = selected ? primaryColors.primary : '#ffffff';
  const color = selected ? '#ffffff' : primaryColors.primary;
  const hoverBackground = selected ? primaryColors.primary700 : primaryColors.primary200;
  if (disabled) {
    return {
      flex: 1,
      border: `1px solid ${primaryColors.primary200}`,
      display: 'flex',
      padding: '8px',
      alignItems: 'center',
      color: color,
      borderRadius: '6px',
      width: '100%',
      gap: '8px',
      backgroundColor: background,
      opacity: 0.3,
    };
  }
  return {
    flex: 1,
    border: `1px solid ${primaryColors.primary200}`,
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    color: color,
    borderRadius: '6px',
    width: '100%',
    cursor: 'pointer',
    gap: '8px',
    backgroundColor: background,
    '&:hover': {
      backgroundColor: hoverBackground,
    },
  };
});

const OrderDetailPayment = ({ disableButtons, formData, changePaymentType, incomingData }) => {
  const { t } = useTranslation();
  return (
    <>
      <PaymentItemContainer
        disabled={disableButtons}
        selected={formData.selectedPayment === 1}
        onClick={() => changePaymentType(1)}>
        <AccountBalanceWalletOutlinedIcon />
        <Typography variant="bodyMedium">{t('configMaster:openAccount')}</Typography>
      </PaymentItemContainer>
      <PaymentItemContainer
        disabled={disableButtons}
        selected={formData.selectedPayment === 2}
        onClick={() => changePaymentType(2)}>
        <AccountBalanceOutlinedIcon />
        <Typography variant="bodyMedium">{t('configMaster:bankTransfer')}</Typography>
      </PaymentItemContainer>
      <PaymentItemContainer
        disabled={!(incomingData?.has3dPermission || incomingData?.hasVposPermission) || disableButtons}
        selected={formData.selectedPayment === 3}
        onClick={() => changePaymentType(3)}>
        <PaymentOutlinedIcon />
        <Typography variant="bodyMedium">{t('configMaster:onlinePayment')}</Typography>
      </PaymentItemContainer>
    </>
  );
};

export default OrderDetailPayment;
