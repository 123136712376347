import AddIcon from '@mui/icons-material/Add';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PErrorChip from 'components/chip/PErrorChip';
import PSuccessChip from 'components/chip/PSuccessChip';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PClientTable from 'components/table/PClientTable';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import MainContainer from 'layout/container/MainContainer';
import TableMenu from 'pages/userManagement/TableMenu';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllUsers } from './api/UserManagementApi';
const UserManagement = () => {
  const currentUser = useSelector((state) => state.currentUser.currentUser);

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState({ allData: [], visibleData: [] });
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        field: 'isAdmin',
        headerName: ' ',
        width: 20,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          if (row.isAdmin) {
            return <StarOutlineIcon />;
          } else {
            return '';
          }
        },
      },
      {
        field: 'name',
        headerName: t('userManagement:name'),
        minWidth: 190,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'surname',
        headerName: t('userManagement:lastname'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'mobile',
        headerName: t('userManagement:phoneNumber'),
        minWidth: 250,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'email',
        headerName: t('userManagement:email'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'isActive',
        headerName: t('userManagement:status'),
        width: 160,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          if (row.isActive) {
            return <PSuccessChip label={t('userManagement:active')} />;
          } else {
            return <PErrorChip label={t('userManagement:passive')} />;
          }
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 64,
        sortable: false,
        align: 'right',
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          if (!currentUser.user.isAdmin && row.isAdmin) {
            return <></>;
          }
          return (
            <TableMenu
              row={row}
              loaderFunction={loaderFunction}
            />
          );
        },
      },
    ];
  }, [t]);

  const loaderFunction = () => {
    setLoading(true);
    getAllUsers({ sapCustomerId: currentUser.user.sapCustomerId, pageSize: 1000, page: 1 })
      .then((res) => {
        console.log(res.data.data);
        const allData = res.data.data;
        setData((prevState) => ({ ...prevState, allData: allData, visibleData: allData }));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loaderFunction();
  }, []);

  const onChangeSearch = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({ name: e.name, surname: e.surname, email: e.email, mobile: e.mobile })
          .join(' ')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };

  return (
    <MainContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
        <Box>
          <Typography
            variant="titleLarge"
            sx={{ color: neutralColors.neutral950 }}>
            {t('userManagement:userManagement')}
          </Typography>
          <br />
          <Typography
            variant="bodyMedium"
            sx={{ color: primaryColors.primary700 }}>
            {t('userManagement:editUsersAndPermissions')}
          </Typography>
        </Box>
        <Box className="d-flex align-items-center gap-20">
          <PButton
            startIcon={<AddIcon />}
            to={`${paths.userManagement}${paths.addUserManagement}`}
            component={Link}>
            {t('userManagement:addUser')}
          </PButton>
        </Box>
      </Box>
      <PClientTable
        headerRightSide={
          <TableSearchTextField
            placeholder={t('userManagement:searchUser')}
            value={searchValue}
            onChange={onChangeSearch}
            showHelperContainer={false}
            showLabel={false}
          />
        }
        dataLength={`${data.allData.length} ${t('userManagement:person')}`}
        headerTitle={t('userManagement:userList')}
        loading={loading}
        columns={columns}
        rows={data.visibleData}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
    </MainContainer>
  );
};

export default UserManagement;
