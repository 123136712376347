import { useTheme } from '@emotion/react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import PSuccessChip from 'components/chip/PSuccessChip';
import SingleFileUpload from 'components/fileUpload/SingleFileUpload';
import PClientTable from 'components/table/PClientTable';
import { blueColors, primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addBulkAddressWithExcel, downloadTemplate } from '../api/DeliveryAddressApi';

const AddressExcelBulkContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '17px',
  background: '#ffffff',
  borderRadius: '12px',
}));

const ItemContainer = styled(Box)(({ toppadding = 0 }) => ({
  padding: `${toppadding}px 24px 0px 24px`,
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Info = styled(Box)(() => ({
  display: 'flex',
  border: `1px solid ${blueColors.blue300}`,
  backgroundColor: blueColors.blue50,
  borderRadius: '8px',
  padding: '12px',
  justifyContent: 'space-between',
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',
  padding: '0 24px 24px 24px',
}));

const AddBulkAddressExcel = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isSuccess, setIsSuccess] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [queryMessage, setQueryMessage] = useState('');
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOnChange = (event) => {
    setProgress(0);
    setFile(event.target.files[0]);
  };

  const dragAndDrop = (files) => {
    setProgress(0);
    setFile(files[0]);
  };

  const deleteFile = () => {
    setFile(null);
  };

  const handleBulkAddressCreate = () => {
    setIsSuccess(null);
    setProgress(0);
    const formData = new FormData();
    formData.append('file', file);

    const onUploadProgress = (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(progress);
    };

    addBulkAddressWithExcel(formData, onUploadProgress)
      .then((res) => {
        setIsSuccess(true);
        setQueryMessage(res.data.data.queryCode);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        toast.error(err.response.data.message);

        if (err.response.data?.data?.errors) {
          const errorArray = [];
          err.response.data.data.errors.forEach((error) => {
            error.id = error.rowNo;
            errorArray.push(error);
          });
          setIsSuccess(false);
          setTableData(errorArray);
        }
      });
  };

  const downloadFile = () => {
    downloadTemplate()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Toplu Adres Ekleme Şablon.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'companyName',
        headerName: t('deliveryAddress:companyName'),
        width: 150,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',
      },
      {
        field: 'rowNo',
        headerName: t('deliveryAddress:rowNumber'),
        width: 130,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',
      },
      {
        field: 'errorMessages',
        headerName: t('deliveryAddress:message'),
        minWidth: 300,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',

        renderCell: ({ value }) => (
          <ul>
            {value.map((singleValue) => (
              <li key={singleValue}>{singleValue}</li>
            ))}
          </ul>
        ),
      },
    ];
  }, [t]);

  return (
    <>
      <SecondaryLayout
        path={`${paths.customer}${paths.myDeliveryAdresses}`}
        title={t('deliveryAddress:addBulkAddress')}>
        <AddressExcelBulkContainer>
          <ItemContainer toppadding={20}>
            <Header>
              <div className="d-flex align-items-center gap-8">
                <Typography
                  variant="titleMedium"
                  color={primaryColors.primary}>
                  {t('deliveryAddress:addBulkAddress')}
                </Typography>
                <PSuccessChip label={'EXCEL'} />
              </div>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {t('deliveryAddress:addBulkAddressExcel')}
              </Typography>
            </Header>
          </ItemContainer>
          <PDivider />
          <ItemContainer>
            <Info>
              <div className="d-flex align-items-center">
                <InfoOutlinedIcon sx={{ color: blueColors.blue600, marginRight: '10px' }} />
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {t('deliveryAddress:downloadAndCheckTheTemplateBeforeUploading')}
                </Typography>
              </div>
              <div>
                <PButton
                  inputtype="text"
                  onClick={downloadFile}
                  startIcon={<FileDownloadOutlinedIcon />}>
                  {t('deliveryAddress:downloadTemplate')}
                </PButton>
              </div>
            </Info>
          </ItemContainer>
          <ItemContainer>
            <SingleFileUpload
              texMessage={t('deliveryAddress:xlsMaxSize')}
              onChange={handleOnChange}
              file={file}
              deleteFile={deleteFile}
              dragAndDrop={dragAndDrop}
              progress={progress}
              isSuccess={isSuccess}
            />
          </ItemContainer>
          {isSuccess === true && (
            <ItemContainer>
              <InfoAlert
                label={
                  <Trans
                    values={{ number: queryMessage }}
                    i18nKey="deliveryAddress:onSuccessFileUpload"
                    components={{
                      CustomLink: (
                        <Link
                          className="hyperlink-text"
                          to={`${paths.customer}${paths.myDeliveryAdresses}${paths.checkExcel}?query-message=${queryMessage}`}
                        />
                      ),
                    }}
                  />
                }
              />
            </ItemContainer>
          )}
          <Footer>
            <PButton
              disabled={!file}
              onClick={handleBulkAddressCreate}
              width={matchesSm ? '100%' : 'auto'}>
              {t('deliveryAddress:uploadAddressesToTheSystem')}
            </PButton>
          </Footer>
          {isSuccess === false && (
            <ItemContainer>
              <PClientTable
                dataLength={`${tableData.length} ${t('deliveryAddress:piece')}`}
                headerTitle={t('deliveryAddress:addedFromBulkAddressFile')}
                rows={tableData}
                columns={columns}
                getRowHeight={() => 'auto'}
              />
            </ItemContainer>
          )}
        </AddressExcelBulkContainer>
      </SecondaryLayout>
    </>
  );
};
export default AddBulkAddressExcel;
