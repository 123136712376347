import { Box, styled, Typography } from '@mui/material';
import ApproveModal from 'components/approveModal/ApproveModal';
import PButton from 'components/button/PButton';
import PInfoChip from 'components/chip/PInfoChip';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { blueColors, primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import { deleteCartOption, getActiveCartItems, getAllCarts, getCartItemCount, setCartAsCurrent, updateCartItemQuantity } from 'pages/pay/api/PayApi';
import { payTabNumbers } from 'pages/pay/constants/PayTabNumbers';
import ProductItem from 'pages/pay/tabs/subscriptionCart/components/ProductItem';
import SubscriptionCartBasket, { BasketOptions } from 'pages/pay/tabs/subscriptionCart/components/SubscriptionCartBasket';
import SubscriptionSummary from 'pages/pay/tabs/subscriptionCart/components/SubscriptionSummary';
import UpsertCartModal from 'pages/pay/tabs/subscriptionCart/components/UpsertCartModal';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Contianer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  paddingBottom: '12px',
}));

const BasketOptionContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
}));

const IconBasketContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '32px',
  alignItems: 'center',
  overflow: 'hidden',
}));

const IconButtonContainer = styled('button')(({ disabled }) => ({
  margin: 0,
  padding: 0,
  height: 'auto',
  width: 'auto',
  outline: 0,
  border: 0,
  background: 'transparent',
  cursor: disabled ? 'normal' : 'pointer',
}));

const BasketContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '16px',
  overflow: 'hidden',
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const Main = styled(Box)(() => ({
  flex: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const Summary = styled(Box)(() => ({
  flex: 1,
}));

const Title = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

const TitleAndItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  '&:last-child>hr': {
    display: 'none',
  },
}));

const SubscriptionCart = ({ setShowOrderDetail, tabValue, setCartItemNumber }) => {
  const { t } = useTranslation();
  const basketOptionRef = useRef();
  const [basketOptions, setBasketOptions] = useState([]);
  const [activeBasket, setActiveBasket] = useState(null);
  const [scrollable, setScrollable] = useState(false);
  const [upsertCart, setUpsertCart] = useState(false);
  const [updateCardInfo, setUpdateCardInfo] = useState({ id: null, cartName: '' });
  const [deleteBasketModal, setDeleteBasketModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [incomingDatas, setIncomingDatas] = useState([]);
  useEffect(() => {
    setScrollable(basketOptionRef.current?.scrollWidth > basketOptionRef.current?.clientWidth);
  }, [basketOptionRef.current?.clientWidth, basketOptionRef.current?.scrollWidth, basketOptions.length]);

  useEffect(() => {
    if (tabValue === payTabNumbers.subscriptionCart) {
      getBasketOptions();
    } else {
      setActiveBasket(null);
    }
  }, [tabValue]);

  const scrollToRight = () => {
    basketOptionRef.current.scrollLeft += 100;
  };

  const scrollToLeft = () => {
    basketOptionRef.current.scrollLeft -= 100;
  };

  useEffect(() => {
    if (activeBasket) {
      getActiveCartItemsFn();
    }
  }, [activeBasket]);

  const getActiveCartItemsFn = () => {
    getCartItemCount()
      .then((res) => {
        setCartItemNumber(res.data.data.itemCount);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
    setLoading(true);
    getActiveCartItems(activeBasket.id)
      .then((res) => {
        setIncomingDatas(res.data.data);
      })
      .catch(() => {
        setIncomingDatas([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBasketOptions = () => {
    setLoading(true);
    getAllCarts()
      .then((res) => {
        const incomingResponse = res.data.data;
        const currentlyUsingCartId = incomingResponse.currentlyUsingCartId;

        incomingResponse.carts.forEach((cart) => {
          cart.isCurrentlyUsing = cart.id === currentlyUsingCartId;
          if (cart.id === currentlyUsingCartId) {
            setActiveBasket(cart);
          }
        });
        const orderedByDate = incomingResponse.carts.sort((a, b) => dayjs(b.createdDate) - dayjs(a.createdDate));
        const orderedBaskets = orderedByDate.sort((a, b) => b.isDefaultCart - a.isDefaultCart);
        setBasketOptions(orderedBaskets);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setCartAsActive = (basket) => {
    if (!basket.isCurrentlyUsing) {
      setCartAsCurrent(basket.id)
        .then(() => {
          const copyOfBasketOptions = [...basketOptions];
          copyOfBasketOptions.forEach((basketOption) => {
            basketOption.isCurrentlyUsing = false;
            if (basketOption.id === basket.id) {
              setActiveBasket(basketOption);
              basketOption.isCurrentlyUsing = true;
            }
          });
          getCartItemCount()
            .then((res) => {
              setCartItemNumber(res.data.data.itemCount);
              setBasketOptions(copyOfBasketOptions);
            })
            .catch((err) => {
              toast.error(err.response.data.Message);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  };

  const toogleCartModal = () => {
    setUpsertCart((prevState) => !prevState);
  };

  const updateValuesOperation = (updateValues) => {
    const copyOfBasketOptions = [...basketOptions];
    copyOfBasketOptions.forEach((basketOption) => {
      if (basketOption.id === updateValues.id) {
        basketOption.cartName = updateValues.cartName;
      }
    });
    setBasketOptions(copyOfBasketOptions);
  };

  const onCloseDeleteBasketModalHandler = () => {
    setDeleteBasketModal(false);
  };

  const onBasketDeleteHandler = (event, id) => {
    event.stopPropagation();
    setDeleteBasketModal(id);
  };

  const onApproveDeleteBasket = () => {
    deleteCartOption(deleteBasketModal)
      .then((res) => {
        toast.success(res.data.message);
        setDeleteBasketModal(false);
        getBasketOptions();
        getCartItemCount()
          .then((res) => {
            setCartItemNumber(res.data.data.itemCount);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const onBasketUpdateHandler = (event, id, cartName) => {
    event.stopPropagation();
    setUpdateCardInfo({ id, cartName });
    toogleCartModal();
  };

  const updateCartHandler = () => {
    const updateProductList = [];
    setLoading(true);
    incomingDatas.forEach(({ byGroups }) => {
      byGroups.forEach(({ products }) => {
        products.forEach(({ cartItemId, quantity }) => {
          updateProductList.push({
            cartItemId: cartItemId,
            quantity: quantity,
          });
        });
      });
    });
    updateCartItemQuantity(updateProductList)
      .then(() => {
        setTimeout(() => {
          getActiveCartItemsFn();
        }, 0);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateProductQuantity = (cartItemId, newQuantity) => {
    setIncomingDatas((prevData) =>
      prevData.map((data) => {
        const updatedByGroups = data.byGroups.map((group) => {
          const updatedProducts = group.products.map((product) =>
            product.cartItemId === cartItemId ? { ...product, quantity: newQuantity } : product,
          );

          return updatedProducts.some((p, index) => p !== group.products[index]) ? { ...group, products: updatedProducts } : group;
        });

        return updatedByGroups.some((g, index) => g !== data.byGroups[index]) ? { ...data, byGroups: updatedByGroups } : data;
      }),
    );
  };

  return (
    <Contianer>
      {loading && <LoadingSpinner />}
      <BasketOptionContainer>
        <BasketOptions
          onClick={toogleCartModal}
          backgroundColor="transparent"
          borderColor={primaryColors.primary700}>
          <IconResolver
            iconName="AddOutlined"
            sx={{ display: 'flex', alignItems: 'center', height: '100%', color: primaryColors.primary }}
          />
        </BasketOptions>
        <IconBasketContainer>
          <IconButtonContainer
            disabled={!scrollable}
            onClick={scrollToLeft}>
            <IconResolver iconName="ArrowBackIosNew" />
          </IconButtonContainer>
          <BasketContainer ref={basketOptionRef}>
            {basketOptions.map((basketOption) => {
              return (
                <SubscriptionCartBasket
                  key={basketOption.id}
                  basketOption={basketOption}
                  onBasketUpdateHandler={(event) => onBasketUpdateHandler(event, basketOption.id, basketOption.cartName)}
                  onBasketDeleteHandler={(event) => onBasketDeleteHandler(event, basketOption.id)}
                  onClick={() => setCartAsActive(basketOption)}
                  borderColor={basketOption.isCurrentlyUsing ? blueColors.blue500 : primaryColors.primary300}
                  backgroundColor={basketOption.isCurrentlyUsing ? blueColors.blue50 : '#ffffff'}
                />
              );
            })}
          </BasketContainer>
          <IconButtonContainer
            disabled={!scrollable}
            onClick={scrollToRight}>
            <IconResolver iconName="ArrowForwardIos" />
          </IconButtonContainer>
        </IconBasketContainer>
      </BasketOptionContainer>
      <Content>
        <Main>
          {incomingDatas.length !== 0 && (
            <Box className="d-flex w100 justify-flex-end">
              <PButton
                width="auto"
                inputtype="outline"
                onClick={updateCartHandler}
                startIcon={<IconResolver iconName="RefreshOutlined" />}>
                {t('pay:updateCart')}
              </PButton>
            </Box>
          )}
          {incomingDatas.map((incomingData) => {
            return (
              <TitleAndItemContainer key={`${incomingData.cartGroup}-${incomingData.cartGroupDefinition}`}>
                {incomingData.byGroups.map((group) => {
                  return (
                    <Box
                      className="d-flex flex-column gap-20"
                      key={`${incomingData.cartGroup}-${incomingData.cartGroupDefinition}-${group.orderDefinition}`}>
                      <Title>
                        <Typography variant="titleMedium">{group.orderDefinition}</Typography>
                        <PInfoChip label={`${group.products.length} ${t('pay:product')}`} />
                      </Title>
                      <Box className="d-flex flex-column gap-16">
                        {group.products.map((product) => {
                          return (
                            <ProductItem
                              activeBasket={activeBasket}
                              getActiveCartItemsFn={getActiveCartItemsFn}
                              product={product}
                              key={product.cartItemId}
                              updateProductQuantity={updateProductQuantity}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
                <PDivider marginBottom="0" />
              </TitleAndItemContainer>
            );
          })}
          {incomingDatas.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '200px',
              }}>
              <p>{t('configMaster:thereAreNoProductsInYourCart')}</p>
            </Box>
          )}
        </Main>
        {incomingDatas.length !== 0 && (
          <Summary>
            <SubscriptionSummary
              activeBasketId={activeBasket?.id}
              setShowOrderDetail={setShowOrderDetail}
              incomingDatas={incomingDatas}
              isProjectBasket={activeBasket?.isProject}
            />
          </Summary>
        )}
      </Content>
      {upsertCart && (
        <UpsertCartModal
          setCardInfo={setUpdateCardInfo}
          cardInfo={updateCardInfo}
          getAllCarts={getBasketOptions}
          onClose={toogleCartModal}
          operation={updateValuesOperation}
        />
      )}
      {deleteBasketModal && (
        <ApproveModal
          onClose={onCloseDeleteBasketModalHandler}
          approveClick={onApproveDeleteBasket}
          bodyText={t('storageCart:removeSelectedRow')}
        />
      )}
    </Contianer>
  );
};

export default SubscriptionCart;
