import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, COMMON_API } from 'constants/Keys';

const AUTH = '/auth';
const LOGIN = '/login';
const LOGOUT = '/logout';
const VERIFY_OTP = '/verify-otp';
const SEND_OTP = '/send-otp';
const CREATE_RESET_PASSWORD_LINK = '/create-reset-password-link';
const CHECK_RESET_PASSWORD_LINK_VALID = '/check-reset-password-link-valid';
const CREATE_NEW_PASSWORD = '/create-new-password';
const VALIDATE_USER_REGISTERED = '/validate-user-registered';
const REGISTER_USER_FOR_GOOGLE_AUTH = '/register-user-for-google-auth';
const GOOGLE_AUTHENTICATOR = '/google-authenticator';
const RESET_FORGOTTEN_PASSWORD = '/reset-forgotten-password';
const GET_USER = '/get-user';
const CHECK_CONTRACTS = '/check-contracts';
const UPDATE_BAYINET_USER = '/update-bayinet-user';
const APPROVE_GOOGLE_AUTH = '/approve-google-auth';

export const getOTPCode = ({ sapCustomerId, emailOrPhone, password }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${LOGIN}`, {
    sapCustomerId,
    emailOrPhone,
    password: password,
  });

export const createResetPasswordLink = ({ emailOrPhone, sapCustomerId }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${CREATE_RESET_PASSWORD_LINK}`, {
    sapCustomerId,
    emailOrPhone,
  });

export const verifyOtp = ({ emailOrPhone, otp }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${VERIFY_OTP}`, {
    emailOrPhone,
    otp,
  });

export const checkTokenValid = (token) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${CHECK_RESET_PASSWORD_LINK_VALID}`, {
    token,
  });

export const createNewPassword = ({ emailOrPhone, password, password2 }, headers) =>
  httpRequest.post(
    `${COMMON_API}${API}${AUTH}${CREATE_NEW_PASSWORD}`,
    {
      emailOrPhone,
      password,
      password2,
    },
    { headers },
  );

export const resendOtp = ({ sapCustomerId, emailOrPhone }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${SEND_OTP}`, {
    sapCustomerId,
    emailOrPhone,
  });

export const logout = () => httpRequest.post(`${COMMON_API}${API}${AUTH}${LOGOUT}`);

export const validateUserQr = ({ emailOrPhone }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${VALIDATE_USER_REGISTERED}`, {
    emailOrPhone,
  });

export const generateQrCode = ({ emailOrPhone }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${REGISTER_USER_FOR_GOOGLE_AUTH}`, {
    emailOrPhone,
  });

export const loginWithGoogleAuth = ({ emailOrPhone, otp }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${GOOGLE_AUTHENTICATOR}`, {
    emailOrPhone,
    otp,
  });

export const resetForgettenPassword = ({ token, password, password2 }, headers) =>
  httpRequest.post(
    `${COMMON_API}${API}${AUTH}${RESET_FORGOTTEN_PASSWORD}`,
    {
      token,
      password,
      password2,
    },
    { headers },
  );

export const getMobileOrMail = (emailOrPhone) => httpRequest.get(`${COMMON_API}${API}${AUTH}${GET_USER}/${emailOrPhone}`);

export const checkContracts = () => httpRequest.get(`${COMMON_API}${API}${AUTH}${CHECK_CONTRACTS}`);

export const updateBayinetUser = ({ email, mobile, password, password2 }, headers) =>
  httpRequest.post(
    `${COMMON_API}${API}${AUTH}${UPDATE_BAYINET_USER}`,
    {
      email,
      mobile,
      password,
      password2,
    },
    { headers },
  );

export const approveGoogleAuth = ({ token }) =>
  httpRequest.post(`${COMMON_API}${API}${AUTH}${APPROVE_GOOGLE_AUTH}`, {
    token,
  });
