import { useTheme } from '@emotion/react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { primaryColors } from 'config/theme/Colors';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const Card = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  border: `1px solid ${primaryColors.primary200}`,
  position: 'relative',
  overflow: 'hidden',
  padding: '18px 12px',
  cursor: 'pointer',
}));

const CircleImage = styled(Box)(({ imagewidth, imageheight, bottom, right, imagebackground }) => ({
  width: imagewidth,
  height: imageheight,
  borderRadius: '50%',
  backgroundColor: imagebackground,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: bottom,
  right: right,
}));

const LandingCard = ({
  title,
  subtitle,
  image,
  imageBackground,
  url,
  imageWidthContainer = '92px',
  imageHeightContainer = '92px',
  bottom = '-16px',
  right = '-16px',
  imageWidth = 48,
  imageHeight = 48,
  internal = true,
}) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const navigateToUrl = () => {
    if (internal) {
      navigate(url);
    } else {
      setLoading(true);
      loginForBayinet()
        .then((res) => {
          window.location.href = res.data.data.url;
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}
      <Card onClick={navigateToUrl}>
        <Box>
          <Typography variant="titleLarge">{title}</Typography>
        </Box>
        <Box>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {subtitle}
          </Typography>
        </Box>
        <CircleImage
          imagebackground={imageBackground}
          right={matchesSm ? '-16px' : right}
          bottom={matchesSm ? '-16px' : bottom}
          imagewidth={matchesSm ? '92px' : imageWidthContainer}
          imageheight={matchesSm ? '92px' : imageHeightContainer}>
          <img
            width={matchesSm ? 48 : imageWidth}
            height={matchesSm ? 48 : imageHeight}
            src={image}
            alt="Icon"
          />
        </CircleImage>
      </Card>
    </>
  );
};

export default LandingCard;
