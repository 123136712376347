import { paths } from 'constants/Paths';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import Policies from '../Policies';

export const policiesRoutes = {
  path: `${paths.account}${paths.ourPolitics}`,
  element: (
    <LoginLayout>
      <Policies />
    </LoginLayout>
  ),
};
