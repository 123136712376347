import { styled, Typography } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import { primaryColors } from 'config/theme/Colors';
import { paymentEnum } from 'pages/pay/constants/PaymentEnum';
import { useTranslation } from 'react-i18next';

const PaymentItemContainer = styled('button')(({ selected, disabled }) => {
  const background = selected ? primaryColors.primary : '#ffffff';
  const color = selected ? '#ffffff' : primaryColors.primary;
  const hoverBackground = selected ? primaryColors.primary700 : primaryColors.primary200;
  if (disabled) {
    return {
      flex: 1,
      border: `1px solid ${primaryColors.primary200}`,
      display: 'flex',
      padding: '8px',
      alignItems: 'center',
      color: color,
      borderRadius: '6px',
      width: '100%',
      gap: '8px',
      backgroundColor: background,
      opacity: 0.3,
    };
  }
  return {
    flex: 1,
    border: `1px solid ${primaryColors.primary200}`,
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    color: color,
    borderRadius: '6px',
    width: '100%',
    cursor: 'pointer',
    gap: '8px',
    backgroundColor: background,
    '&:hover': {
      backgroundColor: hoverBackground,
    },
  };
});

const PayPaymentPart = ({ showOrderDetail, formData, changePaymentType }) => {
  const { t } = useTranslation();
  return (
    <>
      {showOrderDetail.paymentPage[0].aciK_HESAP === 'X' && (
        <PaymentItemContainer
          selected={formData.selectedPayment === paymentEnum.wallet}
          onClick={() => changePaymentType(paymentEnum.wallet)}>
          <IconResolver iconName="AccountBalanceWalletOutlined" />
          <Typography variant="bodyMedium">{t('pay:openAccount')}</Typography>
        </PaymentItemContainer>
      )}
      {showOrderDetail.paymentPage[0].bankA_TRANSFER === 'X' && (
        <PaymentItemContainer
          selected={formData.selectedPayment === paymentEnum.bankTransfer}
          onClick={() => changePaymentType(paymentEnum.bankTransfer)}>
          <IconResolver iconName="AccountBalanceOutlined" />
          <Typography variant="bodyMedium">{t('pay:bankTransfer')}</Typography>
        </PaymentItemContainer>
      )}
      {showOrderDetail.paymentPage[0].onlinE_ODEME === 'X' && (
        <PaymentItemContainer
          selected={formData.selectedPayment === paymentEnum.onlinePayment}
          onClick={() => changePaymentType(paymentEnum.onlinePayment)}>
          <IconResolver iconName="PaymentOutlined" />
          <Typography variant="bodyMedium">{t('pay:onlinePayment')}</Typography>
        </PaymentItemContainer>
      )}
    </>
  );
};

export default PayPaymentPart;
