import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PSearchableTextField from 'components/textField/PSearchableTextField';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddPerson from './AddPerson';

const DeliveryFromStorageOption = ({ handleChange, error, errorMessage, storagePlace, setValues, contracts = [] }) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const [inputValue, setInputValue] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showAllMenu, setShowAllMenu] = useState(true);

  const handleModalVisibility = () => {
    setModalVisible((prevState) => !prevState);
  };

  const onSelectChange = (event) => {
    setSearchInputValue(event.target.value);
    setShowAllMenu(false);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
  };

  const menuItems = useMemo(() => {
    const formatContent = (contract) => `${contract.namE1} ${contract.namev}`;

    const handleItemClick = (contract) => {
      const customObject = { target: { value: contract.parnr } };
      handleChange(customObject);
      setInputValue(formatContent(contract));
      setShowAllMenu(true);
    };

    return contracts
      .filter(
        (contract) =>
          showAllMenu ||
          Object.values({ namE1: contract.namE1, namev: contract.namev })
            .join(' ')
            .toLocaleLowerCase('tr')
            .includes(searchInputValue.toLocaleLowerCase('tr')),
      )
      .map((contract) => ({
        id: contract.parnr,
        content: <div>{formatContent(contract)}</div>,
        onClick: () => handleItemClick(contract),
      }));
  }, [contracts, searchInputValue, showAllMenu, handleChange]);

  return (
    <Box className="d-flex align-items-center gap-20">
      <PSearchableTextField
        containerWidth="100%"
        placeholder={t('deliveryDetails:selectRelevantPeople')}
        name="storage"
        menuItems={menuItems}
        label={t('deliveryDetails:deliveryOptions')}
        value={inputValue}
        searchInputValue={searchInputValue}
        clearSearchInputValue={clearSearchInputValue}
        onChange={onSelectChange}
        error={error}
        errorMessage={errorMessage}
      />
      {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
        <PIconButton
          inputtype="tonal"
          onClick={handleModalVisibility}>
          <AddIcon />
        </PIconButton>
      )}
      {modalVisible && (
        <AddPerson
          setValues={setValues}
          onClose={handleModalVisibility}
          storagePlace={storagePlace}
        />
      )}
    </Box>
  );
};

export default DeliveryFromStorageOption;
