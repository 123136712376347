export const paths = {
  protectedHome: '/landing-page',
  home: '/',
  account: '/Account',
  logon: '/logon',
  aboutUs: '/AboutUs',
  generalManagerMessage: '/GeneralManagerMessage',
  missionVision: '/MissionVision',
  ourPolitics: '/OurPolitics',
  contact: '/Contact',
  admin: '/admin',
  add: '/add',
  userManagement: '/UserManagement',
  addUserManagement: '/AddUserManagement',
  forgetPassword: '/forget-password',
  pageContent: '/page-content',
  storage: '/Storage',
  main: '/Main',
  accounting: '/Accounting',
  passwordChange: '/PasswordChange',
  resetPassword: '/reset-password',
  approveUpdateEmail: '/approve-update-email',
  approveUpdateMobile: '/approve-update-mobile',
  storageCost: '/storageCost',
  customer: '/Customer',
  myDeliveryAdresses: '/MyDeliveryAdresses',
  checkExcel: '/CheckExcel',
  createBulk: '/CreateBulk',
  accountRelatedPersons: '/AccountRelatedPersons',
  subscriptionPermission: '/SubscriptionPermission',
  subscriptionSubpermission: '/SubscriptionSubpermission',
  createBulkOrder: '/CreateBulkOrder',
  viewBulkOrder: '/ViewBulkOrder',
  myContracts: '/MyContracts',
  forbidden: '/forbidden',
  wizard: '/Wizard',
  fbw: '/Fbw',
  index: '/index',
  paymentSuccess: '/payment-success',
  paymentFail: '/payment-fail',
  orderSuccess: '/order-success',
  orderFail: '/order-fail',
  pay: '/pay',
  subscriptions: '/subscriptions',
  byProducts: '/by-products',
  integration: '/integration',
  productServices: '/product-services',
  orderServices: '/order-services',
  storageServices: '/storage-services',
  payServices: '/pay-services',
  configurationPreferences: '/configuration-preferences',
  getAnOffer: '/get-an-offer',
  getAnofferResult: '/get-an-offer-result',
  transactionDetail: '/transaction-detail',
};
