import { Box, styled, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { primaryColors } from 'config/theme/Colors';
import { updateIntegrationKey } from 'pages/pentaIntegration/api/IntegrationApi';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import key from '../../constants/media/key.png';
const Container = styled(Box)(() => ({
  background: '#ffffff',
  border: `1px solid ${primaryColors.primary200}`,
  minHeight: '80px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  gap: '16px',
  justifyContent: 'space-between',
  borderRadius: '12px',
  overflow: 'auto',
}));

const SecretKey = ({ password, setLoading, isLive, setFormData }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const tooglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const showRealOrHidden = useCallback(() => {
    if (showPassword) {
      return password;
    }
    return '*'.repeat(password.length);
  }, [showPassword, password]);

  const changeKey = () => {
    setLoading(true);
    updateIntegrationKey(isLive)
      .then((res) => {
        toast.success(res.data.message);
        setFormData((prevState) => ({ ...prevState, ...(isLive ? { liveAppKey: res.data.data } : { testAppKey: res.data.data }) }));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const copyText = () => {
    navigator.clipboard.writeText(password);
    toast.success(t('integration:keyCopied'));
  };

  return (
    <Container>
      <Box className="d-flex gap-12 align-items-center">
        <img
          alt="icon"
          width={40}
          height={40}
          src={key}
        />
        {!password && <Typography variant="bodyMedium">{t('integration:thereIsNoSecretKey')}</Typography>}
        {password && (
          <Box className="d-flex flex-column gap-4">
            <Typography variant="bodyMedium"> {t('integration:secretKeyCreated')}</Typography>
            <Box className="d-flex gap-8 align-items-center">
              <Typography variant="bodyMedium">{showRealOrHidden()}</Typography>
              <IconResolver
                onClick={tooglePassword}
                iconName={showPassword ? 'VisibilityOffOutlined' : 'VisibilityOutlined'}
                className="cursor-pointer"
              />
              <IconResolver
                onClick={copyText}
                iconName="CopyAllOutlined"
                className="cursor-pointer"
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <PButton
          disabled={!password}
          onClick={changeKey}
          startIcon={<IconResolver iconName="Key" />}>
          {t('integration:changeKey')}
        </PButton>
      </Box>
    </Container>
  );
};
export default SecretKey;
