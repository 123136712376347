import { Box, IconButton, styled, Typography } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import { primaryColors } from 'config/theme/Colors';
import { useState } from 'react';
const JsonContainer = styled(Box)(({ isopen }) => ({
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '8px',
  background: '#ffffff',
  padding: '0 16px',
  overflowY: 'hidden',
  height: isopen ? '100%' : 0,
  transition: 'height 0.5s linear',
}));

const CollapsableContainer = ({ label, content }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleExpand = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Box className="d-flex flex-column">
      <Box>
        <Typography
          variant="bodySmall"
          color={primaryColors.primary800}>
          {label}
        </Typography>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={handleExpand}>
          {isOpen ? <IconResolver iconName="ExpandLess" /> : <IconResolver iconName="ExpandMore" />}
        </IconButton>
      </Box>
      <JsonContainer isopen={+isOpen}>
        <pre style={{ textWrap: 'wrap' }}>{content}</pre>
      </JsonContainer>
    </Box>
  );
};

export default CollapsableContainer;
