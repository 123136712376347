export const MyShipmentsEn = {
  excelPrintout: 'Excel Printout',
  searchByCustomerNumber: 'Search by customer number...',
  list: 'List',
  selectStartingDate: 'Select starting date',
  selectEndDate: 'Select end date',
  date: 'Date',
  shipmentNumber: 'Shipment No',
  shippingCompany: 'Shipping Company',
  shipmentType: 'Shipment Type',
  buyer: 'Buyer',
  status: 'Status',
  deliveryInformation: 'Delivery Information',
  volume: 'Volume',
  transportationCost: 'Transportation Cost',
  customerOrderNumber: 'Customer Order Number',
  cargoTracking: 'Cargo Tracking',
  noResultsFound: 'No Results Found',
  productNo: 'Product No',
  productName: 'Product Name',
  referenceDocument: 'Reference Document',
  referencePen: 'Reference Pen',
  amount: 'Amount',
  piece: 'Piece',
  shipmentProductsNo: 'Shipment Products No. {{number}}',
  serialNo: 'Serial No',
  referenceRegion: 'Reference Region',
  header: 'Header',
  entryNo: 'Entry No',
  serialNumberList: 'Serial Number List',
  shipmentReferenceNo: 'Shipment Reference No',
  doSearch: 'Do search...',
  emptyComponentTitle: 'Select date range or enter order number',
  emptyComponentSubTitle: 'You can access shipments by selecting a date range or entering an order number.',
};
