import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import SimpleTable from 'components/simpleTable/SimpleTable';
import PPagination from 'components/table/PPagination';
import { paths } from 'constants/Paths';
import { getAllInterlocutor } from 'pages/accountRelatedPersons/api/AcountRelatedPersonsApi';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SubscriptionColumns from './SubscriptionColumns';
import SubscriptionRows from './SubscriptionRows';

const SubscriptionList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [data, setData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const getData = () => {
    setIsLoading(true);
    getAllInterlocutor()
      .then((res) => {
        const filteredData = res.data.data.interlocutors.filter((item) => item.interlocutorrRole === 'ZL');
        filteredData.forEach((item, index) => {
          item.id = index;
          item.childPartners.forEach((child, count) => {
            child.id = `${child.email} ${count}`;
          });
        });
        setData(filteredData);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const descendingComparator = (a, b, orderBy) => {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
      return b[orderBy].localeCompare(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        paginationModel.page * paginationModel.pageSize,
        paginationModel.page * paginationModel.pageSize + paginationModel.pageSize,
      ),
    [order, orderBy, data, paginationModel.page, paginationModel.pageSize],
  );

  const handlePageSizeChange = (event) => {
    const prevPageSize = paginationModel.pageSize;
    const pageSize = event.target.value;
    let page = paginationModel.page;
    if (prevPageSize < pageSize) {
      page = Math.round(data.length / pageSize) > 0 ? Math.round(data.length / pageSize) - 1 : Math.round(data.length / pageSize);
    }

    setPaginationModel((prevState) => ({ ...prevState, page, pageSize }));
  };

  const handlePageChange = (_, newPage) => {
    setPaginationModel((prevState) => ({ ...prevState, page: newPage - 1 }));
  };

  const menuItems = useMemo(() => {
    return [
      { key: 10, value: 10 },
      { key: 20, value: 20 },
      { key: 50, value: 50 },
    ];
  }, []);

  const columns = useMemo(() => {
    return [
      { id: 'id', headerName: '', minWidth: '48px' },
      {
        id: 'interlocutorName',
        headerName: t('accountRelatedPersons:nameSurnameTitle'),
        minWidth: '160px',
        sortable: true,
      },
      {
        id: 'mobilePhone',
        headerName: t('accountRelatedPersons:phoneNumber'),
        minWidth: '140px',
        sortable: true,
      },
      {
        id: 'emailAddress',
        headerName: t('accountRelatedPersons:email'),
        minWidth: '210px',
        sortable: true,
      },
      {
        id: 'authorizedB2BUser',
        headerName: t('accountRelatedPersons:authorizedInCompany'),
        minWidth: '124px',
        sortable: true,
      },
      {
        id: 'onlyMTVisible',
        headerName: t('accountRelatedPersons:whoShouldSeeInPenta'),
        minWidth: '153px',
        sortable: true,
      },
      {
        id: 'deletionFlag',
        headerName: t('accountRelatedPersons:status'),
        minWidth: '100px',
        sortable: true,
      },
      {
        id: 'actions',
        headerName: '',
        width: '84px',
      },
    ];
  }, [t]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleAddNewPerson = () => {
    navigate(`${paths.add}${paths.accounting}${paths.subscriptionPermission}`);
  };

  return (
    <Box className="d-flex flex-column gap-16">
      {isLoading && <LoadingSpinner />}
      <SimpleTable
        dataLength={`${data.length} ${t('accountRelatedPersons:person')}`}
        headerTitle={t('accountRelatedPersons:subscriptionLicenceList')}
        headerRightSide={
          <Box className="d-flex gap-12 justify-flex-end">
            {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
              <PButton
                width="auto"
                startIcon={<AddIcon />}
                onClick={handleAddNewPerson}>
                {t('accountRelatedPersons:addNewPerson')}
              </PButton>
            )}
          </Box>
        }
        columns={
          <SubscriptionColumns
            order={order}
            orderBy={orderBy}
            columns={columns}
            onRequestSort={handleRequestSort}
          />
        }
        rows={visibleRows.map((row) => (
          <SubscriptionRows
            setIsLoading={setIsLoading}
            loaderFunction={getData}
            key={row.id}
            row={row}
          />
        ))}
        pagination={
          <PPagination
            page={paginationModel.page + 1}
            handlePageChange={handlePageChange}
            menuItems={menuItems}
            handlePageSizeChange={handlePageSizeChange}
            pageSize={paginationModel.pageSize}
            totalCount={data.length}
          />
        }
        showFooter
      />
    </Box>
  );
};
export default SubscriptionList;
