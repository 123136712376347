/* eslint-disable max-len */
import { Box, styled, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PChip from 'components/chip/PChip';
import IconResolver from 'components/iconResolver/IconResolver';
import PTooltip from 'components/tooltip/PTooltip';
import { viloetColors } from 'config/theme/Colors';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SpecialOffer.css';

const SpecialOfferContainer = styled(Box)(() => ({
  background: 'linear-gradient(92.38deg, #FBE8EF 0%, #E5E5FB 49.98%, #DEE8FC 74.99%, #DCEFF3 100%)',
  width: '100%',
  height: '64px',
}));
const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0 4px',
  'button:last-of-type': {
    marginLeft: 'auto',
  },
}));

const SpecialOffer = ({ campaigns, onSpecialOfferHandler }) => {
  const { t } = useTranslation();
  const [arrayIndex, setArrayIndex] = useState(0);
  const [fadeState, setFadeState] = useState('fade-enter');
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const ref = useRef();
  useEffect(() => {
    setFadeState('fade-enter-active');
  }, [arrayIndex]);

  useEffect(() => {
    if (campaigns.length > 1) {
      const intervalId = setInterval(nextIndex, 4000);
      return () => clearInterval(intervalId);
    }
  }, [arrayIndex, campaigns.length]);

  useEffect(() => {
    setArrayIndex(0);
  }, [campaigns]);

  const nextIndex = () => {
    setIsButtonDisable(true);
    setFadeState('fade-exit');
    setTimeout(() => {
      setFadeState('fade-exit-active');
      setTimeout(() => {
        setArrayIndex((prevIndex) => (prevIndex === campaigns.length - 1 ? 0 : prevIndex + 1));
        setFadeState('fade-enter');
        setIsButtonDisable(false);
      }, 300);
    }, 0);
  };

  const previousIndex = () => {
    setIsButtonDisable(true);
    setFadeState('fade-exit');
    setTimeout(() => {
      setFadeState('fade-exit-active');
      setTimeout(() => {
        if (arrayIndex === 0) {
          setArrayIndex(campaigns.length - 1);
        } else {
          setArrayIndex((prevState) => prevState - 1);
        }
        setFadeState('fade-enter');
        setIsButtonDisable(false);
      }, 300);
    }, 0);
  };

  useEffect(() => {
    if (ref.current.scrollWidth > ref.current.clientWidth) {
      setTooltipEnabled(true);
    } else {
      setTooltipEnabled(false);
    }
  }, [ref, arrayIndex]);

  return (
    <SpecialOfferContainer>
      <ItemContainer>
        <PIconButton
          onClick={previousIndex}
          disabled={campaigns.length === 0 || campaigns.length === 1 || isButtonDisable}
          inputtype="text">
          <IconResolver iconName="ArrowBackIosNewOutlined" />
        </PIconButton>
        <Box className="d-flex ellipsis ">
          <Box className="d-flex align-items-center gap-8 ellipsis">
            <PChip
              label={t('configMaster:specialOffer')}
              sx={{ backgroundColor: '#EDE9FEA3', border: `1px solid ${viloetColors.violet300}`, color: viloetColors.violet700 }}
            />
            <Typography
              onClick={() => onSpecialOfferHandler(campaigns[arrayIndex])}
              ref={ref}
              className={`ellipsis ${fadeState} cursor-pointer`}
              variant="titleMedium">
              <PTooltip
                placement="bottom-start"
                title={tooltipEnabled ? campaigns[arrayIndex]?.description : ''}>
                {campaigns[arrayIndex]?.description}
              </PTooltip>
            </Typography>
          </Box>
        </Box>
        <PIconButton
          onClick={nextIndex}
          disabled={campaigns.length === 0 || campaigns.length === 1 || isButtonDisable}
          inputtype="text">
          <IconResolver iconName="ArrowForwardIosOutlined" />
        </PIconButton>
      </ItemContainer>
    </SpecialOfferContainer>
  );
};

export default SpecialOffer;
