import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Popover, Typography, styled } from '@mui/material';
import PButton from 'components/button/PButton';
import PInfoChip from 'components/chip/PInfoChip';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PSelect from 'components/select/PSelect';
import { blueColors, primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import PentaStorageCards from 'pages/pentaStorage/commonComponents/PentaStorageCards';
import PentaStorageTabContainer from 'pages/pentaStorage/commonComponents/PentaStorageTabContainer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { numberToDecimal, splitWithDot } from 'utils/HelperFunctions';
import * as XLSX from 'xlsx';
import AllProductsTable from './AllProductsTable';
import OrderNumberTable from './OrderNumberTable';
import ProductNumberTable from './ProductNumberTable';
import StorageNumberTable from './StorageNumberTable';
import { addItemToCart, getAllProducts } from './api/ProductsInStorageApi';
import AddWithSerialNumberModal from './components/AddWithSerialNumberModal';
import DetailFilter from './components/DetailFilter';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const InputActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  flexWrap: 'wrap',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > *': { width: '100% !important' },
  },
}));

const SearchAndFilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    gap: '16px',
    '& > *': { width: '100% !important' },
  },
}));

const ShowLoadedExcel = styled(Box)(({ theme }) => ({
  border: `1px solid ${blueColors.blue300}`,
  backgroundColor: blueColors.blue50,
  padding: '10px 12px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '42px',
  justifyContent: 'space-between',
  gap: '12px',
  width: '848px',
  [theme.breakpoints.down('lg')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ProductsInStorage = ({ tabValue, setCartItemNumber }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState('allProducts');
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState({});
  const [tableData, setTableData] = useState({ allData: [], visibleData: [], groupByOrderId: {}, groupByProductNumber: {}, groupByStoragePlace: {} });
  const [searchValue, setSearchValue] = useState('');
  const [detailFiterData, setDetailFilterData] = useState({ storagePlace: [], brand: [], category: [], serialNo: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [maxProductCountForSerialSelection, setMaxProductCountForSerialSelection] = useState(10);
  const [userHasPermissionForBulkAdd, setUserHasPermissionForBulkAdd] = useState(false);

  const getTableData = () => {
    setLoading(true);
    setSearchValue('');
    getAllProducts()
      .then((res) => {
        setHeaders(res.data.data.totals);
        setMaxProductCountForSerialSelection(res.data.data.maxProductCountForSerialSelection);
        const localData = [...res.data.data.deliveries];
        const serialNumbersData = [...res.data.data.serialNumbers];
        localData.forEach((item, index) => {
          item.id = index + 1;
          item.amount = 1;

          const found = serialNumbersData.filter(
            (serialNumber) => serialNumber.entryNumber === item.entryNumber && item.orderId === serialNumber.orderId,
          );
          item.manufacturerSerialNumber = found;
        });

        const groupByOrderId = Object.groupBy(localData, ({ orderId }) => orderId);
        const groupByProductNumber = Object.groupBy(localData, ({ productNumber }) => productNumber);
        const groupByStoragePlace = Object.groupBy(localData, ({ storagePlace }) => storagePlace);

        setTableData((prevState) => ({
          ...prevState,
          allData: localData,
          visibleData: localData,
          groupByOrderId: Object.entries(groupByOrderId),
          groupByProductNumber: Object.entries(groupByProductNumber),
          groupByStoragePlace: Object.entries(groupByStoragePlace),
        }));
        setUserHasPermissionForBulkAdd(res.data.data.userHasCustomerContract);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (tabValue === 1) {
      getTableData();
    }
  }, [tabValue]);

  const selectChange = (event) => {
    setSelectValue(event.target.value);
  };

  const selectData = useMemo(() => {
    return [
      { key: 'allProducts', value: t('productsInStorage:allProducts') },
      { key: 'orderNumber', value: t('productsInStorage:orderNumber') },
      { key: 'productNumber', value: t('productsInStorage:itemNumber') },
      { key: 'storageNumber', value: t('productsInStorage:storageNumber') },
    ];
  }, [t]);

  const onChangeSearch = (event) => {
    const value = event.target.value;

    setSearchValue(value);
  };

  const onHandleFilterData = (detailFilterValues = null) => {
    const copyAllData = [...tableData.allData];
    const filteredData = copyAllData.filter((e) =>
      Object.values({ productNumber: e.productNumber, orderId: e.orderId, storagePlace: e.storagePlace, shortDesc: e.shortDesc })
        .join(' ')
        .toLocaleLowerCase('tr')
        .includes(searchValue.toLocaleLowerCase('tr')),
    );
    handleDetailFilter(detailFilterValues, filteredData);
  };

  const handleDetailFilter = (detailFilterValues, filteredData) => {
    let result = [...filteredData];

    if (detailFilterValues.storagePlace.length > 0) {
      result = result.filter((data) => detailFilterValues.storagePlace.some((filterStoragePlace) => data.storagePlace === filterStoragePlace));
    }
    if (detailFilterValues.brand.length > 0) {
      result = result.filter((data) => detailFilterValues.brand.some((singleBrand) => data.productBrand === singleBrand));
    }
    if (detailFilterValues.category.length > 0) {
      result = result.filter((data) => detailFilterValues.category.some((singleCategory) => data.category === singleCategory));
    }
    if (detailFilterValues.serialNo) {
      result = result.filter((item) =>
        item.manufacturerSerialNumber.some((serial) => serial.manufacturerSerialNumber === detailFilterValues.serialNo),
      );
    }

    const groupByOrderId = Object.groupBy(result, ({ orderId }) => orderId);
    const groupByProductNumber = Object.groupBy(result, ({ productNumber }) => productNumber);
    const groupByStoragePlace = Object.groupBy(result, ({ storagePlace }) => storagePlace);
    setTableData((prevState) => ({
      ...prevState,
      visibleData: result,
      groupByOrderId: Object.entries(groupByOrderId),
      groupByProductNumber: Object.entries(groupByProductNumber),
      groupByStoragePlace: Object.entries(groupByStoragePlace),
    }));

    setDetailFilterData(detailFilterValues);
  };

  const inputChange = (value, row) => {
    const localAllData = [...tableData.allData];
    const foundAllRow = localAllData.find((data) => data.id === row.id);
    if (value > foundAllRow.count) {
      toast.error(t('storageCart:cannotSendMoreThanTheStock'));
    } else {
      foundAllRow.amount = value;
      setTableData((prevState) => ({ ...prevState, allData: localAllData }));
    }
  };

  const addToCart = (row) => {
    if (row.count >= maxProductCountForSerialSelection || row.manufacturerSerialNumber.length === 0) {
      const addItem = {
        stockCode: Number(row.productNumber).toString(),
        itemName: row.shortDesc,
        orderNo: Number(row.orderId).toString(),
        entryNumber: row.entryNumber,
        storagePlace: row.storagePlace,
        quantity: row.amount,
        serials: [],
      };

      addItemToCart(addItem)
        .then((res) => {
          setCartItemNumber(res.data.data.productCountInCart);
          toast.success(res.data.message);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    } else {
      setAddToCartModal(row);
    }
  };

  const toExcel = () => {
    const sheet1 = [
      {
        [t('productsInStorage:averageStockAge')]: `${headers?.avarageStockAge} ${t('productsInStorage:day')}`,
        [t('productsInStorage:totalNumber')]: `${headers?.totalCount} ${t('productsInStorage:piece')}`,
        [t('productsInStorage:differentNumberOfProducts')]: `${headers?.sku} ${t('productsInStorage:piece')}`,
        [t('productsInStorage:totalCost')]: `${numberToDecimal(headers?.totalAmount)} ${headers.currency}`,
      },
    ];
    const sheet2 = tableData.allData.map((item) => ({
      [t('productsInStorage:productNumber')]: Number(item.productNumber),
      [t('productsInStorage:orderNo')]: Number(item.orderId),
      [t('productsInStorage:entryNo')]: item.entryNumber,
      [t('productsInStorage:storage')]: item.storagePlace,
      [t('productsInStorage:itemName')]: item.shortDesc,
      [t('productsInStorage:unitPrice')]: `${item.netPrice} ${item.currency}`,
      [t('productsInStorage:stockAge')]: item.age,
      [t('productsInStorage:piece')]: item.count,
      [t('productsInStorage:brand')]: item.productBrand,
      [t('productsInStorage:category')]: item.category,
    }));
    const fileName = `Bayinet ${dayjs(new Date()).format('DD.MM.YYYY')}`;
    const worksheet1 = XLSX.utils.json_to_sheet(sheet1);
    const worksheet2 = XLSX.utils.json_to_sheet(sheet2);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1');
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'Sheet2');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const handleDetailFilterClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      handleDetailFilterClose();
    }
  };

  const handleDetailFilterClose = () => {
    setAnchorEl(null);
  };

  const showFilterCount = () => {
    let filterCount = 0;

    filterCount = filterCount + detailFiterData.brand.length;

    filterCount = filterCount + detailFiterData.category.length;

    filterCount = filterCount + detailFiterData.storagePlace.length;

    if (detailFiterData.serialNo) {
      filterCount = filterCount + 1;
    }

    return filterCount;
  };

  const applyFilter = (detailFilterCallbackValues) => {
    onHandleFilterData(detailFilterCallbackValues);
  };

  return (
    <div>
      <div className="flex-column gap-24">
        {tableData.allData.length > 0 && (
          <GridContainer>
            <PentaStorageCards>
              <div>
                <Typography
                  color={primaryColors.primary800}
                  variant="bodyMedium">
                  {t('productsInStorage:averageStockAge')}
                </Typography>
              </div>
              <div>
                <Typography
                  color={primaryColors.primary}
                  variant="titleLarge">
                  {splitWithDot(headers?.avarageStockAge)} {t('productsInStorage:day')}
                </Typography>
              </div>
            </PentaStorageCards>
            <PentaStorageCards>
              <div>
                <Typography
                  color={primaryColors.primary800}
                  variant="bodyMedium">
                  {t('productsInStorage:totalNumber')}
                </Typography>
              </div>
              <div>
                <Typography
                  color={primaryColors.primary}
                  variant="titleLarge">
                  {splitWithDot(headers?.totalCount, 0)} {t('productsInStorage:piece')}
                </Typography>
              </div>
            </PentaStorageCards>
            <PentaStorageCards>
              <div>
                <Typography
                  color={primaryColors.primary800}
                  variant="bodyMedium">
                  {t('productsInStorage:differentNumberOfProducts')}
                </Typography>
              </div>
              <div>
                <Typography
                  color={primaryColors.primary}
                  variant="titleLarge">
                  {splitWithDot(headers?.sku, 0)} {t('productsInStorage:piece')}
                </Typography>
              </div>
            </PentaStorageCards>
            <PentaStorageCards background={blueColors.blue50}>
              <div>
                <Typography
                  color={primaryColors.primary800}
                  variant="bodyMedium">
                  {t('productsInStorage:totalCost')}
                </Typography>
              </div>
              <div>
                <Typography
                  color={primaryColors.primary}
                  variant="titleLarge">
                  {numberToDecimal(headers.totalAmount)} {headers.currency}
                </Typography>
              </div>
            </PentaStorageCards>
          </GridContainer>
        )}
        <ActionContainer>
          <InputActions>
            <PSelect
              disabled={tableData.allData.length === 0}
              width="300px"
              showLabel={false}
              showHelperContainer={false}
              value={selectValue}
              menuItems={selectData}
              onChange={selectChange}
            />
            <SearchContainer>
              <TableSearchTextField
                width="100%"
                placeholder={t('productsInStorage:searchWord')}
                value={searchValue}
                onChange={onChangeSearch}
              />
              <PButton
                style={{ width: 'auto' }}
                inputtype="tonal"
                onClick={() => onHandleFilterData(detailFiterData)}>
                {t('productsInStorage:filter')}
              </PButton>
            </SearchContainer>
          </InputActions>
          {userHasPermissionForBulkAdd && (
            <ShowLoadedExcel>
              <div className="d-flex align-items-center gap-4">
                <CloudUploadOutlinedIcon
                  sx={{ color: blueColors.blue600 }}
                  fontSize="small"
                />
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {t('productsInStorage:viewBulkOrders')}
                </Typography>
              </div>
              <PButton
                sx={{ height: '20px' }}
                width="auto"
                inputtype="text"
                onClick={() => navigate(`${paths.storage}${paths.viewBulkOrder}`)}
                startIcon={<VisibilityOutlinedIcon />}>
                <Typography
                  variant="labelLarge"
                  color={primaryColors.primary}>
                  {' '}
                  {t('productsInStorage:bulkOrders')}
                </Typography>
              </PButton>
            </ShowLoadedExcel>
          )}
        </ActionContainer>
        <SearchAndFilterContainer>
          <SearchContainer>
            <PButton
              style={{ width: 'auto' }}
              inputtype="tonal"
              onClick={handleDetailFilterClick}
              startIcon={<FilterAltOutlinedIcon />}
              endIcon={
                <PInfoChip
                  sx={{
                    display: !!showFilterCount() ? 'flex' : 'none',
                  }}
                  label={showFilterCount()}
                />
              }>
              {t('productsInStorage:detailFilter')}
            </PButton>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleDetailFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <DetailFilter
                applyFilter={applyFilter}
                onClose={handleDetailFilterClose}
                tableData={tableData.allData}
                detailFiterData={detailFiterData}
                showFilterCount={showFilterCount}
              />
            </Popover>
            <PButton
              style={{ width: 'auto' }}
              inputtype="tonal"
              disabled={tableData.allData.length === 0}
              onClick={toExcel}
              startIcon={<DifferenceOutlinedIcon />}>
              {t('productsInStorage:excelPrintout')}
            </PButton>
          </SearchContainer>
        </SearchAndFilterContainer>
        {tabValue === 1 && (
          <PentaStorageTabContainer>
            {loading && <LoadingSpinner />}
            {selectValue === 'allProducts' && (
              <AllProductsTable
                tableData={tableData}
                inputChange={inputChange}
                addToCart={addToCart}
              />
            )}
            {selectValue === 'orderNumber' && (
              <OrderNumberTable
                tableData={tableData}
                inputChange={inputChange}
                addToCart={addToCart}
              />
            )}
            {selectValue === 'productNumber' && (
              <ProductNumberTable
                tableData={tableData}
                inputChange={inputChange}
                addToCart={addToCart}
              />
            )}
            {selectValue === 'storageNumber' && (
              <StorageNumberTable
                tableData={tableData}
                inputChange={inputChange}
                addToCart={addToCart}
              />
            )}
          </PentaStorageTabContainer>
        )}
        {addToCartModal && (
          <AddWithSerialNumberModal
            getTableData={getTableData}
            setCartItemNumber={setCartItemNumber}
            onClose={() => setAddToCartModal(null)}
            selectedItem={addToCartModal}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsInStorage;
