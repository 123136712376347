import { Box, Typography, styled } from '@mui/material';
import PInfoChip from 'components/chip/PInfoChip';
import PTableHeader from 'components/table/customTable/PTableHeader';
import PPagination from 'components/table/PPagination';
import { primaryColors } from 'config/theme/Colors';
import { useCallback, useState } from 'react';
import PTableRow from './PTableRow';

const ListContainer = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  border: `1px solid ${primaryColors.primary200}`,
}));

const TableWrapper = styled('div')(() => ({
  border: 'none',
  fontFamily: 'Inter',
  width: '100%',
  maxHeight: 'auto',
  overflow: 'auto',
  borderBottom: `1px solid ${primaryColors.primary300}`,
}));

const Table = styled('table')(() => ({
  width: '100%',
  borderCollapse: 'collapse',
}));

const TableHeaderContainer = styled('thead')(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 2,
}));

export const TableHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '12px 12px 0 0',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${primaryColors.primary200}`,
  padding: '16px',
  background: '#ffffff',
  flexWrap: 'wrap',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '16px',
  },
}));
const HeaderRightSide = styled(Box)(() => ({
  display: 'flex',
  gap: '4px',
  flexDirection: 'column',
  alignItems: 'baseline',
  justifyContent: 'center',
}));

const PTable = ({
  data,
  columns,
  showHeader = true,
  headerTitle = '',
  totalCount = null,
  headerRightSide = null,
  showFooter = true,
  pageSizeArray,
  page,
  pageSize,
  pageSizeChange,
  pageChange,
  order,
  orderBy,
  sortRequest,
  infoLabel,
  subLabel,
  emptyMessage = '',
  showEmptyMessage = false,
  getRowClassName,
}) => {
  const [collapsedSections, setCollapsedSections] = useState({});

  const toggleCollapse = (index) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleAllCollapse = useCallback(() => {
    setCollapsedSections((prevState) => {
      const allCollapsed = data.every((_, index) => prevState[index]);

      const newCollapsedState = {};
      data.forEach((_, index) => {
        newCollapsedState[index] = !allCollapsed;
      });

      return newCollapsedState;
    });
  }, [data]);

  return (
    <ListContainer>
      {showHeader && (
        <TableHeader>
          <HeaderRightSide>
            <Box className="d-flex gap-12 align-items-center">
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {headerTitle}
              </Typography>
              {infoLabel && <PInfoChip label={infoLabel} />}
            </Box>
            {subLabel && (
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {subLabel}
              </Typography>
            )}
          </HeaderRightSide>
          {headerRightSide}
        </TableHeader>
      )}
      <TableWrapper>
        <Table>
          <TableHeaderContainer>
            <PTableHeader
              columns={columns}
              order={order}
              orderBy={orderBy}
              sortRequest={sortRequest}
              data={data}
              toggleAllCollapse={toggleAllCollapse}
              collapsedSections={collapsedSections}
            />
          </TableHeaderContainer>
          <tbody>
            <PTableRow
              collapsedSections={collapsedSections}
              data={data}
              columns={columns}
              toggleCollapse={toggleCollapse}
              emptyMessage={emptyMessage}
              showEmptyMessage={showEmptyMessage}
              getRowClassName={getRowClassName}
            />
          </tbody>
        </Table>
      </TableWrapper>
      {showFooter && (
        <Box sx={{ marginBottom: '5px' }}>
          <PPagination
            handlePageSizeChange={pageSizeChange}
            page={page + 1}
            totalCount={totalCount}
            pageSize={pageSize}
            handlePageChange={pageChange}
            menuItems={pageSizeArray}
          />
        </Box>
      )}
    </ListContainer>
  );
};

export default PTable;
