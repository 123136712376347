import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import MoneyIcon from '@mui/icons-material/Money';
import { Box, Grid, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PDatePicker from 'components/datePicker/PDatePicker';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PCustomTable from 'components/table/customTable/PCustomTable';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { tabNumbers } from 'pages/pentaStorage/constants/TabNumbers';
import { getLoadedBulkOrders } from 'pages/pentaStorage/Tabs/productsInStorage/api/ProductsInStorageApi';
import SerialNumberModal from 'pages/pentaStorage/Tabs/productsInStorage/components/SerialNumberModal';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ViewBulkOrder = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ allData: [], visibleData: [] });
  const [guid, setGuid] = useState('');
  const [dateInterval, setDateInterval] = useState({
    startDate: dayjs().subtract(7, 'days'),
    endDate: dayjs(),
  });
  const [searchValue, setSearchValue] = useState('');
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedRowHeader, setSelectedRowHeader] = useState(null);
  useEffect(() => {
    setIsLoading(false);
    setData({
      allData: [],
      visibleData: [],
    });
  }, []);

  useEffect(() => {
    const searchKey = searchParams.get('query-message') || '';
    if (searchKey) {
      const sendData = {
        startDate: dateInterval.startDate ? dayjs(dateInterval.startDate).format('YYYY-MM-DD') : null,
        endDate: dateInterval.endDate ? dayjs(dateInterval.endDate).format('YYYY-MM-DD') : null,
        guid: searchKey,
      };
      setGuid(searchKey);
      search(sendData);
    }
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'uuidItem',
        headerName: t('productsInStorage:queueEntryNo'),
        width: '116px',
        sortable: true,
      },
      {
        field: 'materialNumber',
        headerName: t('productsInStorage:material'),
        width: '200px',
        sortable: true,
        renderCell: (row) => {
          return `${Number(row.materialNumber)}`;
        },
      },
      {
        field: 'manufacturerPartCode',
        headerName: t('productsInStorage:manufacturerPartNo'),
        width: '200px',
        sortable: true,
      },
      {
        field: 'salesOrder',
        headerName: t('productsInStorage:sdCertificate'),
        width: '120px',
        sortable: true,
      },
      {
        field: 'salesOrderItem',
        headerName: t('productsInStorage:orderItemNo'),
        width: '120px',
        sortable: true,
      },
      {
        field: 'quantity',
        headerName: t('productsInStorage:piece'),
        width: '80px',
        sortable: true,
      },
      {
        field: 'ean11',
        headerName: 'EAN',
        width: '120px',
        sortable: true,
      },
      {
        field: 'serialNumbers',
        headerName: t('productsInStorage:serialNo'),
        width: '120px',
        renderCell: (row) => {
          if (row.serialNumbers.length > 0) {
            return (
              <MoneyIcon
                className="cursor-pointer"
                onClick={() => {
                  const serialNumberArray = row.serialNumbers.map((item) => ({
                    manufacturerSerialNumber: item,
                  }));
                  setModalData(serialNumberArray);
                  setSelectedRowHeader(row.manufacturerPartCode);
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
    ];
  }, [t]);

  const onSearchHandler = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.map((e) => ({
        ...e,
        children: e.children
          ? e.children.filter((child) =>
              Object.values({
                materialDescription: child.materialDescription,
                manufacturerPartCode: child.manufacturerPartCode,
                materialDocument: child.materialDocument,
              })
                .join('')
                .toLocaleLowerCase('tr')
                .includes(value.toLocaleLowerCase('tr')),
            )
          : [],
      })),
    }));
  };

  const search = ({ startDate, endDate, guid }) => {
    const sendData = {
      startDate: startDate,
      endDate: endDate,
      guid: guid,
    };
    setIsLoading(true);
    getLoadedBulkOrders(sendData)
      .then((res) => {
        const customizedData = res.data.data.map((item) => ({
          ...item,
          title: `${item.title} ${t('productsInStorage:productsWithQueueNumbers')} - ${item.user} - ${dayjs(item.date).format('DD.MM.YYYY HH:MM')}`,
        }));
        setData({ allData: customizedData, visibleData: customizedData });
        setShowEmptyMessage(true);
      })
      .catch((error) => {
        toast.error(error.response.data.Message);
        setData({ allData: [], visibleData: [] });
        setShowEmptyMessage(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onListClickHandler = () => {
    const sendData = {
      startDate: dateInterval.startDate ? dayjs(dateInterval.startDate).format('YYYY-MM-DD') : null,
      endDate: dateInterval.endDate ? dayjs(dateInterval.endDate).format('YYYY-MM-DD') : null,
      guid: guid ? guid : null,
    };
    search(sendData);
  };

  const backToProducts = () => {
    navigate(`${paths.storage}${paths.main}`, { state: tabNumbers.productsInStorage });
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <SecondaryLayout
        onClick={backToProducts}
        title={t('productsInStorage:bulkOrders')}
        borderColor="transparent"
        backgroundColor="transparent">
        <Grid
          container
          spacing={2}
          sx={{ paddingRight: 2, paddingBottom: 2, paddingLeft: 0, borderRadius: '15px', margin: 0, alignItems: 'flex-end' }}>
          <Grid
            item
            md={3}
            xs={12}>
            <PDatePicker
              showHelperContainer={false}
              label={t('productsInStorage:selectStartDate')}
              sx={{ width: 'auto' }}
              value={dateInterval.startDate}
              onChange={(value) => {
                setDateInterval((prevState) => ({ ...prevState, startDate: value }));
              }}
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}>
            <PDatePicker
              showHelperContainer={false}
              sx={{ width: 'auto' }}
              label={t('productsInStorage:selectEndDate')}
              value={dateInterval.endDate}
              onChange={(value) => {
                setDateInterval((prevState) => ({ ...prevState, endDate: value }));
              }}
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}>
            <TableSearchTextField
              value={guid}
              onChange={(event) => setGuid(event.target.value)}
              placeholder={t('productsInStorage:searchForQueueNumber')}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={12}>
            <PButton
              width={matchesSm ? '100%' : 'auto'}
              onClick={onListClickHandler}>
              {t('productsInStorage:list')}
            </PButton>
          </Grid>
          <Grid
            item
            xs={12}>
            <Box sx={{ width: '100%' }}>
              <PCustomTable
                columns={columns}
                headerTitle={t('productsInStorage:bulkOrderList')}
                infoLabel={`${data.allData.length} ${t('productsInStorage:piece')}`}
                rows={data.visibleData}
                showEmptyMessage={showEmptyMessage}
                emptyMessage={t('storageCost:noResultsFound')}
                headerRightSide={
                  <Box className="d-flex gap-12 justify-flex-end">
                    <TableSearchTextField
                      value={searchValue}
                      onChange={onSearchHandler}
                      placeholder={t('productsInStorage:doSearch')}
                    />
                    <PButton
                      width="auto"
                      inputtype="outline"
                      startIcon={matchesSm ? '' : <AddIcon />}
                      onClick={() => navigate(`${paths.storage}${paths.createBulkOrder}`)}>
                      {t('productsInStorage:createBulkOrder')}
                    </PButton>
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Grid>
        {modalData && (
          <SerialNumberModal
            onClose={() => setModalData(null)}
            allData={modalData}
            selectedRowHeader={selectedRowHeader}
          />
        )}
      </SecondaryLayout>
    </>
  );
};

export default ViewBulkOrder;
