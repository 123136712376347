import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import Forbidden403 from '../Forbidden403';
import NotFound404 from '../NotFound404';

export const specialPagesRoutes = [
  {
    path: '*',
    element: (
      <ChildLayout>
        <NotFound404 />
      </ChildLayout>
    ),
  },
  {
    path: paths.forbidden,
    element: (
      <ChildLayout>
        <Forbidden403 />
      </ChildLayout>
    ),
  },
];
