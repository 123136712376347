export const statusCodes = {
  success: 200,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  preconditionFailed: 412,
  locked: 423,
  preconditionRequired: 428,
  internalServerError: 500,
};
