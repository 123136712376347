import { Box, styled } from '@mui/material';
import EmptyScreenMessage from 'components/emptyScreenMessage/EmptyScreenMessage';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { payFilterSelectEnums } from 'pages/pay/constants/PayFilterSelectEnums';
import EmptyScreenIcon from 'pages/pay/tabs/components/EmptyScreenIcon';
import ProductionTable from 'pages/pay/tabs/components/tables/ProductionTable';
import SubscriptionTable from 'pages/pay/tabs/components/tables/SubscriptionTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: '24px',
  marginTop: '56px',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0',
  },
}));

const MainContent = ({ selectValue, showEmptyPage, url, setTabValue, setProductInfo, setAdditionalInfoForTableMenu, detailTabNumber, tableRef }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Content>
      {isLoading && <LoadingSpinner />}
      {showEmptyPage.show && selectValue === payFilterSelectEnums.subscription && (
        <EmptyScreenMessage
          icon={<EmptyScreenIcon />}
          title={t('pay:selectSubscriptionDetails')}
          subTitle={t('pay:youCanListByEnteringSubscriptionDetails')}
        />
      )}

      {!showEmptyPage.show && (
        <>
          {selectValue === payFilterSelectEnums.product && (
            <ProductionTable
              tableRef={tableRef}
              url={url}
              setTabValue={setTabValue}
              setProductInfo={setProductInfo}
              setAdditionalInfoForTableMenu={setAdditionalInfoForTableMenu}
              detailTabNumber={detailTabNumber}
              setIsLoading={setIsLoading}
            />
          )}
          {selectValue === payFilterSelectEnums.subscription && (
            <SubscriptionTable
              tableRef={tableRef}
              url={url}
              setTabValue={setTabValue}
              setProductInfo={setProductInfo}
              setAdditionalInfoForTableMenu={setAdditionalInfoForTableMenu}
              detailTabNumber={detailTabNumber}
              setIsLoading={setIsLoading}
            />
          )}
        </>
      )}
    </Content>
  );
};
export default MainContent;
