import { Typography, styled } from '@mui/material';
import PTooltip from 'components/tooltip/PTooltip';
import { primaryColors } from 'config/theme/Colors';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const TableCell = styled('td')(({ textAlign, position }) => {
  let positionCss = {};
  if (position) {
    positionCss = {
      position: 'sticky',
      right: position,
      zIndex: 1,
    };
  }
  return {
    minHeight: '72px',
    maxHeight: '72px',
    height: '72px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100px',
    padding: 12,
    fontSize: 14,
    color: primaryColors.primary,
    textAlign: textAlign || 'left',
    borderBottom: `1px solid ${primaryColors.primary200}`,
    ...positionCss,
  };
});

const PTableRowCell = ({ item, column }) => {
  const value = get(item, column.field);
  const ref = useRef();

  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    if (ref?.current?.scrollWidth > ref?.current?.clientWidth) {
      setTooltipEnabled(true);
    } else {
      setTooltipEnabled(false);
    }
  }, [ref]);

  return (
    <TableCell
      ref={ref}
      textAlign={column.align}
      position={column.position}>
      {column.renderCell ? (
        column.renderCell(item)
      ) : (
        <PTooltip
          placement="bottom-end"
          title={tooltipEnabled ? value : ''}>
          <Typography variant="bodyMedium">{value}</Typography>
        </PTooltip>
      )}
    </TableCell>
  );
};

export default PTableRowCell;
