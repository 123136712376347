import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import MainLayout from 'layout/mainLayout/MainLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import AccountRelatedPersonsListContainer from 'pages/accountRelatedPersons/accountRelatedPersonsList/AccountRelatedPersonsListContainer';
import SubscriptionPermission from 'pages/accountRelatedPersons/subscription/SubscriptionPermission';
import SubscriptionSubpermission from 'pages/accountRelatedPersons/subscription/SubscriptionSubpermission';
import UpsertRelatedPersons from 'pages/accountRelatedPersons/upsertRelatedPersons/UpsertRelatedPersons';

export const accountRelatedPersonsRoutes = [
  {
    path: `${paths.accounting}${paths.accountRelatedPersons}`,
    element: (
      <ProtectedRoute>
        <MainLayout>
          <AccountRelatedPersonsListContainer />
        </MainLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.add}${paths.accounting}${paths.accountRelatedPersons}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <UpsertRelatedPersons />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.add}${paths.accounting}${paths.subscriptionPermission}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <SubscriptionPermission />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.add}${paths.accounting}${paths.subscriptionSubpermission}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <SubscriptionSubpermission />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
];
