import { Menu, MenuItem, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { paths } from 'constants/Paths';
import { updateSubuser } from 'pages/accountRelatedPersons/api/AcountRelatedPersonsApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { subSubcriptionEnum } from '../SubSubcriptionEnum';

const SubSubcriptionListMenu = ({ row, loaderFunction, setIsLoading }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    navigate(`${paths.add}${paths.accounting}${paths.subscriptionSubpermission}`, { state: { ...row, actionType: subSubcriptionEnum.update } });
    handleClose();
  };

  const makeActivePassive = () => {
    handleClose();
    setIsLoading(true);
    updateSubuser({
      interlocutorCode: row.interlocutorNumber,
      name: row.firstName,
      lastname: row.lastName,
      phone: row.phone,
      emailAddress: row.email,
      status: row.deletionFlag ? false : true,
    })
      .then((res) => {
        toast.success(res.data.message);
        loaderFunction();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <PIconButton
        inputtype="text"
        onClick={handleClick}>
        <IconResolver iconName="MoreVert" />
      </PIconButton>
      <Menu
        id="subscriptionList"
        aria-labelledby="subscriptionList"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={onEdit}>
          <Typography variant="labelLarge">{t('accountRelatedPersons:update')}</Typography>
        </MenuItem>
        <MenuItem onClick={makeActivePassive}>
          <Typography variant="labelLarge">
            {row.deletionFlag ? t('accountRelatedPersons:makePassive') : t('accountRelatedPersons:makeActive')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SubSubcriptionListMenu;
