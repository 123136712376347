export const StorageCartTr = {
  stockCode: 'Stok Kodu',
  product: 'Ürün',
  orderNo: 'Sipariş No',
  penNo: 'Kalem No',
  stockStatus: 'Stok Durumu',
  amount: 'Miktar',
  delete: 'Sil',
  default: 'Varsayılan',
  common: 'Ortak',
  individual: 'Bireysel',
  updateCart: 'Sepeti Güncelle',
  createDelivery: 'Teslimat Oluştur',
  removeSelectedRow: 'Silmek istediğinize emin misiniz ?',
  cartName: 'Sepet Adı',
  cancel: 'İptal',
  save: 'Kaydet',
  cannotSendMoreThanTheStock: 'Depo stoğundan fazla adette gönderim yapamazsınız.',
  thereAreNoProductsInYourCart: 'Sepetinizde ürün bulunmamaktadır.',
  selectStorage: 'Depo seçin',
  thereAreItemsFromDifferentStorage: 'Sepetinizde farklı depodan ürünler olduğu için teslimat ayrı ayrı oluşturulmalıdır.',
  storageNo: 'numaralı depo',
  cannotShipMoreThanStorageStock: 'Depo stoğunuzdan fazla adette gönderim yapamazsınız!',
  itemsInBasket: 'Sepetteki Ürünler',
  piece: 'Adet',
  serialNo: 'Seri No',
};
