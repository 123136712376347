/* eslint-disable max-len */
export const PayEn = {
  pentaSubscriptionManagement: 'Penta Subscription Management',
  manageYourOwnAndEnUsersSubscriptions: 'Manage your own and end users subscriptions',
  subscriptions: 'Subscriptions',
  subscriptionCart: 'Subscription Cart',
  endUserReports: 'End User Reports',
  subscriptionDetail: 'Subscription Detail',
  lastNthDays: 'Last {{day}} Days',
  all: 'All',
  manuel: 'Manuel',
  automatic: 'Automatic',
  activeOnes: 'Active Ones',
  showByProducts: 'Show by products',
  showBySubscriptions: 'Show by subscriptions',
  renewalDates: 'Renewal Dates',
  startDateLabel: 'Start',
  endDateLabel: 'End',
  renewalType: 'Renewal Type',
  brand: 'Brand',
  searchPlaceholder: 'Search...',
  endUser: 'End User',
  subscriberNo: 'Penta Subscriber No',
  detail: 'Details',
  contractNo: 'Contract No',
  status: 'Status',
  list: 'List',
  enterSubscriberNo: 'Enter subscriber no',
  enterContractNo: 'Enter contract no',
  productsList: 'Products List',
  piece: 'Piece',
  product: 'Product',
  renewalDate: 'Renewal Date',
  selectSubscriptionDetails: 'Select subscription details',
  youCanListByEnteringSubscriptionDetails: 'You can list by entering subscription details.',
  subscriptionList: 'Subscription List',
  subscription: 'Subscription',
  welcomeMessage: 'Welcome to Penta Subscription Management!',
  introductionFirstParagraph:
    'Penta Subscription Management is a platform where you can monitor the status of subscription products purchased from Penta, renew products when their renewal period arrives, and add new products to the relevant subscription',
  firstTitle: 'Who can use the Penta Subscription Management screens?',
  secondTitle: 'How to filter subscriptions on Penta Subscription Management screens?',
  thirdTitle: 'How to contract renewal process on Penta Subscription Management screens?',
  firstTitleFirstParagraph: 'It is suitable for use by all resellers who wish to sell subscription products.',
  searchByManufacturerContractNumber: 'Search by Manufacturer Contract Number',
  secondTitleFirstParagraph:
    'Go to the "My Subscriptions" tab, enter the contract number (VIP number) in the "Contract No" field, select the "All" option in the "Status" field, and then perform the search. Records matching the entered information will be listed',
  searchByPentaSubscriptionNumber: 'Search by Penta Subscription Number',
  secondTitleSecondParagraph:
    'Go to the "My Subscriptions" tab, enter the subscription number in the "Subscriber No" field, select the "All" option in the "Status" field, and then perform the search. Records matching the entered information will be listed.',
  thirdTitleFirstParagraph:
    'After locating the contract, click on the "Actions" tab as shown in the screenshot below, and then press the "Renew" button.',
  thirdTitleSecondParagraph: 'After determining the quantity of the product, select the product to be renewed and click the "Add" button.',
  thirdTitleThirdParagraph: 'Go to the Subscription Cart tab and complete the order by clicking the "Place Order" button.',
  reportView: 'View Report',
  addNewReport: 'New Report',
  endCustomerCsnNo: 'End Customer CSN',
  endCustomerName: 'End Customer Name',
  report: 'Report',
  refresh: 'Refresh',
  create: 'Create',
  createUserReport: 'Create End User Report',
  endUserList: 'End User List',
  resellerCSN: 'Reseller CSN',
  insightMetrics: 'Insight Metrics',
  usageReports: 'Usage Reports',
  successful: 'Successful',
  date: 'Date',
  requestMailAddress: 'Request Creator',
  resellerCsnNo: 'Reseller CSN',
  getInsightStatus: 'Insight Metrics',
  getUsageStatus: 'Usage Reports',
  noRecordFound: 'No Record Found',
  productList: 'Product List',
  refreshType: 'Renewal Type',
  startDate: 'Start Date',
  endDate: 'Expiration Date',
  notSelect: 'No Selected',
  selectedWaitingAutodesk: 'No selectedWaiting for response from Autodesk service',
  completed: 'Completed',
  newReportSuccessMessage: 'The report request was processed successfully.',
  manage: 'Manage',
  addProduct: 'Add Product',
  cancel: 'Cancel',
  stop: 'Stop',
  change: 'Change Count',
  manualRenewal: 'Manual Renewal',
  automaticRenewal: 'Automatic Renewal',
  updateCart: 'Update Cart',
  subscriptionProducts: 'Subscription Products',
  process: 'Process',
  projectNo: 'Project No',
  shipmentType: 'Shipment Type',
  maturity: 'Maturity',
  unitPrice: 'Unit Price',
  grandTotal: 'Grand Total',
  insufficientStock: 'Insufficient Stock',
  licenseProducts: 'License Products',
  individual: 'Individual',
  common: 'Common',
  orderSummary: 'Order Summary',
  subscriptionProductsLower: 'Subscription products',
  licenseProductsLower: 'License products',
  eArchive: 'E-Archive',
  invoicesWillBeSentToYourDefinedEMailAddresses: 'invoices will be sent to your defined e-mail addresses.',
  getAnOffer: 'Get an Offer',
  order: 'Order',
  availableCreditLimit: 'Available credit limit',
  orderDetails: 'Order Details',
  informationAndExplanations: 'Information and Explanations',
  enterInformationAndDescriptions: 'Enter information and descriptions',
  orderDescription: 'Order Description',
  orderDescriptionPlaceholder: 'Order description',
  invoiceDescription: 'Invoice Description',
  invoiceDescriptionPlaceholder: 'Invoice description',
  deliveryNoteDescription: 'Delivery Note Description',
  deliveryNoteDescriptionPlaceholder: 'Delivery note description',
  paymentOptions: 'Payment Options',
  chooseShippingOptionsAndNotificationMethods: 'Choose shipping options and notification methods',
  openAccount: 'Open account',
  bankTransfer: 'Bank transfer',
  onlinePayment: 'Online Payment',
  pleaseSelectPaymentType: 'Please select payment type.',
  orderCreatedSuccessfully: 'Order created successfully!',
  youCanViewOrderDetailsInTheTableBelow: 'You can view order details in the table below.',
  orderItems: 'Order Items',
  itemNumber: 'Item No',
  productName: 'Product Name',
  taxIncludedPrice: 'KDV Included Total',
  transactionResult: 'Transaction Result',
  couldNotCreateOrder: 'Could not create order',
  document: 'Document',
  contactInformation: 'Contact Information',
  productHistoryNoParams: 'Product History No. {{number}}',
  billingHistory: 'Billing History',
  transactionHistory: 'Transaction History',
  documentNo: 'Document No',
  billingDocument: 'Billing Document',
  quantity: 'Quantity',
  amount: 'Amount',
  description: 'Description',
  addingSubscriptionProduct: 'Adding Subscription Product',
  productWillBeAddedToYourSubscription: 'The product will be added to your subscription.',
  productCodeOrName: 'Product Code or Name',
  productQuantity: 'Product Quantity',
  add: 'Add',
  productContractRenewal: 'Product Contract Renewal',
  selectTheProductsYouWantToRenew: 'Select the products you want to renew.',
  productContractTermination: 'Product Contract Termination',
  theContractWillBeTerminatedForThisProduct: 'The contract will be terminated for this product. Do you want to terminate the contract?',
  cancelSinlgeWord: 'Cancel',
  terminate: 'Terminate',
  itemContractTermination: 'Item Contract Termination',
  theContractWillBeTerminatedForThisItem: 'The contract will be terminated for this item. Do you want to end the contract?',
  changeContractProductQuantity: 'Change Contract Product Quantity',
  productNoAndName: 'Product No and Name',
  thisProductCurrentlyAppearsToBeNumberUnits:
    'This product currently appears to be {{number}} units.<br/>When you increase the product, you will be directed to the cart.<br/>If you want to make a reduction, it will be applied directly on this screen.',
  startingDate: 'Starting Date',
  requestForOffer: 'Request for Offer',
  selectRequestOptions: 'Select request options',
  getAnOfferInfoAlertLabel:
    'After your transaction is completed, you can follow the demand for the products you have selected from the price request list.',
  createQuickRequest: 'Create Quick Request',
  createDetailedRequest: 'Create Detailed Request',
  requestName: 'Request Name',
  clearShippedItemsFromCart: 'Clear shipped items from cart',
  endingDate: 'Ending Date',
  optional: 'Optional',
  username: 'Username',
  thisFieldIsRequired: 'Please enter {{field}}.',
  MaxLength: '{{field}} field has a maximum of {{char}} characters.',
  DateFormat: '{{field}} field is not a date.',
  note: 'Note',
  itemUpdate: 'Item Update',
  itemNote: 'Item Note',
  update: 'Update',
  singleCancel: 'Cancel',
  approveDecreseModalBodyText: 'A contract change will be made for this subscription. Do you want to change the contract?',
  clickToDefineLicenseFormInformation: 'Click to define license form information',
  doYouConfirmYourOrder: 'Do you confirm your order ?',
  save: 'Save',
  dataInformationIsRequired: '{{field}} information is required.',
  productLicenseInformationNo: ' Product License Information No {{number}}',
  saveSimilarFormsWithTheSameInformation: 'Save similar forms with the same information',
  transactionCreatedSuccessfully: 'Transaction created successfully',
  youanFollowYourTransactionFromThePriceRequestListWithTheRelevantProjectNumberInformation:
    'You can follow your transaction from the price request list with the relevant project number information.',
  goToQuoteList: 'Go to Quote List',
  subscriptionDetailWithoutI: 'Subscription Detail',
  subscriptionDetailList: 'Subscription Detail List',
  subscriptionActions: 'Subscription Actions',
  productActions: 'Product Actions',
  subscriptionAdd: '{{number}} subscriptions will be added to your contract.',
  subscriptionDelete: '{{number}} subscriptions will be deleted from your contract.',
  clear: 'Clear',
  productHistory: 'History',
  subscriptionDocuments: 'Documents',
};
