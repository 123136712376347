import { FixedSizeList as List } from 'react-window';

const PClientInfiniteScroll = ({ data, renderItem, listHeight = 100, itemHeight = 50 }) => {
  return (
    <List
      height={listHeight}
      itemCount={data.length}
      itemSize={itemHeight}>
      {({ index, style }) => (
        <div
          style={style}
          className="ellipsis">
          {renderItem(data[index])}
        </div>
      )}
    </List>
  );
};

export default PClientInfiniteScroll;
