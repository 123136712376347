import styled from '@emotion/styled';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { slateColors } from 'config/theme/Colors';
import masterCard from 'svgs/masterCard.svg';
import troy from 'svgs/troy.png';
import visa from 'svgs/visa.svg';

const CopyRight = styled('p')(() => ({
  fontSize: '12px',
  fontWeight: 500,
  color: slateColors.slate950,
  textAlign: 'center',
}));

const FooterContainer = styled(Box)(() => ({
  flexDirection: 'column',
  display: 'flex',
  gap: '10px',
}));

const CustomBottomNavigation = styled(BottomNavigation)(() => ({
  backgroundColor: 'transparent',
  gap: '24px',
  display: 'flex',
  height: 'auto',
  flexWrap: 'wrap',
  margin: '0 12px',
  '&>a': {
    minWidth: 'auto',
    height: '44px',
    padding: 0,
  },
}));

const LoginFooter = () => {
  return (
    <FooterContainer>
      <CustomBottomNavigation>
        {/* <Box>
          <BottomNavigationAction
            component={Link}
            to="https://apps.apple.com/us/app/mobil-bayinet/id1436574579"
            icon={
              <img
                width={120}
                height={44}
                src={appStore}
                alt="App Store"
              />
            }
          />
          <BottomNavigationAction
            component={Link}
            to="https://play.google.com/store/apps/details?id=com.bayinet.app"
            icon={
              <img
                width={120}
                height={44}
                src={googleStore}
                alt="Google Play"
              />
            }
          />
        </Box> */}
        <Box>
          <BottomNavigationAction
            disabled
            icon={
              <img
                width={63}
                height={44}
                src={visa}
                alt="Visa"
              />
            }
          />
          <BottomNavigationAction
            disabled
            icon={
              <img
                width={64}
                height={44}
                src={masterCard}
                alt="Master Card"
              />
            }
          />
          <BottomNavigationAction
            disabled
            icon={
              <img
                width={58}
                height={32}
                src={troy}
                alt="Troy"
              />
            }
          />
        </Box>
      </CustomBottomNavigation>
      <CopyRight>CopyRight © {new Date().getFullYear()}. Penta Teknoloji Ürünleri Dağıtım Ticaret A.Ş</CopyRight>
    </FooterContainer>
  );
};

export default LoginFooter;
