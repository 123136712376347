import { Box, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import LoginAppBar from 'layout/loginLayout/LoginAppBar';
import MainAppBar from 'layout/mainLayout/MainAppBar';
import { useSelector } from 'react-redux';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  minHeight: '100vh',
}));

const MainWrapper = styled('main')(() => ({
  backgroundColor: primaryColors.primary50,
  width: '100%',
  flexWrap: 'wrap',
  marginTop: '80px',
  marginBottom: '16px',
}));

const ChildLayout = ({ children }) => {
  const userPermissions = useSelector((state) => state.currentUser?.currentUser?.permissions);
  return (
    <Wrapper>
      {userPermissions ? <MainAppBar /> : <LoginAppBar />}
      <MainWrapper>{children}</MainWrapper>
    </Wrapper>
  );
};

export default ChildLayout;
