import { Typography } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StorageCostsDetailCollapsableTable from '../components/StorageCostsDetailCollapsableTable';

const ShippingCostTab = ({ tableData }) => {
  const { t } = useTranslation();
  const [totalCount, setTotalCount] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    setTotalCount(tableData.length);
  }, [tableData.length]);

  const headers = useMemo(() => {
    return [
      { name: t('storageCost:shipmentNo') },
      { name: t('storageCost:shipmentType') },
      { name: t('storageCost:buyer') },
      { name: t('storageCost:status') },
      { name: t('storageCost:volume'), align: 'right' },
      { name: t('storageCost:transportationCost'), align: 'right' },
      { name: t('storageCost:preparationCost'), align: 'right' },
      { name: t('storageCost:cargoTrack') },
    ];
  }, [t]);

  const handlePageChange = (_, newPage) => {
    setPaginationModel((prevState) => ({ ...prevState, page: newPage - 1 }));
  };

  const menuItems = useMemo(() => {
    return [
      { key: 10, value: 10 },
      { key: 20, value: 20 },
      { key: 50, value: 50 },
    ];
  }, []);

  const handlePageSizeChange = (event) => {
    const prevPageSize = paginationModel.pageSize;
    const pageSize = event.target.value;
    let page = paginationModel.page;
    if (prevPageSize < pageSize) {
      page = Math.round(totalCount / pageSize) > 0 ? Math.round(totalCount / pageSize) - 1 : Math.round(totalCount / pageSize);
    }

    setPaginationModel((prevState) => ({ ...prevState, page, pageSize }));
  };
  return (
    <>
      {tableData?.length === 0 && (
        <Typography
          color={primaryColors.primary400}
          variant="titleMedium">
          {t('storageCost:noShippingCost')}
        </Typography>
      )}
      {tableData.length > 0 && (
        <StorageCostsDetailCollapsableTable
          headers={headers}
          rows={tableData}
          totalCount={totalCount}
          menuItems={menuItems}
          page={paginationModel.page}
          handlePageChange={handlePageChange}
          pageSize={paginationModel.pageSize}
          handleChange={handlePageSizeChange}
        />
      )}
    </>
  );
};

export default ShippingCostTab;
