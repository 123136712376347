import { Typography } from '@mui/material';
import { TableHeaderCell } from 'components/table/customTable/PTableHeader';
import { primaryColors } from 'config/theme/Colors';

const StorageCostsDetailTableCell = ({ header }) => {
  return (
    <TableHeaderCell align={header.align || 'left'}>
      <Typography
        variant="labelMedium"
        color={primaryColors.primary}>
        {header.name}
      </Typography>
    </TableHeaderCell>
  );
};

export default StorageCostsDetailTableCell;
