export const MainAppBarEn = {
  searchSomething: 'Search something...',
  logout: 'Logout',
  turkish: 'Turkish',
  english: 'English',
  management: 'Management',
  language: 'Language',
  managementPanel: 'Management Panel',
  passwordOperations: 'Passoword Operations',
  'user-management': 'User Management',
  services: 'Services',
  'penta-storage': 'Penta Storage',
  'delivery-addresses': 'My Delivery Addresses',
  myContracts: 'My Contracts',
  'config-master': 'Penta Config Master',
  multipleServices: 'Services',
  manageSubscriptionDetails: 'Manage subscription details.',
  'penta-pay': 'Penta Subscription Management',
  'penta-integration': 'Penta Integration',
};
