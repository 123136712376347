import PClientTable from 'components/table/PClientTable';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/HelperFunctions';
import { v4 as uuidv4 } from 'uuid';

const BillingHistory = ({ row }) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        field: 'billingDate',
        headerName: t('pay:date'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 120,
        renderCell: ({ row }) => dayjs(row.billingDate).format('DD.MM.YYYY'),
      },
      {
        field: 'documentNumber',
        headerName: t('pay:documentNo'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 120,
        renderCell: ({ row }) => {
          if (row.documentNumber) {
            return row.documentNumber;
          }
          return '-';
        },
      },
      {
        field: 'billingDocument',
        headerName: t('pay:billingDocument'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 120,
        renderCell: ({ row }) => {
          if (row.billingDocument) {
            return <div sclassName="hyperlink-text">{row.billingDocument}</div>;
          }
          return '-';
        },
      },
      {
        field: 'count',
        headerName: t('pay:quantity'),
        disableColumnMenu: true,
        minWidth: 96,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'amount',
        headerName: t('pay:amount'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 254,
        renderCell: ({ row }) => {
          return `${formatPrice(row.amount)} ${row.currency}`;
        },
      },
      {
        field: 'status',
        headerName: t('pay:status'),
        disableColumnMenu: true,
        minWidth: 96,
      },
    ];
  }, [t]);

  return (
    <PClientTable
      headerTitle={t('pay:billingHistory')}
      columns={columns}
      rows={row.billingList}
      getRowId={() => uuidv4()}
    />
  );
};
export default BillingHistory;
