import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography, styled } from '@mui/material';
import { blueColors, primaryColors } from 'config/theme/Colors';
const InfoContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${blueColors.blue300}`,
  backgroundColor: blueColors.blue50,
  padding: '10px 12px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  minHeight: '40px',
  justifyContent: 'space-between',
  wordBreak: 'break-word',
}));

const InfoAlert = ({ label, icon = null, ...rest }) => {
  return (
    <InfoContainer {...rest}>
      <Box className="d-flex gap-8 align-items-center">
        <InfoOutlinedIcon sx={{ color: blueColors.blue600 }} />
        <Typography
          variant="bodyMedium"
          color={primaryColors.primary}>
          {label}
        </Typography>
      </Box>
      {icon}
    </InfoContainer>
  );
};
export default InfoAlert;
