import { Box, RadioGroup, styled, Typography } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import WarningAlert from 'components/alert/WarningAlert';
import PButton from 'components/button/PButton';
import PDivider from 'components/Divider/PDivider';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PRadio from 'components/radio/PRadio';
import { primaryColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { contractPersonTypeEnum } from 'pages/accountRelatedPersons/common/Enums';
import { simulateInOrderPage, updateCartItemQuantity } from 'pages/pay/api/PayApi';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatPrice } from 'utils/HelperFunctions';

const SubscriptionSummaryContainer = styled(Box)(() => ({
  width: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  border: `1px solid ${primaryColors.primary200}`,
  display: 'flex',
  flexDirection: 'column',
}));

const Title = styled(Box)(() => ({
  padding: '28px 16px',
}));

const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px',
}));

const DynamicContainer = styled(Box)(() => ({
  paddingTop: '24px',
  '& > *': {
    borderBottom: `1px solid ${slateColors.slate200}`,
  },
  '&>*:nth-of-type(1)': {
    padding: 0,
    paddingBottom: '24px',
  },
  'div:last-of-type': {
    borderBottom: 0,
  },
}));

const Item = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 0',
  width: '100%',
}));

const CreditLimit = styled(Box)(() => ({
  background: 'linear-gradient(92.38deg, #F6FAE4 0%, #F7FBED 49.98%, #F4FBF5 74.99%, #F4F8E9 100%)',
  width: '100%',
  padding: '16px 12px',
  borderRadius: '8px',
}));

const SubscriptionSummary = ({ setShowOrderDetail, incomingDatas, activeBasketId, isProjectBasket }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState('');
  const onRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  useEffect(() => {
    if (incomingDatas.length > 0) {
      if (!selectedRadio) {
        setSelectedRadio(incomingDatas[0].cartGroup);
      }
    }
  }, [incomingDatas]);

  const renderDynamicField = useCallback(() => {
    const foundGroup = incomingDatas.find((item) => item.cartGroup === selectedRadio);

    if (foundGroup) {
      return foundGroup.amounts.map((amount) => {
        return (
          <Item key={amount.key}>
            <Box>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {amount.amountType}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${formatPrice(amount.amount)} ${amount.currency}`}
              </Typography>
            </Box>
          </Item>
        );
      });
    }

    return <Item />;
  }, [incomingDatas, selectedRadio]);

  const renderCreditLimit = useCallback(() => {
    const foundGroup = incomingDatas.find((item) => item.cartGroup === selectedRadio);

    if (foundGroup) {
      return foundGroup.creditLimit;
    }

    return '';
  }, [incomingDatas, selectedRadio]);

  const renderWarningAlert = useCallback(() => {
    const foundGroup = incomingDatas.find((item) => item.cartGroup === selectedRadio);

    if (foundGroup && foundGroup.matrixTxt) {
      return <WarningAlert label={foundGroup.matrixTxt} />;
    }

    return <Box />;
  }, [incomingDatas, selectedRadio]);

  const createOrder = () => {
    const updateProductList = [];
    setLoading(true);
    incomingDatas.forEach(({ byGroups }) => {
      byGroups.forEach(({ products }) => {
        products.forEach(({ cartItemId, quantity }) => {
          updateProductList.push({
            cartItemId: cartItemId,
            quantity: quantity,
          });
        });
      });
    });
    updateCartItemQuantity(updateProductList)
      .then(() => {
        const found = incomingDatas.find((incomingData) => incomingData.cartGroup === selectedRadio);
        const productIds = found?.byGroups?.flatMap((group) => group.products.map((product) => product.productNumber)) || [];
        const convertedSendObject = {
          productIds,
          cartGroup: selectedRadio,
        };
        setLoading(true);
        simulateInOrderPage(convertedSendObject)
          .then((res) => {
            setShowOrderDetail(res.data.data);
          })
          .catch((err) => {
            if (err?.response?.data?.Message) {
              toast.error(err.response.data.Message);
            } else {
              err?.response?.data?.data?.errorMessages?.forEach((item) => {
                toast.error(item.message);
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        setLoading(false);
      });
  };

  const navigateToGetAnOffer = () => {
    const updateProductList = [];
    setLoading(true);
    incomingDatas.forEach(({ byGroups }) => {
      byGroups.forEach(({ products }) => {
        products.forEach(({ cartItemId, quantity }) => {
          updateProductList.push({
            cartItemId: cartItemId,
            quantity: quantity,
          });
        });
      });
    });
    updateCartItemQuantity(updateProductList)
      .then(() => {
        navigate(`${paths.subscriptions}${paths.getAnOffer}`, { state: { selectedRadio, activeBasketId } });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SubscriptionSummaryContainer>
      <Title>
        <Typography variant="titleMedium">{t('pay:orderSummary')}</Typography>
      </Title>
      {loading && <LoadingSpinner />}
      <PDivider marginBottom="0" />
      {incomingDatas.length > 0 && (
        <ItemContainer>
          <Box className="d-flex flex-column">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedRadio}
              onChange={onRadioChange}>
              {incomingDatas.map((incomingData) => {
                return (
                  <PRadio
                    key={incomingData.cartGroup}
                    label={incomingData.cartGroupDefinition}
                    value={incomingData.cartGroup}
                    onChange={onRadioChange}
                  />
                );
              })}
            </RadioGroup>
          </Box>
          <DynamicContainer>{renderDynamicField()}</DynamicContainer>
          <Box
            sx={{ paddingTop: '32px' }}
            className="d-flex flex-column gap-16">
            <CreditLimit>
              <Box className="d-flex flex-column">
                <Typography
                  variant="bodySmall"
                  color={primaryColors.primary800}>
                  {`${t('pay:availableCreditLimit')}:`}
                </Typography>
                <Typography
                  variant="titleMedium"
                  color={primaryColors.primary}>
                  {renderCreditLimit()}
                </Typography>
              </Box>
            </CreditLimit>
            {renderWarningAlert()}
            <InfoAlert
              label={
                <div>
                  <span
                    className="hyperlink-text"
                    onClick={() =>
                      navigate(`${paths.accounting}${paths.accountRelatedPersons}`, {
                        state: { selectedKey: contractPersonTypeEnum.eArchive },
                      })
                    }>
                    {t('pay:eArchive')}
                  </span>
                  <span> {t('pay:invoicesWillBeSentToYourDefinedEMailAddresses')}</span>
                </div>
              }
            />
          </Box>
          <Box
            sx={{ paddingTop: '32px' }}
            className="d-flex flex-column gap-16">
            <PButton
              disabled={isProjectBasket}
              inputtype="outline"
              onClick={navigateToGetAnOffer}>
              {t('pay:getAnOffer')}
            </PButton>
            <PButton onClick={createOrder}>{t('pay:order')}</PButton>
          </Box>
        </ItemContainer>
      )}
    </SubscriptionSummaryContainer>
  );
};

export default SubscriptionSummary;
