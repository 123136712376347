export const StorageCostEn = {
  storageCosts: 'Storage Costs',
  shippingCosts: 'Shipping Costs',
  productNo: 'Product No',
  productName: 'Product Name',
  piece: 'Piece',
  desi: 'Desi',
  differentProductCost: 'Different Product Cost',
  desiCost: 'Desi Cost',
  shipmentNo: 'Shipment No',
  shipmentType: 'Shipment Type',
  buyer: 'Buyer',
  status: 'Status',
  volume: 'Volume',
  transportationCost: 'Transportation Cost',
  preparationCost: 'Preparation Cost',
  cargoTrack: 'Cargo Track',
  trackLink: 'Track Link',
  referenceDocument: 'Reference Document',
  referencePen: 'Reference Pen',
  amount: 'Amount',
  costAndShippingDetailsDate: ' Cost and Shipping Details Dated {{date}}',
  noShippingCost: 'There are no shipping costs.',
  noWarehouseCost: 'There are no warehouse costs.',
  noResultsFound: 'No Results Found',
  costTable: 'Cost Table',
};
