import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { primaryColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';

const NoActiveRequest = styled(Box)(() => ({
  width: '100%',
  height: '160px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
}));

const ActiveRequestNotFound = ({ toogleShowTestModal }) => {
  const { t } = useTranslation();
  return (
    <NoActiveRequest>
      <Typography variant="titleSmall">{t('integration:thereIsNoActiveRequestYet')}</Typography>
      <Typography
        variant="bodySmall"
        color={primaryColors.primary800}>
        {t('integration:pleaseCreateANewRequest')}
      </Typography>
      <PButton
        width="auto"
        startIcon={<IconResolver iconName="Add" />}
        onClick={toogleShowTestModal}>
        {t('integration:createRequest')}
      </PButton>
    </NoActiveRequest>
  );
};

export default ActiveRequestNotFound;
