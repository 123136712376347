export const StorageCostTr = {
  storageCosts: 'Depolama Maliyetleri',
  shippingCosts: 'Kargo Maliyetleri',
  productNo: 'Ürün No',
  productName: 'Ürün Adı',
  piece: 'Adet',
  desi: 'Desi',
  differentProductCost: 'Farklı Ürün Maliyeti',
  desiCost: 'Desi Maliyeti',
  shipmentNo: 'Sevkiyat No',
  shipmentType: 'Sevkiyat Türü',
  buyer: 'Alıcı',
  status: 'Durumu',
  volume: 'Hacim',
  transportationCost: 'Taşıma Bedeli',
  preparationCost: 'Hazırlama Bedeli',
  cargoTrack: 'Kargo Takip',
  trackLink: 'Takip Linki',
  referenceDocument: 'Referans Belge',
  referencePen: 'Referans Kalem',
  amount: 'Miktar',
  costAndShippingDetailsDate: '{{date}} Tarihli Maliyet ve Kargo Detayı',
  noShippingCost: 'Kargo maliyetleri bulunmamaktadır.',
  noWarehouseCost: 'Depo maliyetleri bulunmamaktadır.',
  noResultsFound: 'Sonuç Bulunamadı',
  costTable: 'Maliyet Tablosu',
};
