import { Box, styled } from '@mui/material';
import { blueColors, neutralColors, viloetColors, yellowColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import bigB from 'media/assets/bigB.png';
import calendar from 'media/assets/calendar.png';
import configuration from 'media/assets/configuration.png';
import openBox from 'media/assets/openBox.png';
import productIntegration from 'media/assets/productIntegration.png';
import { checkContracts } from 'pages/landing/api/LandingApi';
import LandingCard from 'pages/landing/components/LandingCard';
import { landingScreenmenuButtons } from 'pages/landing/constants/MenuButtonEnums';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '16px',
  gridTemplateRows: '151px 151px 69px',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    gridTemplateRows: ' repeat(2, 200px)',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    padding: '0 12px',
    flexDirection: 'column',
  },
}));

const LargeCard = styled(Box)(() => ({
  gridColumnStart: 2,
  gridColumnEnd: 6,
  gridRowStart: 1,
  gridRowEnd: 4,
  display: 'grid',
  paddingRight: '8px',
}));

const SmallCard = styled(Box)(({ start, end }) => ({
  gridColumnStart: start,
  gridColumnEnd: end,
  display: 'grid',
}));

const LandingPage = () => {
  const landingScreenButtons = useSelector((state) => state.currentUser?.currentUser?.landingScreenMenu);
  const { t } = useTranslation();
  const cardItems = useMemo(() => {
    const array = [];
    if (landingScreenButtons) {
      landingScreenButtons.forEach((landingScreenButton, index) => {
        let start = 6;
        let end = 9;

        if (index % 2 === 1) {
          start = 9;
          end = 12;
        }

        if (landingScreenButton === landingScreenmenuButtons.storage) {
          array.push({
            title: t('landing:pentaStorage'),
            subtitle: t('landing:manageYourProductsInStorage'),
            imageBackground: yellowColors.yellow50,
            url: `${paths.storage}${paths.main}`,
            start: start,
            end: end,
            image: openBox,
          });
        } else if (landingScreenButton === landingScreenmenuButtons.pay) {
          array.push({
            title: t('landing:pentaSubscriptionManagement'),
            subtitle: t('landing:manageSubscriptionDetails'),
            imageBackground: viloetColors.violet50,
            url: `${paths.subscriptions}${paths.byProducts}`,
            start: start,
            end: end,
            image: calendar,
          });
        } else if (landingScreenButton === landingScreenmenuButtons.configMaster) {
          array.push({
            title: t('landing:pentaConfigMaster'),
            subtitle: t('landing:configureTheHardwareYouWant'),
            imageBackground: neutralColors.neutral50,
            url: `${paths.wizard}${paths.fbw}`,
            start: start,
            end: end,
            image: configuration,
          });
        } else if (landingScreenButton === landingScreenmenuButtons.integrations) {
          array.push({
            title: t('landing:pentaIntegration'),
            subtitle: t('landing:integrateDifferentServices'),
            imageBackground: blueColors.blue50,
            url: `${paths.integration}${paths.productServices}`,
            start: start,
            end: end,
            image: productIntegration,
          });
        }
      });
    }
    return array;
  }, [landingScreenButtons, t]);

  useEffect(() => {
    checkContracts();
  }, []);
  return (
    <GridContainer>
      <LargeCard>
        <LandingCard
          image={bigB}
          imageBackground="#AC202D1F"
          imageWidth={116}
          imageHeight={94}
          bottom="-40px"
          right="-40px"
          imageWidthContainer="222px"
          imageHeightContainer="222px"
          title="Bayinet"
          url={process.env.REACT_APP_BAYINET_URL}
          internal={false}
        />
      </LargeCard>
      {cardItems.map((cardItem) => {
        return (
          <SmallCard
            key={cardItem.title}
            start={cardItem.start}
            end={cardItem.end}>
            <LandingCard
              url={cardItem.url}
              image={cardItem.image}
              title={cardItem.title}
              imageBackground={cardItem.imageBackground}
            />
          </SmallCard>
        );
      })}
    </GridContainer>
  );
};
export default LandingPage;
