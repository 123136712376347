import { Box, styled } from '@mui/material';
import PCenterModal from 'components/modal/PCenterModal';
import { useTranslation } from 'react-i18next';

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PayLinksModal = ({ onClose, links }) => {
  const { t } = useTranslation();

  return (
    <PCenterModal
      showClose
      width="750px"
      onClose={onClose}
      title={t('pay:subscriptionDocuments')}>
      {links &&
        links.map((link) => (
          <Box className="d-flex flex-column gap-20">
            <Content>
              <a
                href={link.fileLink}
                target="_blank"
                rel="noreferrer">
                {link.fileName}
              </a>
            </Content>
          </Box>
        ))}
    </PCenterModal>
  );
};
export default PayLinksModal;
