import { Box, Typography } from '@mui/material';
import PCenterModal from 'components/modal/PCenterModal';
import { useTranslation } from 'react-i18next';

const OrderInfoModal = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <PCenterModal
      showClose
      width="632px"
      title={t('integration:orderServices')}
      onClose={onClose}>
      <Box>
        <ol>
          <li>
            <Typography variant="titleMedium">{t('integration:orderInfoFirstTitle')}</Typography>
          </li>
          <div>
            <Typography variant="bodyMedium">{t('integration:orderInfoFirstItem')}</Typography>
          </div>
          <li>
            <Typography variant="titleMedium">{t('integration:orderInfoSecondTitle')}</Typography>
          </li>
          <div>
            <Typography variant="bodyMedium">{t('integration:orderInfoSecondItem')}</Typography>
          </div>
        </ol>
      </Box>
    </PCenterModal>
  );
};

export default OrderInfoModal;
