/* eslint-disable max-len */
import { Box, Grid } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import BoxSpinner from 'components/loadingSpinner/BoxSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import { contractPersonTypeEnum } from 'pages/accountRelatedPersons/common/Enums';
import { upsertRelatedPersonsValidationMessagesKeys } from 'pages/accountRelatedPersons/upsertRelatedPersons/UpsertRelatedPersonsValidationMessagesKeys';
import { createPersonFromCart } from 'pages/pentaStorage/Tabs/storageCart/api/StorageCartApi';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';

const AddPerson = ({ onClose, storagePlace, setValues }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    relatedPersonNo: null,
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
  });

  const validateForm = () => {
    const rules = {
      firstName: 'required',
      lastName: 'required',
      phoneNumber: 'required',
    };
    let phoneNumber = formData.phoneNumber.substring(1);
    phoneNumber = phoneNumber.replace(/\s/g, '');
    const copyData = { ...formData, phoneNumber };
    const validation = new Validator(copyData, rules, upsertRelatedPersonsValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        firstName: validation.errors.first('firstName'),
        lastName: validation.errors.first('lastName'),
        phoneNumber: validation.errors.first('phoneNumber'),
        emailAddress: validation.errors.first('emailAddress'),
      }));
    }
  };

  const createAddress = () => {
    if (validateForm()) {
      let phoneNumber = formData.phoneNumber?.substring(1);
      phoneNumber = phoneNumber?.replace(/\s/g, '');
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: phoneNumber,
        emailAddress: '',
        isUpdate: false,
        contractPersonType: contractPersonTypeEnum.storageDelivery,
        invoiceTransmissionType: contractPersonTypeEnum.storageDelivery,
        storagePlace,
      };
      setIsLoading(true);
      createPersonFromCart(data)
        .then((res) => {
          toast.success(t('deliveryDetails:personSavedSuccessfully'));
          setValues(res.data.data);
          onClose();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  return (
    <PCenterModal
      width="35%"
      open
      onClose={onClose}
      showClose
      title={t('accountRelatedPersons:addNewPerson')}
      subTitle={t('deliveryDetails:addPersonDescription')}>
      {isLoading && <BoxSpinner />}

      <Box className="d-flex flex-column gap-20">
        <Grid
          container
          spacing={2}>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextField
              label={t('accountRelatedPersons:name')}
              value={formData.firstName}
              name="firstName"
              onChange={onChangeHandler}
              error={!!errors.firstName}
              errorMessage={t(errors.firstName, { field: t('accountRelatedPersons:name') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <PTextField
              label={t('accountRelatedPersons:surname')}
              value={formData.lastName}
              name="lastName"
              onChange={onChangeHandler}
              error={!!errors.lastName}
              errorMessage={t(errors.lastName, { field: t('accountRelatedPersons:surname') })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}>
            <TelInput
              label={`${t('accountRelatedPersons:phone')}`}
              value={formData.phoneNumber}
              onChange={(value) => {
                setFormData((prevState) => ({ ...prevState, phoneNumber: value }));
                setErrors((prevState) => ({ ...prevState, phoneNumber: '' }));
              }}
              error={!!errors.phoneNumber}
              errorMessage={t(errors.phoneNumber, { field: t('accountRelatedPersons:phone') })}
            />
          </Grid>
        </Grid>
        <InfoAlert label={<Trans i18nKey="accountRelatedPersons:infoText" />} />
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={createAddress}>
            {t('deliveryDetails:save')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default AddPerson;
