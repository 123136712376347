export const DeliveryAddressTr = {
  tableBranchCode: 'Şube Kodu',
  branch: 'Şube',
  address: 'Adres',
  phone: 'Telefon',
  status: 'Durum',
  all: 'Hepsi',
  active: 'Aktif',
  passive: 'Pasif',
  myDeliveryAddresses: 'Teslimat Adreslerim',
  manageYourDeliveryAddresses: 'Teslimat adreslerinizi yönetin',
  addBulkAddress: 'Toplu Adres Ekle',
  addNewAddress: 'Yeni Adres Ekle',
  branchCode: 'Şube kodu',
  selectCity: 'Şehir seçin',
  addCity: 'Şehir ekle',
  activePassive: 'Aktif/Pasif',
  fetchAddresses: 'Adresleri Getir',
  myRegisteredAddresses: 'Kayıtlı Adreslerim',
  piece: 'Adet',
  searchAddress: 'Adres ara...',
  youCanOnlyMakeNumberChoices: 'Sadece {{number}} seçim yapabilirsiniz',
  noResultFound: 'Sonuç bulunamadı',
  edit: 'Düzenle',
  makePassive: 'Pasif yap',
  makeActive: 'Aktif yap',
  noMatchingRecordsFound: 'Eşleşen Kayıt Bulunmadı',
  addBulkAddressExcel: 'Dosyadan hızlı bir şekilde toplu adres ekleyin',
  downloadAndCheckTheTemplateBeforeUploading: ' Yüklemeden önce şablonu indirip kontrol edin.',
  downloadTemplate: 'Şablonu indir',
  cancel: 'İptal',
  uploadAddressesToTheSystem: 'Adresleri Sisteme Yükle',
  xlsMaxSize: 'XLS dosyası kabul edilir (max 30MB)',
  addDeliveryAddress: 'Teslimat Adresi Ekle',
  savedSuccessfully: 'Adres başarıyla kaydedildi',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  theEmailFormatIsInvalid: 'E-posta biçimi geçersiz.',
  basicKnowledge: 'Temel Bilgiler',
  companyName: 'Firma Adı',
  city: 'Şehir',
  district: 'İlçe',
  mobile: 'Mobil',
  optional: 'Opsiyonel',
  doorNo: 'Kapı No',
  postCode: 'Posta Kodu',
  fax: 'Faks',
  email: 'E-posta',
  shipment: 'Sevkiyat',
  mapInfo: 'Adresiniz otomatik olarak harita üzerinde güncellenecektir. Harita üzerindeki pin ile adresinizi işaretleyebilirsiniz.',
  saveAddress: 'Adresi Kaydet',
  successfullyUpdated: 'Başarıyla Güncellendi',
  regexFailed: 'Posta kodu 5 haneli bir sayı olmalıdır.',
  view: 'Görüntüle',
  theProcessingOfBulkAdded: 'Toplu eklenen adresleri görüntüleyin',
  selectStartDate: 'Başlangıç tarihi seçin',
  selectEndDate: 'Bitiş tarihi seçin',
  searchForQueueNumber: 'Kuyruk numarası ara',
  list: 'Listele',
  searchWithinTheTable: 'Tablo içerisinde ara...',
  addedFromBulkAddressFile: 'Toplu Adres Dosyasından Eklenenler',
  queueNumber: 'Kuyruk No',
  date: 'Tarih',
  emailTable: 'E-Posta',
  message: 'Mesaj',
  rowNumber: 'Satır No',
  onSuccessFileUpload: '<CustomLink>{{number}}</CustomLink> numarası ile yüklediğiniz adresleri görüntüleyebilirsiniz.',
  viewBulkUploadedAddresses: 'Toplu Yüklenen Adresleri Görüntüle',
};
