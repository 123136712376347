import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, COMMON_API, PAY_API } from 'constants/Keys';
import dayjs from 'dayjs';

const PAY = '/pay';
const SUBSCRIPTIONS = '/subscriptions';
const PRODUCTS = '/products';
const REPORT = '/report';
const REPORTS = '/reports';
const AUTODESKS = '/autodesks';
const BY_SUBSCRIPTION_NUMBER = '/by-subscription-number';
const BRANDS = '/brands';
const END_USERS = '/end-users';
const BY_PRODUCTS = '/by-products';
const BY_SUBSCRIPTIONS = '/by-subscriptions';
const CART = '/cart';
const PRODUCT_COUNT_IN_CART = '/product-count-in-cart';
const ALL_CARTS = '/all-carts';
const SET_CART_AS_CURRENT = '/set-cart-as-current';
const UPDATE_CART_NAME = '/update-cart-name';
const CANCEL_PRODUCT = '/cancel-product';
const CANCEL_SUBSCRIPTION = '/cancel-subscription';
const ADD_ITEM_TO_CART = '/add-item-to-cart';
const CHANGE_PRODUCT = '/change-product';
const CART_ITEMS_BY_ID = '/cartitems-by-id';
const UPDATE_CART_ITEM_QUANTITY = '/update-cart-item-quantity';
const REMOVE_ITEM_FROM_CART = '/remove-item-from-cart';
const CREATE_ORDER_BEFORE = '/create-order-before';
const CREATE_ORDER = '/create-order';
const CREATE_ORDER_WITH_PAYMENT = '/create-order-with-payment';
const CREATE_DEFAULT_PRODUCT_CART = '/create-default-product-cart';
const PAYMENT_RESULT = '/payment-result';
const PAY_INFO = '/pay-info';
const CREATE_OFFER = '/create-offer';
const ADD_LICENCE = '/add-licence';
const SUBS_DETAIL = '/subs-detail';

export const PAY_REPORT_GET_URL = `${PAY_API}${API}${PAY}${REPORT}${REPORTS}`;
export const PAY_DETAIL_GET_URL = `${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${BY_SUBSCRIPTION_NUMBER}`;
export const urlProductByProducts = `${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${BY_PRODUCTS}`;
export const urlProductBySubscriptions = `${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${BY_SUBSCRIPTIONS}`;

export const getItemByRenewalTypeAndGroupId = ({ renewalTypeText, exMaterialGroupId, searchKey }) =>
  httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${PRODUCTS}`, {
    params: {
      RenewalTypeText: renewalTypeText,
      ExMaterialGroupId: exMaterialGroupId,
      SearchKey: searchKey,
    },
  });

export const getAutodesks = () => httpRequest.get(`${PAY_API}${API}${PAY}${REPORT}${AUTODESKS}`);

export const createReport = (formData) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${REPORT}`, {
    ...formData,
  });

export const getBrands = () => httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${BRANDS}`);
export const getEndUsers = () => httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${END_USERS}`);
export const getCartItemCount = () => httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}${PRODUCT_COUNT_IN_CART}`);
export const getAllCarts = () => httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}${ALL_CARTS}`);

export const setCartAsCurrent = (id) =>
  httpRequest.put(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}${SET_CART_AS_CURRENT}`, {
    id,
  });

export const createCart = (cartName) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}`, {
    cartName,
  });

export const updateCartName = (id, name) => httpRequest.put(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}${UPDATE_CART_NAME}`, { id, name });

export const deleteCartOption = (id) => httpRequest.delete(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}?cartId=${id}`);

export const stopProduct = (subscriptionNumber, itemNumber) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CANCEL_PRODUCT}`, {
    subscriptionNumber,
    itemNumber,
  });

export const cancelSubscription = (subscriptionNumber) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CANCEL_SUBSCRIPTION}`, {
    subscriptionNumber,
  });

export const addItemToCart = (products) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}${ADD_ITEM_TO_CART}`, {
    products,
  });

export const getProductsInsideSubscription = (subscriptionNumber) =>
  httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${BY_SUBSCRIPTION_NUMBER}`, {
    params: {
      pageSize: 1000,
      page: 1,
      subscriptionNumber,
    },
  });

export const changeProduct = (product) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CHANGE_PRODUCT}`, {
    ...product,
  });

export const getActiveCartItems = (cartId) => httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}${CART_ITEMS_BY_ID}/${cartId}`);

export const updateCartItemQuantity = (items) =>
  httpRequest.put(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${UPDATE_CART_ITEM_QUANTITY}`, {
    updateCartItems: [...items],
  });

export const deleteItemFromCart = (id) =>
  httpRequest.delete(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CART}${REMOVE_ITEM_FROM_CART}?cartItemId=${id}`);

export const simulateInOrderPage = (products) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CREATE_ORDER_BEFORE}`, {
    ...products,
  });

export const createOrder = (products) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CREATE_ORDER}`, {
    ...products,
  });

export const createOrderWithPayment = (products) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CREATE_ORDER_WITH_PAYMENT}`, {
    ...products,
  });

export const controlForDefaultPayCart = () => httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CREATE_DEFAULT_PRODUCT_CART}`);

export const getPayPaymentResult = (key) => httpRequest.get(`${COMMON_API}${API}${PAYMENT_RESULT}${PAY_INFO}/${key}`);

export const createAnOffer = (offer) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${CREATE_OFFER}`, {
    ...offer,
  });

export const getProductsBySubscriptionNumber = ({ subscriptionNumber, renewalDate }) =>
  httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${BY_SUBSCRIPTION_NUMBER}`, {
    params: {
      SubscriptionNumber: subscriptionNumber,
      RenewalDate: dayjs(renewalDate, 'M.D.YYYY HH:mm:ss').format('YYYY.MM.DD'),
    },
  });

export const addLicence = (licence) =>
  httpRequest.post(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${ADD_LICENCE}`, {
    ...licence,
  });

export const getSubDetails = (subscriptionNumber) =>
  httpRequest.get(`${PAY_API}${API}${PAY}${SUBSCRIPTIONS}${SUBS_DETAIL}?subscriptionNumber=${subscriptionNumber}`);
