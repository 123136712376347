import { Autocomplete, styled } from '@mui/material';
import PChip from 'components/chip/PChip';
import PTextField from 'components/textField/PTextField';

const CustomAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap',
  },
}));

const PMultiSelectAutocomplete = ({
  label = '',
  value,
  onChange,
  menuItems,
  errorMessage,
  error = false,
  placeholder,
  onDeleteHandler,
  preventMessage,
  noOptionsText,
  showHelperContainer = true,
}) => {
  return (
    <>
      <CustomAutocomplete
        value={value}
        onChange={(e, value) => {
          onChange(e, value);
        }}
        multiple
        options={preventMessage ? [] : menuItems}
        noOptionsText={preventMessage || noOptionsText}
        getOptionLabel={(option) => option.value}
        freeSolo={false}
        renderTags={(value) => (
          <div className="d-flex gap-4 flex-wrap">
            {value.map((option) => (
              <PChip
                key={option.key}
                label={option.value}
                onDelete={() => onDeleteHandler(option)}
              />
            ))}
          </div>
        )}
        renderInput={(params) => {
          delete params.inputProps.className;

          return (
            <PTextField
              multiline
              inputwidth="auto"
              {...params}
              label={label}
              placeholder={placeholder}
              showPlaceholder={value.length === 0 ? true : false}
              errorMessage={errorMessage}
              showHelperContainer={showHelperContainer}
            />
          );
        }}
      />
    </>
  );
};

export default PMultiSelectAutocomplete;
