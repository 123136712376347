import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import PTooltip from 'components/tooltip/PTooltip';
import { primaryColors } from 'config/theme/Colors';
import { useEffect, useRef, useState } from 'react';

const CustomCheckbox = styled(Checkbox)(() => {
  return {
    '&.Mui-disabled': {
      color: primaryColors.primary,
      opacity: '38%',
    },
    '&.Mui-checked': {
      color: primaryColors.primary,
    },
    '&.MuiCheckbox-indeterminate': {
      color: primaryColors.primary,
    },
    '&.MuiCheckbox-root:hover': {
      backgroundColor: primaryColors.primary50,
    },
  };
});

export const SelectionFormLabel = styled(FormControlLabel)(({ width }) => ({
  marginRight: '0',
  width: width,
}));

const PCheckbox = ({ checked, onChange, label = '', disabled = false, noWhiteSpace = false, isRadio = false, width = 'auto', ...rest }) => {
  const ref = useRef();
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    if (ref?.current?.scrollWidth > ref?.current?.clientWidth) {
      setTooltipEnabled(true);
    } else {
      setTooltipEnabled(false);
    }
  }, [ref]);

  return (
    <SelectionFormLabel
      width={width}
      disabled={disabled}
      label={
        <Typography
          ref={ref}
          className="ellipsis"
          variant={isRadio ? 'titleMedium' : 'bodyMedium'}
          sx={{
            opacity: disabled && '38%',
            whiteSpace: noWhiteSpace ? 'nowrap' : 'normal',
          }}
          color={primaryColors.primary}>
          <PTooltip
            placement="bottom-end"
            title={tooltipEnabled ? label : ''}>
            {label}
          </PTooltip>
        </Typography>
      }
      control={
        <CustomCheckbox
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          {...rest}
        />
      }
    />
  );
};

export default PCheckbox;
