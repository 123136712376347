import { Box, Typography, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PInfoChip from 'components/chip/PInfoChip';
import { primaryColors } from 'config/theme/Colors';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PPagination from './PPagination';

const CustomDataGrid = styled(DataGrid)(({ maxHeight, minHeight }) => ({
  border: 'none',
  ' &.MuiDataGrid-main ': {
    borderRadius: '12px',
  },

  '& .MuiDataGrid-columnHeader--moving': {
    background: `${primaryColors.primary50} !important`,
    padding: '0 16px',
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'normal',
    lineHeight: 'normal',
  },
  '& .MuiDataGrid-columnHeaders ': {
    fontSize: '12px',
    fontWeight: 500,
    color: primaryColors.primary,
    outline: 'none !important',
    background: primaryColors.primary50,
    lineHeight: '16px !important',
    letterSpacing: '0.5px',
    minHeight: '48px !important',
    maxHeight: '48px !important',
    height: '48px',
  },

  '& .MuiDataGrid-columnHeader ': {
    padding: '0 16px',
  },

  '& .MuiDataGrid-cellContent': {
    whiteSpace: 'normal',
    wordSpacing: '5px',
    margin: '8px 0',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },

  '& .MuiDataGrid-columnHeader:focus ': {
    outline: 'none',
  },

  '.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
  '.MuiDataGrid-cell': {
    outline: 'none !important',
  },
  '.MuiDataGrid-virtualScroller ': {
    maxHeight: `${maxHeight}px`,
    minHeight: `${minHeight}px`,
    background: '#ffffff',
  },
}));

const ListContainer = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  border: `1px solid ${primaryColors.primary300}`,
  paddingBottom: '5px',
}));

export const TableHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '12px 12px 0 0',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${primaryColors.primary300}`,
  padding: '16px',
  background: '#ffffff',
  flexWrap: 'wrap',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '16px',
  },
}));

export const HeaderRightSide = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}));

const PClientTable = ({
  columns,
  loading,
  rows = [],
  defaultPageSize = 10,
  noRowsMessageKey = 'table:noDataFound',
  minHeight = '300',
  maxHeight = 'auto',
  showPaging = true,
  headerTitle = '',
  showHeader = true,
  dataLength = null,
  headerRightSide = null,
  noRowsOverlay = null,
  ...rest
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: defaultPageSize,
    page: 0,
  });

  useEffect(() => {
    setTotalCount(rows.length);
  }, [rows.length]);

  const handlePageChange = (_, newPage) => {
    setPaginationModel((prevState) => ({ ...prevState, page: newPage - 1 }));
  };

  const menuItems = useMemo(() => {
    return [
      { key: 10, value: 10 },
      { key: 20, value: 20 },
      { key: 50, value: 50 },
    ];
  }, []);

  const handleChange = (event) => {
    setPaginationModel((prevState) => ({ ...prevState, pageSize: event.target.value }));
  };

  return (
    <ListContainer>
      {showHeader && (
        <TableHeader>
          <HeaderRightSide>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {headerTitle}
            </Typography>
            {dataLength && (
              <PInfoChip
                fontSize="12px"
                label={dataLength}
              />
            )}
          </HeaderRightSide>
          {headerRightSide}
        </TableHeader>
      )}
      <CustomDataGrid
        sortingOrder={['desc', 'asc']}
        minHeight={minHeight}
        maxHeight={maxHeight}
        sortingMode="client"
        paginationMode="client"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={loading}
        rows={rows}
        columns={columns}
        checkboxSelection={false}
        disableRowSelectionOnClick
        hideFooter
        hideFooterPagination
        rowSelection={false}
        {...rest}
        slots={{
          noRowsOverlay: noRowsOverlay
            ? noRowsOverlay
            : () => <div className="d-flex align-items-center justify-content-center w100 h100">{t(noRowsMessageKey)}</div>,
        }}
      />
      {showPaging && (
        <PPagination
          handlePageSizeChange={handleChange}
          page={paginationModel.page + 1}
          totalCount={totalCount}
          pageSize={paginationModel.pageSize}
          handlePageChange={handlePageChange}
          menuItems={menuItems}
        />
      )}
    </ListContainer>
  );
};

export default React.memo(PClientTable);
