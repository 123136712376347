import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, COMMON_API } from 'constants/Keys';

const CONTENT = '/page-content';

export const getPageContentByTitle = (title) => httpRequest.get(`${COMMON_API}${API}${CONTENT}/${title}`);

export const createPageContent = ({ content, contentEn, title }) =>
  httpRequest.post(`${COMMON_API}${API}${CONTENT}`, {
    content,
    contentEn,
    title,
  });

export const updatePageContent = ({ content, contentEn, title, id }) =>
  httpRequest.put(`${COMMON_API}${API}${CONTENT}`, {
    id,
    content,
    contentEn,
    title,
  });
