import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import ApproveModal from 'components/approveModal/ApproveModal';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteUser } from './api/UserManagementApi';
const TableMenu = ({ row, loaderFunction }) => {
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    navigate(`${paths.userManagement}${paths.addUserManagement}/${row.id}`);
    handleClose();
  };

  const onDelete = () => {
    deleteUser(row.id)
      .then((res) => {
        toast.success(res.data.message);
        loaderFunction();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setOpenModal(false);
        handleClose();
      });
  };

  return (
    <>
      <PIconButton
        inputtype="text"
        onClick={handleClick}>
        <IconResolver iconName="MoreVert" />
      </PIconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {(!row.isAdmin || currentUser.user.isAdmin) && (
          <MenuItem onClick={onEdit}>
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <Typography variant="labelLarge">{t('userManagement:edit')}</Typography>
          </MenuItem>
        )}
        {currentUser.user.isAdmin && !row.isAdmin && (
          <MenuItem onClick={() => setOpenModal(true)}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon sx={{ color: redColors.red600 }} />
            </ListItemIcon>
            <Typography variant="labelLarge">{t('userManagement:delete')}</Typography>
          </MenuItem>
        )}
      </Menu>
      {openModal && (
        <ApproveModal
          onClose={() => setOpenModal(false)}
          bodyText={t('userManagement:sureToDeleteUser')}
          approveClick={onDelete}
          cancelClick={() => {
            setOpenModal(false);
          }}
          approveTextKey="userManagement:yes"
          cancelTextKey="userManagement:cancel"
        />
      )}
    </>
  );
};

export default TableMenu;
