import { CssBaseline, ThemeProvider } from '@mui/material';
import ContractAgreement from 'components/contractAgreement/ContractAgreement';
import { customTheme } from 'config/theme/CustomTheme';
import { aboutUsRoutes } from 'pages/aboutUs/routes/AboutUsRoutes';
import { accountRelatedPersonsRoutes } from 'pages/accountRelatedPersons/routes/AccountRelatedPersonsRoutes';
import { staticTemplateRoutes } from 'pages/addStaticTemplate/routes/StaticTemplateRoutes';
import { addUserRoutes } from 'pages/addUser/routes/AddUserRoutes';
import { approveUpdateRoutes } from 'pages/approveUpdate/routes/ApproveUpdateRoutes';
import { changePasswordRoutes } from 'pages/changePassword/routes/ChangePasswordRoutes';
import { configMasterRoutes } from 'pages/configMaster/routes/ConfigMasterRoutes';
import { contactRoutes } from 'pages/contact/routes/ContactRoutes';
import { deliveryAddressRoutes } from 'pages/deliveryAddress/routes/DeliveryAddressRoutes';
import { specialPagesRoutes } from 'pages/forbidden/routes/SpecialPagesRoutes';
import { generalManagerRoutes } from 'pages/generalManager/routes/GeneralManagerRoutes';
import { landingPageRoutes } from 'pages/landing/routes/LandingPageRoutes';
import { loginRoutes } from 'pages/login/routes/LoginRoutes';
import { missionVisionRoutes } from 'pages/missionVision/routes/MissionVisionRoutes';
import { myContractsListRoutes } from 'pages/myContracts/myContractsList/routes/MyContractsListRoutes';
import { payRoutes } from 'pages/pay/routes/PayRoutes';
import { pentaIntegrationRoutes } from 'pages/pentaIntegration/routes/PentaIntegrationRoutes';
import { pentaStorageRoutes } from 'pages/pentaStorage/routes/PentaStorageRoutes';
import { policiesRoutes } from 'pages/policies/routes/PoliciesRoutes';
import { resetPasswordRoutes } from 'pages/resetPassword/routes/ResetPasswordRoutes';
import { userManagementRoutes } from 'pages/userManagement/routes/UserManagementRoutes';
import { useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const isContractAgreementOpen = useSelector((state) => state.contractAgreement?.contractAgreement?.isOpen);

  const router = createBrowserRouter([
    ...loginRoutes,
    aboutUsRoutes,
    contactRoutes,
    staticTemplateRoutes,
    policiesRoutes,
    generalManagerRoutes,
    missionVisionRoutes,
    userManagementRoutes,
    changePasswordRoutes,
    landingPageRoutes,
    myContractsListRoutes,
    ...pentaStorageRoutes,
    ...resetPasswordRoutes,
    ...addUserRoutes,
    ...approveUpdateRoutes,
    ...deliveryAddressRoutes,
    ...accountRelatedPersonsRoutes,
    ...specialPagesRoutes,
    ...configMasterRoutes,
    ...payRoutes,
    ...pentaIntegrationRoutes,
  ]);

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <ToastContainer />
      {isContractAgreementOpen && <ContractAgreement />}
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
