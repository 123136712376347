import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography, styled } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import PTooltip from 'components/tooltip/PTooltip';
import UnitInput from 'components/unitInput/UnitInput';
import { blueColors, primaryColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';

const DescriptionContainer = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
}));

const Description = styled(Box)(() => ({
  padding: '20px 32px',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '70px',
}));

const CustomTypography = styled(Typography)(() => ({
  color: primaryColors.primary800,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.4000000059604645px',
  '&>a': {
    color: blueColors.blue500,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4000000059604645px',
  },

  '&>*>a': {
    color: blueColors.blue500,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4000000059604645px',
  },
}));

const CalculatorDescription = ({ descriptionValues }) => {
  const { t, i18n } = useTranslation();
  return (
    <DescriptionContainer>
      <Description>
        <div className="d-flex flex-column">
          <Typography variant="titleMedium">{t('calculator:storage')}</Typography>
          <div className="d-flex justify-content-between">
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('calculator:storageCost')}
            </Typography>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.desiCost}
                unit={descriptionValues.currency}
              />
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="titleMedium">{t('calculator:transport')}</Typography>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' }}>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}
              sx={{ gridColumnStart: 2 }}>
              {t('calculator:sameLocation')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}
              sx={{ gridColumnStart: 3 }}>
              {t('calculator:differentLocation')}
            </Typography>
            <Typography variant="bodyMedium">{t('calculator:cargo')}</Typography>
            <Typography
              variant="titleMedium"
              className="d-flex align-items-start gap-4"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.sameLocationCargoFee}
                unit={descriptionValues.currency}
              />
              <PTooltip
                placement="bottom-end"
                title={descriptionValues.sameLocationCargoInfo[i18n.language]}>
                <InfoOutlinedIcon
                  sx={{ color: blueColors.blue600 }}
                  fontSize="small"
                />
              </PTooltip>
            </Typography>
            <Typography
              variant="titleMedium"
              className="d-flex align-items-start gap-4"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.differentLocationShipment}
                unit={descriptionValues.currency}
              />
              <PTooltip
                placement="bottom-end"
                title={descriptionValues.differentLocationCargoInfo[i18n.language]}>
                <InfoOutlinedIcon
                  sx={{ color: blueColors.blue600 }}
                  fontSize="small"
                />
              </PTooltip>
            </Typography>
            <Typography ariant="bodyMedium">{t('calculator:shipment')}</Typography>
            <Typography
              variant="titleMedium"
              className="d-flex align-items-start gap-4"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.shippingCost}
                unit={descriptionValues.currency}
              />
              <PTooltip
                placement="bottom-end"
                title={descriptionValues.sameLocationShipmentInfo[i18n.language]}>
                <InfoOutlinedIcon
                  sx={{ color: blueColors.blue600 }}
                  fontSize="small"
                />
              </PTooltip>
            </Typography>
            <Typography
              variant="titleMedium"
              className="d-flex align-items-start gap-4"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.sameLocationShipment}
                unit={descriptionValues.currency}
              />
              <PTooltip
                placement="bottom-end"
                title={descriptionValues.differentLocationShipmentInfo[i18n.language]}>
                <InfoOutlinedIcon
                  sx={{ color: blueColors.blue600 }}
                  fontSize="small"
                />
              </PTooltip>
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="titleMedium">{t('calculator:total')}</Typography>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' }}>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}
              sx={{ gridColumnStart: 2 }}>
              {t('calculator:sameLocation')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}
              sx={{ gridColumnStart: 3 }}>
              {t('calculator:differentLocation')}
            </Typography>
            <Typography variant="bodyMedium">{t('calculator:cargo')}</Typography>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.totalSameLocationCargoFee}
                unit={descriptionValues.currency}
              />
            </Typography>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.totalDifferentLocationCargoFee}
                unit={descriptionValues.currency}
              />
            </Typography>
            <Typography ariant="bodyMedium">{t('calculator:shipment')}</Typography>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.totalSameLocationShippingFee}
                unit={descriptionValues.currency}
              />
            </Typography>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary800}>
              <UnitInput
                value={descriptionValues.totalDifferentLocationShippingFee}
                unit={descriptionValues.currency}
              />
            </Typography>
          </div>
        </div>
      </Description>
      <PDivider sx={{ marginBottom: 0 }} />
      <Description>
        <div className="d-flex flex-column gap-12">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {t('calculator:storage')}
          </Typography>
          <PDivider sx={{ marginBottom: 0 }} />
          {descriptionValues.storageField[i18n.language].map((item) => (
            <>
              <CustomTypography
                variant="bodySmall"
                dangerouslySetInnerHTML={{ __html: item }}
              />
              <PDivider sx={{ marginBottom: 0 }} />
            </>
          ))}
        </div>
        <div className="d-flex flex-column gap-12">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {t('calculator:transport')}
          </Typography>
          <PDivider sx={{ marginBottom: 0 }} />
          {descriptionValues.shipmentsField[i18n.language].map((item) => (
            <>
              <CustomTypography
                variant="bodySmall"
                dangerouslySetInnerHTML={{ __html: item }}
              />
              <PDivider sx={{ marginBottom: 0 }} />
            </>
          ))}
        </div>
        <div className="d-flex flex-column gap-12">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {t('calculator:total')}
          </Typography>
          <PDivider sx={{ marginBottom: 0 }} />
          {descriptionValues.totalField[i18n.language].map((item) => (
            <>
              <CustomTypography
                variant="bodySmall"
                dangerouslySetInnerHTML={{ __html: item }}
              />
              <PDivider sx={{ marginBottom: 0 }} />
            </>
          ))}
        </div>
      </Description>
    </DescriptionContainer>
  );
};

export default CalculatorDescription;
