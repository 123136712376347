import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PDatePicker from 'components/datePicker/PDatePicker';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PClientTable from 'components/table/PClientTable';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLoadedExcel } from '../api/DeliveryAddressApi';

const ActionContianer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'flex-end',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&>*': {
      flex: 1,
      width: '100%',
    },
  },
}));

const DeliveryAddressExcelCheck = () => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ allData: [], visibleData: [] });
  const [guid, setGuid] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [dateInterval, setDateInterval] = useState({
    startDate: dayjs().subtract(7, 'days'),
    endDate: dayjs(),
  });

  useEffect(() => {
    const searchKey = searchParams.get('query-message') || '';
    if (searchKey) {
      const sendData = {
        startDate: dateInterval.startDate ? dayjs(dateInterval.startDate).format('YYYY-MM-DD') : null,
        endDate: dateInterval.endDate ? dayjs(dateInterval.endDate).format('YYYY-MM-DD') : null,
        guid: searchKey,
      };
      setGuid(searchKey);
      search(sendData);
    }
  }, []);

  useEffect(() => {
    setIsLoading(false);
    setData({ allData: [], visibleData: [] });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'guid',
        headerName: t('deliveryAddress:queueNumber'),
        minWidth: 100,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'date',
        headerName: t('deliveryAddress:date'),
        width: 120,
        disableColumnMenu: true,
      },
      {
        field: 'email',
        headerName: t('deliveryAddress:emailTable'),
        minWidth: 224,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'address1',
        headerName: t('deliveryAddress:address'),
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'message',
        headerName: t('deliveryAddress:message'),
        minWidth: 140,
        flex: 1,
        disableColumnMenu: true,
      },
    ];
  }, [t]);

  const search = ({ startDate, endDate, guid }) => {
    const sendData = {
      startDate: startDate,
      endDate: endDate,
      guid: guid,
    };
    setIsLoading(true);

    getLoadedExcel(sendData)
      .then((res) => {
        const array = [];
        res.data.data.address.forEach((item, index) => {
          item.id = index;
          array.push(item);
        });

        setData({ allData: array, visibleData: array });
      })
      .catch((error) => {
        toast.error(error.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onListClickHandler = () => {
    const sendData = {
      startDate: dateInterval.startDate ? dayjs(dateInterval.startDate).format('YYYY-MM-DD') : null,
      endDate: dateInterval.endDate ? dayjs(dateInterval.endDate).format('YYYY-MM-DD') : null,
      guid: guid ? guid : null,
    };

    search(sendData);
  };

  const onTableSearch = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({ guid: e.guid, branchCode: e.branchCode, date: e.date, email: e.email, address1: e.address1, message: e.message })
          .join('')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <SecondaryLayout
        path={`${paths.customer}${paths.myDeliveryAdresses}`}
        title={t('deliveryAddress:viewBulkUploadedAddresses')}
        size="lg"
        borderColor="transparent"
        backgroundColor="transparent">
        <Box className="d-flex gap-16 flex-column">
          <ActionContianer>
            <Box>
              <PDatePicker
                showHelperContainer={false}
                label={t('deliveryAddress:selectStartDate')}
                sx={{ width: 'auto' }}
                value={dateInterval.startDate}
                onChange={(value) => {
                  setDateInterval((prevState) => ({ ...prevState, startDate: value }));
                }}
              />
            </Box>
            <Box>
              <PDatePicker
                showHelperContainer={false}
                sx={{ width: 'auto' }}
                label={t('deliveryAddress:selectEndDate')}
                value={dateInterval.endDate}
                onChange={(value) => {
                  setDateInterval((prevState) => ({ ...prevState, endDate: value }));
                }}
              />
            </Box>
            <Box>
              <TableSearchTextField
                showLabel
                value={guid}
                onChange={(event) => setGuid(event.target.value)}
                placeholder={t('deliveryAddress:searchForQueueNumber')}
              />
            </Box>
            <Box>
              <PButton onClick={onListClickHandler}>{t('deliveryAddress:list')}</PButton>
            </Box>
          </ActionContianer>
          <Box sx={{ width: '100%' }}>
            <PClientTable
              headerRightSide={
                <Box>
                  <TableSearchTextField
                    width={matchesSm ? '100%' : '300px'}
                    value={searchValue}
                    onChange={onTableSearch}
                    placeholder={t('deliveryAddress:searchWithinTheTable')}
                  />
                </Box>
              }
              headerTitle={t('deliveryAddress:addedFromBulkAddressFile')}
              dataLength={`${data.allData.length} ${t('deliveryAddress:piece')}`}
              rows={data.visibleData}
              columns={columns}
              getRowHeight={() => 'auto'}
            />
          </Box>
        </Box>
      </SecondaryLayout>
    </>
  );
};
export default DeliveryAddressExcelCheck;
