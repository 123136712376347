import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import ApproveModal from 'components/approveModal/ApproveModal';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { paths } from 'constants/Paths';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteRelatedPerson } from '../api/AcountRelatedPersonsApi';

const PersonListMenu = ({ row, loaderFunction }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    navigate(`${paths.add}${paths.accounting}${paths.accountRelatedPersons}`, { state: { update: row } });
    handleClose();
  };

  const onDelete = () => {
    setOpenModal(false);
    handleClose();
    deleteRelatedPerson(row.relatedPersonNo)
      .then((res) => {
        toast.success(res.data.message);
        loaderFunction();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setOpenModal(false);
        handleClose();
      });
  };

  return (
    <>
      <PIconButton
        inputtype="text"
        onClick={handleClick}>
        <IconResolver iconName="MoreVert" />
      </PIconButton>
      <Menu
        id="personList-menu"
        aria-labelledby="personList-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <Typography variant="labelLarge">{t('accountRelatedPersons:edit')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setOpenModal(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <Typography variant="labelLarge">{t('accountRelatedPersons:delete')}</Typography>
        </MenuItem>
      </Menu>
      {openModal && (
        <ApproveModal
          onClose={() => setOpenModal(false)}
          bodyText={
            <Trans
              values={{ name: row.name, lastName: row.lastName }}
              i18nKey="accountRelatedPersons:sureToDeletePerson"
            />
          }
          // bodyText={t('accountRelatedPersons:sureToDeletePerson', { name: row.name, lastName: row.lastName })}
          approveClick={onDelete}
          cancelClick={() => {
            setOpenModal(false);
          }}
          approveTextKey="accountRelatedPersons:delete"
          cancelTextKey="accountRelatedPersons:cancel"
        />
      )}
    </>
  );
};
export default PersonListMenu;
