import { LOCAL_STORAGE_KEY } from 'constants/Keys';
import { jwtDecode } from 'jwt-decode';

export const removeGivenNumberFromArray = (array, number) => {
  const removedNumberArray = array.filter((x) => x !== number);
  return removedNumberArray;
};

export const validEmail = (email) => {
  const regex = /^((?!\.)[\w\-_.]*[^.])(@[\w-]+)(\.\w+(\.\w+)?[^.\W])$/;

  return regex.test(email);
};

export const isTokenValid = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (token) {
    const { exp } = jwtDecode(token);
    const expirationTime = exp * 1000;
    if (Date.now() >= expirationTime) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const numberToDecimal = (number, fixedNumber = 2) => {
  const numberToFix = number.toFixed(fixedNumber);
  let parts = numberToFix.toString().split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? ',' + parts[1] : '';
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return integerPart + decimalPart;
};

export const splitWithDot = (number, fixedNumber = 3) => {
  const numberToFix = number.toFixed(fixedNumber);
  let parts = numberToFix.toString().split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return integerPart + decimalPart;
};

export const addZeroToNumber = (number) => {
  if (number >= 10) {
    return number.toString();
  } else {
    return `0${number}`;
  }
};

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const formatPrice = (price) => {
  const roundedPrice = Math.round(price * 100) / 100;

  const formattedPrice = roundedPrice
    .toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return formattedPrice;
};
