import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import SelectableTags from 'components/selectableTags/SelectableTags';
import PClientTable from 'components/table/PClientTable';
import { paths } from 'constants/Paths';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRelatedPersons } from '../api/AcountRelatedPersonsApi';
import { contractPersonTypeEnum } from '../common/Enums';
import PersonListMenu from './PersonListMenu';

const PersonList = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedTag, setSelectedTag] = useState(location?.state?.selectedKey || contractPersonTypeEnum.storageDelivery);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState({ allData: [], visibleData: [] });
  const navigate = useNavigate();
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);

  const handleAddPerson = () => {
    navigate(`${paths.add}${paths.accounting}${paths.accountRelatedPersons}`, {
      state: { selectedTag: selectedTag },
    });
  };

  const getTableDatas = (tableDeterminant) => {
    setIsLoading(true);
    getRelatedPersons(tableDeterminant)
      .then((res) => {
        const tableDataCandidate = [];
        res.data.data.contracts.forEach((item, index) => {
          item.id = index;
          item.contractPersonType = tableDeterminant;
          tableDataCandidate.push(item);
        });
        setData({ allData: tableDataCandidate, visibleData: tableDataCandidate });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTableDatas(selectedTag);
  }, []);

  const TagObject = useMemo(() => {
    return [
      {
        value: contractPersonTypeEnum.storageDelivery,
        title: 'accountRelatedPersons:storageDelivery',
      },
      {
        value: contractPersonTypeEnum.eArchive,
        title: 'accountRelatedPersons:eArchive',
      },
      {
        value: contractPersonTypeEnum.finance,
        title: 'accountRelatedPersons:finance',
      },
      {
        value: contractPersonTypeEnum.technicalService,
        title: 'accountRelatedPersons:technicalService',
      },
    ];
  }, []);

  const tableTitle = useMemo(() => {
    if (selectedTag === contractPersonTypeEnum.storageDelivery) {
      return `${t('accountRelatedPersons:storageDelivery')} ${t('accountRelatedPersons:personList')}`;
    } else if (selectedTag === contractPersonTypeEnum.eArchive) {
      return `${t('accountRelatedPersons:eArchive')} ${t('accountRelatedPersons:personList')}`;
    } else if (selectedTag === contractPersonTypeEnum.finance) {
      return `${t('accountRelatedPersons:finance')} ${t('accountRelatedPersons:personList')}`;
    } else if (selectedTag === contractPersonTypeEnum.technicalService) {
      return `${t('accountRelatedPersons:technicalService')} ${t('accountRelatedPersons:personList')}`;
    }
  }, [selectedTag, t]);

  const columns = useMemo(() => {
    const dynamicColumns = [
      {
        field: 'name',
        headerName: t('accountRelatedPersons:name'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'lastName',
        headerName: t('accountRelatedPersons:surname'),
        minWidth: 250,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'phone',
        headerName: t('accountRelatedPersons:phoneNumber'),
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
      },
    ];
    if (selectedTag !== contractPersonTypeEnum.storageDelivery) {
      dynamicColumns.push({
        field: 'mail',
        headerName: t('accountRelatedPersons:email'),
        minWidth: 300,
        flex: 1,
        disableColumnMenu: true,
      });
    }
    if (userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud')) {
      dynamicColumns.push({
        field: 'operations',
        headerName: '',
        width: 72,
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        renderCell: ({ row }) => {
          return (
            <PersonListMenu
              row={row}
              loaderFunction={() => getTableDatas(selectedTag)}
            />
          );
        },
      });
    }

    return dynamicColumns;
  }, [t, selectedTag, userPermissions]);

  const tagSelectHandler = (index) => {
    setSelectedTag(index);
    getTableDatas(index);
    setSearchValue('');
  };

  const onSearchHandler = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({ name: e.name, lastName: e.lastName, email: e.email, phone: e.phone })
          .join('')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };

  return (
    <Box className="d-flex flex-column gap-16">
      {isLoading && <LoadingSpinner />}
      <Box className="d-flex gap-4 flex-wrap">
        {TagObject.map((tag) => {
          return (
            <SelectableTags
              value={tag.value}
              key={tag.value}
              selected={tag.value === selectedTag}
              onClick={() => tagSelectHandler(tag.value)}
              title={t(tag.title)}
            />
          );
        })}
      </Box>
      <PClientTable
        headerRightSide={
          <Box className="d-flex gap-12 justify-flex-end">
            <TableSearchTextField
              value={searchValue}
              onChange={onSearchHandler}
              placeholder={t('accountRelatedPersons:searchInTable')}
            />
            {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
              <PButton
                width="auto"
                startIcon={<AddIcon />}
                onClick={handleAddPerson}>
                {t('accountRelatedPersons:addNewPerson')}
              </PButton>
            )}
          </Box>
        }
        dataLength={`${data.allData.length} ${t('accountRelatedPersons:person')}`}
        headerTitle={tableTitle}
        columns={columns}
        rows={data.visibleData}
      />
    </Box>
  );
};
export default PersonList;
