import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import PaymentLayout from 'layout/paymentLayout/PaymentLayout';
import { createOrder, simulateBeforeOrder } from 'pages/configMaster/api/ConfigMasterApi';
import { configMasterOrderValidationMessagesKeys } from 'pages/configMaster/i18n/ConfigMasterOrderValidationMessagesKeys';
import OrderDetailMain from 'pages/configMaster/orderDetail/OrderDetailMain';
import OrderDetailPayment from 'pages/configMaster/orderDetail/OrderDetailPayment';
import OrderDetailSummary from 'pages/configMaster/orderDetail/OrderDetailSummary';
import { deliveryTypeEnum } from 'pages/pentaStorage/constants/DeliveryTypeEnum';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Validator from 'validatorjs';

const OrderDetail = ({ showOrderDetail, setShowOrderDetail }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicTelInput, setDynamicTelInput] = useState([]);
  const [disableButtons, setDisableButtons] = useState(false);
  const [formData, setFormData] = useState({
    receiveSms: false,
    selectedRadio: 'createOrder',
    selectedPayment: null,
    shipmentType: '',
    storage: '',
    orderDescription: '',
    invoiceDescription: '',
    trackNumber: '',
    shippingDate: '',
  });
  const [shippingDateData, setShippingDateData] = useState([]);
  const [ownStorageData, setOwnStorageData] = useState([]);
  const [errors, setErrors] = useState({
    additionalInfo: '',
    shipmentType: '',
    storage: '',
  });
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [incomingData, setIncomingData] = useState(null);
  const [userHasStoragePermission, setUserHasStoragePermission] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    callSimulate({ initialCall: true, useShowOrder: true });
  }, []);

  const callSimulate = ({ initialCall = false, useShowOrder = false, value = '', setDefaultValue = false, receiver = null }) => {
    setIsLoading(true);

    if (initialCall && useShowOrder) {
      simulateBeforeOrder(
        showOrderDetail.activeBasket.maturityPeriod,
        showOrderDetail.activeBasket.maturityPeriodDescription,
        showOrderDetail.activeBasket.configIds,
        showOrderDetail.basketItems,
        showOrderDetail.customerDefaultCurrency,
        showOrderDetail.shippingType[0].key,
      )
        .then((res) => {
          setIncomingData({ ...showOrderDetail, ...res.data.data });
          setUserHasStoragePermission(res.data.data.userHasPentaProductPermission);
          setShippingTimeAndDefaultValue({ shipTimeData: res.data.data.shipTime, defaultShippingTime: res.data.data.defaultShippingTime });
          setDeliveryOptionsAndDefaultValue({
            deliveryOptions: res.data.data.deliveryOptions,
            defaultDeliveryOptions: res.data.data.defaultDeliveryOption,
          });
          setStorageDataAndDefaultValue({ storageData: res.data.data.ownStorages, defaultStorageData: res.data.data.defaultStorage });
        })
        .catch((err) => {
          setIncomingData({ ...showOrderDetail, ...err.response.data?.data });
          setDisableButtons(true);
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
          setFormData((prevState) => ({ ...prevState, shipmentType: showOrderDetail.shippingType[0].key }));
        });
    }
    if (!useShowOrder && value) {
      simulateBeforeOrder(
        incomingData.activeBasket.maturityPeriod,
        incomingData.activeBasket.maturityPeriodDescription,
        incomingData.activeBasket.configIds,
        incomingData.basketItems,
        incomingData.customerDefaultCurrency,
        value,
        receiver,
      )
        .then((res) => {
          setIncomingData((prevState) => ({ ...prevState, ...res.data.data }));
          setShippingTimeAndDefaultValue({ shipTimeData: res.data.data.shipTime || [], defaultShippingTime: res.data.data.defaultShippingTime });
          if (setDefaultValue) {
            setDeliveryOptionsAndDefaultValue({
              deliveryOptions: res.data.data.deliveryOptions || [],
              defaultDeliveryOptions: res.data.data.defaultDeliveryOption,
            });
            setStorageDataAndDefaultValue({ storageData: res.data.data.ownStorages, defaultStorageData: res.data.data.defaultStorage });
          }
          setFormData((prevState) => ({ ...prevState, shipmentType: res.data.data.vsart }));
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
          setErrors((prevState) => ({ ...prevState, shipmentType: '' }));
        });
    }
  };

  const setShippingTimeAndDefaultValue = ({ shipTimeData = [], defaultShippingTime = null }) => {
    const shipTimeArray = shipTimeData?.map((item) => ({
      key: uuidv4(),
      value: `${dayjs(item.date).format('DD.MM.YYYY')} ${item.hour.slice(0, -3)} - ${item.description}`,
      rest: item,
    }));
    setShippingDateData(shipTimeArray);
    if (defaultShippingTime) {
      const found = shipTimeArray.find(
        (item) =>
          item.value ===
          `${dayjs(defaultShippingTime.date).format('DD.MM.YYYY')} ${defaultShippingTime.hour.slice(0, -3)} - ${defaultShippingTime.description}`,
      );
      setFormData((prevState) => ({ ...prevState, shippingDate: found.key }));
    } else {
      setFormData((prevState) => ({ ...prevState, shippingDate: '' }));
    }
  };

  const setDeliveryOptionsAndDefaultValue = ({ deliveryOptions = [], defaultDeliveryOptions = null }) => {
    if (defaultDeliveryOptions) {
      const found = deliveryOptions.find((item) => item.kunnr === defaultDeliveryOptions.kunnr);
      setAdditionalInfo(found.kunnr);
    } else {
      setAdditionalInfo('');
    }
  };

  const setStorageDataAndDefaultValue = ({ storageData = [], defaultStorageData = null }) => {
    const deliveryOptionArray = storageData.map((item) => ({
      key: item.storageCode,
      value: item.description,
      rest: item,
    }));
    setOwnStorageData(deliveryOptionArray);
    if (defaultStorageData) {
      const found = deliveryOptionArray.find((item) => item.key === defaultStorageData.storageCode);
      setFormData((prevState) => ({ ...prevState, storage: found.key }));
    } else {
      setFormData((prevState) => ({ ...prevState, storage: '' }));
    }
  };

  const shipmentTypeData = useMemo(() => {
    if (incomingData?.shippingType) {
      return incomingData.shippingType?.map((item) => ({
        key: item.key,
        value: item.value,
        rest: item,
      }));
    } else {
      return [];
    }
  }, [t, incomingData?.shippingType]);

  const backToConfigMasterBasket = () => {
    setShowOrderDetail(false);
  };

  const onRadioChange = (event) => {
    const { name } = event.target;
    setFormData((prevState) => ({ ...prevState, selectedRadio: name }));
  };

  const handleAddTelInput = () => {
    if (dynamicTelInput.length < 4) {
      setDynamicTelInput([...dynamicTelInput, { phone: '' }]);
    }
  };

  const onChangeReceiveSms = (event) => {
    const { checked, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: checked }));
    setDynamicTelInput([]);
  };

  const handleDynamicTelChange = (value, index) => {
    const onChangeValue = [...dynamicTelInput];
    onChangeValue[index]['phone'] = value;
    setDynamicTelInput(onChangeValue);
  };

  const handleDynamicTelDelete = (index) => {
    const deleteValue = [...dynamicTelInput];
    deleteValue.splice(index, 1);
    setDynamicTelInput(deleteValue);
  };

  const changePaymentType = (paymentType) => {
    setFormData((prevState) => ({ ...prevState, selectedPayment: paymentType }));
  };

  const onShipmentTypeChangeHandler = (event) => {
    const { value } = event.target;
    callSimulate({ value, setDefaultValue: true });
  };

  const onStorageChangeHandler = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({ ...prevState, storage: value }));
    setErrors((prevState) => ({ ...prevState, storage: '' }));
  };

  const handleChange = (event) => {
    setAdditionalInfo(event.target.value);
    if (formData.shipmentType !== deliveryTypeEnum.pickUpFromStorage) {
      callSimulate({ value: formData.shipmentType, receiver: event.target.value });
    }
    setErrors((prevState) => ({ ...prevState, additionalInfo: '' }));
  };

  const onAddPersonHandler = () => {
    callSimulate({ value: deliveryTypeEnum.pickUpFromStorage });
  };

  const handleCreateOrder = () => {
    const rules = {};
    if (formData.selectedRadio === 'createOrder') {
      rules.additionalInfo = 'required';
      rules.shipmentType = 'required';
    } else {
      rules.storage = 'required';
    }
    const validationData = { ...formData, additionalInfo };
    const validation = new Validator(validationData, rules, configMasterOrderValidationMessagesKeys);
    if (validation.passes()) {
      const phones = [];
      phones.push(currentUser.user.phone);
      dynamicTelInput.forEach((item) => phones.push(item.phone.replace(/\s/g, '').substring(1)));
      const sendData = {
        maturity: incomingData.maturity,
        maturityText: incomingData.maturityText,
        shippingType: formData.shipmentType,
        customerDefaultCurrency: incomingData.customerDefaultCurrency,
        orderDescription: formData.orderDescription,
        invoiceDescription: formData.invoiceDescription,
        trackingNumber: formData.trackNumber,
        UseMyStorage: formData.selectedRadio === 'sendToStorage' ? true : false,
        WaybillDescription: '',
        smsNotification: formData.receiveSms,
        phoneNumbersForSmsNotification: phones,
        paymentType: formData.selectedPayment,
        configIds: incomingData.activeBasket.configIds,
        products: incomingData.basketItems,
        cartId: incomingData.activeBasket.id,
      };
      if (formData.selectedRadio === 'sendToStorage') {
        const found = ownStorageData.find((item) => item.key === formData.storage);
        sendData.ownStorage = found.rest;
      }
      if (formData.shipmentType === deliveryTypeEnum.pickUpFromStorage) {
        sendData.contract = incomingData.contracts.find((item) => item.number === additionalInfo);
      } else {
        const found = shippingDateData.find((item) => item.key === formData.shippingDate);
        sendData.shippingTypeChoise = found?.rest;
        sendData.deliveryOption = incomingData.deliveryOptions.find((item) => item.kunnr === additionalInfo);
      }
      setIsLoading(true);
      createOrder(sendData)
        .then((res) => {
          const successOrErrorData = res?.data?.data;
          if (successOrErrorData) {
            if (!successOrErrorData.anyErrorExists && !successOrErrorData.redirect) {
              navigate(`${paths.wizard}${paths.orderSuccess}`, { state: { ...successOrErrorData } });
            }
          } else {
            window.location.href = res.request.responseURL;
          }
        })
        .catch((err) => {
          const successOrErrorData = err.response.data.data;
          if (successOrErrorData.anyErrorExists && !successOrErrorData.redirect) {
            navigate(`${paths.wizard}${paths.orderFail}`, { state: { ...successOrErrorData } });
          }
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrors((prevState) => ({
        ...prevState,
        additionalInfo: validation.errors.first('additionalInfo'),
        shipmentType: validation.errors.first('shipmentType'),
        storage: validation.errors.first('storage'),
      }));
    }
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onShippingDateChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({ ...prevState, shippingDate: value }));
  };

  return (
    <>
      <PaymentLayout
        onClick={backToConfigMasterBasket}
        mainTitle={t('configMaster:orderDetails')}
        leftTitle={t('configMaster:shipmentAndInformation')}
        leftSubtitle={t('configMaster:chooseShippingOptionsAndNotificationMethods')}
        leftPart={
          <OrderDetailMain
            formData={formData}
            onRadioChange={onRadioChange}
            userHasStoragePermission={userHasStoragePermission}
            ownStorageData={ownStorageData}
            onStorageChangeHandler={onStorageChangeHandler}
            errors={errors}
            shipmentTypeData={shipmentTypeData}
            onShipmentTypeChangeHandler={onShipmentTypeChangeHandler}
            incomingData={incomingData}
            additionalInfo={additionalInfo}
            handleChange={handleChange}
            onAddPersonHandler={onAddPersonHandler}
            shippingDateData={shippingDateData}
            onShippingDateChange={onShippingDateChange}
            inputChangeHandler={inputChangeHandler}
            onChangeReceiveSms={onChangeReceiveSms}
            handleAddTelInput={handleAddTelInput}
            dynamicTelInput={dynamicTelInput}
            handleDynamicTelChange={handleDynamicTelChange}
            handleDynamicTelDelete={handleDynamicTelDelete}
          />
        }
        rightPart={
          <OrderDetailSummary
            showOrderDetail={showOrderDetail}
            incomingData={incomingData}
            formData={formData}
            handleCreateOrder={handleCreateOrder}
          />
        }
        summaryTitle={t('configMaster:orderSummary')}
        count={showOrderDetail.products.length}
        countLabel={t('configMaster:product')}
        paymentPart={
          <OrderDetailPayment
            disableButtons={disableButtons}
            formData={formData}
            changePaymentType={changePaymentType}
            incomingData={incomingData}
          />
        }
        paymentTitle={t('configMaster:paymentOptions')}
        paymentSubtitle={t('configMaster:setPaymentInformationAndMethods')}
      />
      {isLoading && <LoadingSpinner />}
    </>
  );
};
export default OrderDetail;
