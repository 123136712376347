import SimpleTable from 'components/simpleTable/SimpleTable';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubSubscriptionColumns from './SubSubscriptionColumns';
import SubSubscriptionRows from './SubSubscriptionRows';

const SubSubcriptionList = ({ rows, loaderFunction, setIsLoading }) => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const columns = useMemo(() => {
    return [
      { id: 'id', headerName: '', minWidth: '48px' },
      {
        id: 'interlocutorRole',
        headerName: t('accountRelatedPersons:type'),
        minWidth: '160px',
        sortable: true,
      },
      {
        id: 'firstName',
        headerName: t('accountRelatedPersons:name'),
        sortable: true,
        minWidth: '140px',
      },
      {
        id: 'lastName',
        headerName: t('accountRelatedPersons:surname'),
        sortable: true,
        minWidth: '210px',
      },
      {
        id: 'phone',
        headerName: t('accountRelatedPersons:phone'),
        sortable: true,
        minWidth: '124px',
      },
      {
        id: 'email',
        headerName: t('accountRelatedPersons:email'),
        sortable: true,
        minWidth: '153px',
      },
      {
        id: 'deletionFlag',
        headerName: t('accountRelatedPersons:status'),
        sortable: true,
        minWidth: '100px',
      },
      {
        id: 'actions',
        headerName: '',
        width: '84px',
      },
    ];
  }, [t]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
      return b[orderBy].localeCompare(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const visibleRows = useMemo(() => stableSort(rows, getComparator(order, orderBy)), [order, orderBy, rows]);

  return (
    <SimpleTable
      showBorder={false}
      showHeader={false}
      showFooter={false}
      columns={
        <SubSubscriptionColumns
          order={order}
          orderBy={orderBy}
          columns={columns}
          onRequestSort={handleRequestSort}
        />
      }
      rows={visibleRows.map((row) => (
        <SubSubscriptionRows
          key={row.id}
          setIsLoading={setIsLoading}
          row={row}
          loaderFunction={loaderFunction}
        />
      ))}
    />
  );
};
export default SubSubcriptionList;
