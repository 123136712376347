export const ProductsInStorageTr = {
  filter: 'Filtrele',
  detailFilter: 'Detaylı Filtreleme',
  excelPrintout: 'Excel Çıktısı Al',
  averageStockAge: 'Ortalama Stok Yaşı',
  totalNumber: 'Toplam Adet',
  differentNumberOfProducts: 'Farklı Ürün Adedi',
  totalCost: 'Toplam Tutar',
  piece: 'Adet',
  day: 'Gün',
  allProducts: 'Tüm Ürünler',
  orderNumber: 'Sipariş Numarası',
  productNumber: 'Ürün No',
  itemNumber: 'Ürün Numarası',
  storageNumber: 'Depo Numarası',
  searchWord: 'Aranacak Kelime',
  orderNo: 'Sipariş No',
  entryNo: 'Kalem No',
  storage: 'Depo',
  itemName: 'Ürün Adı',
  unitPrice: 'Birim Fiyatı',
  stockAge: 'Stok Yaşı',
  serialNo: 'Seri No',
  serialNumbersOfProduction: ' {{number}} Numaralı Ürünün Seri Numaraları',
  orderNoWithNumber: '{{number}} Numaralı Sipariş',
  productNoWithNumber: '{{number}} Numaralı Ürün',
  warehouseNoWithNumber: '{{number}} Numaralı Depo',
  listItems: 'Ürünleri listele',
  capitalizeDetailFilter: 'Detaylı filtre',
  clearAll: 'Tümünü temizle',
  category: 'Kategori',
  brand: 'Marka',
  storagePlace: 'Depo Yeri',
  cancel: 'İptal',
  apply: 'Uygula',
  addProductNoToCart: '{{number}} No’lu Ürünü Depo Sepetine Ekle',
  selectTheSerialNumberOfTheProduct: 'Sepete eklemek istediğim ürünlerin seri numaralarını seçmek istiyorum',
  addToCart: 'Sepete Ekle',
  searchWithSerialNo: 'Seri No Arayın...',
  selectedSerialNoCannotExeed: 'Seçilen seri no sayısı ürün sayısını geçemez',
  youCannotDeleteAPreviouslyAddedSerialNumber: 'Önceden eklenen bir seri numarasını silemezsiniz',
  alreadyInCart: 'Sepette Olanlar',
  additableSerialNo: 'Eklenebilir Seri Numaralar',
  allProductsInStockAreAlreadyInYourCart: 'Stoktaki bütün ürünler zaten sepetinizdedir.',
  piecesInStock: 'Stokta {{number}} adet',
  viewBulkOrders: 'Toplu ürün ekleyebilir ve görüntüleyebilirsiniz',
  bulkOrders: 'Toplu Eklenen Ürünler',
  bulkOrderList: 'Toplu Eklenen Ürün Listesi',
  selectStartDate: 'Başlangıç tarihi seçin',
  selectEndDate: 'Bitiş tarihi seçin',
  searchForQueueNumber: 'Kuyruk numarası ...',
  list: 'Listele',
  doSearch: 'Arama Yap...',
  createBulkOrder: 'Toplu Ürün Ekle',
  bulkOrderFile: 'Toplu Ürün Dosyası',
  quicklyCreateBulkOrdersFromFile: 'Dosyadan hızlıca toplu ürün ekleyin.',
  createOrders: 'Ürün Ekle',
  referenceCustomerOrderNo: 'Referans Müşteri Sipariş No.',
  manufacturerPartNo: 'Üretici Parça No',
  message: 'Mesaj',
  incorrectOrderLines: 'Hatalı Sipariş Satırları',
  deleteErrors: 'Hatalı Olanları Sil',
  queueEntryNo: 'Kuyruk Kalem No',
  material: 'Malzeme',
  sdCertificate: 'Proje Sipariş No',
  orderItemNo: 'Sipariş Kalem No',
  excelSuccess: 'Dosya başarıyla yüklendi.',
  excelError: 'Dosya yüklenirken bir sorun oluştu. Lütfen tekrar deneyin',
  onSuccessFileUpload: '<CustomLink>{{number}}</CustomLink> kuyruk numarası ile eklediğiniz ürünleri görüntüleyebilirsiniz.',
  productsWithQueueNumbers: 'Kuyruk Numaralı Ürünler',
};
