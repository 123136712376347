import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, styled } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import PChip from 'components/chip/PChip';
import PTextField from 'components/textField/PTextField';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { removeGivenNumberFromArray } from 'utils/HelperFunctions';
const DetailFilterContainer = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px',
  width: '330px',
  height: '500px',
  overflowY: 'auto',
}));

const DetailFilterHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Summary = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
}));

const DetailFilter = ({ tableData, detailFiterData, showFilterCount, applyFilter, onClose }) => {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState({ storagePlace: [], brand: [], category: [], serialNo: '' });
  const [storagePlaces, setStoragePlaces] = useState([]);
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const copyOfDetailFilter = structuredClone(detailFiterData);
    setFilterData(copyOfDetailFilter);
  }, []);

  useEffect(() => {
    const copyOfTableData = structuredClone(tableData);
    const localStoragePlaces = copyOfTableData.map((item) => item.storagePlace);
    const localBrands = copyOfTableData.map((item) => item.productBrand);
    const localCategory = copyOfTableData.map((item) => item.category);
    setCategory([...new Set(localCategory)]);
    setBrands([...new Set(localBrands)]);
    setStoragePlaces([...new Set(localStoragePlaces)]);
  }, []);
  const onCheckboxChange = (event, type, value) => {
    if (type === 'storagePlace') {
      if (event.target.checked) {
        const localStoragePlace = filterData.storagePlace;
        localStoragePlace.push(value);

        setFilterData((prevState) => ({ ...prevState, storagePlace: localStoragePlace }));
      } else {
        handleStoragePlaceDelete(value);
      }
    }
    if (type === 'brand') {
      if (event.target.checked) {
        const localBrand = filterData.brand;
        localBrand.push(value);

        setFilterData((prevState) => ({ ...prevState, brand: localBrand }));
      } else {
        handleBrandsDelete(value);
      }
    }
    if (type === 'category') {
      if (event.target.checked) {
        const localCategory = filterData.category;
        localCategory.push(value);

        setFilterData((prevState) => ({ ...prevState, category: localCategory }));
      } else {
        handleCategoryDelete(value);
      }
    }
  };

  const handleStoragePlaceDelete = (value) => {
    const localStoragePlace = filterData.storagePlace;
    const removedArray = removeGivenNumberFromArray(localStoragePlace, value);
    setFilterData((prevState) => ({ ...prevState, storagePlace: removedArray }));
  };

  const handleBrandsDelete = (value) => {
    const localBrands = filterData.brand;
    const removedArray = removeGivenNumberFromArray(localBrands, value);
    setFilterData((prevState) => ({ ...prevState, brand: removedArray }));
  };

  const handleCategoryDelete = (value) => {
    const localCategory = filterData.category;
    const removedArray = removeGivenNumberFromArray(localCategory, value);
    setFilterData((prevState) => ({ ...prevState, category: removedArray }));
  };

  const resetAllFilters = () => {
    setFilterData({ storagePlace: [], brand: [], category: [], serialNo: '' });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setFilterData((prevState) => ({ ...prevState, serialNo: value }));
  };

  const apply = () => {
    const copyOfFilterData = structuredClone(filterData);
    applyFilter(copyOfFilterData);
    onClose();
  };

  return (
    <DetailFilterContainer>
      <DetailFilterHeader>
        <Typography
          variant="titleLarge"
          color={neutralColors.neutral950}>
          {t('productsInStorage:capitalizeDetailFilter')}
        </Typography>
        <Typography
          className="cursor-pointer"
          onClick={resetAllFilters}
          variant="labelLarge"
          color={primaryColors.primary}>
          {t('productsInStorage:clearAll')} ({showFilterCount()})
        </Typography>
      </DetailFilterHeader>
      <br />
      <PTextField
        onChange={handleInputChange}
        value={filterData.serialNo}
        sx={{ marginBottom: '7px' }}
        placeholder={t('productsInStorage:serialNo')}
      />
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="category-content"
          id="category-header">
          <Typography
            variant="titleSmall"
            color={primaryColors.primary}>
            {t('productsInStorage:category')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {category.map((category) => {
            return (
              <div key={category}>
                <PCheckbox
                  label={category}
                  id={category}
                  checked={filterData.category.includes(category)}
                  onChange={(event) => onCheckboxChange(event, 'category', category)}
                />
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="brand-content"
          id="brand-header">
          <Typography
            variant="titleSmall"
            color={primaryColors.primary}>
            {t('productsInStorage:brand')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {brands.map((brand) => {
            return (
              <div key={brand}>
                <PCheckbox
                  label={brand}
                  id={brand}
                  checked={filterData.brand.includes(brand)}
                  onChange={(event) => onCheckboxChange(event, 'brand', brand)}
                />
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="storageLocation-content"
          id="storageLocation-header">
          <Typography
            variant="titleSmall"
            color={primaryColors.primary}>
            {t('productsInStorage:storagePlace')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {storagePlaces.map((storagePlace) => {
            return (
              <div key={storagePlace}>
                <PCheckbox
                  label={storagePlace}
                  id={storagePlace}
                  checked={filterData.storagePlace.includes(storagePlace)}
                  onChange={(event) => onCheckboxChange(event, 'storagePlace', storagePlace)}
                />
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Summary>
        <br />
        {filterData.category.length > 0 && (
          <div>
            <Typography
              variant="titleSmall"
              color="#000000">
              {t('productsInStorage:category')}
            </Typography>
            <Grid
              container
              spacing={2}>
              {filterData.category.map((item) => (
                <Grid
                  item
                  xs={5}
                  key={item}>
                  <PChip
                    label={item}
                    onDelete={() => handleCategoryDelete(item)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {filterData.brand.length > 0 && (
          <div>
            <Typography
              variant="titleSmall"
              color="#000000">
              {t('productsInStorage:brand')}
            </Typography>
            <Grid
              container
              spacing={2}>
              {filterData.brand.map((item) => (
                <Grid
                  item
                  xs={5}
                  key={item}>
                  <PChip
                    label={item}
                    onDelete={() => handleBrandsDelete(item)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {filterData.storagePlace.length > 0 && (
          <div>
            <Typography
              variant="titleSmall"
              color="#000000">
              {t('productsInStorage:storagePlace')}
            </Typography>
            <Grid
              container
              spacing={2}>
              {filterData.storagePlace.map((item) => (
                <Grid
                  item
                  xs={5}
                  key={item}>
                  <PChip
                    label={item}
                    onDelete={() => handleStoragePlaceDelete(item)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </Summary>
      <br />
      <PDivider />
      <Footer>
        <PButton
          sx={{ width: '78px' }}
          inputtype="outline"
          onClick={onClose}>
          {t('productsInStorage:cancel')}
        </PButton>
        <PButton
          sx={{ width: '78px' }}
          onClick={apply}>
          {t('productsInStorage:apply')}
        </PButton>
      </Footer>
    </DetailFilterContainer>
  );
};

export default DetailFilter;
