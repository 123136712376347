/* eslint-disable max-len */
export const DeliveryDetailsTr = {
  deliveryDetails: 'Teslimat Detayları',
  shipmentAndInformation: 'Sevkiyat ve Bilgilendirme',
  chooseShippingOptionsAndNotificationMethods: 'Sevkiyat seçeneklerini ve bilgilendirme yöntemlerini belirleyin',
  M4: 'Penta Sevkiyat',
  M2: 'Depodan Teslim',
  M1: 'Kargo',
  shipmentType: 'Sevkiyat Türü',
  enterDescription: 'Açıklama Giriniz',
  checkboxInfo: 'Sipariş sonrasında SMS ile bilgilendirilmek istiyorum. (Ürün çıkışında ve tesliminde.)',
  numberToSendSms: 'SMS Atılacak Numara',
  transportationFee: 'Taşıma Bedeli',
  coveredAmount: 'Karşılanan Taşıma Bedeli',
  preparationFee: 'Hazırlanma Bedeli',
  createDelivery: 'Teslimat Oluştur',
  deliveryOptions: 'Teslimat Seçenekleri',
  selectDeliveryAddress: 'Teslimat adresi seçiniz',
  enterDeliveryDescription: 'Teslimat açıklaması giriniz',
  selectRelevantPeople: 'İlgili kişileri seçiniz',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  orderNo: 'Sipariş Numarası',
  deliveryCreatedSuccessfully: 'Teslimat başarıyla oluşturuldu!',
  youCanViewTheDeliveryDetailsInTheTableBelow: 'Teslimat detaylarını aşağıdaki tablodan görüntüleyebilirsiniz.',
  deliveryNumber: 'Teslimat No',
  storage: 'Depo',
  address: 'Adres',
  cargo: 'Kargo',
  deliveryItems: 'Teslimat Kalemleri',
  piece: 'Adet',
  orderCode: 'Sipariş Kodu',
  entryNo: 'Kalem No',
  stockCode: 'Stok Kodu',
  itemName: 'Ürün Adı',
  description: 'Açıklama',
  amount: 'Miktar',
  deliveryCouldNotBeCreated: 'Teslimat oluşturulamadı!',
  addDeliveryAddress: 'Teslimat Adresi Ekle',
  addAddressDescription: 'Teslimat detaylarında kullanmak ve teslimat adreslerim listenize kaydetmek için yeni teslimat adresi oluşturun.',
  companyName: 'Firma Adı',
  city: 'Şehir',
  mobile: 'Mobil',
  district: 'İlçe',
  phone: 'Telefon',
  doorNo: 'Kapı No',
  optional: 'Opsiyonel',
  fax: 'Faks',
  postCode: 'Posta Kodu',
  email: 'E-posta',
  saveAddress: 'Adresi Kaydet',
  addressSavedSuccessfully: 'Adres başarıyla kaydedildi',
  addPersonDescription: 'Teslimat detaylarında kullanmak ve ilgili kişilerinize kaydetmek için yeni kişi oluşturun.',
  personSavedSuccessfully: 'Kişi başarıyla kaydedildi ',
  save: 'Kaydet',
  infoMessage:
    'Yukarıda belirtilen hizmet ve taşıma bedelleri için şu an bir ödeme veya faturalama yapılmayacaktır. Dönem sonunda kümülatif olarak hesaplanarak sizlere fatura edilecektir.',
};
