import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, STORAGE_API } from 'constants/Keys';

const STORAGE = '/storage';
const CART = '/cart';
const REMOVE_ITEM_FROM_CART = '/remove-item-from-cart';
const ALL_CARTS = '/all-carts';
const SET_CART_AS_CURRENT = '/set-cart-as-current';
const PRODUCT_COUNT_IN_CART = '/product-count-in-cart';
const UPDATE_CART = '/update-cart';
const UPDATE_CART_NAME = '/update-cart-name';
const CREATE_DELIVERY = '/create-delivery';
const ADD_DELIVERY_ADDRESS = '/add-delivery-address';
const ADD_CONTRACT_PERSON = '/add-contract-person';
const CREATE_DEFAULT_CART = '/create-default-cart';

export const getCartProducts = () => httpRequest.get(`${STORAGE_API}${API}${STORAGE}${CART}`);

export const deleteSelectedItem = (id) => httpRequest.delete(`${STORAGE_API}${API}${STORAGE}${CART}${REMOVE_ITEM_FROM_CART}?productId=${id}`);

export const getAllBaskets = () => httpRequest.get(`${STORAGE_API}${API}${STORAGE}${CART}${ALL_CARTS}`);

export const setCartAsCurrent = (id) =>
  httpRequest.put(`${STORAGE_API}${API}${STORAGE}${CART}${SET_CART_AS_CURRENT}`, {
    id,
  });

export const deleteCartOption = (id) =>
  httpRequest.delete(`${STORAGE_API}${API}${STORAGE}${CART}?cartId=${id}`, {
    id,
  });

export const createCart = (cartName) =>
  httpRequest.post(`${STORAGE_API}${API}${STORAGE}${CART}`, {
    cartName,
  });

export const getStorageItemCount = () => httpRequest.get(`${STORAGE_API}${API}${STORAGE}${CART}${PRODUCT_COUNT_IN_CART}`);

export const simulateUpdateCart = (simulateItem) => httpRequest.put(`${STORAGE_API}${API}${STORAGE}${CART}${UPDATE_CART}`, simulateItem);

export const updateCartName = (id, name) => httpRequest.put(`${STORAGE_API}${API}${STORAGE}${CART}${UPDATE_CART_NAME}`, { id, name });

export const createDelivery = (data) =>
  httpRequest.post(`${STORAGE_API}${API}${STORAGE}${CART}${CREATE_DELIVERY}`, {
    ...data,
  });

export const createAddressFromCart = ({ city, district, address, doorNumber, postCode, phone, mobile, fax, email, name, storagePlace }) =>
  httpRequest.post(`${STORAGE_API}${API}${STORAGE}${CART}${ADD_DELIVERY_ADDRESS}`, {
    city,
    district,
    address,
    doorNumber,
    postCode,
    phone,
    mobile,
    fax,
    email,
    name,
    storagePlace,
  });

export const createPersonFromCart = ({
  firstName,
  lastName,
  phoneNumber,
  emailAddress,
  isUpdate,
  contractPersonType,
  invoiceTransmissionType,
  storagePlace,
}) =>
  httpRequest.post(`${STORAGE_API}${API}${STORAGE}${CART}${ADD_CONTRACT_PERSON}`, {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    isUpdate,
    contractPersonType,
    invoiceTransmissionType,
    storagePlace,
  });

export const controlForDefaultStorageCart = () => httpRequest.get(`${STORAGE_API}${API}${STORAGE}${CREATE_DEFAULT_CART}`);
