export const CalculatorEn = {
  productToBeAddedToTheCalculation: 'Product to be Added to the Calculation',
  piece: 'Piece',
  daysToStore: 'Days to Store',
  add: 'Add',
  calculateByIncludingProductsFromPentaStorage: 'Calculate by including products from penta storage',
  calculate: 'Calculate',
  productsAddedToTheCalculation: 'Products Added to the Calculation',
  stockCode: 'Stock Code',
  itemName: 'Item Name',
  numberOfDaysToStore: 'Number of Days to Store',
  storage: 'Storage',
  storageCost: 'Storage Cost',
  transport: 'Transport',
  cargo: 'Cargo',
  shipment: 'Shipment',
  total: 'Total',
  sameLocation: 'Same Location',
  differentLocation: 'Different Location',
  thisFieldIsRequired: 'Please enter {{field}}.',
  theProductYouWantToAddIsAvailableInTheTable: 'The product you want to add is available in the table!',
};
