import { useTheme } from '@emotion/react';
import { Box, styled, useMediaQuery } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import WarningAlert from 'components/alert/WarningAlert';
import PErrorButton from 'components/button/PErrorButton';
import POkButton from 'components/button/POkButton';
import PRemindLaterButton from 'components/button/PRemindLaterButton';
import PSuccessButton from 'components/button/PSuccessButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import { getSingleContract } from 'pages/myContracts/api/MyContractsApi';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { buttonActionRequest } from './api/ContractAgreementApi';
import { buttonTypeEnum } from './constants/Constants';
import { resetContracts } from './reducer/ContractAgreementReducer';

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
  },
}));

const ContractAgreement = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const contractIds = useSelector((state) => state.contractAgreement?.contractAgreement?.ids);
  const [contractData, setContractData] = useState({
    contractId: '',
    description: '',
    contractInformation: '',
    canApprovalStatusChangeable: false,
    file: '',
    fileName: '',
    contractFileType: '',
    buttons: [],
  });

  useEffect(() => {
    setIsLoading(true);
    getSingleContract(contractIds[0])
      .then((res) => {
        setContractData(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        dispatch(resetContracts());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [contractIds]);

  const showButtons = useCallback(() => {
    const buttonsActions = (buttonId) => {
      setIsLoading(true);
      buttonActionRequest(buttonId, contractData.contractId)
        .then((res) => {
          toast.success(res.data.message);
          dispatch(resetContracts());
          window.location.reload();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    return (
      <ButtonContainer>
        {contractData.buttons.map((item) => {
          if (item.buttonType === 1) {
            return (
              <PSuccessButton
                key={item.buttonId}
                width="auto"
                onClick={() => buttonsActions(item.buttonId)}>
                {t(`contractAgreement:${buttonTypeEnum[item.buttonType]}`)}
              </PSuccessButton>
            );
          } else if (item.buttonType === 2) {
            return (
              <PErrorButton
                key={item.buttonId}
                width="auto"
                onClick={() => buttonsActions(item.buttonId)}>
                {t(`contractAgreement:${buttonTypeEnum[item.buttonType]}`)}
              </PErrorButton>
            );
          } else if (item.buttonType === 3) {
            return (
              <POkButton
                key={item.buttonId}
                width="auto"
                onClick={() => buttonsActions(item.buttonId)}>
                {t(`contractAgreement:${buttonTypeEnum[item.buttonType]}`)}
              </POkButton>
            );
          } else {
            return (
              <PRemindLaterButton
                key={item.buttonId}
                width="auto"
                onClick={() => buttonsActions(item.buttonId)}>
                {t(`contractAgreement:${buttonTypeEnum[item.buttonType]}`)}
              </PRemindLaterButton>
            );
          }
        })}
      </ButtonContainer>
    );
  }, [contractData.buttons]);

  const getDynamicWidth = () => {
    if (matchesSm) {
      return '300px';
    } else if (matchesMd) {
      return '500px';
    } else {
      return '70%';
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <PCenterModal
          width={getDynamicWidth()}
          title={contractData.contractInformation}
          subTitle={contractData.description}>
          <Box className="d-flex flex-column gap-24">
            <Box>
              <embed
                style={{ width: '100%', minHeight: '500px', height: '100%' }}
                src={`data:application/pdf;base64,${contractData.file}`}
              />
            </Box>
            <Box>
              {contractData.canApprovalStatusChangeable && <InfoAlert label={t('contractAgreement:canChange')} />}
              {!contractData.canApprovalStatusChangeable && <WarningAlert label={t('contractAgreement:cantChange')} />}
            </Box>
            <Box className="d-flex justify-flex-end ">{showButtons()}</Box>
          </Box>
        </PCenterModal>
      )}
    </>
  );
};

export default ContractAgreement;
