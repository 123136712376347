import { Grid, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PPasswordTextField from 'components/textField/PPasswordTextField';
import { redColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import LoginContainer from 'layout/loginLayout/container/LoginContainer';
import { checkTokenValid, resetForgettenPassword } from 'pages/login/api/LoginApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetForgottenPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [errorMessage, setErrorMessage] = useState('');
  const [formField, setFormField] = useState({
    password: '',
    passwordRepeat: '',
    token: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    checkTokenValid(params.token)
      .then((res) => {
        const token = res.data.data.token;
        setFormField((prevState) => ({ ...prevState, token }));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        navigate(paths.home);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    setFormField((prevState) => ({ ...prevState, [name]: value }));
  };

  const onResetHandler = () => {
    if (!formField.password || !formField.passwordRepeat) {
      setErrorMessage('login:pleaseEnterYourPassword');
    } else if (formField.password !== formField.passwordRepeat) {
      setErrorMessage(t('resetPassword:thePasswordsYouEnteredDonotMatchEachOther'));
    } else {
      localStorage.clear();
      setErrorMessage('');
      const data = {
        token: params.token,
        password: formField.password,
        password2: formField.passwordRepeat,
      };

      const headers = { Authorization: `Bearer ${formField.token}` };
      setLoading(true);
      resetForgettenPassword(data, headers)
        .then((res) => {
          toast.success(res.data.message);
          navigate(paths.home);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <LoginContainer maxWidth="xs">
      {loading && <LoadingSpinner />}
      <Grid
        container
        spacing={2}
        sx={{ paddingRight: 2, paddingBottom: 2, backgroundColor: '#ffffff' }}>
        <Grid
          item
          xs={12}>
          <Typography
            variant="headlineSmall"
            sx={{ color: slateColors.slate950 }}>
            {t('resetPassword:resetYourPassword')}
          </Typography>
          <br />
          <Typography
            variant="bodyMedium"
            sx={{ color: slateColors.slate600 }}>
            {t('resetPassword:enterNewPasswordDescription')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}>
          <PPasswordTextField
            value={formField.password}
            label={t('resetPassword:newPassword')}
            name="password"
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <PPasswordTextField
            value={formField.passwordRepeat}
            label={t('resetPassword:repeatNewPassword')}
            name="passwordRepeat"
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <PButton onClick={onResetHandler}>{t('resetPassword:changePassword')}</PButton>
          {errorMessage && (
            <Typography
              variant="labelLarge"
              sx={{ color: redColors.red600 }}>
              {t(errorMessage)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </LoginContainer>
  );
};
export default ResetForgottenPassword;
