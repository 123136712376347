import { Box, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import { cancelSubscription } from 'pages/pay/api/PayApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const PayCancelModal = ({ onClose, row, getCartCount }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const onCancelHandler = () => {
    const { subscriptionNumber } = row;
    setLoading(true);
    cancelSubscription(subscriptionNumber)
      .then((res) => {
        getCartCount();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <PCenterModal
      showClose
      width="442px"
      onClose={onClose}
      title={t('pay:itemContractTermination')}>
      {loading && <LoadingSpinner />}
      <Box className="d-flex flex-column gap-12">
        <Box>
          <Typography variant="bodyMedium">{t('pay:theContractWillBeTerminatedForThisItem')}</Typography>
        </Box>
        <Box className="d-flex justify-flex-end gap-8">
          <PButton
            inputtype="outline"
            width="auto"
            onClick={onClose}>
            {t('pay:cancelSinlgeWord')}
          </PButton>
          <PButton
            width="auto"
            onClick={onCancelHandler}>
            {t('pay:terminate')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};

export default PayCancelModal;
