import { Box, Link, styled, Typography } from '@mui/material';
import PAccordion from 'components/accordion/PAccordion';
import PAccordionDetail from 'components/accordion/PAccordionDetail';
import PAccordionSummary from 'components/accordion/PAccordionSummary';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { Image, ImageItem, Item } from 'pages/pentaStorage/storageIndex/StorageIndex';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import image6 from './media/deliveryDetail.jpg';
import image5 from './media/depodakiUrunlerim.jpg';
import image1 from './media/introduction.jpg';
import image2 from './media/normalFlow.jpg';
import image4 from './media/orderDetail.jpg';
import image3 from './media/pentaDepoFlow.jpg';

const AccordionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 24px',
  marginBottom: '24px',
}));

const Header = styled(Box)(() => ({
  padding: '0 8px',
}));

const AccordionPart = () => {
  const { t } = useTranslation();

  return (
    <AccordionContainer>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storage:howDoesPentaStorageProvideAnAdvantage')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:advantageFirstParagraph')}
            </Typography>
          </Item>
          <Item>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary800}>
              {t('storage:inTheNormalWorkflow')}
            </Typography>
          </Item>
          <ImageItem>
            <Image
              src={image2}
              alt="normal flow"
            />
          </ImageItem>
          <Item>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary800}>
              {t('storage:inThePentaStorageApplication')}
            </Typography>
          </Item>
          <ImageItem>
            <Image
              src={image3}
              alt="penta depom flow"
            />
          </ImageItem>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:advantageSecondParagraph')}
            </Typography>
          </Item>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storage:howCanIUsePentaStorage')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:howToUseFirstParagraph')}
            </Typography>
          </Item>
          <Item>
            <Link
              component={RouterLink}
              to={`${paths.accounting}${paths.myContracts}`}
              variant="bodyMedium">
              {t('storage:clickHereForTheAgreement')}
            </Link>
          </Item>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:howToUseSecondParagraph')}
            </Typography>
          </Item>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storage:isPentaStoragePaidService')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:isPaidServiceFirstParagraph')}
            </Typography>
          </Item>
          <Item>
            <Link
              component={RouterLink}
              to={`${paths.accounting}${paths.myContracts}`}
              variant="bodyMedium">
              {t('storage:clickHereForTheAgreement')}
            </Link>
          </Item>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storage:howCanIShipProductsToPentaStorageViaBayinet')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:shipPentaStorageViaBayinetFirstParagraph')}
            </Typography>
            <ImageItem margin={0}>
              <Image
                showborder={false}
                src={image4}
                alt="detail"
              />
            </ImageItem>
          </Item>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storage:howCanIViewMyProductsInPentaStorage')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:viewMyProductsFirstParagraph')}
            </Typography>
          </Item>
          <ImageItem>
            <Image
              width={'100%'}
              src={image5}
              alt="products in storage"
            />
          </ImageItem>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storage:howCnIShipMyProductsToAnotherAddressUsingBayinetFromPentaStorage')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:shipMyProductToAnotherAddressFirstParagraph')}
            </Typography>
          </Item>
          <ImageItem>
            <Image
              showborder={false}
              src={image6}
              alt="delivery detail"
            />
          </ImageItem>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:shipMyProductToAnotherAddressSecondParagraph')}
            </Typography>
          </Item>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storage:howCanIViewMyCostsIncurredDuringThePeriodInPentaStorage')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:costIncurredFirstParagraph')}
            </Typography>
          </Item>
          <ImageItem>
            <Image
              width={'100%'}
              src={image1}
              alt="storage cost"
            />
          </ImageItem>
        </PAccordionDetail>
      </PAccordion>
    </AccordionContainer>
  );
};

export default AccordionPart;
