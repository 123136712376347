import { styled } from '@mui/material';
import { greenColors } from 'config/theme/Colors';
import PChip from './PChip';

const CustomChip = styled(PChip)(({ fontSize }) => ({
  borderRadius: '24px',
  padding: '2px, 12px',
  height: '24px',
  color: greenColors.green700,
  backgroundColor: greenColors.green100,
  border: `1px solid ${greenColors.green300}`,
  '& .MuiChip-label': {
    fontSize: fontSize,
    fontWeight: 500,
    lineHeight: '20px',
  },
}));

const PSuccessChip = ({ label, fontSize = '14px', variant = 'filled', ...rest }) => {
  return (
    <CustomChip
      variant={variant}
      label={label}
      fontSize={fontSize}
      {...rest}
    />
  );
};

export default PSuccessChip;
