import { InputLabel, ListSubheader, MenuItem, Select, Typography, styled } from '@mui/material';
import { CustomFormControl, HelperContainer, HelperText } from 'components/textField/PTextField';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { useEffect, useRef, useState } from 'react';

export const CustomSelect = styled(Select)(({ sx }) => ({
  margin: '0 !important',
  color: primaryColors.primary,
  backgroundColor: '#ffffff',
  borderRadius: 8,
  minHeight: '40px',
  height: 'auto',
  border: `1px solid ${primaryColors.primary400} !important`,
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&:before': {
    border: 0,
  },
  '&:after': {
    border: 0,
    padding: 0,
  },
  '&:hover:before': {
    border: '0 !important',
  },
  '.MuiInputBase-input': {
    paddingLeft: '10px !important',
    backgroundColor: 'transparent !important',
  },
  '.MuiSelect-select': {
    padding: 0,
  },
  '.MuiFilledInput-input:focus': {
    background: 'transparent !important',
  },
  '& .Mui-disabled': {
    opacity: 0.48,
  },
  ...sx,
}));

const CustomListSubheader = styled(ListSubheader)(() => ({
  fontSize: '14px',
  color: neutralColors.neutral950,
  fontWeight: 500,
}));

const CustomMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  textWrap: 'wrap',
  overflowWrap: 'anywhere',
  '&.Mui-selected': {
    backgroundColor: `${primaryColors.primary100} !important`,
  },
  '&:hover': {
    backgroundColor: primaryColors.primary300,
  },
}));

export const CustomSelectLabel = styled(InputLabel)(({ visibility }) => ({
  color: primaryColors.primary,
  fontSize: '14px',
  fontWeight: 500,
  position: 'relative',
  transform: 'none',
  height: '23px',
  visibility: visibility ? 'visible' : 'hidden',
  '&.Mui-focused': {
    color: `${primaryColors.primary} !important`,
  },
}));

const PSelect = ({
  label = '',
  value,
  id = 'test-id-2',
  onChange,
  menuItems,
  labelId = 'test-id',
  placeholder = '',
  errorMessage,
  error = false,
  withGroup = false,
  width = '100%',
  showLabel = true,
  showHelperContainer = true,
  ...rest
}) => {
  const selectRef = useRef();
  const [menuWidth, setMenuWidth] = useState('auto');

  useEffect(() => {
    if (selectRef.current) {
      setMenuWidth(`${selectRef.current.offsetWidth}px`);
    }
  }, [selectRef]);

  const renderMenu = () => {
    if (!withGroup) {
      return menuItems.map((item) => {
        return (
          <CustomMenuItem
            key={item.key}
            value={item.key}>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {item.value}
            </Typography>
          </CustomMenuItem>
        );
      });
    } else {
      return menuItems.reduce(
        (listItems, element) => [
          ...listItems,
          <CustomListSubheader
            key={element.category}
            disabled>
            {element.category}
          </CustomListSubheader>,
          ...element.children.map((child) => (
            <CustomMenuItem
              key={`${element.category}-${child.key}`}
              value={child.key}>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {child.value}
              </Typography>
            </CustomMenuItem>
          )),
        ],
        [],
      );
    }
  };

  return (
    <CustomFormControl width={width}>
      {showLabel && (
        <CustomSelectLabel
          visibility={label}
          id={id}>
          {label}
        </CustomSelectLabel>
      )}
      <CustomSelect
        ref={selectRef}
        displayEmpty
        renderValue={
          value !== ''
            ? undefined
            : () => (
                <Typography
                  variant="bodyLarge"
                  color={primaryColors.primary600}>
                  {placeholder}
                </Typography>
              )
        }
        labelId={labelId}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            style: {
              width: menuWidth,
              maxHeight: 250,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        {...rest}>
        {renderMenu()}
      </CustomSelect>
      {showHelperContainer && (
        <HelperContainer visibility={errorMessage && error}>
          <HelperText error>{errorMessage}</HelperText>
        </HelperContainer>
      )}
    </CustomFormControl>
  );
};

export default PSelect;
