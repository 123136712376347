export const MainAppBarTr = {
  searchSomething: 'Herhangi bir şey ara...',
  logout: 'Çıkış Yap',
  turkish: 'Türkçe',
  english: 'İngilizce',
  management: 'Yönetim',
  language: 'Dil',
  managementPanel: 'Yönetim Paneli',
  passwordOperations: 'Şifre İşlemleri',
  'user-management': 'Kullanıcı Yönetimi',
  services: 'Hizmetler',
  'penta-storage': 'Penta Depom',
  'delivery-addresses': 'Teslimat Adreslerim',
  myContracts: 'Sözleşmelerim',
  'config-master': 'Penta Config Master',
  multipleServices: 'Hizmetler',
  manageSubscriptionDetails: ' Abonelik detaylarını yönetin.',
  'penta-pay': 'Penta Abonelik Yönetimi',
  'penta-integration': 'Penta Entegrasyon',
};
