import { Box, Grid, styled } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';
import { checkEarchiveStatus, updateEarchiveStatus, upsertRelatedPerson } from '../api/AcountRelatedPersonsApi';
import { contractPersonTypeEnum } from '../common/Enums';
import { upsertRelatedPersonsValidationMessagesKeys } from './UpsertRelatedPersonsValidationMessagesKeys';

const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '20px',
  gap: '16px',
}));
const UpsertRelatedPersons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    contractPersonType: contractPersonTypeEnum.storageDelivery,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    relatedPersonNo: null,
    invoiceTransmissionType: false,
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
  });
  const [invoiceDb, setInvoiceDb] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (location?.state?.selectedTag) {
      setFormData((prevState) => ({ ...prevState, contractPersonType: location?.state?.selectedTag }));
    }
    if (location?.state?.update) {
      const updateValues = location?.state?.update;
      const phoneNumber = `+${updateValues.phone}`;
      setFormData((prevState) => ({
        ...prevState,
        firstName: updateValues.name,
        lastName: updateValues.lastName,
        phoneNumber,
        relatedPersonNo: updateValues.relatedPersonNo,
        emailAddress: updateValues.mail,
        contractPersonType: updateValues.contractPersonType,
      }));
    }
    checkEarchiveStatus()
      .then((res) => {
        setFormData((prevState) => ({
          ...prevState,
          invoiceTransmissionType: res.data.data.type,
        }));
        setInvoiceDb(res.data.data.type);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSelectChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onCheckboxChangeHandler = (event) => {
    const { checked, name } = event.target;
    setIsLoading(true);
    updateEarchiveStatus({ invoiceTransmissionType: checked ? 2 : 1 })
      .then(() => {
        setFormData((prevState) => ({ ...prevState, [name]: checked }));
        setInvoiceDb(checked);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const contractPersonTypeMenu = useMemo(() => {
    return [
      { key: contractPersonTypeEnum.storageDelivery, value: t('accountRelatedPersons:storageDelivery') },
      { key: contractPersonTypeEnum.eArchive, value: t('accountRelatedPersons:eArchive') },
      { key: contractPersonTypeEnum.finance, value: t('accountRelatedPersons:finance') },
      { key: contractPersonTypeEnum.technicalService, value: t('accountRelatedPersons:technicalService') },
    ];
  }, [t]);

  const validateForm = () => {
    const rules = {
      firstName: 'required',
      lastName: 'required',
      phoneNumber: 'required',
    };
    if (formData.contractPersonType !== contractPersonTypeEnum.storageDelivery) {
      rules.emailAddress = 'required|email';
    }
    let phoneNumber = formData.phoneNumber.substring(1);
    phoneNumber = phoneNumber.replace(/\s/g, '');
    const copyData = { ...formData, phoneNumber };
    const validation = new Validator(copyData, rules, upsertRelatedPersonsValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        firstName: validation.errors.first('firstName'),
        lastName: validation.errors.first('lastName'),
        phoneNumber: validation.errors.first('phoneNumber'),
        emailAddress: validation.errors.first('emailAddress'),
      }));
    }
  };

  const onUpsertHandler = () => {
    if (validateForm()) {
      let phoneNumber = formData.phoneNumber.substring(1);
      phoneNumber = phoneNumber.replace(/\s/g, '');
      let invoiceTransmissionType = invoiceDb ? 2 : 1;
      if (formData.contractPersonType === contractPersonTypeEnum.eArchive) {
        invoiceTransmissionType = formData.invoiceTransmissionType ? 2 : 1;
      }

      const sendData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber,
        emailAddress: formData.emailAddress,
        relatedPersonNumber: formData.relatedPersonNo?.toString(),
        isUpdate: location.state ? true : false,
        contractPersonType: formData.contractPersonType,
        invoiceTransmissionType: invoiceTransmissionType,
      };
      setIsLoading(true);
      upsertRelatedPerson(sendData)
        .then((res) => {
          toast.success(res.data.message);
          navigate(`${paths.accounting}${paths.accountRelatedPersons}`, {
            state: { selectedKey: formData.contractPersonType },
          });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <SecondaryLayout
        path={`${paths.accounting}${paths.accountRelatedPersons}`}
        title={t('accountRelatedPersons:addNewPerson')}>
        <ItemContainer>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}
              md={6}>
              <PSelect
                disabled={location?.state?.update}
                label={t('accountRelatedPersons:personCategory')}
                value={formData.contractPersonType}
                menuItems={contractPersonTypeMenu}
                name="contractPersonType"
                onChange={onSelectChangeHandler}
              />
            </Grid>
          </Grid>
          {formData.contractPersonType === contractPersonTypeEnum.eArchive && (
            <Grid
              container
              spacing={2}>
              <Grid
                item
                xs={12}
                md={12}>
                <PCheckbox
                  onChange={onCheckboxChangeHandler}
                  name="invoiceTransmissionType"
                  checked={formData.invoiceTransmissionType}
                  label={t('accountRelatedPersons:eArchiveCheckbox')}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={t('accountRelatedPersons:name')}
                value={formData.firstName}
                name="firstName"
                onChange={onChangeHandler}
                error={!!errors.firstName}
                errorMessage={t(errors.firstName, { field: t('accountRelatedPersons:name') })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <PTextField
                label={t('accountRelatedPersons:surname')}
                value={formData.lastName}
                name="lastName"
                onChange={onChangeHandler}
                error={!!errors.lastName}
                errorMessage={t(errors.lastName, { field: t('accountRelatedPersons:surname') })}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}
              md={6}>
              {!location?.state?.update && (
                <TelInput
                  label={`${t('accountRelatedPersons:phone')}`}
                  value={formData.phoneNumber}
                  onChange={(value) => setFormData((prevState) => ({ ...prevState, phoneNumber: value }))}
                  error={!!errors.phoneNumber}
                  errorMessage={t(errors.phoneNumber, { field: t('accountRelatedPersons:phone') })}
                />
              )}
              {location?.state?.update && (
                <PTextField
                  label={`${t('accountRelatedPersons:phone')}`}
                  value={formData.phoneNumber}
                  name="phoneNumber"
                  onChange={onChangeHandler}
                  error={!!errors.phoneNumber}
                  errorMessage={t(errors.phoneNumber, { field: t('accountRelatedPersons:phone') })}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              {formData.contractPersonType !== contractPersonTypeEnum.storageDelivery && (
                <PTextField
                  label={t('accountRelatedPersons:email')}
                  value={formData.emailAddress}
                  onChange={onChangeHandler}
                  name="emailAddress"
                  error={!!errors.emailAddress}
                  errorMessage={t(errors.emailAddress, { field: t('accountRelatedPersons:email') })}
                />
              )}
            </Grid>
          </Grid>
          {formData.contractPersonType === contractPersonTypeEnum.storageDelivery && (
            <InfoAlert label={<Trans i18nKey="accountRelatedPersons:infoText" />} />
          )}
          {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
            <Box className="d-flex justify-flex-end">
              <PButton
                width="auto"
                onClick={onUpsertHandler}>
                {t('accountRelatedPersons:savePerson')}
              </PButton>
            </Box>
          )}
        </ItemContainer>
      </SecondaryLayout>
    </>
  );
};
export default UpsertRelatedPersons;
