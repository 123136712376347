import { Box, styled } from '@mui/material';
import dayjs from 'dayjs';
import { urlProductByProducts, urlProductBySubscriptions } from 'pages/pay/api/PayApi';
import { payFilterSelectEnums } from 'pages/pay/constants/PayFilterSelectEnums';
import { payRefreshTypeEnums } from 'pages/pay/constants/PayRefreshTypeEnums';
import { payStatusEnums } from 'pages/pay/constants/PayStatusEnums';
import MainContent from 'pages/pay/tabs/components/MainContent';
import Sidebar from 'pages/pay/tabs/components/Sidebar';
import { useRef, useState } from 'react';

const PaySubscriptionContainerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PaySubscriptionContainer = ({ setTabValue, setProductInfo, setAdditionalInfoForTableMenu, detailTabNumber }) => {
  const [selectValue, setSelectValue] = useState(payFilterSelectEnums.subscription);
  const ref = useRef();
  const [url, setUrl] = useState('');
  const [searchValues, setSearchValues] = useState({
    startDate: dayjs(),
    endDate: dayjs().add(180, 'days'),
    refreshType: payRefreshTypeEnums.all,
    brand: '',
    endUser: '',
    subscriptionNo: '',
    contractNo: '',
    status: payStatusEnums.active,
  });
  const [showEmptyPage, setShowEmptyPage] = useState({ isWorkedOnce: false, show: true });
  const onSelectChangeHandler = (event) => {
    const { value } = event.target;
    setSelectValue(value);
    getProducts(value);
    if (value === payFilterSelectEnums.product) {
      setShowEmptyPage((prevState) => ({ ...prevState, show: false }));
    } else {
      if (!showEmptyPage.isWorkedOnce) {
        setShowEmptyPage((prevState) => ({ ...prevState, show: true }));
      }
    }
  };

  const getProducts = (value = null) => {
    const control = value ? value : selectValue;
    const baseUrl = control === payFilterSelectEnums.subscription ? urlProductBySubscriptions : urlProductByProducts;
    const localUrl = new URL(baseUrl);

    const { startDate, endDate, brand, endUser, subscriptionNo, contractNo, refreshType, status } = searchValues;
    if (!(subscriptionNo || contractNo || endUser)) {
      if (startDate) {
        localUrl.searchParams.append('startDate', dayjs(startDate).format('YYYY-MM-DD'));
      }
      if (endDate) {
        localUrl.searchParams.append('endDate', dayjs(endDate).format('YYYY-MM-DD'));
      }
    }

    const params = { brand, endUser, subscriptionNumber: subscriptionNo, contractNumber: contractNo };
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        localUrl.searchParams.append(key, value);
      }
    }

    if (control === payFilterSelectEnums.subscription) {
      localUrl.searchParams.append('renewalType', refreshType);
      if (status) {
        localUrl.searchParams.append('status', payStatusEnums.active);
      }
    }
    if (url === localUrl.toString()) {
      ref?.current?.refreshTable();
    }

    setUrl(localUrl.toString());
  };

  return (
    <PaySubscriptionContainerWrapper>
      <Sidebar
        selectValue={selectValue}
        onSelectChangeHandler={onSelectChangeHandler}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        setShowEmptyPage={setShowEmptyPage}
        getProducts={getProducts}
      />
      <MainContent
        selectValue={selectValue}
        showEmptyPage={showEmptyPage}
        url={url}
        setTabValue={setTabValue}
        setProductInfo={setProductInfo}
        setAdditionalInfoForTableMenu={setAdditionalInfoForTableMenu}
        detailTabNumber={detailTabNumber}
        tableRef={ref}
      />
    </PaySubscriptionContainerWrapper>
  );
};

export default PaySubscriptionContainer;
