import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import IconResolver from 'components/iconResolver/IconResolver';
import PTooltip from 'components/tooltip/PTooltip';
import { greenColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import { getSubDetails } from 'pages/pay/api/PayApi';
import PayProductionTable from 'pages/pay/components/PayProductionTable';
import { renewalTypeEnum } from 'pages/pay/constants/RenewalTypeEnum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ProductionTable = ({ url, setTabValue, setProductInfo, setAdditionalInfoForTableMenu, detailTabNumber, tableRef, setIsLoading }) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        field: 'productName',
        headerName: t('pay:product'),
        minWidth: 500,
        flex: 1,
        renderCell: (rows) => {
          return (
            <div className="d-flex flex-column">
              <PTooltip
                placement="bottom-end"
                title={`${rows.brand} - ${rows.productName}`}>
                <div className="ellipsis">{`${rows.brand} - ${rows.productName}`}</div>
              </PTooltip>
              <Box
                className="hyperlink-text fit-content"
                onClick={() => {
                  loginForBayinet()
                    .then((res) => {
                      window.open(`${process.env.REACT_APP_BAYINET_URL}/Product/ProductDetail?ProductId=${rows.productNumber}`, '_blank');
                    })
                    .catch((err) => {});
                }}>
                {rows.productNumber}
              </Box>
            </div>
          );
        },
      },
      {
        field: 'endUser',
        headerName: t('pay:endUser'),
        minWidth: 140,
        flex: 1,
      },
      {
        field: 'subscriptionNumber',
        headerName: t('pay:subscriberNo'),
        width: 130,
        flex: 1,
      },
      {
        field: 'renewalDate',
        headerName: t('pay:renewalDate'),
        width: 120,
        renderCell: (rows) => {
          return dayjs(rows.renewalDate, 'M.D.YYYY HH:mm:ss').format('DD.MM.YYYY');
        },
      },
      {
        field: 'count',
        headerName: t('pay:piece'),
        minWidth: 96,
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'renewalType',
        headerName: t('pay:renewalType'),
        width: 108,
        align: 'center',
        renderCell: (rows) => {
          if (rows.renewalType === renewalTypeEnum.MANUAL) {
            return (
              <PTooltip
                placement="bottom-end"
                title={t('pay:manualRenewal')}>
                <RefreshIcon />
              </PTooltip>
            );
          }

          if (rows.renewalType === renewalTypeEnum.AUTOMATIC) {
            return (
              <PTooltip
                placement="bottom-end"
                title={t('pay:automaticRenewal')}>
                <RefreshIcon sx={{ color: greenColors.green600 }} />
              </PTooltip>
            );
          }

          return <></>;
        },
      },
      {
        field: 'actions',
        headerName: t('pay:detail'),
        width: 72,
        sortable: false,
        align: 'center',
        renderCell: (row) => {
          return (
            <PTooltip
              placement="bottom-start"
              title={t('pay:subscriptionDetailWithoutI')}>
              <IconResolver
                onClick={() => {
                  setIsLoading(true);
                  getSubDetails(row.subscriptionNumber)
                    .then((res) => {
                      setProductInfo({ subscriptionNumber: row.subscriptionNumber, renewalDate: row.renewalDate });
                      setAdditionalInfoForTableMenu(res.data.data);
                      setTabValue(detailTabNumber);
                    })
                    .catch((err) => {
                      toast.error(err.response.data.Message);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
                iconName="ManageSearchOutlined"
                className="cursor-pointer"
              />
            </PTooltip>
          );
        },
      },
    ];
  }, [t]);

  return (
    <PayProductionTable
      infoLabel={t('pay:piece')}
      headerTitle={t('pay:productsList')}
      columns={columns}
      url={url}
      generateId
      getRowClassName={(rowData) => rowData.ended && 'table-error-color'}
      ref={tableRef}
    />
  );
};
export default ProductionTable;
