import { useTheme } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import SimpleTable from 'components/simpleTable/SimpleTable';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyShipmentsColums from './components/MyShipmentsColums';
import MyShipmentsRows from './components/MyShipmentsRows';

const MyShipmentsDetail = ({ detailData }) => {
  const [shippingDatas, setShippingDatas] = useState([]);
  const [renderScreen, setRenderScreen] = useState(false);
  const { t } = useTranslation();
  const [openAll, setOpenAll] = useState(false);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const openCloseAll = () => {
    setOpenAll((prevState) => !prevState);
  };
  useEffect(() => {
    detailData.items.forEach((item, index) => {
      item.id = index + 1;
    });

    setShippingDatas(detailData.items);
    setRenderScreen(true);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        headerName: t('myShipments:productNo'),
        minWidth: 150,
      },
      {
        headerName: t('myShipments:productName'),
        minWidth: 150,
      },
      {
        headerName: t('myShipments:referenceDocument'),
        minWidth: 100,
      },
      {
        headerName: t('myShipments:referencePen'),
        minWidth: 150,
      },
      {
        headerName: t('myShipments:volume'),
        minWidth: 150,
        align: 'right',
      },
      {
        headerName: t('myShipments:amount'),
        minWidth: 150,
        align: 'right',
      },
    ];
  }, [t]);

  const getDynamicWidth = () => {
    if (matchesSm) {
      return '300px';
    } else if (matchesMd) {
      return '500px';
    } else {
      return 'auto';
    }
  };

  return (
    <Box sx={{ width: getDynamicWidth() }}>
      {renderScreen && (
        <>
          <PDivider />
          <SimpleTable
            showHeader={false}
            columns={
              <MyShipmentsColums
                columns={columns}
                openCloseAll={openCloseAll}
                isOpenAll={openAll}
              />
            }
            rows={shippingDatas.map((shippingData) => (
              <MyShipmentsRows
                isOpenAll={openAll}
                row={shippingData}
              />
            ))}
          />
        </>
      )}
    </Box>
  );
};

export default MyShipmentsDetail;
