import { useTheme } from '@emotion/react';
import { Box, Menu, MenuItem, styled, Typography, useMediaQuery } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CustomMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    width: 'auto',
    minWidth: '280px',
  },
}));

const CustomMenuItem = styled(MenuItem)(() => ({
  width: 'auto',
  margin: '4px',
  marginLeft: '16px',
  borderRadius: '8px',
  whiteSpace: 'normal',
}));

const CustomMenuContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const MenuColumn = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: '8px 24px',
  borderRight: `1px solid ${primaryColors.primary200}`,
  '&:last-child': {
    border: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    border: 0,
    borderBottom: `1px solid ${primaryColors.primary200}`,
  },
}));

const ColumnTitleContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '12px',
}));

const ColumnItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '2px',
}));

// const StatusBarWrapper = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   padding: '16px',
//   marginTop: '12px',
//   gap: '24px',
//   [theme.breakpoints.down('md')]: {
//     flexDirection: 'column',
//   },
// }));

// const DeliveryTracking = styled(Box)(() => ({
//   background: 'linear-gradient(92.38deg, #E3FCFD 0%, #E0F3FD 49.98%, #DEE8FC 74.99%, #E2F5FC 100%)',
//   cursor: 'pointer',
//   borderRadius: '8px',
//   padding: '12px',
//   height: '48px',
//   justifyContent: 'space-between',
//   flex: 1,
//   display: 'flex',
// }));

// const DefectiveProductTracking = styled(Box)(() => ({
//   background: 'linear-gradient(92.38deg, #FAE4E4 0%, #FBEDF4 49.98%, #FBF5F4 74.99%, #F8E9EA 100%)',
//   cursor: 'pointer',
//   borderRadius: '8px',
//   padding: '12px',
//   height: '48px',
//   justifyContent: 'space-between',
//   flex: 1,
//   display: 'flex',
// }));

const ManagementMenu = () => {
  const userPermissions = useSelector((state) => state.currentUser?.currentUser?.permissions);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [management, setManagement] = useState([]);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const managementArray = [];
    const managementSelectData = [];

    userPermissions.forEach((permission) => {
      if (permission.parentMenuName === 'Admin' && permission.isMenuItem) {
        managementArray.push({ id: permission.menuName, path: `/${permission.path}`, title: `mainAppBar:${permission.menuName}` });
        managementSelectData.push({ key: `/${permission.path}`, value: t(`mainAppBar:${permission.menuName}`) });
      }
    });
    managementArray.push({ id: 'passwordChange', path: `${paths.accounting}${paths.passwordChange}`, title: 'mainAppBar:passwordOperations' });
    managementSelectData.push({ key: `${paths.accounting}${paths.passwordChange}`, value: t('mainAppBar:passwordOperations') });

    managementArray.push({ id: 'relatedPersons', path: `${paths.accounting}${paths.accountRelatedPersons}`, title: 'loginAppBar:relatedPersons' });
    managementSelectData.push({ key: `${paths.accounting}${paths.accountRelatedPersons}`, value: t('loginAppBar:relatedPersons') });
    managementArray.push({ id: 'myContracts', path: `${paths.accounting}${paths.myContracts}`, title: 'mainAppBar:myContracts' });
    managementSelectData.push({ key: `${paths.accounting}${paths.myContracts}`, value: t('mainAppBar:myContracts') });

    managementArray.push({ id: 'aboutUs', path: `${paths.account}${paths.aboutUs}`, title: 'loginAppBar:aboutUs' });
    managementSelectData.push({ key: `${paths.account}${paths.aboutUs}`, value: t('loginAppBar:aboutUs') });

    managementArray.push({ id: 'contact', path: `${paths.account}${paths.contact}`, title: 'loginAppBar:contact' });
    managementSelectData.push({ key: `${paths.account}${paths.contact}`, value: t('loginAppBar:contact') });

    setManagement(managementArray);
  }, [userPermissions]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateAndClose = (path) => {
    navigate(path);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        className="d-flex align-items-center cursor-pointer h100"
        onClick={handleClick}>
        <Box
          className="d-flex align-items-center"
          sx={{ height: '52px' }}>
          <Typography variant="labelLarge">{t('mainAppBar:management')} </Typography>
        </Box>
        {!open && <IconResolver iconName="KeyboardArrowDown" />}
        {open && <IconResolver iconName="KeyboardArrowUp" />}
      </Box>
      {open && !matchesMd && (
        <Box>
          <CustomMenuContainer>
            {/* <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="StorefrontOutlined" />
                <Typography variant="titleMedium">Satış İşlemleri</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                <CustomMenuItem>Sepetim</CustomMenuItem>
                <CustomMenuItem>Sipariş Takibi</CustomMenuItem>
                <CustomMenuItem>Fatura Listesi</CustomMenuItem>
                <CustomMenuItem>Sevkiyat Saatleri</CustomMenuItem>
                <CustomMenuItem>Kıyaslama Sepeti</CustomMenuItem>
                <CustomMenuItem>Hedef Takip Listesi</CustomMenuItem>
                <CustomMenuItem>Favorilerim / Takip Ettiklerim</CustomMenuItem>
                <CustomMenuItem>Hediye Kuponlarım</CustomMenuItem>
              </ColumnItemContainer>
            </MenuColumn>
            <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="SupportAgentOutlined" />
                <Typography variant="titleMedium">Müşteri Hizmetleri</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                <CustomMenuItem>Öneri / Yorum</CustomMenuItem>
                <CustomMenuItem>Kargom Nerede</CustomMenuItem>
                <CustomMenuItem>Garanti Süresi Sorgulama</CustomMenuItem>
                <CustomMenuItem>Yetkili Servisler</CustomMenuItem>
                <CustomMenuItem>Arızalı Ürün Takibi</CustomMenuItem>
                <CustomMenuItem>İade Formu</CustomMenuItem>
                <CustomMenuItem>Sık Sorulan Sorular</CustomMenuItem>
                <CustomMenuItem>Prosedür</CustomMenuItem>
                <CustomMenuItem>Duyurular</CustomMenuItem>
                <CustomMenuItem>Yetkili Servis Hizmetleri</CustomMenuItem>
                <CustomMenuItem>Kişisel Verilerin Korunması Aydınlatma Metni</CustomMenuItem>
                <CustomMenuItem>Elektronik Lisans Sorgulama</CustomMenuItem>
                <CustomMenuItem>Penta Depom Hesap Makinesi</CustomMenuItem>
              </ColumnItemContainer>
            </MenuColumn>
            <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="AccountBalanceOutlined" />
                <Typography variant="titleMedium">Muhasebe & Finans</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                <CustomMenuItem>Cari Hesap Özeti</CustomMenuItem>
                <CustomMenuItem>Hesap Ekstresi</CustomMenuItem>
                <CustomMenuItem>Bakiye Yapılandırma</CustomMenuItem>
                <CustomMenuItem>Tahsil Edilecek Çekler</CustomMenuItem>
                <CustomMenuItem>Online Taksitli Ödeme Sayfası</CustomMenuItem>
                <CustomMenuItem>Online Taksitli Ödeme Listesi</CustomMenuItem>
                <CustomMenuItem>Banka Hesap No.ları</CustomMenuItem>
                <CustomMenuItem>Banka Hesaplarım</CustomMenuItem>
                <CustomMenuItem>Döviz Kurları</CustomMenuItem>
                <CustomMenuItem>BA-BS Formu</CustomMenuItem>
                <CustomMenuItem>Referanslı Fatura Takibi</CustomMenuItem>
                <CustomMenuItem>Ödeme Yetki ve İptal Talepleri</CustomMenuItem>
                <CustomMenuItem>İndirimli Ödeme Fırsatları</CustomMenuItem>
              </ColumnItemContainer>
            </MenuColumn> */}
            <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="ManageAccountsOutlined" />
                <Typography variant="titleMedium">{t('mainAppBar:managementPanel')}</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                {management.map((item) => {
                  return (
                    <CustomMenuItem
                      key={item.path}
                      onClick={() => navigateAndClose(item.path)}>
                      {t(item.title)}
                    </CustomMenuItem>
                  );
                })}
              </ColumnItemContainer>
            </MenuColumn>
          </CustomMenuContainer>
          {/* <StatusBarWrapper>
            <DeliveryTracking>
              <Box className="d-flex gap-8 align-items-center">
                <PInfoChip label="Teslimat Takibi" />
                <Typography variant="titleMedium">102 adet ürün taşıma durumundadır.</Typography>
              </Box>
              <IconResolver iconName="ChevronRight" />
            </DeliveryTracking>
            <DefectiveProductTracking>
              <Box className="d-flex gap-8 align-items-center">
                <PErrorChip label="Arızalı Ürün Takibi" />
                <Typography variant="titleMedium">Arızalı ürün bulunmamaktadır.</Typography>
              </Box>
              <IconResolver iconName="ChevronRight" />
            </DefectiveProductTracking>
          </StatusBarWrapper> */}
        </Box>
      )}
      {open && matchesMd && (
        <CustomMenu
          id="services-menu"
          aria-labelledby="services-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <CustomMenuContainer>
            {/* <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="StorefrontOutlined" />
                <Typography variant="titleMedium">Satış İşlemleri</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                <CustomMenuItem>Sepetim</CustomMenuItem>
                <CustomMenuItem>Sipariş Takibi</CustomMenuItem>
                <CustomMenuItem>Fatura Listesi</CustomMenuItem>
                <CustomMenuItem>Sevkiyat Saatleri</CustomMenuItem>
                <CustomMenuItem>Kıyaslama Sepeti</CustomMenuItem>
                <CustomMenuItem>Hedef Takip Listesi</CustomMenuItem>
                <CustomMenuItem>Favorilerim / Takip Ettiklerim</CustomMenuItem>
                <CustomMenuItem>Hediye Kuponlarım</CustomMenuItem>
              </ColumnItemContainer>
            </MenuColumn>
            <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="SupportAgentOutlined" />
                <Typography variant="titleMedium">Müşteri Hizmetleri</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                <CustomMenuItem>Öneri / Yorum</CustomMenuItem>
                <CustomMenuItem>Kargom Nerede</CustomMenuItem>
                <CustomMenuItem>Garanti Süresi Sorgulama</CustomMenuItem>
                <CustomMenuItem>Yetkili Servisler</CustomMenuItem>
                <CustomMenuItem>Arızalı Ürün Takibi</CustomMenuItem>
                <CustomMenuItem>İade Formu</CustomMenuItem>
                <CustomMenuItem>Sık Sorulan Sorular</CustomMenuItem>
                <CustomMenuItem>Prosedür</CustomMenuItem>
                <CustomMenuItem>Duyurular</CustomMenuItem>
                <CustomMenuItem>Yetkili Servis Hizmetleri</CustomMenuItem>
                <CustomMenuItem>Kişisel Verilerin Korunması Aydınlatma Metni</CustomMenuItem>
                <CustomMenuItem>Elektronik Lisans Sorgulama</CustomMenuItem>
                <CustomMenuItem>Penta Depom Hesap Makinesi</CustomMenuItem>
              </ColumnItemContainer>
            </MenuColumn>
            <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="AccountBalanceOutlined" />
                <Typography variant="titleMedium">Muhasebe & Finans</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                <CustomMenuItem>Cari Hesap Özeti</CustomMenuItem>
                <CustomMenuItem>Hesap Ekstresi</CustomMenuItem>
                <CustomMenuItem>Bakiye Yapılandırma</CustomMenuItem>
                <CustomMenuItem>Tahsil Edilecek Çekler</CustomMenuItem>
                <CustomMenuItem>Online Taksitli Ödeme Sayfası</CustomMenuItem>
                <CustomMenuItem>Online Taksitli Ödeme Listesi</CustomMenuItem>
                <CustomMenuItem>Banka Hesap No.ları</CustomMenuItem>
                <CustomMenuItem>Banka Hesaplarım</CustomMenuItem>
                <CustomMenuItem>Döviz Kurları</CustomMenuItem>
                <CustomMenuItem>BA-BS Formu</CustomMenuItem>
                <CustomMenuItem>Referanslı Fatura Takibi</CustomMenuItem>
                <CustomMenuItem>Ödeme Yetki ve İptal Talepleri</CustomMenuItem>
                <CustomMenuItem>İndirimli Ödeme Fırsatları</CustomMenuItem>
              </ColumnItemContainer>
            </MenuColumn> */}
            <MenuColumn>
              <ColumnTitleContainer>
                <IconResolver iconName="ManageAccountsOutlined" />
                <Typography variant="titleMedium">{t('mainAppBar:managementPanel')}</Typography>
              </ColumnTitleContainer>
              <ColumnItemContainer>
                {management.map((item) => {
                  return (
                    <CustomMenuItem
                      key={item.path}
                      onClick={() => navigateAndClose(item.path)}>
                      {t(item.title)}
                    </CustomMenuItem>
                  );
                })}
              </ColumnItemContainer>
            </MenuColumn>
          </CustomMenuContainer>
          {/* <StatusBarWrapper>
            <DeliveryTracking>
              <Box className="d-flex gap-8 align-items-center">
                <PInfoChip label="Teslimat Takibi" />
                <Typography variant="titleMedium">102 adet ürün taşıma durumundadır.</Typography>
              </Box>
              <IconResolver iconName="ChevronRight" />
            </DeliveryTracking>
            <DefectiveProductTracking>
              <Box className="d-flex gap-8 align-items-center">
                <PErrorChip label="Arızalı Ürün Takibi" />
                <Typography variant="titleMedium">Arızalı ürün bulunmamaktadır.</Typography>
              </Box>
              <IconResolver iconName="ChevronRight" />
            </DefectiveProductTracking>
          </StatusBarWrapper> */}
        </CustomMenu>
      )}
    </>
  );
};
export default ManagementMenu;
