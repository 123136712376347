import { Grid, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PCenterModal from 'components/modal/PCenterModal';
import { slateColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';

const ApproveModal = ({
  bodyText,
  onClose,
  approveClick = () => {},
  cancelClick = () => {},
  approveTextKey = 'approveModal:yes',
  cancelTextKey = 'approveModal:no',
}) => {
  const { t } = useTranslation();

  const handleCancelButton = () => {
    cancelClick();
    onClose();
  };

  const handleApproveButton = () => {
    approveClick();
  };

  return (
    <PCenterModal onClose={onClose}>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}>
          <Typography
            variant="bodyMedium"
            sx={{ color: slateColors.slate600 }}>
            {bodyText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}>
          <PButton onClick={handleCancelButton}>{t(cancelTextKey)}</PButton>
        </Grid>
        <Grid
          item
          xs={6}>
          <PButton onClick={handleApproveButton}>{t(approveTextKey)}</PButton>
        </Grid>
      </Grid>
    </PCenterModal>
  );
};

export default ApproveModal;
