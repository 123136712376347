import { Box, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import LoginAppBar from 'layout/loginLayout/LoginAppBar';
import { Navigate } from 'react-router-dom';
import { isTokenValid } from 'utils/HelperFunctions';

const LoginWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '100vh',
}));

const MainWrapper = styled('main')(() => ({
  backgroundColor: primaryColors.primary50,
  flex: 1,
  display: 'flex',
  marginTop: '64px',
  marginBottom: '16px',
}));

const LoginLayout = ({ children, checkToken }) => {
  if (checkToken && isTokenValid()) {
    return (
      <Navigate
        to={paths.protectedHome}
        replace={true}
      />
    );
  } else {
    return (
      <LoginWrapper>
        <LoginAppBar />
        <MainWrapper>{children}</MainWrapper>
      </LoginWrapper>
    );
  }
};

export default LoginLayout;
