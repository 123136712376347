export const productServicesTabNumbers = Object.freeze({
  integration: 0,
  transactionHistory: 1,
});

export const orderServicesTabNumbers = Object.freeze({
  integration: 0,
  transactionHistory: 1,
});

export const storageServicesTabNumbers = Object.freeze({
  integration: 0,
  transactionHistory: 1,
});

export const payServicesTabNumbers = Object.freeze({
  integration: 0,
  transactionHistory: 1,
});
