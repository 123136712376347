import { Box, Typography, styled } from '@mui/material';
import WarningAlert from 'components/alert/WarningAlert';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import { primaryColors } from 'config/theme/Colors';
import { addItemToCart } from 'pages/pay/api/PayApi';
import { addToCartEnums } from 'pages/pay/constants/AddToCartEnums';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { removeGivenNumberFromArray } from 'utils/HelperFunctions';
import { v4 as uuidv4 } from 'uuid';

const ItemContainer = styled(Box)(() => ({
  padding: '8px 12px',
  borderRadius: '8px',
  border: `1px solid ${primaryColors.primary200}`,
  minHeight: '72px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexShrink: 0,
}));

const PayRefreshModal = ({ onClose, row, getCartCount, renewalList }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  useEffect(() => {
    if (!row.productNumber) {
      const array = [];
      renewalList.forEach((item) => {
        item.id = uuidv4();
        item.productCount = item.productCount || 1;
        array.push(item);
      });
      setRefreshData(array);
    } else {
      const array = [];
      row.renewalList.forEach((item) => {
        item.id = uuidv4();
        array.push(item);
      });
      setRefreshData([...array]);
    }
  }, []);

  const onInputChange = (newValue, id) => {
    const localData = [...refreshData];
    const foundElement = localData.find((data) => data.id === id);
    foundElement.productCount = newValue;
    setRefreshData(localData);
  };

  const onCheckboxChange = (event, id) => {
    const localIds = [...selectedIds];
    if (event.target.checked) {
      const localIds = [...selectedIds];
      localIds.push(id);
      setSelectedIds([...localIds]);
    } else {
      const removedArray = removeGivenNumberFromArray(localIds, id);
      setSelectedIds([...removedArray]);
    }
  };

  const onSaveHandler = () => {
    if (selectedIds.length <= 0) {
      toast.error(t('pay:selectTheProductsYouWantToRenew'));
      return;
    }
    const filteredData = refreshData.filter((item) => selectedIds.includes(item.id));

    const sendData = filteredData.map((item) => ({
      productId: item.productNumber,
      productName: item.productName,
      quantity: item.productCount,
      currency: item.currency,
      penNumber: item.penNumber,
      zL2Code: item.endUserNumber,
      itemNumber: item.itemNumber,
      subscriptionId: item.subscriptionNumber,
      brand: item.brand,
      type: addToCartEnums.renewable,
    }));

    addItemToCart(sendData)
      .then((res) => {
        getCartCount();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <PCenterModal
      showClose
      width="750px"
      onClose={onClose}
      title={t('pay:productContractRenewal')}>
      {loading && <LoadingSpinner />}
      <Box className="d-flex flex-column gap-12">
        <Box>
          <Typography variant="labelLarge">{t('pay:productList')}</Typography>
        </Box>
        <Box
          className="d-flex flex-column gap-16"
          sx={{ overflow: 'auto', maxHeight: '200px' }}>
          {refreshData.map((item) => {
            return (
              <ItemContainer key={item.id}>
                <Box sx={{ flex: 4 }}>
                  <PCheckbox
                    checked={selectedIds.includes(item.id)}
                    onChange={(event) => onCheckboxChange(event, item.id)}
                    label={`${item.productNumber} - ${item.productName}`}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <InlineEditNumberInput
                    value={item.productCount}
                    onChange={(value) => onInputChange(value, item.id)}
                  />
                </Box>
              </ItemContainer>
            );
          })}
        </Box>
        <Box>
          <WarningAlert label={t('pay:selectTheProductsYouWantToRenew')} />
        </Box>
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={onSaveHandler}>
            {t('pay:add')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};

export default PayRefreshModal;
