import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import { PTableHeaderCell } from 'components/simpleTable/SimpleTable';
const MyShipmentsColums = ({ columns, openCloseAll, isOpenAll }) => {
  return (
    <>
      <PTableHeaderCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={openCloseAll}>
          {isOpenAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </PTableHeaderCell>
      {columns.map((item) => {
        return (
          <PTableHeaderCell
            align={item.align || 'left'}
            key={item.headerName}
            sx={{ width: item.width || 'auto', minWidth: item.minWidth || 'auto' }}>
            {item.headerName}
          </PTableHeaderCell>
        );
      })}
    </>
  );
};

export default MyShipmentsColums;
