/* eslint-disable quotes */
export const AddUserEn = {
  addUser: 'Add User',
  basicKnowledge: 'Basic Knowledge',
  name: 'Name',
  surname: 'Surname',
  email: 'E-mail',
  password: 'Password',
  mobile: 'Mobile',
  phone: 'Phone',
  optional: 'optional',
  userExpirationDate: 'User Expiration Date',
  profitability: 'Profitability',
  valueCanBeEnteredInFormat: 'value can be entered in {{number}} format',
  activeUser: 'Active user',
  callCenterService: 'Call center service',
  permissions: 'Permissions',
  thereAreNoPermissionsAddedYet: 'There are no permissions added yet.',
  managePermissions: 'Manage Permissions',
  saveUser: 'Save User',
  saveAsTemplate: 'Save As Template',
  priceDefinitions: 'Price Definitions',
  userPermissions: 'User Permissions',
  allowedIpAddresses: 'Allowed IP addresses',
  applyPermissions: 'Apply Permissions',
  pleasePressEnterAfterEachEntry: 'Please press enter after each entry.',
  templateName: 'Template name',
  cancel: 'Cancel',
  save: 'Save',
  thisFieldIsRequired: 'Please enter {{field}}.',
  theEmailFormatIsInvalid: 'E-mail format is invalid.',
  permissionsAdded: '{{number}} permission added',
  selectTemplate: 'Select template',
  yes: 'Yes',
  phoneNumberChangeIsAwaitingApproval: 'Phone number change is awaiting approval',
  emailAddressChangeIsAwaitingApproval: 'E-mail address change is awaiting approval',
  'Permissions.User.Management': 'User Management',
  'Permissions.Storage.Management': 'Penta Storage',
  'Permissions.DeliveryAddress.DeliveryAddressView': 'Adding/Updating Delivery Addresses',
  'Permissions.DeliveryAddress.AddBulkDeliverAddress': 'Bulk Add Address',
  'Permissions.ConfigMaster.ConfigMasterView': 'Penta Config Master',
  enterUserBasicInformations: 'Enter user basic informations',
  emailPlaceholder: 'Enter your e-mail address',
  datepickerPlaceholder: 'Select a date',
  manageUserPermissionsInfo: "Manage user's permission information",
  ipAddress: 'IP address',
  addIpAddresses: 'Add IP addresses',
  'Permissions.PentaSubscriptionManagement': 'Penta Subscription Management',
  MaxLength: '{{field}} field has a maximum of {{char}} characters.',
  'Permissions.Integration.All': 'Integration',
  'Permissions.ContractPerson.ContractPersonCrud': 'Related Persons',
};
