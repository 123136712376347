import { paths } from 'constants/Paths';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import ApproveUpdate from '../ApproveUpdate';

export const approveUpdateRoutes = [
  {
    path: `${paths.approveUpdateEmail}/:token`,
    element: (
      <LoginLayout>
        <ApproveUpdate type="mail" />
      </LoginLayout>
    ),
  },
  {
    path: `${paths.approveUpdateMobile}/:token`,
    element: (
      <LoginLayout>
        <ApproveUpdate type="mobile" />
      </LoginLayout>
    ),
  },
];
