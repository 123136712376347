import { paths } from 'constants/Paths';
import MainLayout from 'layout/mainLayout/MainLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import ChangePassword from '../ChangePassword';

export const changePasswordRoutes = {
  path: `${paths.accounting}${paths.passwordChange}`,
  element: (
    <ProtectedRoute>
      <MainLayout>
        <ChangePassword />
      </MainLayout>
    </ProtectedRoute>
  ),
};
