export const ProductsInStorageEn = {
  filter: 'Filter',
  detailFilter: 'Detail Filter',
  excelPrintout: 'Excel Printout',
  averageStockAge: 'Average Stock Age',
  totalNumber: 'Total Number',
  differentNumberOfProducts: 'Different Number of Products',
  totalCost: 'Total Cost',
  piece: 'Piece',
  day: 'Day',
  allProducts: 'All Products',
  orderNumber: 'Order Number',
  productNumber: 'Product Number',
  itemNumber: 'Item Number',
  storageNumber: 'Storage Number',
  searchWord: 'Search Word',
  orderNo: 'Order No',
  entryNo: 'Entry No',
  storage: 'Storage',
  itemName: 'Item Name',
  unitPrice: 'Unit Price',
  stockAge: 'Stock Age',
  serialNo: 'Serial No',
  serialNumbersOfProduction: 'Serial Numbers of Production {{number}}',
  orderNoWithNumber: 'Order No {{number}}',
  productNoWithNumber: 'Product No {{number}}',
  warehouseNoWithNumber: 'Warehouse No {{number}}',
  listItems: 'List items',
  capitalizeDetailFilter: 'Detail Filter',
  clearAll: 'Clear all',
  category: 'Category',
  brand: 'Brand',
  storagePlace: 'Storage Place',
  cancel: 'Cancel',
  apply: 'Apply',
  addProductNoToCart: 'Add Product No. {{number}} to the Cart',
  selectTheSerialNumberOfTheProduct: 'I want to select the serial numbers of the products I want to add to the cart',
  addToCart: 'Add to Cart',
  searchWithSerialNo: 'Search Serial No...',
  selectedSerialNoCannotExeed: 'The selected serial no number cannot exceed the number of products',
  youCannotDeleteAPreviouslyAddedSerialNumber: 'You cannot delete a previously added serial number',
  alreadyInCart: 'Already In Cart',
  additableSerialNo: 'Additable Serials No',
  allProductsInStockAreAlreadyInYourCart: 'All products in stock are already in your cart.',
  piecesInStock: '{{number}} Pieces in stock',
  viewBulkOrders: 'Add and view bulk products',
  bulkOrders: 'Bulk Added Product',
  bulkOrderList: 'Bulk Added Product List',
  selectStartDate: 'Select start date',
  selectEndDate: 'Select end date',
  searchForQueueNumber: 'Queue number ...',
  list: 'List',
  doSearch: 'Do Search...',
  createBulkOrder: 'Add Bulk Products',
  bulkOrderFile: 'Bulk Product File',
  quicklyCreateBulkOrdersFromFile: 'Quickly add bulk products from file.',
  createOrders: 'Add Product',
  referenceCustomerOrderNo: 'Reference Customer Order No.',
  manufacturerPartNo: 'Manufacturer Part No',
  message: 'Message',
  incorrectOrderLines: 'Incorrect Order Lines',
  deleteErrors: 'Delete Errors',
  queueEntryNo: 'Queue Entry No',
  material: 'Material',
  sdCertificate: 'Project Order No',
  orderItemNo: 'Order Item No',
  excelSuccess: 'The file has been uploaded successfully',
  excelError: 'There was a problem uploading the file. Please try again',
  onSuccessFileUpload: 'You can view the products you added with the queue number <CustomLink>{{number}}</CustomLink>.',
  productsWithQueueNumbers: 'Products with Queue Numbers',
};
