import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import ConfigurationPreferences from 'pages/pentaIntegration/pages/configurationPreferences/ConfigurationPreferences';
import OrderServices from 'pages/pentaIntegration/pages/orderServices/OrderServices';
import PayServices from 'pages/pentaIntegration/pages/payServices/PayServices';
import ProductServices from 'pages/pentaIntegration/pages/productServices/ProductServices';
import StorageServices from 'pages/pentaIntegration/pages/storageServices/StorageServices';
import TransactionDetail from 'pages/pentaIntegration/pages/transactionDetail/TransactionDetail';
import PentaIntegrationContainer from 'pages/pentaIntegration/PentaIntegrationContainer';

export const pentaIntegrationRoutes = [
  {
    path: `${paths.integration}${paths.productServices}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PentaIntegrationContainer>
            <ProductServices />
          </PentaIntegrationContainer>
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.integration}${paths.orderServices}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PentaIntegrationContainer>
            <OrderServices />
          </PentaIntegrationContainer>
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.integration}${paths.storageServices}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PentaIntegrationContainer>
            <StorageServices />
          </PentaIntegrationContainer>
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.integration}${paths.payServices}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PentaIntegrationContainer>
            <PayServices />
          </PentaIntegrationContainer>
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.integration}${paths.configurationPreferences}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PentaIntegrationContainer>
            <ConfigurationPreferences />
          </PentaIntegrationContainer>
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.integration}${paths.transactionDetail}/:id`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <TransactionDetail />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
];
