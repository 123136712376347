import { Box } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import PTextField from 'components/textField/PTextField';
import { addLicence } from 'pages/pay/api/PayApi';
import { createAnOfferValidationMessagesKeys } from 'pages/pay/pages/getAnOffer/constants/CreateAnOfferValidationMessagesKeys';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';

const LicenceModal = ({ onClose, licenceInfo, getActiveCartItemsFn }) => {
  const { t } = useTranslation();
  const [fieldsData, setFieldsData] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [saveSimilarFrom, setSaveSimilarFrom] = useState(false);
  useEffect(() => {
    const saveSimilar = licenceInfo.fields[0].saveSimilarFrom;
    setSaveSimilarFrom(saveSimilar);
    setFieldsData([...licenceInfo.fields]);
  }, []);

  const handleFieldChange = (index, newValue) => {
    const updatedFields = fieldsData.map((field, i) => {
      if (i === index) {
        setErrors((prevState) => ({ ...prevState, [field.counter]: '' }));
        return { ...field, value: newValue };
      } else {
        return field;
      }
    });
    setFieldsData(updatedFields);
  };

  const validate = () => {
    const rules = {};
    licenceInfo.fields.forEach((item) => {
      rules[item.counter] = item.isFieldRequired ? `required|max:${item.fieldLenght}` : `max:${item.fieldLenght}`;
    });

    const validationData = Object.assign(...fieldsData.map((a) => ({ [a.counter]: a.value })));
    const validation = new Validator({ ...validationData }, rules, createAnOfferValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => {
        const updatedErrors = { ...prevState };
        Object.keys(validation.errors.errors).forEach((key) => {
          updatedErrors[key] = validation.errors.first(key);
        });
        return updatedErrors;
      });
    }
  };

  const onSaveHandler = () => {
    if (validate()) {
      const convertedData = {
        productCartItemsId: licenceInfo.cartItemId,
        lisans: [...fieldsData],
        saveSimilarFrom,
      };
      setLoading(true);
      addLicence(convertedData)
        .then((res) => {
          toast.success(res.data.message);
          onClose();
          getActiveCartItemsFn();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSaveSimilar = (event) => {
    const { checked } = event.target;
    setSaveSimilarFrom(checked);
  };

  return (
    <PCenterModal
      width="676px"
      showClose
      onClose={onClose}
      title={t('pay:productLicenseInformationNo', { number: Number(licenceInfo.title) })}>
      {loading && <LoadingSpinner />}
      <Box className="d-flex flex-column gap-12">
        {fieldsData.map((field, index) => (
          <PTextField
            key={field.counter}
            label={field.fieldName}
            placeholder={field.fieldName}
            value={field.value}
            onChange={(e) => handleFieldChange(index, e.target.value)}
            maxLength={field.fieldLenght}
            error={!!errors[field.counter]}
            errorMessage={t(errors[field.counter], { field: field.fieldName, char: field.fieldLenght })}
          />
        ))}
        <PCheckbox
          checked={saveSimilarFrom}
          onChange={handleSaveSimilar}
          label={t('pay:saveSimilarFormsWithTheSameInformation')}
        />
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={onSaveHandler}>
            {t('pay:save')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};

export default LicenceModal;
