import { InfoOutlined } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PIconButton from 'components/button/PIconButton';
import PErrorChip from 'components/chip/PErrorChip';
import PInfoChip from 'components/chip/PInfoChip';
import PSuccessChip from 'components/chip/PSuccessChip';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import PTooltip from 'components/tooltip/PTooltip';
import { redColors } from 'config/theme/Colors';
import { integrationStatusEnum } from 'pages/pentaIntegration/constants/IntegrationStatusEnum';
import { useTranslation } from 'react-i18next';
import requestIntegration from '../../constants/media/requestIntegration.png';

const ActiveRequestTopPart = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  minHeight: '80px',
  justifyContent: 'space-between',
  background: '#ffffff',
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  overflow: 'auto',
}));

const ActiveRequestBottomPart = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  minHeight: '80px',
  justifyContent: 'space-between',
  background: '#ffffff',
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  paddingLeft: '68px',
  overflow: 'auto',
}));

const BottomItems = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '180px',
  width: 'auto',
}));

const ActiveRequestFound = ({ activeRequest, approveTest, toogleShowLiveModal, cancel }) => {
  const { t } = useTranslation();

  return (
    <Box className="overflow-hidden">
      <ActiveRequestTopPart>
        <Box className="d-flex gap-16 w100">
          <Box>
            <img
              alt="icon"
              width={40}
              height={40}
              src={requestIntegration}
            />
          </Box>
          <Box className="d-flex flex-column">
            <Box>
              <Typography variant="titleMedium">{t('integration:integrationRequestNumber', { number: activeRequest.processNo })}</Typography>
            </Box>
            <Box>
              {activeRequest.statuCode === integrationStatusEnum.requestCreated && (
                <PInfoChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.requestWaitingForApprove && (
                <PInfoChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.requestApproved && (
                <PSuccessChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.customerApprovedTest && (
                <PInfoChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.pentaApprovalProcessTest && (
                <PInfoChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.testAppvored && (
                <PSuccessChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.activeRequestCreated && (
                <PInfoChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.pentaApprovalProcessActive && (
                <PInfoChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.activeRequestApproved && (
                <PSuccessChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.processCompleted && (
                <PInfoChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
              {activeRequest.statuCode === integrationStatusEnum.rejected && (
                <PErrorChip
                  fontSize="12px"
                  label={activeRequest.statuName}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box className="d-flex gap-16 align-items-center">
          {activeRequest.statuCode === integrationStatusEnum.requestApproved && (
            <Box>
              <PButton
                width="max-content"
                onClick={approveTest}
                startIcon={<IconResolver iconName="PlayArrowOutlined" />}>
                {t('integration:completeTheTest')}
              </PButton>
            </Box>
          )}
          {activeRequest.statuCode === integrationStatusEnum.testAppvored && (
            <Box>
              <PButton
                width="max-content"
                onClick={toogleShowLiveModal}
                startIcon={<IconResolver iconName="PlayArrowOutlined" />}>
                {t('integration:goLive')}
              </PButton>
            </Box>
          )}
          <Box>
            <PIconButton
              inputtype="text"
              onClick={cancel}>
              <IconResolver
                sx={{ color: redColors.red600 }}
                iconName="CloseOutlined"
                fontSize="small"
              />
            </PIconButton>
          </Box>
        </Box>
      </ActiveRequestTopPart>
      <PDivider marginBottom="0px" />
      <ActiveRequestBottomPart>
        <Box className="d-flex gap-16">
          <BottomItems>
            <Typography variant="bodyMedium">{t('integration:requester')}</Typography>
            <Typography variant="titleSmall">{activeRequest.configuration.sapCustomerName}</Typography>
            <Typography variant="bodySmall">{activeRequest.configuration.sapCustomerId}</Typography>
          </BottomItems>
          <BottomItems>
            <Typography variant="bodyMedium">{t('integration:ipAddresses')}</Typography>
            <Box className="d-flex gap-4 align-items-start">
              <Typography variant="titleSmall">
                {t('integration:ipAddressesWithNumber', { number: activeRequest.configuration.ipList.length })}
              </Typography>
              <PTooltip
                placement="bottom-end"
                title={
                  <div className="d-flex gap-16">
                    {activeRequest.configuration.ipList.some((item) => !item.isLive) && (
                      <div className="d-flex flex-column gap-8">
                        <b>{t('integration:testIpAddressess')}</b>
                        {activeRequest.configuration.ipList
                          .filter((item) => !item.isLive)
                          .map((item) => {
                            return <div key={item.ip}>{item.ip}</div>;
                          })}
                      </div>
                    )}
                    {activeRequest.configuration.ipList.some((item) => item.isLive) && (
                      <div className="d-flex flex-column gap-8">
                        <b>{t('integration:liveIpAddressess')}</b>
                        {activeRequest.configuration.ipList
                          .filter((item) => item.isLive)
                          .map((item) => {
                            return <div key={item.ip}>{item.ip}</div>;
                          })}
                      </div>
                    )}
                  </div>
                }>
                <InfoOutlined
                  fontSize="small"
                  sx={{ height: '0.75em', width: '0.75em' }}
                />
              </PTooltip>
            </Box>
          </BottomItems>
          <BottomItems>
            <Typography variant="bodyMedium">{t('integration:emailAddresses')}</Typography>
            <Box className="d-flex gap-4 align-items-start">
              <Typography variant="titleSmall">
                {t('integration:emailAddressesWithNumber', { number: activeRequest.configuration.mailList.length })}
              </Typography>
              <PTooltip
                placement="bottom-end"
                title={
                  <div>
                    {activeRequest.configuration.mailList.map((item) => (
                      <div key={item}>{item}</div>
                    ))}
                  </div>
                }>
                <InfoOutlined
                  fontSize="small"
                  sx={{ height: '0.75em', width: '0.75em' }}
                />
              </PTooltip>
            </Box>
          </BottomItems>
        </Box>
      </ActiveRequestBottomPart>
    </Box>
  );
};

export default ActiveRequestFound;
