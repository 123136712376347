import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Typography, styled } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import PIconButton from 'components/button/PIconButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import UnitInput from 'components/unitInput/UnitInput';
import { primaryColors } from 'config/theme/Colors';
import ErrorPage from 'pages/pentaStorage/DeliveryDetails/ErrorPage';
import SuccessPage from 'pages/pentaStorage/DeliveryDetails/SuccessPage';
import DeliveryFromStorageOption from 'pages/pentaStorage/DeliveryDetails/components/DeliveryFromStorageOption';
import PentaShipmentOption from 'pages/pentaStorage/DeliveryDetails/components/PentaShipmentOption';
import { deliveryDetailValidationMessagesKeys } from 'pages/pentaStorage/DeliveryDetails/i18n/DeliveryDetailValidationMessagesKeys';
import { createDelivery } from 'pages/pentaStorage/Tabs/storageCart/api/StorageCartApi';
import { getDeliveryType } from 'pages/pentaStorage/api/StorageCostApi';
import { deliveryTypeEnum } from 'pages/pentaStorage/constants/DeliveryTypeEnum';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';
const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  margin: '0 16px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const TitleGridContainer = styled(Box)(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 12,
}));

const BigGridItem = styled(Box)(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 8,
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
}));

const GridItemContainer = styled(Box)(({ direction = 'column' }) => ({
  display: 'flex',
  flexDirection: direction,
  gap: '8px',
  padding: '20px 16px',
}));

const SmallGridItem = styled(Box)(() => ({
  gridColumnStart: 8,
  gridColumnEnd: 11,
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
  padding: '20px 0',
  height: 'min-content',
}));

const ContainerParts = styled(Box)(() => ({
  padding: '0 16px',
}));

const DeliveryDetails = ({ data, setShowDeliveryDetails, setCartItemNumber }) => {
  const { t } = useTranslation();
  const [incomingData, setIncomingData] = useState({});
  const [selectValue, setSelectValue] = useState('');
  const [description, setDescription] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const [receiveSms, setReceiveSms] = useState(false);
  const [additionalInfo, setAditionalInfo] = useState('');
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const [dynamicTelInput, setDynamicTelInput] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    additionalInfo: '',
  });
  useEffect(() => {
    setIsLoading(true);
    getDeliveryType({ storagePlace: data.storagePlace, deliveryType: '', receiver: '', contactPerson: '' })
      .then((updatedData) => {
        setIncomingData(updatedData.data.data);
        setSelectValue(updatedData.data.data.e_VSART);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const selectData = useMemo(() => {
    if (incomingData.deliveryType) {
      return incomingData.deliveryType?.map((item) => ({
        key: item.vsart,
        value: t(`deliveryDetails:${item.vsart}`),
      }));
    } else {
      return [];
    }
  }, [t, incomingData.deliveryType]);

  const backToPentaBasket = () => {
    setShowDeliveryDetails(false);
  };

  const onSelectChange = (event) => {
    setAditionalInfo('');
    setSelectValue(event.target.value);
    setIsLoading(true);
    getDeliveryType({ storagePlace: data.storagePlace, deliveryType: event.target.value, contactPerson: '', receiver: '' })
      .then((res) => {
        setIncomingData(res.data.data);
        setSelectValue(res.data.data.e_VSART);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setErrors((prevState) => ({ ...prevState, additionalInfo: '' }));
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleOrderNoChange = (event) => {
    setOrderNo(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setReceiveSms(event.target.checked);
    setDynamicTelInput([]);
  };

  const handleAddTelInput = () => {
    if (dynamicTelInput.length < 4) {
      setDynamicTelInput([...dynamicTelInput, { phone: '' }]);
    }
  };

  const handleDynamicTelChange = (value, index) => {
    const onChangeValue = [...dynamicTelInput];
    onChangeValue[index]['phone'] = value;
    setDynamicTelInput(onChangeValue);
  };

  const handleDynamicTelDelete = (index) => {
    const deleteValue = [...dynamicTelInput];
    deleteValue.splice(index, 1);
    setDynamicTelInput(deleteValue);
  };

  const handleCreateDelivery = () => {
    const rules = {
      additionalInfo: 'required',
    };

    const validationData = { additionalInfo };

    const validation = new Validator(validationData, rules, deliveryDetailValidationMessagesKeys);
    if (validation.passes()) {
      const phones = [];
      phones.push(currentUser.user.phone);
      dynamicTelInput.forEach((item) => phones.push(item.phone.replace(/\s/g, '').substring(1)));
      const sendData = {
        currency: incomingData.currency,
        deliveryText: description,
        spfrom: 'PD',
        orderNo: orderNo,
        deliveryStore: selectValue,
        storageCode: data.storagePlace,
        items: data.tableData.map((singleData) => ({
          item: {
            itemId: singleData.id,
            cartId: singleData.cartId,
            productId: singleData.stockCode,
            productName: singleData.itemName,
            qty: singleData.quantity,
            storagePlace: data.storagePlace,
            orderNo: singleData.orderNo,
            entryNo: singleData.entryNumber,
            serialNumbers: singleData.serials,
          },
        })),
        phones,
        deliveryType: selectValue,
      };
      if (selectValue === deliveryTypeEnum.pickUpFromStorage) {
        sendData.contactPerson = additionalInfo.toString();
      } else {
        const found = incomingData.deliveryOptions.find((item) => item.customerCode === additionalInfo);
        sendData.deliveryAddress = `${found.name} ${found.street} ${found.stR_SUPPL3} ${found.phone}`;

        sendData.deliveryAddressValue = additionalInfo.toString();
      }
      setIsLoading(true);
      createDelivery(sendData)
        .then((res) => {
          if (res.data.data.errors.length === 0) {
            setCartItemNumber(0);
            setShowSuccess(res.data.data);
          } else {
            setShowError(res.data.data);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrors((prevState) => ({
        ...prevState,
        additionalInfo: validation.errors.first('additionalInfo'),
      }));
    }
  };

  const handleChange = (event) => {
    setAditionalInfo(event.target.value);
    setErrors({ additionalInfo: '' });
    if (selectValue !== deliveryTypeEnum.pickUpFromStorage) {
      setIsLoading(true);
      getDeliveryType({ storagePlace: data.storagePlace, deliveryType: selectValue, contactPerson: '', receiver: event.target.value })
        .then((res) => {
          setIncomingData(res.data.data);
          setSelectValue(res.data.data.e_VSART);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const dataFromApi = (apiData) => {
    setIncomingData((prevState) => ({
      ...prevState,
      deliveryType: apiData.deliveryType,
      contracts: apiData.contracts,
      deliveryOptions: apiData.deliveryOptions,
      currency: apiData.currency,
      prepareCost: apiData.prepareCost,
      cargoCost: apiData.cargoCost,
    }));
    setSelectValue(apiData.e_VSART);
  };

  return (
    <>
      <GridContainer>
        {isLoading && <LoadingSpinner />}
        <TitleGridContainer>
          <TitleContainer>
            <PIconButton
              inputtype="text"
              onClick={backToPentaBasket}>
              <ChevronLeftIcon />
            </PIconButton>
            <Typography variant="titleLarge">{t('deliveryDetails:deliveryDetails')}</Typography>
          </TitleContainer>
        </TitleGridContainer>
      </GridContainer>
      <PDivider />
      {!showSuccess && !showError && (
        <GridContainer>
          <BigGridItem>
            <Box className="d-flex flex-column">
              <GridItemContainer>
                <Typography
                  variant="titleMedium"
                  color={primaryColors.primary}>
                  {t('deliveryDetails:shipmentAndInformation')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary700}>
                  {t('deliveryDetails:chooseShippingOptionsAndNotificationMethods')}
                </Typography>
              </GridItemContainer>
              <PDivider marginBottom="0" />
              <GridItemContainer>
                <PSelect
                  value={selectValue}
                  menuItems={selectData}
                  onChange={onSelectChange}
                  label={t('deliveryDetails:shipmentType')}
                  placeholder={t('deliveryDetails:shipmentType')}
                />
                {selectValue !== deliveryTypeEnum.pickUpFromStorage && (
                  <PentaShipmentOption
                    deliveryOptions={incomingData.deliveryOptions}
                    errorMessage={t(errors.additionalInfo, { field: t('deliveryDetails:selectDeliveryAddress') })}
                    error={!!errors.additionalInfo}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    storagePlace={data.storagePlace}
                    setValues={dataFromApi}
                  />
                )}
                {selectValue === deliveryTypeEnum.pickUpFromStorage && (
                  <DeliveryFromStorageOption
                    contracts={incomingData.contracts}
                    errorMessage={t(errors.additionalInfo, { field: t('deliveryDetails:selectRelevantPeople') })}
                    error={!!errors.additionalInfo}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    storagePlace={data.storagePlace}
                    setValues={dataFromApi}
                  />
                )}
                <PTextField
                  placeholder={t('deliveryDetails:enterDeliveryDescription')}
                  value={description}
                  label={t('deliveryDetails:enterDescription')}
                  name="description"
                  onChange={handleDescriptionChange}
                />
                <PTextField
                  placeholder={t('deliveryDetails:orderNo')}
                  value={orderNo}
                  label={t('deliveryDetails:orderNo')}
                  name="orderNo"
                  onChange={handleOrderNoChange}
                />
                <PCheckbox
                  checked={receiveSms}
                  onChange={handleCheckboxChange}
                  label={t('deliveryDetails:checkboxInfo')}
                />
                <Box className="d-flex align-items-center gap-20">
                  <TelInput
                    label={t('deliveryDetails:numberToSendSms')}
                    disabled
                    value={`+${currentUser.user.phone}`}
                  />
                  <PIconButton
                    disabled={!receiveSms}
                    inputtype="tonal"
                    onClick={handleAddTelInput}>
                    <AddIcon />
                  </PIconButton>
                </Box>
                {dynamicTelInput.map((telInput, index) => (
                  <Box className="d-flex align-items-center gap-20">
                    <TelInput
                      label={t('deliveryDetails:numberToSendSms')}
                      name="telInput"
                      value={telInput.phone}
                      onChange={(value) => handleDynamicTelChange(value, index)}
                    />
                    <PIconButton
                      inputtype="tonal"
                      onClick={() => handleDynamicTelDelete(index)}>
                      <DeleteOutlineIcon />
                    </PIconButton>
                  </Box>
                ))}
              </GridItemContainer>
            </Box>
          </BigGridItem>
          <SmallGridItem>
            <ContainerParts>
              <Box className="d-flex flex-column gap-32">
                <Box className="d-flex flex-column gap-16">
                  <Box className="d-flex align-items-center justify-content-between">
                    <Typography variant="bodyMedium">{t('deliveryDetails:transportationFee')}</Typography>
                    <Typography variant="titleMedium">
                      {incomingData.currency && (
                        <UnitInput
                          value={incomingData.cargoCost}
                          unit={incomingData.currency}
                        />
                      )}
                    </Typography>
                  </Box>
                  <PDivider marginBottom="0" />
                  <Box className="d-flex align-items-center justify-content-between">
                    <Typography variant="bodyMedium">{t('deliveryDetails:coveredAmount')}</Typography>
                    <Typography variant="titleMedium">
                      {incomingData.currency && (
                        <UnitInput
                          value={incomingData.numberOfCargoCovered}
                          unit={incomingData.currency}
                        />
                      )}
                    </Typography>
                  </Box>
                  <PDivider marginBottom="0" />
                  <Box className="d-flex align-items-center justify-content-between">
                    <Typography variant="bodyMedium">{t('deliveryDetails:preparationFee')}</Typography>
                    <Typography variant="titleMedium">
                      {incomingData.currency && (
                        <UnitInput
                          value={incomingData.prepareCost}
                          unit={incomingData.currency}
                        />
                      )}
                    </Typography>
                  </Box>
                </Box>
                <InfoAlert label={t('deliveryDetails:infoMessage')} />
                <PButton onClick={handleCreateDelivery}> {t('deliveryDetails:createDelivery')}</PButton>
              </Box>
            </ContainerParts>
          </SmallGridItem>
        </GridContainer>
      )}
      {showSuccess && <SuccessPage data={showSuccess} />}
      {showError && <ErrorPage data={showError} />}
    </>
  );
};

export default DeliveryDetails;
