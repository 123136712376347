import AddIcon from '@mui/icons-material/Add';
import { Box, styled } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PPositiveTextField from 'components/textField/PPositiveTextField';
import PTextFieldWithMenu from 'components/textField/PTextFieldWithMenu';
import PentaStorageTabContainer from 'pages/pentaStorage/commonComponents/PentaStorageTabContainer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';
import { addCalculatorValidationMessagesKeys } from './AddCalculatorValidationMessagesKeys';
import CalculatorDescription from './CalculatorDescription';
import CalculatorTable from './CalculatorTable';
import { calculateTableData, getSimiliarItems } from './api/CalculatorApi';

const AddContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '16px',
  '&>*:nth-of-type(1)': {
    diplay: 'inline-flex',
    gridColumnStart: 1,
    gridColumnEnd: 4,
  },
  '&>*:nth-of-type(2)': {
    diplay: 'inline-flex',

    gridColumnStart: 4,
    gridColumnEnd: 6,
  },
  '&>*:nth-of-type(3)': {
    diplay: 'inline-flex',

    gridColumnStart: 6,
    gridColumnEnd: 8,
  },
  '&>*:nth-of-type(4)': {
    diplay: 'inline-flex',

    gridColumnStart: 8,
    gridColumnEnd: 9,
    alignSelf: 'center',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const Calculator = ({ tabValue }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    item: {
      name: '',
      productNumber: '',
    },
    count: 1,
    storageDay: 30,
    checkbox: false,
  });
  const [errors, setErrors] = useState({
    item: '',
    count: '',
    storageDay: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [itemsMenu, setItemsMenu] = useState([]);
  const [descriptionValues, setDescriptionValues] = useState(null);
  const [tableData, setTableData] = useState([]);

  const onFormDataChangeHandler = (event) => {
    const { value, name } = event.target;

    if (name === 'item' && value && value.length >= 3) {
      getSimiliarItems(value)
        .then((res) => {
          const itemMenuArray = [];
          res.data.data.forEach((item) => {
            itemMenuArray.push({
              id: item.productNumber,
              content: (
                <div>
                  {item.productNumber} - {item.productName}
                </div>
              ),
              onClick: () =>
                setFormData((prevState) => ({
                  ...prevState,
                  item: { name: `${item.productNumber} - ${item.productName}`, productNumber: item.productNumber },
                })),
            });
          });
          setItemsMenu(itemMenuArray);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onChecboxChangeHandler = (event) => {
    const { checked, name } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: checked }));
  };

  useEffect(() => {
    if (tableData.length === 0) {
      setDescriptionValues(null);
    }
  }, [tableData]);

  const validate = () => {
    const rules = {
      item: 'required',
      count: 'required',
      storageDay: 'required',
    };

    const validateData = {
      item: formData.item.productNumber,
      count: formData.count,
      storageDay: formData.storageDay,
    };

    const validation = new Validator(validateData, rules, addCalculatorValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        item: validation.errors.first('item'),
        count: validation.errors.first('count'),
        storageDay: validation.errors.first('storageDay'),
      }));
      return false;
    }
  };

  const addHandler = () => {
    if (validate()) {
      const localData = [...tableData];
      if (!localData.find((item) => item.stockCode === formData.item.productNumber)) {
        let editedName = formData.item.name;
        editedName = editedName.split(' - ');
        localData.push({
          id: formData.item.productNumber,
          stockCode: formData.item.productNumber,
          itemName: editedName[1],
          piece: formData.count,
          numberOfDaysToStore: formData.storageDay,
        });
        setTableData(localData);
        setFormData((prevState) => ({ ...prevState, item: { name: '', productNumber: '' }, storageDay: 30, count: 1 }));
        setItemsMenu([]);
        setErrors({
          item: '',
          count: '',
          storageDay: '',
        });
      } else {
        toast.error(t('calculator:theProductYouWantToAddIsAvailableInTheTable'));
      }
    }
  };

  const calculate = () => {
    const sendData = {
      calculateWithPentaStorageProducts: formData.checkbox ? 'X' : '',
      products: tableData.map((item) => ({
        productNumber: item.id,
        quantity: item.piece,
        storageTimeAsDay: item.numberOfDaysToStore,
      })),
    };
    setIsLoading(true);
    calculateTableData(sendData)
      .then((response) => {
        const res = response.data.data;
        const data = {
          desiCost: res.desiCost,
          sameLocationCargoFee: res.sameLocationCargoFee,
          sameLocationShipment: res.sameLocationShipment,
          differentLocationShipment: res.differentLocationShipment,
          differentLocationCargo: res.differentLocationCargo,
          shippingCost: res.shippingCost,
          currency: res.currency,
          totalSameLocationCargoFee: res.totalSameLocationCargoFee,
          totalSameLocationShippingFee: res.totalSameLocationShippingFee,
          totalDifferentLocationCargoFee: res.totalDifferentLocationCargoFee,
          totalDifferentLocationShippingFee: res.totalDifferentLocationShippingFee,
          storageField: {
            en: res.constantContent[0].valueEn.split('##'),
            tr: res.constantContent[0].value.split('##'),
          },
          shipmentsField: {
            en: res.constantContent[1].valueEn.split('##'),
            tr: res.constantContent[1].value.split('##'),
          },
          totalField: {
            en: res.constantContent[2].valueEn.split('##'),
            tr: res.constantContent[2].value.split('##'),
          },
          sameLocationCargoInfo: {
            en: res.constantContent[3].valueEn,
            tr: res.constantContent[3].value,
          },
          sameLocationShipmentInfo: {
            en: res.constantContent[4].valueEn,
            tr: res.constantContent[4].value,
          },
          differentLocationCargoInfo: {
            en: res.constantContent[5].valueEn,
            tr: res.constantContent[5].value,
          },
          differentLocationShipmentInfo: {
            en: res.constantContent[6].valueEn,
            tr: res.constantContent[6].value,
          },
        };

        setDescriptionValues(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PentaStorageTabContainer>
      {isLoading && <LoadingSpinner />}

      <AddContainer>
        <div>
          <PTextFieldWithMenu
            label={t('calculator:productToBeAddedToTheCalculation')}
            name="item"
            value={formData.item.name}
            onChange={onFormDataChangeHandler}
            menuItems={itemsMenu}
            error={!!errors.item}
            errorMessage={t(errors.item, { field: t('calculator:productToBeAddedToTheCalculation') })}
          />
        </div>
        <div>
          <PPositiveTextField
            label={t('calculator:piece')}
            name="count"
            value={formData.count}
            onChange={onFormDataChangeHandler}
            error={!!errors.count}
            errorMessage={t(errors.count, { field: t('calculator:piece') })}
          />
        </div>
        <div>
          <PPositiveTextField
            label={t('calculator:daysToStore')}
            name="storageDay"
            value={formData.storageDay}
            onChange={onFormDataChangeHandler}
            error={!!errors.storageDay}
            errorMessage={t(errors.storageDay, { field: t('calculator:daysToStore') })}
          />
        </div>
        <div>
          <PButton
            width="auto"
            onClick={addHandler}
            startIcon={<AddIcon />}
            inputtype="tonal">
            {t('calculator:add')}
          </PButton>
        </div>
      </AddContainer>
      <Box>
        <PCheckbox
          label={t('calculator:calculateByIncludingProductsFromPentaStorage')}
          checked={formData.checkbox}
          onChange={onChecboxChangeHandler}
          name="checkbox"
        />
      </Box>
      <Box>
        {tabValue === 4 && (
          <CalculatorTable
            tableData={tableData}
            calculate={calculate}
            setTableData={setTableData}
          />
        )}
      </Box>
      {descriptionValues && tableData.length > 0 && <CalculatorDescription descriptionValues={descriptionValues} />}
    </PentaStorageTabContainer>
  );
};

export default Calculator;
