import { Box, Typography, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

const Tags = styled(Box)(({ selected, clickable }) => ({
  borderRadius: '99px',
  padding: '6px 12px',
  cursor: clickable ? 'pointer' : 'default',
  border: `1px solid ${selected ? primaryColors.primary800 : primaryColors.primary700}`,
  color: selected ? primaryColors.primary50 : primaryColors.primary,
  backgroundColor: selected ? primaryColors.primary800 : 'transparent',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  height: '32px',
}));

const SelectableTags = ({ value, selected, onClick, title, rest, clickable = true }) => {
  return (
    <Tags
      clickable={+clickable}
      value={value}
      selected={selected}
      onClick={onClick}
      {...rest}>
      <Typography variant="labelLarge">{title}</Typography>
    </Tags>
  );
};

export default SelectableTags;
