import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { PTableRowCell } from 'components/simpleTable/SimpleTable';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { splitWithDot } from 'utils/HelperFunctions';
const MyShipmentsRows = ({ row, isOpenAll }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isOpenAll);
  }, [isOpenAll]);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <PTableRowCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </PTableRowCell>
        <PTableRowCell
          component="th"
          scope="row">
          {Number(row.productNumber)}
        </PTableRowCell>
        <PTableRowCell>{row.productDescription}</PTableRowCell>
        <PTableRowCell>{Number(row.referenceDocument)}</PTableRowCell>
        <PTableRowCell>{row.referenceEntry}</PTableRowCell>
        <PTableRowCell align="right">{`${splitWithDot(Math.round(row.volume), 0)} ${row.volumeType}`}</PTableRowCell>
        <PTableRowCell align="right">{`${row.quantity} ${t('myShipments:piece')} `}</PTableRowCell>
      </TableRow>
      <TableRow>
        <PTableRowCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={9}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            scrollable>
            <Box sx={{ margin: 1, width: '100%', maxHeight: 400, overflowY: 'auto' }}>
              <Table
                size="small"
                aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <PTableRowCell>
                      <b>{t('myShipments:serialNo')}</b>
                    </PTableRowCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.serialNumbers.map((detailRow, index) => (
                    <TableRow key={index}>
                      <PTableRowCell
                        component="th"
                        scope="row">
                        {detailRow.serialNumber}
                      </PTableRowCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </PTableRowCell>
      </TableRow>
    </>
  );
};

export default MyShipmentsRows;
