import { Divider, styled } from '@mui/material';
import { slateColors } from 'config/theme/Colors';

const CustomDivider = styled(Divider)(({ marginbottom }) => ({
  border: `1px solid ${slateColors.slate200}`,
  marginBottom: marginbottom,
  height: 0,
}));

const PDivider = ({ sx, marginBottom = '20px', orientation = 'vertical' }) => {
  return (
    <CustomDivider
      marginbottom={marginBottom}
      orientation={orientation}
      sx={sx}
    />
  );
};

export default PDivider;
