import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography, styled } from '@mui/material';
import { primaryColors, slateColors } from 'config/theme/Colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
const CustomLink = styled(Link)(() => ({
  color: slateColors.slate950,
  textDecoration: 'none',
}));

const CustomListItemButton = styled(ListItemButton)(() => ({
  gap: '20px',
  '&:hover': {
    borderRadius: '100px',
  },
}));

const LeftMenuItem = ({ headerText, data, headerIcon }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(true);

  const handleClick = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <>
      <CustomListItemButton
        onClick={handleClick}
        sx={{ gap: '20px' }}>
        <ListItemIcon sx={{ minWidth: 'auto' }}>{headerIcon}</ListItemIcon>
        <ListItemText>
          <Box className="d-flex aling-items-center justify-content-between">
            <Typography variant="titleMedium">{headerText}</Typography>
            {menuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </ListItemText>
      </CustomListItemButton>
      <Collapse
        in={menuOpen}
        timeout="auto"
        unmountOnExit>
        <List component="div">
          {data.map((listItem) => {
            return (
              <CustomLink
                key={listItem.id}
                to={listItem.path}>
                <ListItemButton
                  sx={{
                    pl: 15,
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    backgroundColor: location.pathname === listItem.path ? primaryColors.primary100 : 'transparent',
                    borderRadius: '100px',
                  }}>
                  <Typography variant="titleSmall">{t(listItem.title)}</Typography>
                </ListItemButton>
              </CustomLink>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
export default LeftMenuItem;
