/* eslint-disable max-len */
import { Box, Typography, styled } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { formatPrice } from 'utils/HelperFunctions';

const Container = styled(Box)(() => ({
  lineHeight: 1.6,
  width: '100%',
  padding: '5px 30px',
  fontSize: '17px',
}));

const Header = styled('h2')(() => ({
  fontSize: '1.1em',
  marginBottom: '10px',
}));

const InfoText = styled('p')(() => ({
  margin: '10px 0',
  fontSize: '1.1em',
}));

const CriteriaHeader = styled('h3')(() => ({
  margin: '20px 0 10px 0',
  fontSize: '1.1em',
}));

const CriteriaText = styled('p')(() => ({
  fontSize: '1.1em',
}));

const Signature = styled(Box)(() => ({
  fontSize: '1.1em',
  marginLeft: '10px',
}));

const Confirmation = styled(Box)(() => ({
  textAlign: 'center',
  margin: '5px 0',
  marginRight: '10px',
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const PrintableContent = React.forwardRef(({ paymentDetail }, ref) => {
  return (
    <div style={{ display: 'none' }}>
      <Container ref={ref}>
        <Header>SANAL POS KREDİ KARTI ÖDEME FORMU</Header>
        <Box>
          <InfoText>
            <strong>Tarih:</strong> {dayjs(paymentDetail.date).format('DD.MM.YYYY')}
          </InfoText>
          <InfoText>
            <strong>Müşteri Kodu:</strong> {paymentDetail.customerId}
          </InfoText>
          <InfoText>
            <strong>Müşteri:</strong> {paymentDetail.customerName}
          </InfoText>
          <InfoText>
            <strong>Kart Sahibi:</strong> {paymentDetail.cardHolder}
          </InfoText>
          <InfoText>
            <strong>Kart Numarası:</strong> {paymentDetail.cardNumber}
          </InfoText>
          <InfoText>
            <strong>Ödeme Şekli:</strong> {paymentDetail.bank}
          </InfoText>
          <InfoText>
            <strong>Tutar:</strong> {`${formatPrice(paymentDetail.amount)} TL`}
          </InfoText>
          <InfoText>
            <strong>Açıklama:</strong> {paymentDetail.description}
          </InfoText>
          <InfoText>
            <strong>{`${paymentDetail.posCommissionAmount} TL POS komisyonu daha sonradan fatura edilecektir.`}</strong>
          </InfoText>
        </Box>

        <Typography>
          {`Sanal POS ile Kredi kartımdan çekilen ${formatPrice(paymentDetail.amount)} TL tutarının ${paymentDetail.customerName} firması adına Penta Teknoloji Ürünleri
        Dağıtım Ticaret A.Ş.'ye ödemiş bulunduğumu, ilgili firma ile aramda oluşabilecek anlaşmazlıktan dolayı Penta Teknoloji Ürünleri Dağıtım
        Ticaret A.Ş.'yi sorumlu tutmayacağımı beyan ve taahhüt ederim.`}
        </Typography>

        <Box>
          <CriteriaHeader>Kriterler:</CriteriaHeader>
          <CriteriaText>
            2.BÖLÜM (Bu bölüm Penta Teknoloji Ürünleri Dağıtım Ticaret A.Ş.'nin müşterisi olan firma tarafından doldurulacaktır.) Sanal POS ile kredi
            kartından çekilen tutara kredi kartı sahibinin isteği doğrultusunda banka tarafından bloke konur ve Penta Teknoloji Ürünleri Dağıtım
            Ticaret A.Ş.'nin hesaplarına aktarılmaz ise; ilgili tutarı her türlü gecikme faizleri ile birlikte Penta Teknoloji Ürünleri Dağıtım
            Ticaret A.Ş.'nin talep ettiği tarihte nakit olarak Penta Teknoloji Ürünleri Dağıtım Ticaret A.Ş.'ye ödeyeceğimizi beyan ve taahhüt ederiz.
          </CriteriaText>
        </Box>

        <Footer>
          <Signature>
            <Typography>Müşteri İmzası:</Typography>
          </Signature>
          <Confirmation>
            <Typography>Bilgilerin doğruluğunu kabul ve tasdik eden</Typography>
            <Typography>BAYİ İMZA VE KAŞESİ</Typography>
          </Confirmation>
        </Footer>
      </Container>
    </div>
  );
});

export default PrintableContent;
