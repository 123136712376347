import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, STORAGE_API } from 'constants/Keys';

const STORAGE = '/storage';

const DELIVERIES = '/deliveries';

const GET_WAYBILL = '/get-waybill';

export const getMyShipments = ({ startDate, endDate, customerOrderNo }) =>
  httpRequest.get(`${STORAGE_API}${API}${STORAGE}${DELIVERIES}?StartDate=${startDate}&EndDate=${endDate}&CustomerOrderNo=${customerOrderNo}`);

export const getPdf = (deliveryNo) => httpRequest.get(`${STORAGE_API}${API}${STORAGE}${DELIVERIES}${GET_WAYBILL}?deliveryNo=${deliveryNo}`);
