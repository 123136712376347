import { Box } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import LoginContainer from 'layout/loginLayout/container/LoginContainer';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <LoginContainer maxWidth="lg">
      <Box className="d-flex gap-4 w100 flex-column">
        <Box>
          <h1 className="text-center">{t('aboutUs:aboutUs')}</h1>
          <PDivider marginBottom="0" />
        </Box>
        <article>
          <Trans i18nKey="aboutUs:content" />
        </article>
      </Box>
    </LoginContainer>
  );
};

export default React.memo(AboutUs);
