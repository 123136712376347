import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import PIconButton from 'components/button/PIconButton';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import TagTextField from 'components/tagTextField/TagTextField';
import PTextField from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(() => ({
  background: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  display: 'flex',
  borderRadius: '12px',
  flexDirection: 'column',
  gap: '16px',
}));

const Header = styled(Box)(() => ({
  background: '#ffffff',
  display: 'flex',
  borderRadius: '12px',
  flexDirection: 'column',
  gap: '4px',
  padding: '16px 16px 0 16px',
}));

const Content = styled(Box)(() => ({
  background: '#ffffff',
  display: 'flex',
  borderRadius: '12px',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 16px 16px 16px',
}));

const NetworkPreferences = ({
  formData,
  handleAddEmailInput,
  handleDynamicEmailDelete,
  handleDynamicEmailChange,
  dynamicEmailInput,
  onSaveHandler,
  handleInputChange,
  setFormData,
  setErrors,
  errors,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Container>
      <Header>
        <Typography variant="titleMedium">{t('integration:networkAndCommunicationPreferences')}</Typography>
        <Typography
          variant="bodyMedium"
          color={primaryColors.primary700}>
          {t('integration:enterInformationAndDescriptions')}
        </Typography>
      </Header>
      <PDivider marginBottom="0px" />
      <Content>
        <TagTextField
          maxLenght={5}
          label={t('integration:testIpAddressess')}
          tags={formData.testIpList}
          onChange={(event) => {
            const localRestrictedIps = event;
            setFormData((prevState) => ({ ...prevState, testIpList: localRestrictedIps }));
            setErrors((prevState) => ({ ...prevState, testIpList: '' }));
          }}
          placeholder={t('addUser:addIpAddresses')}
          helpertext={t('addUser:pleasePressEnterAfterEachEntry')}
          error={!!errors.testIpList}
          errorMessage={t(errors.testIpList, { field: t('integration:testIpAddressess') })}
        />
        <TagTextField
          maxLenght={5}
          label={t('integration:liveIpAddressess')}
          tags={formData.liveIpList}
          onChange={(event) => {
            const localRestrictedIps = event;
            setFormData((prevState) => ({ ...prevState, liveIpList: localRestrictedIps }));
            setErrors((prevState) => ({ ...prevState, liveIpList: '' }));
          }}
          placeholder={t('addUser:addIpAddresses')}
          helpertext={t('addUser:pleasePressEnterAfterEachEntry')}
          error={!!errors.liveIpList}
          errorMessage={t(errors.liveIpList, { field: t('integration:liveIpAddressess') })}
        />
        <Box
          className="d-flex gap-20 align-items-center"
          sx={{ width: matchesMd ? '100%' : '40%' }}>
          <PTextField
            value={formData.requiredEmail}
            onChange={handleInputChange}
            label={t('integration:emailAddressess')}
            name="requiredEmail"
            error={!!errors.requiredEmail}
            errorMessage={t(errors.requiredEmail, { field: t('integration:emailAddressess') })}
            placeholder={t('integration:emailPlaceholder')}
          />
          <PIconButton
            inputtype="tonal"
            onClick={handleAddEmailInput}>
            <AddIcon />
          </PIconButton>
        </Box>
        {dynamicEmailInput.map((emailInput, index) => (
          <Box
            className="d-flex gap-20 align-items-start"
            sx={{ width: matchesMd ? '100%' : '40%' }}>
            <PTextField
              showLabel={false}
              showHelperContainer={false}
              value={emailInput.email}
              onChange={(event) => handleDynamicEmailChange(event, index)}
              error={!!errors[`requiredEmail${index}`]}
              errorMessage={t(errors[`requiredEmail${index}`], { field: t('integration:emailAddressess') })}
              placeholder={t('integration:emailPlaceholder')}
            />
            <PIconButton
              inputtype="tonal"
              onClick={() => handleDynamicEmailDelete(index)}>
              <IconResolver iconName="DeleteOutline" />
            </PIconButton>
          </Box>
        ))}
        <Box style={{ width: matchesMd ? '100%' : 'max-content' }}>
          <InfoAlert label={t('integration:networkPreferencesInfo')} />
        </Box>
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={onSaveHandler}>
            {t('integration:save')}
          </PButton>
        </Box>
      </Content>
    </Container>
  );
};

export default NetworkPreferences;
