import { useTheme } from '@emotion/react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Typography, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PMultiSelect from 'components/select/PMultiSelect';
import PSelect from 'components/select/PSelect';
import PSelectWithIcon from 'components/select/PSelectWithIcon';
import SelectableTags from 'components/selectableTags/SelectableTags';
import { primaryColors } from 'config/theme/Colors';
import {
  deleteVariant,
  getCampaigns,
  getComponentsByTemplate,
  getConfigMasterTemplates,
  getPriceOfGivenId,
  getVariantById,
  getVariantsByTemplate,
} from 'pages/configMaster/api/ConfigMasterApi';
import ConfigMasterAccordion from 'pages/configMaster/tabs/configMaster/components/accordion/ConfigMasterAccordion';
import ConfigMasterAccordionDetails from 'pages/configMaster/tabs/configMaster/components/accordion/ConfigMasterAccordionDetails';
import ConfigMasterAccordionSummary from 'pages/configMaster/tabs/configMaster/components/accordion/ConfigMasterAccordionSummary';
import SaveVariantModal from 'pages/configMaster/tabs/configMaster/components/saveVariantModal/SaveVariantModal';
import SpecialOffer from 'pages/configMaster/tabs/configMaster/components/specialOffer/SpecialOffer';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ConfigMasterMainPart = ({
  selectedTag,
  tagSelectHandler,
  onShowInStockChange,
  justShowInStock,
  setSelectedParts,
  selectedParts,
  filteringType,
  setFilteringType,
  configsData,
  setConfigsData,
  selectedConfigs,
  setSelectedConfigs,
  components,
  setComponents,
  setConfigSummaryPart,
  setRequiredFields,
  setShowUpdateCartButton,
  setCustomerDefaultCurrency,
  setSelectedTag,
  notTriggerPrice,
  setNotTriggerPrice,
  setExpandedAccordionNameAndIds,
  expandedAccordionNameAndIds,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showNoResultMessage, setShowNoResultMessage] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [variantValue, setVariantValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [controlOfSelectedTag, setControlOfSelectedTag] = useState(null);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    if (expandedAccordionNameAndIds.length > 0 && components.pure.length > 0 && !notTriggerPrice) {
      const ids = expandedAccordionNameAndIds.flatMap((item) => item.ids);
      getPriceOfComponents(ids);
    }
    setNotTriggerPrice(false);
  }, [components.pure, expandedAccordionNameAndIds]);

  const loaderFunction = (params = null) => {
    let localComponents = null;
    setLoading(true);
    setShowNoResultMessage(false);
    getComponentsByTemplate(params)
      .then((res) => {
        if (controlOfSelectedTag !== selectedTag) {
          setConfigsData(res.data.data.configs);
          setControlOfSelectedTag(selectedTag);
        }
        setConfigSummaryPart(res.data.data.configs);
        localComponents = {
          allData: res.data.data.componentGroups,
          visibleData: res.data.data.componentGroups,
          pure: res.data.data.componentGroups,
        };
        setComponents(localComponents);
        const tempRequired = [];
        res.data.data.componentGroups.forEach((item) => {
          if (item.isRequired) {
            tempRequired.push(item.componentGroupTypeName);
          }
        });
        setRequiredFields(tempRequired);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setShowNoResultMessage(true);
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    getConfigMasterTemplates()
      .then((res) => {
        const candidateTags = res?.data?.data?.templates.map((item) => ({
          title: item,
          value: item,
        }));
        setTags(candidateTags);
        setSelectedTag(candidateTags[0].value);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedTag) {
      loaderFunction({
        template: selectedTag,
        inStock: justShowInStock,
        selectedAnswers: selectedBrands,
        selectedValues: selectedParts,
        configIds: [...selectedConfigs.map((item) => item)],
      });
    }
  }, [justShowInStock, selectedParts, selectedTag, selectedConfigs.length]);

  useEffect(() => {
    getCampaigns(selectedTag)
      .then((res) => {
        setCampaigns(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        setCampaigns([]);
      });
  }, [selectedTag]);

  const filterTypeData = useMemo(() => {
    return [
      { key: 1, value: t('configMaster:configuration') },
      { key: 2, value: t('configMaster:variant') },
    ];
  }, [t]);

  const deleteVariants = (event, id) => {
    event.stopPropagation();

    deleteVariant(id)
      .then((res) => {
        toast.success(res.data.message);
        getVariants(selectedTag);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setVariantValue('');
      });
  };

  const getVariants = (templateId) => {
    setLoading(true);
    getVariantsByTemplate(templateId)
      .then((res) => {
        const convertedVariants = res.data.data.map((item) => ({
          key: item.id,
          value: item.name,
          rightIcon: item.customerId === '*' ? <></> : <DeleteOutlineIcon onClick={(event) => deleteVariants(event, item.id)} />,
        }));
        setVariantData(convertedVariants);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeFilteringType = (event) => {
    const { value } = event.target;
    setFilteringType(value);
    setVariantValue('');
    setSelectedParts([]);
    setSelectedConfigs([]);
    setSelectedBrands([]);
    setNotTriggerPrice(true);
    if (value === 2) {
      getVariants(selectedTag);
    }
  };

  const onTagChangeHandler = (value) => {
    tagSelectHandler(value);
    setFilteringType('');
    setVariantValue([]);
    setSelectedParts([]);
    setConfigsData([]);
    setSelectedConfigs([]);
    setSelectedBrands([]);
    setNotTriggerPrice(true);
  };

  const searchByMainGroup = (event, componentGroupType) => {
    const { value } = event.target;
    const filteredData = components.allData.map((group) => {
      if (group.componentGroupType === componentGroupType) {
        return {
          ...group,
          components: group.components.filter((component) => component.name.toLocaleLowerCase('tr').includes(value.toLocaleLowerCase('tr'))),
        };
      }
      return group;
    });

    setComponents((prevState) => ({
      ...prevState,
      visibleData: filteredData,
    }));
  };

  const clearAllSelectedParts = () => {
    setSelectedParts([]);
    setVariantValue([]);
    setFilteringType('');
    setSelectedConfigs([]);
    setSelectedBrands([]);
    setExpandedAccordionNameAndIds([]);
  };

  const onVariantChangeHandler = async (event) => {
    const { value } = event.target;
    setLoading(true);
    try {
      const selectedVariant = await getVariantById(value);
      const response = await getPriceOfGivenId(selectedVariant.data.data.productIds.map((item) => item.productId));
      const priceListWithIds = response.data.data.prices;
      setCustomerDefaultCurrency(response.data.data.customerDefaultCurrency);
      const copyOfAllData = [...components.allData];
      priceListWithIds.forEach((priceItem) => {
        const productId = priceItem.productId;
        const price = priceItem.price;

        copyOfAllData.forEach((group) => {
          group.components.forEach((component) => {
            if (component.id === productId) {
              component.price = price;
              component.sapPrice = price.amountWithoutVatRate;
            }
          });
        });
      });
      const localComponents = { allData: copyOfAllData, visibleData: copyOfAllData };
      const part = selectedVariant.data.data.productIds.map(({ productId, count }) => {
        for (const group of copyOfAllData) {
          const component = group.components.find((component) => component.id === productId);
          if (component) {
            return {
              component: group.componentGroupTypeName.toUpperCase(),
              value: component.id,
              count: count,
              price: component.price,
              quantity: component.quantity,
              stock: component.stock,
              name: component.name,
              sapPrice: component.sapPrice,
              stockDisplay: component.stockDisplay,
            };
          }
        }
        return null;
      });
      setComponents((prevState) => ({ ...prevState, localComponents }));
      if (part.some((item) => !item)) {
        toast.error(t('configMaster:noComponentsFound'));
      }
      setSelectedParts(part.filter((item) => item));
    } catch (err) {
      toast.error(err.response.data.Message);
    } finally {
      setSelectedBrands([]);
      setVariantValue(value);
      setLoading(false);
    }
  };

  const onConfigChangeHandler = (event) => {
    const { value } = event.target;
    setSelectedConfigs(value);
    setSelectedParts([]);
    setSelectedBrands([]);
    setNotTriggerPrice(true);
  };

  const handleChipDelete = (itemKey) => {
    const copySelectValue = [...selectedConfigs];
    const newSelectValue = copySelectValue.filter((item) => item !== itemKey);
    setSelectedConfigs(newSelectValue);
    setSelectedParts([]);
    setSelectedBrands([]);
    setNotTriggerPrice(true);
  };

  const getPriceOfComponents = (arrayOfIds) => {
    getPriceOfGivenId(arrayOfIds)
      .then((response) => {
        const priceListWithIds = response.data.data.prices;
        setCustomerDefaultCurrency(response.data.data.customerDefaultCurrency);
        const copyOfAllData = [...components.allData];
        priceListWithIds.forEach((priceItem) => {
          const productId = priceItem.productId;
          const price = priceItem.price;
          copyOfAllData.forEach((group) => {
            group.components.forEach((component) => {
              if (component.id === productId) {
                component.price = price;
                component.image = priceItem.image;
                component.sapPrice = price.amountWithoutVatRate;
              }
            });
          });
        });
        setComponents((prevState) => ({ ...prevState, allData: copyOfAllData, visibleData: copyOfAllData }));
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const onSpecialOfferHandler = (selectedCampaigns) => {
    setFilteringType(1);
    setSelectedConfigs([selectedCampaigns.configId]);
    setSelectedParts([]);
    setSelectedBrands([]);
    setExpandedAccordionNameAndIds([]);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Box className="d-flex justify-content-between flex-wrap gap-12">
        <Box className="d-flex gap-12">
          {tags.map((tag) => {
            return (
              <SelectableTags
                value={tag.value}
                key={tag.value}
                selected={tag.value === selectedTag}
                onClick={() => tag.value !== selectedTag && onTagChangeHandler(tag.value)}
                title={t(tag.title)}
              />
            );
          })}
        </Box>
        <PButton
          inputtype="outline"
          disabled={selectedParts.length === 0}
          startIcon={<IconResolver iconName="SaveOutlined" />}
          width={matches ? '100%' : 'auto'}
          onClick={() => {
            setIsModalOpen(true);
          }}>
          {t('configMaster:saveSelectionsAsVariants')}
        </PButton>
      </Box>
      <Box className="d-flex justify-content-between flex-wrap align-items-center">
        <Box className="d-flex gap-12 flex-wrap">
          {selectedTag && (
            <>
              <PSelect
                name="filteringType"
                menuItems={filterTypeData}
                value={filteringType}
                onChange={onChangeFilteringType}
                placeholder={t('configMaster:selectFilteringType')}
                width={matches ? '100%' : '222px'}
                showLabel={false}
                showHelperContainer={false}
              />
              {filteringType === 1 && (
                <PMultiSelect
                  placeholder={t('configMaster:selectConfiguration')}
                  withGroup={false}
                  showLabel={false}
                  showHelperContainer={false}
                  handleChipDelete={handleChipDelete}
                  menuItems={configsData.map((item) => ({
                    key: item.configId,
                    value: item.configName,
                  }))}
                  value={selectedConfigs}
                  onChange={onConfigChangeHandler}
                  width={matches ? '100%' : '222px'}
                />
              )}
              {filteringType === 2 && (
                <PSelectWithIcon
                  showLabel={false}
                  menuItems={variantData}
                  value={variantValue}
                  onChange={onVariantChangeHandler}
                  placeholder={t('configMaster:selectVariant')}
                  width={matches ? '100%' : '222px'}
                />
              )}
              <PCheckbox
                name="justShowInStock"
                checked={justShowInStock}
                noWhiteSpace
                sx={{ whiteSpace: 'nowrap' }}
                label={t('configMaster:justShowInStock')}
                onChange={onShowInStockChange}
              />
            </>
          )}
        </Box>
        <Box>
          <PButton
            inputtype="outline"
            startIcon={<IconResolver iconName="DeleteOutlined" />}
            onClick={clearAllSelectedParts}
            disabled={selectedParts.length === 0 && selectedConfigs.length === 0 && selectedBrands.length === 0}
            sx={{ width: matches ? '100%' : 'auto', marginLeft: 'auto' }}>
            {t('configMaster:clear')}
          </PButton>
        </Box>
      </Box>
      <Box>
        {campaigns.length > 0 && (
          <SpecialOffer
            campaigns={campaigns}
            onSpecialOfferHandler={onSpecialOfferHandler}
          />
        )}
      </Box>
      {showNoResultMessage && components?.visibleData?.length === 0 && (
        <Box className="text-center">
          <Typography
            color={primaryColors.primary400}
            variant="titleMedium">
            {t('configMaster:noRecordFound')}
          </Typography>
        </Box>
      )}

      <Box className="d-flex flex-column gap-12">
        {components?.visibleData.map((componentGroups) => {
          return (
            <ConfigMasterAccordion
              setExpandedAccordionNameAndIds={setExpandedAccordionNameAndIds}
              setShowUpdateCartButton={setShowUpdateCartButton}
              justShowInStock={justShowInStock}
              selectedConfigs={selectedConfigs}
              key={componentGroups.componentGroupType}
              componentGroups={componentGroups}
              selectedParts={selectedParts}>
              <ConfigMasterAccordionSummary
                component={componentGroups}
                selectedParts={selectedParts}
                setSelectedParts={setSelectedParts}
                allData={components?.allData}
              />
              <ConfigMasterAccordionDetails
                setNotTriggerPrice={setNotTriggerPrice}
                selectedConfigs={selectedConfigs}
                loaderFunction={loaderFunction}
                justShowInStock={justShowInStock}
                selectedTag={selectedTag}
                componentGroups={componentGroups}
                searchByMainGroup={searchByMainGroup}
                selectedBrands={selectedBrands}
                setSelectedBrands={setSelectedBrands}
                setSelectedParts={setSelectedParts}
                selectedParts={selectedParts}
              />
            </ConfigMasterAccordion>
          );
        })}
      </Box>
      {isModalOpen && (
        <SaveVariantModal
          handleClose={setIsModalOpen}
          selectedTag={selectedTag}
          selectedParts={selectedParts}
          getVariants={getVariants}
        />
      )}
    </>
  );
};

export default React.memo(ConfigMasterMainPart);
