export const MyContractsEn = {
  myContracts: 'My Contracts',
  manageYourContractList: 'Manage your contract list',
  contractList: 'Contract List',
  piece: 'Piece',
  contractName: 'Contract Name',
  contractVersion: 'Contract Version',
  startEndDate: 'Start - End Date',
  status: 'Status',
  approvalStatus: 'Approval Status',
  searchPlaceholder: 'Search...',
  active: 'Active',
  passive: 'Passive',
  waiting: 'Waiting',
  appoved: 'Appoved',
  declined: 'Declined',
  remindLater: 'Remind Later',
};
