import { Box, styled, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import PTextField from 'components/textField/PTextField';
import PTextFieldWithMenu from 'components/textField/PTextFieldWithMenu';
import debounce from 'lodash.debounce';
import { addItemToCart, getItemByRenewalTypeAndGroupId } from 'pages/pay/api/PayApi';
import { addToCartEnums } from 'pages/pay/constants/AddToCartEnums';
import { createAnOfferValidationMessagesKeys } from 'pages/pay/pages/getAnOffer/constants/CreateAnOfferValidationMessagesKeys';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PayAddModal = ({ onClose, row, getCartCount }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    piece: 1,
    inputVisibleValue: '',
    inputValue: '',
    type: addToCartEnums.addProduct,
  });
  const [errors, setErrors] = useState({
    inputValue: '',
    piece: '',
  });

  const validateForm = () => {
    const rules = {
      inputValue: 'required',
      piece: 'required',
    };
    const validation = new Validator({ ...formData }, rules, createAnOfferValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        inputValue: validation.errors.first('inputValue'),
        piece: validation.errors.first('piece'),
      }));
    }
  };

  const onSaveHandler = () => {
    if (validateForm()) {
      const result = formData.inputVisibleValue.slice(formData.inputVisibleValue.indexOf('-') + 1);
      const sendObject = [
        {
          currency: row.currency,
          zL2Code: row.endUserNumber,
          brand: row.brand,
          subscriptionId: row.subscriptionNumber,
          type: formData.type,
          quantity: formData.piece,
          productId: formData.inputValue.id,
          productName: result.trim(),
        },
      ];
      addItemToCart(sendObject)
        .then((res) => {
          getCartCount();
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
  };

  const getSelectDataBySearchkeyFn = (searchKey) => {
    const { renewalTypeText, exMaterialGroupId } = row;
    const sendObject = {
      renewalTypeText,
      exMaterialGroupId,
      searchKey,
    };
    getItemByRenewalTypeAndGroupId(sendObject)
      .then((res) => {
        const data = res.data.data;
        const productItems = [];
        data.forEach((product) => {
          productItems.push({
            id: product.id,
            content: (
              <div>
                <Typography variant="bodyLarge">{`${product.id} - ${product.name}`}</Typography>
              </div>
            ),
            onClick: () => {
              setFormData((prevState) => ({ ...prevState, inputVisibleValue: `${product.id} - ${product.name} `, inputValue: product }));
              setErrors((prevState) => ({ ...prevState, inputValue: '' }));
            },
          });
        });
        setProducts(productItems);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const debounceFn = useCallback(debounce(getSelectDataBySearchkeyFn, 350), []);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'inputVisibleValue' && value.length >= 3) {
      debounceFn(value);
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onNumberInputChange = (event) => {
    event.stopPropagation();
    const { name, value } = event.target;
    if (value && value <= 0) {
      setFormData((prevState) => ({ ...prevState, [name]: 1 }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleOnKeyDown = (event) => {
    const k = event.which;

    if ((k < 48 || k > 57) && (k < 96 || k > 105) && k !== 8) {
      event.preventDefault();
      return false;
    }
  };

  return (
    <PCenterModal
      showClose
      width="750px"
      onClose={onClose}
      title={t('pay:addingSubscriptionProduct')}
      subTitle={t('pay:productWillBeAddedToYourSubscription')}>
      {loading && <LoadingSpinner />}
      <Box className="d-flex flex-column gap-20">
        <Content>
          <Box sx={{ flex: 5 }}>
            <PTextFieldWithMenu
              label={t('pay:productCodeOrName')}
              placeholder={t('pay:productCodeOrName')}
              name="inputVisibleValue"
              value={formData.inputVisibleValue}
              onChange={onChangeHandler}
              menuItems={products}
              error={!!errors.inputValue}
              errorMessage={t(errors.inputValue, { field: t('pay:productCodeOrName') })}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <PTextField
              label={t('pay:productQuantity')}
              placeholder={t('pay:piece')}
              name="piece"
              value={formData.piece}
              onKeyDown={handleOnKeyDown}
              onChange={onNumberInputChange}
              error={!!errors.piece}
            />
          </Box>
        </Content>
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={onSaveHandler}>
            {t('pay:add')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default PayAddModal;
