import { paths } from 'constants/Paths';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import Contact from '../Contact';

export const contactRoutes = {
  path: `${paths.account}${paths.contact}`,
  element: (
    <LoginLayout>
      <Contact />
    </LoginLayout>
  ),
};
