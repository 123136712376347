import { Box, styled, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PInfoChip from 'components/chip/PInfoChip';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import { primaryColors } from 'config/theme/Colors';
import { useNavigate } from 'react-router-dom';

const PaymentGridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PaymentTitleGridContainer = styled(Box)(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 12,
}));

const PaymentMainWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  margin: '0 16px',
  marginBottom: '16px',
}));

const PaymentBigGridItem = styled(Box)(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 8,
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
}));

const PaymentGridItemContainer = styled(Box)(({ direction = 'column' }) => ({
  display: 'flex',
  flexDirection: direction,
  gap: '8px',
  padding: '20px 16px',
}));

const PaymentSmallGridItem = styled(Box)(() => ({
  gridColumnStart: 8,
  gridColumnEnd: 11,
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
  height: 'min-content',
  paddingBottom: '18px',
}));

const PaymentSelectionContainer = styled(Box)(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 8,
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
  minHeight: '152px',
  backgroundColor: '#ffffff',
}));

const PaymentLayout = ({
  mainTitle,
  path,
  leftTitle,
  leftSubtitle,
  leftPart,
  summaryTitle,
  count,
  countLabel,
  rightPart,
  paymentTitle,
  paymentSubtitle,
  paymentPart,
  onClick = null,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(path);
    }
  };
  return (
    <>
      <PaymentGridContainer>
        <PaymentTitleGridContainer>
          <PIconButton
            inputtype="text"
            onClick={handleClick}>
            <IconResolver iconName="ChevronLeft" />
          </PIconButton>
          <Typography variant="titleLarge">{mainTitle}</Typography>
        </PaymentTitleGridContainer>
      </PaymentGridContainer>
      <PDivider />
      <PaymentMainWrapper>
        <PaymentGridContainer>
          <PaymentBigGridItem>
            <Box className="d-flex flex-column">
              <PaymentGridItemContainer>
                <Typography
                  variant="titleMedium"
                  color={primaryColors.primary}>
                  {leftTitle}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary700}>
                  {leftSubtitle}
                </Typography>
              </PaymentGridItemContainer>
              <PDivider marginBottom="0" />
              <PaymentGridItemContainer>{leftPart}</PaymentGridItemContainer>
            </Box>
          </PaymentBigGridItem>
          <PaymentSmallGridItem>
            <Box className="d-flex flex-column">
              <PaymentGridItemContainer
                className="align-items-center"
                direction="row">
                <Typography
                  variant="titleMedium"
                  color={primaryColors.primary}>
                  {summaryTitle}
                </Typography>
                <PInfoChip label={`${count} ${countLabel}`} />
              </PaymentGridItemContainer>
              <PDivider marginBottom="0" />
              {rightPart}
            </Box>
          </PaymentSmallGridItem>
        </PaymentGridContainer>
        <PaymentGridContainer>
          <PaymentSelectionContainer>
            <PaymentGridItemContainer className="d-flex flex-column">
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {paymentTitle}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {paymentSubtitle}
              </Typography>
            </PaymentGridItemContainer>
            <PDivider marginBottom="0" />
            <PaymentGridItemContainer
              direction="row"
              sx={{ flexWrap: 'wrap' }}>
              {paymentPart}
            </PaymentGridItemContainer>
          </PaymentSelectionContainer>
        </PaymentGridContainer>
      </PaymentMainWrapper>
    </>
  );
};

export default PaymentLayout;
