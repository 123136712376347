import { Box, styled, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PDivider from 'components/Divider/PDivider';
import PPasswordTextField from 'components/textField/PPasswordTextField';
import PTextField from 'components/textField/PTextField';
import { primaryColors, redColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import LoginFooter from 'layout/loginLayout/LoginFooter';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LoginAndRegistrationWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '68px',
  alignItems: 'strech',
  justifyContent: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    margin: '0 16px',
  },
}));

const RegisterNewCustomerWrapper = styled(Box)(() => ({
  gridColumnStart: 2,
  gridColumnEnd: 6,
  flex: 1,
  background: '#ffffff',
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '60px 24px 60px 24px',
  flexDirection: 'column',
}));

const UserLoginWrapper = styled(Box)(() => ({
  gridColumnStart: 8,
  gridColumnEnd: 12,
  flex: 1,
  gap: '24px',
  display: 'flex',
  flexDirection: 'column',
}));

const Forgot = styled(Link)(() => ({
  color: primaryColors.primary,
  fontSize: '14px',
  fontWeight: 500,
}));

const Login = ({ formField, errorMessage, onChangeHandler, onLoginHandler }) => {
  const { t } = useTranslation();

  return (
    <Box className="d-flex align-items-center justify-content-center">
      <LoginAndRegistrationWrapper>
        <RegisterNewCustomerWrapper>
          <Box className="d-flex flex-column gap-16">
            <Typography
              variant="headlineMedium"
              color={slateColors.slate950}>
              {t('login:newCustomer')}
            </Typography>
            <Typography
              variant="bodyLarge"
              color={slateColors.slate600}>
              {t('login:newCustomerFirstPart')}
            </Typography>
            <Typography
              variant="bodyLarge"
              color={slateColors.slate600}>
              {t('login:newCustomerSecondPart')}
            </Typography>
          </Box>
          <Box className="d-flex flex-column gap-20">
            <PButton
              component={Link}
              to={`${process.env.REACT_APP_BAYINET_URL}/Account/NewDealerForm`}>
              {t('login:onlineDealerApplicationForm')}
            </PButton>
            <PButton
              inputtype="outline"
              component={Link}
              to={`${process.env.REACT_APP_BAYINET_URL}/Account/CheckDealerRequest`}>
              {t('login:dealerApplicationStatusCheck')}
            </PButton>
          </Box>
        </RegisterNewCustomerWrapper>
        <Box
          sx={{
            gridColumnStart: 6,
            gridColumnEnd: 8,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <PDivider
            sx={{ height: '100%', width: '1px' }}
            marginBottom="0"
            orientation="horizontal"
          />
        </Box>
        <UserLoginWrapper>
          <Box className="d-flex flex-column gap-8">
            <Typography
              variant="headlineSmall"
              color={slateColors.slate950}>
              {t('login:login')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={slateColors.slate600}>
              {t('login:privileges')}
            </Typography>
          </Box>
          <Box className="d-flex flex-column gap-8">
            <PTextField
              value={formField.sapCustomerId}
              label={t('login:customerCode')}
              name="sapCustomerId"
              onChange={onChangeHandler}
            />
            <PTextField
              value={formField.email}
              label={t('login:emailOrPhone')}
              name="email"
              onChange={onChangeHandler}
            />
            <PPasswordTextField
              value={formField.password}
              label={t('login:password')}
              name="password"
              onChange={onChangeHandler}
            />
          </Box>
          <Box className="text-end">
            <Forgot to={paths.forgetPassword}>{t('login:forgotPassword')}</Forgot>
          </Box>
          <Box>
            <PButton onClick={onLoginHandler}>{t('login:login')}</PButton>
            {errorMessage && (
              <Typography
                variant="labelLarge"
                color={redColors.red600}>
                {t(errorMessage)}
              </Typography>
            )}
          </Box>
          <Box>
            <LoginFooter />
          </Box>
        </UserLoginWrapper>
      </LoginAndRegistrationWrapper>
    </Box>
  );
};

export default Login;
