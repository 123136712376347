import { Box, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

const Card = styled(Box)(({ background }) => ({
  backgroundColor: background ? background : '#ffffff',
  color: primaryColors.primary800,
  width: '100%',
  height: '100%',
  padding: 12,
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '8px',
}));

const PentaStorageCards = ({ children, background }) => {
  return <Card background={background}>{children}</Card>;
};

export default PentaStorageCards;
