import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, COMMON_API } from 'constants/Keys';

const CONTRACT_ACTION = '/contract-action';

export const buttonActionRequest = (buttonId, contractId) =>
  httpRequest.post(`${COMMON_API}${API}${CONTRACT_ACTION}`, {
    buttonId,
    contractId,
  });
