import { useTheme } from '@emotion/react';
import { Box, Tab, useMediaQuery } from '@mui/material';
import PCenterModal from 'components/modal/PCenterModal';
import TabPanel from 'components/tabs/PTabPanel';
import PTabs from 'components/tabs/PTabs';
import BillingHistory from 'pages/pay/tabs/subscriptionDetailInPay/components/BillingHistory';
import TransactionHistory from 'pages/pay/tabs/subscriptionDetailInPay/components/TransactionHistory';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DetailModal = ({ onClose, row }) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const getDynamicWidth = () => {
    if (matchesSm) {
      return '300px';
    } else if (matchesMd) {
      return '500px';
    } else {
      return 'auto';
    }
  };

  return (
    <PCenterModal
      showClose
      width="1142px"
      onClose={onClose}
      title={t('pay:productHistoryNoParams', { number: row.productNumber })}>
      <Box
        className="d-flex flex-column gap-16"
        sx={{ width: getDynamicWidth() }}>
        <Box>
          <PTabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto">
            <Tab label={t('pay:billingHistory')} />
            <Tab label={t('pay:transactionHistory')} />
          </PTabs>
        </Box>
        <Box>
          <TabPanel
            value={tabValue}
            index={0}>
            <BillingHistory row={row} />
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={1}>
            <TransactionHistory row={row} />
          </TabPanel>
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default DetailModal;
