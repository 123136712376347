import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography, styled } from '@mui/material';
import { primaryColors, redColors } from 'config/theme/Colors';
const ErrorContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${redColors.red300}`,
  backgroundColor: redColors.red50,
  padding: '10px 12px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  minHeight: '40px',
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    flexDirection: 'column',
  },
}));

const ErrorAlert = ({ label }) => {
  return (
    <ErrorContainer>
      <InfoOutlinedIcon sx={{ color: redColors.red600 }} />
      <Typography
        variant="bodyMedium"
        color={primaryColors.primary}>
        {label}
      </Typography>
    </ErrorContainer>
  );
};
export default ErrorAlert;
