/* eslint-disable quotes */
/* eslint-disable max-len */
export const PentaIntegrationEn = {
  pentaIntegration: 'Penta Integration',
  makeYourWorkflowMoreEfficientByIntegratingDifferentServices: 'Make your workflow more efficient by integrating different services',
  productServices: 'Product Services',
  orderServices: 'Order Services',
  storageServices: 'Penta Storage Services',
  subscriptionManagementServices: 'Penta Subscription Management Services',
  configurationPreferences: 'Configuration Preferences',
  capitalIntegrations: 'INTEGRATIONS',
  capitalManagement: 'MANAGEMENT',
  integration: 'Integration',
  transactionHistory: 'Transaction History',
  developerLink: 'Developer Page',
  clickToGetDetailedInformationAboutProductServicesIntegration: 'Click to get detailed information about Product Services Integration.',
  activeRequest: 'Active Request',
  piece: 'Piece',
  pastRequests: 'Past Requests',
  searchPlaceholder: 'Search...',
  requestNumber: 'Request Number',
  requester: 'Requester',
  requestDateAndTime: 'Request Date and Time',
  status: 'Status',
  request: 'Request',
  dateAndTime: 'Date and Time',
  httpRequest: 'Request',
  httpResponse: 'Response',
  result: 'Result',
  clickToGetDetailedInformationAboutOrderServicesIntegration: 'Click to get detailed information about Order Services Integration.',
  clickToGetDetailedInformationAboutStorageServicesIntegration: 'Click to get detailed information about Penta Storage Services Integration.',
  clickToGetDetailedInformationAboutPayServicesIntegration:
    'Click to get detailed information about Penta Subscription Management Services Integration.',
  thereIsNoActiveRequestYet: 'There is no active request yet.',
  pleaseCreateANewRequest: 'Please create a new request.',
  createRequest: 'Create Request',
  createProductServiceIntegrationTestRequest: 'Create Product Service Integration Test Request',
  createOrderServiceIntegrationTestRequest: 'Create Order Service Integration Test Request',
  createPayServiceIntegrationTestRequest: 'Create Penta Subscription Management Service Integration Test Request',
  createStorageServiceIntegrationTestRequest: 'Create Storage Service Integration Test Request',
  testIpAddressess: 'Test IP Addresses',
  description: 'Description',
  enterDescription: 'Enter description',
  emailAddressess: 'E-mail Addressess',
  create: 'Create',
  thisFieldIsRequired: 'Please enter {{field}}.',
  theEmailFormatIsInvalid: 'E-mail format is invalid.',
  emailPlaceholder: 'Add e-mail address',
  ipAddresses: 'IP Addresses',
  emailAddresses: 'E-mail addresses',
  integrationRequestNumber: 'Integration request number {{number}}',
  ipAddressesWithNumber: '{{number}} IP addresses',
  emailAddressesWithNumber: '{{number}} E-mail addresses',
  completeTheTest: 'Complete the Test',
  goLive: 'Go Live',
  testSecretKey: 'Test Secret Key',
  liveSecretKey: 'Live Secret Key',
  thereIsNoSecretKey: 'There is no secret key.',
  secretKeyCreated: 'Secret key created.',
  changeKey: 'Change Key',
  liveIpAddressess: 'Live IP Addresses',
  save: 'Save',
  networkAndCommunicationPreferences: 'Network and Communication Preferences',
  enterInformationAndDescriptions: 'Enter information and descriptions',
  testCompleted: 'Test completed',
  requestCanceled: 'Request canceled',
  createProductServiceIntegrationLiveRequest: 'Create Product Service Integration Live Request',
  createOrderServiceIntegrationLiveRequest: 'Create Order Service Integration Live Request',
  createPayServiceIntegrationLiveRequest: 'Create Penta Subscription Management Service Integration Live Request',
  createStorageServiceIntegrationLiveRequest: 'Create Storage Service Integration Live Request',
  productInfoFirstItem:
    'This service includes basic information such as the category, price, stock status, brand, warranty period, and subscription products of the products, as well as detailed information such as images, technical specifications, dimensions, and weight. ',
  orderInfoFirstTitle: 'CreateOrder – Create Order & Change Order & Delete Order ',
  orderInfoFirstItem:
    'Using the CreateOrder method, automatic order records can be made into the Penta Teknoloji ERP system. The same method can also be used for order chage and delete processes. Order change and delete are applicable for orders that have not been invoiced and where the goods have not been dispatched yet. Requests must be sent via the POST method. ',
  orderInfoSecondTitle: 'GetOrders - Order Tracking and Inquiry Service',
  orderInfoSecondItem: 'Using the GetOrders method, you can access information about orders in the Penta Teknoloji ERP system',
  storageInfoFirstTitle: 'CreateOrder2890 - PentaDepom Order Service',
  storageInfoFirstItem:
    "This service allows products in the PentaDepom application delivered to the selected address. For this service to be used, the PentaDepom agreement must be signed, and the firm's permission must be active.",
  storageInfoSecondTitle: 'Get2890Product - PentaDepom Product Information Service',
  storageInfoSecondItem:
    "This service retrieves information about the products in the PentaDepom application. To use this service, the PentaDepom agreement must be signed, and the firm's permission must be active. ",
  storageInfoThirdTitle: 'GetDelivery2890 - PentaDepom Order Delivery Tracking Service (POST)',
  storageInfoThirdItem:
    'After sending products in the PentaDepom application, this service allows the tracking and retrieval of delivery statuses and information. All orders between two dates can be listed, or the related order can be displayed using the Customer Order Number parameter sent during a previous order. ',
  payInfoFirstTitle: 'GetContract',
  payInfoFirstItem: 'A service that provides a list which contains all subscriptions and subscription details related to the customer.',
  payInfoSecondTitle: 'ContractChange',
  payInfoSecondItem:
    'A service that performs subscription changes such as subscription cancellation, adding products, renewal, increasing/decreasing product quantities, and stopping products.',
  payInfoThirdTitle: 'UpcomingRenewals Service',
  payInfoThirdItem: 'A service that provides a list of items that need to be renewed within a specified number of days.',
  ipAddress: 'IP Address',
  serviceName: 'Service Name',
  nameRequestDetail: '{{name}} Request Detail',
  requestInformation: 'Request Information',
  requestResult: 'Request Result',
  networkPreferencesInfo: 'You must enter your E-mail address to follow the integration process.',
  keyCopied: 'Key copied.',
};
