import MoneyIcon from '@mui/icons-material/Money';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import PClientTable from 'components/table/PClientTable';
import UnitInput from 'components/unitInput/UnitInput';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { splitWithDot } from 'utils/HelperFunctions';
import SerialNumberModal from './components/SerialNumberModal';

const AllProductsTable = ({ tableData, inputChange, addToCart }) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState(null);
  const [selectedRowHeader, setSelectedRowHeader] = useState(null);

  const columns = useMemo(() => {
    return [
      {
        field: 'productNumber',
        headerName: t('productsInStorage:productNumber'),
        minWidth: 164,
        disableColumnMenu: true,
        renderCell: ({ row }) => Number(row.productNumber),
      },
      {
        field: 'orderId',
        headerName: t('productsInStorage:orderNo'),
        minWidth: 160,
        disableColumnMenu: true,
        renderCell: ({ row }) => Number(row.orderId),
      },
      {
        field: 'entryNumber',
        headerName: t('productsInStorage:entryNo'),
        minWidth: 120,
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
      },
      {
        field: 'storagePlace',
        headerName: t('productsInStorage:storage'),
        minWidth: 120,
        disableColumnMenu: true,
      },
      {
        field: 'shortDesc',
        headerName: t('productsInStorage:itemName'),
        minWidth: 260,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'netPrice',
        headerName: t('productsInStorage:unitPrice'),
        width: 160,
        disableColumnMenu: true,
        headerAlign: 'right',
        align: 'right',
        renderCell: ({ row, value }) => (
          <UnitInput
            value={value}
            unit={row.currency}
          />
        ),
      },
      {
        field: 'age',
        headerName: t('productsInStorage:stockAge'),
        minWidth: 124,
        headerAlign: 'right',
        align: 'right',
        disableColumnMenu: true,
      },
      {
        field: 'count',
        headerName: t('productsInStorage:piece'),
        minWidth: 120,
        headerAlign: 'right',
        align: 'right',
        disableColumnMenu: true,
        renderCell: ({ value }) => splitWithDot(value, 0),
      },
      {
        field: 'manufacturerSerialNumber',
        headerName: t('productsInStorage:serialNo'),
        minWidth: 100,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
          if (row.manufacturerSerialNumber.length > 0) {
            return (
              <MoneyIcon
                className="cursor-pointer"
                onClick={() => {
                  setModalData(row.manufacturerSerialNumber);
                  setSelectedRowHeader(Number(row.productNumber));
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
      {
        field: 'id',
        headerName: '',
        minWidth: 150,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <InlineEditNumberInput
            disabled={row.count <= 0}
            value={row.amount}
            showIcon
            onChange={(value) => inputChange(value, row)}
            onIconClick={() => addToCart(row)}
          />
        ),
      },
    ];
  }, [t, tableData, inputChange, addToCart]);

  return (
    <>
      <PClientTable
        headerTitle={<b>{t('productsInStorage:allProducts')}</b>}
        rows={tableData.visibleData}
        columns={columns}
        getRowHeight={() => 'auto'}
      />
      {modalData && (
        <SerialNumberModal
          onClose={() => setModalData(null)}
          allData={modalData}
          selectedRowHeader={selectedRowHeader}
        />
      )}
    </>
  );
};

export default AllProductsTable;
