import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import PentaStorageContainer from 'pages/pentaStorage/PentaStorageContainer';
import StorageIndex from 'pages/pentaStorage/storageIndex/StorageIndex';
import CreateBulkOrder from 'pages/pentaStorage/Tabs/productsInStorage/bulkOrder/CreateBulkOrder';
import ViewBulkOrder from 'pages/pentaStorage/Tabs/productsInStorage/bulkOrder/ViewBulkOrder';

export const pentaStorageRoutes = [
  {
    path: `${paths.storage}${paths.main}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PentaStorageContainer />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.storage}${paths.viewBulkOrder}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <ViewBulkOrder />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.storage}${paths.createBulkOrder}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <CreateBulkOrder />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.storage}${paths.index}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <StorageIndex />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
];
