import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { blueColors, primaryColors, redColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const BasketOptions = styled(Box)(({ theme, backgroundColor, borderColor, width = 'auto' }) => ({
  flexShrink: 0,
  color: primaryColors.primary700,
  border: `1px solid ${borderColor}`,
  borderRadius: '8px',
  minHeight: '64px',
  backgroundColor,
  width: '304px',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '231px',
  },
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

const SmallTag = styled(Box)(({ backgroundColor }) => ({
  backgroundColor,
  padding: '2px 12px',
  borderRadius: '24px',
  flexDirection: 'column',
  gap: '4px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
}));

const CartBasket = ({ basketOption, onClick, borderColor, backgroundColor, onBasketUpdateHandler, onBasketDeleteHandler }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const basketUpdateHandler = (event) => {
    onBasketUpdateHandler(event, basketOption.id, basketOption.cartName);
    handleClose();
  };

  const basketDeleteHandler = (event) => {
    onBasketDeleteHandler(event, basketOption.id);
    handleClose();
  };

  return (
    <BasketOptions
      key={basketOption.id}
      onClick={onClick}
      borderColor={borderColor}
      backgroundColor={backgroundColor}>
      <Box className="d-flex">
        <Typography
          variant="labelMedium"
          color={primaryColors.primary800}>
          {dayjs(basketOption.createdDate).format('DD.MM.YYYY')}
        </Typography>
      </Box>
      <Box className="d-flex align-items-center justify-content-between">
        <div className="d-flex gap-4">
          <Typography
            sx={{
              wordBreak: 'break-word',
            }}
            variant="titleMedium"
            color={primaryColors.primary}>
            {basketOption.cartName}
          </Typography>
          <SmallTag backgroundColor={basketOption.isCurrentlyUsing ? blueColors.blue200 : primaryColors.primary300}>
            <Typography
              variant="labelLarge"
              color={basketOption.isCurrentlyUsing ? blueColors.blue950 : primaryColors.primary}>
              {t('storageCart:common')}
            </Typography>
          </SmallTag>
        </div>
        {matchesSm && (
          <Box>
            <Box className="gap-4">
              <MoreVertIcon onClick={handleClick} />
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}>
                <MenuItem onClick={basketUpdateHandler}>
                  <ListItemIcon>
                    <ModeOutlinedIcon sx={{ color: primaryColors.primary }} />
                  </ListItemIcon>
                  <Typography variant="labelLarge">{t('userManagement:edit')}</Typography>
                </MenuItem>
                <MenuItem onClick={basketDeleteHandler}>
                  <ListItemIcon>
                    <DeleteOutlineOutlinedIcon sx={{ color: redColors.red500 }} />
                  </ListItemIcon>
                  <Typography variant="labelLarge">{t('userManagement:delete')}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        )}
      </Box>
    </BasketOptions>
  );
};

export default CartBasket;
