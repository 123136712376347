export const AddUserTr = {
  addUser: 'Kullanıcı Ekle',
  basicKnowledge: 'Temel Bilgiler',
  name: 'Adı',
  surname: 'Soyadı',
  email: 'E-posta',
  password: 'Şifre',
  mobile: 'Mobil',
  phone: 'Telefon',
  optional: 'opsiyonel',
  userExpirationDate: 'Kullanıcı geçerlilik tarihi',
  profitability: 'Karlılık',
  valueCanBeEnteredInFormat: '{{number}} formatında değer girilebilir',
  activeUser: 'Aktif kullanıcı',
  callCenterService: 'Çağrı merkezi hizmeti',
  permissions: 'İzinler',
  thereAreNoPermissionsAddedYet: 'Henüz eklenen bir izin yok.',
  managePermissions: 'İzinleri Yönet',
  saveUser: 'Kullanıcıyı Kaydet',
  saveAsTemplate: 'Şablon Olarak Kaydet',
  priceDefinitions: 'Fiyat Tanımlamaları',
  userPermissions: 'Kullanıcı Yetkileri',
  allowedIpAddresses: 'İzin verilen IP adresleri',
  applyPermissions: 'İzinleri Uygula',
  pleasePressEnterAfterEachEntry: 'Her bir IP adresinden sonra eklemek için “Enter” tuşuna basın',
  templateName: 'Şablon adı',
  cancel: 'İptal',
  save: 'Kaydet',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  theEmailFormatIsInvalid: 'E-posta biçimi geçersiz.',
  permissionsAdded: '{{number}} izin eklendi',
  selectTemplate: 'Şablon seç',
  yes: 'Evet',
  phoneNumberChangeIsAwaitingApproval: 'Telefon numarası değişikliği onay beklemektedir.',
  emailAddressChangeIsAwaitingApproval: 'E-posta adresi değişikliği onay beklemektedir.',
  'Permissions.User.Management': 'Kullanıcı Yönetimi',
  'Permissions.Storage.Management': 'Penta Depom',
  'Permissions.DeliveryAddress.DeliveryAddressView': 'Teslimat Adresleri Ekleme/Güncelleme',
  'Permissions.DeliveryAddress.AddBulkDeliverAddress': 'Toplu Adres Ekleme',
  'Permissions.ConfigMaster.ConfigMasterView': 'Penta Config Master',
  enterUserBasicInformations: 'Kullanıcının temel bilgilerini girin',
  emailPlaceholder: 'E-posta adresinizi giriniz',
  datepickerPlaceholder: 'Bir tarih seçin',
  manageUserPermissionsInfo: 'Kullanıcının izin bilgilerini yönetin',
  ipAddress: 'IP adresi',
  addIpAddresses: 'IP adreslerini ekle',
  'Permissions.PentaSubscriptionManagement': 'Penta Abonelik Yönetimi',
  MaxLength: '{{field}} alanı maksimum {{char}} karakterdir.',
  'Permissions.Integration.All': 'Entegrasyon',
  'Permissions.ContractPerson.ContractPersonCrud': 'İlgili Kişiler',

  'B2B.3DÖdemeYetki.Process': '3D Ödeme Talebi Yetkisi',
  'B2B.BankAccount.Process': 'Müşteri Banka Hesapları İşlemleri',
  'B2B.BayiFiyat.View': 'Bayi Fiyatı Görünsün',
  'B2B.CariHesapHareketleri.Process': 'Cari Hesap Hareketleri',
  'B2B.Customer.Activity': 'Müşteri Toplantı Aktiviteleri ',
  'B2B.EOdeme.Process': 'E Ödeme',
  'B2B.EOdeme.Promotion': 'Promosyon Ödeme',
  'B2B.FarkliAdreseSevk.Process': 'Teslimat Adresleri Ekleme/Güncelleme',
  'B2B.Kampanyalar.Process': 'Kampanyalar',
  'B2B.KrediTalep.Process': 'Kredi Talep Etme ve Durumunu Görüntüleme',
  'B2B.MarketPlace.View': 'MarketPlace Yetkileri',
  'B2B.MusteriHizmetleri.Process': 'Müşteri Hizmetleri',
  'B2B.OdemeListesi.Process': 'E-Ödeme Listesi',
  'B2B.Outlet.Process': 'Outlet',
  'B2B.OzelFiyat.View': 'Özel Fiyatlar Görünsün',
  'B2B.Ödemeİptal.Process': 'Ödeme İptal Talebi Yetkisi',
  'B2B.Project.Create': 'Fiyat Talepleri Oluşturma',
  'B2B.Project.Order': 'Fiyat Talepleri Sipariş Verme',
  'B2B.Project.View': 'Fiyat Talepleri Görüntüleme',
  'B2B.SakliKart.Process': 'Saklı Kart Kullanımı',
  'B2B.SiparisKayit.Process': 'Sepet&Sipariş',
  'B2B.SiparisListesi.Process': 'Sipariş Listesi',
  'B2B.SiparisOnay.Process': 'Sipariş Onayı',
  'B2B.SKFiyat.View': 'SK Fiyatı Görünsün',
  'B2B.StokMiktari.View': 'Stok Miktarı Görme Yetkisi.',
  'B2B.TeknikServisIslemleri.Process': 'Teknik Servis İşlemleri',
};
