import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, styled, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableHeaderCell, TableHeaderRow } from 'components/table/customTable/PTableHeader';
import { HeaderRightSide, TableHeader } from 'components/table/PClientTable';
import PPagination from 'components/table/PPagination';
import { primaryColors } from 'config/theme/Colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StorageCostsDetailRow from './StorageCostsDetailRow';
import StorageCostsDetailTableCell from './StorageCostsDetailTableCell';
const Footer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));
const ListContainer = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  border: `1px solid ${primaryColors.primary200}`,
}));

const StorageCostsDetailCollapsableTable = ({ rows, headers, totalCount, menuItems, handlePageChange, page, pageSize, handleChange }) => {
  const { t } = useTranslation();
  const [openAll, setOpenAll] = useState(false);

  const openCloseAll = () => {
    setOpenAll((prevState) => !prevState);
  };

  return (
    <ListContainer>
      <TableHeader>
        <HeaderRightSide>
          <Box className="d-flex gap-12 align-items-center">
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('storageCost:shippingCosts')}
            </Typography>
          </Box>
        </HeaderRightSide>
      </TableHeader>
      <TableContainer sx={{ height: '400px' }}>
        <Table
          stickyHeader
          aria-label="collapsible table">
          <TableHead>
            <TableHeaderRow>
              <TableHeaderCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={openCloseAll}>
                  {openAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </TableHeaderCell>
              {headers.map((header) => (
                <StorageCostsDetailTableCell
                  key={header.name}
                  header={header}
                />
              ))}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {(pageSize > 0 ? rows.slice(page * pageSize, page * pageSize + pageSize) : rows).map((row) => (
              <StorageCostsDetailRow
                key={row.id}
                row={row}
                isOpenAll={openAll}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Footer>
        <PPagination
          page={page + 1}
          handlePageChange={handlePageChange}
          menuItems={menuItems}
          handlePageSizeChange={handleChange}
          pageSize={pageSize}
          totalCount={totalCount}
        />
      </Footer>
    </ListContainer>
  );
};

export default StorageCostsDetailCollapsableTable;
