export const StorageCartEn = {
  stockCode: 'Stock Code',
  product: 'Product',
  orderNo: 'Order No',
  penNo: 'Pen No',
  stockStatus: 'Stock Status',
  amount: 'Amount',
  delete: 'Delete',
  default: 'Default',
  common: 'Common',
  individual: 'Individual',
  updateCart: 'Update Cart',
  createDelivery: 'Create Delivery',
  removeSelectedRow: 'Are you sure to remove ?',
  cartName: 'Cart Name',
  cancel: 'Cancel',
  save: 'Save',
  cannotSendMoreThanTheStock: 'Can not send more than the stock',
  thereAreNoProductsInYourCart: 'There are no products in your cart.',
  selectStorage: 'Select storage',
  thereAreItemsFromDifferentStorage: 'Since there are items from different warehouses in your cart, delivery must be created separately.',
  storageNo: 'storage no',
  cannotShipMoreThanStorageStock: 'You cannot ship more than your warehouse stock!',
  itemsInBasket: 'Items in Basket',
  piece: 'Piece',
  serialNo: 'Serial No',
};
