export const AddStaticTemplateTr = {
  generalManager: 'Genel Müdür',
  missionVission: 'Misyon Vizyon',
  policy: 'Politika',
  whichPage: 'Referans Sayfa',
  turkishContent: 'Türkçe İçerik',
  englishContent: 'İngilizce İçerik',
  save: 'Kaydet',
  savedSuccessfully: 'Başarıyla Kaydedildi',
  updatedSuccessfully: 'Başarıyla Güncellendi',
};
