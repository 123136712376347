import styled from '@emotion/styled';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PInfoChip from 'components/chip/PInfoChip';
import { HeaderRightSide, TableHeader } from 'components/table/PClientTable';
import { primaryColors } from 'config/theme/Colors';

const CustomTableContainer = styled(Box)(({ showfooter, showborder }) => ({
  border: showborder ? `1px solid ${primaryColors.primary300}` : 0,
  borderRadius: showfooter ? '12px' : '0px',
}));

export const PTableHeaderCell = styled(TableCell)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  color: primaryColors.primary,
  outline: 'none !important',
  background: primaryColors.primary50,
  height: '48px',
  lineHeight: '16px !important',
  letterSpacing: '0.5px',
  maxHeight: '48px',
  minHeight: '48px',
}));

export const PTableRowCell = styled(TableCell)(() => ({
  color: primaryColors.primary900,
  padding: '0 16px',
  whiteSpace: 'normal',
  wordSpacing: '5px',
  margin: '8px 0',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.25px',
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '6px 16px',
  borderRadius: '0 0 12px 12px',
  backgroundColor: '#ffffff',
}));

const SimpleTable = ({
  rows,
  columns,
  showHeader = true,
  headerTitle = '',
  dataLength = null,
  headerRightSide = null,
  pagination,
  showFooter = false,
  showBorder = true,
}) => {
  return (
    <CustomTableContainer
      showfooter={+showFooter}
      showborder={+showBorder}>
      <TableContainer>
        {showHeader && (
          <TableHeader>
            <HeaderRightSide>
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {headerTitle}
              </Typography>
              {dataLength && <PInfoChip label={dataLength} />}
            </HeaderRightSide>
            {headerRightSide}
          </TableHeader>
        )}
        <Table>
          <TableHead>
            <TableRow>{columns}</TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
      {showFooter && <Footer>{pagination}</Footer>}
    </CustomTableContainer>
  );
};
export default SimpleTable;
