const { CircularProgress, Backdrop } = require('@mui/material');

const LoadingSpinner = () => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 999999999 }}
      open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingSpinner;
