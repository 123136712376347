import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import AddUserContainer from 'pages/addUser/AddUserContainer';

export const addUserRoutes = [
  {
    path: `${paths.userManagement}${paths.addUserManagement}`,
    element: (
      <ProtectedRoute path={`${paths.userManagement}${paths.userManagement}`}>
        <ChildLayout>
          <AddUserContainer />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.userManagement}${paths.addUserManagement}/:userId`,
    element: (
      <ProtectedRoute path={`${paths.userManagement}${paths.userManagement}`}>
        <ChildLayout>
          <AddUserContainer />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
];
