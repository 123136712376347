import { Box, Tab, Typography } from '@mui/material';
import PInfoChip from 'components/chip/PInfoChip';
import IconResolver from 'components/iconResolver/IconResolver';
import TabPanel from 'components/tabs/PTabPanel';
import PTabs from 'components/tabs/PTabs';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { MarginContainer } from 'layout/container/MarginContainer';
import { tabNumbers } from 'pages/configMaster/constants/TabNumbers';
import OrderDetail from 'pages/configMaster/orderDetail/OrderDetail';
import ConfigMaster from 'pages/configMaster/tabs/configMaster/ConfigMaster';
import ConfigMasterCart from 'pages/configMaster/tabs/configMasterCart/ConfigMasterCart';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfigMasterContainer = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [cartItemNumber, setCartItemNumber] = useState(0);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  useEffect(() => {
    setCartItemNumber(0);
  }, []);

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      {!showOrderDetail && (
        <MarginContainer>
          <Box className="d-flex align-items-center justify-content-between">
            <Box className="flex-column">
              <Typography
                variant="titleLarge"
                sx={{ color: neutralColors.neutral950 }}>
                {t('configMaster:pentaConfigMaster')}
              </Typography>
              <Typography
                variant="bodyMedium"
                sx={{ color: primaryColors.primary700 }}>
                {t('configMaster:configureAndOrderTheHardwareYouWant')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <PTabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ padding: ' 20px 0' }}>
              <Tab
                iconPosition="start"
                icon={
                  <IconResolver
                    iconName="LocalOfferOutlined"
                    fontSize="small"
                  />
                }
                label={t('configMaster:configMaster')}
              />
              <Tab
                label={
                  <div className="d-flex align-items-center gap-8">
                    <IconResolver
                      iconName="ShoppingCartOutlined"
                      fontSize="small"
                    />
                    {t('configMaster:configMasterCart')}
                  </div>
                }
                iconPosition="end"
                icon={<PInfoChip label={`${cartItemNumber}`} />}
              />
            </PTabs>
          </Box>
          <Box>
            <TabPanel
              value={tabValue}
              index={tabNumbers.configMaster}>
              <ConfigMaster setTabValue={setTabValue} />
            </TabPanel>
            <TabPanel
              value={tabValue}
              index={tabNumbers.configMasterCart}>
              <ConfigMasterCart
                setShowOrderDetail={setShowOrderDetail}
                tabValue={tabValue}
                setCartItemNumber={setCartItemNumber}
              />
            </TabPanel>
          </Box>
        </MarginContainer>
      )}
      {showOrderDetail && (
        <OrderDetail
          showOrderDetail={showOrderDetail}
          setShowOrderDetail={setShowOrderDetail}
        />
      )}
    </>
  );
};
export default ConfigMasterContainer;
