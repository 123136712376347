import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import { Container, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { greenColors, primaryColors, redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { SpecialPageContainer } from 'layout/container/MarginContainer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { approveGoogleAuth } from './api/LoginApi';

const ApproveGoogleAuthMail = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = searchParams.get('token');
    setLoading(true);
    approveGoogleAuth({ token })
      .then(() => setStatus(true))
      .catch((err) => {
        setStatus(false);
        setErrorMessage(err.response.data.Message || 'Bilinmeyen bir hata oluştu');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (status === true) {
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            navigate(paths.home); // Login sayfasına yönlendir
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [status, navigate]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {status !== null && (
        <Container
          maxWidth="md"
          sx={{ marginTop: '94px' }}>
          <SpecialPageContainer
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}>
            {status === true ? (
              <>
                <CancelIcon
                  fontSize="large"
                  sx={{ color: greenColors.green600 }}
                />
                <Typography
                  variant="titleLarge"
                  color={primaryColors.primary}>
                  {t('login:googleAuthApproved')}
                </Typography>
                <Typography
                  variant="body1"
                  color={primaryColors.secondary}>
                  {countdown} saniye içinde giriş sayfasına yönlendiriliyorsunuz...
                </Typography>
              </>
            ) : (
              <>
                <CancelIcon
                  fontSize="large"
                  sx={{ color: redColors.red600 }}
                />

                <Typography
                  variant="body1"
                  color={primaryColors.secondary}>
                  {errorMessage}
                </Typography>
                <PButton
                  onClick={() => navigate(paths.home)}
                  startIcon={<ArrowBackIcon />}
                  variant="contained"
                  color="primary">
                  Giriş Sayfasına Dön
                </PButton>
              </>
            )}
          </SpecialPageContainer>
        </Container>
      )}
    </>
  );
};

export default ApproveGoogleAuthMail;
