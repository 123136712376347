/*eslint-disable*/

export const LoginAppBarEn = {
  aboutUs: 'About Us',
  messageFromTheGeneralManager: "General Manager's Message",
  missionVision: 'Mission/Vision',
  ourPolicies: 'Policies',
  contact: 'Contact',
  relatedPersons: 'Related Persons',
};
