import LinkIcon from '@mui/icons-material/Link';
import { Box, styled, Typography } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import PButton from 'components/button/PButton';
import PErrorChip from 'components/chip/PErrorChip';
import PInfoChip from 'components/chip/PInfoChip';
import PSuccessChip from 'components/chip/PSuccessChip';
import IconResolver from 'components/iconResolver/IconResolver';
import PLazyLoadingTable from 'components/table/PLazyLoadingTable';
import { blueColors } from 'config/theme/Colors';
import { INTEGRATION_HISTORY_URL } from 'pages/pentaIntegration/api/IntegrationApi';
import ActiveRequest from 'pages/pentaIntegration/components/activeRequest/ActiveRequest';
import StorageInfoModal from 'pages/pentaIntegration/components/modals/StorageInfoModal';
import { integrationServiceEnum } from 'pages/pentaIntegration/constants/IntegrationServiceEnum';
import { integrationStatusEnum } from 'pages/pentaIntegration/constants/IntegrationStatusEnum';
import NoRowsFound from 'pages/pentaIntegration/constants/NoRowsFound';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const InfoAndDev = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '16px',
  '&>*:nth-of-type(1)': {
    flex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '&>*': {
      flex: 1,
      width: '100%',
    },
  },
}));

const StorageServicesIntegration = ({ activeRequest, getActiveRequest }) => {
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const { t } = useTranslation();
  const tableRef = useRef();

  const columns = useMemo(() => {
    return [
      {
        field: 'processNo',
        headerName: t('integration:requestNumber'),
        minWidth: 256,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'sapCustomerName',
        headerName: t('integration:requester'),
        minWidth: 256,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'createdDate',
        headerName: t('integration:requestDateAndTime'),
        minWidth: 256,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'statuName',
        headerName: t('integration:status'),
        minWidth: 260,
        flex: 1,
        disableColumnMenu: true,
        renderCell: (row) => {
          //ayrı ayrı if yapılmasının nedeni custom chip mesajı istenrise
          if (integrationStatusEnum.requestCreated === row.statuCode) {
            return <PInfoChip label={row.statuName} />;
          }
          if (integrationStatusEnum.requestWaitingForApprove === row.statuCode) {
            return <PInfoChip label={row.statuName} />;
          }
          if (integrationStatusEnum.requestApproved === row.statuCode) {
            return <PSuccessChip label={row.statuName} />;
          }
          if (integrationStatusEnum.customerApprovedTest === row.statuCode) {
            return <PInfoChip label={row.statuName} />;
          }
          if (integrationStatusEnum.pentaApprovalProcessTest === row.statuCode) {
            return <PInfoChip label={row.statuName} />;
          }
          if (integrationStatusEnum.testAppvored === row.statuCode) {
            return <PSuccessChip label={row.statuName} />;
          }
          if (integrationStatusEnum.activeRequestCreated === row.statuCode) {
            return <PInfoChip label={row.statuName} />;
          }
          if (integrationStatusEnum.pentaApprovalProcessActive === row.statuCode) {
            return <PInfoChip label={row.statuName} />;
          }
          if (integrationStatusEnum.activeRequestApproved === row.statuCode) {
            return <PSuccessChip label={row.statuName} />;
          }
          if (integrationStatusEnum.processCompleted === row.statuCode) {
            return <PInfoChip label={row.statuName} />;
          }
          if (integrationStatusEnum.rejected === row.statuCode) {
            return <PErrorChip label={row.statuName} />;
          }
          if (integrationStatusEnum.cancel === row.statuCode) {
            return <PErrorChip label={row.statuName} />;
          }
        },
      },
    ];
  }, [t]);

  const refreshFunction = () => {
    tableRef?.current?.refreshTable();
  };

  const toogleInfoModal = () => {
    setOpenInfoModal((prevState) => !prevState);
  };

  return (
    <Box className="d-flex gap-24 flex-column">
      {openInfoModal && <StorageInfoModal onClose={toogleInfoModal} />}
      <InfoAndDev>
        <InfoAlert
          className="cursor-pointer"
          onClick={toogleInfoModal}
          label={t('integration:clickToGetDetailedInformationAboutStorageServicesIntegration')}
          icon={
            <IconResolver
              iconName="ArrowForward"
              sx={{ color: blueColors.blue600 }}
            />
          }
        />
        <Box>
          <PButton
            inputtype="text"
            startIcon={<LinkIcon />}>
            <a
              href="https://pentadev.atlassian.net/wiki/spaces/PEDP/pages/1100677129/CreateOrder2890+-+PentaDepom+Sipari+Servisi"
              target="_blank"
              rel="noreferrer">
              {t('integration:developerLink')}
            </a>
          </PButton>
        </Box>
      </InfoAndDev>
      <Box>
        <Typography
          variant="titleMedium"
          color="#000000">
          {t('integration:activeRequest')}
        </Typography>
      </Box>
      <Box>
        <ActiveRequest
          activeRequest={activeRequest}
          getActiveRequest={getActiveRequest}
          serviceId={integrationServiceEnum.storageService}
          refreshFunction={refreshFunction}
        />
      </Box>
      <Box>
        <PLazyLoadingTable
          ref={tableRef}
          infoLabel={t('integration:piece')}
          url={`${INTEGRATION_HISTORY_URL}?ServiceId=${integrationServiceEnum.storageService}`}
          headerTitle={t('integration:pastRequests')}
          columns={columns}
          emptyMessage={NoRowsFound}
        />
      </Box>
    </Box>
  );
};

export default StorageServicesIntegration;
