import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import { primaryColors } from 'config/theme/Colors';
import { MarginContainer } from 'layout/container/MarginContainer';
import AccordionPart from 'pages/pentaStorage/storageIndex/AccordionPart';
import { useTranslation } from 'react-i18next';
import image1 from './media/introduction.jpg';

const StorageIndexContentContainer = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '12px',
  padding: '0 !important',
  maxWidth: '910px !important',
  margin: '0 auto',
}));

const Title = styled(Box)(() => ({
  display: 'flex',
  padding: '28px 16px',
  alignItems: 'center',
}));

export const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
  marginBottom: '28px',
}));

export const Item = styled(Box)(() => ({
  padding: '0 24px',
}));

export const ImageItem = styled(Box)(({ theme, margin = '0 24px' }) => ({
  margin: margin,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    margin: 0,
  },
}));

export const Image = styled('img')(({ width = 'auto', showborder = true }) => ({
  width: width,
  border: showborder ? `1px solid ${primaryColors.primary200}` : 'none',
  borderRadius: '8px',
}));

const StorageIndex = () => {
  const { t } = useTranslation();
  return (
    <MarginContainer>
      <Box className="d-flex flex-column">
        <Typography
          variant="titleLarge"
          color={primaryColors.primary}>
          {t('storage:pentaMyStorage')}
        </Typography>
        <Typography
          variant="bodyMedium"
          color={primaryColors.primary700}>
          {t('storage:controlAndManageYourPentaStorageTransactions')}
        </Typography>
      </Box>
      <PDivider marginBottom="24px" />
      <StorageIndexContentContainer>
        <Title>
          <Typography
            variant="titleMedium"
            color={primaryColors.primary}>
            {t('storage:whatIsPentaStorage')}
          </Typography>
        </Title>
        <PDivider marginBottom="28px" />
        <ItemContainer>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:introductionFirstParagraph')}
            </Typography>
          </Item>
          <ImageItem>
            <Image
              width={'100%'}
              src={image1}
              alt="introduction"
            />
          </ImageItem>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('storage:introductionSecondParagraph')}
            </Typography>
          </Item>
        </ItemContainer>
        <AccordionPart />
      </StorageIndexContentContainer>
    </MarginContainer>
  );
};

export default StorageIndex;
