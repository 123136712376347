import { Grid } from '@mui/material';
import PCenterModal from 'components/modal/PCenterModal';
import SelectableTags from 'components/selectableTags/SelectableTags';
import { useTranslation } from 'react-i18next';

const SerialNumberModal = ({ onClose, allData, selectedRowHeader }) => {
  const { t } = useTranslation();
  return (
    <PCenterModal
      showClose
      onClose={onClose}
      height="500px"
      title={t('productsInStorage:serialNumbersOfProduction', { number: selectedRowHeader })}>
      <Grid
        container
        spacing={2}>
        {allData.map((data) => {
          return (
            <Grid
              item
              key={data.manufacturerSerialNumber}
              xs={12}
              sx={{ wordWrap: 'break-word' }}>
              <SelectableTags
                clickable={false}
                title={data.manufacturerSerialNumber}
              />
            </Grid>
          );
        })}
      </Grid>
    </PCenterModal>
  );
};
export default SerialNumberModal;
