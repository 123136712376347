/* eslint-disable max-len */
const EmptyScreenDefaultIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="64"
        height="64"
        rx="32"
        fill="#DBEAFE"
      />
      <path
        d="M49 20H45V28C45 29.1025 44.103 30 43 30H37C35.897 30 35 29.1025 35 28V20H31C29.35 20 28 21.35 28 23V41C28 42.65 29.35 44 31 44H49C50.65 44 52 42.65 52 41V23C52 21.35 50.65 20 49 20Z"
        fill="#3B82F6"
      />
      <path
        d="M43 20H37V28H43V20Z"
        fill="#93C5FD"
      />
      <path
        d="M26 37H12V39H26V37Z"
        fill="#93C5FD"
      />
      <path
        d="M26 31H16V33H26V31Z"
        fill="#93C5FD"
      />
      <path
        d="M26 25H20V27H26V25Z"
        fill="#93C5FD"
      />
    </svg>
  );
};
export default EmptyScreenDefaultIcon;
