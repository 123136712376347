import { paths } from 'constants/Paths';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import AboutUs from '../AboutUs';

export const aboutUsRoutes = {
  path: `${paths.account}${paths.aboutUs}`,
  element: (
    <LoginLayout>
      <AboutUs />
    </LoginLayout>
  ),
};
