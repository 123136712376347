import { useTheme } from '@emotion/react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import { primaryColors } from 'config/theme/Colors';
import MainContainer from 'layout/container/MainContainer';
import PentaIntegrationSidebar from 'pages/pentaIntegration/components/PentaIntegrationSidebar';
import { useTranslation } from 'react-i18next';

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PentaIntegrationContainer = ({ children }) => {
  const theme = useTheme();
  const smallerMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  return (
    <MainContainer>
      <Box className="d-flex flex-column gap-24">
        <Box className="d-flex flex-column w100 gap-16">
          <Box className="d-flex flex-column w100">
            <Typography
              variant="titleLarge"
              sx={{ color: primaryColors.primary }}>
              {t('integration:pentaIntegration')}
            </Typography>
            <Typography
              variant="bodyMedium"
              sx={{ color: primaryColors.primary700 }}>
              {t('integration:makeYourWorkflowMoreEfficientByIntegratingDifferentServices')}
            </Typography>
          </Box>
          <PDivider marginBottom="0" />
        </Box>
        <ContentContainer>
          <PentaIntegrationSidebar />
          {smallerMd && <PDivider marginBottom="0" />}
          {children}
        </ContentContainer>
      </Box>
    </MainContainer>
  );
};
export default PentaIntegrationContainer;
