import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PSearchableTextField from 'components/textField/PSearchableTextField';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddPerson from './AddPerson';

const ConfigMasterDeliveryFromStorageOption = ({
  additionalInfo,
  error,
  errorMessage,
  handleChange,
  storagePlace,
  setValues,
  contracts = [],
  onAddPersonHandler,
}) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const [inputValue, setInputValue] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showAllMenu, setShowAllMenu] = useState(true);

  useEffect(() => {
    if (additionalInfo) {
      const found = contracts.find((item) => item.number === additionalInfo);
      if (found) {
        setInputValue(`${found.name} ${found.name2}`);
      }
    }
  }, [contracts]);

  const handleModalVisibility = () => {
    setModalVisible((prevState) => !prevState);
  };

  const onSelectChange = (event) => {
    setSearchInputValue(event.target.value);
    setShowAllMenu(false);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
  };

  const menuItems = useMemo(() => {
    const formatContent = (contract) => `${contract.name} ${contract.name2}`;

    const handleItemClick = (contract) => {
      const customObject = { target: { value: contract.number } };
      handleChange(customObject);
      setInputValue(formatContent(contract));
      setShowAllMenu(true);
    };

    return contracts
      .filter(
        (contract) =>
          showAllMenu ||
          Object.values({
            name: contract.name,
            name2: contract.name2,
          })
            .join(' ')
            .toLocaleLowerCase('tr')
            .includes(searchInputValue.toLocaleLowerCase('tr')),
      )
      .map((contract) => ({
        id: contract.number,
        content: <div>{formatContent(contract)}</div>,
        onClick: () => {
          handleItemClick(contract);
        },
      }));
  }, [contracts, searchInputValue, showAllMenu, handleChange]);

  return (
    <Box className="d-flex align-items-center gap-20">
      <PSearchableTextField
        containerWidth="100%"
        placeholder={t('configMaster:selectRelatedPerson')}
        name="storage"
        menuItems={menuItems}
        label={t('configMaster:relatedPerson')}
        value={inputValue}
        searchInputValue={searchInputValue}
        clearSearchInputValue={clearSearchInputValue}
        onChange={onSelectChange}
        error={error}
        errorMessage={errorMessage}
      />
      {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
        <PIconButton
          inputtype="tonal"
          onClick={handleModalVisibility}>
          <AddIcon />
        </PIconButton>
      )}
      {modalVisible && (
        <AddPerson
          setValues={setValues}
          onAddPersonHandler={onAddPersonHandler}
          onClose={handleModalVisibility}
          storagePlace={storagePlace}
        />
      )}
    </Box>
  );
};

export default ConfigMasterDeliveryFromStorageOption;
