import { Box, InputLabel } from '@mui/material';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PSelect from 'components/select/PSelect';
import { paths } from 'constants/Paths';
import LoginContainer from 'layout/loginLayout/container/LoginContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createPageContent, getPageContentByTitle, updatePageContent } from './api/StaticTemplateApi';

const AddStaticTemplate = () => {
  const [type, setType] = useState(1);
  const [turkishData, setTurkishData] = useState('');
  const [englishData, setEnglishData] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuItems = useMemo(() => {
    return [
      { key: 2, value: t('loginAppBar:messageFromTheGeneralManager') },
      { key: 1, value: t('loginAppBar:missionVision') },
      { key: 0, value: t('loginAppBar:ourPolicies') },
    ];
  }, [t]);

  const loaderFunction = useCallback(() => {
    setIsLoading(true);
    getPageContentByTitle(type)
      .then((res) => {
        setTurkishData(res.data.data.content);
        setEnglishData(res.data.data.contentEn);
        setIsUpdate(res.data.data.id);
      })
      .catch((err) => {
        setIsUpdate(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [type]);

  useEffect(() => {
    loaderFunction();
  }, [loaderFunction]);

  const handleChange = (event) => {
    setType(event.target.value);
    setTurkishData('');
    setEnglishData('');
  };

  const onSaveHandler = () => {
    if (isUpdate) {
      updatePageContent({ content: turkishData, contentEn: englishData, title: type, id: isUpdate })
        .then(() => {
          toast.success(t('addStaticTemplate:updatedSuccessfully'));
          loaderFunction();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
          navigate(paths.home);
        });
    } else {
      createPageContent({ content: turkishData, contentEn: englishData, title: type })
        .then(() => {
          toast.success(t('addStaticTemplate:savedSuccessfully'));
          loaderFunction();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);

          navigate(paths.home);
        });
    }
  };

  return (
    <LoginContainer maxWidth="lg">
      {isLoading && <LoadingSpinner />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: ' 10px',
          width: '100%',
        }}>
        <Box>
          <PSelect
            label={t('addStaticTemplate:whichPage')}
            labelId="static-type"
            id="demo-simple-select"
            value={type}
            onChange={handleChange}
            menuItems={menuItems}
          />
        </Box>
        <Box>
          <InputLabel>{t('addStaticTemplate:turkishContent')}</InputLabel>
          <RichTextEditor
            value={turkishData}
            onChange={setTurkishData}
          />
        </Box>
        <Box>
          <InputLabel>{t('addStaticTemplate:englishContent')}</InputLabel>
          <RichTextEditor
            value={englishData}
            onChange={setEnglishData}
          />
        </Box>
        <PButton
          disabled={!turkishData || !englishData}
          onClick={onSaveHandler}>
          {' '}
          {t('addStaticTemplate:save')}
        </PButton>
      </Box>
    </LoginContainer>
  );
};

export default AddStaticTemplate;
