import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import Logo from 'components/logo/Logo';
import { slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import ManagementMenu from 'layout/mainLayout/menuItems/ManagementMenu';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AvatarMenu from './AvatarMenu';
import ServicesMenu from './menuItems/ServicesMenu';
const CustomAppBar = styled(AppBar)(() => ({
  height: '64px',
  backgroundColor: '#ffffff',
  color: slateColors.slate950,
  boxShadow: ' 0px 1px 2px 0px #3232320D',
  justifyContent: 'center',
}));

const HamburgerMenuContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  width: '220px',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '220px',
    top: '64px',
    [theme.breakpoints.between('xs', 'md')]: {
      width: '100%',
    },
  },
}));

const MainAppBar = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const menuItemsVisible = useMemo(() => {
    return location.pathname !== paths.protectedHome;
  }, [location.pathname]);

  const navigateToBayinet = () => {
    setLoading(true);
    loginForBayinet()
      .then((res) => {
        window.location.href = res.data.data.url;
      })
      .catch((err) => {});
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <CustomAppBar>
        <Toolbar sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Box className="d-flex align-items-center gap-32 h100">
            <Logo />
            {matchesMd && (
              <>
                {menuItemsVisible && (
                  <Box
                    onClick={navigateToBayinet}
                    className="d-flex cursor-pointer">
                    <Typography variant="labelLarge">Bayinet</Typography>
                  </Box>
                )}
                {menuItemsVisible && (
                  <Box className="d-flex align-items-center h100">
                    <ServicesMenu />
                  </Box>
                )}
              </>
            )}
          </Box>
          {!matchesMd && (
            <Box className="d-flex gap-24 align-items-center">
              <HamburgerMenuContainer>
                <IconButton
                  inputtype="outline"
                  onClick={() => setIsOpenMenu((prevState) => !prevState)}>
                  <MenuIcon />
                </IconButton>
              </HamburgerMenuContainer>
              <CustomDrawer
                hideBackdrop={theme.breakpoints.between('xs', 'md') ? true : false}
                onClose={() => setIsOpenMenu(false)}
                anchor="left"
                open={isOpenMenu}>
                <Box
                  sx={{ padding: '16px' }}
                  className="d-flex flex-column gap-12">
                  {menuItemsVisible && (
                    <Box
                      onClick={navigateToBayinet}
                      className="d-flex cursor-pointer align-items-center"
                      sx={{ height: '52px' }}>
                      <Typography variant="labelLarge">Bayinet</Typography>
                    </Box>
                  )}
                  {menuItemsVisible && (
                    <Box className="d-flex flex-column">
                      <ServicesMenu />
                    </Box>
                  )}
                  <Box className="d-flex flex-column">
                    <ManagementMenu />
                  </Box>

                  <br />
                  <AvatarMenu />
                </Box>
              </CustomDrawer>
            </Box>
          )}

          {matchesMd && (
            <Box className="d-flex align-items-center gap-24 h100">
              <ManagementMenu />
              <AvatarMenu />
            </Box>
          )}
        </Toolbar>
      </CustomAppBar>
    </>
  );
};

export default MainAppBar;
