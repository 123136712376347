import { useTheme } from '@emotion/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Menu, MenuItem, styled, Typography, useMediaQuery } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import calendar from 'media/assets/calendar.png';
import configuration from 'media/assets/configuration.png';
import openBox from 'media/assets/openBox.png';
import productIntegration from 'media/assets/productIntegration.png';
import { landingScreenmenuButtons } from 'pages/landing/constants/MenuButtonEnums';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const CustomMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}));

const CustomMenuItem = styled(MenuItem)(() => ({
  width: 'auto',
  margin: '16px',
  borderRadius: '8px',
  padding: '4px',
}));

const ServicesMenu = () => {
  const landingScreenButtons = useSelector((state) => state.currentUser?.currentUser?.landingScreenMenu);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userPermissions = useMemo(() => {
    const array = [];
    if (landingScreenButtons) {
      landingScreenButtons.forEach((landingScreenButton) => {
        if (landingScreenButton === landingScreenmenuButtons.storage) {
          array.push({
            title: 'landing:pentaStorage',
            path: `${paths.storage}${paths.main}`,
            image: openBox,
          });
        } else if (landingScreenButton === landingScreenmenuButtons.pay) {
          array.push({
            title: 'landing:pentaSubscriptionManagement',
            path: `${paths.subscriptions}${paths.byProducts}`,
            image: calendar,
          });
        } else if (landingScreenButton === landingScreenmenuButtons.configMaster) {
          array.push({
            title: 'landing:pentaConfigMaster',
            path: `${paths.wizard}${paths.fbw}`,
            image: configuration,
          });
        } else if (landingScreenButton === landingScreenmenuButtons.integrations) {
          array.push({
            title: 'landing:pentaIntegration',
            path: `${paths.integration}${paths.productServices}`,
            image: productIntegration,
          });
        }
      });
    }
    return array;
  }, [landingScreenButtons]);

  return (
    <>
      <Box
        className="d-flex align-items-center cursor-pointer h100"
        onClick={handleClick}>
        <Box
          className="d-flex align-items-center "
          sx={{ height: '52px' }}>
          <Typography variant="labelLarge">{t('mainAppBar:services')} </Typography>
        </Box>
        {!open && <KeyboardArrowDownIcon />}
        {open && <KeyboardArrowUpIcon />}
      </Box>
      {open && !matchesMd && (
        <Box>
          {userPermissions.map((permission) => {
            return (
              <CustomMenuItem
                key={permission?.path}
                onClick={handleClose}>
                <Box
                  className="d-flex align-items-center gap-8"
                  key={permission?.path}
                  onClick={handleClose}>
                  <Link
                    className="d-flex align-items-center gap-8"
                    style={{ textDecoration: 'none' }}
                    relative="route"
                    to={`${permission?.path}`}>
                    <img
                      src={permission?.image}
                      alt="icon"
                      width="48"
                      height="48"
                    />
                    <Box className="d-flex flex-column">
                      <Typography
                        variant="titleMedium"
                        color={primaryColors.primary}>
                        {t(permission?.title)}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              </CustomMenuItem>
            );
          })}
        </Box>
      )}
      {open && matchesMd && (
        <CustomMenu
          id="services-menu"
          aria-labelledby="services-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          {userPermissions.map((permission) => {
            return (
              <CustomMenuItem
                sx={{ width: 'auto' }}
                key={permission?.path}
                onClick={handleClose}>
                <Box
                  className="d-flex align-items-center gap-8"
                  key={permission?.path}
                  onClick={handleClose}>
                  <Link
                    className="d-flex align-items-center gap-8"
                    style={{ textDecoration: 'none' }}
                    relative="route"
                    to={`${permission?.path}`}>
                    <img
                      src={permission.image}
                      alt="icon"
                      width="48"
                      height="48"
                    />
                    <Box className="d-flex flex-column">
                      <Typography
                        variant="titleMedium"
                        color={primaryColors.primary}>
                        {t(permission?.title)}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              </CustomMenuItem>
            );
          })}
        </CustomMenu>
      )}
    </>
  );
};

export default ServicesMenu;
