/* eslint-disable max-len */
export const DeliveryDetailsEn = {
  deliveryDetails: 'Delivery Details',
  shipmentAndInformation: 'Shipment and Information',
  chooseShippingOptionsAndNotificationMethods: 'Choose shipping options and notification methods',
  M4: 'Penta Shipment',
  M2: 'Delivery From Storage',
  M1: 'Cargo',
  shipmentType: 'Shipment Type',
  enterDescription: 'Enter Description',
  checkboxInfo: 'I want to be informed via SMS after the order. (At product exit and delivery.)',
  numberToSendSms: 'Number to Send SMS',
  transportationFee: 'Transportation Fee',
  coveredAmount: 'Covered Transportation Fee',
  preparationFee: 'Preparation Fee',
  createDelivery: 'Create Delivery',
  deliveryOptions: 'Delivery Options',
  selectDeliveryAddress: 'Select delivery address',
  enterDeliveryDescription: 'Enter delivery description',
  selectRelevantPeople: 'Select relevant people',
  thisFieldIsRequired: 'Please enter {{field}}.',
  orderNo: 'Order No',
  deliveryCreatedSuccessfully: 'Delivery created successfully!',
  youCanViewTheDeliveryDetailsInTheTableBelow: 'You can view the delivery details in the table below.',
  deliveryNumber: 'Delivery Number',
  storage: 'Storage',
  address: 'Address',
  cargo: 'Cargo',
  deliveryItems: 'Delivery Items',
  piece: 'Piece',
  orderCode: 'Order Code',
  entryNo: 'Entry No',
  stockCode: 'Stock Code',
  itemName: 'Item Name',
  description: 'Description',
  amount: 'Amount',
  deliveryCouldNotBeCreated: 'Delivery could not be created!',
  addDeliveryAddress: 'Add Delivery Address',
  addAddressDescription: 'Create a new delivery address to use in delivery details and save it in your delivery addresses list.',
  companyName: 'Company Name',
  city: 'City',
  mobile: 'Mobile',
  district: 'District',
  phone: 'Phone',
  doorNo: 'Door No',
  optional: 'Optional',
  fax: 'Fax',
  postCode: 'Post Code',
  email: 'E-mail',
  saveAddress: 'Save Address',
  addressSavedSuccessfully: 'Address saved successfully',
  addPersonDescription: 'Create a new contact to use in delivery details and save to your contacts.',
  personSavedSuccessfully: 'Person saved successfully',
  save: 'Save',
  infoMessage:
    'No payment or invoicing will be made at this time for the aforementioned service and transportation fees. They will be calculated cumulatively at the end of the period and invoiced to you.',
};
