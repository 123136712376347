export const UserManagementEn = {
  userManagement: 'User Management',
  editUsersAndPermissions: ' Edit users and permissions',
  addUser: 'Add User',
  active: 'Active',
  passive: 'Passive',
  edit: 'Edit',
  delete: 'Delete',
  name: 'Name',
  lastname: 'Lastname',
  phoneNumber: 'Phone Number',
  email: 'E-mail',
  status: 'Status',
  searchUser: 'Search user...',
  sureToDeleteUser: 'Are you sure you want to delete the user ?',
  yes: 'Yes',
  cancel: 'Cancel',
  userList: 'User List',
  person: 'person',
};
