import { httpRequest } from 'config/api/AxiosInterceptors';
import { COMMON_API } from 'constants/Keys';

const API = '/api';

const TEMPLATE = '/template';
const TEMPLATE_OVERRIDE = '/template-override';

export const getAllTemplates = (customerId) => httpRequest.get(`${COMMON_API}${API}${TEMPLATE}/${customerId}`);

export const saveTemplate = ({ sapCustomerId, permissionTypes, name, code }) =>
  httpRequest.post(`${COMMON_API}${API}${TEMPLATE}`, {
    sapCustomerId,
    permissionTypes,
    name,
    code,
  });

export const updateTemplate = ({ sapCustomerId, permissionTypes, name, code }) =>
  httpRequest.post(`${COMMON_API}${API}${TEMPLATE}${TEMPLATE_OVERRIDE}`, {
    sapCustomerId,
    permissionTypes,
    name,
    code,
  });

export const deleteTemplate = (id) => httpRequest.delete(`${COMMON_API}${API}${TEMPLATE}/${id}`);
