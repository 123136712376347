import { Box, Pagination, styled } from '@mui/material';
import RowPerPage from './RowPerPage';

const PaginationContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '15px',
  justifyContent: 'flex-end',
}));

const PPagination = ({ pageSize, handlePageChange, handlePageSizeChange, menuItems, totalCount, page }) => {
  return (
    <PaginationContainer>
      <RowPerPage
        value={pageSize}
        onChange={handlePageSizeChange}
        menuItems={menuItems}
      />
      <Pagination
        count={Math.ceil(totalCount / pageSize)}
        page={page}
        onChange={handlePageChange}
      />
    </PaginationContainer>
  );
};

export default PPagination;
