export const ResetPasswordTr = {
  resetYourPassword: 'Şifrenizi Sıfırlayın',
  youCanResetYourPassword: 'Aşağıdaki alanları girip e-posta adresinize gelecek linke tıklayarak şifrenizi sıfırlayabilirsiniz.',
  customerCode: 'Müşteri kodu',
  email: 'E-posta adresi',
  changePassword: 'Şifreyi Değiştir',
  pleaseEnterYourCustomerCode: 'Lütfen müşteri kodunuzu giriniz.',
  pleaseEnterYourEmailAddress: 'Lütfen e-posta adresinizi giriniz.',
  newPassword: 'Yeni Şifre',
  repeatNewPassword: 'Yeni şifre tekrarı',
  theNewPasswordDoesntMatchTheOldPassword: 'Yeni şifreyle eski şifre eşleşmiyor.',
  enterNewPasswordDescription: 'Aşağıya yeni şifrenizi girin. Her 90 günde bir şifrenizi yenilemeniz gerekir.',
  thePasswordsYouEnteredDonotMatchEachOther: 'Girmiş olduğunuz şifreler birbiri ile uyuşmamaktadır.',
  theEmailFormatIsInvalid: 'E-posta biçimi geçersiz.',
};
