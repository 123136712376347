export const redColors = {
  red950: '#450A0A',
  red900: '#7F1D1D',
  red800: '#991B1B',
  red700: '#B91C1C',
  red600: '#DC2626',
  red500: '#EF4444',
  red400: '#F87171',
  red300: '#FCA5A5',
  red200: '#FECACA',
  red100: '#FEE2E2',
  red50: '#FEF2F2',
};
export const yellowColors = {
  yellow950: '#451A03',
  yellow900: '#78350F',
  yellow800: '#92400E',
  yellow700: '#B45309',
  yellow600: '#D97706',
  yellow500: '#F59E0B',
  yellow400: '#FBBF24',
  yellow300: '#FCD34D',
  yellow200: '#FDE68A',
  yellow100: '#FEF3C7',
  yellow50: '#FFFBEB',
};
export const greenColors = {
  green950: '#052E16',
  green900: '#14532D',
  green800: '#166534',
  green700: '#15803D',
  green600: '#16A34A',
  green500: '#22C55E',
  green400: '#4ADE80',
  green300: '#86EFAC',
  green200: '#BBF7D0',
  green100: '#DCFCE7',
  green50: '#F0FDF4',
};
export const blueColors = {
  blue950: '#172554',
  blue900: '#1E3A8A',
  blue800: '#1E40AF',
  blue700: '#1D4ED8',
  blue600: '#2563EB',
  blue500: '#3B82F6',
  blue400: '#60A5FA',
  blue300: '#93C5FD',
  blue200: '#BFDBFE',
  blue100: '#DBEAFE',
  blue50: '#EFF6FF',
};
export const limeColors = {
  lime950: '#1A2E05',
  lime900: '#365314',
  lime800: '#3F6212',
  lime700: '#4D7C0F',
  lime600: '#65A30D',
  lime500: '#84CC16',
  lime400: '#A3E635',
  lime300: '#BEF264',
  lime200: '#D9F99D',
  lime100: '#ECFCCB',
  lime50: '#F7FEE7',
};
export const tealColors = {
  teal950: '#042F2E',
  teal900: '#134E4A',
  teal800: '#115E59',
  teal700: '#0F766E',
  teal600: '#0D9488',
  teal500: '#14B8A6',
  teal400: '#2DD4BF',
  teal300: '#5EEAD4',
  teal200: '#99F6E4',
  teal100: '#CCFBF1',
  teal50: '#F0FDFA',
};
export const viloetColors = {
  violet950: '#2E1065',
  violet900: '#4C1D95',
  violet800: '#5B21B6',
  violet700: '#6D28D9',
  violet600: '#7C3AED',
  violet500: '#8B5CF6',
  violet400: '#A78BFA',
  violet300: '#C4B5FD',
  violet200: '#DDD6FE',
  violet100: '#EDE9FE',
  violet50: '#F5F3FF',
};
export const slateColors = {
  slate950: '#020617',
  slate900: '#111827',
  slate800: '#1F2937',
  slate700: '#374151',
  slate600: '#4B5563',
  slate500: '#6B7280',
  slate400: '#9CA3AF',
  slate300: '#D1D5DB',
  slate200: '#E5E7EB',
  slate100: '#F4F4F5',
  slate50: '#F9FAFB',
};
export const neutralColors = {
  neutral950: '#0A0A0A',
  neutral900: '#18181B',
  neutral800: '#27272A',
  neutral700: '#3F3F46',
  neutral600: '#52525B',
  neutral500: '#71717A',
  neutral400: '#A1A1AA',
  neutral300: '#D4D4D8',
  neutral200: '#E4E4E7',
  neutral100: '#F5F5F5',
  neutral50: '#FAFAFA',
};
export const primaryColors = {
  primary: '#323232',
  primary900: '#424242',
  primary800: '#4D4D4D',
  primary700: '#7C7C7C',
  primary600: '#9D9D9D',
  primary500: '#BDBDBD',
  primary400: '#CECECE',
  primary300: '#DEDEDE',
  primary200: '#E6E6E6',
  primary100: '#EFEFEF',
  primary50: '#F7F7F7',
};
