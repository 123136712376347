import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import ApproveGoogleAuthMail from '../ApproveGoogleAuthMail';
import UserLoginContainer from '../UserLoginContainer';

export const loginRoutes = [
  {
    path: paths.home,
    element: (
      <LoginLayout checkToken>
        <UserLoginContainer />
      </LoginLayout>
    ),
  },

  {
    path: `${paths.home}verify-google-auth`,
    element: (
      <ChildLayout>
        <ApproveGoogleAuthMail />
      </ChildLayout>
    ),
  },
];
