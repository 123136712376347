import SimpleTable from 'components/simpleTable/SimpleTable';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderColumns from './components/OrderColumns';
import OrderRows from './components/OrderRows';
import SerialNumberModal from './components/SerialNumberModal';

const OrderNumberTable = ({ tableData, inputChange, addToCart }) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState(null);
  const [selectedRowHeader, setSelectedRowHeader] = useState(null);

  const columns = useMemo(() => {
    return [
      {
        headerName: t('productsInStorage:productNumber'),
        minWidth: 200,
      },
      {
        headerName: t('productsInStorage:entryNo'),
        minWidth: 200,
        align: 'center',
      },
      {
        headerName: t('productsInStorage:storage'),
        minWidth: 100,
      },
      {
        headerName: t('productsInStorage:itemName'),
        minWidth: 200,
      },
      {
        headerName: t('productsInStorage:unitPrice'),
        minWidth: 200,
        align: 'right',
      },
      {
        headerName: t('productsInStorage:stockAge'),
        minWidth: 200,
        align: 'right',
      },
      {
        headerName: t('productsInStorage:piece'),
        minWidth: 100,
        align: 'right',
      },
      {
        headerName: t('productsInStorage:serialNo'),
        minWidth: 100,
        align: 'center',
      },
      {
        headerName: '',
        width: '30px',
      },
    ];
  }, [t]);

  return (
    <>
      {tableData.groupByOrderId.map((keyWithArray) => {
        return (
          <div key={keyWithArray[0]}>
            <SimpleTable
              headerTitle={<b>{t('productsInStorage:orderNoWithNumber', { number: Number(keyWithArray[0]) })}</b>}
              columns={<OrderColumns columns={columns} />}
              rows={
                <OrderRows
                  addToCart={addToCart}
                  rows={keyWithArray[1]}
                  setModalData={setModalData}
                  setSelectedRowHeader={setSelectedRowHeader}
                  inputChange={inputChange}
                />
              }
            />
            <br />
          </div>
        );
      })}
      {modalData && (
        <SerialNumberModal
          onClose={() => setModalData(null)}
          allData={modalData}
          selectedRowHeader={selectedRowHeader}
        />
      )}
    </>
  );
};

export default OrderNumberTable;
