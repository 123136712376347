import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, FormControl, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { primaryColors, redColors, slateColors } from 'config/theme/Colors';
import { forwardRef } from 'react';

const CustomInput = styled(TextField)(({ error, multiline, inputwidth }) => ({
  backgroundColor: '#ffffff',
  height: multiline ? 'auto' : '42px',

  ' & input[type=search]::-webkit-search-cancel-button': {
    display: 'none',
  },

  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '& .MuiInputBase-root': {
    borderRadius: '8px',
    border: error ? `1px solid ${redColors.red600}` : `1px solid ${primaryColors.primary400}`,
    fontSize: 16,
    width: '100%',
    color: primaryColors.primary,
    padding: '8px 12px',
    '& input:-internal-autofill-selected': {
      backgroundColor: 'transparent',
    },
  },
  '& .Mui-focused': {
    border: error ? `1px solid ${redColors.red600}` : `1px solid ${primaryColors.primary700}`,
  },
  '& .Mui-disabled': {
    backgroundColor: primaryColors.primary100,
    WebkitTextFillColor: `${primaryColors.primary600} !important`,
  },
  '.MuiOutlinedInput-notchedOutline ': {
    display: 'none',
  },
  '& .MuiInputBase-input': {
    padding: 0,
    width: inputwidth,
  },

  '& input:-internal-autofill-selected': {
    WebkitBoxShadow: '0 0 0 50px white inset',
  },
}));

export const HelperContainer = styled(Box)(({ visibility }) => ({
  visibility: visibility ? 'visible' : 'hidden',
  display: 'flex',
  fontWeight: 400,
  minHeight: '16px',
  fontSize: '12px',
  marginLeft: '5px',
  color: slateColors.slate400,
  justifyContent: 'space-between',
}));

export const HelperText = styled('span')(({ helpertext, error }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: helpertext || error ? 'space-between' : 'flex-end',
  color: error ? redColors.red600 : slateColors.slate400,
}));

export const CustomFormControl = styled(FormControl)(({ width }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: width,
  position: 'relative',
}));

const CustomInputLabel = styled(InputLabel)(({ visibility }) => ({
  position: 'relative',
  transform: 'none',
  height: '23px',
  visibility: visibility ? 'visible' : 'hidden',
}));

const PTextField = forwardRef(
  (
    {
      id,
      disabled = false,
      label = '',
      value,
      error = false,
      helpertext = '',
      onChange,
      multiline = false,
      maxRows = 1,
      maxLength = 299,
      showLength = false,
      errorMessage = '',
      startIcon = null,
      endIcon = null,
      showLabel = true,
      showHelperContainer = true,
      width = '100%',
      inputWidth = '100%',
      placeholder = '',
      showPlaceholder = true,
      ...rest
    },
    ref,
  ) => {
    return (
      <CustomFormControl width={width}>
        {showLabel && (
          <CustomInputLabel
            visibility={label}
            htmlFor={id}>
            <Typography
              variant="labelLarge"
              color={primaryColors.primary800}>
              {label}
            </Typography>
          </CustomInputLabel>
        )}
        <CustomInput
          ref={ref}
          id={id}
          disabled={disabled}
          value={value}
          error={error}
          onChange={onChange}
          multiline={multiline}
          maxRows={maxRows}
          inputwidth={inputWidth}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ color: primaryColors.primary700, display: startIcon ? 'flex' : 'none' }}
                position="start">
                {startIcon}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                sx={{ color: error ? redColors.red600 : primaryColors.primary700, display: endIcon || error ? 'flex' : 'none' }}
                position="end">
                {error ? <ErrorOutlineIcon /> : endIcon}
              </InputAdornment>
            ),
          }}
          placeholder={showPlaceholder ? placeholder || label : ''}
          {...rest}
        />
        {showHelperContainer && (
          <HelperContainer visibility={helpertext || showLength || errorMessage}>
            {errorMessage && error && <HelperText error>{errorMessage}</HelperText>}
            {!error && helpertext && <HelperText sx={{ justifyContent: 'flex-start' }}>{helpertext}</HelperText>}
            {showLength && (
              <HelperText
                sx={{ justifyContent: 'flex-end' }}
                error={errorMessage && error}>
                {value?.length ? `${value.length}/${maxLength}` : `0/${maxLength}`}
              </HelperText>
            )}
          </HelperContainer>
        )}
      </CustomFormControl>
    );
  },
);

export default PTextField;
