export const LandingPageTr = {
  pentaStorage: 'Penta Depom',
  pentaConfigMaster: 'Penta Config Master',
  pentaSubscriptionManagement: 'Penta Abonelik Yönetimi',
  manageSubscriptionDetails: 'Abonelik detaylarını yönetin',
  classicBayinetExperience: 'Klasik bayinet deneyimi',
  manageYourProductsInStorage: 'Depodaki ürünlerinizi yönetin',
  configureTheHardwareYouWant: 'İstediğiniz donanımı konfigüre edin',
  pentaIntegration: 'Penta Entegrasyon',
  integrateDifferentServices: 'Farklı servisleri entegre edin',
};
