import styled from '@emotion/styled';
import { Box, Container, Typography } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import { primaryColors } from 'config/theme/Colors';
import { MarginContainer } from 'layout/container/MarginContainer';
import AccordionPart from 'pages/configMaster/pages/index/AccordionPart';
import { Image, ImageItem, Item, ItemContainer } from 'pages/pentaStorage/storageIndex/StorageIndex';
import { useTranslation } from 'react-i18next';
import image1 from './media/image1.svg';

const ConfigMasterIndexContainer = styled(Container)(() => ({
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '12px',
  padding: '0 !important',
  maxWidth: '910px !important',
}));

const Title = styled(Box)(() => ({
  display: 'flex',
  padding: '28px 16px',
  alignItems: 'center',
}));

const ConfigMasterIndex = () => {
  const { t } = useTranslation();
  return (
    <MarginContainer>
      <Box className="d-flex flex-column">
        <Typography
          variant="titleLarge"
          color={primaryColors.primary}>
          {t('configMaster:pentaConfigMaster')}
        </Typography>
        <Typography
          variant="bodyMedium"
          color={primaryColors.primary700}>
          {t('configMaster:configureAndOrderTheHardwareYouWant')}
        </Typography>
      </Box>
      <PDivider marginBottom="24px" />
      <ConfigMasterIndexContainer>
        <Title>
          <Typography
            variant="titleMedium"
            color={primaryColors.primary}>
            {t('configMaster:welcomeMessage')}
          </Typography>
        </Title>
        <PDivider marginBottom="28px" />
        <ItemContainer>
          <Item className="d-flex flex-column">
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('configMaster:introductionFirstParagraph')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('configMaster:introductionSecondParagraph')}
            </Typography>
          </Item>
          <ImageItem>
            <Image
              width={'100%'}
              src={image1}
              alt="introduction"
            />
          </ImageItem>
        </ItemContainer>
        <AccordionPart />
      </ConfigMasterIndexContainer>
    </MarginContainer>
  );
};

export default ConfigMasterIndex;
