import { Box, Typography, styled } from '@mui/material';
import InfoAlert from 'components/alert/InfoAlert';
import WarningAlert from 'components/alert/WarningAlert';
import ApproveModal from 'components/approveModal/ApproveModal';
import PButton from 'components/button/PButton';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import { primaryColors } from 'config/theme/Colors';
import { addItemToCart, changeProduct } from 'pages/pay/api/PayApi';
import { addSeatTypeEnum, addToCartEnums } from 'pages/pay/constants/AddToCartEnums';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ItemContainer = styled(Box)(() => ({
  padding: '8px 12px',
  borderRadius: '8px',
  border: `1px solid ${primaryColors.primary200}`,
  minHeight: '72px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const PayChangeModal = ({ onClose, row, getCartCount }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [refreshData, setRefreshData] = useState([]);
  const [showDecreaseModal, setShowDecreaseModal] = useState(false);
  useEffect(() => {
    setRefreshData([structuredClone(row)]);
    setValue(row.productCount);
  }, []);

  const renderInfoMessage = useCallback(() => {
    if (value === refreshData[0]?.productCount) {
      return <></>;
    } else if (value < refreshData[0]?.productCount) {
      return <InfoAlert label={t('pay:subscriptionAdd', { number: refreshData[0]?.productCount - value })} />;
    } else if (value > refreshData[0]?.productCount) {
      return <InfoAlert label={t('pay:subscriptionDelete', { number: value - refreshData[0]?.productCount })} />;
    }
  }, [refreshData, value]);

  const onInputChange = (newValue, id) => {
    const localData = [...refreshData];
    const foundElement = localData.find((data) => data.id === id);
    foundElement.productCount = newValue;
    setRefreshData(localData);
  };

  const onSaveHandler = async () => {
    const singleData = refreshData[0];
    if (singleData.productCount > value) {
      const sendData = refreshData.map((item) => ({
        currency: row.currency,
        type: addToCartEnums.addSeat,
        quantity: item.productCount - value,
        productId: row.productNumber,
        productName: row.productName,
        zL2Code: row.endUserNumber,
        subscriptionId: row.subscriptionNumber,
        brand: row.brand,
        penNumber: row.penNumber,
        itemNumber: row.itemNumber,
      }));
      setLoading(true);
      addItemToCart(sendData)
        .then((res) => {
          getCartCount();
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
    if (singleData.productCount < value) {
      setShowDecreaseModal(true);
    }
  };

  const onApprove = () => {
    const sendData = refreshData.map((item) => ({
      quantity: item.productCount,
      penNumber: item.penNumber,
      itemNumber: item.itemNumber,
      subscriptionNumber: item.subscriptionNumber,
      type: addSeatTypeEnum.decrease,
    }));
    console.log(sendData);

    setLoading(true);
    setShowDecreaseModal(false);
    changeProduct(sendData[0])
      .then((res) => {
        getCartCount();
        toast.success(res?.data?.message);
        setLoading(false);
        setShowDecreaseModal(false);
        onClose();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
        setShowDecreaseModal(false);
      });
  };

  return (
    <PCenterModal
      showClose
      width="750px"
      onClose={onClose}
      title={t('pay:changeContractProductQuantity')}>
      {loading && <LoadingSpinner />}
      {showDecreaseModal && (
        <ApproveModal
          onClose={() => setShowDecreaseModal(false)}
          approveClick={onApprove}
          bodyText={t('pay:approveDecreseModalBodyText')}
          approveTextKey={t('pay:change')}
          cancelTextKey={t('pay:singleCancel')}
        />
      )}
      <Box className="d-flex flex-column gap-12">
        <Box>
          <Typography variant="labelLarge">{t('pay:productNoAndName')}</Typography>
        </Box>
        <Box
          className="d-flex flex-column gap-16"
          sx={{ overflow: 'auto', maxHeight: '200px' }}>
          {refreshData.map((item) => {
            return (
              <ItemContainer key={item.id}>
                <Box sx={{ flex: 4 }}>
                  <Typography variant="titelSmall">{`${item.productNumber} - ${item.productName}`}</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <InlineEditNumberInput
                    value={item.productCount}
                    onChange={(incomingValue) => onInputChange(incomingValue, item.id)}
                  />
                </Box>
              </ItemContainer>
            );
          })}
        </Box>
        <Box>
          <WarningAlert
            label={
              <Trans
                values={{ number: value }}
                i18nKey="pay:thisProductCurrentlyAppearsToBeNumberUnits"
              />
            }
          />
        </Box>
        <Box>{renderInfoMessage()}</Box>
        <Box className="d-flex justify-flex-end">
          <PButton
            disabled={refreshData[0]?.productCount === value}
            width="auto"
            onClick={onSaveHandler}>
            {t('pay:change')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};

export default PayChangeModal;
