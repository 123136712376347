import { Grid, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PTextField from 'components/textField/PTextField';
import { redColors, slateColors } from 'config/theme/Colors';
import LoginContainer from 'layout/loginLayout/container/LoginContainer';
import { createNewPassword } from 'pages/login/api/LoginApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ResetPassword = ({ emailOrPhone, setPage, setLoginFormField, token, setIsLoading }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [formField, setFormField] = useState({
    password: '',
    passwordRepeat: '',
  });

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    setFormField((prevState) => ({ ...prevState, [name]: value }));
  };

  const onResetHandler = () => {
    if (!formField.password || !formField.passwordRepeat) {
      setErrorMessage('login:pleaseEnterYourPassword');
    } else if (formField.password !== formField.passwordRepeat) {
      setErrorMessage(t('resetPassword:thePasswordsYouEnteredDonotMatchEachOther'));
    } else {
      setErrorMessage('');
      const data = {
        emailOrPhone,
        password: formField.password,
        password2: formField.passwordRepeat,
      };
      const headers = { Authorization: `Bearer ${token}` };
      setIsLoading(true);
      createNewPassword(data, headers)
        .then((res) => {
          toast.success(res.data.message);
          localStorage.clear();
          setLoginFormField((prevState) => ({ ...prevState, password: '' }));
          setPage(0);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <LoginContainer maxWidth="xs">
      <Grid
        container
        spacing={2}
        sx={{ paddingRight: 2, paddingBottom: 2, backgroundColor: '#ffffff' }}>
        <Grid
          item
          xs={12}>
          <Typography
            variant="headlineSmall"
            sx={{ color: slateColors.slate950 }}>
            {t('resetPassword:resetYourPassword')}
          </Typography>
          <br />
          <Typography
            variant="bodyMedium"
            sx={{ color: slateColors.slate600 }}>
            {t('resetPassword:enterNewPasswordDescription')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}>
          <PTextField
            value={formField.password}
            label={t('resetPassword:newPassword')}
            name="password"
            type="password"
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <PTextField
            value={formField.passwordRepeat}
            label={t('resetPassword:repeatNewPassword')}
            name="passwordRepeat"
            type="password"
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <PButton onClick={onResetHandler}>{t('resetPassword:changePassword')}</PButton>
          {errorMessage && (
            <Typography
              variant="labelLarge"
              sx={{ color: redColors.red600 }}>
              {t(errorMessage)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </LoginContainer>
  );
};
export default ResetPassword;
