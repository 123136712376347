import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, INTEGRATION_API } from 'constants/Keys';

const INTEGRATION = '/integration';
const SERVICE = '/service';
const REQUEST = '/request';
const ADD = '/add';
const HISTORY = '/history';
const APPROVE_TEST = '/approve-test';
const REQUEST_LIVE = '/request-live';
const CONFIGURATION = '/configuration';
const CANCEL = '/cancel';
const LOGS = '/logs';
const UPPDATE_KEYS = '/update-keys';
const LOG_DETAIL = '/log-detail';

export const INTEGRATION_HISTORY_URL = `${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${HISTORY}`;

export const TRANSACTION_HISTORY_URL = `${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${LOGS}`;

export const getRequest = (requestId) => httpRequest.get(`${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${SERVICE}/${requestId}`);

export const addRequest = (request) =>
  httpRequest.post(`${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${ADD}`, {
    ...request,
  });

export const approveTestRequest = (request) =>
  httpRequest.put(`${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${APPROVE_TEST}`, {
    ...request,
  });

export const goLiveRequest = (request) =>
  httpRequest.put(`${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${REQUEST_LIVE}`, {
    ...request,
  });

export const getConfigurations = () => httpRequest.get(`${INTEGRATION_API}${API}${INTEGRATION}${CONFIGURATION}`);

export const updateConfigurations = (updateData) =>
  httpRequest.put(`${INTEGRATION_API}${API}${INTEGRATION}${CONFIGURATION}`, {
    ...updateData,
  });

export const cancelRequest = (request) =>
  httpRequest.put(`${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${CANCEL}`, {
    ...request,
  });

export const createConfigurations = (createData) =>
  httpRequest.post(`${INTEGRATION_API}${API}${INTEGRATION}${CONFIGURATION}`, {
    ...createData,
  });

export const updateIntegrationKey = (isLive) => httpRequest.patch(`${INTEGRATION_API}${API}${INTEGRATION}${CONFIGURATION}${UPPDATE_KEYS}/${isLive}`);

export const getLogDetails = (logId) => httpRequest.get(`${INTEGRATION_API}${API}${INTEGRATION}${REQUEST}${LOG_DETAIL}/${logId}`);
