import { Box, styled, Typography } from '@mui/material';
import { primaryColors, viloetColors } from 'config/theme/Colors';

const CardContainer = styled(Box)(() => ({
  background: 'linear-gradient(92.38deg, #FBE8EF 0%, #E5E5FB 49.98%, #DEE8FC 74.99%, #DCEFF3 100%)',
  borderRadius: 8,
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: 204,
  width: 325,
}));

const CardType = styled(Box)(() => ({
  border: `1px solid ${viloetColors.violet300}`,
  backgroundColor: '#EDE9FEA3',
  padding: '2px 8px',
  borderRadius: 24,
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
}));

const CustomerCreditCard = ({ bank, cardNumber, cardHolder, installment }) => {
  return (
    <CardContainer>
      <Box className="d-flex flex-column gap-16">
        <CardType>
          <Typography
            variant="labelMedium"
            color={viloetColors.violet700}>
            {bank}
          </Typography>
        </CardType>
        <Box>
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {cardNumber}
          </Typography>
        </Box>
      </Box>

      <Box className="d-flex flex-column gap-16">
        <Typography
          variant="titleMedium"
          color={primaryColors.primary800}>
          {cardHolder}
        </Typography>
        <Typography
          variant="bodyLarge"
          color={primaryColors.primary800}>
          {installment}
        </Typography>
      </Box>
    </CardContainer>
  );
};
export default CustomerCreditCard;
