import { paths } from 'constants/Paths';
import Forbidden403 from 'pages/forbidden/Forbidden403';
import NotFound404 from 'pages/forbidden/NotFound404';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import globalRouter from 'utils/GlobalRouter';
import { isTokenValid } from 'utils/HelperFunctions';

const ProtectedRoute = ({ path = null, children, isRequired = false }) => {
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  let renderComponent = <></>;
  try {
    if (!isTokenValid()) {
      localStorage.clear();
      return (
        <Navigate
          to={paths.home}
          replace={true}
        />
      );
    } else if (!path) {
      renderComponent = <>{children}</>;
    } else if (currentUser.permissions.some((permission) => `/${permission.path}` === path)) {
      renderComponent = <>{children}</>;
    } else {
      if (isRequired) {
        return <NotFound404 />;
      }
      renderComponent = <Forbidden403 />;
    }
  } catch {
    return (
      <Navigate
        to={paths.home}
        replace={true}
      />
    );
  }

  return renderComponent;
};

export default ProtectedRoute;
