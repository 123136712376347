import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Box, Menu, MenuItem, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import PIconButton from 'components/button/PIconButton';
import ChangeLanguageSelect from 'components/changeLanguageSelect/ChangeLanguageSelect';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { logout } from 'pages/login/api/LoginApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import globalRouter from 'utils/GlobalRouter';
const AvatarContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '15px',
  alignItems: 'center',
}));

const CustomTypography = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
}));

const NameContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const AvatarMenu = () => {
  const currentUser = useSelector((state) => state.currentUser?.currentUser);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const handleOpenUserMenu = (event) => {
    if (!anchorElUser) {
      setAnchorElUser(event.currentTarget);
    } else {
      setAnchorElUser(null);
    }
  };
  const { t } = useTranslation();
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        localStorage.clear();
        globalRouter.navigate(paths.home);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  return (
    <>
      <AvatarContainer onClick={handleOpenUserMenu}>
        <PIconButton
          onClick={handleOpenUserMenu}
          sx={{ borderColor: '#AC202D' }}
          inputtype="outline">
          <PermIdentityIcon sx={{ color: '#AC202D' }} />
        </PIconButton>
        <NameContainer variant="labelMedium">
          <CustomTypography>
            {currentUser?.user.name} {currentUser?.user.surname}
          </CustomTypography>
          <Typography
            variant="labelSmall"
            color={primaryColors.primary800}>
            {currentUser?.user.sapCustomerId}
          </Typography>
        </NameContainer>
        {anchorElUser && matchesMd && (
          <Menu
            sx={{ mt: '45px' }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            <MenuItem>
              <ChangeLanguageSelect />
            </MenuItem>
            <PDivider />
            <MenuItem onClick={handleLogout}>
              <Typography variant="titleMedium">{t('mainAppBar:logout')}</Typography>
            </MenuItem>
          </Menu>
        )}

        {!matchesMd && !Boolean(anchorElUser) && <KeyboardArrowDownIcon />}
        {!matchesMd && Boolean(anchorElUser) && <KeyboardArrowUpIcon />}
      </AvatarContainer>
      {anchorElUser && !matchesMd && (
        <Box
          sx={{ padding: '0 32px' }}
          className="d-flex flex-column gap-16">
          <ChangeLanguageSelect width="100px" />
          <Box onClick={handleLogout}>
            <Typography variant="titleMedium">{t('mainAppBar:logout')}</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AvatarMenu;
