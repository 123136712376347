import { Typography } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import PLazyLoadingTable from 'components/table/PLazyLoadingTable';
import { paths } from 'constants/Paths';
import { TRANSACTION_HISTORY_URL } from 'pages/pentaIntegration/api/IntegrationApi';
import { integrationServiceEnum } from 'pages/pentaIntegration/constants/IntegrationServiceEnum';
import NoRowsFound from 'pages/pentaIntegration/constants/NoRowsFound';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const OrderServicesTransactionHistory = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: 'createdDate',
        headerName: t('integration:dateAndTime'),
        minWidth: 160,
        flex: 1,
        sortable: true,
      },
      {
        field: 'clientIp',
        headerName: t('integration:ipAddress'),
        minWidth: 212,
        flex: 1,
        sortable: true,
      },
      {
        field: 'serviceName',
        headerName: t('integration:serviceName'),
        minWidth: 212,
        flex: 1,
        sortable: true,
      },
      {
        field: 'endpointName',
        headerName: t('integration:request'),
        minWidth: 160,
        flex: 1,
        sortable: true,
      },
      {
        field: 'httpStatusCode',
        headerName: t('integration:result'),
        minWidth: 212,
        flex: 1,
        sortable: true,
        renderCell: (row) => {
          return <Typography variant="bodyMedium">{`${row.httpStatusCode} - ${row.httpMethod}`}</Typography>;
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 72,
        renderCell: (row) => {
          if (row.isAuditable) {
            return (
              <IconResolver
                onClick={() => {
                  navigate(`${paths.integration}${paths.transactionDetail}/${row.id}?rp=${row.serviceId}`);
                }}
                iconName="ChevronRightOutlined"
                className="cursor-pointer"
              />
            );
          }
        },
      },
    ];
  }, [t]);

  return (
    <PLazyLoadingTable
      isSearchable={false}
      ref={tableRef}
      infoLabel={t('integration:piece')}
      url={`${TRANSACTION_HISTORY_URL}?ServiceId=${integrationServiceEnum.orderService}`}
      headerTitle={t('integration:transactionHistory')}
      columns={columns}
      emptyMessage={NoRowsFound}
    />
  );
};

export default OrderServicesTransactionHistory;
