import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, TableCell, Typography, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import React, { useCallback } from 'react';
import PTableRowCell from './PTableRowCell';
const TableRowItem = styled('tr')(() => ({
  background: '#ffffff',
  minHeight: '72px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));
const TableRowEmptyItem = styled('td')(() => ({
  verticalAlign: 'middle',
  textAlign: 'center',
  height: '283px',
  '& div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));
const PTableRow = ({ data, columns, collapsedSections, toggleCollapse, emptyMessage, showEmptyMessage, getRowClassName }) => {
  const renderData = useCallback(() => {
    if (data.length === 0 && showEmptyMessage) {
      if (typeof emptyMessage === 'string') {
        return (
          <tr>
            <TableRowEmptyItem colSpan={columns.length}>
              <Typography
                color={primaryColors.primary400}
                variant="titleMedium">
                {emptyMessage}
              </Typography>
            </TableRowEmptyItem>
          </tr>
        );
      }
      if (typeof emptyMessage === 'function') {
        return (
          <tr>
            <TableRowEmptyItem colSpan={columns.length}>{emptyMessage()}</TableRowEmptyItem>
          </tr>
        );
      }
    }

    return data.map((item, index) => {
      const rowClassName = getRowClassName ? getRowClassName(item) : '';

      if (item.children) {
        return (
          <React.Fragment key={`table-body-${item.title}`}>
            <TableRowItem className={rowClassName}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => toggleCollapse(index)}>
                  {collapsedSections[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </TableCell>
              <TableCell colSpan={columns.length}>{item.title}</TableCell>
            </TableRowItem>
            {collapsedSections[index] &&
              item.children.map((detail, detailIndex) => (
                <TableRowItem
                  key={detailIndex}
                  className={rowClassName}>
                  <TableCell />
                  {columns.map((column) => (
                    <PTableRowCell
                      key={`table-row-cell-${detail.id}-${column.field}`}
                      item={detail}
                      column={column}
                    />
                  ))}
                </TableRowItem>
              ))}
          </React.Fragment>
        );
      } else {
        return (
          <TableRowItem
            key={`table-body-${item.id}`}
            className={rowClassName}>
            {columns.map((column) => (
              <PTableRowCell
                key={`table-row-cell-${item.id}-${column.field}`}
                item={item}
                column={column}
              />
            ))}
          </TableRowItem>
        );
      }
    });
  }, [data, collapsedSections, columns, toggleCollapse, getRowClassName]);

  return <>{renderData()}</>;
};

export default PTableRow;
