/* eslint-disable quotes */
/* eslint-disable max-len */
export const StorageEn = {
  pentaMyStorage: 'Penta My Storage',
  checkYourPentaStorage: 'Check your penta storage',
  storageCosts: 'Warehouse Costs',
  productsInStorage: 'Products In Storage',
  shipments: 'Shipments',
  storageCart: 'Storage Cart',
  storageCostSimulation: 'Storage Cost Simulation',
  periodYear: 'Period Year',
  periodMonth: 'Period Month',
  filterPeriod: 'Filter Period',
  date: 'Date',
  desi: 'Desi',
  desiCost: 'Desi Cost',
  desiCosts: 'Desi Costs',
  ownDesi: 'Own Desi',
  ownDesiCost: 'Own Desi Cost',
  differentProduct: 'Different Product',
  differentProductCost: 'Different Product Cost',
  differentProductCosts: 'Different Product Costs',
  shipment: 'Shipment',
  shippingCost: 'Shipping Cost',
  shippingCosts: 'Shipping Costs',
  preperationCost: 'Preparation Cost',
  overallCost: 'Overall Cost',
  customerOwnProducts: 'Customer Own Products',
  cargo: 'Cargo',
  shippingAmount: 'Shipping Amount',
  desiAmount: 'Desi Amount',
  piece: 'Piece',
  desiWithSmall: 'desi',
  preparationFeeCost: 'Preparation Fee Cost',
  totalCosts: 'Total Costs',
  controlAndManageYourPentaStorageTransactions: 'Control and manage your Penta storage transactions',
  whatIsPentaStorage: 'Welcome to Penta Storage!',
  introductionUpperCase: 'INTRODUCTION',
  introductionFirstParagraph:
    'PentaStorage is an application that allows products ordered from Penta to be stored within the Penta Warehouse and shipped to the desired address at the time you choose.',
  introductionSecondParagraph:
    'With this application, you can list your products located at the PentaStorage location, view your storage costs, and ship them to the desired address.',
  howDoesPentaStorageProvideAnAdvantage: 'How does Penta Storage provide an advantage?',
  advantageFirstParagraph:
    'In the normal purchasing process, the orders you place are delivered to your warehouse via our shipments or cargo, and tasks such as inventory tracking and transportation are carried out by you. In this process, you also need to consider internal and external factors such as location rent, labor, security, energy, transportation, and time.',
  inTheNormalWorkflow: 'In the normal workflow:',
  inThePentaStorageApplication: 'In the PentaStorage application',
  advantageSecondParagraph:
    'PentaStoage allows you to manage the relevant location. With this application, it aims to minimize the internal and external cost and risk factors mentioned above.',
  howCanIUsePentaStorage: 'How can I use PentaStorage?',
  howToUseFirstParagraph:
    'To use the PentaStorage application, you first need to sign the Service Agreement and send it to the Penta representatives.',
  clickHereForTheAgreement: 'Click here for the agreement.',
  howToUseSecondParagraph: 'After the agreement, the necessary permissions will be granted to your company.',
  isPentaStoragePaidService: 'Is PentaStorage a paid service?',
  isPaidServiceFirstParagraph:
    'For PentaStorage, a daily cost is calculated based on the number of stored products, total volume, and transportation fees, and you will be invoiced periodically. You can view these costs in real-time on the PentaStorage page. Information about pricing is detailed in the agreement.',
  howCanIShipProductsToPentaStorageViaBayinet: 'How can I ship products to PentaStorage via Bayinet?',
  shipPentaStorageViaBayinetFirstParagraph:
    "If you have a PentaStorage agreement, a 'I want to ship to PentaStorage' option will appear on your cart page. By selecting this option, you will see the available PentaStorage locations for shipping. Once you complete the order, the necessary processes will be carried out, and the products will be visible in your PentaStorage management panel.",
  howCanIViewMyProductsInPentaStorage: 'How can I view my products in PentaStorage?',
  viewMyProductsFirstParagraph:
    'In the PentaStorage application, you can list your products using the "Products In Storage" link. Here, you will find details such as the orders through which the products were purchased, product serial numbers, stock quantities, locations, and other relevant information.',
  howCnIShipMyProductsToAnotherAddressUsingBayinetFromPentaStorage: 'How can I ship my products to another address using Bayinet from PentaStorage?',
  shipMyProductToAnotherAddressFirstParagraph:
    'After adding the products you want to ship from the PentaStorage location to your cart, go to the "Storage Cart" section, where you can select the Delivery Options and Shipping Type, then complete the order. This section is specifically for shipping, so it does not display any pricing or related information and is not connected to any payment system.',
  shipMyProductToAnotherAddressSecondParagraph:
    'The only price information you will see here is the Transportation Fee, which is calculated based on the selected shipping type. This amount is determined according to the rates specified in the agreement and is invoiced in bulk at the end of the period.',
  howCanIViewMyCostsIncurredDuringThePeriodInPentaStorage: 'How can I view my costs incurred during the period in PentaStorage?',
  costIncurredFirstParagraph:
    'In the PentaDepom application, there is a "Storage Costs" section where you can view both the total cost incurred for the period and the detailed daily breakdown of these costs.',
};
