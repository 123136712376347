import { Box, styled, Typography } from '@mui/material';
import ErrorAlert from 'components/alert/ErrorAlert';
import InfoAlert from 'components/alert/InfoAlert';
import WarningAlert from 'components/alert/WarningAlert';
import PButton from 'components/button/PButton';
import PTooltip from 'components/tooltip/PTooltip';
import { primaryColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { contractPersonTypeEnum } from 'pages/accountRelatedPersons/common/Enums';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from 'utils/HelperFunctions';

const OrderContainer = styled(Box)(() => ({
  padding: '0 16px',
}));

const OrderItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  padding: '20px 0',
  justifyContent: 'space-between',
}));

const CreditLimit = styled(Box)(({ creditlimit }) => {
  if (!creditlimit) {
    return null;
  }
  const splittedLimit = creditlimit.split('-');

  if (splittedLimit.length === 1) {
    return {
      background: 'linear-gradient(92.38deg, #E4FAEB 0%, #EDF8FB 49.98%, #F4F8FB 74.99%, #E9F1F8 100%)',
      width: '100%',
      padding: '16px 12px',
      borderRadius: '8px',
    };
  } else {
    return {
      background: 'linear-gradient(92.38deg, #FAE4E4 0%, #FBEDF4 49.98%, #FBF5F4 74.99%, #F8E9EA 100%)',
      width: '100%',
      padding: '16px 12px',
      borderRadius: '8px',
    };
  }
});

const DynamicContainer = styled(Box)(() => ({
  paddingTop: '20px',
  '& > *': {
    borderBottom: `1px solid ${slateColors.slate200}`,
  },
  '&>*:nth-of-type(1)': {
    padding: 0,
    paddingBottom: '24px',
  },
  'div:last-of-type': {
    borderBottom: 0,
  },
}));

const OrderDetailSummary = ({ showOrderDetail, incomingData, formData, handleCreateOrder }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <OrderContainer>
      <DynamicContainer>
        {showOrderDetail.amounts.map((summary) => (
          <OrderItemContainer key={summary.amountType}>
            <Box>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {summary.amountType}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${formatPrice(summary.amount)} ${summary.currency}`}
              </Typography>
            </Box>
          </OrderItemContainer>
        ))}
      </DynamicContainer>
      <Box className="d-flex flex-column gap-16">
        {incomingData?.creditLimit && (
          <CreditLimit creditlimit={incomingData?.creditLimit}>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodySmall"
                color={primaryColors.primary800}>
                {`${t('configMaster:availableCreditLimit')}:`}
              </Typography>
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {`${incomingData?.creditLimit}`}
              </Typography>
            </Box>
          </CreditLimit>
        )}
        {incomingData?.messages.map((message) => {
          if (message.type === 'W') {
            return (
              <WarningAlert
                key={message.message}
                label={message.message}
              />
            );
          } else if (message.type === 'E') {
            return (
              <ErrorAlert
                key={message.message}
                label={message.message}
              />
            );
          } else {
            return null;
          }
        })}
        <InfoAlert
          label={
            <div>
              <span
                className="hyperlink-text"
                onClick={() =>
                  navigate(`${paths.accounting}${paths.accountRelatedPersons}`, {
                    state: { selectedKey: contractPersonTypeEnum.eArchive },
                  })
                }>
                {t('configMaster:eArchive')}
              </span>
              <span> {t('configMaster:invoicesWillBeSentToYourDefinedEMailAddresses')}</span>
            </div>
          }
        />
        <PTooltip
          placement="bottom-end"
          title={!formData.selectedPayment && t('configMaster:pleaseSelectPaymentType')}>
          <PButton
            onClick={handleCreateOrder}
            disabled={!formData.selectedPayment}>
            {t('configMaster:order')}
          </PButton>
        </PTooltip>
      </Box>
    </OrderContainer>
  );
};

export default OrderDetailSummary;
