export const UserManagementTr = {
  userManagement: 'Kullanıcı Yönetimi',
  editUsersAndPermissions: ' Kullanıcılarınızı ve yetkilerini yönetin',
  addUser: 'Kullanıcı Ekle',
  active: 'Aktif',
  passive: 'Pasif',
  edit: 'Düzenle',
  delete: 'Sil',
  name: 'Ad',
  lastname: 'Soyad',
  phoneNumber: 'Telefon Numarası',
  email: 'E-posta',
  status: 'Durum',
  searchUser: 'Kullanıcı ara...',
  sureToDeleteUser: 'Kullanıcıyı silmek istediğinizden emin misiniz ?',
  yes: 'Evet',
  cancel: 'İptal',
  userList: 'Kullanıcı Listesi',
  person: 'kişi',
};
