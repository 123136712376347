import CheckIcon from '@mui/icons-material/Check';
import { Box, ListSubheader, MenuItem, Typography, styled } from '@mui/material';
import PChip from 'components/chip/PChip';
import { CustomFormControl, HelperContainer, HelperText } from 'components/textField/PTextField';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { useEffect, useRef, useState } from 'react';

import { CustomSelect, CustomSelectLabel } from './PSelect';
const CustomListSubheader = styled(ListSubheader)(() => ({
  fontSize: '14px',
  color: neutralColors.neutral950,
  fontWeight: 500,
}));

const CustomMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  textWrap: 'wrap',
  overflowWrap: 'anywhere',
  '&.Mui-selected': {
    backgroundColor: `${primaryColors.primary100} !important`,
  },
  '&:hover': {
    backgroundColor: primaryColors.primary300,
  },
}));

const PMultiSelect = ({
  label = '',
  value,
  id = 'test-id-2',
  onChange,
  menuItems,
  labelId = 'test-id',
  placeholder = '',
  errorMessage,
  error = false,
  withGroup = true,
  handleChipDelete,
  disabled = false,
  width = '100%',
  showLabel = true,
  showHelperContainer = true,
  ...rest
}) => {
  const selectRef = useRef();
  const [menuWidth, setMenuWidth] = useState('auto');

  useEffect(() => {
    if (selectRef.current) {
      setMenuWidth(`${selectRef.current.offsetWidth}px`);
    }
  }, [selectRef]);

  function findByKey(keyToFind) {
    let queue = [...menuItems];
    while (queue.length > 0) {
      let node = queue.shift();
      if (node.key === keyToFind) {
        return node;
      } else if (node.key === keyToFind.toString()) {
        return node;
      }
      if (node.children) {
        queue.push(...node.children);
      }
    }
    return null;
  }

  const renderMenu = () => {
    if (!withGroup) {
      return menuItems.map((item) => {
        return (
          <CustomMenuItem
            key={item.key}
            value={item.key}>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {item.value}
            </Typography>
          </CustomMenuItem>
        );
      });
    } else {
      return menuItems.reduce(
        (listItems, element) => [
          ...listItems,
          <CustomListSubheader
            key={element.category}
            disabled>
            {element.category}
          </CustomListSubheader>,
          ...element.children.map((child) => (
            <CustomMenuItem
              key={`${element.category}-${child.key}`}
              value={child.key}>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary}>
                {child.value}
              </Typography>
              {value.some((item) => item === child.key) && <CheckIcon />}
            </CustomMenuItem>
          )),
        ],
        [],
      );
    }
  };

  return (
    <CustomFormControl width={width}>
      {showLabel && (
        <CustomSelectLabel
          visibility={label}
          id={id}>
          {label}
        </CustomSelectLabel>
      )}
      <CustomSelect
        ref={selectRef}
        displayEmpty
        multiple
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <Typography
                variant="bodyLarge"
                color={primaryColors.primary600}>
                {placeholder}
              </Typography>
            );
          }
          return (
            <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
              {selected.map((value) => (
                <PChip
                  disabled={disabled}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onDelete={() => handleChipDelete(value)}
                  key={value}
                  label={findByKey(value).value}
                />
              ))}
            </Box>
          );
        }}
        labelId={labelId}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            style: {
              width: menuWidth,
              minWidth: 'inherit',
              maxHeight: 250,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        disabled={disabled}
        {...rest}>
        {renderMenu()}
      </CustomSelect>
      {showHelperContainer && (
        <HelperContainer visibility={errorMessage && error}>
          <HelperText error>{errorMessage}</HelperText>
        </HelperContainer>
      )}
    </CustomFormControl>
  );
};

export default PMultiSelect;
