import { Box, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import ConfigMasterMainPart from 'pages/configMaster/tabs/configMaster/ConfigMasterMainPart';
import ConfigMasterSummaryPart from 'pages/configMaster/tabs/configMaster/ConfigMasterSummaryPart';
import { useState } from 'react';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const BigGridItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridColumnStart: 1,
  gridColumnEnd: 10,
  gap: '20px',
}));

const SmallGridItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridColumnStart: 10,
  gridColumnEnd: 13,
  backgroundColor: '#ffffff',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '12px',
  height: 'min-content',
}));

const ConfigMaster = ({ setTabValue }) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [justShowInStock, setJustShowInStock] = useState(true);
  const [filteringType, setFilteringType] = useState('');
  const [selectedParts, setSelectedParts] = useState([]);
  const [components, setComponents] = useState({ allData: [], visibleData: [], pure: [] });
  const [configsData, setConfigsData] = useState([]);
  const [configSummaryPart, setConfigSummaryPart] = useState([]);
  const [selectedConfigs, setSelectedConfigs] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [count, setCount] = useState(1);
  const [customerDefaultCurrency, setCustomerDefaultCurrency] = useState(null);
  const [showUpdateCartButton, setShowUpdateCartButton] = useState(true);
  const [pricesAfterSimulate, setPricesAfterSimulate] = useState([]);
  const [notTriggerPrice, setNotTriggerPrice] = useState(false);
  const [expandedAccordionNameAndIds, setExpandedAccordionNameAndIds] = useState([]);
  const tagSelectHandler = (index) => {
    setSelectedTag(index);
  };

  const onShowInStockChange = (event) => {
    const { checked } = event.target;
    setJustShowInStock(checked);
  };

  const resetAfterAddToCart = () => {
    setSelectedParts([]);
    setFilteringType('');
    setSelectedConfigs([]);
    setCount(1);
    setExpandedAccordionNameAndIds([]);
  };

  return (
    <Box>
      <GridContainer>
        <BigGridItem>
          <ConfigMasterMainPart
            setExpandedAccordionNameAndIds={setExpandedAccordionNameAndIds}
            expandedAccordionNameAndIds={expandedAccordionNameAndIds}
            notTriggerPrice={notTriggerPrice}
            setNotTriggerPrice={setNotTriggerPrice}
            setShowUpdateCartButton={setShowUpdateCartButton}
            selectedTag={selectedTag}
            tagSelectHandler={tagSelectHandler}
            onShowInStockChange={onShowInStockChange}
            justShowInStock={justShowInStock}
            setSelectedParts={setSelectedParts}
            selectedParts={selectedParts}
            filteringType={filteringType}
            setFilteringType={setFilteringType}
            configsData={configsData}
            setConfigsData={setConfigsData}
            selectedConfigs={selectedConfigs}
            setSelectedConfigs={setSelectedConfigs}
            components={components}
            setComponents={setComponents}
            setConfigSummaryPart={setConfigSummaryPart}
            setRequiredFields={setRequiredFields}
            setCustomerDefaultCurrency={setCustomerDefaultCurrency}
            setSelectedTag={setSelectedTag}
          />
        </BigGridItem>
        <SmallGridItem>
          <ConfigMasterSummaryPart
            showUpdateCartButton={showUpdateCartButton}
            setShowUpdateCartButton={setShowUpdateCartButton}
            resetAfterAddToCart={resetAfterAddToCart}
            selectedParts={selectedParts}
            count={count}
            setCount={setCount}
            configSummaryPart={configSummaryPart}
            components={components}
            setComponents={setComponents}
            requiredFields={requiredFields}
            setTabValue={setTabValue}
            customerDefaultCurrency={customerDefaultCurrency}
            pricesAfterSimulate={pricesAfterSimulate}
            setPricesAfterSimulate={setPricesAfterSimulate}
          />
        </SmallGridItem>
      </GridContainer>
    </Box>
  );
};
export default ConfigMaster;
