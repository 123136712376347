import { useTheme } from '@emotion/react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Box, styled, Typography, useMediaQuery } from '@mui/material';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import { greenColors, primaryColors, redColors } from 'config/theme/Colors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import logo from 'svgs/logo.svg';
import { formatPrice } from 'utils/HelperFunctions';

export const CustomTypographyWithLineThrough = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 300,
  lineHeight: '20px',
  letterSpacing: '0.10000000149011612px',
  color: primaryColors.primary700,
  textDecoration: 'line-through',
}));

const ConfigMasterAccordionSummary = ({ component, selectedParts, allData, setSelectedParts }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSelected, setIsSelected] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const foundElement = selectedParts.find((selectedPart) => selectedPart.component === component.componentGroupTypeName.toUpperCase());

    if (foundElement) {
      const group = allData.find((group) => group.components.some((groupComponent) => groupComponent.id === foundElement.value));

      if (group) {
        const groupComponent = group.components.find((comp) => comp.id === foundElement.value);

        if (groupComponent) {
          if (groupComponent.price) {
            setIsSelected({
              ...foundElement,
              price: groupComponent.price,
              stock: groupComponent.stock,
              stockDisplay: groupComponent.stockDisplay,
              quantity: groupComponent.quantity,
            });
          } else if (groupComponent.quantity < foundElement.count) {
            toast.warn(t('configMaster:theNumberOfItemInThePackageChanged', { item: foundElement.component }));

            setSelectedParts((prevSelectedParts) =>
              prevSelectedParts.map((part) => (part.component === component.componentGroupTypeName.toUpperCase() ? { ...part, count: 1 } : part)),
            );

            setIsSelected({
              ...foundElement,
              count: groupComponent.quantity,
              quantity: groupComponent.quantity,
            });
          } else {
            setIsSelected({
              ...foundElement,
              quantity: groupComponent.quantity,
            });
          }
        }
      }
    } else {
      setIsSelected(false);
    }
  }, [selectedParts, allData, component.componentGroupTypeName, t]);

  const inputChange = (value) => {
    if (value <= isSelected.quantity) {
      const foundElement = selectedParts.find((selectedPart) => selectedPart.component === component.componentGroupTypeName.toUpperCase());
      const updatedParts = selectedParts.map((part) => (part.component === foundElement.component ? { ...part, count: value } : part));
      setSelectedParts(updatedParts);
    }
  };

  const showPrice = (component) => {
    if (component?.price?.amountWithoutVatRate !== null && component?.price?.amountWithoutVatRate !== undefined && component?.price?.currencyCode) {
      if (component?.price?.unitPrice) {
        return `${formatPrice(component?.price?.unitPrice)} ${component?.price?.currencyCode}`;
      }
      return `${formatPrice(component?.price?.amountWithoutVatRate)} ${component?.price?.currencyCode}`;
    } else {
      return t('configMaster:loading');
    }
  };
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="category-content"
      id="category-header">
      <Box
        className="d-flex gap-8 align-items-center justify-content-between"
        sx={{ width: 'calc(100% - 36px)' }}>
        <Box className="d-flex gap-8 align-items-center">
          <Box>
            <img
              alt="icon"
              width={40}
              height={40}
              src={component.imageUrl || logo}
            />
          </Box>
          <Box className="d-flex flex-column">
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              <Box className="d-flex gap-4 align-items-center">
                {component.componentGroupTypeName}
                {isSelected && (
                  <CheckCircleRoundedIcon
                    sx={{ color: greenColors.green500 }}
                    fontSize="small"
                  />
                )}
              </Box>
            </Typography>
            {!isSelected && (
              <>
                {component.isRequired && (
                  <Typography
                    variant="bodyMedium"
                    color={redColors.red600}>
                    {t('configMaster:pleaseChoose')}
                  </Typography>
                )}
                {!component.isRequired && (
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:addToYourSelection')}
                  </Typography>
                )}
              </>
            )}
            {isSelected && <div>{isSelected.name}</div>}
          </Box>
        </Box>
        {isSelected && isSelected.quantity === 1 && (
          <Box
            className="d-flex flex-column text-end"
            sx={{ minWidth: '105px', width: '105px' }}>
            {isSelected?.price?.oldAmount && (
              <CustomTypographyWithLineThrough>
                {`${isSelected?.price?.oldAmount} ${isSelected?.price?.currencyCode}`}
              </CustomTypographyWithLineThrough>
            )}
            <Typography
              variant="titleMedium"
              color={isSelected?.price?.oldAmount ? greenColors.green600 : primaryColors.primary}>
              {showPrice(isSelected)}
            </Typography>

            {isSelected.stock > 1 ? (
              <Typography
                variant="bodySmall"
                color={greenColors.green600}>
                {`${t('configMaster:stock')}: ${isSelected.stockDisplay} ${t('configMaster:piece')}`}
              </Typography>
            ) : (
              <Typography
                variant="bodySmall"
                color={redColors.red600}>
                {t('configMaster:insufficientStock')}
              </Typography>
            )}
          </Box>
        )}
        {isSelected && isSelected.quantity > 1 && (
          <>
            <Box
              className="d-flex gap-20 align-items-center"
              sx={{ overflowX: matches ? 'auto' : 'hidden' }}>
              <Box sx={{ zIndex: 2 }}>
                <InlineEditNumberInput
                  sx={{ width: matches ? '100px' : '142px' }}
                  value={isSelected.count}
                  onChange={inputChange}
                />
              </Box>
              <Box
                className="d-flex flex-column text-wrap-no-wrap text-end"
                sx={{ width: matches ? '48px' : '105px', minWidth: '105px' }}>
                {isSelected?.price?.oldAmount && (
                  <CustomTypographyWithLineThrough>
                    {`${formatPrice(isSelected?.price?.oldAmount)} ${isSelected?.price?.currencyCode}`}
                  </CustomTypographyWithLineThrough>
                )}
                <Typography
                  variant="titleMedium"
                  color={isSelected?.price?.oldAmount ? greenColors.green600 : primaryColors.primary}>
                  {`${formatPrice(isSelected?.count * isSelected?.price?.amountWithoutVatRate)} ${isSelected?.price?.currencyCode}`}
                </Typography>
                {isSelected.stock > 1 ? (
                  <Typography
                    variant="bodySmall"
                    color={greenColors.green600}>
                    {`${t('configMaster:stock')}: ${isSelected.stockDisplay} ${t('configMaster:piece')}`}
                  </Typography>
                ) : (
                  <Typography
                    variant="bodySmall"
                    color={redColors.red600}>
                    {t('configMaster:insufficientStock')}
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </AccordionSummary>
  );
};

export default React.memo(ConfigMasterAccordionSummary);
