import styled from '@emotion/styled';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PDivider from 'components/Divider/PDivider';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SecondaryLayoutGridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SecondaryLayoutTitleGridContainer = styled(Box)(() => ({
  gridColumnStart: 3,
  gridColumnEnd: 12,
}));

const SecondaryMainGridItem = styled(Box)(({ backgroundcolor, bordercolor }) => ({
  gridColumnStart: 3,
  gridColumnEnd: 11,
  backgroundColor: backgroundcolor,
  border: `1px solid ${bordercolor}`,
  borderRadius: '12px',
}));

const SecondaryLayoutMainWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  margin: '0 16px',
}));

const SecondaryLayout = ({ children, path, title, onClick = null, backgroundColor = '#ffffff', borderColor = primaryColors.primary200 }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <SecondaryLayoutGridContainer>
        <SecondaryLayoutTitleGridContainer>
          <PIconButton
            inputtype="text"
            onClick={handleClick}>
            <ChevronLeftIcon />
          </PIconButton>
          <Typography
            variant="titleLarge"
            color={neutralColors.neutral950}>
            {t(title)}
          </Typography>
        </SecondaryLayoutTitleGridContainer>
      </SecondaryLayoutGridContainer>
      <PDivider />
      <SecondaryLayoutMainWrapper>
        <SecondaryLayoutGridContainer>
          <SecondaryMainGridItem
            backgroundcolor={backgroundColor}
            bordercolor={borderColor}>
            {children}
          </SecondaryMainGridItem>
        </SecondaryLayoutGridContainer>
      </SecondaryLayoutMainWrapper>
    </>
  );
};

export default SecondaryLayout;
