import { Chip, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

const CustomChip = styled(Chip)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '24px',
  padding: '2px, 8px',
  height: '20px',
  color: primaryColors.primary,
  backgroundColor: primaryColors.primary100,
  '& .MuiChip-label': {
    fontSize: '12px',
    fontWeight: 500,
  },
}));

const PChip = ({ variant = 'filled', label, ...rest }) => {
  return (
    <CustomChip
      variant={variant}
      label={label}
      {...rest}
    />
  );
};

export default PChip;
