import PSelect from 'components/select/PSelect';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ChangeLanguageSelect = ({ width = 'auto' }) => {
  const { i18n, t } = useTranslation();

  const languageData = useMemo(() => {
    return [
      { key: 'tr', value: t('mainAppBar:turkish') },
      { key: 'en', value: t('mainAppBar:english') },
    ];
  }, [t]);

  const onLanguageChangeHandler = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <PSelect
      width={width}
      showLabel={false}
      showHelperContainer={false}
      labelId="language-label"
      id="select-language"
      value={i18n.language}
      onChange={onLanguageChangeHandler}
      menuItems={languageData}
      label={t('mainAppBar:language')}
    />
  );
};
export default ChangeLanguageSelect;
