import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { Box, Typography, styled } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import PDefaultChip from 'components/chip/PDefaultChip';
import PErrorChip from 'components/chip/PErrorChip';
import PInfoChip from 'components/chip/PInfoChip';
import PSuccessChip from 'components/chip/PSuccessChip';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PClientTable from 'components/table/PClientTable';
import { primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import MainContainer from 'layout/container/MainContainer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getMyContracts } from '../api/MyContractsApi';
import { approvalStatusEnum } from '../constants/ApprovalStatusEnum';
import MyContractModal from '../myContractModal/MyContractModal';
const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const MyContractsList = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState({ allData: [], visibleData: [] });
  const [selectedRow, setSelectedRow] = useState(null);

  const loaderFunction = () => {
    setIsLoading(true);
    getMyContracts()
      .then((res) => {
        const local = [];

        res.data.data.forEach((item, index) => {
          local.push({
            id: index,
            contractId: item.id,
            approvalStatus: item.approvalStatus,
            status: item.status,
            endDate: item.endDate,
            startDate: item.startDate,
            version: item.version,
            contractName: item.contractName,
            startEndData: `${dayjs(item.startDate).format('DD.MM.YYYY')} - ${dayjs(item.endDate).format('DD.MM.YYYY')}`,
          });
        });
        setData({ allData: local, visibleData: local });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loaderFunction();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'contractName',
        headerName: t('myContracts:contractName'),
        minWidth: 314,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'version',
        headerName: t('myContracts:contractVersion'),
        minWidth: 140,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'startEndData',
        headerName: t('myContracts:startEndDate'),
        minWidth: 220,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'status',
        headerName: t('myContracts:status'),
        minWidth: 140,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          return <Typography variant="bodyMedium">{row.status ? t('myContracts:active') : t('myContracts:passive')}</Typography>;
        },
      },
      {
        field: 'approvalStatus',
        headerName: t('myContracts:approvalStatus'),
        minWidth: 140,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => {
          if (row.approvalStatus === 1) {
            return <PInfoChip label={t(`myContracts:${approvalStatusEnum[row.approvalStatus]}`)} />;
          } else if (row.approvalStatus === 2) {
            return <PSuccessChip label={t(`myContracts:${approvalStatusEnum[row.approvalStatus]}`)} />;
          } else if (row.approvalStatus === 3) {
            return <PErrorChip label={t(`myContracts:${approvalStatusEnum[row.approvalStatus]}`)} />;
          } else {
            return <PDefaultChip label={t(`myContracts:${approvalStatusEnum[row.approvalStatus]}`)} />;
          }
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 72,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <TaskOutlinedIcon
              fontSize="small"
              className="cursor-pointer"
              onClick={() => setSelectedRow(row)}
            />
          );
        },
      },
    ];
  }, [t]);

  const onTableSearch = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({
          contractName: e.contractName,
          version: e.version,
          startEndData: e.startEndData,
          status: e.status ? t('myContracts:active') : t('myContracts:passive'),
          approvalStatus: t(`myContracts:${approvalStatusEnum[e.approvalStatus]}`),
        })
          .join('')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };
  return (
    <MainContainer>
      {isLoading && <LoadingSpinner />}
      <HeaderContainer>
        <Box className="d-flex flex-column">
          <Typography variant="titleLarge">{t('myContracts:myContracts')} </Typography>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary700}>
            {t('myContracts:manageYourContractList')}
          </Typography>
        </Box>
        <PDivider />
      </HeaderContainer>
      <PClientTable
        headerRightSide={
          <TableSearchTextField
            value={searchValue}
            onChange={onTableSearch}
            placeholder={t('myContracts:searchPlaceholder')}
          />
        }
        headerTitle={t('myContracts:contractList')}
        dataLength={`${data.allData.length} ${t('myContracts:piece')}`}
        rows={data.visibleData}
        columns={columns}
      />
      {selectedRow && (
        <MyContractModal
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          loaderFunction={loaderFunction}
        />
      )}
    </MainContainer>
  );
};
export default MyContractsList;
