import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { CustomFormControl } from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import { CustomSelect, CustomSelectLabel } from './PSelect';

const PSelectWithIcon = ({
  label = '',
  value,
  id = 'test-id-2',
  onChange,
  menuItems,
  labelId = 'test-id',
  placeholder,
  showLabel = true,
  width = '100%',
  showHelperContainer = true,
  ...rest
}) => {
  return (
    <CustomFormControl width={width}>
      {showLabel && (
        <CustomSelectLabel
          visibility={label}
          id={id}>
          {label}
        </CustomSelectLabel>
      )}
      <CustomSelect
        displayEmpty
        labelId={labelId}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
        renderValue={(selected) => {
          return value !== '' ? (
            menuItems.find((menuItem) => menuItem.key === selected)?.value
          ) : (
            <Typography
              variant="bodyLarge"
              color={primaryColors.primary600}>
              {placeholder}
            </Typography>
          );
        }}
        {...rest}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.key}
            value={item.key}
            sx={{ justifyContent: 'space-between' }}>
            <Typography variant="bodyLarge">{item.value}</Typography>
            <ListItemIcon>{item.rightIcon}</ListItemIcon>
          </MenuItem>
        ))}
      </CustomSelect>
    </CustomFormControl>
  );
};

export default PSelectWithIcon;
