import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import MainLayout from 'layout/mainLayout/MainLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import DeliveryAddressExcelCheck from 'pages/deliveryAddress/deliveryAddressExcelCheck/DeliveryAddressExcelCheck';
import AddBulkAddressExcel from 'pages/deliveryAddress/deliveryAdressList/AddBulkAddressExcel';
import DeliveryAddressList from 'pages/deliveryAddress/deliveryAdressList/DeliveryAddressList';
import UpsertDeliveryAddress from 'pages/deliveryAddress/upsertDeliveryAddress/UpsertDeliveryAddress';

export const deliveryAddressRoutes = [
  {
    path: `${paths.customer}${paths.myDeliveryAdresses}`,
    element: (
      <ProtectedRoute path={`${paths.customer}${paths.myDeliveryAdresses}`}>
        <MainLayout>
          <DeliveryAddressList />
        </MainLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.add}${paths.customer}${paths.myDeliveryAdresses}`,
    element: (
      <ProtectedRoute path={`${paths.customer}${paths.myDeliveryAdresses}`}>
        <ChildLayout>
          <UpsertDeliveryAddress />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.customer}${paths.myDeliveryAdresses}${paths.checkExcel}`,
    element: (
      <ProtectedRoute path={`${paths.customer}${paths.myDeliveryAdresses}`}>
        <ChildLayout>
          <DeliveryAddressExcelCheck />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.customer}${paths.myDeliveryAdresses}${paths.createBulk}`,
    element: (
      <ProtectedRoute path={`${paths.customer}${paths.myDeliveryAdresses}${paths.createBulk}`}>
        <ChildLayout>
          <AddBulkAddressExcel />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
];
