import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { greenColors, primaryColors, redColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from 'svgs/logo.svg';
import { formatPrice } from 'utils/HelperFunctions';

const ProductCard = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '8px',
  padding: '0 12px',
  backgroundColor: '#ffffff',
  justifyContent: 'space-between',
  overflowY: 'auto',
}));

const ImageWithBorder = styled('img')(() => ({
  width: '40px',
  height: '40px',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '4px',
  marginTop: '12px',
}));

const ProductDetailInfoContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  height: '100%',
}));

const ProductDetails = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  marginTop: '12px',
  marginBottom: '8px',
}));

const ProductInfo = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '6px',
}));

const ProductPrice = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',
  textWrap: 'nowrap',
  width: '160px',
}));

const BasketItem = ({
  stockCode,
  itemName,
  quantity,
  unitPrice,
  totalPrice,
  stock,
  currency,
  maturityPeriodDescription,
  productBayinetUrl,
  stockDisplay,
}) => {
  const { t } = useTranslation();
  return (
    <ProductCard>
      <ProductDetailInfoContainer>
        <ImageWithBorder
          src={logo}
          alt="Product Image"
        />
        <ProductDetails>
          <Typography
            sx={{ width: '766px' }}
            variant="titleMedium"
            color={primaryColors.primary}>
            {itemName}
          </Typography>
          <ProductInfo>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('configMaster:stockCode')}
              </Typography>
              <Box
                component={Link}
                className="hyperlink-text"
                to={productBayinetUrl}
                target="_blank">
                {stockCode}
              </Box>
            </Box>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('configMaster:piece')}
              </Typography>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${quantity} ${t('configMaster:piece')}`}
              </Typography>
            </Box>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('configMaster:unitPrice')}
              </Typography>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${formatPrice(unitPrice)} ${currency}`}
              </Typography>
            </Box>
            <Box className="d-flex flex-column">
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('configMaster:maturity')}
              </Typography>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {`${maturityPeriodDescription}`}
              </Typography>
            </Box>
          </ProductInfo>
        </ProductDetails>
      </ProductDetailInfoContainer>
      <ProductPrice>
        <Typography
          variant="titleMedium"
          color={primaryColors.primary}>
          {`${formatPrice(totalPrice)} ${currency}`}
        </Typography>
        {stock > 0 && (
          <Typography
            variant="bodySmall"
            color={greenColors.green600}>
            {`${t('configMaster:stock')}: ${stockDisplay} ${t('configMaster:piece')}`}
          </Typography>
        )}
        {stock <= 0 && (
          <Typography
            variant="bodySmall"
            color={redColors.red600}>
            {t('configMaster:insufficientStock')}
          </Typography>
        )}
      </ProductPrice>
    </ProductCard>
  );
};

export default BasketItem;
