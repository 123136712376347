import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, COMMON_API, CONFIG_MASTER_API } from 'constants/Keys';

const CONFIG_MASTER = '/config-master';
const TEMPLATES = '/templates';
const COMPONENTS = '/components';
const PRODUCT_DETAIL = '/product-detail';
const VARIANT = '/config-master/variant';
const GET_VARIANT_BY_ID = '/getvariantbyId';
const VARIANTS_BY_TEMPLATE = '/variantsbytemplate';
const PRODUCT_PRICES = '/product-prices';
const SIMULATE_BEFORE_CART = '/simulate-before-cart';
const CART = '/config-master/cart';
const ADD_TO_CART = '/add-to-cart';
const GET_CARTS = '/get-carts';
const UPDATE_CART = '/update-cart';
const GET_CART_ITEMS = '/get-cart-items';
const SET_CART_DEFAULT = '/set-cart-default';
const SIMULATE_IN_CART = '/simulate-in-cart';
const CAMPAIGNS = '/campaigns';
const SIMULATE_BEFORE_ORDER = '/simulate-before-order';
const PAYMENT_RESULT = '/payment-result';
const ADD_CONTRACT_PERSON = '/add-contract-person';

const CREATE_ORDER = '/create-order';

export const getConfigMasterTemplates = () => httpRequest.get(`${CONFIG_MASTER_API}${API}${CONFIG_MASTER}${TEMPLATES}`);

export const getComponentsByTemplate = (params) => {
  return httpRequest.post(`${CONFIG_MASTER_API}${API}${CONFIG_MASTER}${COMPONENTS}`, { ...params });
};

export const getItemDetail = (productId) => httpRequest.get(`${CONFIG_MASTER_API}${API}${CONFIG_MASTER}${PRODUCT_DETAIL}/${productId}`);

export const getVariantsByTemplate = (templateId) => httpRequest.get(`${CONFIG_MASTER_API}${API}${VARIANT}${VARIANTS_BY_TEMPLATE}/${templateId}`);

export const getVariantById = (id) => httpRequest.get(`${CONFIG_MASTER_API}${API}${VARIANT}${GET_VARIANT_BY_ID}/${id}`);

export const saveVariant = ({ name, template, productIds }) => {
  const convertedProducts = productIds.map((item) => ({ productId: item.value, count: item.count }));
  return httpRequest.post(`${CONFIG_MASTER_API}${API}${VARIANT}`, {
    name,
    template,
    productIds: convertedProducts,
  });
};

export const updateVariant = ({ name, template, productIds }) => {
  const convertedProducts = productIds.map((item) => ({ productId: item.value, count: item.count }));

  return httpRequest.put(`${CONFIG_MASTER_API}${API}${VARIANT}`, {
    name,
    template,
    productIds: convertedProducts,
  });
};

export const deleteVariant = (id) => httpRequest.delete(`${CONFIG_MASTER_API}${API}${VARIANT}/${id}`);

export const getPriceOfGivenId = (array) =>
  httpRequest.get(`${CONFIG_MASTER_API}${API}${CONFIG_MASTER}${PRODUCT_PRICES}`, {
    params: { ProductIds: array },
    paramsSerializer: {
      indexes: null,
    },
  });

export const simulateBeforeCart = (configIds, products, customerDefaultCurrency) =>
  httpRequest.post(`${CONFIG_MASTER_API}${API}${CONFIG_MASTER}${SIMULATE_BEFORE_CART}`, {
    configIds: [...configIds],
    products: [...products],
    customerDefaultCurrency,
  });

export const addToCart = (quantity, maturityPeriod, maturityPeriodDescription, cartItems, configIds, customerDefaultCurrency) =>
  httpRequest.post(`${CONFIG_MASTER_API}${API}${CART}${ADD_TO_CART}`, {
    quantity,
    maturityPeriod,
    maturityPeriodDescription,
    cartItems: [...cartItems],
    configIdList: [...configIds],
    customerDefaultCurrency,
  });

export const getCarts = () => httpRequest.get(`${CONFIG_MASTER_API}${API}${CART}${GET_CARTS}`);

export const deleteCart = (id) => httpRequest.delete(`${CONFIG_MASTER_API}${API}${CART}/${id}`);

export const updateCart = (id, cartName) =>
  httpRequest.put(`${CONFIG_MASTER_API}${API}${CART}${UPDATE_CART}`, {
    id,
    cartName,
  });

export const getCartItems = (id) =>
  httpRequest.get(`${CONFIG_MASTER_API}${API}${CART}${GET_CART_ITEMS}`, {
    params: {
      CartId: id,
      page: 1,
      pageSize: 1000,
    },
  });

export const changeActiveCart = (id) =>
  httpRequest.put(`${CONFIG_MASTER_API}${API}${CART}${SET_CART_DEFAULT}`, {
    id,
  });

export const simulateInCart = (maturity, maturityText, configIds, products, customerDefaultCurrency) =>
  httpRequest.post(`${CONFIG_MASTER_API}${API}${CART}${SIMULATE_IN_CART}`, {
    maturity,
    maturityText,
    configIds,
    products: [...products],
    customerDefaultCurrency,
  });

export const getCampaigns = (template) => httpRequest.get(`${CONFIG_MASTER_API}${API}${CONFIG_MASTER}${CAMPAIGNS}/${template}`);

export const simulateBeforeOrder = (maturity, maturityText, configIds, products, customerDefaultCurrency, shippingType = null, receiver = null) =>
  httpRequest.post(`${CONFIG_MASTER_API}${API}${CART}${SIMULATE_BEFORE_ORDER}`, {
    maturity,
    maturityText,
    shippingType,
    configIds,
    products: [...products],
    customerDefaultCurrency,
    receiver,
  });

export const createOrder = (data) => httpRequest.post(`${CONFIG_MASTER_API}${API}${CART}${CREATE_ORDER}`, { ...data });

export const getPaymentResult = (key) => httpRequest.get(`${COMMON_API}${API}${PAYMENT_RESULT}/${key}`);

export const createPersonFromCart = ({
  firstName,
  lastName,
  phoneNumber,
  emailAddress,
  isUpdate,
  contractPersonType,
  invoiceTransmissionType,
  storagePlace,
}) =>
  httpRequest.post(`${CONFIG_MASTER_API}${API}${CART}${ADD_CONTRACT_PERSON}`, {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    isUpdate,
    contractPersonType,
    invoiceTransmissionType,
    storagePlace,
  });
