/* eslint-disable max-len */
export const LoginEn = {
  login: 'Log In',
  privileges: 'Log in now to benefit from the privileges!',
  customerCode: 'Customer code',
  email: 'E-mail address',
  password: 'Password',
  forgotPassword: 'Forgot Password',
  pleaseEnterYourCustomerCode: 'Please enter your customer code.',
  pleaseEnterYourEmailAddress: 'Please enter your e-mail address or mobile phone number.',
  pleaseEnterYourPassword: 'Plase enter your password.',
  emailOrPhone: 'E-mail or phone number',
  enterVerificationCode: 'Enter Verification Code',
  verificationCodeSendTo: 'Verification code sent to {{phoneNumber}}. Please enter the code in the incoming message into the field below.',
  verificationCodeSendToMail: 'Verification code sent to {{mail}}. Please enter the code in the incoming e-mail into the field below.',
  remainingTime: 'Remaining time',
  resendCode: 'Resend Code',
  verify: 'Verify',
  sendCodeToEmailAddress: 'Send Code to E-mail Address',
  sendCodeToPhoneNumbber: 'Send Code to Phone Number',
  signInWithGoogle: 'Sign In with Authenticator',
  send: 'Send',
  enterGoogleAuthCode: 'Enter Authenticator Code',
  enterCodeGeneratedByApp: 'Please enter a code generated by your authenticator application in the field below',
  scanQrCodeThenFillTheInputBelow: 'Scan QR code then fill the input below',
  theVerificationCodeWillBeSentToTheEmailAddress: 'The verification code will be sent to the {{mail}}.',
  theVerificationCodeWillBeSentToThePhoneNumber: 'The verification code will be sent to {{phone}} phone number.',
  yes: 'Yes',
  no: 'No',
  authenticationFailedPleaseTryAgain: 'Authentication failed, please try again',
  thisFieldIsRequired: 'Please enter {{field}}.',
  equalValidation: '{{firstField}} must match with {{secondField}}.',
  newPassword: 'New password',
  newPasswordAgain: 'New password again',
  mobile: 'Mobile',
  save: 'Save',
  pentaUserTitle: 'Our password policy and infrastructure have changed.',
  pentaUserSubtitle: 'For your security, please update your information.',
  newCustomer: 'New Customer',
  newCustomerFirstPart:
    'Penta Teknoloji Ürünleri Dağıtım Ticaret A.Ş. has been prepared for use by our dealers. To enable your access, we kindly request you to complete the Online Dealer Application Form and send us the Dealer Agreement with a wet signature.',
  newCustomerSecondPart:
    'The documents and forms are in PDF format. To view and fill out the form, you need to have Adobe Acrobat Reader installed on your device.',
  onlineDealerApplicationForm: 'Online Dealer Application Form',
  dealerApplicationStatusCheck: 'Dealer Application Status Check',
  googleAuthApproved: 'Google Authenticator Verification Successful.',
};
