import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, styled, Typography } from '@mui/material';
import { greenColors, redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { checkUpdateMailValid, checkUpdateMobileValid } from 'pages/approveUpdate/api/ApproveUpdateApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  margin: '16px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px 24px',
  rowGap: '20px',
  gridColumnStart: 4,
  gridColumnEnd: 10,
}));

const ApproveUpdate = ({ type }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [isTokenValid, setIsTokenValid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(paths.home);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (type === 'mobile') {
      checkUpdateMobileValid(params.token)
        .then(() => {
          setIsTokenValid(true);
        })
        .catch((err) => {
          setIsTokenValid(false);
          toast.error(err.response.data.Message);
        });
    } else if (type === 'mail') {
      checkUpdateMailValid(params.token)
        .then(() => {
          setIsTokenValid(true);
        })
        .catch((err) => {
          setIsTokenValid(false);
          toast.error(err.response.data.Message);
        });
    }
  }, []);

  return (
    <GridContainer>
      <ResponseContainer>
        {isTokenValid && (
          <Box className="flex-column d-flex">
            <CheckCircleIcon
              sx={{ color: greenColors.green500 }}
              fontSize="large"
            />
            <Typography variant="titleLarge">{t('approveUpdate:theVerificationProceessIsSuccessful')}</Typography>
            <Typography variant="bodyMedium">{t('approveUpdate:youAreDirectedToTheLoginScreen')}</Typography>
          </Box>
        )}
        {isTokenValid === false && (
          <Box className="flex-column d-flex">
            <CancelIcon
              sx={{ color: redColors.red600 }}
              fontSize="large"
            />
            <Typography variant="titleLarge">{t('approveUpdate:invalidVerificationCode')}</Typography>
            <Typography variant="bodyMedium">{t('approveUpdate:youAreDirectedToTheLoginScreen')}</Typography>
          </Box>
        )}
      </ResponseContainer>
    </GridContainer>
  );
};

export default ApproveUpdate;
