'http://10.87.7.77:8083/api/storage/cart/get-similar-products?searchKey=123';
import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, STORAGE_API } from 'constants/Keys';
const STORAGE = '/storage';
const CART = '/cart';
const GET_SIMILAR_PRODUCTS = '/get-similar-products';
const CALCULATE = '/calculate';
export const getSimiliarItems = (searchKey) => httpRequest.get(`${STORAGE_API}${API}${STORAGE}${CART}${GET_SIMILAR_PRODUCTS}?searchKey=${searchKey}`);

export const calculateTableData = (data) => httpRequest.post(`${STORAGE_API}${API}${STORAGE}${CART}${CALCULATE}`, data);
