import { useTheme } from '@emotion/react';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PDatePicker from 'components/datePicker/PDatePicker';
import EmptyScreenMessage from 'components/emptyScreenMessage/EmptyScreenMessage';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PClientTable from 'components/table/PClientTable';
import { primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { numberToDecimal, splitWithDot } from 'utils/HelperFunctions';
import * as XLSX from 'xlsx';
import MyShipmentsDetail from './MyShipmentsDetail';
import { getMyShipments, getPdf } from './api/MyShipmentsApi';
import PdfModal from './components/PdfModal';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '&>*': {
      flex: 1,
      width: '100% !important',
    },
  },
}));

const MyShipments = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ allData: [], visibleData: [] });
  const [localSearch, setLocalSearch] = useState('');
  const [canSeeResults, setCanSeeResults] = useState(false);
  const [customerOrderNo, setCustomerOrderNo] = useState('');
  const [dateInterval, setDateInterval] = useState({
    startDate: dayjs().subtract(7, 'days'),
    endDate: dayjs(),
  });
  const [detailData, setDetailData] = useState(null);
  const [openPdfModal, setOpenPdfModal] = useState(null);
  const goToStorageDetail = (row) => {
    setDetailData(row);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'registrationDate',
        headerName: t('myShipments:date'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ value }) => dayjs(value).format('DD.MM.YYYY'),
      },
      {
        field: 'deliveryNo',
        headerName: t('myShipments:shipmentNumber'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ value, row }) => {
          if (row.statusDescription === 'Sevkiyat Bekliyor') {
            return Number(value);
          } else {
            return (
              <div
                className="hyperlink-text"
                onClick={() => {
                  setLoading(true);
                  getPdf(value)
                    .then((res) => {
                      setOpenPdfModal(res.data.data.waybillPdf);
                    })
                    .catch((err) => {
                      toast.error(err.response.data.Message);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}>
                {Number(value)}
              </div>
            );
          }
        },
      },
      {
        field: 'transportCompany',
        headerName: t('myShipments:shippingCompany'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'deliveryType',
        headerName: t('myShipments:shipmentType'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'receiver',
        headerName: t('myShipments:buyer'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'statusDescription',
        headerName: t('myShipments:status'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'receiverPerson',
        headerName: t('myShipments:deliveryInformation'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'volume',
        headerName: t('myShipments:volume'),
        minWidth: 110,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderCell: ({ row }) => `${splitWithDot(Math.round(row.volume), 0)} ${row.volumeType}`,
      },
      {
        field: 'transportCost',
        headerName: t('myShipments:transportationCost'),
        minWidth: 110,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderCell: ({ row }) => `${numberToDecimal(row.transportCost)} ${row.curreny}`,
      },
      {
        field: 'customerOrderNumber',
        headerName: t('myShipments:customerOrderNumber'),
        minWidth: 110,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'trackingLink',
        headerName: '',
        width: 165,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => (
          <PButton
            className="cursor-pointer"
            disabled={!value}
            inputtype="text"
            startIcon={<LocalShippingOutlinedIcon />}
            onClick={() => {
              window.open(value, '_blank');
            }}>
            {t('myShipments:cargoTracking')}
          </PButton>
        ),
      },
      {
        field: 'id',
        headerName: '',
        width: 72,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <ChevronRightOutlinedIcon
            className="cursor-pointer"
            onClick={() => goToStorageDetail(row)}
          />
        ),
      },
    ];
  }, [t]);

  const handleSearchChange = (event) => {
    setCustomerOrderNo(event.target.value);
  };

  const toExcelHandler = () => {
    const sheet2 = [];
    const sheet3 = [];
    const sheet1 = data.allData.map((item) => ({
      [t('myShipments:date')]: dayjs(item.registrationDate).format('DD.MM.YYYY'),
      [t('myShipments:shipmentNumber')]: Number(item.deliveryNo),
      [t('myShipments:shippingCompany')]: item.transportCompany,
      [t('myShipments:shipmentType')]: item.deliveryType,
      [t('myShipments:buyer')]: item.receiver,
      [t('myShipments:status')]: item.statusDescription,
      [t('myShipments:deliveryInformation')]: item.receiverPerson,
      [t('myShipments:volume')]: `${item.volume} ${item.volumeType}`,
      [t('myShipments:transportationCost')]: `${item.transportCost} ${item.curreny}`,
      [t('myShipments:customerOrderNumber')]: item.customerOrderNumber,
    }));

    data.allData.forEach((item) => {
      item.items.forEach((insideElement) => {
        sheet2.push({
          [t('myShipments:shipmentNumber')]: Number(insideElement.deliveryNo),
          [t('myShipments:shipmentReferenceNo')]: Number(insideElement.entryNumber),
          [t('myShipments:productNo')]: Number(insideElement.productNumber),
          [t('myShipments:productName')]: insideElement.productDescription,
          [t('myShipments:referenceDocument')]: Number(insideElement.referenceDocument),
          [t('myShipments:referencePen')]: insideElement.referenceEntry,
          [t('myShipments:volume')]: `${insideElement.volume} ${insideElement.volumeType}`,
          [t('myShipments:amount')]: insideElement.quantity,
        });
      });
    });
    data.allData.forEach((item) => {
      item.items.forEach((insideElement) => {
        sheet3.push({
          [t('myShipments:shipmentNumber')]: Number(insideElement.deliveryNo),
          [t('myShipments:shipmentReferenceNo')]: Number(insideElement.entryNumber),
          [t('myShipments:productNo')]: Number(insideElement.productNumber),
          [t('myShipments:serialNo')]: insideElement.serialNumbers
            .map((serialNumbers) => {
              return serialNumbers.serialNumber;
            })
            .join(','),
        });
      });
    });

    const fileName = `Bayinet ${dayjs(new Date()).format('DD.MM.YYYY')}`;
    const worksheet1 = XLSX.utils.json_to_sheet(sheet1);
    const worksheet2 = XLSX.utils.json_to_sheet(sheet2);
    const worksheet3 = XLSX.utils.json_to_sheet(sheet3);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, t('myShipments:header'));
    XLSX.utils.book_append_sheet(workbook, worksheet2, t('myShipments:entryNo'));
    XLSX.utils.book_append_sheet(workbook, worksheet3, t('myShipments:serialNumberList'));

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const onListHandler = () => {
    setLoading(true);
    getMyShipments({
      startDate: dayjs(dateInterval.startDate).format('DD.MM.YYYY'),
      endDate: dayjs(dateInterval.endDate).format('DD.MM.YYYY'),
      customerOrderNo: customerOrderNo,
    })
      .then((res) => {
        const localData = res.data.data.deliveries;
        localData.forEach((item, index) => {
          item.id = index + 1;
        });
        setData({ allData: localData, visibleData: localData });
        setCanSeeResults(true);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const localSearchHandler = (event) => {
    const value = event.target.value;

    setLocalSearch(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({
          deliveryNo: e.deliveryNo,
          transportCompany: e.transportCompany,
          deliveryType: e.deliveryType,
          receiver: e.receiver,
          mobile: e.mobile,
          statusDescription: e.statusDescription,
          receiverPerson: e.receiverPerson,
          volume: e.volume,
          customerOrderNumber: e.customerOrderNumber,
        })
          .join('')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };

  return (
    <Box className="d-flex flex-column gap-16">
      <HeaderContainer>
        <PDatePicker
          width={matchesMd ? '100%' : 'auto'}
          label={t('myShipments:selectStartingDate')}
          value={dateInterval.startDate}
          onChange={(value) => {
            setDateInterval((prevState) => ({ ...prevState, startDate: value }));
          }}
        />
        <PDatePicker
          width={matchesMd ? '100%' : 'auto'}
          label={t('myShipments:selectEndDate')}
          value={dateInterval.endDate}
          onChange={(value) => {
            setDateInterval((prevState) => ({ ...prevState, endDate: value }));
          }}
        />
        <TableSearchTextField
          sx={{ width: matchesMd ? '100%' : '350px' }}
          placeholder={t('myShipments:searchByCustomerNumber')}
          value={customerOrderNo}
          onChange={handleSearchChange}
          showLabel
          showHelperContainer
        />
        <PButton
          sx={{ width: 'auto' }}
          onClick={onListHandler}>
          {t('myShipments:list')}
        </PButton>
      </HeaderContainer>
      {loading && <LoadingSpinner />}
      {!canSeeResults && (
        <Box sx={{ marginTop: '52px' }}>
          <EmptyScreenMessage
            title={t('myShipments:emptyComponentTitle')}
            subTitle={t('myShipments:emptyComponentSubTitle')}
          />
        </Box>
      )}
      {canSeeResults && (
        <>
          {data?.allData.length > 0 && (
            <PClientTable
              headerTitle={t('storage:shipments')}
              dataLength={`${data.allData.length} ${t('myShipments:piece')}`}
              columns={columns}
              rows={data.visibleData}
              headerRightSide={
                <div className="d-flex gap-12 flex-wrap justify-flex-end">
                  <TableSearchTextField
                    sx={{ width: matchesSm ? '100%' : '300px' }}
                    placeholder={t('myShipments:doSearch')}
                    value={localSearch}
                    onChange={localSearchHandler}
                  />
                  <PButton
                    sx={{ width: matchesSm ? '100%' : 'auto', whiteSpace: 'nowrap' }}
                    inputtype="outline"
                    disabled={data.allData.length <= 0}
                    onClick={toExcelHandler}
                    startIcon={<DownloadOutlinedIcon />}>
                    {t('myShipments:excelPrintout')}
                  </PButton>
                </div>
              }
              getRowHeight={() => 'auto'}
            />
          )}
          {detailData && (
            <PCenterModal
              onClose={() => setDetailData(null)}
              width="75%"
              title={t('myShipments:shipmentProductsNo', { number: Number(detailData.deliveryNo) })}>
              <MyShipmentsDetail detailData={detailData} />
            </PCenterModal>
          )}
          {data?.allData.length === 0 && (
            <Box className="text-center">
              <Typography
                color={primaryColors.primary400}
                variant="titleMedium">
                {t('myShipments:noResultsFound')}
              </Typography>
            </Box>
          )}
          {openPdfModal && (
            <PCenterModal
              onClose={() => setOpenPdfModal(null)}
              showClose
              width="896px"
              height="90%">
              <PdfModal pdf={openPdfModal} />
            </PCenterModal>
          )}
        </>
      )}
    </Box>
  );
};

export default MyShipments;
