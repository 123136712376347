/* eslint-disable max-len */
export const ContractAgreementTr = {
  canChange:
    'Sözleşmeyi onayladığınız takdirde, sözleşmeyi okuduğunuzu ve sözleşmede yazan şartlarla bağlı olduğunuzu kabul etmiş sayılırsınız. Bu karar sonradan sözleşmelerim sayfasından değiştirebilirsiniz.',
  cantChange:
    'Sözleşmeyi onayladığınız takdirde, sözleşmeyi okuduğunuzu ve sözleşmede yazan şartlarla bağlı olduğunuzu kabul etmiş sayılırsınız. Bu karar sonradan değiştirilemez.  ',
  approve: 'Onaylıyorum',
  reject: 'Reddediyorum',
  ok: 'Tamam',
  remindLater: 'Daha Sonra Hatırlat',
};
