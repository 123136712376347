import { Grid, Typography } from '@mui/material';
import { createCurrentUser } from 'common/reducers/CurrentUserReducer';
import PButton from 'components/button/PButton';
import OtpInput from 'components/otpInput/OtpInput';
import { slateColors } from 'config/theme/Colors';
import { statusCodes } from 'constants/HttpStatusCodes';
import { paths } from 'constants/Paths';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { generateQrCode, loginWithGoogleAuth, validateUserQr } from './api/LoginApi';
import SendOtpToMail from './components/SendOtpToMail';
import SendOtpToPhone from './components/SendOtpToPhone';

const GoogleAuthenticator = ({ formField, setIsLoading, setFormField, setPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showQr, setShowQr] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    validateUserQr({ emailOrPhone: formField.email })
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === statusCodes.preconditionRequired) {
          generateQrCode({ emailOrPhone: formField.email })
            .then((res) => {
              setShowQr(res.data.message);
            })
            .catch((err) => {
              toast.err(err.response.data.Message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          toast.err(err.response.data.Message);
        }
      });
  }, []);

  const mailModalOpenHandler = () => {
    setOpenEmailModal((prevState) => !prevState);
  };

  const phoneModalOpenHandler = () => {
    setOpenPhoneModal((prevState) => !prevState);
  };

  const verify = () => {
    if (otpValue?.length === 6) {
      setIsLoading(true);
      loginWithGoogleAuth({ otp: otpValue, emailOrPhone: formField.email })
        .then((res) => {
          dispatch(createCurrentUser(res.data.data));
          navigate(paths.protectedHome);
        })
        .catch((err) => {
          if (err.response.status === statusCodes.preconditionRequired) {
            // burada maile onay linkinin iletildiği bilgisi aktarılacak
          }
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      toast.error(t('login:authenticationFailedPleaseTryAgain'));
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ paddingRight: 2, paddingBottom: 2, backgroundColor: '#ffffff', borderRadius: '12px' }}>
      <Grid
        item
        xs={12}>
        <Typography
          variant="headlineSmall"
          sx={{ color: slateColors.slate950 }}>
          {t('login:enterGoogleAuthCode')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}>
        <Typography
          variant="bodyMedium"
          sx={{ color: slateColors.slate600 }}>
          {t('login:enterCodeGeneratedByApp')}
        </Typography>
      </Grid>
      {showQr && (
        <>
          <Grid
            item
            className="text-center"
            xs={12}>
            <img
              src={showQr}
              alt="Qr"
            />
          </Grid>
          <Grid
            item
            xs={12}>
            <Typography variant="bodyMedium">{t('login:scanQrCodeThenFillTheInputBelow')}</Typography>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}>
        <OtpInput
          value={otpValue}
          onChange={setOtpValue}
        />
      </Grid>
      <Grid
        item
        xs={12}
        className="text-end cursor-pointer">
        <PButton onClick={verify}>{t('login:verify')}</PButton>
      </Grid>
      <Grid
        item
        xs={12}
        className="text-center cursor-pointer">
        <Typography
          variant="labelLarge"
          sx={{ color: slateColors.slate600 }}
          onClick={mailModalOpenHandler}>
          <b>{t('login:sendCodeToEmailAddress')}</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className="text-center cursor-pointer">
        <Typography
          variant="labelLarge"
          sx={{ color: slateColors.slate600 }}
          onClick={phoneModalOpenHandler}>
          <b>{t('login:sendCodeToPhoneNumbber')}</b>
        </Typography>
      </Grid>
      {openPhoneModal && (
        <SendOtpToPhone
          modalOpenHandler={phoneModalOpenHandler}
          setIsLoading={setIsLoading}
          setFormField={setFormField}
          formField={formField}
          setPage={setPage}
        />
      )}
      {openEmailModal && (
        <SendOtpToMail
          modalOpenHandler={mailModalOpenHandler}
          setIsLoading={setIsLoading}
          setFormField={setFormField}
          formField={formField}
          setPage={setPage}
        />
      )}
    </Grid>
  );
};

export default GoogleAuthenticator;
