import { Box, styled, Typography } from '@mui/material';
import PAccordion from 'components/accordion/PAccordion';
import PAccordionDetail from 'components/accordion/PAccordionDetail';
import PAccordionSummary from 'components/accordion/PAccordionSummary';
import { primaryColors } from 'config/theme/Colors';
import { Image, ImageItem, Item } from 'pages/pentaStorage/storageIndex/StorageIndex';
import { useTranslation } from 'react-i18next';
import image1 from './media/image1.svg';
import image2 from './media/image2.svg';
import image3 from './media/image3.svg';
import image4 from './media/image4.svg';
import image5 from './media/image5.svg';
import image6 from './media/image6.svg';

const AccordionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 24px',
  marginBottom: '24px',
}));

const Header = styled(Box)(() => ({
  padding: '0 8px',
}));

const AccordionPart = () => {
  const { t } = useTranslation();

  return (
    <AccordionContainer>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('pay:firstTitle')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('pay:firstTitleFirstParagraph')}
            </Typography>
          </Item>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('pay:secondTitle')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Box className="d-flex flex-column gap-16">
            <Item>
              <Typography
                variant="titleMedium"
                color={primaryColors.primary800}>
                {t('pay:searchByManufacturerContractNumber')}
              </Typography>
            </Item>
            <Item>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('pay:secondTitleFirstParagraph')}
              </Typography>
            </Item>
            <ImageItem>
              <Image
                width={'100%'}
                src={image1}
              />
            </ImageItem>
            <ImageItem>
              <Image
                width={'100%'}
                src={image2}
              />
            </ImageItem>
            <Item>
              <Typography
                variant="titleMedium"
                color={primaryColors.primary800}>
                {t('pay:searchByPentaSubscriptionNumber')}
              </Typography>
            </Item>
            <Item>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('pay:secondTitleSecondParagraph')}
              </Typography>
            </Item>
          </Box>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('pay:thirdTitle')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Box className="d-flex flex-column gap-16">
            <Item>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('pay:thirdTitleFirstParagraph')}
              </Typography>
            </Item>
            <ImageItem>
              <Image
                width={'100%'}
                src={image3}
              />
            </ImageItem>
            <Item>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('pay:thirdTitleSecondParagraph')}
              </Typography>
            </Item>
            <ImageItem>
              <Image
                width={'100%'}
                src={image4}
              />
            </ImageItem>
            <Item>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {t('pay:thirdTitleThirdParagraph')}
              </Typography>
            </Item>
            <ImageItem>
              <Image
                width={'100%'}
                src={image5}
              />
            </ImageItem>
            <ImageItem>
              <Image
                width={'100%'}
                src={image6}
              />
            </ImageItem>
          </Box>
        </PAccordionDetail>
      </PAccordion>
    </AccordionContainer>
  );
};

export default AccordionPart;
