import { Box, styled } from '@mui/material';

const Container = styled(Box)(() => ({
  padding: '0 32px',
}));

const MainContainer = ({ children }) => {
  return <Container className="d-flex flex-column">{children}</Container>;
};

export default MainContainer;
