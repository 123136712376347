import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary } from '@mui/material';

const PAccordionSummary = ({ children, sx }) => {
  return (
    <AccordionSummary
      className="w100"
      sx={sx}
      expandIcon={<ExpandMoreIcon />}
      aria-controls="category-content"
      id={children.toString()}>
      {children}
    </AccordionSummary>
  );
};

export default PAccordionSummary;
