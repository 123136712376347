import { useTheme } from '@emotion/react';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Tab, Typography, useMediaQuery } from '@mui/material';
import PInfoChip from 'components/chip/PInfoChip';
import TabPanel from 'components/tabs/PTabPanel';
import PTabs from 'components/tabs/PTabs';
import { neutralColors, primaryColors } from 'config/theme/Colors';
import { MarginContainer } from 'layout/container/MarginContainer';
import Calculator from 'pages/pentaStorage/Tabs/calculator/Calculator';
import MyShipments from 'pages/pentaStorage/Tabs/myShipments/MyShipments';
import StorageCart from 'pages/pentaStorage/Tabs/storageCart/StorageCart';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeliveryDetails from './DeliveryDetails/DeliveryDetails';
import ProductsInStorage from './Tabs/productsInStorage/ProductsInStorage';
import { controlForDefaultStorageCart, getStorageItemCount } from './Tabs/storageCart/api/StorageCartApi';
import StorageCostsContainer from './Tabs/storageCosts/StorageCostsContainer';
import { tabNumbers } from './constants/TabNumbers';
const PentaStorageContainer = () => {
  const theme = useTheme();
  const smallerSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location?.state || 0);
  const [cartItemNumber, setCartItemNumber] = useState(0);
  const [callApi, setCallApi] = useState(false);
  const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);
  useEffect(() => {
    controlForDefaultStorageCart()
      .then(() => {
        getCartCount();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  }, []);

  const getCartCount = () => {
    getStorageItemCount()
      .then((res) => {
        setCartItemNumber(res.data.data.itemCount);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const handleChange = (_event, newValue) => {
    if (newValue === 3) {
      setCallApi(true);
    } else {
      setCallApi(false);
    }
    setTabValue(newValue);
  };

  return (
    <>
      {!showDeliveryDetails && (
        <MarginContainer className="flex-column">
          <>
            <Box className="d-flex align-items-center justify-content-between">
              {!smallerSm && (
                <Box className="flex-column">
                  <Typography
                    variant="titleLarge"
                    sx={{ color: neutralColors.neutral950 }}>
                    {t('storage:pentaMyStorage')}
                  </Typography>
                  <Typography
                    variant="bodyMedium"
                    sx={{ color: primaryColors.primary700 }}>
                    {t('storage:checkYourPentaStorage')}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              <PTabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ padding: ' 20px 0' }}>
                <Tab
                  iconPosition="start"
                  icon={<LocalOfferOutlinedIcon fontSize="small" />}
                  label={t('storage:storageCosts')}
                />
                <Tab
                  iconPosition="start"
                  icon={<Inventory2OutlinedIcon fontSize="small" />}
                  label={t('storage:productsInStorage')}
                />
                <Tab
                  iconPosition="start"
                  icon={<LocalShippingOutlinedIcon fontSize="small" />}
                  label={t('storage:shipments')}
                />
                <Tab
                  label={
                    <div className="d-flex align-items-center gap-8">
                      <ShoppingCartOutlinedIcon fontSize="small" /> {t('storage:storageCart')}
                    </div>
                  }
                  iconPosition="end"
                  icon={<PInfoChip label={`${cartItemNumber}`} />}
                />
                <Tab
                  iconPosition="start"
                  icon={<CalculateOutlinedIcon fontSize="small" />}
                  label={t('storage:storageCostSimulation')}
                />
              </PTabs>
            </Box>
            <Box>
              <TabPanel
                value={tabValue}
                index={tabNumbers.storageCosts}>
                <Box>
                  <StorageCostsContainer />
                </Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={tabNumbers.productsInStorage}>
                <Box>
                  <ProductsInStorage
                    tabValue={tabValue}
                    setCartItemNumber={setCartItemNumber}
                  />
                </Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={tabNumbers.shipments}>
                <Box>
                  <MyShipments />
                </Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={tabNumbers.storageCart}>
                <Box>
                  <StorageCart
                    callApi={callApi}
                    tabValue={tabValue}
                    setCartItemNumber={setCartItemNumber}
                    setShowDeliveryDetails={setShowDeliveryDetails}
                  />
                </Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={tabNumbers.storageCostSimulation}>
                <Box>
                  <Calculator tabValue={tabValue} />
                </Box>
              </TabPanel>
            </Box>
          </>
        </MarginContainer>
      )}

      {showDeliveryDetails && (
        <DeliveryDetails
          setCartItemNumber={setCartItemNumber}
          data={showDeliveryDetails}
          setShowDeliveryDetails={setShowDeliveryDetails}
        />
      )}
    </>
  );
};

export default PentaStorageContainer;
