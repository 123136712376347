/* eslint-disable max-len */
export const LoginTr = {
  login: 'Giriş Yap',
  privileges: 'Ayrıcalıklardan faydalanmak için hemen giriş yap!',
  customerCode: 'Müşteri kodu',
  email: 'E-posta adresi',
  password: 'Şifre',
  forgotPassword: 'Şifremi Unuttum',
  pleaseEnterYourCustomerCode: 'Lütfen müşteri kodunuzu giriniz.',
  pleaseEnterYourEmailAddress: 'Lütfen e-posta adresinizi ya da cep telefonu numaranızı giriniz.',
  pleaseEnterYourPassword: 'Lütfen şifrenizi giriniz.',
  emailOrPhone: 'E-posta veya telefon numarası',
  enterVerificationCode: 'Doğrulama Kodunu Girin',
  verificationCodeSendTo: 'Doğrulama kodu {{phoneNumber}} numarasına gönderildi. Lütfen gelen mesaj içerisindeki kodu aşağıdaki alana girin.',
  verificationCodeSendToMail: 'Doğrulama kodu {{mail}} adresine gönderildi. Lütfen gelen e-posta içerisindeki kodu aşağıdaki alana girin.',
  remainingTime: 'Kalan süre',
  resendCode: 'Kodu Tekrar Gönder',
  verify: 'Doğrula',
  sendCodeToEmailAddress: 'E-posta Adresine Kodu Gönder',
  sendCodeToPhoneNumbber: 'Telefon Numarasına Kodu Gönder',
  signInWithGoogle: 'Authenticator ile Giriş Yap',
  send: 'Gönder',
  enterGoogleAuthCode: 'Authenticator Kodunu Girin',
  enterCodeGeneratedByApp: 'Lütfen kimlik doğrulayıcı uygulamanız tarafından oluşturulan kodu aşağıdaki alana girin',
  scanQrCodeThenFillTheInputBelow: 'QR kodunu okutup aşagıdaki alanı doldurunuz',
  theVerificationCodeWillBeSentToTheEmailAddress: '{{mail}} adresine doğrulama kodu gönderilecektir.',
  theVerificationCodeWillBeSentToThePhoneNumber: '{{phone}} telefon numarasına doğrulama kodu gönderilecektir.',
  yes: 'Evet',
  no: 'Hayır',
  authenticationFailedPleaseTryAgain: 'Kimlik doğrulama başarısız oldu, lütfen tekrar deneyin.',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  equalValidation: '{{firstField}}, {{secondField}} alanı ile aynı olmalıdır.',
  newPassword: 'Yeni şifreniz',
  newPasswordAgain: 'Yeni şifreniz tekrar',
  mobile: 'Mobil',
  save: 'Kaydet',
  pentaUserTitle: 'Şifre politikamız ve altyapımız değişti.',
  pentaUserSubtitle: 'Güvenliğiniz için lütfen bilgilerinizi güncelleyiniz.',
  newCustomer: 'Yeni Müşteri',
  newCustomerFirstPart:
    'Penta Teknoloji Ürünleri Dağıtım Ticaret A.Ş. bayilerin kullanımı için hazırlanmıştır. Kullanımınıza açılması için Online Bayi Başvuru Formu’nu doldurarak ve Bayi Sözleşmesini ıslak imzali olarak bize göndermenizi rica ederiz.',
  newCustomerSecondPart:
    'Döküman ve formlar PDF formatındadır. Okumak ve formu doldurabilmek için Adobe Acrobat Reader’ın cihazınızda yüklü olması gerekmektedir.',
  onlineDealerApplicationForm: 'Online Bayi Başvuru Formu',
  dealerApplicationStatusCheck: 'Bayilik Başvurusu Onay Sorgulama',
  googleAuthApproved: 'Google Authenticator Onayı Başarılı.',
};
