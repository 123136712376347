import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PCheckbox from 'components/checkbox/PCheckbox';
const PUnselectableRadio = ({ checked, onChange, label = '', width = 'auto', ...rest }) => {
  return (
    <PCheckbox
      noWhiteSpace
      checked={checked}
      onChange={onChange}
      label={label}
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<RadioButtonCheckedIcon />}
      isRadio
      width={width}
      {...rest}
    />
  );
};
export default PUnselectableRadio;
