import { Box, styled, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import CustomerCreditCard from 'components/CustomerCreditCard';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PClientTable from 'components/table/PClientTable';
import PTooltip from 'components/tooltip/PTooltip';
import { greenColors, primaryColors, redColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import PrintableContent from 'pages/configMaster/pages/success/PrintableContent';
import { getPayPaymentResult } from 'pages/pay/api/PayApi';
import { addToCartEnums } from 'pages/pay/constants/AddToCartEnums';
import { payTabNumbers } from 'pages/pay/constants/PayTabNumbers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { formatPrice } from 'utils/HelperFunctions';
const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px',
  rowGap: '20px',
}));

const PaymentDetails = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
}));

const PaymentDetailRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: ' space-between',
  marginBottom: 16,
  borderBottom: `1px solid ${slateColors.slate200}`,
  paddingBottom: 16,
  '&>*:nth-of-type(1)': {
    width: '50%',
  },
  '&>*:nth-of-type(2)': {
    width: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
  },
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 24,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
  },
}));

const PaymentSuccessResult = () => {
  const { t } = useTranslation();
  const [paymentDetail, setPaymentDetails] = useState(null);
  const [tableData, setTableData] = useState([]);
  const printRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const keyValue = searchParams.get('key') || '';

    if (!keyValue) {
      navigate(`${paths.subscriptions}${paths.byProducts}`);
      return;
    }
    setLoading(true);
    getPayPaymentResult(keyValue)
      .then((res) => {
        setTableData(res.data.data.productInfos);
        setPaymentDetails(res.data.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const columns = useMemo(() => {
    return [
      {
        field: 'stockCode',
        headerName: `${t('pay:process')} / ${t('pay:subscription')}`,
        disableColumnMenu: true,
        minWidth: 132,
        renderCell: ({ row }) => {
          let label = '';
          if (row.type === addToCartEnums.addProduct) {
            label = 'Add Product';
          } else if (row.type === addToCartEnums.addSeat) {
            label = 'Add Seat';
          } else if (row.type === addToCartEnums.renewable) {
            label = 'Renewable';
          }
          return <Typography variant="bodyMedium">{`${label}-${Number(row.productNumber)}`}</Typography>;
        },
      },
      {
        field: 'productDefinition',
        headerName: t('configMaster:productName'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 148,
      },
      {
        field: 'quantity',
        headerName: t('configMaster:piece'),
        disableColumnMenu: true,
        minWidth: 86,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'unitPrice',
        headerName: t('configMaster:unitPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 124,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.netAmountAsDocument)} ${row.salesAndDistributionDocumentCurrency}`}</Box>;
        },
      },
      {
        field: 'totalPrice',
        headerName: t('configMaster:taxIncludedPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 140,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.netAmount)} ${row.salesAndDistributionDocumentCurrency}`}</Box>;
        },
      },
      {
        field: 'message',
        headerName: t('pay:transactionResult'),
        disableColumnMenu: true,
        minWidth: 132,
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <Typography
              variant="bodyMedium"
              color={row.messageType === 'E' ? redColors.red600 : greenColors.green600}>
              {row.message}
            </Typography>
          );
        },
      },
    ];
  }, [t]);

  const goToCart = () => {
    navigate(`${paths.subscriptions}${paths.byProducts}`, { state: payTabNumbers.subscriptionCart });
  };

  return (
    <>
      <SecondaryLayout
        onClick={goToCart}
        title={t('configMaster:orderDetails')}>
        {loading && <LoadingSpinner />}

        <ResponseContainer>
          <Box className="flex-column d-flex">
            <IconResolver
              iconName="CheckCircle"
              sx={{ color: greenColors.green500, width: '48px', height: '48px' }}
            />
            <Typography
              variant="titleLarge"
              color={primaryColors.primary}>
              {t('configMaster:orderCreatedSuccessfully')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {t('configMaster:paymentTransactionCompletedSuccessfully')}
            </Typography>
          </Box>
          <Content>
            <Box>
              <CustomerCreditCard
                bank={paymentDetail?.bank}
                cardNumber={paymentDetail?.cardNumber}
                cardHolder={paymentDetail?.cardHolder}
                installment={paymentDetail?.installmentInformation}
              />
            </Box>

            <PaymentDetails>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:paymentNo')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.paymentNo}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:erpPaymentNo')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.paymentRefNo}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:dateAndTime')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.date && dayjs(paymentDetail?.date).format('DD.MM.YYYY HH:mm')}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:customer')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.customerId}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:user')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.user}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:amount')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {`${formatPrice(paymentDetail?.amount)} TL`}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:explanation')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  <PTooltip
                    placement="bottom-start"
                    title={paymentDetail?.description}>
                    {paymentDetail?.description}
                  </PTooltip>
                </Typography>
              </PaymentDetailRow>
            </PaymentDetails>
          </Content>
          <ButtonContainer>
            <PButton
              onClick={handlePrint}
              inputtype="outline"
              startIcon={<IconResolver iconName="TextSnippetOutlined" />}>
              {t('configMaster:printPayment')}
            </PButton>
          </ButtonContainer>
          <PClientTable
            dataLength={`${tableData?.length} ${t('configMaster:piece')}`}
            headerTitle={t('configMaster:orderItems')}
            columns={columns}
            rows={tableData}
            getRowId={(row) => row.cartItemId}
            getRowHeight={() => 'auto'}
          />
        </ResponseContainer>
      </SecondaryLayout>
      {paymentDetail && (
        <PrintableContent
          paymentDetail={paymentDetail}
          ref={printRef}
        />
      )}
    </>
  );
};

export default PaymentSuccessResult;
