import ApproveModal from 'components/approveModal/ApproveModal';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { paths } from 'constants/Paths';
import PaymentLayout from 'layout/paymentLayout/PaymentLayout';
import { createOrder, createOrderWithPayment } from 'pages/pay/api/PayApi';
import { paymentEnum } from 'pages/pay/constants/PaymentEnum';
import PayLeftPart from 'pages/pay/subscriptionOrderDetail/PayLeftPart';
import PayPaymentPart from 'pages/pay/subscriptionOrderDetail/PayPaymentPart';
import PayRightPart from 'pages/pay/subscriptionOrderDetail/PayRightPart';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const SubscriptionOrderDetail = ({ showOrderDetail, setShowOrderDetail }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    selectedPayment: null,
    orderDescription: '',
    invoiceDescription: '',
    deliveryNoteDescription: '',
  });
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const backToPayBasket = () => {
    setShowOrderDetail(false);
  };

  const changePaymentType = (paymentType) => {
    setFormData((prevState) => ({ ...prevState, selectedPayment: paymentType }));
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const toogleApproveModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const onApprove = () => {
    const sendObject = {
      paymentType: formData.selectedPayment,
      productIds: showOrderDetail.productIds,
      cartGroup: showOrderDetail.cartGroup,
      orderExpansion: formData.orderDescription,
      invoiceExpansion: formData.invoiceDescription,
      deliveryNote: formData.deliveryNoteDescription,
    };
    setIsLoading(true);
    if (formData.selectedPayment !== paymentEnum.onlinePayment) {
      createOrder(sendObject)
        .then((res) => {
          if (res.data.data.anyErrorExists) {
            navigate(`${paths.subscriptions}${paths.orderFail}`, { state: res.data.data });
          } else {
            if (res.data.data.errorMessage) {
              toast.error(res.data.data.errorMessage);
            }
            if (res.data.data.productInfos && res.data.data.productInfos.length > 0) {
              navigate(`${paths.subscriptions}${paths.orderSuccess}`, { state: res.data.data });
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
          toogleApproveModal();
        });
    } else {
      createOrderWithPayment(sendObject)
        .then((res) => {
          if (res.data.data.redirect) {
            window.location.href = res.data.data.redirectUrl;
          }
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
          toogleApproveModal();
        });
    }
  };

  return (
    <>
      <PaymentLayout
        onClick={backToPayBasket}
        mainTitle={t('pay:orderDetails')}
        leftTitle={t('pay:informationAndExplanations')}
        leftSubtitle={t('pay:enterInformationAndDescriptions')}
        leftPart={
          <PayLeftPart
            formData={formData}
            inputChangeHandler={inputChangeHandler}
          />
        }
        summaryTitle={t('pay:orderSummary')}
        count={showOrderDetail.productIds.length}
        countLabel={t('pay:product')}
        rightPart={
          <PayRightPart
            showOrderDetail={showOrderDetail}
            formData={formData}
            toogleApproveModal={toogleApproveModal}
          />
        }
        paymentTitle={t('pay:paymentOptions')}
        paymentSubtitle={t('pay:chooseShippingOptionsAndNotificationMethods')}
        paymentPart={
          <PayPaymentPart
            showOrderDetail={showOrderDetail}
            formData={formData}
            changePaymentType={changePaymentType}
          />
        }
      />
      {openModal && (
        <ApproveModal
          onClose={toogleApproveModal}
          approveClick={onApprove}
          bodyText={t('pay:doYouConfirmYourOrder')}
        />
      )}
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default SubscriptionOrderDetail;
