import { Box, styled } from '@mui/material';
import { blueColors, primaryColors } from 'config/theme/Colors';

const CustomContainer = styled(Box)(({ isselected }) => ({
  border: isselected ? `1px solid ${blueColors.blue200}` : `1px solid ${primaryColors.primary200}`,
  borderRadius: '8px',
  minHeight: '84px',
  display: 'flex',
  padding: '12px',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: isselected ? blueColors.blue50 : '#ffffff',
  overflowY: 'auto',
}));

const ItemContainer = ({ children, isSelected }) => {
  return <CustomContainer isselected={+isSelected}>{children}</CustomContainer>;
};
export default ItemContainer;
