import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { Typography, useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled, useTheme } from '@mui/material/styles';
import PSelect from 'components/select/PSelect';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LeftMenuItem from './LeftMenuıtem/LeftMenuItem';
const drawerWidthXl = 358;
const drawerWidthLg = 320;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  '.MuiPaper-root': {
    backgroundColor: primaryColors.primary50,
    marginTop: '64px',
    padding: '0 16px',
  },
  [theme.breakpoints.only('lg')]: {
    width: drawerWidthLg,
  },
  [theme.breakpoints.only('xl')]: {
    width: drawerWidthXl,
  },
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...openedMixin(theme),
  '& .MuiDrawer-paper': openedMixin(theme),
}));

const openedMixin = (theme) => ({
  [theme.breakpoints.only('xl')]: {
    width: drawerWidthXl,
  },
  [theme.breakpoints.only('lg')]: {
    width: drawerWidthLg,
  },
  width: drawerWidthXl,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: 'none',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 12px',
  ...theme.mixins.toolbar,
}));

const MainLeftMenu = () => {
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const theme = useTheme();
  const leftMenuInvisible = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const { t } = useTranslation();
  const [management, setManagement] = useState([]);
  const [seletedMenuItem, setSelectedMenuItem] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const naviate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const managementArray = [];
    const managementSelectData = [];

    userPermissions.forEach((permission) => {
      if (permission.parentMenuName === 'Admin' && permission.isMenuItem) {
        managementArray.push({ id: permission.menuName, path: `/${permission.path}`, title: `mainAppBar:${permission.menuName}` });
        managementSelectData.push({ key: `/${permission.path}`, value: t(`mainAppBar:${permission.menuName}`) });
      }
    });
    managementArray.push({ id: 'passwordChange', path: `${paths.accounting}${paths.passwordChange}`, title: 'mainAppBar:passwordOperations' });
    managementSelectData.push({ key: `${paths.accounting}${paths.passwordChange}`, value: t('mainAppBar:passwordOperations') });

    managementArray.push({ id: 'relatedPersons', path: `${paths.accounting}${paths.accountRelatedPersons}`, title: 'loginAppBar:relatedPersons' });
    managementSelectData.push({ key: `${paths.accounting}${paths.accountRelatedPersons}`, value: t('loginAppBar:relatedPersons') });
    managementArray.push({ id: 'myContracts', path: `${paths.accounting}${paths.myContracts}`, title: 'mainAppBar:myContracts' });
    managementSelectData.push({ key: `${paths.accounting}${paths.myContracts}`, value: t('mainAppBar:myContracts') });

    managementArray.push({ id: 'aboutUs', path: `${paths.account}${paths.aboutUs}`, title: 'loginAppBar:aboutUs' });
    managementSelectData.push({ key: `${paths.account}${paths.aboutUs}`, value: t('loginAppBar:aboutUs') });

    managementArray.push({ id: 'contact', path: `${paths.account}${paths.contact}`, title: 'loginAppBar:contact' });
    managementSelectData.push({ key: `${paths.account}${paths.contact}`, value: t('loginAppBar:contact') });

    setManagement(managementArray);
    setSelectedMenuItem(managementSelectData);
  }, [userPermissions]);

  useEffect(() => {
    if (leftMenuInvisible) {
      const currentLocation = location.pathname;
      seletedMenuItem.forEach((item) => {
        if (item.key === currentLocation) {
          setSelectValue(item.key);
        }
      });
    }
  }, [leftMenuInvisible, seletedMenuItem]);

  const onSelectChangeHandler = (event) => {
    const { value } = event.target;
    naviate(value);
    setSelectValue(value);
  };

  return (
    <>
      {leftMenuInvisible && (
        <PSelect
          showLabel={false}
          showHelperContainer={false}
          onChange={onSelectChangeHandler}
          menuItems={seletedMenuItem}
          value={selectValue}
        />
      )}
      {!leftMenuInvisible && (
        <Drawer variant="permanent">
          <DrawerHeader>
            <Typography
              sx={{ paddingLeft: '10px' }}
              variant="titleLarge">
              {t('mainAppBar:management')}
            </Typography>
          </DrawerHeader>
          <List>
            <LeftMenuItem
              headerText={t('mainAppBar:managementPanel')}
              headerIcon={<ManageAccountsOutlinedIcon />}
              data={management}
            />
          </List>
        </Drawer>
      )}
    </>
  );
};

export default MainLeftMenu;
