import { Box, Typography } from '@mui/material';
import WarningAlert from 'components/alert/WarningAlert';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import PCenterModal from 'components/modal/PCenterModal';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import SelectableTags from 'components/selectableTags/SelectableTags';
import { primaryColors } from 'config/theme/Colors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { addItemToCart } from '../api/ProductsInStorageApi';

const AddWithSerialNumberModal = ({ onClose, selectedItem, setCartItemNumber, getTableData }) => {
  const [checkbox, setCheckbox] = useState(false);
  const [selectedSerialNumber, setSelectedSerialNumber] = useState({ alreadySelected: [], newSelected: [], visible: [] });
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [serialNumbersChip, setSerialNumbersChip] = useState({ allData: [], visibleData: [] });
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const notAlreadySelected = selectedItem.manufacturerSerialNumber.filter(
      (selected) => !selectedItem.serialNumbersInUsersCart.some((usedSerial) => selected.manufacturerSerialNumber === usedSerial),
    );

    setSerialNumbersChip({ allData: notAlreadySelected, visibleData: notAlreadySelected });
    setSelectedSerialNumber((prevState) => ({
      ...prevState,
      alreadySelected: selectedItem.serialNumbersInUsersCart,
      visible: selectedItem.serialNumbersInUsersCart,
    }));
    setAmount(selectedItem.amount);
  }, []);

  const onCheckboxChange = (event) => {
    const { checked } = event.target;
    setCheckbox(checked);
  };

  const onSearchHandlder = (event) => {
    const { value } = event.target;
    setSearch(value);
    const newSerialNumbersChip = serialNumbersChip.allData.filter((item) =>
      item.manufacturerSerialNumber.toLocaleLowerCase('tr').includes(value.toLocaleLowerCase('tr')),
    );
    const alreadySelectedVisible = selectedSerialNumber.alreadySelected.filter((item) =>
      item.toLocaleLowerCase('tr').includes(value.toLocaleLowerCase('tr')),
    );
    setSerialNumbersChip((prevState) => ({ ...prevState, visibleData: newSerialNumbersChip }));
    setSelectedSerialNumber((prevState) => ({ ...prevState, visible: alreadySelectedVisible }));
  };

  const handleSerialNumberClick = (selectedSerial) => {
    const foundAlreadySelected = selectedSerialNumber.alreadySelected.find((item) => item === selectedSerial);
    if (foundAlreadySelected) {
      toast.error(t('productsInStorage:youCannotDeleteAPreviouslyAddedSerialNumber'));
      return;
    }
    const found = selectedSerialNumber.newSelected.find((item) => item === selectedSerial);
    if (!found) {
      if (amount <= selectedSerialNumber.newSelected.length) {
        toast.error(t('productsInStorage:selectedSerialNoCannotExeed'));
      } else {
        const copy = [...selectedSerialNumber.newSelected];
        copy.push(selectedSerial);
        setSelectedSerialNumber((prevState) => ({ ...prevState, newSelected: copy }));
      }
    } else {
      const copy = [...selectedSerialNumber.newSelected];
      const newSerialNumber = copy.filter((newSerial) => found !== newSerial);
      setSelectedSerialNumber((prevState) => ({ ...prevState, newSelected: newSerialNumber }));
    }
  };

  const handleAddToCart = () => {
    const addItem = {
      stockCode: Number(selectedItem.productNumber).toString(),
      itemName: selectedItem.shortDesc,
      orderNo: Number(selectedItem.orderId).toString(),
      entryNumber: selectedItem.entryNumber,
      storagePlace: selectedItem.storagePlace,
      quantity: amount,
      serials: [...selectedSerialNumber.newSelected],
    };
    addItemToCart(addItem)
      .then((res) => {
        getTableData();
        setCartItemNumber(res.data.data.productCountInCart);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        onClose();
      });
  };
  const inputChange = (value) => {
    if (value < selectedSerialNumber.newSelected.length) {
      const copy = [...selectedSerialNumber.newSelected];
      copy.pop();
      setSelectedSerialNumber((prevState) => ({ ...prevState, newSelected: copy }));
    }

    if (value > selectedItem.count) {
      toast.error(t('storageCart:cannotSendMoreThanTheStock'));
    } else {
      setAmount(value);
    }
  };

  return (
    <PCenterModal
      width="676px"
      onClose={onClose}
      title={t('productsInStorage:addProductNoToCart', { number: Number(selectedItem.productNumber) })}
      showClose>
      <Box className="d-flex flex-column gap-20">
        <Box className="d-flex gap-16">
          <Box className="d-flex w100 justify-content-between flex-wrap">
            <Box className="d-flex flex-column">
              <Typography
                variant="titleSmall"
                color={primaryColors.primary}>
                {selectedItem.shortDesc}
              </Typography>
              <Typography
                variant="bodySmall"
                color={primaryColors.primary}>
                {t('productsInStorage:piecesInStock', { number: selectedItem.count })}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ width: '132px' }}>
                <InlineEditNumberInput
                  value={amount}
                  onChange={inputChange}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <PCheckbox
            label={t('productsInStorage:selectTheSerialNumberOfTheProduct')}
            id="selectTheSerialNumberOfTheProduct"
            checked={checkbox}
            onChange={onCheckboxChange}
          />
        </Box>
        {checkbox && (
          <Box className="d-flex flex-column gap-24">
            <TableSearchTextField
              onChange={onSearchHandlder}
              placeholder={t('productsInStorage:searchWithSerialNo')}
              value={search}
            />
            {serialNumbersChip.allData.length === 0 && (
              <Box>
                <WarningAlert label={t('productsInStorage:allProductsInStockAreAlreadyInYourCart')} />
              </Box>
            )}
            <Box
              className="d-flex flex-column gap-24"
              sx={{ maxHeight: '250px', overflow: 'auto' }}>
              {selectedSerialNumber.visible.length > 0 && (
                <Box className="d-flex flex-column gap-16">
                  <Typography
                    variant="labelLarge"
                    color="#000000">
                    {t('productsInStorage:alreadyInCart')}
                  </Typography>
                  {
                    <Box className="d-flex gap-16 flex-wrap">
                      {selectedSerialNumber.visible.map((item) => (
                        <SelectableTags
                          onClick={() => handleSerialNumberClick(item)}
                          value={item}
                          key={item}
                          selected
                          title={item}
                        />
                      ))}
                    </Box>
                  }
                </Box>
              )}
              {serialNumbersChip.visibleData.length > 0 && (
                <Box className="d-flex flex-column gap-16">
                  <Typography
                    variant="labelLarge"
                    color="#000000">
                    {t('productsInStorage:additableSerialNo')}
                  </Typography>
                  <Box className="d-flex gap-16 flex-wrap">
                    {serialNumbersChip.visibleData.map((item) => (
                      <SelectableTags
                        value={item.manufacturerSerialNumber}
                        key={item.manufacturerSerialNumber}
                        selected={selectedSerialNumber.newSelected.find((selected) => selected === item.manufacturerSerialNumber)}
                        onClick={() => handleSerialNumberClick(item.manufacturerSerialNumber)}
                        title={item.manufacturerSerialNumber}
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {(serialNumbersChip.allData.length > 0 || !checkbox) && (
          <Box className="d-flex justify-flex-end">
            <PButton
              onClick={handleAddToCart}
              width="auto">
              {t('productsInStorage:addToCart')}
            </PButton>
          </Box>
        )}
      </Box>
    </PCenterModal>
  );
};
export default AddWithSerialNumberModal;
