export const AddStaticTemplateEn = {
  generalManager: 'General Manager',
  missionVission: 'Mission Vission',
  policy: 'Policy',
  whichPage: 'Referance Page',
  turkishContent: 'Turkish Content',
  englishContent: 'English Content',
  save: 'Save',
  savedSuccessfully: 'Saved Successfully',
  updatedSuccessfully: 'Updated Successfully',
};
