/* eslint-disable max-len */
import axios from 'axios';
import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, BASARSOFT, COMMON_API } from 'constants/Keys';

const DELIVERY = '/delivery';
const GET_CITIES = '/get-cities';
const CREATE_BULK = '/create-bulk';
const service = '/Service';
const GET_DISTRICTS = '/get-districts';
const AUTO_SUGGESTION = '/autosuggestion';
const SEARCH = '/Search';
const UPDATE_PASSIVE_ACTIVE = '/update-passive-active';
const GET_TEMPLATE = '/get-template';
const GOOGLE_GEOCODE_API = 'https://maps.googleapis.com/maps/api/geocode/json';
const CHECK_BULK_ADDING = '/check-bulk-adding';

export const getCities = () => httpRequest.get(`${COMMON_API}${API}${DELIVERY}${GET_CITIES}`);

export const getAllAdress = () => httpRequest.get(`${COMMON_API}${API}${DELIVERY}`);

export const addBulkAddressWithExcel = (formData, onUploadProgress) =>
  httpRequest.post(`${COMMON_API}${API}${DELIVERY}${CREATE_BULK}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
export const downloadTemplate = () => httpRequest.get(`${COMMON_API}${API}${DELIVERY}${GET_TEMPLATE}`, { responseType: 'blob' });

export const getDistricts = (cityCode) => httpRequest.get(`${COMMON_API}${API}${DELIVERY}${GET_DISTRICTS}?cityCode=${cityCode}`);

export const getDetailsOfAddress = (words) =>
  httpRequest.get(
    `${BASARSOFT}${service}${API}/v1${AUTO_SUGGESTION}${SEARCH}?accID=DCt6nwrpbkObpK-IDYPZpw&appCode=P0xCoyO6d0O7PmciOK_D9g&words=${words}&limit=5&type=5`,
  );

export const upsertDeliveryAddress = (data) =>
  httpRequest.post(`${COMMON_API}${API}${DELIVERY}`, {
    ...data,
  });

export const changeActivePassive = (isActive, receiver) =>
  httpRequest.post(`${COMMON_API}${API}${DELIVERY}${UPDATE_PASSIVE_ACTIVE}`, {
    isActive,
    receiver,
  });

export const getAddressFromLatLon = (lat, lon) => axios.get(`${GOOGLE_GEOCODE_API}?latlng=${lat},${lon}&key=${process.env.REACT_APP_MAPS_KEY}`);

export const getLatlonFromCity = (city) => axios.get(`${GOOGLE_GEOCODE_API}?address=${city}&key=${process.env.REACT_APP_MAPS_KEY}`);

export const getLoadedExcel = ({ startDate, endDate, guid }) =>
  httpRequest.get(`${COMMON_API}${API}${DELIVERY}${CHECK_BULK_ADDING}`, {
    params: {
      Guid: guid,
      StartDate: startDate,
      EndDate: endDate,
    },
  });
