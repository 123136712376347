import { Box } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import LoginContainer from 'layout/loginLayout/container/LoginContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <LoginContainer maxWidth="lg">
      <Box className="d-flex gap-4 w100 flex-column">
        <Box>
          <h1 className="text-center">{t('contact:contact')}</h1>
          <PDivider marginBottom="0" />
        </Box>
        <article>
          <p>
            <b>Penta Teknoloji Ürünleri Dağıtım Ticaret A.Ş.</b>
          </p>
          <p>
            <b>{t('contact:phone')}</b>
            <br />
            <span>+90 (216) 528 0000</span>
          </p>
          <p>
            <b>{t('contact:callCenter')}</b>
            <br />
            <span>+90 (850) 277 0277</span>
          </p>
          <p>
            <b>{t('contact:address')}</b>
            <br />
            <span>Dudullu Organize Sanayi Bölgesi, 4. Cad. No: 1 Ümraniye, 34775, İstanbul</span>
          </p>
          <p>
            <b>{t('contact:logisticsBuilding')}</b>
            <br />
            <span>Dudullu Organize Sanayi Bölgesi 1. Cad. No: 8 Ümraniye, 34775, İstanbul</span>
          </p>
        </article>
      </Box>
    </LoginContainer>
  );
};

export default React.memo(Contact);
