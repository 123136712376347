import { Box, Tab, Typography } from '@mui/material';
import PInfoChip from 'components/chip/PInfoChip';
import IconResolver from 'components/iconResolver/IconResolver';
import TabPanel from 'components/tabs/PTabPanel';
import PTabs from 'components/tabs/PTabs';
import { primaryColors } from 'config/theme/Colors';
import { MarginContainer } from 'layout/container/MarginContainer';
import { controlForDefaultPayCart, getCartItemCount } from 'pages/pay/api/PayApi';
import { payTabNumbers } from 'pages/pay/constants/PayTabNumbers';
import { userDynamicPermissionForEndUserReportsKey } from 'pages/pay/constants/StringConstants';
import SubscriptionOrderDetail from 'pages/pay/subscriptionOrderDetail/SubscriptionOrderDetail';
import PaySubscriptionContainer from 'pages/pay/tabs/PaySubscriptionContainer';
import PaySubscriptionReport from 'pages/pay/tabs/reportInPay/PaySubscriptionReport';
import SubscriptionCart from 'pages/pay/tabs/subscriptionCart/SubscriptionCart';
import PaySubscriptionDetail from 'pages/pay/tabs/subscriptionDetailInPay/PaySubscriptionDetail';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const PayContainer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location?.state || 0);
  const [cartItemNumber, setCartItemNumber] = useState(0);
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [productInfo, setProductInfo] = useState({ subscriptionNumber: null, renewalDate: null });
  const userDynamicPermission = userPermissions.find((permission) => permission.permissionType === userDynamicPermissionForEndUserReportsKey);
  const [additionalInfoForTableMenu, setAdditionalInfoForTableMenu] = useState(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    controlForDefaultPayCart()
      .then(() => {
        getCartCount();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
    const param = searchParams.get('page');
    if (param && param === '2') {
      setTabValue(1);
    }
  }, []);

  const detailTabNumber = useMemo(() => {
    if (userPermissions.find((permission) => permission.permissionType === userDynamicPermissionForEndUserReportsKey)) {
      return payTabNumbers.subscriptionDetail;
    }
    return payTabNumbers.endUserReports;
  }, [userPermissions]);

  const getCartCount = () => {
    getCartItemCount()
      .then((res) => {
        setCartItemNumber(res.data.data.itemCount);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const handleChange = (_event, newValue) => {
    console.log(newValue);

    setTabValue(newValue);
  };

  return (
    <>
      {!showOrderDetail && (
        <MarginContainer>
          <Box className="d-flex align-items-center justify-content-between">
            <Box className="flex-column">
              <Typography
                variant="titleLarge"
                color={primaryColors.primary}>
                {t('pay:pentaSubscriptionManagement')}
              </Typography>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {t('pay:manageYourOwnAndEnUsersSubscriptions')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <PTabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ padding: ' 20px 0' }}>
              <Tab
                iconPosition="start"
                icon={
                  <IconResolver
                    iconName="LoyaltyOutlined"
                    fontSize="small"
                  />
                }
                label={t('pay:subscriptions')}
              />
              <Tab
                label={
                  <div className="d-flex align-items-center gap-8">
                    <IconResolver
                      iconName="ShoppingCartOutlined"
                      fontSize="small"
                    />
                    {t('pay:subscriptionCart')}
                  </div>
                }
                iconPosition="end"
                icon={<PInfoChip label={`${cartItemNumber}`} />}
              />
              {userDynamicPermission && (
                <Tab
                  iconPosition="start"
                  icon={
                    <IconResolver
                      iconName="AssignmentOutlined"
                      fontSize="small"
                    />
                  }
                  label={t('pay:endUserReports')}
                />
              )}

              <Tab
                iconPosition="start"
                icon={
                  <IconResolver
                    iconName="InfoOutlined"
                    fontSize="small"
                  />
                }
                label={t('pay:subscriptionDetail')}
              />
            </PTabs>
          </Box>
          <Box>
            <TabPanel
              value={tabValue}
              index={payTabNumbers.subscriptions}>
              <PaySubscriptionContainer
                detailTabNumber={detailTabNumber}
                setTabValue={setTabValue}
                setProductInfo={setProductInfo}
                setAdditionalInfoForTableMenu={setAdditionalInfoForTableMenu}
              />
            </TabPanel>
            <TabPanel
              value={tabValue}
              index={payTabNumbers.subscriptionCart}>
              <SubscriptionCart
                setShowOrderDetail={setShowOrderDetail}
                tabValue={tabValue}
                setCartItemNumber={setCartItemNumber}
              />
            </TabPanel>
            {userDynamicPermission && (
              <TabPanel
                value={tabValue}
                index={payTabNumbers.endUserReports}>
                <Box>
                  <PaySubscriptionReport tabValue={tabValue} />
                </Box>
              </TabPanel>
            )}
            <TabPanel
              value={tabValue}
              index={userDynamicPermission ? payTabNumbers.subscriptionDetail : payTabNumbers.endUserReports}>
              <Box>
                <PaySubscriptionDetail
                  additionalInfoForTableMenu={additionalInfoForTableMenu}
                  getCartCount={getCartCount}
                  productInfo={productInfo}
                  tabValue={tabValue}
                />
              </Box>
            </TabPanel>
          </Box>
        </MarginContainer>
      )}
      {showOrderDetail && (
        <SubscriptionOrderDetail
          showOrderDetail={showOrderDetail}
          setShowOrderDetail={setShowOrderDetail}
        />
      )}
    </>
  );
};

export default PayContainer;
