export const LandingPageEn = {
  pentaStorage: 'Penta Storage',
  pentaConfigMaster: 'Penta Config Master',
  pentaSubscriptionManagement: 'Penta Subscription Management',
  manageSubscriptionDetails: 'Manage subscription details',
  classicBayinetExperience: 'Classic bayinet experience',
  manageYourProductsInStorage: 'Manage your products in storage',
  configureTheHardwareYouWant: 'Configure the hardware you want',
  pentaIntegration: 'Penta Integration',
  integrateDifferentServices: 'Integrate different services',
};
