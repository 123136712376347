import { styled } from '@mui/material';
import { paths } from 'constants/Paths';
import { Link } from 'react-router-dom';
import logo from 'svgs/logo.svg';
import { isTokenValid } from 'utils/HelperFunctions';

const CustomLink = styled(Link)(() => ({
  display: 'flex',
}));
const Logo = () => {
  let to = paths.home;

  if (isTokenValid()) {
    to = paths.protectedHome;
  }

  return (
    <CustomLink to={to}>
      <img
        width={130}
        height={66}
        src={logo}
        alt="logo"
      />
    </CustomLink>
  );
};

export default Logo;
