import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Typography, styled } from '@mui/material';
import { primaryColors, redColors } from 'config/theme/Colors';
import { useTranslation } from 'react-i18next';

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px 24px',
  rowGap: '20px',
  gridColumnStart: 3,
  gridColumnEnd: 11,
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ErrorPage = ({ data }) => {
  const { t } = useTranslation();

  return (
    <GridContainer>
      <ResponseContainer>
        <div className="flex-column d-flex">
          <CancelIcon
            sx={{ color: redColors.red600 }}
            fontSize="large"
          />
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {t('deliveryDetails:deliveryCouldNotBeCreated')}
          </Typography>
          {data.errors.map((error) => (
            <Typography
              key={error.message}
              variant="bodyMedium"
              color={primaryColors.primary}>
              {error.message}
            </Typography>
          ))}
        </div>
      </ResponseContainer>
    </GridContainer>
  );
};

export default ErrorPage;
