import { useTheme } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PTable from 'components/table/customTable/PTable';
import { httpRequest } from 'config/api/AxiosInterceptors';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
const PLazyLoadingTable = forwardRef(
  (
    {
      columns,
      url,
      infoLabel,
      headerTitle,
      extraFields,
      searchKey = 'SearchKey',
      subLabel = '',
      emptyMessage = '',
      getTableData = null,
      generateId = false,
      getRowClassName,
      isSearchable = true,
    },
    ref,
  ) => {
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataLength, setDataLength] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('');
    const [globalParams, setGlobalParams] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [showEmptyMessage, setShowEmptyMessage] = useState(false);
    useImperativeHandle(ref, () => ({
      refreshTable: () => {
        loaderFunction(url, globalParams);
      },
    }));

    const loaderFunction = (localUrl, localParams) => {
      if (localUrl) {
        setLoading(true);
        httpRequest
          .get(localUrl, {
            params: {
              ...localParams,
            },
          })
          .then((res) => {
            let localTableData = [];
            setDataLength(res.data.totalCount);
            if (generateId) {
              localTableData = res.data.data.map((item) => ({ ...item, id: uuidv4() }));
            } else {
              localTableData = res.data.data;
            }
            if (getTableData) {
              getTableData(localTableData);
            }
            setData(localTableData);
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setLoading(false);
            setShowEmptyMessage(true);
          });
      }
    };

    useEffect(() => {
      setSearchValue('');
      const defaultParams = {
        page: 1,
        pageSize,
      };

      setGlobalParams(defaultParams);

      loaderFunction(url, defaultParams);
    }, [url]);

    const menuItems = useMemo(() => {
      return [
        { key: 10, value: 10 },
        { key: 20, value: 20 },
        { key: 50, value: 50 },
      ];
    }, []);

    const sortRequest = (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      const copy = { ...globalParams };
      copy.orderBy = property;
      copy.order = isAsc ? 'desc' : 'asc';
      setGlobalParams(copy);
      loaderFunction(url, copy);
    };

    const handlePageSizeChange = (event) => {
      setPageSize(event.target.value);
      setPage(0);
      const copy = { ...globalParams };
      copy.page = 1;
      copy.pageSize = event.target.value;
      setGlobalParams(copy);
      loaderFunction(url, copy);
    };

    const handlePageChange = (_, newPage) => {
      setPage(newPage - 1);
      const copy = { ...globalParams };
      copy.page = newPage;
      setGlobalParams(copy);
      loaderFunction(url, copy);
    };

    const onSearchChangeHandler = (event) => {
      setSearchValue(event.target.value);
      const copy = { ...globalParams };
      copy.page = 1;
      copy[searchKey] = event.target.value;
      setGlobalParams(copy);
      setPage(1);
      loaderFunction(url, copy);
    };

    return (
      <>
        {loading && <LoadingSpinner />}
        <PTable
          headerRightSide={
            <Box className="d-flex gap-12 justify-flex-end align-items-center flex-wrap">
              {isSearchable && (
                <Box sx={{ width: matchesSm ? '100%' : '300px' }}>
                  <TableSearchTextField
                    showLabel={false}
                    placeholder={t('pay:searchPlaceholder')}
                    onChange={onSearchChangeHandler}
                    value={searchValue}
                    showHelperContainer={false}
                  />
                </Box>
              )}
              {extraFields}
            </Box>
          }
          totalCount={dataLength}
          infoLabel={`${dataLength} ${infoLabel}`}
          subLabel={subLabel}
          headerTitle={headerTitle}
          data={data}
          columns={columns}
          pageSizeArray={menuItems}
          page={page}
          pageSize={pageSize}
          pageSizeChange={handlePageSizeChange}
          pageChange={handlePageChange}
          order={order}
          orderBy={orderBy}
          sortRequest={sortRequest}
          emptyMessage={emptyMessage}
          showEmptyMessage={showEmptyMessage}
          getRowClassName={getRowClassName}
        />
      </>
    );
  },
);

export default PLazyLoadingTable;
