/* eslint-disable max-len */
export const ConfigMasterTr = {
  pentaConfigMaster: 'Penta Config Master',
  configureAndOrderTheHardwareYouWant: 'İstediğiniz donanımı konfigüre edin ve sipariş edin',
  configMaster: 'Config Master',
  configMasterCart: 'Config Master Sepeti',
  pcBuild: 'PC Toplama',
  meetingTableSet: 'Toplantı Masası Seti',
  saveSelectionsAsVariants: 'Seçimleri Varyant Olarak Kaydet',
  clear: 'Temizle',
  selectTemplate: 'Şablon seç',
  justShowInStock: 'Sadece stokta olanları göster',
  component: 'bileşen',
  selectionSummary: 'Seçim Özeti',
  piece: 'Adet',
  configuration: 'Konfigürasyon',
  subtotal: 'Ara Toplam',
  selectFilteringType: 'Filtreleme tipi seç',
  variant: 'Varyant',
  stock: 'Stok',
  selection: 'Seçimi',
  pleaseChoose: 'Lütfen seçim yapın',
  addToYourSelection: 'Seçiminize ekleyin',
  searchBrand: '{{brand}} ara...',
  select: 'Seç',
  selected: 'Seçildi',
  remove: 'Çıkart',
  insufficientStock: 'Yetersiz Stok',
  productDetail: 'Ürün Detayı',
  productPage: 'Ürün Sayfası',
  productNumber: 'Ürün Numarası',
  noRecordFound: 'Kayıt bulunamadı',
  currencySelection: 'Para Birimi Seçimi',
  basketDetail: 'Sepet Detayı',
  updateBasket: 'Sepeti Güncelle',
  product: 'Ürün',
  selectVariant: 'Varyant seç',
  variantName: 'Varyant adı',
  save: 'Kaydet',
  cancel: 'İptal',
  yes: 'Evet',
  selectConfiguration: 'Konfigürasyon seç',
  grandTotal: 'Genel Toplam',
  including: 'Dahil',
  loading: 'Yükleniyor ...',
  addToCart: 'Sepete Ekle',
  insufficientStockError: 'Yetersiz stok. Lütfen gözden geçirin.',
  mandatoryFieldsError: 'Seçilmemiş zorunlu alanlar bulunuyor. Lütfen kontrol edin.',
  orderDetails: 'Sipariş Detayları',
  orderCreatedSuccessfully: 'Sipariş başarıyla oluşturuldu!',
  paymentTransactionCompletedSuccessfully: 'Ödeme işlemi başarıyla tamamlandı. Ödeme bilgilerinizi gözden geçirebilirsiniz.',
  paymentNo: 'Ödeme No',
  erpPaymentNo: 'ERP Ödeme No',
  dateAndTime: 'Tarih ve Saat',
  customer: 'Müşteri',
  user: 'Kullanıcı',
  amount: 'Tutar',
  explanation: 'Açıklama',
  printPayment: 'Ödeme Çıktısı Al',
  couldNotCreateOrder: 'Sipariş oluşturulamadı',
  paymentTransactionCompletedUnsuccessfully: 'Ödeme gerçekleşemedi. Ödeme bilgilerinizi gözden geçirebilirsiniz.',
  youCanViewOrderDetailsInTheTableBelow: 'Sipariş detaylarını aşağıdaki tablodan görüntüleyebilirsiniz.',
  orderNo: 'Sipariş No',
  shipmentType: 'Sevkiyat Türü',
  serviceFee: 'Hizmet Bedeli',
  orderItems: 'Sipariş Kalemleri',
  itemNumber: 'Kalem No',
  productCode: 'Ürün Kodu',
  productName: 'Ürün Adı',
  unitPrice: 'Birim Fiyat',
  taxIncludedPrice: 'KDV Dahil Toplam',
  stockCode: 'Stok Kodu',
  maturity: 'Vade',
  day: 'Gün',
  orderSummary: 'Sipariş Özeti',
  taxIncluded: 'KDV Dahil',
  totalTax: 'Toplam KDV',
  order: 'Sipariş Ver',
  thereAreNoProductsInYourCart: 'Sepetinizde ürün bulunmamaktadır.',
  someOfProductsNotInStock: 'Seçtiğiniz bazı ürünler stokta bulunmuyor.',
  removeSelectedRow: 'Silmek istediğinize emin misiniz ?',
  welcomeMessage: 'Penta Config Master’a Hoş Geldiniz!',
  introductionFirstParagraph: 'Bayinet üzerinden konfigüratör ile istenilen özelliklerde PC toplanmasını sağlayan platformdur.',
  introductionSecondParagraph:
    'Bu uygulama ile birlikte bayilerimize istenilen özelliklerde montajı ve testleri yapılmış hazır PC hizmeti verilmesi amaçlanmıştır.',
  firstTitle: 'Penta Config Master’dan kimler faydalanabilir?',
  firstTitleFirstParagraph: 'Tüm bayilerimiz bu hizmetten faydalanabilmektedir.',
  secondTitle: 'Penta Config Master ile nasıl konfigüratör oluşturulur?',
  secondTitleFirstDot: 'Uyumlu bileşenler ile istenilen özelliklerde config hazırlanabilir ve 	anında sipariş verilebilir.',
  secondTitleSecondDot: 'Bileşen seçimlerinde sepete eklerken hata almamak için sadece stoktaki ürünler seçilmelidir.',
  secondTitleThirdDot: 'Seçimlerinizi varyant olarak kaydettiğinizde sonrasında bu varyantları hazır olarak kullanabilirsiniz.',
  secondTitleFourthDot: 'Sonrasında Config Masterda istenilen özellikteki bileşenler seçilmelidir.',
  secondTitleFifthDot: 'Bileşen Seçimi tamamlandıktan sonra Sepete ekle butonu ile ürünler sepete eklenir.',
  secondTitleSixthDot:
    'Uygun Şekilde Toplanan PC Sepete Eklendikten Sonra Satın Alma İşlemi Yapıldığında Depo ve Üretim Süreci Otomatik Olarak 	Başlayacaktır.',
  secondTitleSeventhDot: 'Montaj ve Test İşlemleri Yapılarak Ürünler Paketlenir.',
  secondTitleEighthDot: 'Paketlene ürünler müşteriye sevk edilir.',
  specialOffer: 'Özel Teklif',
  noComponentsFound: 'Bileşen bulunamadı.',
  shipmentAndInformation: 'Sevkiyat ve Bilgilendirme',
  chooseShippingOptionsAndNotificationMethods: 'Sevkiyat seçeneklerini ve bilgilendirme yöntemlerini belirleyin',
  createOrder: 'Sipariş oluştur',
  sendToPentaStorage: 'Penta Depom’a gönder',
  storage: 'Depo',
  selectStorage: 'Depo seçin',
  selectShipmentType: 'Sevkiyat türü seçin',
  orderDescription: 'Sipariş Açıklaması',
  enterDescription: 'Açıklama girin',
  invoiceDescription: 'Fatura Açıklaması',
  enterInvoiceDescription: 'Fatura açıklaması girin',
  trackingNumber: 'Takip Numarası',
  enterTrackingNumber: 'Takip numarası girin',
  checkboxInfo: 'Sipariş sonrasında SMS ile bilgilendirilmek istiyorum. (Ürün çıkışında ve tesliminde.)',
  availableCreditLimit: 'Kullanılabilir kredi limiti',
  eArchive: 'E-Arşiv',
  invoicesWillBeSentToYourDefinedEMailAddresses: 'e-arşiv faturalarınız tanımlı e-posta adreslerinize gönderilecektir.',
  paymentOptions: 'Ödeme Seçenekleri',
  setPaymentInformationAndMethods: 'Ödeme bilgilerini ve yöntemlerini belirleyin',
  openAccount: 'Açık hesap',
  bankTransfer: 'Banka transfer',
  onlinePayment: 'Online ödeme',
  pleaseSelectPaymentType: 'Lütfen ödeme yöntemi seçin.',
  thisFieldIsRequired: 'Lütfen {{field}} alanını giriniz.',
  deliveryAddress: 'Teslimat Adresi',
  relatedPerson: 'İlgili Kişi',
  selectRelatedPerson: 'İlgili kişi seçin',
  selectDeliveryAddress: 'Teslimat adresi seçin',
  shipmentDate: 'Sevkiyat Tarihi',
  selectShipmentDate: 'Teslimat tarihi seçin',
  theNumberOfItemInThePackageChanged: 'Paketteki {{item}} sayısı değişti, lütfen kontrol ediniz.',
};
