import { Box, Typography } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import EmptyScreenDefaultIcon from './EmptyScreenDefaultIcon';

const EmptyScreenMessage = ({ title, subTitle, icon = <EmptyScreenDefaultIcon /> }) => {
  return (
    <Box className="d-flex flex-column align-items-center justify-content-center gap-8">
      <Box>{icon}</Box>
      <Box className="d-flex flex-column align-items-center justify-content-center gap-4">
        <Typography
          variant="titleMedium"
          color={primaryColors.primary}>
          {title}
        </Typography>
        <Typography
          variant="bodyMedium"
          color={primaryColors.primary800}>
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyScreenMessage;
