import { Grid, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PTextField from 'components/textField/PTextField';
import { redColors, slateColors } from 'config/theme/Colors';
import LoginContainer from 'layout/loginLayout/container/LoginContainer';
import { createResetPasswordLink } from 'pages/login/api/LoginApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { validEmail } from 'utils/HelperFunctions';

const ForgetPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formField, setFormField] = useState({
    sapCustomerId: '',
    email: '',
  });
  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    setFormField((prevState) => ({ ...prevState, [name]: value }));
  };

  const onResetHandler = () => {
    if (formField.sapCustomerId === '') {
      setErrorMessage('resetPassword:pleaseEnterYourCustomerCode');
    } else if (formField.email === '') {
      setErrorMessage('resetPassword:pleaseEnterYourEmailAddress');
    } else if (!validEmail(formField.email)) {
      setErrorMessage('resetPassword:theEmailFormatIsInvalid');
    } else {
      setErrorMessage('');
      const data = {
        sapCustomerId: formField.sapCustomerId,
        emailOrPhone: formField.email,
      };
      setLoading(true);
      createResetPasswordLink(data)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <LoginContainer maxWidth="xs">
      {loading && <LoadingSpinner />}
      <Grid
        container
        spacing={2}
        sx={{ paddingRight: 2, paddingBottom: 2, backgroundColor: '#ffffff' }}>
        <Grid
          item
          xs={12}>
          <Typography
            variant="headlineSmall"
            sx={{ color: slateColors.slate950 }}>
            {t('resetPassword:resetYourPassword')}
          </Typography>
          <br />
          <Typography
            variant="bodyMedium"
            sx={{ color: slateColors.slate600 }}>
            {t('resetPassword:youCanResetYourPassword')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}>
          <PTextField
            value={formField.sapCustomerId}
            label={t('resetPassword:customerCode')}
            name="sapCustomerId"
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <PTextField
            value={formField.email}
            label={t('resetPassword:email')}
            name="email"
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <PButton onClick={onResetHandler}>{t('resetPassword:changePassword')}</PButton>
          {errorMessage && (
            <Typography
              variant="labelLarge"
              sx={{ color: redColors.red600 }}>
              {t(errorMessage)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </LoginContainer>
  );
};

export default ForgetPassword;
