import { useTheme } from '@emotion/react';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import PDatePicker from 'components/datePicker/PDatePicker';
import PDivider from 'components/Divider/PDivider';
import TelInput from 'components/telInput/TelInput';
import PTextField from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { addUserValidationMessagesKeys } from 'pages/addUser/AddUserValidationMessagesKeys';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';
import { addUser, updateUser } from './api/AddUserApi';

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
}));

const AddUser = ({ setTab, userInfo, setUserInfo, emailToBeSetAsDefault, mobileToBeSetAsDefault }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const params = useParams();

  const [errors, setErrors] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
  });
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    setUserInfo((prevState) => ({ ...prevState, [name]: value }));
  };
  const validateForm = () => {
    const rules = {
      name: 'required|max:100',
      surname: 'required|max:100',
      email: 'required|email',
      mobile: 'required',
    };
    let mobile = userInfo.mobile.substring(1);
    mobile = mobile.replace(/\s/g, '');

    const copyUserInfo = { ...userInfo, mobile };

    const validation = new Validator(copyUserInfo, rules, addUserValidationMessagesKeys);
    if (validation.passes()) {
      if (params.userId) {
        onUpdate();
      } else {
        onSave();
      }
    } else if (validation.fails()) {
      setErrors((prevState) => ({
        ...prevState,
        name: validation.errors.first('name'),
        surname: validation.errors.first('surname'),
        email: validation.errors.first('email'),
        mobile: validation.errors.first('mobile'),
      }));
    }
  };

  const onSave = () => {
    let mobile = userInfo.mobile.substring(1);
    mobile = mobile.replace(/\s/g, '');

    addUser({
      name: userInfo.name,
      surname: userInfo.surname,
      email: userInfo.email,
      mobile,
      expireDate: dayjs(userInfo.expireDate).add(1, 'day'),
      isActive: userInfo.isActive,
      callCenterService: userInfo.callCenterService,
      profit: Number(userInfo.profit),
      restrictedIps: userInfo.restrictedIps,
      permissionTypes: userInfo.permissionTypes,
      sapCustomerId: currentUser.user.sapCustomerId,
    })
      .then((res) => {
        toast.success(res.data.message);
        navigate(`${paths.userManagement}${paths.userManagement}`);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const onUpdate = () => {
    let mobile = userInfo.mobile.substring(1);
    mobile = mobile.replace(/\s/g, '');

    updateUser({
      id: Number(params.userId),
      name: userInfo.name,
      surname: userInfo.surname,
      email: userInfo.email,
      mobile,

      expireDate: dayjs(userInfo.expireDate),
      isActive: userInfo.isActive,
      callCenterService: userInfo.callCenterService,
      profit: Number(userInfo.profit),
      restrictedIps: userInfo.restrictedIps,
      permissionTypes: userInfo.permissionTypes,

      sapCustomerId: currentUser.user.sapCustomerId,
    })
      .then((res) => {
        toast.success(res.data.message);
        navigate(`${paths.userManagement}${paths.userManagement}`);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const isFieldsDisable = useMemo(() => {
    if (Number(params.userId) === currentUser.user.id) {
      return false;
    }

    if ((params.userId && !currentUser.user.isAdmin) || (params.userId && userInfo.isAdmin)) {
      return true;
    }
    return false;
  }, [params.userId, currentUser.user, userInfo.isAdmin]);

  return (
    <>
      <SecondaryLayout
        path={`${paths.userManagement}${paths.userManagement}`}
        title={t('addUser:addUser')}>
        <Header>
          <Typography variant="titleMedium">{t('addUser:basicKnowledge')}</Typography>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary700}>
            {t('addUser:enterUserBasicInformations')}
          </Typography>
        </Header>
        <PDivider marginBottom="2px" />
        <Grid
          container
          spacing={4}
          rowSpacing={1}
          sx={{ paddingRight: '16px', paddingBottom: 2, backgroundColor: '#ffffff', borderRadius: '15px', margin: 0, width: '100%' }}>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}>
            <PTextField
              name="name"
              label={t('addUser:name')}
              placeholder={t('addUser:name')}
              value={userInfo.name}
              onChange={onChangeHandler}
              error={!!errors.name}
              errorMessage={t(errors.name, { field: t('addUser:name'), char: 100 })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}>
            <PTextField
              name="surname"
              label={t('addUser:surname')}
              placeholder={t('addUser:surname')}
              value={userInfo.surname}
              onChange={onChangeHandler}
              error={!!errors.surname}
              errorMessage={t(errors.surname, { field: t('addUser:surname'), char: 100 })}
            />
          </Grid>
          <Grid
            item
            xs={6}
            lg={6}
            md={6}>
            <PTextField
              name="email"
              disabled={isFieldsDisable}
              label={t('addUser:email')}
              placeholder={t('addUser:emailPlaceholder')}
              value={userInfo.email}
              onChange={onChangeHandler}
              error={!!errors.email || emailToBeSetAsDefault}
              errorMessage={
                t(errors.email, { field: t('addUser:email') }) || (emailToBeSetAsDefault && t('addUser:emailAddressChangeIsAwaitingApproval'))
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            md={6}>
            {!params.userId && (
              <TelInput
                label={t('addUser:mobile')}
                value={userInfo.mobile}
                onChange={(value) => {
                  if (value.replace(/\s/g, '').length <= 13) {
                    setUserInfo((prevState) => ({ ...prevState, mobile: value }));
                  }
                }}
                error={!!errors.mobile || mobileToBeSetAsDefault}
                errorMessage={
                  t(errors.mobile, { field: t('addUser:mobile') }) || (mobileToBeSetAsDefault && t('addUser:phoneNumberChangeIsAwaitingApproval'))
                }
              />
            )}
            {params.userId && (
              <TelInput
                disabled={isFieldsDisable}
                label={t('addUser:mobile')}
                value={userInfo.mobile}
                name="mobile"
                onChange={(value) => {
                  if (value.replace(/\s/g, '').length <= 13) {
                    setUserInfo((prevState) => ({ ...prevState, mobile: value }));
                  }
                }}
                error={!!errors.mobile || mobileToBeSetAsDefault}
                errorMessage={
                  t(errors.mobile, { field: t('addUser:mobile') }) || (mobileToBeSetAsDefault && t('addUser:phoneNumberChangeIsAwaitingApproval'))
                }
              />
            )}
          </Grid>

          <Grid
            item
            xs={6}
            lg={6}
            md={6}>
            <PDatePicker
              placeholder={t('addUser:datepickerPlaceholder')}
              disabled={params.userId && !currentUser.user.isAdmin}
              label={t('addUser:userExpirationDate')}
              value={userInfo.expireDate}
              onChange={(value) => setUserInfo((prevState) => ({ ...prevState, expireDate: value }))}
            />
          </Grid>
          <Grid
            item
            xs={6}
            lg={6}
            md={6}
          />
          <Grid
            item
            xs={12}
            lg={6}
            md={6}>
            <PCheckbox
              label={t('addUser:activeUser')}
              checked={userInfo.isActive}
              onChange={(event) => setUserInfo((prevState) => ({ ...prevState, isActive: event.target.checked }))}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}>
            <PCheckbox
              label={t('addUser:callCenterService')}
              checked={userInfo.callCenterService}
              onChange={(event) => setUserInfo((prevState) => ({ ...prevState, callCenterService: event.target.checked }))}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <div style={{ margin: '23px 0' }} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <Box className="d-flex flex-column">
              <Typography variant="titleMedium">{t('addUser:permissions')}</Typography>
              <Typography
                variant="bodyMeduim"
                color={primaryColors.primary700}>
                {t('addUser:manageUserPermissionsInfo')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <PDivider sx={{ margin: '6px 0 10px 0' }} />
        <Grid
          container
          spacing={4}
          rowSpacing={1}
          sx={{ paddingRight: '16px', paddingBottom: 2, backgroundColor: '#ffffff', borderRadius: '15px', margin: 0, width: '100%' }}>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <Typography variant="bodyMedium">
              {userInfo.permissionTypes.length === 0
                ? t('addUser:thereAreNoPermissionsAddedYet')
                : t('addUser:permissionsAdded', { number: userInfo.permissionTypes.length })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <div style={{ margin: '4px 0' }} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            md={4}>
            <PButton
              width={matchesSm ? '100%' : 'auto'}
              inputtype="outline"
              onClick={() => setTab(false)}
              startIcon={<GppMaybeOutlinedIcon />}>
              {t('addUser:managePermissions')}
            </PButton>
          </Grid>
          <Grid
            item
            xs={12}
            className="text-end"
            sx={{ marginBottom: '16px' }}>
            <PButton
              width={matchesSm ? '100%' : 'auto'}
              onClick={validateForm}>
              {t('addUser:saveUser')}
            </PButton>
          </Grid>
        </Grid>
      </SecondaryLayout>
    </>
  );
};

export default AddUser;
