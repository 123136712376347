import CloseIcon from '@mui/icons-material/Close';
import { Box, styled, Typography } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import React, { Suspense } from 'react';
const ModalContainer = styled(Box)(({ width, height, theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width,
  backgroundColor: '#ffffff',
  boxShadow: '0px 1px 3px 0px #0A0A0A1A',
  borderRadius: '24px',
  padding: '24px',
  height,
  overflow: 'auto',
  outline: 'none',
  maxWidth: 'calc(100% - 15px)',
  [theme.breakpoints.down('md')]: {
    maxHeight: '500px',
    minWidth: '320px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '320px !important',
  },
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const Modal = React.lazy(() => import('@mui/material/Modal'));

const PCenterModal = ({ onClose, children, width = '400px', height = 'auto', showClose = false, title = '', subTitle = '' }) => {
  return (
    <Suspense fallback={<></>}>
      <Modal
        open
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContainer
          width={width}
          height={height}>
          <Box className="d-flex flex-column gap-4 h100">
            <Box className="d-flex justify-content-between align-items-center">
              <Typography
                variant="headlineSmall"
                color={primaryColors.primary}>
                {title}
              </Typography>
              {showClose && (
                <CloseIcon
                  onClick={onClose}
                  className="cursor-pointer"
                />
              )}
            </Box>
            <Box>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary800}>
                {subTitle}
              </Typography>
            </Box>
            {children}
          </Box>
        </ModalContainer>
      </Modal>
    </Suspense>
  );
};

export default PCenterModal;
