import { Box, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import MainAppBar from 'layout/mainLayout/MainAppBar';
import MainLeftMenu from 'layout/mainLayout/MainLeftMenu';

const LoginWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  [theme.breakpoints.between('xs', 'md')]: {
    flexDirection: 'column',
  },
}));

const MainWrapper = styled('main')(({ theme }) => ({
  backgroundColor: primaryColors.primary50,
  width: '100%',
  flexWrap: 'wrap',
  marginTop: '80px',
  marginBottom: '16px',
  [theme.breakpoints.between('xs', 'md')]: {
    marginTop: '0',
  },
  overflowX: 'hidden',
}));

const LeftMenuSelectContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'block',
    padding: '0 16px',
    marginTop: '48px',
    marginBottom: '32px',
  },
}));

const MainLayout = ({ children }) => {
  return (
    <LoginWrapper>
      <MainAppBar />
      <MainLeftMenu />
      <MainWrapper>
        <LeftMenuSelectContainer>
          <MainLeftMenu />
        </LeftMenuSelectContainer>
        {children}
      </MainWrapper>
    </LoginWrapper>
  );
};

export default MainLayout;
