import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import PButton from 'components/button/PButton';
import { TableHeaderCell, TableHeaderRow } from 'components/table/customTable/PTableHeader';
import { primaryColors } from 'config/theme/Colors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { numberToDecimal } from 'utils/HelperFunctions';
const StorageCostsDetailRow = ({ row, isOpenAll }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpenAll);
  }, [isOpenAll]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row">
          {Number(row.deliveryNumber)}
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {row.deliveryType}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {row.buyer}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {row.status}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {`${Math.round(row.volume)} ${row.volumeType}`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {`${numberToDecimal(row.transferCost)} ${row.currency}`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {`${numberToDecimal(row.haZ_TUTAR)} ${row.currency}`}
          </Typography>
        </TableCell>
        <TableCell align="left">
          {row.trackingLink && (
            <PButton
              disabled={!row.trackingLink}
              inputtype="text"
              sx={{ width: '132px' }}
              startIcon={<LocalShippingOutlinedIcon />}
              onClick={() => {
                window.open(row.trackingLink, '_blank');
              }}>
              {t('myShipments:cargoTracking')}
            </PButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={9}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit>
            <Box sx={{ margin: 1, width: '100%' }}>
              <Table
                size="small"
                aria-label="purchases">
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <Typography
                        variant="labelMedium"
                        color={primaryColors.primary}>
                        {t('storageCost:productNo')}
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Typography
                        variant="labelMedium"
                        color={primaryColors.primary}>
                        {t('storageCost:productName')}
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Typography
                        variant="labelMedium"
                        color={primaryColors.primary}>
                        {t('storageCost:referenceDocument')}
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Typography
                        variant="labelMedium"
                        color={primaryColors.primary}>
                        {t('storageCost:referencePen')}
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Typography
                        variant="labelMedium"
                        color={primaryColors.primary}>
                        {t('storageCost:volume')}
                      </Typography>
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <Typography
                        variant="labelMedium"
                        color={primaryColors.primary}>
                        {t('storageCost:amount')}
                      </Typography>
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {row.items.map((detailRow, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row">
                        <Typography
                          variant="bodyMedium"
                          color={primaryColors.primary}>
                          {Number(detailRow.productNumber)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="bodyMedium"
                          color={primaryColors.primary}>
                          {detailRow.productName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="bodyMedium"
                          color={primaryColors.primary}>
                          {Number(detailRow.referenceDocument)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="bodyMedium"
                          color={primaryColors.primary}>
                          {detailRow.referenceEntry}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="bodyMedium"
                          color={primaryColors.primary}>
                          {`${Math.round(detailRow.volume)} ${detailRow.volumeType}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="bodyMedium"
                          color={primaryColors.primary}>
                          {detailRow.count}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default StorageCostsDetailRow;
