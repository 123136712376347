import { TableSortLabel } from '@mui/material';
import { PTableHeaderCell } from 'components/simpleTable/SimpleTable';

const SubscriptionColumns = ({ columns, orderBy, order, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <>
      {columns.map((headCell) => {
        return (
          <PTableHeaderCell
            align={headCell.align || 'left'}
            key={headCell.id}
            sx={{ width: headCell.width || 'auto', minWidth: headCell.minWidth || 'auto' }}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.headerName}
              </TableSortLabel>
            ) : (
              <div>{headCell.headerName}</div>
            )}
          </PTableHeaderCell>
        );
      })}
    </>
  );
};

export default SubscriptionColumns;
