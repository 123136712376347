import { Box, Typography } from '@mui/material';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { createConfigurations, getConfigurations, updateConfigurations } from 'pages/pentaIntegration/api/IntegrationApi';
import SecretKey from 'pages/pentaIntegration/components/secretKey/SecretKey';
import { integrationValidationMessagesKeys } from 'pages/pentaIntegration/constants/IntegrationValidationMessagesKeys';
import { ENTITY_NOT_FOUND } from 'pages/pentaIntegration/constants/StringConstants';
import NetworkPreferences from 'pages/pentaIntegration/pages/configurationPreferences/networkPreferences/NetworkPreferences';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';

const ConfigurationPreferences = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    testAppKey: null,
    liveAppKey: null,
    testIpList: [],
    liveIpList: [],
    requiredEmail: '',
  });
  const [errors, setErrors] = useState({
    testIpList: '',
    requiredEmail: '',
    liveIpList: '',
  });
  const [dynamicEmailInput, setDynamicEmailInput] = useState([]);

  const handleAddEmailInput = () => {
    if (dynamicEmailInput.length < 4) {
      setDynamicEmailInput([...dynamicEmailInput, { email: '' }]);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const handleDynamicEmailDelete = (index) => {
    const deleteValue = [...dynamicEmailInput];
    deleteValue.splice(index, 1);
    setDynamicEmailInput(deleteValue);
  };

  const handleDynamicEmailChange = (event, index) => {
    const { value } = event.target;
    const onChangeValue = [...dynamicEmailInput];
    onChangeValue[index]['email'] = value;
    setDynamicEmailInput(onChangeValue);
    setErrors((prevState) => ({ ...prevState, [`requiredEmail${index}`]: '' }));
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  const getConfiguration = () => {
    setLoading(true);
    getConfigurations()
      .then((res) => {
        const { id, testAppKey, liveAppKey, ipList, mailList } = res.data.data;
        const testIpList = [];
        const liveIpList = [];

        ipList.forEach((ip) => {
          if (ip.isLive) {
            liveIpList.push(ip.ip);
          } else {
            testIpList.push(ip.ip);
          }
        });
        let requiredMail = '';
        const dynamicMail = [];
        mailList.forEach((mail, index) => {
          if (index === 0) {
            requiredMail = mail;
          } else {
            dynamicMail.push({ email: mail });
          }
        });

        setFormData((prevState) => ({ ...prevState, id, testAppKey, liveAppKey, testIpList, liveIpList, requiredEmail: requiredMail }));
        setDynamicEmailInput(dynamicMail);
      })
      .catch((err) => {
        if (ENTITY_NOT_FOUND !== err?.response?.data?.Message) {
          toast.error(err?.response?.data?.Message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createIpList = (ips, isLive) => ips.map((ip) => ({ ip, isLive }));

  const collectEmails = (primaryEmail, additionalEmails) => [primaryEmail, ...additionalEmails.map(({ email }) => email)];

  const validate = () => {
    const rules = {
      requiredEmail: 'email|required',
      testIpList: 'required',
      liveIpList: 'required',
    };

    const dynamicObject = {};
    if (dynamicEmailInput.length > 0) {
      dynamicEmailInput.forEach((item, index) => {
        rules[`requiredEmail${index}`] = 'email|required';
        dynamicObject[`requiredEmail${index}`] = item.email;
      });
    }

    const validation = new Validator({ ...formData, ...dynamicObject }, rules, integrationValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      const keyOfRules = Object.keys(rules);
      const localErrors = { ...errors };
      keyOfRules.forEach((item) => {
        localErrors[item] = validation.errors.first(item);
      });
      setErrors(localErrors);
    }
  };

  const onSaveHandler = () => {
    if (validate()) {
      if (formData.id) {
        const mailList = collectEmails(formData.requiredEmail, dynamicEmailInput);
        const ipList = [...createIpList(formData.liveIpList, true), ...createIpList(formData.testIpList, false)];
        const { id, testAppKey, liveAppKey } = formData;
        const sendData = {
          id,
          testAppKey,
          liveAppKey,
          mailList,
          ipList,
        };

        setLoading(true);
        updateConfigurations(sendData)
          .then((res) => {
            toast.success(res.data.message);
            getConfiguration();
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const mailList = collectEmails(formData.requiredEmail, dynamicEmailInput);
        const ipList = [...createIpList(formData.liveIpList, true), ...createIpList(formData.testIpList, false)];
        const sendData = {
          mailList,
          ipList,
        };
        setLoading(true);
        createConfigurations(sendData)
          .then((res) => {
            toast.success(res.data.message);
            getConfiguration();
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Box className="d-flex flex-column gap-24 w100">
      {loading && <LoadingSpinner />}
      <Typography variant="titleMedium">{t('integration:testSecretKey')}</Typography>
      <SecretKey
        password={formData.testAppKey}
        setLoading={setLoading}
        isLive={false}
        setFormData={setFormData}
      />
      <Typography variant="titleMedium">{t('integration:liveSecretKey')}</Typography>
      <SecretKey
        password={formData.liveAppKey}
        setLoading={setLoading}
        isLive={true}
        setFormData={setFormData}
      />
      <NetworkPreferences
        handleInputChange={handleInputChange}
        formData={formData}
        setErrors={setErrors}
        errors={errors}
        setFormData={setFormData}
        dynamicEmailInput={dynamicEmailInput}
        handleAddEmailInput={handleAddEmailInput}
        handleDynamicEmailDelete={handleDynamicEmailDelete}
        handleDynamicEmailChange={handleDynamicEmailChange}
        onSaveHandler={onSaveHandler}
      />
    </Box>
  );
};
export default ConfigurationPreferences;
