import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { neutralColors, primaryColors } from 'config/theme/Colors';

const CustomButton = styled(IconButton)(({ inputtype, sx }) => {
  const commonProperties = {
    padding: '10px 12px',
    ...sx,
  };

  if (inputtype === 'filled') {
    return {
      ...commonProperties,
      color: primaryColors.primary50,
      backgroundColor: primaryColors.primary,

      '&:hover': {
        backgroundColor: primaryColors.primary900,
        color: neutralColors.neutral50,
      },
      '&:active': {
        backgroundColor: primaryColors.primary800,
        color: neutralColors.neutral50,
      },
      '&:disabled': {
        color: neutralColors.neutral400,
        backgroundColor: neutralColors.neutral200,
      },
    };
  } else if (inputtype === 'outline') {
    return {
      ...commonProperties,
      border: `1px solid ${primaryColors.primary700}`,

      color: primaryColors.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        border: `1px solid ${primaryColors.primary700}`,
        backgroundColor: primaryColors.primary50,
        color: primaryColors.primary,
      },
      '&:active': {
        border: `1px solid ${primaryColors.primary700}`,
        backgroundColor: primaryColors.primary100,
        color: primaryColors.primary,
      },
      '&:disabled': {
        color: primaryColors.primary500,
        border: `1px solid ${primaryColors.primary100}`,
      },
    };
  } else if (inputtype === 'text') {
    return {
      ...commonProperties,
      border: '1px solid transparent',
      color: primaryColors.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: primaryColors.primary50,
        color: primaryColors.primary,
      },
      '&:active': {
        backgroundColor: primaryColors.primary100,
        color: primaryColors.primary,
      },
      '&:disabled': {
        color: primaryColors.primary500,
      },
    };
  } else if (inputtype === 'tonal') {
    return {
      ...commonProperties,
      color: primaryColors.primary,
      backgroundColor: primaryColors.primary100,
      '&:hover': {
        backgroundColor: primaryColors.primary300,
        color: primaryColors.primary,
      },
      '&:active': {
        backgroundColor: primaryColors.primary400,
        color: primaryColors.primary,
      },
      '&:disabled': {
        color: neutralColors.neutral400,
        backgroundColor: neutralColors.neutral200,
      },
    };
  }
});

const PIconButton = ({ inputtype = 'filled', disabled = false, onClick = () => {}, children, sx }) => {
  return (
    <CustomButton
      sx={sx}
      inputtype={inputtype}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </CustomButton>
  );
};

export default PIconButton;
