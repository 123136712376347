import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

const CustomReminLaterButton = styled(Button)(({ width }) => {
  const commonProperties = {
    height: '40px',
    borderRadius: 999,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.1,
    wordWrap: 'break-word',
    width,
  };
  return {
    ...commonProperties,
    padding: '10px 24px',
    border: `1px solid ${primaryColors.primary700}`,
    color: primaryColors.primary,
    backgroundColor: 'transparent',

    '&:hover': {
      border: `1px solid ${primaryColors.primary700}`,
      backgroundColor: primaryColors.primary50,
      color: primaryColors.primary,
    },
  };
});

const PRemindLaterButton = ({ disabled = false, endIcon = null, onClick = () => {}, children, width = '100%', ...rest }) => {
  return (
    <CustomReminLaterButton
      width={width}
      inputtype="outline"
      disabled={disabled}
      endIcon={endIcon}
      onClick={onClick}
      {...rest}>
      <Typography variant="labelLarge"> {children}</Typography>
    </CustomReminLaterButton>
  );
};

export default PRemindLaterButton;
