import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, IconButton, TableRow } from '@mui/material';
import PErrorChip from 'components/chip/PErrorChip';
import PSuccessChip from 'components/chip/PSuccessChip';
import { PTableRowCell } from 'components/simpleTable/SimpleTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SubscriptionListMenu from './SubscriptionListMenu';
import SubSubcriptionList from './subSubcriptionList/SubSubcriptionList';
const SubscriptionRows = ({ row, loaderFunction, setIsLoading }) => {
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', backgroundColor: '#ffffff', height: '56px' } }}>
        <PTableRowCell>
          <IconButton
            disabled={row.childPartners.length <= 0}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </PTableRowCell>
        <PTableRowCell
          component="th"
          scope="row">
          {row.interlocutorName}
        </PTableRowCell>
        <PTableRowCell align="left">{row.phone}</PTableRowCell>
        <PTableRowCell align="left">{row.emailAddress}</PTableRowCell>
        <PTableRowCell align="left">{row.authorizedB2BUser || t('accountRelatedPersons:everybody')}</PTableRowCell>
        <PTableRowCell align="left">
          {row.onlyMTVisible ? t('accountRelatedPersons:onlyMyCustomerRepresentative') : t('accountRelatedPersons:everybody')}
        </PTableRowCell>
        <PTableRowCell align="left">
          {row.deletionFlag ? <PSuccessChip label={t('userManagement:active')} /> : <PErrorChip label={t('userManagement:passive')} />}
        </PTableRowCell>
        <PTableRowCell align="left">
          {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
            <SubscriptionListMenu
              row={row}
              loaderFunction={loaderFunction}
              setIsLoading={setIsLoading}
            />
          )}
        </PTableRowCell>
      </TableRow>
      <TableRow>
        <PTableRowCell
          style={{ padding: 0 }}
          colSpan={10}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <SubSubcriptionList
                setIsLoading={setIsLoading}
                rows={row.childPartners}
                loaderFunction={loaderFunction}
              />
            </Box>
          </Collapse>
        </PTableRowCell>
      </TableRow>
    </>
  );
};

export default SubscriptionRows;
