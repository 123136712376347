import PTable from 'components/table/customTable/PTable';
import React, { useEffect, useMemo, useState } from 'react';

const PCustomTable = ({
  rows,
  columns,
  showHeader = true,
  headerTitle = '',
  headerRightSide = null,
  showFooter = true,
  infoLabel,
  subLabel,
  emptyMessage = '',
  showEmptyMessage = false,
}) => {
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    setTotalCount(rows.length);
  }, [rows.length]);

  const handlePageChange = (_, newPage) => {
    setPaginationModel((prevState) => ({ ...prevState, page: newPage - 1 }));
  };

  const menuItems = useMemo(() => {
    return [
      { key: 10, value: 10 },
      { key: 20, value: 20 },
      { key: 50, value: 50 },
    ];
  }, []);

  const handlePageSizeChange = (event) => {
    setPaginationModel((prevState) => ({ ...prevState, pageSize: event.target.value }));
  };

  const sortRequest = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (!a.children) {
      if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        return b[orderBy].localeCompare(a[orderBy]);
      }
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    } else {
      a.children.sort((childA, childB) =>
        order === 'desc' ? descendingComparator(childA, childB, orderBy) : -descendingComparator(childA, childB, orderBy),
      );
      b.children.sort((childA, childB) =>
        order === 'desc' ? descendingComparator(childA, childB, orderBy) : -descendingComparator(childA, childB, orderBy),
      );
      return 0;
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    if (stabilizedThis.length === 1 && stabilizedThis[0][0].children) {
      stabilizedThis[0][0].children.sort((childA, childB) =>
        order === 'desc' ? descendingComparator(childA, childB, orderBy) : -descendingComparator(childA, childB, orderBy),
      );
    }

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        paginationModel.page * paginationModel.pageSize,
        paginationModel.page * paginationModel.pageSize + paginationModel.pageSize,
      ),
    [order, orderBy, rows, paginationModel.page, paginationModel.pageSize],
  );

  return (
    <PTable
      data={visibleRows}
      columns={columns}
      showHeader={showHeader}
      headerTitle={headerTitle}
      totalCount={totalCount}
      headerRightSide={headerRightSide}
      showFooter={showFooter}
      pageSizeArray={menuItems}
      page={paginationModel.page}
      pageSize={paginationModel.pageSize}
      pageSizeChange={handlePageSizeChange}
      pageChange={handlePageChange}
      order={order}
      orderBy={orderBy}
      sortRequest={sortRequest}
      infoLabel={infoLabel}
      subLabel={subLabel}
      emptyMessage={emptyMessage}
      showEmptyMessage={showEmptyMessage}
    />
  );
};

export default React.memo(PCustomTable);
