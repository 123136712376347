import { Box, styled } from '@mui/material';
import PButton from 'components/button/PButton';
import PCenterModal from 'components/modal/PCenterModal';
import PTextField from 'components/textField/PTextField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const NoteModal = ({ onClose, row, setProductItems }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    piece: row.quantity,
    note: row.productDefinition,
  });

  const onUpdateHandler = () => {
    setProductItems((prevProducts) => {
      const index = prevProducts.findIndex((product) => product.cartItemId === row.cartItemId);

      if (index !== -1) {
        const updatedProducts = [...prevProducts];

        updatedProducts[index] = {
          ...updatedProducts[index],
          quantity: Number(formData.piece),
          productDefinition: formData.note,
        };

        return updatedProducts;
      }

      return prevProducts;
    });
    onClose();
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'piece') {
      if (/^[1-9]\d*$/.test(value) || value === '') {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <PCenterModal
      showClose
      width="750px"
      onClose={onClose}
      title={t('pay:itemUpdate')}>
      <Box className="d-flex flex-column gap-8">
        <Content>
          <Box sx={{ flex: 5 }}>
            <PTextField
              label={t('pay:itemNote')}
              placeholder={t('pay:note')}
              name="note"
              value={formData.note}
              onChange={onChangeHandler}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <PTextField
              label={t('pay:productQuantity')}
              placeholder={t('pay:piece')}
              name="piece"
              value={formData.piece}
              onChange={onChangeHandler}
            />
          </Box>
        </Content>
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={onUpdateHandler}>
            {t('pay:update')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default NoteModal;
