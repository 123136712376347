import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, styled, Typography } from '@mui/material';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PClientTable from 'components/table/PClientTable';
import PTooltip from 'components/tooltip/PTooltip';
import { greenColors, primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import PayAddModal from 'pages/pay/actionModals/PayAddModal';
import PayCancelModal from 'pages/pay/actionModals/PayCancelModal';
import PayChangeModal from 'pages/pay/actionModals/PayChangeModal';
import PayLinksModal from 'pages/pay/actionModals/PayLinksModal';
import PayRefreshModal from 'pages/pay/actionModals/PayRefreshModal';
import PayStopModal from 'pages/pay/actionModals/PayStopModal';
import { getProductsBySubscriptionNumber } from 'pages/pay/api/PayApi';
import { payTabNumbers } from 'pages/pay/constants/PayTabNumbers';
import { renewalTypeEnum } from 'pages/pay/constants/RenewalTypeEnum';
import { userDynamicPermissionForEndUserReportsKey } from 'pages/pay/constants/StringConstants';
import DetailTableMenu from 'pages/pay/tabs/components/DetailTableMenu';
import DetailHeaderMenu from 'pages/pay/tabs/components/tables/DetailHeaderMenu';
import DetailModal from 'pages/pay/tabs/subscriptionDetailInPay/components/DetailModal';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const ReportContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const AdditionalInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  justifyContent: 'space-between',
  overflow: 'auto',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'flex-start',
  },
}));

const AdditionalInfoItem = styled(Box)(() => ({
  minHeight: '92px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  minWidth: '204px',
  maxWidth: '298px',
  overflow: 'hidden',
  wordBreak: 'break-word',
}));

const PaySubscriptionDetail = ({ tabValue, productInfo, getCartCount, additionalInfoForTableMenu }) => {
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    email: '',
    link: '',
    name: '',
    phone: '',
    renewalDate: '',
    subscription: '',
    subscriptionNumber: '',
    count: '',
  });
  const [data, setData] = useState({ visibleData: [], allData: [] });
  const [subscriptionRenewalList, setSubscriptionRenewalList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [actions, setActions] = useState({ add: false, change: false, refresh: false, stop: false, cancel: false, link: false });
  const [links, setLinks] = useState([]);
  let localTabValue = payTabNumbers.endUserReports;
  if (userPermissions.find((permission) => permission.permissionType === userDynamicPermissionForEndUserReportsKey)) {
    localTabValue = payTabNumbers.subscriptionDetail;
  }

  useEffect(() => {
    if (tabValue === localTabValue && productInfo.subscriptionNumber) {
      refreshFunction();
    }
  }, [productInfo.subscriptionNumber, tabValue]);

  const refreshFunction = () => {
    setLoading(true);
    getProductsBySubscriptionNumber({ ...productInfo })
      .then((res) => {
        const updatedData = res.data.data.products.map((item) => ({ ...item, id: uuidv4() }));
        setData((prevState) => ({ ...prevState, allData: updatedData, visibleData: updatedData }));
        setAdditionalInfo(res.data.data.products[0]);
        setSubscriptionRenewalList(res.data.data.subscriptionRenewalList);
        setLinks(res.data.data.links);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'productName',
        headerName: t('pay:subscription'),
        flex: 1,
        minWidth: 600,
        sortable: true,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const productName = row.productName;
          const productNumber = row.productNumber;
          return (
            <div className="d-flex flex-column">
              <PTooltip
                placement="bottom-end"
                title={productName}>
                <div className="ellipsis">{productName}</div>
              </PTooltip>
              <Box
                className="hyperlink-text fit-content"
                onClick={() => {
                  loginForBayinet()
                    .then((res) => {
                      window.open(`${process.env.REACT_APP_BAYINET_URL}/Product/ProductDetail?ProductId=${productNumber}`, '_blank');
                    })
                    .catch((err) => {});
                }}>
                {productNumber}
              </Box>
            </div>
          );
        },
      },
      {
        field: 'renewalType',
        headerName: t('pay:refreshType'),
        minWidth: 136,
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => {
          const renewalType = row.renewalType;
          if (renewalType === renewalTypeEnum.MANUAL) {
            return (
              <PTooltip
                placement="bottom-end"
                title={t('pay:manualRenewal')}>
                <RefreshIcon />
              </PTooltip>
            );
          }

          if (renewalType === renewalTypeEnum.AUTOMATIC) {
            return (
              <PTooltip
                placement="bottom-end"
                title={t('pay:automaticRenewal')}>
                <RefreshIcon sx={{ color: greenColors.green600 }} />
              </PTooltip>
            );
          }

          return <></>;
        },
      },
      {
        field: 'status',
        headerName: t('pay:status'),
        minWidth: 140,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: 'startDate',
        headerName: t('pay:startingDate'),
        minWidth: 140,
        sortable: true,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const startDate = row.startDate;
          return dayjs(startDate).format('DD.MM.YYYY');
        },
      },
      {
        field: 'endDate',
        headerName: t('pay:endDate'),
        minWidth: 140,
        sortable: true,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const endDate = row.endDate;
          return dayjs(endDate).format('DD.MM.YYYY');
        },
      },
      {
        field: 'productCount',
        headerName: t('pay:piece'),
        minWidth: 96,
        sortable: true,
        headerAlign: 'right',
        align: 'right',
        disableColumnMenu: true,
      },
      {
        field: 'action',
        headerAlign: 'left',
        align: 'right',
        headerName: t('pay:productHistory'),
        width: 72,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          if (row.billingList.length > 0 || row.historyList.length > 0) {
            return (
              <IconResolver
                onClick={() => setOpenModal(row)}
                iconName="History"
                sx={{ cursor: 'pointer' }}
              />
            );
          }
          return <></>;
        },
      },
      {
        field: 'actions',
        headerAlign: 'right',
        align: 'right',
        headerName: '',
        width: 72,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          if ((row.change || row.renew || row.stop) && !row.ended) {
            return (
              <DetailTableMenu
                setActions={setActions}
                row={row}
              />
            );
          }
        },
      },
    ];
  }, [t]);

  const onSearchHandler = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({
          productName: e.productName,
          productNumber: e.productNumber,
          status: e.status,
          startDate: dayjs(e.startDate).format('DD.MM.YYYY'),
          endDate: dayjs(e.endDate).format('DD.MM.YYYY'),
          productCount: e.productCount,
        })
          .join('')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };

  const linkClickHandler = () => {
    setActions((prevState) => ({ ...prevState, link: true }));
  };
  return (
    <ReportContainer>
      {additionalInfo.subscription && (
        <AdditionalInfoContainer>
          <AdditionalInfoItem>
            <Typography
              variant="bodySmall"
              color={primaryColors.primary800}>
              {t('pay:subscription')}
            </Typography>
            <Typography variant="titleMedium">{additionalInfo.subscription}</Typography>
            <Typography variant="bodyMedium">{`#${additionalInfo.subscriptionNumber}`}</Typography>
          </AdditionalInfoItem>
          <AdditionalInfoItem>
            <Typography
              variant="bodySmall"
              color={primaryColors.primary800}>
              {t('pay:renewalDate')}
            </Typography>
            <Typography variant="titleMedium">{dayjs(additionalInfo.renewalDate, 'D.M.YYYY').format('DD.MM.YYYY')}</Typography>
          </AdditionalInfoItem>
          <AdditionalInfoItem>
            <Typography
              variant="bodySmall"
              color={primaryColors.primary800}>
              {t('pay:piece')}
            </Typography>
            <Typography variant="titleMedium">{additionalInfo.count}</Typography>
          </AdditionalInfoItem>

          <AdditionalInfoItem>
            <Typography
              variant="bodySmall"
              color={primaryColors.primary800}>
              {t('pay:document')}
            </Typography>

            <Box className="d-flex gap-4">
              <Box
                onClick={() => {
                  if (links && links.length > 0) linkClickHandler();
                }}
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: primaryColors.primary200,
                  borderRadius: '99px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '4px',
                }}>
                <IconResolver
                  iconName="FilePresent"
                  sx={{ width: '24px', height: '24px' }}
                />
              </Box>
              {links && links.length > 0 && (
                <Typography
                  onClick={linkClickHandler}
                  variant="titleMedium"
                  className="hyperlink-text">
                  {t('pay:subscriptionDocuments')}
                </Typography>
              )}
            </Box>
          </AdditionalInfoItem>

          <AdditionalInfoItem>
            <Typography
              variant="bodySmall"
              color={primaryColors.primary800}>
              {t('pay:contactInformation')}
            </Typography>
            <Box className="d-flex gap-4">
              <Box
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: primaryColors.primary200,
                  borderRadius: '99px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '4px',
                }}>
                <IconResolver
                  iconName="PersonOutlineOutlined"
                  sx={{ width: '24px', height: '24px' }}
                />
              </Box>
              <Box className="d-flex flex-column gap-4">
                <Typography variant="titleMedium">{additionalInfo.name}</Typography>
                <Typography
                  variant="bodySmall"
                  color={primaryColors.primary800}>
                  {additionalInfo.email}
                </Typography>
                <Typography
                  variant="bodySmall"
                  color={primaryColors.primary800}>
                  {additionalInfo.phone}
                </Typography>
              </Box>
            </Box>
          </AdditionalInfoItem>
        </AdditionalInfoContainer>
      )}
      {tabValue === localTabValue && (
        <div>
          {loading && <LoadingSpinner />}
          <PClientTable
            headerRightSide={
              <Box className="d-flex gap-12 justify-flex-end">
                <TableSearchTextField
                  width="300px"
                  value={searchValue}
                  onChange={onSearchHandler}
                  placeholder={t('pay:searchPlaceholder')}
                />
                {(additionalInfoForTableMenu?.addProduct || additionalInfoForTableMenu?.renew || additionalInfoForTableMenu?.stop) && (
                  <DetailHeaderMenu
                    additionalInfoForTableMenu={additionalInfoForTableMenu}
                    setActions={setActions}
                  />
                )}
              </Box>
            }
            rows={data.visibleData}
            headerTitle={t('pay:subscriptionDetailList')}
            dataLength={`${data.allData.length} ${t('pay:piece')} ${t('pay:product')}`}
            columns={columns}
            emptyMessage={t('pay:noRecordFound')}
            getRowClassName={(params) => params?.row?.ended && 'table-error-color'}
          />
        </div>
      )}
      {openModal && (
        <DetailModal
          row={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
      {actions.add && (
        <PayAddModal
          row={actions.add}
          onClose={() => setActions((prevState) => ({ ...prevState, add: false }))}
          getCartCount={getCartCount}
        />
      )}
      {actions.refresh && (
        <PayRefreshModal
          renewalList={subscriptionRenewalList}
          row={actions.refresh}
          onClose={() => setActions((prevState) => ({ ...prevState, refresh: false }))}
          getCartCount={getCartCount}
        />
      )}
      {actions.stop && (
        <PayStopModal
          row={actions.stop}
          onClose={() => setActions((prevState) => ({ ...prevState, stop: false }))}
          getCartCount={getCartCount}
        />
      )}
      {actions.cancel && (
        <PayCancelModal
          row={actions.cancel}
          onClose={() => setActions((prevState) => ({ ...prevState, cancel: false }))}
          getCartCount={getCartCount}
        />
      )}
      {actions.change && (
        <PayChangeModal
          row={actions.change}
          onClose={() => setActions((prevState) => ({ ...prevState, change: false }))}
          getCartCount={getCartCount}
        />
      )}
      {actions.link && (
        <PayLinksModal
          links={links}
          onClose={() => setActions((prevState) => ({ ...prevState, link: false }))}
        />
      )}
    </ReportContainer>
  );
};

export default PaySubscriptionDetail;
