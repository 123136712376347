/* eslint-disable max-len */
const EmptyScreenIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="64"
        height="64"
        rx="32"
        fill="#DBEAFE"
      />
      <path
        d="M26.9993 38.6667C27.4596 38.6667 27.8327 38.2936 27.8327 37.8333C27.8327 37.3731 27.4596 37 26.9993 37C26.5391 37 26.166 37.3731 26.166 37.8333C26.166 38.2936 26.5391 38.6667 26.9993 38.6667Z"
        fill="#93C5FD"
      />
      <path
        d="M36.9993 21.9987V15.332H21.166C19.791 15.332 18.666 16.457 18.666 17.832V46.1654C18.666 47.5403 19.791 48.6654 21.166 48.6654H42.8327C44.2077 48.6654 45.3327 47.5403 45.3327 46.1654V23.6654H38.666C37.7468 23.6654 36.9993 22.9179 36.9993 21.9987ZM26.9993 40.332C25.6208 40.332 24.4993 39.2106 24.4993 37.832C24.4993 36.4534 25.6208 35.332 26.9993 35.332C28.3779 35.332 29.4993 36.4534 29.4993 37.832C29.4993 39.2106 28.3779 40.332 26.9993 40.332ZM26.7552 32.5879C26.5924 32.7507 26.3792 32.832 26.166 32.832C25.9528 32.832 25.7396 32.7507 25.5768 32.5879L23.9102 30.9212L25.0885 29.7428L26.166 30.8203L28.9102 28.0762L30.0885 29.2546L26.7552 32.5879ZM40.3327 38.6654H31.9993V36.9987H40.3327V38.6654ZM40.3327 31.9987H31.9993V30.332H40.3327V31.9987Z"
        fill="#3B82F6"
      />
      <path
        d="M44.8002 21.9993C44.7357 21.917 44.675 21.832 44.5993 21.7576L38.9077 16.066C38.8333 15.9903 38.7483 15.9297 38.666 15.8652V21.9993H44.8002Z"
        fill="#93C5FD"
      />
    </svg>
  );
};
export default EmptyScreenIcon;
