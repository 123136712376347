import styled from '@emotion/styled';
import { AccordionDetails, Box, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import SelectableTags from 'components/selectableTags/SelectableTags';
import { greenColors, primaryColors, redColors } from 'config/theme/Colors';
import { CustomTypographyWithLineThrough } from 'pages/configMaster/tabs/configMaster/components/accordion/ConfigMasterAccordionSummary';
import ConfigMasterSelectButton from 'pages/configMaster/tabs/configMaster/components/ConfigMasterSelectButton';
import ItemContainer from 'pages/configMaster/tabs/configMaster/components/itemContainer/ItemContainer';
import ProductDetailModal from 'pages/configMaster/tabs/configMaster/components/productDetailModal/ProductDetailModal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import noImage from 'svgs/noImage.png';
import { formatPrice } from 'utils/HelperFunctions';

const CustomAccordionDetail = styled(AccordionDetails)(() => ({
  padding: 0,
  maxHeight: '442px',
  overflow: 'hidden',
  overflowY: 'auto',
}));

const ConfigMasterAccordionDetails = ({
  componentGroups,
  searchByMainGroup,
  selectedBrands,
  setSelectedParts,
  selectedParts,
  setSelectedBrands,
  loaderFunction,
  selectedTag,
  justShowInStock,
  selectedConfigs,
  setNotTriggerPrice,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectSinlgeItem = (component, item) => {
    const componentUpperCase = component.toUpperCase();
    let localArray = [...selectedParts];
    if (localArray.some((item) => item.component === componentUpperCase)) {
      localArray = localArray.filter((item) => item.component !== componentUpperCase);
    }
    const isAlreadySelected = isSelected(componentUpperCase, item.id);
    if (!isAlreadySelected) {
      localArray.push({
        component: componentUpperCase,
        value: item.id,
        count: 1,
        price: item.price,
        quantity: item.quantity,
        stock: item.stock,
        name: item.name,
        sapPrice: item.price.amountWithoutVatRate,
        stockDisplay: item.stockDisplay,
      });
    }
    setNotTriggerPrice(true);
    setSelectedParts(localArray);
  };

  const isSelected = (component, value) => {
    const isAlreadySelected = selectedParts.some((item) => item.component === component.toUpperCase() && item.value === value);
    return isAlreadySelected;
  };

  const onBrandTagClickHandler = (name, key) => {
    const localBrands = [...selectedBrands];
    const foundObjectIndex = localBrands.findIndex(
      (item) => item.key === key && item.componentGroupTypeName.toUpperCase() === componentGroups.componentGroupTypeName.toUpperCase(),
    );

    if (foundObjectIndex !== -1) {
      const foundObject = localBrands[foundObjectIndex];
      if (foundObject.answers.includes(key)) {
        localBrands.splice(foundObjectIndex, 1);
      } else {
        foundObject.answers.push(key);
      }
    } else {
      localBrands.push({
        componentGroupTypeName: name.toUpperCase(),
        answers: [key],
        key: key,
      });
    }

    setSelectedBrands(localBrands);
    loaderFunction({
      template: selectedTag,
      inStock: justShowInStock,
      selectedAnswers: localBrands,
      selectedValues: selectedParts,
      configIds: [...selectedConfigs.map((item) => item)],
    });
  };

  const openDetailModal = (productId) => {
    setIsModalOpen(productId);
  };

  const showPrice = (component) => {
    if (component?.price?.amountWithoutVatRate !== null && component?.price?.amountWithoutVatRate !== undefined && component?.price?.currencyCode) {
      if (component?.price?.unitPrice) {
        return `${formatPrice(component?.price?.unitPrice)} ${component?.price?.currencyCode}`;
      }
      return `${formatPrice(component?.price?.amountWithoutVatRate)} ${component?.price?.currencyCode}`;
    } else {
      return t('configMaster:loading');
    }
  };

  const renderQuestionAnswer = (questionAndAnswer, groupTypeName) => {
    const results = Object.entries(Object.groupBy(questionAndAnswer, ({ question }) => question));

    return results.map((result) => {
      return (
        <Typography
          key={result[0]}
          variant="labelLarge"
          color="#000000">
          {result[0]}
          <Box className="d-flex gap-8">
            {result[1].map((questionAndAnswer) => {
              return (
                <SelectableTags
                  key={`${result[0]}-${questionAndAnswer.answer}`}
                  value={questionAndAnswer.answer}
                  selected={selectedBrands.find(
                    (item) => item.key === questionAndAnswer.answer && item.componentGroupTypeName.toUpperCase() === groupTypeName.toUpperCase(),
                  )}
                  onClick={() => onBrandTagClickHandler(groupTypeName, questionAndAnswer.answer)}
                  title={questionAndAnswer.answer}
                />
              );
            })}
          </Box>
        </Typography>
      );
    });
  };

  return (
    <CustomAccordionDetail>
      <PDivider marginBottom="12px" />
      <Box
        className="d-flex flex-column gap-12"
        sx={{ padding: '8px 16px' }}>
        {componentGroups?.questionAnswer.length > 0 &&
          renderQuestionAnswer(componentGroups?.questionAnswer, componentGroups?.componentGroupTypeName, componentGroups?.components)}
        <Typography
          variant="labelLarge"
          color="#000000">
          {`${componentGroups.componentGroupTypeName} ${t('configMaster:selection')}`}
        </Typography>
        <TableSearchTextField
          placeholder={t('configMaster:searchBrand', { brand: componentGroups.componentGroupTypeName })}
          onChange={(event) => searchByMainGroup(event, componentGroups.componentGroupType)}
          sx={{ maxWidth: '300px' }}
        />
        {componentGroups.components.map((component) => {
          return (
            <ItemContainer
              key={component.id}
              isSelected={isSelected(componentGroups.componentGroupTypeName, component.id)}>
              <Box
                className="d-flex gap-16 align-items-center"
                sx={{ minWidth: '300px', width: '300px' }}>
                <Box>
                  <img
                    alt=""
                    width={40}
                    height={40}
                    src={component.image || noImage}
                  />
                </Box>
                <Box>
                  <Typography variant="bodyMedium">{component.name}</Typography>
                </Box>
              </Box>
              <Box className="d-flex gap-32 align-items-center">
                <Box className="d-flex flex-column text-end">
                  {component?.price?.oldAmount && (
                    <CustomTypographyWithLineThrough>
                      {`${formatPrice(component?.price?.oldAmount)} ${component?.price?.currencyCode}`}
                    </CustomTypographyWithLineThrough>
                  )}

                  <Typography
                    variant="titleMedium"
                    sx={{ minWidth: '140px' }}
                    color={component?.price?.oldAmount ? greenColors.green600 : primaryColors.primary}>
                    {showPrice(component)}
                  </Typography>
                  {component.stock > 1 ? (
                    <Typography
                      variant="bodySmall"
                      color={greenColors.green600}>
                      {`${t('configMaster:stock')}: ${component.stockDisplay} ${t('configMaster:piece')}`}
                    </Typography>
                  ) : (
                    <Typography
                      variant="bodySmall"
                      color={redColors.red600}>
                      {t('configMaster:insufficientStock')}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <ConfigMasterSelectButton
                    disabled={!component?.price?.amountWithoutVatRate}
                    isSelected={isSelected}
                    componentGroupTypeName={componentGroups.componentGroupTypeName}
                    id={component.id}
                    onClick={() => selectSinlgeItem(componentGroups.componentGroupTypeName, component)}
                  />
                </Box>
                <Box>
                  <PIconButton
                    onClick={() => openDetailModal(component.id)}
                    inputtype="tonal">
                    <IconResolver iconName="VisibilityOutlined" />
                  </PIconButton>
                </Box>
              </Box>
            </ItemContainer>
          );
        })}
      </Box>
      {isModalOpen && (
        <ProductDetailModal
          onClose={() => setIsModalOpen(false)}
          productId={isModalOpen}
        />
      )}
    </CustomAccordionDetail>
  );
};

export default React.memo(ConfigMasterAccordionDetails);
