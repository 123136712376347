/* eslint-disable max-len */
export const AccountRelatedPersonsEn = {
  relatedPersons: 'Related Persons',
  manageYourRelatedPersonsList: 'Manage your related persons list',
  addNewPerson: 'Add New Person',
  personList: 'Person List',
  subscription: 'Subscription',
  licence: 'Licence',
  storageDelivery: 'Storage Delivery',
  eArchive: 'e-Archive',
  finance: 'Finance',
  technicalService: 'Technical Service',
  storageDeliveryPersonList: 'Storage Delivery Person List',
  searchInTable: 'Search in table',
  person: 'Person',
  name: 'Name',
  surname: 'Surname',
  phoneNumber: 'Phone Number',
  email: 'E-mail',
  personCategory: 'Person Category',
  phone: 'Phone',
  optional: 'Optional',
  gender: 'Gender',
  selectGender: 'Select gender',
  savePerson: 'Save Person',
  infoText:
    'The name selected here must present his/her <strong>ID card</strong> when coming to the warehouse to receive the product. Otherwise, the product <strong>will not be delivered</strong> to a different person.',
  man: 'Man',
  woman: 'Woman',
  thisFieldIsRequired: 'Please enter {{field}}.',
  theEmailFormatIsInvalid: 'E-mail format is invalid.',
  edit: 'Edit',
  delete: 'Delete',
  cancel: 'Cancel',
  sureToDeletePerson: 'Are you sure you want to delete <strong>{{name}} {{lastName}}</strong>?',
  authorizedUpsertForm: 'Authorized Add/Edit Form for Subscription/License',
  companyTitle: 'Company Title',
  company: 'Company',
  address: 'Address',
  addressPlaceholder: 'Enter address',
  emailPlaceholder: 'Enter your email address',
  city: 'City',
  cityPlaceholder: 'Select city',
  postCode: 'Post Code',
  postCodePlaceholder: 'Enter post code',
  authorizedInCompany: 'Authorized In Company',
  selectAuthorized: 'Select authorized',
  whoShouldSeeInPenta: 'Who Should See In Penta',
  selectPersonPlaceholder: 'Select person',
  district: 'District',
  districtPlaceholder: 'Enter district',
  activeSubscriptionLicense: 'Active subscription/license',
  subauthorizedUpsertForm: 'Subscription/License Sub-Authority Add/Edit Form',
  type: 'Type',
  typePlaceholder: 'Select type',
  status: 'Status',
  statusPlaceholder: 'Select status',
  subscriptionLicenceList: 'Subscription/Licence List',
  nameSurnameTitle: 'Name Surname/Title',
  everybody: 'Everybody',
  onlyMyCustomerRepresentative: ' Only My Customer Representative',
  contractManager: 'Contract Manager',
  softwareCoordinator: 'Software Coordinator',
  add: 'Add',
  update: 'Update',
  makePassive: 'Make Passive',
  makeActive: 'Make Active',
  eArchiveCheckbox: 'I want my E-Archive invoices to be sent to my e-mail addresses defined below. I do not want paper invoices.',
};
