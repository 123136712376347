import { Box, Tab } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import TabPanel from 'components/tabs/PTabPanel';
import PTabs from 'components/tabs/PTabs';
import { getRequest } from 'pages/pentaIntegration/api/IntegrationApi';
import { integrationServiceEnum } from 'pages/pentaIntegration/constants/IntegrationServiceEnum';
import { ENTITY_NOT_FOUND } from 'pages/pentaIntegration/constants/StringConstants';
import { storageServicesTabNumbers } from 'pages/pentaIntegration/constants/TabConstants';
import StorageServicesIntegration from 'pages/pentaIntegration/pages/storageServices/StorageServicesIntegration';
import StorageServicesTransactionHistory from 'pages/pentaIntegration/pages/storageServices/StorageServicesTransactionHistory';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const StorageServices = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(location?.state || storageServicesTabNumbers.integration);
  const [loading, setLoading] = useState(false);
  const [activeRequest, setActiveRequest] = useState(null);

  useEffect(() => {
    getActiveRequest();
  }, []);

  const getActiveRequest = () => {
    setLoading(true);
    getRequest(integrationServiceEnum.storageService)
      .then((res) => {
        setActiveRequest(res.data.data);
      })
      .catch((err) => {
        if (ENTITY_NOT_FOUND !== err?.response?.data?.Message) {
          toast.error(err?.response?.data?.Message);
        }
        setActiveRequest(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box className="d-flex flex-column w100 gap-24">
      {loading && <LoadingSpinner />}
      <PTabs
        value={tabValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto">
        <Tab
          label={
            <div className="d-flex align-items-center gap-8">
              <IconResolver
                iconName="SettingsSuggestOutlined"
                fontSize="small"
              />
              {t('integration:integration')}
            </div>
          }
        />
        <Tab
          label={
            <div className="d-flex align-items-center gap-8">
              <IconResolver
                iconName="History"
                fontSize="small"
              />
              {t('integration:transactionHistory')}
            </div>
          }
        />
      </PTabs>
      <TabPanel
        value={tabValue}
        index={storageServicesTabNumbers.integration}
        mountChildren={false}>
        <StorageServicesIntegration
          activeRequest={activeRequest}
          getActiveRequest={getActiveRequest}
        />
      </TabPanel>
      <TabPanel
        value={tabValue}
        index={storageServicesTabNumbers.transactionHistory}
        mountChildren={false}>
        <StorageServicesTransactionHistory />
      </TabPanel>
    </Box>
  );
};
export default StorageServices;
