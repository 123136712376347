import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Popper, styled, Typography } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import PTextField from 'components/textField/PTextField';
import { primaryColors, redColors } from 'config/theme/Colors';
import { useEffect, useRef, useState } from 'react';

const Menu = styled('div')(({ anchor }) => ({
  overflow: 'hidden',
  top: '64px',
  borderTop: 0,
  border: `1px solid ${primaryColors.primary400}`,
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  maxHeight: '250px',
  overflowY: 'auto',
  zIndex: 1500,
  width: anchor ? anchor.offsetWidth : 'auto',
}));

const MenuItem = styled('div')(() => ({
  color: primaryColors.primary,
  padding: '12px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: primaryColors.primary50,
    color: primaryColors.primary,
  },
}));

const TextfieldWrapper = styled('div')(({ width }) => ({
  width,
}));

const PSearchableTextField = ({
  id,
  disabled = false,
  label = '',
  value,
  error = false,
  helpertext = '',
  onChange,
  multiline = false,
  maxRows = 1,
  maxLength = 299,
  showLength = false,
  errorMessage = '',
  startIcon = null,
  endIcon = null,
  menuItems = [],
  containerWidth = 'auto',
  searchInputValue,
  clearSearchInputValue,
  ...rest
}) => {
  const [showData, setShowData] = useState(false);
  const anchorRef = useRef(null);
  const popperRef = useRef(null);
  const toggleData = (event) => {
    event.target.blur();
    setShowData((prevState) => !prevState);
  };

  const handleMenuClick = (incomingFn) => {
    incomingFn();
    clearSearchInputValue();
    setShowData((prevState) => !prevState);
  };

  const handleOutsideClick = (event) => {
    if (anchorRef.current && !anchorRef.current.contains(event.target) && popperRef.current && !popperRef.current.contains(event.target)) {
      setShowData(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <TextfieldWrapper width={containerWidth}>
      <PTextField
        ref={anchorRef}
        id={id}
        onClick={toggleData}
        label={label}
        value={value}
        error={error}
        helpertext={helpertext}
        multiline={multiline}
        maxRows={maxRows}
        maxLength={maxLength}
        showLength={showLength}
        errorMessage={errorMessage}
        startIcon={startIcon}
        endIcon={<IconResolver iconName={showData && menuItems.length > 0 ? 'ExpandLess' : 'ExpandMore'} />}
        readOnly
        {...rest}
      />
      {showData && (
        <Popper
          sx={{ zIndex: 1500 }}
          open={showData}
          anchorEl={anchorRef.current}
          placement="bottom-start">
          <Menu
            anchor={anchorRef?.current}
            ref={popperRef}>
            <div style={{ margin: '12px' }}>
              <PTextField
                type="search"
                showLabel={false}
                showHelperContainer={false}
                onChange={onChange}
                startIcon={<SearchOutlinedIcon />}
                endIcon={
                  searchInputValue && (
                    <CloseIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        clearSearchInputValue();
                      }}
                      sx={{ cursor: 'pointer', color: redColors.red600 }}
                    />
                  )
                }
                width="100%"
                value={searchInputValue}
              />
            </div>
            {menuItems.map((item) => (
              <MenuItem
                onClick={() => handleMenuClick(item.onClick)}
                key={item.id}>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {item.content}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Popper>
      )}
    </TextfieldWrapper>
  );
};

export default PSearchableTextField;
