import { Menu, MenuItem, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import PTooltip from 'components/tooltip/PTooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DetailTableMenu = ({ row, setActions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshClickHandler = () => {
    setActions((prevState) => ({ ...prevState, refresh: row }));
    handleClose();
  };

  const stopClickHandler = () => {
    setActions((prevState) => ({ ...prevState, stop: row }));
    handleClose();
  };

  const changeClickHandler = () => {
    setActions((prevState) => ({ ...prevState, change: row }));
    handleClose();
  };

  return (
    <>
      <PTooltip
        title={t('pay:productActions')}
        placement="bottom-start">
        <PIconButton
          inputtype="text"
          onClick={handleClick}>
          <IconResolver iconName="MoreVert" />
        </PIconButton>
      </PTooltip>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {row.change && (
          <MenuItem onClick={changeClickHandler}>
            <Typography variant="labelLarge">{t('pay:change')}</Typography>
          </MenuItem>
        )}
        {row.renew && (
          <MenuItem onClick={refreshClickHandler}>
            <Typography variant="labelLarge">{t('pay:refresh')}</Typography>
          </MenuItem>
        )}
        {row.stop && (
          <MenuItem onClick={stopClickHandler}>
            <Typography variant="labelLarge">{t('pay:stop')}</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DetailTableMenu;
