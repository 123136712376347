import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography, styled } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import ErrorAlert from 'components/alert/ErrorAlert';
import InfoAlert from 'components/alert/InfoAlert';
import SuccessAlert from 'components/alert/SuccessAlert';
import PButton from 'components/button/PButton';
import PSuccessChip from 'components/chip/PSuccessChip';
import SingleFileUpload from 'components/fileUpload/SingleFileUpload';
import PClientTable from 'components/table/PClientTable';
import { blueColors, primaryColors, redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import {
  createBukOrderTemplate,
  downloadBulkOrderTemplate,
  ignoreErrorsAndCreate,
} from 'pages/pentaStorage/Tabs/productsInStorage/api/ProductsInStorageApi';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
const AddressExcelBulkContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '17px',
  background: '#ffffff',
  borderRadius: '12px',
}));

const ItemContainer = styled(Box)(({ topPadding = 0 }) => ({
  padding: `${topPadding}px 24px 0px 24px`,
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Info = styled(Box)(() => ({
  display: 'flex',
  border: `1px solid ${blueColors.blue300}`,
  backgroundColor: blueColors.blue50,
  borderRadius: '8px',
  padding: '12px',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}));

const Footer = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',
  padding: '0 24px 24px 0',
}));
const ErrorButton = styled(PButton)(() => ({
  border: `1px solid ${redColors.red600}`,
  color: redColors.red600,
}));

const CreateBulkOrder = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isSuccess, setIsSuccess] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [queryMessage, setQueryMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleOnChange = (event) => {
    setIsSuccess(null);
    setProgress(0);
    setFile(event.target.files[0]);
  };

  const dragAndDrop = (files) => {
    setIsSuccess(null);
    setProgress(0);
    setFile(files[0]);
  };

  const deleteFile = () => {
    setFile(null);
    setIsSuccess(null);
  };

  const handleBulkAddressCreate = () => {
    setIsSuccess(null);
    setProgress(0);
    const formData = new FormData();
    formData.append('file', file);

    const onUploadProgress = (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(progress);
    };
    setIsLoading(true);
    createBukOrderTemplate(formData, onUploadProgress)
      .then((res) => {
        setIsSuccess(true);
        setQueryMessage(res.data.data.guid);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        toast.error(err.response.data.message);
        if (err.response.data?.data?.errors) {
          const errorArray = [];
          err.response.data.data.errors.forEach((error) => {
            error.id = error.rowNo;
            errorArray.push(error);
          });
          setIsSuccess(false);
          setTableData(errorArray);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadFile = () => {
    downloadBulkOrderTemplate().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Toplu Sipariş Ekleme Şablon.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleIgnoreErrors = () => {
    setIsSuccess(null);
    setProgress(0);
    const formData = new FormData();
    formData.append('file', file);

    const onUploadProgress = (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(progress);
    };
    setIsLoading(true);
    ignoreErrorsAndCreate(formData, onUploadProgress)
      .then((res) => {
        setIsSuccess(true);
        setQueryMessage(res.data.data.guid);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        toast.error(err.response.data.message);
        if (err.response.data?.data?.errors) {
          const errorArray = [];
          err.response.data.data.errors.forEach((error) => {
            error.id = error.rowNo;
            errorArray.push(error);
          });
          setIsSuccess(false);
          setTableData(errorArray);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const columns = useMemo(() => {
    return [
      {
        field: 'rowNo',
        headerName: '#',
        width: 60,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',
      },
      {
        field: 'referansMusteriSiparisNo',
        headerName: t('productsInStorage:referenceCustomerOrderNo'),
        minWidth: 250,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',
        sortable: false,
        flex: 1,
      },
      {
        field: 'uretıcıParcaNo',
        headerName: t('productsInStorage:manufacturerPartNo'),
        minWidth: 160,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',
      },
      {
        field: 'adet',
        headerName: t('productsInStorage:piece'),
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',
      },
      {
        field: 'errorMessages',
        headerName: t('productsInStorage:message'),
        minWidth: 200,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: 'table-error-color',

        renderCell: ({ value }) => (
          <ul>
            {value.map((singleValue) => (
              <li key={singleValue}>{singleValue}</li>
            ))}
          </ul>
        ),
      },
    ];
  }, [t]);

  return (
    <SecondaryLayout
      path={`${paths.storage}${paths.viewBulkOrder}`}
      title={t('productsInStorage:createBulkOrder')}>
      <AddressExcelBulkContainer>
        <ItemContainer topPadding={20}>
          <Header>
            <div className="d-flex align-items-center gap-8">
              <Typography
                variant="titleMedium"
                color={primaryColors.primary}>
                {t('productsInStorage:bulkOrderFile')}
              </Typography>
              <PSuccessChip label={'EXCEL'} />
            </div>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary700}>
              {t('productsInStorage:quicklyCreateBulkOrdersFromFile')}
            </Typography>
          </Header>
        </ItemContainer>
        <PDivider />
        {isSuccess === false && (
          <ItemContainer>
            <ErrorAlert label={t('productsInStorage:excelError')} />
          </ItemContainer>
        )}
        <ItemContainer>
          {isSuccess !== true && (
            <Info>
              <div className="d-flex align-items-center">
                <InfoOutlinedIcon sx={{ color: blueColors.blue600, marginRight: '10px' }} />
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {t('deliveryAddress:downloadAndCheckTheTemplateBeforeUploading')}
                </Typography>
              </div>
              <div>
                <PButton
                  inputtype="text"
                  onClick={downloadFile}
                  startIcon={<FileDownloadOutlinedIcon />}>
                  {t('deliveryAddress:downloadTemplate')}
                </PButton>
              </div>
            </Info>
          )}

          {isSuccess === true && <SuccessAlert label={t('productsInStorage:excelSuccess')} />}
        </ItemContainer>
        <ItemContainer>
          <SingleFileUpload
            texMessage={t('deliveryAddress:xlsMaxSize')}
            onChange={handleOnChange}
            file={file}
            deleteFile={deleteFile}
            dragAndDrop={dragAndDrop}
            progress={progress}
            isSuccess={isSuccess}
          />
        </ItemContainer>
        {isSuccess === true && (
          <ItemContainer>
            <InfoAlert
              label={
                <Trans
                  values={{ number: queryMessage }}
                  i18nKey="productsInStorage:onSuccessFileUpload"
                  components={{
                    CustomLink: (
                      <Link
                        className="hyperlink-text"
                        to={`${paths.storage}${paths.viewBulkOrder}?query-message=${queryMessage}`}
                      />
                    ),
                  }}
                />
              }
            />
          </ItemContainer>
        )}
        <Footer>
          <PButton
            disabled={!file || isSuccess === true || isSuccess === false || isLoading}
            onClick={handleBulkAddressCreate}
            sx={{ width: 'auto' }}>
            {t('productsInStorage:createOrders')}
          </PButton>
        </Footer>
        {isSuccess === false && (
          <ItemContainer>
            <PClientTable
              headerRightSide={
                <Box className="w100 d-flex justify-flex-end">
                  <ErrorButton
                    onClick={handleIgnoreErrors}
                    width="auto"
                    startIcon={<DeleteOutlinedIcon />}
                    inputtype="outline">
                    {t('productsInStorage:deleteErrors')}
                  </ErrorButton>
                </Box>
              }
              dataLength={`${tableData.length} ${t('productsInStorage:piece')}`}
              headerTitle={t('productsInStorage:incorrectOrderLines')}
              rows={tableData}
              columns={columns}
              getRowHeight={() => 'auto'}
            />
          </ItemContainer>
        )}
      </AddressExcelBulkContainer>
    </SecondaryLayout>
  );
};
export default CreateBulkOrder;
