import { Box, Typography, styled } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import { primaryColors, redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { payTabNumbers } from 'pages/pay/constants/PayTabNumbers';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px 24px',
  rowGap: '20px',
}));

const OrderFailResult = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate(paths.home);
    }
  }, []);

  const goToCart = () => {
    navigate(`${paths.subscriptions}${paths.byProducts}`, { state: payTabNumbers.subscriptionCart });
  };

  return (
    <SecondaryLayout
      onClick={goToCart}
      title={t('pay:orderDetails')}>
      <ResponseContainer>
        <div className="flex-column d-flex">
          <IconResolver
            iconName="Cancel"
            sx={{ color: redColors.red600, width: '48px', height: '48px' }}
          />
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {`${t('pay:couldNotCreateOrder')}!`}
          </Typography>
          {location?.state?.errorMessages?.map((item) => {
            return (
              <Typography
                key={item.message}
                variant="bodyMedium"
                color={primaryColors.primary}>
                {item.message}
              </Typography>
            );
          })}
        </div>
      </ResponseContainer>
    </SecondaryLayout>
  );
};

export default OrderFailResult;
