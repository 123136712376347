import { Box, styled } from '@mui/material';

const MarginContainer = styled(Box)(() => ({
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const PentaStorageTabContainer = ({ children }) => {
  return <MarginContainer>{children}</MarginContainer>;
};

export default PentaStorageTabContainer;
