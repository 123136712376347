import { Box } from '@mui/material';

const TabPanel = ({ value, index, children, mountChildren = true, ...rest }) => {
  return mountChildren ? (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...rest}>
      <Box>{children}</Box>
    </div>
  ) : (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...rest}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
