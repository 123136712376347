import { paths } from 'constants/Paths';
import MainLayout from 'layout/mainLayout/MainLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import AddStaticTemplate from '../AddStaticTemplate';

export const staticTemplateRoutes = {
  path: paths.pageContent,
  element: (
    <ProtectedRoute
      path={paths.pageContent}
      isRequired>
      <MainLayout>
        <AddStaticTemplate />
      </MainLayout>
    </ProtectedRoute>
  ),
};
