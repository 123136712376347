import { Menu, MenuItem, Typography } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { paths } from 'constants/Paths';
import { updateInterluctor } from 'pages/accountRelatedPersons/api/AcountRelatedPersonsApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { subSubcriptionEnum } from './SubSubcriptionEnum';
const SubscriptionListMenu = ({ row, loaderFunction, setIsLoading }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAdd = () => {
    navigate(`${paths.add}${paths.accounting}${paths.subscriptionSubpermission}`, { state: { ...row, actionType: subSubcriptionEnum.create } });
    handleClose();
  };

  const onEdit = () => {
    navigate(`${paths.add}${paths.accounting}${paths.subscriptionPermission}`, { state: row });
    handleClose();
  };

  const makeActivePassive = () => {
    handleClose();
    setIsLoading(true);

    updateInterluctor({
      interlocutorCode: row.interlocutorNumber,
      name: row.interlocutorName,
      address: row.address,
      district: row.district,
      city: row.cityCode,
      phone: row.phone,
      emailAddress: row.emailAddress,
      postCode: row.postalCode,
      authorizedB2bUserMailAddress: row.authorizedB2BUser,
      isVisibleToCustomerRepresentativeOnly: row.onlyMTVisible ? true : false,
      deletionFlag: !row.deletionFlag,
    })
      .then((res) => {
        toast.success(res.data.message);
        loaderFunction();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PIconButton
        inputtype="text"
        onClick={handleClick}>
        <IconResolver iconName="MoreVert" />
      </PIconButton>
      <Menu
        id="subscriptionList"
        aria-labelledby="subscriptionList"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <MenuItem onClick={onAdd}>
          <Typography variant="labelLarge">{t('accountRelatedPersons:add')}</Typography>
        </MenuItem>
        <MenuItem onClick={onEdit}>
          <Typography variant="labelLarge">{t('accountRelatedPersons:update')}</Typography>
        </MenuItem>
        <MenuItem onClick={makeActivePassive}>
          <Typography variant="labelLarge">
            {row.deletionFlag ? t('accountRelatedPersons:makePassive') : t('accountRelatedPersons:makeActive')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SubscriptionListMenu;
