import { httpRequest } from 'config/api/AxiosInterceptors';
import { USER_API } from 'constants/Keys';

const API = '/api';

const USER = '/user';

const CHANGE_PASSWORD = '/change-password';

export const changePassword = ({ currentPassword, newPassword, newPassword2 }) =>
  httpRequest.post(`${USER_API}${API}${USER}${CHANGE_PASSWORD}`, {
    currentPassword,
    newPassword,
    newPassword2,
  });
