import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, STORAGE_API } from 'constants/Keys';

const STORAGE = '/storage';

const PRODUCTS = '/products';

const CART = '/cart';

const ADD_ITEM_TO_CART = '/add-item-to-cart';

const GET_BULK_ORDER_TEMPLATE = '/get-bulk-order-template';

const CREATE_BULK_ORDER = '/create-bulk-order';

const IGNORE_ERRORS_CREATE_BULK_ORDER = '/ignore-errors-create-bulk-order';

const CHECK_BULK_ORDER = '/check-bulk-order';

export const getAllProducts = () => httpRequest.get(`${STORAGE_API}${API}${STORAGE}${PRODUCTS}`);

export const addItemToCart = ({ stockCode, itemName, orderNo, entryNumber, storagePlace, quantity, serials }) =>
  httpRequest.post(`${STORAGE_API}${API}${STORAGE}${CART}${ADD_ITEM_TO_CART}`, {
    stockCode,
    itemName,
    orderNo,
    entryNumber,
    storagePlace,
    quantity,
    serials,
  });

export const downloadBulkOrderTemplate = () =>
  httpRequest.get(`${STORAGE_API}${API}${STORAGE}${PRODUCTS}${GET_BULK_ORDER_TEMPLATE}`, { responseType: 'blob' });

export const createBukOrderTemplate = (formData, onUploadProgress) =>
  httpRequest.post(`${STORAGE_API}${API}${STORAGE}${PRODUCTS}${CREATE_BULK_ORDER}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });

export const ignoreErrorsAndCreate = (formData, onUploadProgress) =>
  httpRequest.post(`${STORAGE_API}${API}${STORAGE}${PRODUCTS}${IGNORE_ERRORS_CREATE_BULK_ORDER}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });

export const getLoadedBulkOrders = ({ startDate, endDate, guid }) =>
  httpRequest.get(`${STORAGE_API}${API}${STORAGE}${PRODUCTS}${CHECK_BULK_ORDER}`, {
    params: {
      Guid: guid,
      StartDate: startDate,
      EndDate: endDate,
    },
  });
