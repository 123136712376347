import { MonthEn } from 'common/i18/MonthEn';
import { MonthTr } from 'common/i18/MonthTr';
import { ApproveModalTr } from 'components/approveModal/i18n/En';
import { ApproveModalEn } from 'components/approveModal/i18n/Tr';
import { ContractAgreementEn } from 'components/contractAgreement/i18n/ContractAgreementEn';
import { ContractAgreementTr } from 'components/contractAgreement/i18n/ContractAgreementTr';
import { FileUploadEn } from 'components/fileUpload/i18n/En';
import { FileUploadTr } from 'components/fileUpload/i18n/Tr';
import { TableEn } from 'components/table/i18n/En';
import { TableTr } from 'components/table/i18n/Tr';
import i18n from 'i18next';
import { LoginAppBarEn } from 'layout/loginLayout/i18n/En';
import { LoginAppBarTr } from 'layout/loginLayout/i18n/Tr';
import { MainAppBarEn } from 'layout/mainLayout/i18n/En';
import { MainAppBarTr } from 'layout/mainLayout/i18n/Tr';
import { AboutUsEn } from 'pages/aboutUs/i18n/En';
import { AboutUsTr } from 'pages/aboutUs/i18n/Tr';
import { AccountRelatedPersonsEn } from 'pages/accountRelatedPersons/i18n/En';
import { AccountRelatedPersonsTr } from 'pages/accountRelatedPersons/i18n/Tr';
import { AddStaticTemplateEn } from 'pages/addStaticTemplate/i18n/En';
import { AddStaticTemplateTr } from 'pages/addStaticTemplate/i18n/Tr';
import { AddUserEn } from 'pages/addUser/i18n/En';
import { AddUserTr } from 'pages/addUser/i18n/Tr';
import { ApproveUpdateEn } from 'pages/approveUpdate/i18n/En';
import { ApproveUpdateTr } from 'pages/approveUpdate/i18n/Tr';
import { ChangePasswordEn } from 'pages/changePassword/i18n/En';
import { ChangePasswordTr } from 'pages/changePassword/i18n/Tr';
import { ConfigMasterEn } from 'pages/configMaster/i18n/ConfigMasterEn';
import { ConfigMasterTr } from 'pages/configMaster/i18n/ConfigMasterTr';
import { ContactEn } from 'pages/contact/i18n/En';
import { ContactTr } from 'pages/contact/i18n/Tr';
import { DeliveryAddressEn } from 'pages/deliveryAddress/i18n/En';
import { DeliveryAddressTr } from 'pages/deliveryAddress/i18n/Tr';
import { Forbidden403En } from 'pages/forbidden/i18n/En';
import { Forbidden403Tr } from 'pages/forbidden/i18n/Tr';
import { LandingPageEn } from 'pages/landing/i18n/LandingPageEn';
import { LandingPageTr } from 'pages/landing/i18n/LandingPageTr';
import { LoginEn } from 'pages/login/i18n/En';
import { LoginTr } from 'pages/login/i18n/Tr';
import { MyContractsEn } from 'pages/myContracts/i18n/MyContractsEn';
import { MyContractsTr } from 'pages/myContracts/i18n/MyContractsTr';
import { PayEn } from 'pages/pay/i18n/PayEn';
import { PayTr } from 'pages/pay/i18n/PayTr';
import { PentaIntegrationEn } from 'pages/pentaIntegration/i18n/PentaIntegrationEn';
import { PentaIntegrationTr } from 'pages/pentaIntegration/i18n/PentaIntegrationTr';
import { DeliveryDetailsEn } from 'pages/pentaStorage/DeliveryDetails/i18n/En';
import { DeliveryDetailsTr } from 'pages/pentaStorage/DeliveryDetails/i18n/Tr';
import { CalculatorEn } from 'pages/pentaStorage/Tabs/calculator/i18n/En';
import { CalculatorTr } from 'pages/pentaStorage/Tabs/calculator/i18n/Tr';
import { MyShipmentsEn } from 'pages/pentaStorage/Tabs/myShipments/i18n/En';
import { MyShipmentsTr } from 'pages/pentaStorage/Tabs/myShipments/i18n/Tr';
import { ProductsInStorageEn } from 'pages/pentaStorage/Tabs/productsInStorage/i18n/En';
import { ProductsInStorageTr } from 'pages/pentaStorage/Tabs/productsInStorage/i18n/Tr';
import { StorageCartEn } from 'pages/pentaStorage/Tabs/storageCart/i18n/En';
import { StorageCartTr } from 'pages/pentaStorage/Tabs/storageCart/i18n/Tr';
import { StorageCostEn } from 'pages/pentaStorage/Tabs/storageCosts/i18n/En';
import { StorageCostTr } from 'pages/pentaStorage/Tabs/storageCosts/i18n/Tr';
import { StorageEn } from 'pages/pentaStorage/i18n/En';
import { StorageTr } from 'pages/pentaStorage/i18n/Tr';
import { ResetPasswordEn } from 'pages/resetPassword/i18n/En';
import { ResetPasswordTr } from 'pages/resetPassword/i18n/Tr';
import { UserManagementEn } from 'pages/userManagement/i18n/En';
import { UserManagementTr } from 'pages/userManagement/i18n/Tr';
import { initReactI18next } from 'react-i18next';

const resources = {
  tr: {
    login: LoginTr,
    loginAppBar: LoginAppBarTr,
    aboutUs: AboutUsTr,
    contact: ContactTr,
    addStaticTemplate: AddStaticTemplateTr,
    addUser: AddUserTr,
    resetPassword: ResetPasswordTr,
    userManagement: UserManagementTr,
    mainAppBar: MainAppBarTr,
    storage: StorageTr,
    month: MonthTr,
    changePassword: ChangePasswordTr,
    approveUpdate: ApproveUpdateTr,
    productsInStorage: ProductsInStorageTr,
    storageCost: StorageCostTr,
    approveModal: ApproveModalTr,
    storageCart: StorageCartTr,
    table: TableTr,
    myShipments: MyShipmentsTr,
    forbidden403: Forbidden403Tr,
    deliveryDetails: DeliveryDetailsTr,
    deliveryAddress: DeliveryAddressTr,
    fileUpload: FileUploadTr,
    calculator: CalculatorTr,
    accountRelatedPersons: AccountRelatedPersonsTr,
    myContracts: MyContractsTr,
    contractAgreement: ContractAgreementTr,
    configMaster: ConfigMasterTr,
    pay: PayTr,
    landing: LandingPageTr,
    integration: PentaIntegrationTr,
  },
  en: {
    login: LoginEn,
    loginAppBar: LoginAppBarEn,
    aboutUs: AboutUsEn,
    contact: ContactEn,
    addStaticTemplate: AddStaticTemplateEn,
    addUser: AddUserEn,
    resetPassword: ResetPasswordEn,
    userManagement: UserManagementEn,
    mainAppBar: MainAppBarEn,
    storage: StorageEn,
    month: MonthEn,
    changePassword: ChangePasswordEn,
    approveUpdate: ApproveUpdateEn,
    productsInStorage: ProductsInStorageEn,
    storageCost: StorageCostEn,
    approveModal: ApproveModalEn,
    storageCart: StorageCartEn,
    table: TableEn,
    myShipments: MyShipmentsEn,
    forbidden403: Forbidden403En,
    deliveryDetails: DeliveryDetailsEn,
    deliveryAddress: DeliveryAddressEn,
    fileUpload: FileUploadEn,
    calculator: CalculatorEn,
    accountRelatedPersons: AccountRelatedPersonsEn,
    myContracts: MyContractsEn,
    contractAgreement: ContractAgreementEn,
    configMaster: ConfigMasterEn,
    pay: PayEn,
    landing: LandingPageEn,
    integration: PentaIntegrationEn,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: 'tr',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
