import PChip from 'components/chip/PChip';

const Tags = ({ data, handleDelete }) => {
  return (
    <PChip
      label={data}
      onDelete={() => {
        handleDelete(data);
      }}
    />
  );
};
export default Tags;
