import { Box, Grid } from '@mui/material';
import PDatePicker from 'components/datePicker/PDatePicker';
import PTextField from 'components/textField/PTextField';
import EditProductDetailBox from 'pages/pay/pages/getAnOffer/components/EditProductDetailBox';
import NoteModal from 'pages/pay/pages/getAnOffer/components/NoteModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DetailRequest = ({ formData, onInputChange, setFormData, errors, productItems, setProductItems }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Grid
        container
        spacing={4}
        rowSpacing={1}>
        <Grid
          item
          xs={12}
          lg={4}
          md={4}>
          <PDatePicker
            label={t('pay:startDate')}
            name="startDate"
            value={formData.startDate}
            onChange={(value) => setFormData((prevState) => ({ ...prevState, startDate: value }))}
            error={!!errors.startDate}
            errorMessage={t(errors.startDate, { field: t('pay:startDate') })}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          md={4}>
          <PDatePicker
            label={t('pay:endingDate')}
            name="endDate"
            value={formData.endDate}
            onChange={(value) => setFormData((prevState) => ({ ...prevState, endDate: value }))}
            error={!!errors.endDate}
            errorMessage={t(errors.endDate, { field: t('pay:endingDate') })}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          md={4}>
          <PTextField
            label={t('pay:requestName')}
            placeholder={t('pay:requestName')}
            name="detailRequestName"
            value={formData.detailRequestName}
            onChange={onInputChange}
            error={!!errors.detailRequestName}
            errorMessage={t(errors.detailRequestName, { field: t('pay:requestName') })}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          md={4}>
          <PTextField
            label={`${t('pay:endUser')} (${t('pay:optional')})`}
            placeholder={t('pay:username')}
            name="username"
            value={formData.username}
            onChange={onInputChange}
            error={!!errors.username}
            errorMessage={t(errors.username, { field: t('pay:username'), char: 100 })}
          />
        </Grid>
        {openModal && (
          <NoteModal
            setProductItems={setProductItems}
            onClose={() => setOpenModal(false)}
            row={openModal}
          />
        )}
        <Box
          className="d-flex flex-column gap-16 overflow-hidden w100"
          sx={{ paddingLeft: '16px' }}>
          {productItems.map((productItem) => (
            <EditProductDetailBox
              setProductItems={setProductItems}
              key={productItem.cartItemId}
              productItem={productItem}
              setOpenModal={setOpenModal}
            />
          ))}
        </Box>
      </Grid>
    </>
  );
};

export default DetailRequest;
