import { Box, styled, Typography } from '@mui/material';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import PDivider from 'components/Divider/PDivider';
import IconResolver from 'components/iconResolver/IconResolver';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import PTooltip from 'components/tooltip/PTooltip';
import { blueColors, primaryColors } from 'config/theme/Colors';
import { addToCartEnums } from 'pages/pay/constants/AddToCartEnums';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'svgs/logo.svg';
import { formatPrice } from 'utils/HelperFunctions';

const EditProductDetailContainer = styled(Box)(() => ({
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

const TopPart = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '12px',
  padding: '12px',
  gridTemplateColumns: '40px auto 132px 116px 40px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    overflow: 'auto',
    gridTemplateColumns: '40px 466px  132px 116px 40px',
  },
}));

const ImageWithBorder = styled('img')(() => ({
  width: '40px',
  height: '40px',
  border: `1px solid ${primaryColors.primary300}`,
  borderRadius: '4px',
}));

const NameContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const PriceContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
}));

const BottomPart = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '12px',
  padding: '12px',
  paddingLeft: '74px',
  gridTemplateColumns: 'repeat(4, 190px)',
  overflow: 'hidden',
  maxHeight: '104px',
  [theme.breakpoints.down('lg')]: {
    overflow: 'auto',
  },
}));

const BottomInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const MultilineEllipsis = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  minWidth: 0,
  wordBreak: 'break-word',
}));

const LinkText = styled(Box)(() => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.25px',
  color: blueColors.blue500,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const EditProductDetailBox = ({ setOpenModal, productItem, setProductItems }) => {
  const { t } = useTranslation();
  const ellipsisRef = useRef(null);
  const containerRef = useRef(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    if (ellipsisRef.current && containerRef.current) {
      const isOverflowing = ellipsisRef.current.scrollHeight > containerRef.current.scrollHeight;
      setTooltipEnabled(isOverflowing);
    }
  }, [productItem.productDefinition]);

  const goToDetailPage = (givenNumber) => {
    const number = Number(givenNumber);
    loginForBayinet()
      .then((res) => {
        window.open(`${process.env.REACT_APP_BAYINET_URL}/Product/ProductDetail?ProductId=${number}`, '_blank');
      })
      .catch((err) => {});
  };

  const getTransactionLabel = (value) => {
    let label = '';
    if (value === addToCartEnums.addProduct) {
      label = 'Add Product';
    } else if (value === addToCartEnums.addSeat) {
      label = 'Add Seat';
    } else if (value === addToCartEnums.renewable) {
      label = 'Renewable';
    }
    return label;
  };

  const handleQuantityChange = (newQuantity) => {
    setProductItems((prevProducts) => {
      const index = prevProducts.findIndex((product) => product.cartItemId === productItem.cartItemId);

      if (index !== -1) {
        const updatedProducts = [...prevProducts];

        updatedProducts[index] = {
          ...updatedProducts[index],
          quantity: newQuantity,
        };

        return updatedProducts;
      }

      return prevProducts;
    });
  };

  return (
    <EditProductDetailContainer>
      <TopPart>
        <ImageWithBorder
          src={logo}
          alt="Product Image"
        />
        <NameContainer>
          <Typography variant="titleMedium">{productItem.productName}</Typography>
          <Typography
            variant="bodyMedium"
            className="hyperlink-text">
            <LinkText onClick={() => goToDetailPage(productItem.productNumber)}>{`#${productItem.productNumber}`}</LinkText>
          </Typography>
        </NameContainer>
        <InlineEditNumberInput
          value={productItem.quantity}
          onChange={handleQuantityChange}
        />
        <PriceContainer>
          <Typography variant="bodySmall">{t('pay:grandTotal')}</Typography>
          <Typography
            variant="titleMedium"
            className="text-end">
            {`${formatPrice(productItem.netAmount)} ${productItem.salesAndDistributionDocumentCurrency}`}
          </Typography>
        </PriceContainer>
        <Box className="d-flex align-items-center">
          <IconResolver
            onClick={() => setOpenModal(productItem)}
            iconName="EditNote"
            sx={{ cursor: 'pointer' }}
          />
        </Box>
      </TopPart>
      <PDivider marginBottom="0" />
      <BottomPart>
        <BottomInfo>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {`${t('pay:process')} / ${t('pay:subscription')}`}
          </Typography>
          <Typography
            variant="titleSmall"
            color={primaryColors.primary}>
            {`${getTransactionLabel(productItem.type)} - ${productItem.subscriptionId}`}
          </Typography>
        </BottomInfo>
        <BottomInfo>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {t('pay:itemNumber')}
          </Typography>
          <Typography
            variant="titleSmall"
            color={primaryColors.primary}>
            {productItem.penNumber}
          </Typography>
        </BottomInfo>
        <BottomInfo>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {t('pay:note')}
          </Typography>
          <Box
            ref={containerRef}
            sx={{ position: 'relative' }}>
            <PTooltip
              placement="bottom-end"
              title={tooltipEnabled ? productItem.productDefinition : ''}>
              <MultilineEllipsis
                ref={ellipsisRef}
                variant="titleSmall"
                color={primaryColors.primary}>
                {productItem.productDefinition}
              </MultilineEllipsis>
            </PTooltip>
          </Box>
        </BottomInfo>
        <BottomInfo>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary800}>
            {t('pay:unitPrice')}
          </Typography>
          <Typography
            variant="titleSmall"
            color={primaryColors.primary}>
            {`${formatPrice(productItem.netAmountAsDocument)} ${productItem.salesAndDistributionDocumentCurrency}`}
          </Typography>
        </BottomInfo>
      </BottomPart>
    </EditProductDetailContainer>
  );
};
export default EditProductDetailBox;
