import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PIconButton from 'components/button/PIconButton';
import PSearchableTextField from 'components/textField/PSearchableTextField';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddAddress from './AddAddress';

const PentaShipmentOption = ({ handleChange, error, errorMessage, storagePlace, setValues, deliveryOptions = [] }) => {
  const { t } = useTranslation();
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  const [modalVisible, setModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showAllMenu, setShowAllMenu] = useState(true);

  const handleModalVisibility = () => {
    setModalVisible((prevState) => !prevState);
  };

  const onSelectChange = (event) => {
    setSearchInputValue(event.target.value);
    setShowAllMenu(false);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
  };

  const menuItems = useMemo(() => {
    const formatContent = (deliveryOption) => `${deliveryOption.name} ${deliveryOption.street} ${deliveryOption.stR_SUPPL3} ${deliveryOption.phone}`;

    const handleItemClick = (deliveryOption) => {
      const customObject = { target: { value: deliveryOption.customerCode } };
      handleChange(customObject);
      setInputValue(formatContent(deliveryOption));
      setShowAllMenu(true);
    };

    return deliveryOptions
      .filter(
        (deliveryOption) =>
          showAllMenu ||
          Object.values({
            name: deliveryOption.name,
            street: deliveryOption.street,
            stR_SUPPL3: deliveryOption.stR_SUPPL3,
            phone: deliveryOption.phone,
          })
            .join(' ')
            .toLocaleLowerCase('tr')
            .includes(searchInputValue.toLocaleLowerCase('tr')),
      )
      .map((deliveryOption) => ({
        id: deliveryOption.customerCode,
        content: <div>{formatContent(deliveryOption)}</div>,
        onClick: () => handleItemClick(deliveryOption),
      }));
  }, [deliveryOptions, searchInputValue, showAllMenu, handleChange]);

  return (
    <Box className="d-flex align-items-center gap-20">
      <PSearchableTextField
        containerWidth="100%"
        placeholder={t('deliveryDetails:selectDeliveryAddress')}
        name="penta"
        menuItems={menuItems}
        label={t('deliveryDetails:deliveryOptions')}
        value={inputValue}
        searchInputValue={searchInputValue}
        clearSearchInputValue={clearSearchInputValue}
        onChange={onSelectChange}
        error={error}
        errorMessage={errorMessage}
      />
      {userPermissions.find((permission) => permission.route === 'api/storage/cart/add-delivery-address') && (
        <PIconButton
          inputtype="tonal"
          onClick={handleModalVisibility}>
          <AddIcon />
        </PIconButton>
      )}
      {modalVisible && (
        <AddAddress
          setValues={setValues}
          onClose={handleModalVisibility}
          storagePlace={storagePlace}
        />
      )}
    </Box>
  );
};

export default PentaShipmentOption;
