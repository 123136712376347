import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import ConfigMasterContainer from 'pages/configMaster/ConfigMasterContainer';
import WithCardInfoFail from 'pages/configMaster/pages/fail/WithCardInfoFail';
import WithoutCardInfoFail from 'pages/configMaster/pages/fail/WithoutCardInfoFail';
import ConfigMasterIndex from 'pages/configMaster/pages/index/ConfigMasterIndex';
import WithCardInfoSuccess from 'pages/configMaster/pages/success/WithCardInfoSuccess';
import WithoutCardInfoSuccess from 'pages/configMaster/pages/success/WithoutCardInfoSuccess';

export const configMasterRoutes = [
  {
    path: `${paths.wizard}${paths.fbw}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <ConfigMasterContainer />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.wizard}${paths.paymentSuccess}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <WithCardInfoSuccess />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.wizard}${paths.paymentFail}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <WithCardInfoFail />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.wizard}${paths.orderSuccess}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <WithoutCardInfoSuccess />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.wizard}${paths.orderFail}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <WithoutCardInfoFail />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.wizard}${paths.index}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <ConfigMasterIndex />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
];
