import { AccordionDetails, styled } from '@mui/material';

const CustomAccordionDetail = styled(AccordionDetails)(({ maxheight, sx }) => ({
  padding: 0,
  maxHeight: maxheight,
  overflow: 'hidden',
  overflowY: 'auto',
  marginBottom: '8px',
  ...sx,
}));

const PAccordionDetail = ({ children, maxHeight = 'auto', sx }) => {
  return (
    <CustomAccordionDetail
      maxheight={maxHeight}
      sx={sx}>
      {children}
    </CustomAccordionDetail>
  );
};

export default PAccordionDetail;
