import { useTheme } from '@emotion/react';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { loginForBayinet } from 'common/api/UserLoginBayinet';
import PButton from 'components/button/PButton';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import { primaryColors } from 'config/theme/Colors';
import { getItemDetail } from 'pages/configMaster/api/ConfigMasterApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const NameAndButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > button': {
      width: '100% !important',
    },
  },
}));

const ProductDetailModal = ({ onClose, productId }) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getItemDetail(productId)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getImageWidth = () => {
    if (matchesSm) {
      return 300;
    } else if (matchesMd) {
      return 480;
    } else {
      return 480;
    }
  };

  const goToBayinet = (url) => {
    loginForBayinet()
      .then((res) => {
        window.location.href = url;
      })
      .catch((err) => {});
  };

  return (
    <PCenterModal
      onClose={onClose}
      height="90%"
      width="675px"
      showClose
      title={t('configMaster:productDetail')}>
      {loading && <LoadingSpinner />}
      <Box className="d-flex flex-column gap-24">
        <Box
          sx={{ border: `1px solid ${primaryColors.primary200}`, borderRadius: '8px' }}
          className="d-flex justify-content-center">
          <img
            width={getImageWidth()}
            height={360}
            alt={data?.productName}
            src={data?.productImage}
          />
        </Box>
        <NameAndButtonContainer>
          <Typography variant="titleMedium">{data?.productName}</Typography>
          <PButton
            onClick={() => goToBayinet(data?.productBayinetUrl)}
            width="280px"
            inputtype="outline"
            startIcon={<IconResolver iconName="ArrowOutwardOutlined" />}>
            {t('configMaster:productPage')}
          </PButton>
        </NameAndButtonContainer>
        <Box>
          <Box
            sx={{
              height: '60px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderBottom: `1px solid ${primaryColors.primary200}`,
            }}
            className="d-flex gap-20 align-items-center">
            <Typography
              sx={{ flex: 1 }}
              variant="titleSmall">
              {t('configMaster:productNumber')}
            </Typography>
            <Typography
              sx={{ flex: 1 }}
              variant="bodyMedium">
              {productId}
            </Typography>
          </Box>
          {data?.productProperties.map((item) => {
            return (
              <Box
                sx={{
                  height: '60px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  borderBottom: `1px solid ${primaryColors.primary200}`,
                }}
                className="d-flex gap-20 align-items-center"
                key={`${item.propertyName}-${item.propertValue}`}>
                <Typography
                  sx={{ flex: 1 }}
                  variant="titleSmall">
                  {item.propertyName}
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="bodyMedium">
                  {item.propertValue}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default ProductDetailModal;
