import { Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import { SelectionFormLabel } from 'components/checkbox/PCheckbox';
import { primaryColors } from 'config/theme/Colors';

const CustomRadio = styled(Radio)(() => {
  return {
    '&.Mui-disabled': {
      color: `${primaryColors.primary} !important`,
    },
    '&.Mui-checked': {
      color: primaryColors.primary,
    },
    '&.MuiRadio-root:hover': {
      backgroundColor: primaryColors.primary50,
    },
  };
});

const PRadio = ({ checked, onChange, label = '', width = 'auto', ...rest }) => {
  return (
    <SelectionFormLabel
      width={width}
      label={<Typography variant="titleMedium"> {label}</Typography>}
      control={
        <CustomRadio
          checked={checked}
          onChange={onChange}
          {...rest}
        />
      }
    />
  );
};

export default PRadio;
