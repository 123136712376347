import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddPermission from './AddPermission';
import AddUser from './AddUser';
import { getAllPermissions, getUser } from './api/AddUserApi';
import { deleteTemplate, getAllTemplates } from './api/TemplateApi';
const AddUserContainer = () => {
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const [userTab, setUserTab] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    mobile: '',
    phone: '',
    expireDate: null,
    profit: undefined,
    isActive: true,
    callCenterService: false,
    permissionTypes: [],
    restrictedIps: [],
    isAdmin: false,
  });
  const [templateData, setTemplateData] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const [emailToBeSetAsDefault, setEmailToBeSetAsDefault] = useState(null);
  const [mobileToBeSetAsDefault, setMobileToBeSetAsDefault] = useState(null);

  const params = useParams();

  const deleteTemplates = (event, id) => {
    event.stopPropagation();

    deleteTemplate(id)
      .then((res) => {
        toast.success(res.data.message);
        setSelectValue('');
        loadTemplates();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setSelectValue('');
      });
  };

  const loadTemplates = async () => {
    const response = await getAllTemplates(currentUser.user.sapCustomerId);
    if (response.data) {
      const templates = [];
      response.data?.data?.forEach((template) => {
        templates.push({
          key: template.id.toString(),
          value: template.name,
          permissions: template.permissionTypes,
          rightIcon: <DeleteOutlineIcon onClick={(event) => deleteTemplates(event, template.id)} />,
        });
      });
      setTemplateData(templates);
    }
  };

  const loaderFunction = async () => {
    try {
      setLoading(true);
      const { data } = await getAllPermissions();
      setPermissions(data.data);
      if (params.userId) {
        const { data } = await getUser(params.userId);
        const user = data.data;
        let mobile = '';
        let phone = '';
        if (data.data.mobile) {
          mobile = `+${data.data.mobile}`;
        }
        if (data.data.phone) {
          phone = `+${data.data.phone}`;
        }
        setMobileToBeSetAsDefault(user.mobileToBeSetAsDefault);
        setEmailToBeSetAsDefault(user.emailToBeSetAsDefault);

        setUserInfo((prevState) => ({
          ...prevState,
          name: user.name || undefined,
          surname: user.surname || undefined,
          email: user.email || undefined,
          mobile: mobile,
          phone: phone,
          expireDate: dayjs(user.expireDate) || undefined,
          profit: user.profit || undefined,
          isActive: user.isActive,
          callCenterService: user.callCenterService,
          restrictedIps: user.restrictedIps || [],
          permissionTypes: user.permissionTypes || [],
          isAdmin: user.isAdmin,
        }));
      }
      loadTemplates();
    } catch (err) {
      toast.error(err.response.data.Message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loaderFunction();
  }, []);

  const onSelectChange = (value) => {
    setSelectValue(value);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {userTab ? (
        <AddUser
          setTab={setUserTab}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          emailToBeSetAsDefault={emailToBeSetAsDefault}
          mobileToBeSetAsDefault={mobileToBeSetAsDefault}
        />
      ) : (
        <AddPermission
          templateData={templateData}
          permissions={permissions}
          setTab={setUserTab}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          selectValue={selectValue}
          setSelectValue={onSelectChange}
          loadTemplates={loadTemplates}
        />
      )}
    </>
  );
};
export default AddUserContainer;
