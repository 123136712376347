import { paths } from 'constants/Paths';
import LoginLayout from 'layout/loginLayout/LoginLayout';
import ForgetPassword from '../ForgetPassword';
import ResetForgottenPassword from '../ResetForgottenPassword';

export const resetPasswordRoutes = [
  {
    path: `${paths.resetPassword}/:token`,
    element: (
      <LoginLayout>
        <ResetForgottenPassword />
      </LoginLayout>
    ),
  },
  {
    path: `${paths.forgetPassword}`,
    element: (
      <LoginLayout>
        <ForgetPassword />
      </LoginLayout>
    ),
  },
];
