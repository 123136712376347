import { Grid } from '@mui/material';
import PButton from 'components/button/PButton';
import PCenterModal from 'components/modal/PCenterModal';
import PTextField from 'components/textField/PTextField';
import { updateCart } from 'pages/configMaster/api/ConfigMasterApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const UpdateCart = ({ cardInfo, getAllCarts, setIsUpsertModalOpen, setLoading }) => {
  const { t } = useTranslation();
  const [cartName, setCartName] = useState('');

  const onChangeHandler = (event) => {
    setCartName(event.target.value);
  };

  useEffect(() => {
    setCartName(cardInfo.cartName);
  }, []);

  const handleClose = () => {
    setIsUpsertModalOpen(false);
  };

  const update = () => {
    setLoading(true);
    updateCart(cardInfo.id, cartName)
      .then((res) => {
        toast.success(res.data.message);
        handleClose();
        getAllCarts();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PCenterModal onClose={handleClose}>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}>
          <PTextField
            label={t('storageCart:cartName')}
            value={cartName}
            onChange={onChangeHandler}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}>
          <PButton onClick={handleClose}>{t('storageCart:cancel')}</PButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}>
          <PButton
            onClick={update}
            disabled={!cartName}>
            {t('storageCart:save')}
          </PButton>
        </Grid>
      </Grid>
    </PCenterModal>
  );
};
export default UpdateCart;
