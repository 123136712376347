export const LOCAL_STORAGE_KEY = 'user-token';

export const USER_API = `${process.env.REACT_APP_USER_API_ENDPOINT}`;

export const COMMON_API = `${process.env.REACT_APP_COMMON_API_ENDPOINT}`;

export const STORAGE_API = `${process.env.REACT_APP_STORAGE_API_ENDPOINT}`;

export const CONFIG_MASTER_API = `${process.env.REACT_APP_CONFIG_MASTER_API_ENDPOINT}`;

export const PAY_API = `${process.env.REACT_APP_PAY_API_ENDPOINT}`;

export const INTEGRATION_API = `${process.env.REACT_APP_INTEGRATION_API_ENDPOINT}`;

export const BASARSOFT = 'https://bms.basarsoft.com.tr';

export const API = '/api';
