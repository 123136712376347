import { styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import PChip from './PChip';

const CustomChip = styled(PChip)(() => ({
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '24px',
  padding: '2px, 8px',
  height: '20px',
  color: primaryColors.primary700,
  backgroundColor: primaryColors.primary100,
  border: `1px solid ${primaryColors.primary300}`,
  '& .MuiChip-label': {
    fontSize: '12px',
    fontWeight: 500,
  },
}));

const PDefaultChip = ({ variant = 'filled', label, ...rest }) => {
  return (
    <CustomChip
      variant={variant}
      label={label}
      {...rest}
    />
  );
};

export default PDefaultChip;
