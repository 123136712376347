import { Box, Typography } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

/* eslint-disable max-len */
const NoRowsFound = () => {
  return (
    <Box className="d-flex flex-column gap-12 align-items-center justify-content-center w100 h100">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width="64"
          height="64"
          rx="32"
          fill="#DBEAFE"
        />
        <path
          d="M26.9993 38.6667C27.4596 38.6667 27.8327 38.2936 27.8327 37.8333C27.8327 37.3731 27.4596 37 26.9993 37C26.5391 37 26.166 37.3731 26.166 37.8333C26.166 38.2936 26.5391 38.6667 26.9993 38.6667Z"
          fill="#93C5FD"
        />
        <path
          d="M36.9993 21.9997V15.333H21.166C19.791 15.333 18.666 16.458 18.666 17.833V46.1663C18.666 47.5413 19.791 48.6663 21.166 48.6663H42.8327C44.2077 48.6663 45.3327 47.5413 45.3327 46.1663V23.6663H38.666C37.7468 23.6663 36.9993 22.9189 36.9993 21.9997ZM26.9993 40.333C25.6208 40.333 24.4993 39.2116 24.4993 37.833C24.4993 36.4544 25.6208 35.333 26.9993 35.333C28.3779 35.333 29.4993 36.4544 29.4993 37.833C29.4993 39.2116 28.3779 40.333 26.9993 40.333ZM26.7552 32.5889C26.5924 32.7516 26.3792 32.833 26.166 32.833C25.9528 32.833 25.7396 32.7516 25.5768 32.5889L23.9102 30.9222L25.0885 29.7438L26.166 30.8213L28.9102 28.0771L30.0885 29.2555L26.7552 32.5889ZM40.3327 38.6663H31.9993V36.9997H40.3327V38.6663ZM40.3327 31.9997H31.9993V30.333H40.3327V31.9997Z"
          fill="#3B82F6"
        />
        <path
          d="M44.8002 21.9993C44.7357 21.917 44.675 21.832 44.5993 21.7576L38.9077 16.066C38.8333 15.9903 38.7483 15.9297 38.666 15.8652V21.9993H44.8002Z"
          fill="#93C5FD"
        />
      </svg>
      <Typography
        variant="titleMedium"
        color={primaryColors.primary}>
        Geçmiş talep bulunmuyor
      </Typography>
      <Typography
        variant="bodyMedium"
        color={primaryColors.primary800}>
        Geçmiş bütün taleplerin durumlarını buradan takip edebilirsiniz.
      </Typography>
    </Box>
  );
};

export default NoRowsFound;
