export const DeliveryAddressEn = {
  tableBranchCode: 'Branch Code',
  branch: 'Branch',
  address: 'Address',
  phone: 'Phone',
  status: 'Status',
  all: 'All',
  active: 'Active',
  passive: 'Passive',
  myDeliveryAddresses: 'My Delivery Addresses',
  manageYourDeliveryAddresses: 'Manage your delivery addresses',
  addBulkAddress: 'Add Bulk Address',
  addNewAddress: 'Add New Address',
  branchCode: 'Branch code',
  selectCity: 'Select city',
  addCity: 'Add city',
  activePassive: 'Active/Passive',
  fetchAddresses: 'Fetch Addresses',
  myRegisteredAddresses: 'My Registered Addresses',
  piece: 'Piece',
  searchAddress: 'Search address...',
  youCanOnlyMakeNumberChoices: 'You can only make {{number}} choices',
  noResultFound: 'No result found',
  edit: 'Edit',
  makePassive: 'Make passive',
  makeActive: 'Make active',
  noMatchingRecordsFound: 'No Matching Records Found',
  addBulkAddressExcel: 'Quickly add bulk address from file',
  downloadAndCheckTheTemplateBeforeUploading: 'Download and check the template before uploading.',
  downloadTemplate: 'Download template',
  cancel: 'Cancel',
  uploadAddressesToTheSystem: 'Upload Addresses to the System',
  xlsMaxSize: 'XLS file accepted (max 30MB)',
  addDeliveryAddress: 'Add Delivery Address',
  savedSuccessfully: 'Address saved successfully',
  thisFieldIsRequired: 'Please enter {{field}}.',
  theEmailFormatIsInvalid: 'E-mail format is invalid.',
  basicKnowledge: 'Basic Knowledge',
  companyName: 'Company Name',
  city: 'City',
  district: 'District',
  mobile: 'Mobile',
  optional: 'Optional',
  doorNo: 'Door No',
  postCode: 'Post Code',
  fax: 'Fax',
  email: 'E-mail',
  shipment: 'Shipment',
  mapInfo: 'Your address will automatically be updated on the map. You can mark your address with the pin on the map.',
  saveAddress: 'Save Address',
  successfullyUpdated: 'Successfully Updated',
  regexFailed: 'The postal code must be a 5-digit number.',
  view: 'View',
  theProcessingOfBulkAdded: 'View bulk added addresses',
  selectStartDate: 'Select start date',
  selectEndDate: 'Select end date',
  searchForQueueNumber: 'Search for queue number',
  list: 'List',
  searchWithinTheTable: 'Search within the table...',
  addedFromBulkAddressFile: 'Added from Bulk Address File',
  queueNumber: 'Queue No',
  date: 'Date',
  emailTable: 'E-Mail',
  message: 'Message',
  rowNumber: 'Row No',
  onSuccessFileUpload: 'You can view the addresses you have uploaded with the number <CustomLink>{{number}}</CustomLink>.',
  viewBulkUploadedAddresses: 'View Bulk Uploaded Addresses',
};
