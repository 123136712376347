const PdfModal = ({ pdf }) => {
  return (
    <>
      <embed
        style={{ width: '100%', height: '100%' }}
        src={`data:application/pdf;base64,${pdf}`}
      />
    </>
  );
};

export default PdfModal;
