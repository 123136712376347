import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PButton from 'components/button/PButton';
import InlineEditNumberInput from 'components/inlineEditNumberInput/InlineEditNumberInput';
import PClientTable from 'components/table/PClientTable';
import { redColors } from 'config/theme/Colors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const CalculatorTable = ({ tableData, calculate, setTableData }) => {
  const { t } = useTranslation();

  const inputChange = (value, row, name) => {
    const localData = [...tableData];
    const foundAllRow = localData.find((data) => data.id === row.id);
    foundAllRow[name] = value;
    setTableData(localData);
  };

  const tableDataDeleteHandler = (row) => {
    const localData = [...tableData];
    localData.splice(
      localData.findIndex((a) => a.id === row.id),
      1,
    );
    setTableData(localData);
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'stockCode',
        headerName: t('calculator:stockCode'),
        minWidth: 180,
        disableColumnMenu: true,
      },
      {
        field: 'itemName',
        headerName: t('calculator:itemName'),
        minWidth: 800,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'piece',
        headerName: t('calculator:piece'),
        minWidth: 164,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => (
          <InlineEditNumberInput
            value={row.piece}
            onChange={(value) => inputChange(value, row, 'piece')}
          />
        ),
      },
      {
        field: 'numberOfDaysToStore',
        headerName: t('calculator:numberOfDaysToStore'),
        minWidth: 164,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => (
          <InlineEditNumberInput
            value={row.numberOfDaysToStore}
            onChange={(value) => inputChange(value, row, 'numberOfDaysToStore')}
          />
        ),
      },
      {
        field: '',
        width: 100,
        sortable: false,
        align: 'center',
        headerAlign: 'center',

        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <DeleteOutlineOutlinedIcon
            sx={{ color: redColors.red500, cursor: 'pointer' }}
            onClick={() => tableDataDeleteHandler(row)}
          />
        ),
      },
    ];
  }, [t, tableDataDeleteHandler, inputChange]);

  return (
    <PClientTable
      headerRightSide={
        <PButton
          disabled={tableData.length <= 0}
          width="auto"
          onClick={calculate}>
          {t('calculator:calculate')}
        </PButton>
      }
      dataLength={`${tableData.length} ${t('calculator:piece')}`}
      headerTitle={t('calculator:productsAddedToTheCalculation')}
      columns={columns}
      minHeight="100"
      rows={tableData}
    />
  );
};
export default CalculatorTable;
