export const ResetPasswordEn = {
  resetYourPassword: 'Reset Your Password',
  youCanResetYourPassword: 'You can reset your password by entering the fields below and clicking on the link sent to your e-mail address.',
  customerCode: 'Customer code',
  email: 'E-mail address',
  changePassword: 'Change Password',
  pleaseEnterYourCustomerCode: 'Please enter your customer code.',
  pleaseEnterYourEmailAddress: 'Please enter your e-mail address.',
  newPassword: 'New password',
  repeatNewPassword: 'Repeat new password',
  theNewPasswordDoesntMatchTheOldPassword: 'The new password does not match the old password.',
  enterNewPasswordDescription: 'Enter your new password below. You must change your password every 90 days.',
  thePasswordsYouEnteredDonotMatchEachOther: 'The passwords you entered do not match each other.',
  theEmailFormatIsInvalid: 'E-mail format is invalid.',
};
