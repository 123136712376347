import { useTheme } from '@emotion/react';
import { Box, styled, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import IconResolver from 'components/iconResolver/IconResolver';
import PLazyLoadingTable from 'components/table/PLazyLoadingTable';
import dayjs from 'dayjs';
import { PAY_REPORT_GET_URL } from 'pages/pay/api/PayApi';
import ReportTableMenu from 'pages/pay/tabs/components/ReportTableMenu';
import AddReportModal from 'pages/pay/tabs/reportInPay/components/AddReportModal';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReportContainer = styled(Box)(() => ({
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
    '&>*': {
      width: '100% !important',
    },
  },
}));

const PaySubscriptionReport = ({ tabValue }) => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const [openAddModal, setOpenAddModal] = useState(false);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const columns = useMemo(() => {
    return [
      {
        field: 'requestDate',
        headerName: t('pay:date'),
        minWidth: 170,
        sortable: true,
        renderCell: (rows) => {
          return dayjs(rows.requestDate).format('DD.MM.YYYY HH:mm:ss');
        },
      },
      {
        field: 'requestMailAddress',
        headerName: t('pay:requestMailAddress'),
        minWidth: 190,
        sortable: true,
      },
      {
        field: 'endCustomerCsnNo',
        headerName: t('pay:endCustomerCsnNo'),
        width: 180,
        sortable: true,
      },
      {
        field: 'resellerCsnNo',
        headerName: t('pay:resellerCsnNo'),
        width: 150,
        sortable: true,
      },
      {
        field: 'endCustomerName',
        headerName: t('pay:endCustomerName'),
        minWidth: 250,
        sortable: true,
      },
      {
        field: 'getInsightStatus',
        headerName: t('pay:getInsightStatus'),
        minWidth: 250,
        sortable: true,
        renderCell: (rows) => {
          if (rows.getInsightStatus === 'Completed') {
            return t('pay:completed');
          }
          return rows.getInsightStatus === 'Not Selected' ? t('pay:notSelect') : t('pay:selectedWaitingAutodesk');
        },
      },
      {
        field: 'getUsageStatus',
        headerName: t('pay:getUsageStatus'),
        minWidth: 250,
        sortable: true,
        renderCell: (rows) => {
          if (rows.getUsageStatus === 'Completed') {
            return t('pay:completed');
          }
          return rows.getUsageStatus === 'Not Selected' ? t('pay:notSelect') : t('pay:selectedWaitingAutodesk');
        },
      },
      {
        field: 'actions',
        headerAlign: 'right',
        align: 'right',
        headerName: '',
        width: '72px',
        position: '0px',
        renderCell: (rows) => {
          return rows.getInsightStatus === 'Completed' || rows.getUsageStatus === 'Completed' ? (
            <ReportTableMenu
              row={rows}
              refreshFunction={refreshFunction}
            />
          ) : (
            <></>
          );
        },
      },
    ];
  }, [t]);

  const refreshFunction = () => {
    tableRef?.current?.refreshTable();
  };

  const addNewReport = () => {
    setOpenAddModal(true);
  };

  const closeModal = () => {
    setOpenAddModal(false);
  };

  return (
    <ReportContainer>
      <PLazyLoadingTable
        ref={tableRef}
        headerTitle={t('pay:reportView')}
        infoLabel={t('pay:report')}
        columns={columns}
        url={tabValue === 2 && PAY_REPORT_GET_URL}
        searchKey="SearchKey"
        emptyMessage={t('pay:noRecordFound')}
        extraFields={
          <ButtonContainer>
            <PButton
              width="auto"
              inputtype="outline"
              startIcon={matchesMd ? '' : <IconResolver iconName="Refresh" />}
              onClick={refreshFunction}>
              {t('pay:refresh')}
            </PButton>
            <PButton
              width="auto"
              startIcon={matchesMd ? '' : <IconResolver iconName="Add" />}
              onClick={addNewReport}>
              {t('pay:addNewReport')}
            </PButton>
          </ButtonContainer>
        }
      />
      {openAddModal && (
        <AddReportModal
          refreshFunction={refreshFunction}
          closeModal={closeModal}
        />
      )}
    </ReportContainer>
  );
};

export default PaySubscriptionReport;
