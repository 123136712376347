import { paths } from 'constants/Paths';
import ChildLayout from 'layout/childLayout/ChildLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import PayContainer from 'pages/pay/container/PayContainer';
import PayGetAnOffer from 'pages/pay/pages/getAnOffer/PayGetAnOffer';
import PayGetAnOfferResult from 'pages/pay/pages/getAnOffer/PayGetAnOfferResult';
import PayIndex from 'pages/pay/pages/index/PayIndex';
import OrderFailResult from 'pages/pay/pages/orderResult/OrderFailResult';
import OrderSuccessResult from 'pages/pay/pages/orderResult/OrderSuccessResult';
import PaymentFailResult from 'pages/pay/pages/paymentResult/PaymentFailResult';
import PaymentSuccessResult from 'pages/pay/pages/paymentResult/PaymentSuccessResult';

export const payRoutes = [
  {
    path: `${paths.subscriptions}${paths.byProducts}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PayContainer />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.subscriptions}${paths.index}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PayIndex />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.subscriptions}${paths.orderSuccess}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <OrderSuccessResult />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.subscriptions}${paths.orderFail}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <OrderFailResult />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.subscriptions}${paths.paymentSuccess}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PaymentSuccessResult />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.subscriptions}${paths.paymentFail}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PaymentFailResult />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.subscriptions}${paths.getAnOffer}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PayGetAnOffer />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: `${paths.subscriptions}${paths.getAnofferResult}`,
    element: (
      <ProtectedRoute>
        <ChildLayout>
          <PayGetAnOfferResult />
        </ChildLayout>
      </ProtectedRoute>
    ),
  },
];
