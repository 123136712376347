/* eslint-disable max-len */
export const ConfigMasterEn = {
  pentaConfigMaster: 'Penta Config Master',
  configureAndOrderTheHardwareYouWant: 'Configure and order the hardware you want',
  configMaster: 'Config Master',
  configMasterCart: 'Config Master Cart',
  pcBuild: 'PC Build',
  meetingTableSet: 'Meeting Table Set',
  saveSelectionsAsVariants: 'Save Selections as Variant',
  clear: 'Clear',
  selectTemplate: 'Select template',
  justShowInStock: 'Just show in stock',
  component: 'component',
  selectionSummary: 'Selection Summary',
  piece: 'Piece',
  configuration: 'Configuration',
  subtotal: 'Subtotal',
  selectFilteringType: 'Select filtering type',
  variant: 'Variant',
  stock: 'Stock',
  selection: 'Selection',
  pleaseChoose: 'Please choose',
  addToYourSelection: 'Add to your selection',
  searchBrand: 'search {{brand}}...',
  select: 'Select',
  selected: 'Selected',
  remove: 'Remove',
  insufficientStock: 'Insufficient Stock',
  productDetail: 'Product Detail',
  productPage: 'Product Page',
  productNumber: 'Product Number',
  noRecordFound: 'No Record Found',
  currencySelection: 'Currency Selection',
  basketDetail: 'Basket Detail',
  updateBasket: 'Update Basket',
  product: 'Product',
  selectVariant: 'Select variant',
  variantName: 'Variant name',
  save: 'Save',
  cancel: 'Cancel',
  yes: 'Yes',
  selectConfiguration: 'Select configuration',
  grandTotal: 'Grand Total',
  including: 'Including',
  loading: 'Loading ...',
  addToCart: 'Add to Cart',
  insufficientStockError: 'Insufficient stock. Please review.',
  mandatoryFieldsError: 'There are mandatory fields that have not been selected. Please check.',
  orderDetails: 'Order Details',
  orderCreatedSuccessfully: 'Order created successfully!',
  paymentTransactionCompletedSuccessfully: 'Payment transaction completed successfully. You can review your payment information.',
  paymentNo: 'Payment No',
  erpPaymentNo: 'ERP Payment No',
  dateAndTime: 'Date and Time',
  customer: 'Customer',
  user: 'User',
  amount: 'Amount',
  explanation: 'Explanation',
  printPayment: 'Print Payment',
  couldNotCreateOrder: 'Could not create order',
  paymentTransactionCompletedUnsuccessfully: 'Payment could not be made. You can review your payment information.',
  youCanViewOrderDetailsInTheTableBelow: 'You can view order details in the table below.',
  orderNo: 'Order No',
  shipmentType: 'Shipment Type',
  serviceFee: 'Service Fee',
  orderItems: 'Order Items',
  itemNumber: 'Item No',
  productCode: 'Product Code',
  productName: 'Product Name',
  unitPrice: 'Unit Price',
  taxIncludedPrice: 'KDV Included Total',
  stockCode: 'Stock Code',
  maturity: 'Maturity',
  day: 'Day',
  orderSummary: 'Order Summary',
  taxIncluded: 'KDV Included',
  totalTax: 'Total KDV',
  order: 'Order',
  thereAreNoProductsInYourCart: 'There are no products in your cart',
  someOfProductsNotInStock: 'Some of the products you selected are not in stock.',
  removeSelectedRow: 'Are you sure to remove ?',
  welcomeMessage: 'Welcome to Penta Config Master!',
  introductionFirstParagraph: 'It is a platform that enables custom PC assembly through a configurator available on Bayinet.',
  introductionSecondParagraph:
    'With this application, we aim to provide our resellers with a service of pre-assembled and tested PCs tailored to their specific requirements.',
  firstTitle: 'Who can benefit from Penta Config Master?',
  firstTitleFirstParagraph: 'All of our resellers can benefit from this service.',
  secondTitle: 'How to create a configurator with Penta Config Master',
  secondTitleFirstDot:
    'You can prepare a configuration with compatible components according to your desired specifications and place an order instantly.',
  secondTitleSecondDot: 'To avoid errors when adding components to the cart, only in-stock items should be selected.',
  secondTitleThirdDot: 'When you save your selections as a variant, you can use these variants later as pre-configured options.',
  secondTitleFourthDot: 'Afterwards, components with the desired features should be selected in Config Master.',
  secondTitleFifthDot: 'After selecting the components, add the products to the cart using the "Add to Cart" button.',
  secondTitleSixthDot:
    'Once the properly assembled PC is added to the cart and the purchase is completed, the warehouse and production process will automatically begin.',
  secondTitleSeventhDot: 'The products are assembled, tested, and then packaged.',
  secondTitleEighthDot: 'The packaged products are then shipped to the customer.',
  specialOffer: 'Special Offer',
  noComponentsFound: 'No components found.',
  shipmentAndInformation: 'Shipment and Information',
  chooseShippingOptionsAndNotificationMethods: 'Choose shipping options and notification methods',
  createOrder: 'Create order',
  sendToPentaStorage: 'Send to Penta Storage',
  storage: 'Storage',
  selectStorage: 'Select storage',
  selectShipmentType: 'Select shipment type',
  orderDescription: 'Order Description',
  enterDescription: 'Enter description',
  invoiceDescription: 'Invoice Description',
  enterInvoiceDescription: 'Enter invoice description',
  trackingNumber: 'Tracking Number',
  enterTrackingNumber: 'Enter tracking number',
  checkboxInfo: 'I want to be informed via SMS after the order. (At product exit and delivery.)',
  availableCreditLimit: 'Available credit limit',
  eArchive: 'E-Archive',
  invoicesWillBeSentToYourDefinedEMailAddresses: 'invoices will be sent to your defined e-mail addresses.',
  paymentOptions: 'Payment Options',
  setPaymentInformationAndMethods: 'Set payment information and methods',
  openAccount: 'Open account',
  bankTransfer: 'Bank transfer',
  onlinePayment: 'Online Payment',
  pleaseSelectPaymentType: 'Please select payment type.',
  thisFieldIsRequired: 'Please enter {{field}}.',
  deliveryAddress: 'DeliveryAddress',
  relatedPerson: 'Related Person',
  selectRelatedPerson: 'Select related person',
  selectDeliveryAddress: 'Select delivery address',
  shipmentDate: 'Shipment Date',
  selectShipmentDate: 'Select shipment date',
  theNumberOfItemInThePackageChanged: 'The number of {{item}} in the package has changed, please check.',
};
