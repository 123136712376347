import { paths } from 'constants/Paths';
import MainLayout from 'layout/mainLayout/MainLayout';
import ProtectedRoute from 'layout/protectedRoute/ProtectedRoute';
import UserManagement from '../UserManagement';

export const userManagementRoutes = {
  path: `${paths.userManagement}${paths.userManagement}`,
  element: (
    <ProtectedRoute path={`${paths.userManagement}${paths.userManagement}`}>
      <MainLayout>
        <UserManagement />
      </MainLayout>
    </ProtectedRoute>
  ),
};
