import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Typography, styled } from '@mui/material';
import { primaryColors, redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px 24px',
  rowGap: '20px',
}));

const WithoutCardInfoFail = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <SecondaryLayout
      path={`${paths.wizard}${paths.fbw}`}
      title={t('configMaster:orderDetails')}>
      <ResponseContainer>
        <div className="flex-column d-flex">
          <CancelIcon
            sx={{ color: redColors.red600 }}
            fontSize="large"
          />
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {`${t('configMaster:couldNotCreateOrder')}!`}
          </Typography>
          {location?.state?.errorMessages?.map((item) => {
            return (
              <Typography
                key={item.message}
                variant="bodyMedium"
                color={primaryColors.primary}>
                {item.message}
              </Typography>
            );
          })}
        </div>
      </ResponseContainer>
    </SecondaryLayout>
  );
};

export default WithoutCardInfoFail;
