import { useTheme } from '@emotion/react';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import PDivider from 'components/Divider/PDivider';
import PSelectWithIcon from 'components/select/PSelectWithIcon';
import TagTextField from 'components/tagTextField/TagTextField';
import PTextField from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { removeGivenNumberFromArray } from 'utils/HelperFunctions';
import SaveTemplateModal from './SaveTemplateModal';

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
}));

const AddPermission = ({ setTab, permissions, userInfo, setUserInfo, templateData, selectValue, setSelectValue, loadTemplates }) => {
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const backToUser = () => {
    setTab(true);
  };

  const onCheckboxChange = (event, permission) => {
    if (event.target.checked) {
      const localPermission = userInfo.permissionTypes;
      localPermission.push(permission);
      setUserInfo((prevState) => ({ ...prevState, permissionTypes: localPermission }));
    } else {
      const localPermission = userInfo.permissionTypes;
      const removedArray = removeGivenNumberFromArray(localPermission, permission);
      setUserInfo((prevState) => ({ ...prevState, permissionTypes: removedArray }));
    }
  };

  const onSelectChange = (event) => {
    const { value } = event.target;
    setSelectValue(event.target.value);
    const selectedTemplate = templateData.find((template) => template.key === value);
    setUserInfo((prevState) => ({ ...prevState, permissionTypes: [...selectedTemplate.permissions] }));
  };

  return (
    <SecondaryLayout
      onClick={backToUser}
      title={t('addUser:managePermissions')}>
      <Header>
        <Typography variant="titleMedium">{t('addUser:permissions')}</Typography>
        <Typography
          variant="bodyMedium"
          color={primaryColors.primary700}>
          {t('addUser:manageUserPermissionsInfo')}
        </Typography>
      </Header>
      <PDivider marginBottom="2px" />
      <Grid
        container
        spacing={4}
        rowSpacing={1}
        sx={{ paddingRight: '16px', paddingBottom: 2, backgroundColor: '#ffffff', borderRadius: '15px', margin: 0, width: '100%' }}>
        <Grid
          item
          xs={12}
          md={6}>
          <PSelectWithIcon
            placeholder={t('addUser:selectTemplate')}
            label={t('addUser:selectTemplate')}
            labelId="select-label"
            id="select"
            onChange={onSelectChange}
            value={selectValue}
            menuItems={templateData}
            showHelperContainer={false}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className=" d-flex align-items-end justify-flex-end">
          <PButton
            inputtype="outline"
            startIcon={<SaveOutlinedIcon />}
            width={matchesSm ? '100%' : 'auto'}
            onClick={() => {
              setIsModalOpen(true);
            }}>
            {t('addUser:saveAsTemplate')}
          </PButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}>
          <div style={{ marginTop: '22px' }} />
        </Grid>
        {currentUser.user.isAdmin && (
          <Grid
            item
            xs={12}
            md={12}>
            <Typography variant="titleMedium">{t('addUser:priceDefinitions')}</Typography>
          </Grid>
        )}

        {currentUser.user.isAdmin && (
          <Grid
            item
            xs={12}
            lg={12}
            md={12}>
            <PTextField
              label={`${t('addUser:profitability')} (${t('addUser:optional')})`}
              helpertext={t('addUser:valueCanBeEnteredInFormat', { number: '5.00' })}
              value={userInfo.profit}
              onChange={(event) => setUserInfo((prevState) => ({ ...prevState, profit: event.target.value }))}
              type="number"
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={12}>
          <Typography variant="titleMedium">{t('addUser:userPermissions')}</Typography>
        </Grid>
        {permissions.map((permission) => {
          return (
            <Grid
              sx={{ paddingLeft: '28px !important' }}
              item
              md={4}
              key={permission}>
              <PCheckbox
                disabled={userInfo.isAdmin}
                label={t(`addUser:${permission}`)}
                id={permission}
                checked={userInfo.permissionTypes.includes(permission)}
                onChange={(event) => onCheckboxChange(event, permission)}
              />
            </Grid>
          );
        })}
        <Grid
          item
          xs={12}
          md={12}>
          <Typography variant="titleMedium">{t('addUser:allowedIpAddresses')}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}>
          <TagTextField
            label={t('addUser:ipAddress')}
            tags={userInfo.restrictedIps}
            onChange={(event) => {
              const localRestrictedIps = event;
              setUserInfo((prevState) => ({ ...prevState, restrictedIps: localRestrictedIps }));
            }}
            placeholder={t('addUser:addIpAddresses')}
            helpertext={t('addUser:pleasePressEnterAfterEachEntry')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="text-end"
          sx={{ marginBottom: '16px' }}>
          <PButton
            width={matchesSm ? '100%' : 'auto'}
            onClick={backToUser}>
            {t('addUser:applyPermissions')}
          </PButton>
        </Grid>
      </Grid>
      {isModalOpen && (
        <SaveTemplateModal
          handleClose={setIsModalOpen}
          userInfo={userInfo}
          loadTemplates={loadTemplates}
        />
      )}
    </SecondaryLayout>
  );
};
export default AddPermission;
