import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEY } from 'constants/Keys';

const initialState = {
  currentUser: null,
};

const createCurrentUserAction = (state, action) => {
  const data = action.payload;

  state.currentUser = { ...data };
  localStorage.setItem(LOCAL_STORAGE_KEY, data.token);
};

const currentUserSlice = createSlice({
  name: 'currentUserSlice',
  initialState,
  reducers: {
    createCurrentUser: createCurrentUserAction,
  },
});

export const { createCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
