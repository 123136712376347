import { Box, styled, Typography } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import PClientTable from 'components/table/PClientTable';
import { greenColors, primaryColors, redColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { addToCartEnums } from 'pages/pay/constants/AddToCartEnums';
import { payTabNumbers } from 'pages/pay/constants/PayTabNumbers';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPrice } from 'utils/HelperFunctions';
import { v4 as uuidv4 } from 'uuid';

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px',
  rowGap: '20px',
}));

const OrderSuccessResult = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const localArray = location.state.productInfos.map((productInfo) => ({
        ...productInfo,
        id: uuidv4(),
      }));

      setData(localArray);
    } else {
      navigate(paths.home);
    }
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'subscriptionId',
        headerName: `${t('pay:process')}/${t('pay:subscription')}`,
        disableColumnMenu: true,
        minWidth: 200,
        renderCell: ({ row }) => {
          let label = '';
          if (row.type === addToCartEnums.addProduct) {
            label = 'Add Product';
          } else if (row.type === addToCartEnums.addSeat) {
            label = 'Add Seat';
          } else if (row.type === addToCartEnums.renewable) {
            label = 'Renewable';
          }
          return <Typography variant="bodyMedium">{`${label}-${Number(row.subscriptionId)}`}</Typography>;
        },
      },
      {
        field: 'productDefinition',
        headerName: t('pay:productName'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) => {
          return <Box>{`${row.productNumber} - ${row.productDefinition}`}</Box>;
        },
      },
      {
        field: 'quantity',
        headerName: t('pay:piece'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 70,
      },
      {
        field: 'unitPrice',
        headerName: t('pay:unitPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 128,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.netAmountAsDocument)} ${row.salesAndDistributionDocumentCurrency}`}</Box>;
        },
      },
      {
        field: 'vat',
        headerName: t('pay:taxIncludedPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 148,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.vatAmount)} ${row.salesAndDistributionDocumentCurrency}`}</Box>;
        },
      },
      {
        field: 'totalAmount',
        headerName: t('pay:transactionResult'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 128,
        renderCell: ({ row }) => {
          return (
            <Typography
              variant="bodyMedium"
              color={row.messageType === 'E' ? redColors.red600 : greenColors.green600}>
              {row.message}
            </Typography>
          );
        },
      },
    ];
  }, [t]);

  const goToCart = () => {
    navigate(`${paths.subscriptions}${paths.byProducts}`, { state: payTabNumbers.subscriptionCart });
  };

  return (
    <SecondaryLayout
      onClick={goToCart}
      title={t('pay:orderDetails')}>
      <ResponseContainer>
        <Box className="flex-column d-flex">
          <IconResolver
            iconName="CheckCircle"
            sx={{ color: greenColors.green500, width: '48px', height: '48px' }}
          />
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {t('pay:orderCreatedSuccessfully')}
          </Typography>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {t('pay:youCanViewOrderDetailsInTheTableBelow')}
          </Typography>
        </Box>
        <PClientTable
          dataLength={`${data.length} ${t('pay:piece')}`}
          headerTitle={t('pay:orderItems')}
          columns={columns}
          rows={data}
          getRowHeight={() => 'auto'}
        />
      </ResponseContainer>
    </SecondaryLayout>
  );
};
export default OrderSuccessResult;
