export const MonthTr = {
  january: 'Ocak',
  february: 'Şubat',
  march: 'Mart',
  april: 'Nisan',
  may: 'Mayıs',
  june: 'Haziran',
  july: 'Temmuz',
  august: 'Ağustos',
  september: 'Eylül',
  october: 'Ekim',
  november: 'Kasım',
  december: 'Aralık',
};
