export const integrationStatusEnum = Object.freeze({
  requestCreated: '1', //Talep Oluşturuldu mavi info
  requestWaitingForApprove: '2', // Onayda
  requestApproved: '3', //Talep Onaylandı yeşil info + Testi tamamla butonu
  customerApprovedTest: '4', //Müşteri Testi Onayladı,
  pentaApprovalProcessTest: '5', //Penta Onay Sürceinde Test mavi info
  testAppvored: '6', //Test Onaylandı yeşil info + canlıya geç
  activeRequestCreated: '7', //Canlı Talep Oluşturuldu mavi info
  pentaApprovalProcessActive: '8', //Penta Onay Sürceinde Canlı
  activeRequestApproved: '9', //Canlı talep onaylandı yesil
  processCompleted: '10', //Süreç Tamamlandı
  rejected: '11', //Reddedildi
  cancel: '12',
});
