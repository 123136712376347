import { useTheme } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';
import PButton from 'components/button/PButton';
import PCheckbox from 'components/checkbox/PCheckbox';
import PCenterModal from 'components/modal/PCenterModal';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PClientTable from 'components/table/PClientTable';
import { createReport, getAutodesks } from 'pages/pay/api/PayApi';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isObjEmpty } from 'utils/HelperFunctions';

const AddReportModal = ({ refreshFunction, closeModal }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ allData: [], visibleData: [] });
  const [selectedUsageRows, setSelectedUsageRows] = useState({});
  const [selectedInsightRows, setSelectedInsightRows] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  const addReport = () => {
    const addFormData = formData.allData
      .filter((row) => selectedUsageRows[row.id] || selectedInsightRows[row.id])
      .map((row) => {
        const updatedUsage = selectedUsageRows[row.id] ? true : row.getUsage;
        const updatedInsight = selectedInsightRows[row.id] ? true : row.getInsight;

        return {
          ...row,
          getUsage: updatedUsage,
          getInsight: updatedInsight,
        };
      });

    setIsLoading(true);
    createReport({ reports: addFormData })
      .then(() => {
        toast.success(t('pay:newReportSuccessMessage'));
        refreshFunction();
        closeModal();
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getAutodesks()
      .then((res) => {
        setFormData({ visibleData: res.data.data, allData: res.data.data });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'resellerCSN',
        headerName: t('pay:resellerCSN'),
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'endCustomerCSN',
        headerName: t('pay:endCustomerCsnNo'),
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'endCustomerName',
        headerName: t('pay:endCustomerName'),
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'getInsight',
        headerName: t('pay:insightMetrics'),
        minWidth: 100,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          return (
            <PCheckbox
              checked={!!selectedInsightRows[row.id]}
              noWhiteSpace
              sx={{ whiteSpace: 'nowrap' }}
              onChange={(event) => handleGetInsightCheckboxChange(event, row.id)}
            />
          );
        },
      },
      {
        field: 'getUsage',
        headerName: t('pay:usageReports'),
        minWidth: 200,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          return (
            <PCheckbox
              checked={!!selectedUsageRows[row.id]}
              noWhiteSpace
              sx={{ whiteSpace: 'nowrap' }}
              onChange={(event) => handleGetUsageCheckboxChange(event, row.id)}
            />
          );
        },
      },
    ];
  }, [selectedUsageRows, selectedInsightRows]);

  const handleGetUsageCheckboxChange = (event, rowId) => {
    setSelectedUsageRows((prevState) => ({
      ...prevState,
      [rowId]: event.target.checked,
    }));
  };

  const handleGetInsightCheckboxChange = (event, rowId) => {
    setSelectedInsightRows((prevState) => ({
      ...prevState,
      [rowId]: event.target.checked,
    }));
  };

  const isButtonDisable = useMemo(() => {
    if (isObjEmpty(selectedUsageRows) && isObjEmpty(selectedInsightRows)) {
      return true;
    }
    if (Object.values(selectedUsageRows).every((v) => v === false) && Object.values(selectedInsightRows).every((v) => v === false)) {
      return true;
    }
    return false;
  }, [selectedUsageRows, selectedInsightRows]);

  const onSearchHandler = (event) => {
    const value = event.target.value;

    setSearchValue(value);
    setFormData((prevState) => ({
      ...prevState,
      visibleData: prevState.allData.filter((e) =>
        Object.values({ resellerCSN: e.resellerCSN, endCustomerCSN: e.endCustomerCSN, endCustomerName: e.endCustomerName })
          .join('')
          .toLocaleLowerCase('tr')
          .includes(value.toLocaleLowerCase('tr')),
      ),
    }));
  };

  const getDynamicWidth = () => {
    if (matchesSm) {
      return '300px';
    } else if (matchesMd) {
      return '500px';
    } else {
      return 'auto';
    }
  };

  return (
    <PCenterModal
      width="1000px"
      showClose
      onClose={closeModal}
      title={t('pay:createUserReport')}>
      <Box className="d-flex flex-column gap-20">
        <Box sx={{ width: getDynamicWidth() }}>
          <PClientTable
            maxHeight="300"
            headerRightSide={
              <Box>
                <TableSearchTextField
                  value={searchValue}
                  onChange={onSearchHandler}
                  placeholder={t('pay:searchPlaceholder')}
                />
              </Box>
            }
            dataLength={`${formData.allData.length} ${t('pay:piece')}`}
            headerTitle={t('pay:endUserList')}
            rows={formData.visibleData}
            columns={columns}
            loading={isLoading}
            getRowHeight={() => 'auto'}
          />
        </Box>
        <Box className="d-flex justify-flex-end">
          <PButton
            disabled={isButtonDisable}
            width="auto"
            onClick={addReport}>
            {t('pay:create')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default AddReportModal;
