import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography, styled } from '@mui/material';
import PDivider from 'components/Divider/PDivider';
import PClientTable from 'components/table/PClientTable';
import UnitInput from 'components/unitInput/UnitInput';
import { greenColors, primaryColors } from 'config/theme/Colors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px 24px',
  rowGap: '20px',
  gridColumnStart: 3,
  gridColumnEnd: 11,
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '24px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SuccessPage = ({ data }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: 'deliveryNumber',
        headerName: t('deliveryDetails:deliveryNumber'),
        minWidth: 112,
        flex: 1,
        disableColumnMenu: true,
        renderCell: () => Number(data.deliveryNo),
      },
      {
        field: 'orderNo',
        headerName: t('deliveryDetails:orderCode'),
        minWidth: 112,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'entryNumber',
        headerName: t('deliveryDetails:entryNo'),
        minWidth: 90,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'stockCode',
        headerName: t('deliveryDetails:stockCode'),
        minWidth: 90,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'itemName',
        headerName: t('deliveryDetails:itemName'),
        minWidth: 116,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'quantity',
        headerName: t('deliveryDetails:amount'),
        minWidth: 90,
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'description',
        headerName: t('deliveryDetails:description'),
        minWidth: 116,
        flex: 1,
        disableColumnMenu: true,
        renderCell: () => t('deliveryDetails:deliveryCreatedSuccessfully'),
      },
    ];
  }, [t]);

  return (
    <GridContainer>
      <ResponseContainer>
        <Box className="flex-column d-flex">
          <CheckCircleIcon
            sx={{ color: greenColors.green500 }}
            fontSize="large"
          />
          <Typography
            variant="titleLarge"
            color={primaryColors.primary}>
            {t('deliveryDetails:deliveryCreatedSuccessfully')}
          </Typography>
          <Typography
            variant="bodyMedium"
            color={primaryColors.primary}>
            {t('deliveryDetails:youCanViewTheDeliveryDetailsInTheTableBelow')}
          </Typography>
        </Box>
        <Box className="flex-column d-flex">
          <Box
            className="d-flex justify-content-between gap-4"
            style={{ marginBottom: '15px' }}>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary}>
              {t('deliveryDetails:deliveryNumber')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {Number(data.deliveryNo)}
            </Typography>
          </Box>
          <PDivider />
          <Box
            className="d-flex justify-content-between gap-4"
            style={{ marginBottom: '15px' }}>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary}>
              {t('deliveryDetails:storage')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {data.storagePlace}
            </Typography>
          </Box>
          <PDivider />
          <Box
            className="d-flex justify-content-between gap-4"
            style={{ marginBottom: '15px' }}>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary}>
              {t('deliveryDetails:shipmentType')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {(() => {
                switch (data.deliveryType) {
                  case 'M2':
                    return 'Depodan Teslim';
                  case 'M1':
                    return 'Kargo';
                  case 'M4':
                    return 'Penta Sevkiyat';
                  default:
                    return 'Test';
                }
              })()}
            </Typography>
          </Box>
          <PDivider />
          <Box
            className="d-flex justify-content-between gap-4"
            style={{ marginBottom: '15px' }}>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary}>
              {t('deliveryDetails:address')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {data.address}
            </Typography>
          </Box>
          <PDivider />
          <Box
            className="d-flex justify-content-between gap-4"
            style={{ marginBottom: '15px' }}>
            <Typography
              variant="titleSmall"
              color={primaryColors.primary}>
              {t('deliveryDetails:cargo')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              <UnitInput
                value={data.cargo}
                unit="TRY"
              />
            </Typography>
          </Box>
        </Box>
        <Box>
          <PClientTable
            headerTitle={t('deliveryDetails:deliveryItems')}
            dataLength={`${data.deliveryEntries.length} ${t('deliveryDetails:piece')}`}
            getRowHeight={() => 'auto'}
            rows={data.deliveryEntries}
            columns={columns}
          />
        </Box>
      </ResponseContainer>
    </GridContainer>
  );
};

export default SuccessPage;
