import { Typography } from '@mui/material';
import PClientTable from 'components/table/PClientTable';
import UnitInput from 'components/unitInput/UnitInput';
import { primaryColors } from 'config/theme/Colors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { splitWithDot } from 'utils/HelperFunctions';

const StorageCostTab = ({ tableData }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: 'productCode',
        headerName: t('storageCost:productNo'),
        minWidth: 128,
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => Number(row.productCode),
      },
      {
        field: 'productDescription',
        headerName: t('storageCost:productName'),
        minWidth: 470,
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'totalCount',
        headerName: t('storageCost:piece'),
        minWidth: 96,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderCell: ({ value }) => (
          <UnitInput
            value={value}
            precision={0}
          />
        ),
      },
      {
        field: 'totalDesi',
        headerName: t('storageCost:desi'),
        minWidth: 96,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderCell: ({ value }) => splitWithDot(Math.round(value), 0),
      },
      {
        field: 'differentProductAmount',
        headerName: t('storageCost:differentProductCost'),
        minWidth: 152,
        flex: 1,
        type: 'number',
        headerClassName: 'text-end',
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <UnitInput
            value={row.differentProductAmount}
            unit={row.currency}
          />
        ),
      },
      {
        field: 'totalDesiCost',
        headerName: t('storageCost:desiCost'),
        minWidth: 152,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <UnitInput
            value={Math.round(row.totalDesiCost * 100) / 100}
            unit={row.currency}
          />
        ),
      },
    ];
  }, [t]);

  return (
    <>
      {tableData.length > 0 && (
        <PClientTable
          headerTitle={t('storageCost:storageCosts')}
          columns={columns}
          rows={tableData}
          getRowHeight={() => 'auto'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'totalCount', sort: 'desc' }],
            },
          }}
        />
      )}
      {tableData?.length === 0 && (
        <Typography
          color={primaryColors.primary400}
          variant="titleMedium">
          {t('storageCost:noWarehouseCost')}
        </Typography>
      )}
    </>
  );
};

export default StorageCostTab;
