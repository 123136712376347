import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import PButton from 'components/button/PButton';
import PIconButton from 'components/button/PIconButton';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import TagTextField from 'components/tagTextField/TagTextField';
import PTextField from 'components/textField/PTextField';
import { addRequest, getConfigurations } from 'pages/pentaIntegration/api/IntegrationApi';
import { integrationServiceEnum } from 'pages/pentaIntegration/constants/IntegrationServiceEnum';
import { integrationValidationMessagesKeys } from 'pages/pentaIntegration/constants/IntegrationValidationMessagesKeys';
import { ENTITY_NOT_FOUND } from 'pages/pentaIntegration/constants/StringConstants';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';

const EntegrationTestRequestModal = ({ onClose, serviceId, getActiveRequest, refreshFunction }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    id: null,
    restrictedIps: [],
    description: '',
    requiredEmail: '',
    sapCustomerName: '',
    testAppKey: '',
    sapCustomerId: '',
    liveAppKey: '',
    liveIpList: [],
  });
  const [errors, setErrors] = useState({
    restrictedIps: '',
    requiredEmail: '',
  });
  const [dynamicEmailInput, setDynamicEmailInput] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getConfigurations()
      .then((res) => {
        const { id, testAppKey, liveAppKey, ipList, mailList } = res.data.data;
        const testIpList = [];
        const liveIpList = [];

        ipList.forEach((ip) => {
          if (ip.isLive) {
            liveIpList.push(ip.ip);
          } else {
            testIpList.push(ip.ip);
          }
        });
        let requiredMail = '';
        const dynamicMail = [];
        mailList.forEach((mail, index) => {
          if (index === 0) {
            requiredMail = mail;
          } else {
            dynamicMail.push({ email: mail });
          }
        });

        setFormData((prevState) => ({
          ...prevState,
          id,
          testAppKey,
          liveAppKey,
          restrictedIps: testIpList,
          liveIpList,
          requiredEmail: requiredMail,
        }));
        setDynamicEmailInput(dynamicMail);
      })
      .catch((err) => {
        if (ENTITY_NOT_FOUND !== err?.response?.data?.Message) {
          toast.error(err?.response?.data?.Message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const handleAddEmailInput = () => {
    if (dynamicEmailInput.length < 4) {
      setDynamicEmailInput([...dynamicEmailInput, { email: '' }]);
    }
  };

  const handleDynamicEmailDelete = (index) => {
    const deleteValue = [...dynamicEmailInput];
    deleteValue.splice(index, 1);
    setDynamicEmailInput(deleteValue);
  };

  const handleDynamicEmailChange = (event, index) => {
    const { value } = event.target;
    const onChangeValue = [...dynamicEmailInput];
    onChangeValue[index]['email'] = value;
    setDynamicEmailInput(onChangeValue);
    setErrors((prevState) => ({ ...prevState, [`requiredEmail${index}`]: '' }));
  };

  const validate = () => {
    const rules = {
      requiredEmail: 'email|required',
      restrictedIps: 'required',
    };

    const dynamicObject = {};
    if (dynamicEmailInput.length > 0) {
      dynamicEmailInput.forEach((item, index) => {
        rules[`requiredEmail${index}`] = 'email|required';
        dynamicObject[`requiredEmail${index}`] = item.email;
      });
    }

    const validation = new Validator({ ...formData, ...dynamicObject }, rules, integrationValidationMessagesKeys);
    if (validation.passes()) {
      return true;
    } else {
      const keyOfRules = Object.keys(rules);
      const localErrors = { ...errors };
      keyOfRules.forEach((item) => {
        localErrors[item] = validation.errors.first(item);
      });
      setErrors(localErrors);
    }
  };

  const handleCreate = () => {
    if (validate()) {
      if (!formData.id) {
        const mailList = [];
        mailList.push(formData.requiredEmail);
        dynamicEmailInput.forEach((dynamicEmail) => {
          mailList.push(dynamicEmail.email);
        });
        const ipList = formData.restrictedIps.map((restrictedIp) => {
          return {
            ip: restrictedIp,
            isLive: false,
          };
        });
        const sendData = {
          description: formData.description,
          serviceId: serviceId,
          configuration: {
            ipList,
            mailList,
          },
        };
        setLoading(true);
        addRequest(sendData)
          .then((res) => {
            toast.success(res.data.message);
            getActiveRequest();
            refreshFunction();
            onClose();
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const createIpList = (ips, isLive) => ips.map((ip) => ({ ip, isLive }));
        const collectEmails = (primaryEmail, additionalEmails) => [primaryEmail, ...additionalEmails.map(({ email }) => email)];

        const mailList = collectEmails(formData.requiredEmail, dynamicEmailInput);
        const ipList = [...createIpList(formData.liveIpList, true), ...createIpList(formData.restrictedIps, false)];
        const { testAppKey, liveAppKey } = formData;
        const sendData = {
          description: formData.description,
          serviceId: serviceId,
          configuration: {
            ipList,
            mailList,
          },
          testAppKey,
          liveAppKey,
        };
        addRequest(sendData)
          .then((res) => {
            toast.success(res.data.message);
            getActiveRequest();
            refreshFunction();
            onClose();
          })
          .catch((err) => {
            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const modalTitle = useMemo(() => {
    if (serviceId === integrationServiceEnum.productService) {
      return 'integration:createProductServiceIntegrationTestRequest';
    }
    if (serviceId === integrationServiceEnum.orderService) {
      return 'integration:createOrderServiceIntegrationTestRequest';
    }
    if (serviceId === integrationServiceEnum.payService) {
      return 'integration:createPayServiceIntegrationTestRequest';
    }
    if (serviceId === integrationServiceEnum.storageService) {
      return 'integration:createStorageServiceIntegrationTestRequest';
    }
    return null;
  }, [serviceId]);

  return (
    <PCenterModal
      showClose
      width="910px"
      onClose={onClose}
      title={t(modalTitle)}>
      {loading && <LoadingSpinner />}
      <Box className="d-flex flex-column gap-12">
        <Box>
          <TagTextField
            maxLenght={5}
            label={t('integration:testIpAddressess')}
            tags={formData.restrictedIps}
            onChange={(event) => {
              const localRestrictedIps = event;
              setFormData((prevState) => ({ ...prevState, restrictedIps: localRestrictedIps }));
              setErrors((prevState) => ({ ...prevState, restrictedIps: '' }));
            }}
            placeholder={t('addUser:addIpAddresses')}
            helpertext={t('addUser:pleasePressEnterAfterEachEntry')}
            error={!!errors.restrictedIps}
            errorMessage={t(errors.restrictedIps, { field: t('integration:testIpAddressess') })}
          />
        </Box>
        <Box>
          <PTextField
            value={formData.description}
            onChange={handleInputChange}
            placeholder={t('integration:enterDescription')}
            label={t('integration:description')}
            name="description"
          />
        </Box>
        <Box
          className="d-flex gap-20 align-items-center"
          sx={{ width: '80%' }}>
          <PTextField
            value={formData.requiredEmail}
            onChange={handleInputChange}
            label={t('integration:emailAddressess')}
            name="requiredEmail"
            error={!!errors.requiredEmail}
            errorMessage={t(errors.requiredEmail, { field: t('integration:emailAddressess') })}
            placeholder={t('integration:emailPlaceholder')}
          />
          <PIconButton
            inputtype="tonal"
            onClick={handleAddEmailInput}>
            <AddIcon />
          </PIconButton>
        </Box>
        {dynamicEmailInput.map((emailInput, index) => (
          <Box
            className="d-flex gap-20 align-items-center"
            sx={{ width: '80%' }}>
            <PTextField
              label={t('integration:emailAddressess')}
              value={emailInput.email}
              onChange={(event) => handleDynamicEmailChange(event, index)}
              error={!!errors[`requiredEmail${index}`]}
              errorMessage={t(errors[`requiredEmail${index}`], { field: t('integration:emailAddressess') })}
              placeholder={t('integration:emailPlaceholder')}
            />
            <PIconButton
              inputtype="tonal"
              onClick={() => handleDynamicEmailDelete(index)}>
              <IconResolver iconName="DeleteOutline" />
            </PIconButton>
          </Box>
        ))}
        <Box className="d-flex justify-flex-end">
          <PButton
            width="auto"
            onClick={handleCreate}>
            {t('integration:create')}
          </PButton>
        </Box>
      </Box>
    </PCenterModal>
  );
};
export default EntegrationTestRequestModal;
