import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contractAgreement: { ids: [], isOpen: false },
};

const createContractsAction = (state, action) => {
  const data = action.payload;
  if (!state.contractAgreement.isOpen) {
    state.contractAgreement = { ids: data, isOpen: true };
  }
};

const resetContractsAction = (state) => {
  state.contractAgreement = { ids: [], isOpen: false };
};

const contractAgreementSlice = createSlice({
  name: 'currentUserSlice',
  initialState,
  reducers: {
    createContracts: createContractsAction,
    resetContracts: resetContractsAction,
  },
});

export const { createContracts, resetContracts } = contractAgreementSlice.actions;

export default contractAgreementSlice.reducer;
