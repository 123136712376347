import { Tooltip, styled, tooltipClasses } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '16px',
    borderRadius: '8px',
    borderColor: primaryColors.primary300,
    boxShadow: '0px 4px 8px -2px #3232321A',
    backgroundColor: primaryColors.primary50,
    color: primaryColors.primary,
    maxWidth: 300,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    wordBreak: 'break-word',
  },
}));

const PTooltip = ({ title, children, placement = 'top-start' }) => {
  return (
    <CustomTooltip
      enterDelay={1000}
      title={title}
      placement={placement}>
      <span>{children}</span>
    </CustomTooltip>
  );
};

export default PTooltip;
