import { Box, CircularProgress, styled } from '@mui/material';

const LoadingContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2345,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'inherit',
}));

const BoxSpinner = () => {
  return (
    <LoadingContainer>
      <CircularProgress color="inherit" />
    </LoadingContainer>
  );
};

export default BoxSpinner;
