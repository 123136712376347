/* eslint-disable max-len */
export const StorageTr = {
  pentaMyStorage: 'Penta Depom',
  checkYourPentaStorage: 'Penta deponuzu kontrol edin',
  storageCosts: 'Depo Maliyetlerim',
  productsInStorage: 'Depodaki Ürünlerim',
  shipments: 'Sevkiyatlarım',
  storageCart: 'Depo Sepetim',
  storageCostSimulation: 'Depo Maliyet Simülasyonu',
  periodYear: 'Dönem Yıl',
  periodMonth: 'Dönem Ay',
  filterPeriod: 'Dönemi Getir',
  date: 'Tarih',
  desi: 'Desi',
  desiCost: 'Desi Maliyeti',
  desiCosts: 'Desi Maliyetleri',
  ownDesi: 'Kendi Ür. Desi',
  ownDesiCost: 'Kendi Ür. Desi Maliyeti',
  differentProduct: 'Farklı Ürün',
  differentProductCost: 'Farklı Ürün Maliyetleri',
  differentProductCosts: 'Farklı Ürün Maliyeti',
  shipment: 'Sevkiyat',
  shippingCost: 'Kargo Maliyeti',
  shippingCosts: 'Kargo Maliyetleri',
  preperationCost: 'Hazırlama Maliyeti',
  overallCost: 'Genel Maliyet',
  customerOwnProducts: ' Müşteri Kendi Ürünleri',
  cargo: 'Kargo',
  shippingAmount: 'Nakliye Tutarı',
  desiAmount: 'Desi Tutarı',
  piece: 'Adet',
  desiWithSmall: 'desi',
  preparationFeeCost: 'Hazırlama Bedeli Maliyetleri',
  totalCosts: 'Toplam Maliyetler',
  controlAndManageYourPentaStorageTransactions: 'Penta depo işlemlerinizi kontrol edin ve yönetin',
  whatIsPentaStorage: 'Penta Depom’a Hoş Geldiniz!',
  introductionUpperCase: 'TANITIM',
  introductionFirstParagraph:
    'PentaDepom, Penta’dan sipariş verdiğiniz ürünlerin yine Penta Deposu içinde bekletilip sizin istediğiniz zamanda istediğiniz adrese sevk edilmesini sağlayan bir uygulamadır.',
  introductionSecondParagraph:
    'Bu uygulama ile PentaDepom lokasyonunda bulunan ürünlerinizi listeleyebilir, depolama maliyetlerinizi görebilir ve istediğiniz adrese sevk edebilirsiniz.',
  howDoesPentaStorageProvideAnAdvantage: 'Penta Depom nasıl bir avantaj sağlar?',
  advantageFirstParagraph:
    'Normal satın alma sürecinde verdiğiniz siparişler sevkiyatlarımız veya kargo ile deponuza ulaştırılmakta ve stok takibi, nakliyesi gibi işlemler sizin tarafınızdan yapılmaktadır. Bu süreçte lokasyon kirası, iş gücü, güvenlik, enerji, nakliye, zaman vs gibi iç ve dış etkenleri de düşünmeniz gerekmektedir.',
  inTheNormalWorkflow: 'Normal işleyişte iş akışı:',
  inThePentaStorageApplication: 'PentaDepom uygulamasında ise',
  advantageSecondParagraph:
    'PentaDepom üzerinden ilgili lokasyonu yönetmenizi sağlar. Bu uygulama ile yukarıda saydığımız iç ve dış etken maliyet ve riskleri en aza indirmeniz amaçlanmaktadır.',
  howCanIUsePentaStorage: 'PentaDepom’u nasıl kullanabilirim?',
  howToUseFirstParagraph:
    'PentaDepom uygulamasını kullanabilmek için öncelikle Hizmet Sözleşmesini imzalayıp Penta yetkililerine göndermeniz gerekmektedir.',
  clickHereForTheAgreement: 'Sözleşme için tıklayınız.',
  howToUseSecondParagraph: 'Sözleşme sonrasında gerekli yetkiler firmanıza tanımlanacaktır.',
  isPentaStoragePaidService: 'PentaDepom ücretli midir?',
  isPaidServiceFirstParagraph:
    'PentaDepom için depolanan ürün adedi, toplam desi ve taşıma bedelleri karşılığında günlük olarak bir maliyet hesaplanarak dönemsel olarak size fatura edilecektir. Bu bedelleri PentaDepom sayfasında anlık olarak görebileceksiniz. Ücretlendirme ile ilgili bilgilendirme sözleşmede belirtilmiştir.Ücretlendirme ile ilgili bilgilendirme sözleşmede belirtilmiştir. ',
  howCanIShipProductsToPentaStorageViaBayinet: 'PentaDepom’a bayinet üzerinden nasıl ürün sevk edebilirim?',
  shipPentaStorageViaBayinetFirstParagraph:
    'PentaDepom sözleşmeniz olduğu takdirde, sepet sayfamızda PentaDepom’a sevk etmek istiyorum adında bir seçenek çıkacaktır. Burayı seçmeniz halinde sevk edebileceğiniz PentaDepom lokasyonları belirecek ve siparişi bitirdiğinizde gerekli işlemler yapılarak ürünler PentaDepom yönetim panelinizde gözükecektir.',
  howCanIViewMyProductsInPentaStorage: 'PentaDepom’da bulunan ürünlerimi nasıl görebilirim?',
  viewMyProductsFirstParagraph:
    'PentaDepom uygulamasında yer alan Depodaki Ürünlerim linkinden ürünlerinizi listeleyebilirsiniz. Burada bulunan ürünleri hangi siparişle aldığınız, ürünlerin seri numaraları, stok adetleri, lokasyonları vb. bilgiler yer almaktadır.',
  howCnIShipMyProductsToAnotherAddressUsingBayinetFromPentaStorage:
    'PentaDepom’dan bayinet’i kullanarak ürünlerimi başka bir adrese nasıl sevk edebilirim?',
  shipMyProductToAnotherAddressFirstParagraph:
    'PentaDepom lokasyonundan sevk etmek istediğiniz ürünleri sepete ekledikten sonra Depo Sepetim bölümüne giderek Teslimat Seçeneklerini ve Sevkiyat Türünü seçip siparişi sonlandırabilirsiniz. Bu bölüm sevk bölümü olduğundan ürünle ilgili herhangi bir fiyat vs gibi bilgi yer almamaktadır. Bu nedenle herhangi bir ödeme sistemine de bağlanmamıştır.',
  shipMyProductToAnotherAddressSecondParagraph:
    'Burada yer alan ve görebildiğiniz tek fiyat bilgisi seçmiş olduğunuz sevkiyat türüne göre hesaplanan Taşıma Bedeli tutarıdır. Bu tutar sözleşmede yer alan bedellere göre hesaplanmaktadır. Ve dönem sonunda toplu olarak fatura edilmektedir.',
  howCanIViewMyCostsIncurredDuringThePeriodInPentaStorage: 'PentaDepom’da dönem içinde yer alan maliyetlerimi nasıl görebilirim?',
  costIncurredFirstParagraph:
    'PentaDepom uygulamasında dönem bazında hem toplam olarak ne kadarlık bir maliyet oluştuğunu, hem de bu maliyetlerin gün bazında detaylarını görebileceğiniz Depo Maliyetlerim bölümü bulunmaktadır.',
};
