import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, TableSortLabel, Typography, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import { useCallback } from 'react';
export const TableHeaderRow = styled('tr')(() => ({
  height: '56px',
}));

export const TableHeaderCell = styled('th')(({ width, minWidth, headerAlign, position }) => {
  let positionCss = {};
  if (position) {
    positionCss = {
      position: 'sticky',
      right: position,
    };
  }
  return {
    color: primaryColors.primary,
    background: 'rgb(247, 247, 247)',
    cursor: 'pointer',
    padding: 12,
    borderLeft: 0,
    borderRight: 0,
    width: width || 'auto',
    minWidth: minWidth,
    textAlign: headerAlign || 'left',
    border: 0,
    borderBottom: `1px solid ${primaryColors.primary200}`,
    ...positionCss,
  };
});

const PTableHeader = ({ columns, orderBy, sortRequest, order, data, toggleAllCollapse, collapsedSections }) => {
  const createSortHandler = (property) => (event) => {
    sortRequest(event, property);
  };

  const createCollapseColumn = useCallback(() => {
    if (data.some((item) => item.children)) {
      return (
        <TableHeaderCell width="66px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleAllCollapse}>
            {data.every((_, index) => collapsedSections[index]) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableHeaderCell>
      );
    } else return null;
  }, [data, collapsedSections, toggleAllCollapse]);

  return (
    <TableHeaderRow>
      {createCollapseColumn()}
      {columns.map((column) => (
        <TableHeaderCell
          headerAlign={column.headerAlign}
          width={column.width}
          minWidth={column.minWidth}
          key={`table-head-cell-${column.field}`}
          position={column.position}>
          {column.sortable ? (
            <TableSortLabel
              sx={{
                width: '100%',
                height: '100%',
                flexDirection: column.headerAlign === 'right' ? 'row-reverse' : 'row',
                justifyContent: column.headerAlign === 'center' ? 'center' : 'flex-start',
              }}
              component={Box}
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : 'asc'}
              onClick={createSortHandler(column.field)}>
              <Typography variant="labelMedium">{column.headerName}</Typography>
            </TableSortLabel>
          ) : (
            <Typography variant="labelMedium"> {column.headerName}</Typography>
          )}
        </TableHeaderCell>
      ))}
    </TableHeaderRow>
  );
};
export default PTableHeader;
