import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, styled, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import CustomerCreditCard from 'components/CustomerCreditCard';
import IconResolver from 'components/iconResolver/IconResolver';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PClientTable from 'components/table/PClientTable';
import PTooltip from 'components/tooltip/PTooltip';
import { greenColors, primaryColors, slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import dayjs from 'dayjs';
import SecondaryLayout from 'layout/secondaryLayout/SecondaryLayout';
import { getPaymentResult } from 'pages/configMaster/api/ConfigMasterApi';
import PrintableContent from 'pages/configMaster/pages/success/PrintableContent';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { formatPrice } from 'utils/HelperFunctions';
const ResponseContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '16px',
  rowGap: '20px',
}));

const PaymentDetails = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
}));

const PaymentDetailRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: ' space-between',
  marginBottom: 16,
  borderBottom: `1px solid ${slateColors.slate200}`,
  paddingBottom: 16,
  '&>*:nth-of-type(1)': {
    width: '50%',
  },
  '&>*:nth-of-type(2)': {
    width: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'end',
  },
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 24,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
  },
}));

const WithCardInfoSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [paymentDetail, setPaymentDetails] = useState(null);
  const [tableData, setTableData] = useState([]);
  const printRef = useRef();
  useEffect(() => {
    getPaymentDetail();
  }, []);

  const getPaymentDetail = () => {
    const keyValue = searchParams.get('key') || '';
    if (!keyValue) {
      navigate(`${paths.wizard}${paths.fbw}`);
      return;
    }
    setLoading(true);
    getPaymentResult(keyValue)
      .then((res) => {
        setTableData(res.data.data.items);
        setPaymentDetails(res.data.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const columns = useMemo(() => {
    return [
      {
        field: 'stockCode',
        headerName: t('configMaster:productCode'),
        disableColumnMenu: true,
        minWidth: 96,
        renderCell: ({ row }) => Number(row.stockCode),
      },
      {
        field: 'itemName',
        headerName: t('configMaster:productName'),
        disableColumnMenu: true,
        flex: 1,
        minWidth: 224,
      },
      {
        field: 'quantity',
        headerName: t('configMaster:piece'),
        disableColumnMenu: true,
        width: 92,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'unitPrice',
        headerName: t('configMaster:unitPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 128,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.unitPrice)} ${row.currency}`}</Box>;
        },
      },
      {
        field: 'vatPrice',
        headerName: 'KDV',
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 128,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.vatPrice)} ${row.currency}`}</Box>;
        },
      },
      {
        field: 'totalPrice',
        headerName: t('configMaster:taxIncludedPrice'),
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        minWidth: 128,
        renderCell: ({ row }) => {
          return <Box>{`${formatPrice(row.totalPrice)} ${row.currency}`}</Box>;
        },
      },
    ];
  }, [t]);
  return (
    <>
      {loading && <LoadingSpinner />}
      <SecondaryLayout
        path={`${paths.wizard}${paths.fbw}`}
        title={t('configMaster:orderDetails')}>
        <ResponseContainer>
          <Box className="flex-column d-flex">
            <CheckCircleIcon sx={{ color: greenColors.green500, width: '48px', height: '48px' }} />
            <Typography
              variant="titleLarge"
              color={primaryColors.primary}>
              {t('configMaster:orderCreatedSuccessfully')}
            </Typography>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary}>
              {t('configMaster:paymentTransactionCompletedSuccessfully')}
            </Typography>
          </Box>
          <Content>
            <Box>
              <CustomerCreditCard
                bank={paymentDetail?.bank}
                cardNumber={paymentDetail?.cardNumber}
                cardHolder={paymentDetail?.cardHolder}
                installment={paymentDetail?.installmentInformation}
              />
            </Box>

            <PaymentDetails>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:paymentNo')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.paymentNo}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:erpPaymentNo')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.paymentRefNo}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:dateAndTime')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.date && dayjs(paymentDetail?.date).format('DD.MM.YYYY HH:mm')}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:customer')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.customerId}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:user')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {paymentDetail?.user}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:amount')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {`${formatPrice(paymentDetail?.amount)} TL`}
                </Typography>
              </PaymentDetailRow>
              <PaymentDetailRow>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {t('configMaster:explanation')}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  <PTooltip
                    placement="bottom-start"
                    title={paymentDetail?.description}>
                    {paymentDetail?.description}
                  </PTooltip>
                </Typography>
              </PaymentDetailRow>
            </PaymentDetails>
          </Content>
          <ButtonContainer>
            <PButton
              onClick={handlePrint}
              inputtype="outline"
              startIcon={<IconResolver iconName="TextSnippetOutlined" />}>
              {t('configMaster:printPayment')}
            </PButton>
          </ButtonContainer>
          <PClientTable
            dataLength={`${tableData.length} ${t('configMaster:piece')}`}
            headerTitle={t('configMaster:orderItems')}
            columns={columns}
            rows={tableData}
            getRowId={(row) => row.stockCode}
            getRowHeight={() => 'auto'}
          />
        </ResponseContainer>
      </SecondaryLayout>
      {paymentDetail && (
        <PrintableContent
          paymentDetail={paymentDetail}
          ref={printRef}
        />
      )}
    </>
  );
};

export default WithCardInfoSuccess;
