import { Box } from '@mui/system';
import PButton from 'components/button/PButton';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import PCenterModal from 'components/modal/PCenterModal';
import TelInput from 'components/telInput/TelInput';
import PPasswordTextField from 'components/textField/PPasswordTextField';
import PTextField from 'components/textField/PTextField';
import { updateBayinetUser } from 'pages/login/api/LoginApi';
import { pentaUserModalValidationMessagesKeys } from 'pages/login/pentaUserModal/ValidationMessageKeys';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';

const PentaUserModal = ({ user, setShowModal, token, setToken, setPage, setFormField }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    password: '',
    passwordRepeat: '',
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({
    password: '',
    passwordRepeat: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    setData({
      password: '',
      passwordRepeat: '',
      email: user.email,
      phone: '',
    });
  }, []);

  const onClose = () => {
    setShowModal(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const onClickHandler = () => {
    const rules = {
      email: 'required|email',
      phone: 'required',
      password: 'required',
      passwordRepeat: 'required|same:password',
    };
    let phone = data.phone.substring(1);
    phone = phone.replace(/\s/g, '');

    const copyData = { ...data, phone };

    const validation = new Validator(copyData, rules, pentaUserModalValidationMessagesKeys);

    if (validation.passes()) {
      const headers = { Authorization: `Bearer ${token}` };
      let mobile = data.phone.substring(1);
      mobile = mobile.replace(/\s/g, '');
      const sendingData = {
        password: data.password,
        password2: data.passwordRepeat,
        email: data.email,
        mobile,
      };
      setIsLoading(true);
      updateBayinetUser(sendingData, headers)
        .then((res) => {
          toast.success(res.data.message);
          setPage(0);
          setToken('');
          setFormField((prevState) => ({ ...prevState, password: '' }));
          onClose();
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: validation.errors.first('email'),
        phone: validation.errors.first('phone'),
        password: validation.errors.first('password'),
        passwordRepeat: validation.errors.first('passwordRepeat'),
      }));
    }
  };

  return (
    <PCenterModal
      width="450px"
      onClose={onClose}
      showClose
      subTitle={`${t('login:pentaUserTitle')} ${t('login:pentaUserSubtitle')}`}>
      <Box className="d-flex flex-column">
        {isLoading && <LoadingSpinner />}

        <PPasswordTextField
          label={t('login:newPassword')}
          name="password"
          value={data.password}
          onChange={handleOnChange}
          error={!!errors.password}
          errorMessage={t(errors.password, { field: t('login:newPassword') })}
        />
        <PPasswordTextField
          label={t('login:newPasswordAgain')}
          name="passwordRepeat"
          value={data.passwordRepeat}
          onChange={handleOnChange}
          error={!!errors.passwordRepeat}
          errorMessage={t(errors.passwordRepeat, {
            field: t('login:newPasswordAgain'),
            firstField: t('login:newPassword'),
            secondField: t('login:newPasswordAgain'),
          })}
        />
        <PTextField
          disabled={!!user.email}
          label={t('login:email')}
          name="email"
          value={data.email}
          onChange={handleOnChange}
          error={!!errors.email}
          errorMessage={t(errors.email, { field: t('login:email') })}
        />
        <TelInput
          label={t('login:mobile')}
          value={data.phone}
          onChange={(value) => {
            setErrors((prevState) => ({ ...prevState, phone: '' }));
            setData((prevState) => ({ ...prevState, phone: value }));
          }}
          error={!!errors.phone}
          errorMessage={t(errors.phone, { field: t('login:mobile') })}
        />
        <PButton onClick={onClickHandler}> {t('login:save')}</PButton>
      </Box>
    </PCenterModal>
  );
};

export default PentaUserModal;
