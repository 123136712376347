import { useTheme } from '@emotion/react';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import PButton from 'components/button/PButton';
import ChangeLanguageSelect from 'components/changeLanguageSelect/ChangeLanguageSelect';
import Logo from 'components/logo/Logo';
import NavigationLink from 'components/navigationLink/NavigationLink';
import { slateColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const CustomAppBar = styled(AppBar)(() => ({
  minHeight: '64px',
  backgroundColor: '#ffffff',
  color: slateColors.slate950,
  boxShadow: ' 0px 1px 2px 0px #3232320D',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '4px',
}));

const PhoneButton = styled(PButton)(({ theme }) => ({
  width: 'auto',
  color: '#AC202D',
  borderColor: '#AC202D',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
const LinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    gap: '4px',
    '&>div': {
      display: 'none',
    },
  },
}));

const HamburgerMenuContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  width: '220px',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '220px',
    top: '64px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const RightSideContainer = styled(Box)(({ theme }) => {
  return {
    alignItems: 'center',
    display: 'flex',
    gap: '15px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  };
});

const LoginAppBar = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setIsOpenMenu(false);
  }, [matches]);

  const { t } = useTranslation();

  const links = useMemo(
    () => [
      {
        translateKey: t('loginAppBar:aboutUs'),
        path: `${paths.account}${paths.aboutUs}`,
      },
      {
        translateKey: t('loginAppBar:messageFromTheGeneralManager'),
        path: `${paths.account}${paths.generalManagerMessage}`,
      },
      {
        translateKey: t('loginAppBar:missionVision'),
        path: `${paths.account}${paths.missionVision}`,
      },
      {
        translateKey: t('loginAppBar:ourPolicies'),
        path: `${paths.account}${paths.ourPolitics}`,
      },
      {
        translateKey: t('loginAppBar:contact'),
        path: `${paths.account}${paths.contact}`,
      },
    ],
    [t],
  );

  return (
    <>
      <CustomAppBar>
        <Toolbar sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Box className="d-flex align-items-center h100 w100 justify-content-between flex-wrap gap-4">
            <LinkContainer className="d-flex gap-32  align-items-center">
              <Logo />
              {links.map((link) => (
                <Box key={link.translateKey}>
                  <NavigationLink
                    to={link.path}
                    label={link.translateKey}
                  />
                </Box>
              ))}
            </LinkContainer>
            <RightSideContainer>
              <ChangeLanguageSelect />
              <PhoneButton
                inputtype="outline"
                to="tel:8502770277"
                component={Link}
                startIcon={<LocalPhoneOutlinedIcon />}>
                850 277 0 277
              </PhoneButton>
            </RightSideContainer>
            <HamburgerMenuContainer>
              <IconButton
                inputtype="outline"
                onClick={() => setIsOpenMenu((prevState) => !prevState)}>
                <MenuIcon />
              </IconButton>
            </HamburgerMenuContainer>
          </Box>
          <CustomDrawer
            hideBackdrop={theme.breakpoints.between('xs', 'md') ? true : false}
            onClose={() => setIsOpenMenu(false)}
            anchor="left"
            open={isOpenMenu}>
            <Box
              sx={{ padding: '24px' }}
              className="d-flex flex-column gap-12">
              <Box className="d-flex flex-column gap-12">
                {links.map((link) => (
                  <div key={link.translateKey}>
                    <NavigationLink
                      onClick={() => setIsOpenMenu((prevState) => !prevState)}
                      to={link.path}
                      label={link.translateKey}
                    />
                  </div>
                ))}
              </Box>
              <ChangeLanguageSelect />
            </Box>
          </CustomDrawer>
        </Toolbar>
      </CustomAppBar>
    </>
  );
};

export default LoginAppBar;
