import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { greenColors, neutralColors, primaryColors } from 'config/theme/Colors';

const CustomSuccessButton = styled(Button)(({ width }) => {
  const commonProperties = {
    height: '40px',
    borderRadius: 999,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.1,
    wordWrap: 'break-word',
    width,
  };
  return {
    ...commonProperties,
    padding: '10px 24px',
    color: primaryColors.primary50,
    backgroundColor: greenColors.green700,

    '&:hover': {
      backgroundColor: greenColors.green800,
      color: neutralColors.neutral50,
    },
  };
});

const PSuccessButton = ({ disabled = false, endIcon = null, onClick = () => {}, children, width = '100%', ...rest }) => {
  return (
    <CustomSuccessButton
      width={width}
      inputtype="filled"
      disabled={disabled}
      endIcon={endIcon}
      onClick={onClick}
      {...rest}>
      <Typography variant="labelLarge"> {children}</Typography>
    </CustomSuccessButton>
  );
};

export default PSuccessButton;
