import { Box, Typography, styled } from '@mui/material';
import ErrorAlert from 'components/alert/ErrorAlert';
import PButton from 'components/button/PButton';
import PDivider from 'components/Divider/PDivider';
import { primaryColors, slateColors } from 'config/theme/Colors';
import { simulateBeforeOrder } from 'pages/configMaster/api/ConfigMasterApi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatPrice } from 'utils/HelperFunctions';

const Title = styled(Box)(() => ({
  padding: '28px 16px',
}));

const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 16px',
}));

const Item = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 0',
  width: '100%',
}));

const DynamicContainer = styled(Box)(() => ({
  '& > *': {
    borderBottom: `1px solid ${slateColors.slate200}`,
  },
  '&>*:nth-of-type(1)': {
    padding: 0,
    paddingBottom: '24px',
  },
  'div:last-of-type': {
    borderBottom: 0,
  },
}));

const OrderSummary = ({ setShowOrderDetail, basketItems, orderSummaryPart, basketOptions, items, customerDefaultCurrency, setLoading }) => {
  const { t } = useTranslation();

  const simulateBeforeOrderFn = () => {
    if (basketOptions.length > 0) {
      const activeBasket = basketOptions.find((option) => option.isDefaultCart);
      if (activeBasket) {
        const copyOfItems = [...items].map((item) => ({
          productIdInCart: item.id,
          productId: item.stockCode,
          amount: item.unitPrice,
          quantity: item.quantity,
          stock: item.stock,
          currency: item.currency,
          maturity: item.maturityPeriod,
          maturityText: item.maturityPeriodDescription,
        }));
        setLoading(true);
        simulateBeforeOrder(
          activeBasket.maturityPeriod,
          activeBasket.maturityPeriodDescription,
          activeBasket.configIds,
          copyOfItems,
          customerDefaultCurrency,
        )
          .then((res) => {
            setShowOrderDetail({ ...res.data.data, activeBasket: activeBasket, basketItems: copyOfItems, customerDefaultCurrency });
          })
          .catch((err) => {
            const errorMessages = err.response.data?.data?.messages;

            errorMessages?.forEach((errorMessage) => {
              if (errorMessage.type === 'E') {
                toast.error(errorMessage.message);
              }
            });

            toast.error(err.response.data.Message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <Box className="d-flex flex-column">
      <Title>
        <Typography
          variant="titleMedium"
          color={primaryColors.primary}>
          {t('configMaster:orderSummary')}
        </Typography>
      </Title>
      <PDivider marginBottom="0" />
      <ItemContainer>
        <DynamicContainer>
          {orderSummaryPart.map((summary) => (
            <Item key={summary.amountType}>
              <Box>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {summary.amountType}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="titleSmall"
                  color={primaryColors.primary}>
                  {`${formatPrice(summary.amount)} ${summary.currency}`}
                </Typography>
              </Box>
            </Item>
          ))}
        </DynamicContainer>
        {basketItems.some((item) => item.stock <= 0) && (
          <Item sx={{ padding: 0, paddingBottom: '24px' }}>
            <ErrorAlert label={t('configMaster:someOfProductsNotInStock')} />
          </Item>
        )}
        <PButton onClick={simulateBeforeOrderFn}>{t('configMaster:order')}</PButton>
      </ItemContainer>
    </Box>
  );
};
export default OrderSummary;
