import { TableRow } from '@mui/material';
import PErrorChip from 'components/chip/PErrorChip';
import PSuccessChip from 'components/chip/PSuccessChip';
import { PTableRowCell } from 'components/simpleTable/SimpleTable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SubSubcriptionListMenu from './SubSubcriptionListMenu';

const SubSubscriptionRows = ({ row, loaderFunction, setIsLoading }) => {
  const { t } = useTranslation();
  const userPermissions = useSelector((state) => state.currentUser.currentUser.permissions);
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset', backgroundColor: '#ffffff', height: '56px' } }}>
      <PTableRowCell
        component="th"
        scope="row"
      />
      <PTableRowCell>
        {row.interlocutorRole === 'Z1' ? t('accountRelatedPersons:contractManager') : t('accountRelatedPersons:softwareCoordinator')}
      </PTableRowCell>
      <PTableRowCell align="left">{row.firstName}</PTableRowCell>
      <PTableRowCell align="left">{row.lastName}</PTableRowCell>
      <PTableRowCell align="left">{row.phone}</PTableRowCell>
      <PTableRowCell align="left">{row.email}</PTableRowCell>
      <PTableRowCell align="left">
        {row.deletionFlag ? <PSuccessChip label={t('userManagement:active')} /> : <PErrorChip label={t('userManagement:passive')} />}
      </PTableRowCell>
      <PTableRowCell align="left">
        {userPermissions.find((permission) => permission.permissionType === 'Permissions.ContractPerson.ContractPersonCrud') && (
          <SubSubcriptionListMenu
            row={row}
            loaderFunction={loaderFunction}
            setIsLoading={setIsLoading}
          />
        )}
      </PTableRowCell>
    </TableRow>
  );
};

export default SubSubscriptionRows;
