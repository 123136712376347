import { Box, styled, Typography } from '@mui/material';
import useVisibleInDom from 'common/hooks/useVisibleInDom';
import PAccordion from 'components/accordion/PAccordion';
import PAccordionDetail from 'components/accordion/PAccordionDetail';
import PAccordionSummary from 'components/accordion/PAccordionSummary';
import PButton from 'components/button/PButton';
import PDatePicker from 'components/datePicker/PDatePicker';
import PClientInfiniteScroll from 'components/infiniteScroll/PClientInfiniteScroll';
import PRadio from 'components/radio/PRadio';
import PUnselectableRadio from 'components/radio/PUnselectableRadio';
import TableSearchTextField from 'components/searchInput/TableSearchTextField';
import PSelect from 'components/select/PSelect';
import SelectableTags from 'components/selectableTags/SelectableTags';
import PTextField from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import { getBrands, getEndUsers } from 'pages/pay/api/PayApi';
import { payFilterSelectEnums } from 'pages/pay/constants/PayFilterSelectEnums';
import { payRefreshTypeEnums } from 'pages/pay/constants/PayRefreshTypeEnums';
import { payStatusEnums } from 'pages/pay/constants/PayStatusEnums';
import { payTagEnums } from 'pages/pay/constants/PayTagEnums';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const SidebarWrapper = styled(Box)(({ theme }) => ({
  minWidth: '288px',
  width: '288px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const DetailFilterContainer = styled(Box)(() => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  width: '100%',
  height: 'auto',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 16px',
  justifyContent: 'space-between',
  position: 'relative',
  paddingBottom: '64px',
  border: `1px solid ${primaryColors.primary300}`,
  gap: '50px',
}));

const ButtonContainer = styled(Box)(({ theme, isvisible = false }) => ({
  position: isvisible ? 'absolute' : 'fixed',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  bottom: '12px',
  width: '256px',
  marginTop: '8px',
  [theme.breakpoints.down('md')]: {
    width: '-webkit-fill-available',
    paddingRight: isvisible ? '16px ' : '32px ',
  },
}));

const Sidebar = ({ selectValue, onSelectChangeHandler, searchValues, setSearchValues, setShowEmptyPage, getProducts }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [searchInput, setSearchInput] = useState({ brand: '', endUser: '' });
  const isVisible = useVisibleInDom(ref);
  const [seletedTagValue, setSelectedTagValue] = useState(null);
  const [brands, setBrands] = useState({ allData: [], visibleData: [] });
  const [endUsers, setEndUsers] = useState({ allData: [], visibleData: [] });

  const getBrandList = () => {
    getBrands()
      .then((res) => {
        const brandMapList = res.data.data.map((item) => ({
          id: item.key,
          title: item.value,
        }));

        setBrands({ allData: brandMapList, visibleData: brandMapList });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  const getUserList = () => {
    getEndUsers()
      .then((res) => {
        const userMapList = res.data.data.map((item) => ({
          id: item.key,
          title: item.value,
        }));

        setEndUsers({ allData: userMapList, visibleData: userMapList });
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  };

  useEffect(() => {
    getBrandList();
    getUserList();
  }, []);

  useEffect(() => {
    const startDate = searchValues.startDate;
    const endDate = searchValues.endDate;
    const diff = endDate?.endOf('day')?.diff(startDate, 'days');
    if (startDate?.isSame(dayjs(), 'day')) {
      if (diff === payTagEnums.last10) {
        setSelectedTagValue(payTagEnums.last10);
      } else if (diff === payTagEnums.last30) {
        setSelectedTagValue(payTagEnums.last30);
      } else if (diff === payTagEnums.last60) {
        setSelectedTagValue(payTagEnums.last60);
      } else if (diff === payTagEnums.last90) {
        setSelectedTagValue(payTagEnums.last90);
      } else if (diff === payTagEnums.last120) {
        setSelectedTagValue(payTagEnums.last120);
      } else if (diff === payTagEnums.last360) {
        setSelectedTagValue(payTagEnums.last360);
      } else {
        setSelectedTagValue(null);
      }
    } else {
      setSelectedTagValue(null);
    }
  }, [searchValues.startDate, searchValues.endDate]);

  const tagObject = useMemo(() => {
    return [
      {
        value: payTagEnums.last10,
        title: t('pay:lastNthDays', { day: 10 }),
      },
      {
        value: payTagEnums.last30,
        title: t('pay:lastNthDays', { day: 30 }),
      },
      {
        value: payTagEnums.last60,
        title: t('pay:lastNthDays', { day: 60 }),
      },
      {
        value: payTagEnums.last90,
        title: t('pay:lastNthDays', { day: 90 }),
      },
      {
        value: payTagEnums.last120,
        title: t('pay:lastNthDays', { day: 120 }),
      },
      {
        value: payTagEnums.last360,
        title: t('pay:lastNthDays', { day: 360 }),
      },
    ];
  }, [t]);

  const refreshObject = useMemo(() => {
    return [
      {
        value: payRefreshTypeEnums.all,
        title: t('pay:all'),
      },
      {
        value: payRefreshTypeEnums.manuel,
        title: t('pay:manuel'),
      },
      {
        value: payRefreshTypeEnums.auto,
        title: t('pay:automatic'),
      },
    ];
  }, [t]);

  const statusObject = useMemo(() => {
    return [
      {
        value: payStatusEnums.all,
        title: t('pay:all'),
      },
      {
        value: payStatusEnums.active,
        title: t('pay:activeOnes'),
      },
    ];
  }, [t]);

  const tagSelectHandler = (tagValue) => {
    setSelectedTagValue(tagValue);
    setSearchValues((prevState) => ({ ...prevState, startDate: dayjs(), endDate: dayjs().add(tagValue, 'days') }));
  };

  const menuItems = useMemo(() => {
    return [
      { key: payFilterSelectEnums.product, value: t('pay:showByProducts') },
      { key: payFilterSelectEnums.subscription, value: t('pay:showBySubscriptions') },
    ];
  }, [t]);

  const onRefreshTypeChangeHandler = (value) => {
    setSearchValues((prevState) => ({ ...prevState, refreshType: value }));
  };

  const onBrandChangeHandler = (event, value) => {
    const { checked } = event.target;
    setSearchValues((prevState) => ({ ...prevState, brand: checked ? value : '' }));
  };

  const onEndUserChangeHandler = (event, value) => {
    const { checked } = event.target;
    setSearchValues((prevState) => ({ ...prevState, endUser: checked ? value : '' }));
  };

  const onSearchInputChange = (event) => {
    const { value, name } = event.target;
    setSearchInput((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'brand') {
      setBrands((prevState) => ({
        ...prevState,
        visibleData: prevState.allData.filter((e) =>
          Object.values({ title: e.title }).join('').toLocaleLowerCase('tr').includes(value.toLocaleLowerCase('tr')),
        ),
      }));
    } else if (name === 'endUser') {
      setEndUsers((prevState) => ({
        ...prevState,
        visibleData: prevState.allData.filter((e) =>
          Object.values({ title: e.title }).join('').toLocaleLowerCase('tr').includes(value.toLocaleLowerCase('tr')),
        ),
      }));
    }
  };

  const onSearchValueInputChange = (event) => {
    const { value, name } = event.target;
    setSearchValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const onStatusChangeHandler = (value) => {
    setSearchValues((prevState) => ({ ...prevState, status: value }));
  };

  const onListHandler = () => {
    if (selectValue === payFilterSelectEnums.subscription) {
      setShowEmptyPage({ isWorkedOnce: true, show: false });
    } else {
      setShowEmptyPage((prevState) => ({ ...prevState, show: false }));
    }
    getProducts();
  };

  const renderEndUserCallback = (endUser) => {
    return (
      <PUnselectableRadio
        width="100%"
        checked={endUser.id === searchValues.endUser}
        label={endUser.title}
        onChange={(event) => onEndUserChangeHandler(event, endUser.id)}
      />
    );
  };

  const renderBrandCallback = (brand) => {
    return (
      <PUnselectableRadio
        width="100%"
        checked={brand.id === searchValues.brand}
        label={brand.title}
        onChange={(event) => onBrandChangeHandler(event, brand.id)}
      />
    );
  };

  const resetFilter = useCallback(() => {
    setSearchValues({
      startDate: dayjs(),
      endDate: dayjs().add(180, 'days'),
      refreshType: payRefreshTypeEnums.all,
      brand: '',
      endUser: '',
      subscriptionNo: '',
      contractNo: '',
      status: payStatusEnums.active,
    });
  }, [setSearchValues]);

  return (
    <SidebarWrapper>
      <PSelect
        showLabel={false}
        showHelperContainer={false}
        menuItems={menuItems}
        value={selectValue}
        onChange={onSelectChangeHandler}
      />
      <DetailFilterContainer>
        <Box>
          <PAccordion
            defaultExpanded
            sx={{ border: 'none', boxShadow: 'none', minHeight: '40px' }}>
            <PAccordionSummary>
              <Typography
                variant="labelLarge"
                color={primaryColors.primary}>
                {t('pay:renewalDates')}
              </Typography>
            </PAccordionSummary>
            <PAccordionDetail
              maxHeight="304px"
              sx={{ paddingLeft: '0px', marginBottom: 0 }}>
              <Box className="d-flex flex-column gap-16">
                {selectValue === payFilterSelectEnums.product && (
                  <Box className="d-flex gap-8 flex-wrap">
                    {tagObject.map((tag) => {
                      return (
                        <SelectableTags
                          value={tag.value}
                          key={tag.value}
                          selected={tag.value === seletedTagValue}
                          title={tag.title}
                          onClick={() => tagSelectHandler(tag.value)}
                        />
                      );
                    })}
                  </Box>
                )}
                <Box className="d-flex flex-column gap-8">
                  <PDatePicker
                    label={t('pay:startDateLabel')}
                    value={searchValues.startDate}
                    showHelperContainer={false}
                    onChange={(value) => {
                      setSearchValues((prevState) => ({ ...prevState, startDate: value }));
                    }}
                  />
                  <PDatePicker
                    label={t('pay:endDateLabel')}
                    value={searchValues.endDate}
                    showHelperContainer={false}
                    onChange={(value) => {
                      setSearchValues((prevState) => ({ ...prevState, endDate: value }));
                    }}
                  />
                </Box>
              </Box>
            </PAccordionDetail>
          </PAccordion>
          {selectValue !== payFilterSelectEnums.product && (
            <PAccordion sx={{ border: 'none', boxShadow: 'none', minHeight: '40px' }}>
              <PAccordionSummary>
                <Typography
                  variant="labelLarge"
                  color={primaryColors.primary}>
                  {t('pay:renewalType')}
                </Typography>
              </PAccordionSummary>
              <PAccordionDetail
                maxHeight="304px"
                sx={{ paddingLeft: '0px', marginBottom: 0 }}>
                <Box className="d-flex flex-column gap-8">
                  {refreshObject.map((refresh) => {
                    return (
                      <PRadio
                        key={refresh.title}
                        checked={refresh.value === searchValues.refreshType}
                        label={refresh.title}
                        onChange={() => onRefreshTypeChangeHandler(refresh.value)}
                      />
                    );
                  })}
                </Box>
              </PAccordionDetail>
            </PAccordion>
          )}
          <PAccordion sx={{ border: 'none', boxShadow: 'none', minHeight: '40px' }}>
            <PAccordionSummary>
              <Typography
                variant="labelLarge"
                color={primaryColors.primary}>
                {t('pay:brand')}
              </Typography>
            </PAccordionSummary>
            <PAccordionDetail
              maxHeight="304px"
              sx={{ paddingLeft: '0px', marginBottom: 0 }}>
              <Box className="d-flex flex-column gap-4">
                <TableSearchTextField
                  showHelperContainer={false}
                  showLabel={false}
                  value={searchInput.brand}
                  name="brand"
                  placeholder={t('pay:searchPlaceholder')}
                  onChange={onSearchInputChange}
                />
                <PClientInfiniteScroll
                  listHeight={258}
                  data={brands.visibleData}
                  renderItem={renderBrandCallback}
                />
              </Box>
            </PAccordionDetail>
          </PAccordion>
          <PAccordion sx={{ border: 'none', boxShadow: 'none', minHeight: '40px' }}>
            <PAccordionSummary>
              <Typography
                variant="labelLarge"
                color={primaryColors.primary}>
                {t('pay:endUser')}
              </Typography>
            </PAccordionSummary>
            <PAccordionDetail
              maxHeight="304px"
              sx={{ paddingLeft: '0px', marginBottom: 0, overflow: 'hidden' }}>
              <Box className="d-flex flex-column gap-4">
                <TableSearchTextField
                  showHelperContainer={false}
                  showLabel={false}
                  value={searchInput.endUser}
                  name="endUser"
                  placeholder={t('pay:searchPlaceholder')}
                  onChange={onSearchInputChange}
                />
                <PClientInfiniteScroll
                  listHeight={258}
                  data={endUsers.visibleData}
                  renderItem={renderEndUserCallback}
                />
              </Box>
            </PAccordionDetail>
          </PAccordion>
          <PAccordion
            defaultExpanded
            sx={{ border: 'none', boxShadow: 'none', minHeight: '40px' }}>
            <PAccordionSummary>
              <Typography
                variant="labelLarge"
                color={primaryColors.primary}>
                {t('pay:subscriberNo')}
              </Typography>
            </PAccordionSummary>
            <PAccordionDetail
              maxHeight="304px"
              sx={{ paddingLeft: '0px', marginBottom: 0 }}>
              <Box className="d-flex flex-column">
                <PTextField
                  showHelperContainer={false}
                  showLabel={false}
                  value={searchValues.subscriptionNo}
                  name="subscriptionNo"
                  placeholder={t('pay:enterSubscriberNo')}
                  onChange={onSearchValueInputChange}
                />
              </Box>
            </PAccordionDetail>
          </PAccordion>
          <PAccordion
            defaultExpanded
            sx={{ border: 'none', boxShadow: 'none', minHeight: '40px' }}>
            <PAccordionSummary>
              <Typography
                variant="labelLarge"
                color={primaryColors.primary}>
                {t('pay:contractNo')}
              </Typography>
            </PAccordionSummary>
            <PAccordionDetail
              maxHeight="304px"
              sx={{ paddingLeft: '0px', marginBottom: 0 }}>
              <Box className="d-flex flex-column">
                <PTextField
                  showHelperContainer={false}
                  showLabel={false}
                  value={searchValues.contractNo}
                  name="contractNo"
                  placeholder={t('pay:enterContractNo')}
                  onChange={onSearchValueInputChange}
                />
              </Box>
            </PAccordionDetail>
          </PAccordion>
          {selectValue !== payFilterSelectEnums.product && (
            <PAccordion
              defaultExpanded
              sx={{ border: 'none', boxShadow: 'none', minHeight: '40px' }}>
              <PAccordionSummary>
                <Typography
                  variant="labelLarge"
                  color={primaryColors.primary}>
                  {t('pay:status')}
                </Typography>
              </PAccordionSummary>
              <PAccordionDetail
                maxHeight="304px"
                sx={{ paddingLeft: '0px', marginBottom: 0 }}>
                <Box className="d-flex flex-column gap-8">
                  {statusObject.map((status) => {
                    return (
                      <PRadio
                        key={status.title}
                        checked={status.value === searchValues.status}
                        label={status.title}
                        onChange={() => onStatusChangeHandler(status.value)}
                      />
                    );
                  })}
                </Box>
              </PAccordionDetail>
            </PAccordion>
          )}
        </Box>
        <ButtonContainer isvisible={+isVisible}>
          <PButton onClick={onListHandler}>{t('pay:list')}</PButton>
          <PButton onClick={resetFilter}>{t('pay:clear')}</PButton>
        </ButtonContainer>
        <div ref={ref} />
      </DetailFilterContainer>
    </SidebarWrapper>
  );
};

export default React.memo(Sidebar);
