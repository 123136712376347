import { Container } from '@mui/material';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { getPageContentByTitle } from 'pages/addStaticTemplate/api/StaticTemplateApi';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MissionVision = () => {
  const { i18n } = useTranslation();
  const [content, setContent] = useState({ tr: '', en: '' });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getPageContentByTitle(1)
      .then((res) => {
        setContent({ tr: res.data.data.content, en: res.data.data.contentEn });
      })
      .catch(() => {
        setContent({ tr: '', en: '' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const pageContent = useMemo(() => {
    if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return <div dangerouslySetInnerHTML={{ __html: content[i18n.language] }} />;
    }
  }, [content, i18n.language, isLoading]);

  return <Container>{pageContent}</Container>;
};

export default MissionVision;
