import { Grid, Typography } from '@mui/material';
import PButton from 'components/button/PButton';
import PCenterModal from 'components/modal/PCenterModal';
import PTextField from 'components/textField/PTextField';
import { statusCodes } from 'constants/HttpStatusCodes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { saveTemplate, updateTemplate } from './api/TemplateApi';

const SaveTemplateModal = ({ handleClose, userInfo, loadTemplates }) => {
  const currentUser = useSelector((state) => state.currentUser.currentUser);

  const [templateName, setTemplateName] = useState('');
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  const { t } = useTranslation();
  const onChangeHandler = (event) => {
    setTemplateName(event.target.value);
  };

  const saveTemplates = () => {
    saveTemplate({ sapCustomerId: currentUser.user.sapCustomerId, name: templateName, code: templateName, permissionTypes: userInfo.permissionTypes })
      .then((res) => {
        loadTemplates();
        toast.success(res.data.message);
        handleClose(false);
      })
      .catch((err) => {
        if (err.response.status === statusCodes.conflict) {
          setShowUpdateMessage(true);
          setUpdateMessage(err.response.data.Message);
        } else {
          toast.error(err.response.data.Message);
          handleClose(false);
        }
      });
  };

  const updateTemplates = () => {
    updateTemplate({
      sapCustomerId: currentUser.user.sapCustomerId,
      name: templateName,
      code: templateName,
      permissionTypes: userInfo.permissionTypes,
    })
      .then((res) => {
        loadTemplates();
        toast.success(res.data.message);

        handleClose(false);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setUpdateMessage('');
        setShowUpdateMessage(false);
        handleClose(false);
      });
  };

  return (
    <PCenterModal onClose={() => handleClose(false)}>
      <>
        {!showUpdateMessage && (
          <>
            <Grid
              container
              spacing={2}>
              <Grid
                item
                xs={12}>
                <PTextField
                  value={templateName}
                  onChange={onChangeHandler}
                  label={t('addUser:templateName')}
                />
              </Grid>
              <Grid
                item
                xs={6}>
                <PButton
                  onClick={() => {
                    handleClose(false);
                  }}>
                  {t('addUser:cancel')}
                </PButton>
              </Grid>
              <Grid
                item
                xs={6}>
                <PButton
                  disabled={!templateName}
                  onClick={saveTemplates}>
                  {t('addUser:save')}
                </PButton>
              </Grid>
            </Grid>
          </>
        )}
        {showUpdateMessage && (
          <Grid
            container
            spacing={2}
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Grid
              item
              xs={12}>
              <Typography variant="bodyLarge">{updateMessage}</Typography>
            </Grid>
            <Grid
              item
              xs={6}>
              <PButton
                onClick={() => {
                  handleClose(false);
                }}>
                {t('addUser:cancel')}
              </PButton>
            </Grid>
            <Grid
              item
              xs={6}>
              <PButton
                disabled={!templateName}
                onClick={updateTemplates}>
                {t('addUser:yes')}
              </PButton>
            </Grid>
          </Grid>
        )}
      </>
    </PCenterModal>
  );
};

export default SaveTemplateModal;
