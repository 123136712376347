import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { getAllCosts } from 'pages/pentaStorage/api/StorageCostApi';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import StorageCosts from './StorageCosts';
const StorageCostsContainer = () => {
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const [userSeeResult, setUserSeeResult] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [header, setHeader] = useState(null);
  const [loading, setLoading] = useState(false);

  const periodFilterClickHandler = (period) => {
    setLoading(true);
    getAllCosts({ year: period.periodYear, month: parseInt(period.periodMonth) + 1, sapCustomerId: currentUser.user.sapCustomerId })
      .then((res) => {
        setHeader(res.data.data.header[0]);
        const incomingData = [...res.data.data.products];
        if (incomingData.length > 0) {
          incomingData.forEach((data, index) => (data.id = index + 1));
        }

        setTableData(incomingData);
        setUserSeeResult(true);
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <StorageCosts
        tableData={tableData}
        periodFilterClickHandler={periodFilterClickHandler}
        userSeeResult={userSeeResult}
        headers={header}
      />
    </>
  );
};
export default StorageCostsContainer;
