import { Box } from '@mui/material';
import PButton from 'components/button/PButton';
import IconResolver from 'components/iconResolver/IconResolver';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfigMasterSelectButton = ({ onClick, componentGroupTypeName, id, isSelected, disabled }) => {
  const { t } = useTranslation();
  const [iconName, setIconName] = useState('Check');
  const [hover, setHover] = useState(false);
  const getButtonLabel = (component, value) => {
    const isAlreadySelected = isSelected(component, value);
    if (isAlreadySelected) {
      if (hover) {
        return t('configMaster:remove');
      }
      return t('configMaster:selected');
    } else {
      return t('configMaster:select');
    }
  };

  const handleMouseOver = () => {
    setHover(true);
    const isAlreadySelected = isSelected(componentGroupTypeName, id);
    if (isAlreadySelected) {
      setIconName('Close');
    }
  };

  const handleMouseOut = () => {
    setHover(false);
    setIconName('Check');
    getButtonLabel(componentGroupTypeName, id);
  };

  const getInputType = () => {
    const isAlreadySelected = isSelected(componentGroupTypeName, id);
    if (isAlreadySelected) {
      if (hover) {
        return 'outline';
      }
      return 'filled';
    } else {
      return 'outline';
    }
  };

  return (
    <Box
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}>
      <PButton
        disabled={disabled}
        startIcon={<IconResolver iconName={iconName} />}
        inputtype={getInputType()}
        onClick={onClick}>
        {getButtonLabel(componentGroupTypeName, id)}
      </PButton>
    </Box>
  );
};

export default ConfigMasterSelectButton;
