import { Popper, styled, Typography } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import PTextField from 'components/textField/PTextField';
import { primaryColors } from 'config/theme/Colors';
import { useEffect, useRef, useState } from 'react';

const Menu = styled('div')(({ anchor }) => ({
  overflow: 'hidden',
  top: '64px',
  borderTop: 0,
  border: `1px solid ${primaryColors.primary400}`,
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  maxHeight: '250px',
  overflowY: 'auto',
  zIndex: 1500,
  width: anchor ? anchor.offsetWidth : 'auto',
}));

const MenuItem = styled('div')(() => ({
  color: primaryColors.primary,
  padding: '12px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: primaryColors.primary50,
    color: primaryColors.primary,
  },
}));

const TextfieldWrapper = styled('div')(({ width }) => ({
  width,
}));

const PTextFieldWithMenu = ({
  id,
  disabled = false,
  label = '',
  value,
  error = false,
  helpertext = '',
  onChange,
  multiline = false,
  maxRows = 1,
  maxLength = 299,
  showLength = false,
  errorMessage = '',
  startIcon = null,
  endIcon = null,
  menuItems = [],
  containerWidth = 'auto',
  ...rest
}) => {
  const [showData, setShowData] = useState(false);
  const anchorRef = useRef(null);

  const toggleData = () => {
    setShowData((prevState) => !prevState);
  };

  const handleMenuClick = (incomingFn) => {
    incomingFn();
    setShowData((prevState) => !prevState);
  };

  const handleOutsideClick = (event) => {
    if (anchorRef.current && !anchorRef.current.contains(event.target)) {
      setShowData(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <TextfieldWrapper width={containerWidth}>
      <PTextField
        ref={anchorRef}
        id={id}
        onClick={toggleData}
        disabled={disabled}
        label={label}
        value={value}
        error={error}
        helpertext={helpertext}
        onChange={onChange}
        multiline={multiline}
        maxRows={maxRows}
        maxLength={maxLength}
        showLength={showLength}
        errorMessage={errorMessage}
        startIcon={startIcon}
        endIcon={<IconResolver iconName={showData && menuItems.length > 0 ? 'ExpandLess' : 'ExpandMore'} />}
        {...rest}
      />
      {showData && menuItems.length > 0 && (
        <Popper
          sx={{ zIndex: 1500 }}
          open={showData}
          anchorEl={anchorRef.current}
          placement="bottom-start">
          <Menu anchor={anchorRef?.current}>
            {menuItems.map((item) => (
              <MenuItem
                onClick={() => handleMenuClick(item.onClick)}
                key={item.id}>
                <Typography
                  variant="bodyMedium"
                  color={primaryColors.primary}>
                  {item.content}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Popper>
      )}
    </TextfieldWrapper>
  );
};

export default PTextFieldWithMenu;
