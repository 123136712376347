import { Box, styled, Typography } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
import { paths } from 'constants/Paths';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const MenuItem = styled(Box)(({ selected }) => ({
  borderRadius: '8px',
  padding: '12px',
  border: selected ? `1px solid ${primaryColors.primary200}` : 'none',
  backgroundColor: selected ? '#ffffff' : 'transparent',
  cursor: 'pointer',
  maxHeight: '48px',
  display: 'flex',
  alignItems: 'center',
  minWidth: '326px',
}));

const PentaIntegrationSidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const integrationMenuItems = useMemo(() => {
    return [
      { id: 1, name: t('integration:productServices'), path: `${paths.integration}${paths.productServices}` },
      { id: 2, name: t('integration:orderServices'), path: `${paths.integration}${paths.orderServices}` },
      { id: 3, name: t('integration:storageServices'), path: `${paths.integration}${paths.storageServices}` },
      { id: 4, name: t('integration:subscriptionManagementServices'), path: `${paths.integration}${paths.payServices}` },
    ];
  }, [t]);

  const managementMenuItems = useMemo(() => {
    return [{ id: 1, name: t('integration:configurationPreferences'), path: `${paths.integration}${paths.configurationPreferences}` }];
  }, [t]);

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  return (
    <Box className="d-flex flex-column gap-16">
      <Box className="d-flex flex-column gap-8">
        <Typography
          variant="labelMedium"
          color={primaryColors.primary800}>
          {t('integration:capitalIntegrations')}
        </Typography>
        {integrationMenuItems.map((integrationMenuItem) => {
          return (
            <MenuItem
              onClick={() => handleMenuItemClick(integrationMenuItem.path)}
              key={integrationMenuItem.id}
              selected={location.pathname === integrationMenuItem.path}>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary800}>
                {integrationMenuItem.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Box>
      <Box className="d-flex flex-column gap-8">
        <Typography
          variant="labelMedium"
          color={primaryColors.primary800}>
          {t('integration:capitalManagement')}
        </Typography>
        {managementMenuItems.map((integrationMenuItem) => {
          return (
            <MenuItem
              onClick={() => handleMenuItemClick(integrationMenuItem.path)}
              key={integrationMenuItem.id}
              selected={location.pathname === integrationMenuItem.path}>
              <Typography
                variant="titleSmall"
                color={primaryColors.primary800}>
                {integrationMenuItem.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Box>
    </Box>
  );
};
export default PentaIntegrationSidebar;
