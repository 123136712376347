import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { InputLabel, Typography, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomFormControl, HelperContainer, HelperText } from 'components/textField/PTextField';
import i18n from 'config/i18n/i18n';
import { primaryColors, redColors } from 'config/theme/Colors';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';
const CustomDatePicker = styled(DatePicker)(({ disabled, error }) => {
  const getBorderColor = () => {
    if (disabled) {
      return `${primaryColors.primary400}`;
    } else if (error) {
      return `${redColors.red600}`;
    } else {
      return `${primaryColors.primary400}`;
    }
  };

  return {
    fontWeight: 400,
    width: '100%',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input::placeholder': {
      color: primaryColors.primary600,
      opacity: 1,
      fontSize: '16px',
      fontWeight: 400,
    },
    '.MuiInputBase-inputAdornedEnd': {
      fontSize: 'smaller',
    },
    '.MuiInputBase-input': {
      height: '7px',
      borderRadius: '8px',
    },
    '.MuiInputBase-root': {
      border: `1px solid ${getBorderColor()}`,
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      color: primaryColors.primary,
    },
    '.MuiInputBase-root:before': {
      borderBottom: `1px solid ${getBorderColor()}`,
    },
    '.MuiInputBase-root:after': {
      borderBottom: `1px solid ${getBorderColor()}`,
    },
    '.MuiInputBase-root:hover': {
      backgroundColor: '#FFFFFF',
      border: error ? `1px solid ${redColors.red700} !important` : `1px solid ${primaryColors.primary500} !important`,
    },
    '.MuiInputBase-root:hover:before': {
      borderBottom: error ? `1px solid ${redColors.red700} !important` : `1px solid ${primaryColors.primary500} !important`,
    },
    '.MuiInputBase-root:hover:after': {
      borderBottom: error ? `1px solid ${redColors.red700} !important` : `1px solid ${primaryColors.primary500} !important`,
    },

    '.MuiInputBase-root.Mui-disabled': {
      WebkitTextFillColor: `${primaryColors.primary} !important`,
      backgroundColor: primaryColors.primary100,
    },
    '.MuiInputBase-root.Mui-disabled:before': {
      borderBottomStyle: 'solid !important',
      borderBottomWidth: '1px !important',
      borderBottom: `1px solid ${primaryColors.primary400} !important`,
    },
    '.MuiInputBase-root.Mui-disabled:after': {
      borderBottomStyle: 'solid !important',
      borderBottomWidth: '1px !important',
      borderBottom: `1px solid ${primaryColors.primary400} !important`,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input': {
      WebkitTextFillColor: `${primaryColors.primary} !important`,
      backgroundColor: primaryColors.primary100,
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 0,
      borderRadius: '8px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      // border: `1px solid ${primaryColors.primary700} `,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: getBorderColor(),
    },
    '.MuiInputBase-root.Mui-focused': {
      color: primaryColors.primary,
      border: `1px solid ${primaryColors.primary700} !important`,
      borderRadius: '8px',
    },
    '.MuiInputBase-root.Mui-focused:before': {
      borderBottom: `1px solid ${primaryColors.primary700} !important`,
    },
    '.MuiInputBase-root.Mui-focused:after': {
      borderBottom: `1px solid ${primaryColors.primary700}`,
    },
  };
});

const CustomInputLabel = styled(InputLabel)(({ visibility }) => ({
  position: 'relative',
  transform: 'none',
  height: '23px',
  visibility: visibility ? 'visible' : 'hidden',
}));

const PDatePicker = ({
  label = '',
  width = '100%',
  showLabel = true,
  value,
  onChange,
  disabled = false,
  sx,
  showHelperContainer = true,
  errorMessage = '',
  error = false,
  placeholder = '',
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}>
      <CustomFormControl width={width}>
        {showLabel && (
          <CustomInputLabel visibility={label}>
            <Typography
              variant="labelLarge"
              color={primaryColors.primary800}>
              {label}
            </Typography>
          </CustomInputLabel>
        )}
        <CustomDatePicker
          error={error}
          disabled={disabled}
          slots={{
            openPickerIcon: DateRangeOutlinedIcon,
          }}
          slotProps={{
            inputAdornment: {
              position: 'start',
            },
            textField: { placeholder: placeholder },
          }}
          renderInput={(params) => <TextField {...params} />}
          value={value}
          onChange={onChange}
          sx={sx}
        />
        {showHelperContainer && (
          <HelperContainer visibility={errorMessage}>{errorMessage && error && <HelperText error>{errorMessage}</HelperText>}</HelperContainer>
        )}
      </CustomFormControl>
    </LocalizationProvider>
  );
};

export default PDatePicker;
