export const addToCartEnums = Object.freeze({
  addSeat: 1, // değiştir
  addProduct: 2, // ürün ekle
  renewable: 3, // yenile
});

export const addSeatTypeEnum = Object.freeze({
  increase: 'A',
  decrease: 'C',
});
