import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, USER_API } from 'constants/Keys';

const USER = '/user';

export const getAllUsers = ({ sapCustomerId, page = 1, orderBy = 'name', pageSize = '10', order = 0 }) => {
  return httpRequest.get(
    `${USER_API}${API}${USER}?SapCustomerId=${sapCustomerId}&Order=${order}&OrderBy=${orderBy}&Page=${page}&PageSize=${pageSize}`,
  );
};

export const deleteUser = (userId) => httpRequest.delete(`${USER_API}${API}${USER}/${userId}`);
