import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, styled } from '@mui/material';
import { primaryColors } from 'config/theme/Colors';
const NumberInputContainer = styled(Box)(({ sx }) => ({
  border: `1px solid ${primaryColors.primary400}`,
  borderRadius: '8px',
  padding: '4px',
  display: 'flex',
  backgroundColor: '#ffffff',
  height: '44px',
  justifyContent: 'space-between',
  ...sx,
}));

const NumberInput = styled('input')(({ width = '40px' }) => ({
  border: 0,
  outline: 'none',
  width: width,
  padding: 0,
  margin: 0,
  background: 'transparent',
  textAlign: 'center',
}));

const IconContainer = styled(Box)(() => ({
  width: '36px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: primaryColors.primary100,
  borderRadius: '4px',
  padding: '8px',
}));

const IconButtonContainer = styled('button')(({ disabled }) => ({
  margin: 0,
  padding: 0,
  height: 'auto',
  width: 'auto',
  outline: 0,
  border: 0,
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: disabled ? 'normal' : 'pointer',
}));

const InlineEditNumberInput = ({ value, onChange, showIcon = false, onIconClick, disabled = false, width = '40px', sx }) => {
  const handleOnChange = (event) => {
    event.stopPropagation();
    // if (!event.target.value || event.target.value <= 0) {
    //   event.target.value = '';
    //   onChange(parseInt());
    // } else {
    // }
    onChange(event.target.value);
  };

  const handleOnKeyDown = (event) => {
    const k = event.which;

    if ((k < 48 || k > 57) && (k < 96 || k > 105) && k !== 8) {
      event.preventDefault();
      return false;
    }
  };

  const handlePlus = (event) => {
    event.stopPropagation();
    onChange(value + 1);
  };

  const handleMinus = (event) => {
    event.stopPropagation();
    if (value > 1) {
      onChange(value - 1);
    }
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <NumberInputContainer sx={sx}>
      <IconButtonContainer
        disabled={disabled}
        onClick={handleMinus}>
        <RemoveIcon />
      </IconButtonContainer>
      <NumberInput
        width={width}
        onClick={stopPropagation}
        disabled={disabled}
        onKeyDown={handleOnKeyDown}
        value={value}
        onChange={handleOnChange}
      />
      <IconButtonContainer
        disabled={disabled}
        onClick={handlePlus}>
        <AddIcon />
      </IconButtonContainer>
      {showIcon && (
        <IconButtonContainer
          onClick={onIconClick}
          disabled={disabled}>
          <IconContainer>
            <AddShoppingCartOutlinedIcon />
          </IconContainer>
        </IconButtonContainer>
      )}
    </NumberInputContainer>
  );
};

export default InlineEditNumberInput;
