import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography } from '@mui/material';
import IconResolver from 'components/iconResolver/IconResolver';
import PTooltip from 'components/tooltip/PTooltip';
import { greenColors, primaryColors } from 'config/theme/Colors';
import dayjs from 'dayjs';
import { getSubDetails } from 'pages/pay/api/PayApi';
import PaySubscriptionTable from 'pages/pay/components/PaySubscriptionTable';
import { renewalTypeEnum } from 'pages/pay/constants/RenewalTypeEnum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const SubscriptionTable = ({ url, setTabValue, setProductInfo, setAdditionalInfoForTableMenu, detailTabNumber, tableRef, setIsLoading }) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [
      {
        field: 'subscription',
        headerName: t('pay:subscription'),
        minWidth: 448,
        flex: 1,
        renderCell: (rows) => {
          return (
            <div className="d-flex flex-column">
              <PTooltip
                placement="bottom-end"
                title={`${rows.endUserName}`}>
                <div className="ellipsis">{`${rows.endUserName}`}</div>
              </PTooltip>
              <Typography
                variant="bodyMedium"
                color={primaryColors.primary700}>
                {`#${rows.subscriptionNumber}`}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'contractNumber',
        headerName: t('pay:contractNo'),
        minWidth: 220,
        flex: 1,
      },
      {
        field: 'renewalDate',
        headerName: t('pay:renewalDate'),
        minWidth: 120,
        flex: 1,
        sortable: true,
        renderCell: (rows) => {
          return dayjs(rows.renewalDate, 'M.D.YYYY HH:mm:ss').format('DD.MM.YYYY');
        },
      },
      {
        field: 'count',
        headerName: t('pay:piece'),
        minWidth: 96,
        flex: 1,
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'refreshType',
        headerName: t('pay:renewalType'),
        minWidth: 108,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (rows) => {
          if (rows.renewalType === renewalTypeEnum.MANUAL) {
            return (
              <PTooltip
                placement="bottom-end"
                title={t('pay:manualRenewal')}>
                <RefreshIcon />
              </PTooltip>
            );
          }

          if (rows.renewalType === renewalTypeEnum.AUTOMATIC) {
            return (
              <PTooltip
                placement="bottom-end"
                title={t('pay:automaticRenewal')}>
                <RefreshIcon sx={{ color: greenColors.green600 }} />
              </PTooltip>
            );
          }

          return <></>;
        },
      },
      {
        field: 'actions',
        headerName: t('pay:detail'),
        width: 72,
        sortable: false,
        align: 'center',
        renderCell: (row) => {
          return (
            <PTooltip
              placement="bottom-start"
              title={t('pay:subscriptionDetailWithoutI')}>
              <IconResolver
                onClick={() => {
                  setIsLoading(true);
                  getSubDetails(row.subscriptionNumber)
                    .then((res) => {
                      setProductInfo({ subscriptionNumber: row.subscriptionNumber, renewalDate: row.renewalDate });
                      setAdditionalInfoForTableMenu(res.data.data);
                      setTabValue(detailTabNumber);
                    })
                    .catch((err) => {
                      toast.error(err.response.data.Message);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
                iconName="ManageSearchOutlined"
                className="cursor-pointer"
              />
            </PTooltip>
          );
        },
      },
    ];
  }, [t]);

  return (
    <PaySubscriptionTable
      infoLabel={t('pay:piece')}
      headerTitle={t('pay:subscriptionList')}
      columns={columns}
      url={url}
      generateId
      getRowClassName={(rowData) => rowData.ended && 'table-error-color'}
      ref={tableRef}
    />
  );
};
export default SubscriptionTable;
