/* eslint-disable quotes */
import { createTheme } from '@mui/material/styles';
import { primaryColors } from './Colors';

export const customTheme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 376, //iphpone mini
      md: 746, //ipad mini
      lg: 1281, //macbookair
      xl: 1442, //web
    },
  },

  typography: {
    bodySmall: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.4000000059604645px',
    },
    bodyMedium: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    bodyLarge: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    labelSmall: {
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    labelMedium: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    labelLarge: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.10000000149011612px',
    },
    titleSmall: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.10000000149011612px',
    },
    titleMedium: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: ' 0.15000000596046448px',
    },
    titleLarge: {
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '28px',
    },
    headlineSmall: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    headlineMedium: {
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: '36px',
    },
    headlineLarge: {
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: '40px',
    },
    displaySmall: {
      fontSize: '36px',
      fontWeight: 400,
      lineHeight: '44px',
    },
    displayMedium: {
      fontSize: '45px',
      fontWeight: 400,
      lineHeight: '52px',
    },
    displayLarge: {
      fontSize: '57px',
      fontWeight: 400,
      lineHeight: '64px',
      letterSpacing: '-0.25px',
    },
    allVariants: {
      fontFamily: "'Inter', sans-serif",
      textTransform: 'none',
    },
  },

  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "'Inter', sans-serif",
          backgroundColor: primaryColors.primary50,
        },
      },
    },
  },
});
