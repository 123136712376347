import { Box } from '@mui/material';
import PSearchableTextField from 'components/textField/PSearchableTextField';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfigMasterPentaShipmentOption = ({ additionalInfo, handleChange, error, errorMessage, deliveryOptions = [] }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showAllMenu, setShowAllMenu] = useState(true);

  useEffect(() => {
    if (additionalInfo) {
      const found = deliveryOptions.find((item) => item.kunnr === additionalInfo);
      if (found) {
        setInputValue(`${found.namE1} ${found.street} ${found.stR_SUPPL3} ${found.moB_NUMBER}`);
      }
    }
  }, [deliveryOptions]);

  const onSelectChange = (event) => {
    setSearchInputValue(event.target.value);
    setShowAllMenu(false);
  };

  const clearSearchInputValue = () => {
    setSearchInputValue('');
  };

  const menuItems = useMemo(() => {
    const formatContent = (deliveryOption) =>
      `${deliveryOption.namE1} ${deliveryOption.street} ${deliveryOption.stR_SUPPL3} ${deliveryOption.moB_NUMBER}`;

    const handleItemClick = (deliveryOption) => {
      const customObject = { target: { value: deliveryOption.kunnr } };
      handleChange(customObject);
      setInputValue(formatContent(deliveryOption));
      setShowAllMenu(true);
    };

    return deliveryOptions
      .filter(
        (deliveryOption) =>
          showAllMenu ||
          Object.values({
            namE1: deliveryOption.namE1,
            street: deliveryOption.street,
            stR_SUPPL3: deliveryOption.stR_SUPPL3,
            moB_NUMBER: deliveryOption.moB_NUMBER,
          })
            .join(' ')
            .toLocaleLowerCase('tr')
            .includes(searchInputValue.toLocaleLowerCase('tr')),
      )
      .map((deliveryOption) => ({
        id: deliveryOption.kunnr,
        content: <div>{formatContent(deliveryOption)}</div>,
        onClick: () => {
          handleItemClick(deliveryOption);
        },
      }));
  }, [deliveryOptions, searchInputValue, showAllMenu, handleChange]);

  return (
    <Box className="d-flex align-items-center gap-20">
      <PSearchableTextField
        containerWidth="100%"
        placeholder={t('configMaster:selectDeliveryAddress')}
        name="penta"
        menuItems={menuItems}
        label={t('configMaster:deliveryAddress')}
        value={inputValue}
        searchInputValue={searchInputValue}
        clearSearchInputValue={clearSearchInputValue}
        onChange={onSelectChange}
        error={error}
        errorMessage={errorMessage}
      />
    </Box>
  );
};

export default ConfigMasterPentaShipmentOption;
