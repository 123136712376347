import { Box, styled, Typography } from '@mui/material';
import PAccordion from 'components/accordion/PAccordion';
import PAccordionDetail from 'components/accordion/PAccordionDetail';
import PAccordionSummary from 'components/accordion/PAccordionSummary';
import { primaryColors } from 'config/theme/Colors';
import { Image, ImageItem, Item } from 'pages/pentaStorage/storageIndex/StorageIndex';
import { useTranslation } from 'react-i18next';
import image1 from './media/image1.svg';
import image2 from './media/image2.svg';

const AccordionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 24px',
  marginBottom: '24px',
}));

const Header = styled(Box)(() => ({
  padding: '0 8px',
}));

const CustomUl = styled('ul')(() => ({
  paddingLeft: '24px',
}));

const AccordionPart = () => {
  const { t } = useTranslation();

  return (
    <AccordionContainer>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('configMaster:firstTitle')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Item>
            <Typography
              variant="bodyMedium"
              color={primaryColors.primary800}>
              {t('configMaster:firstTitleFirstParagraph')}
            </Typography>
          </Item>
        </PAccordionDetail>
      </PAccordion>
      <PAccordion>
        <PAccordionSummary>
          <Header>
            <Typography
              variant="titleMedium"
              color={primaryColors.primary}>
              {t('configMaster:secondTitle')}
            </Typography>
          </Header>
        </PAccordionSummary>
        <PAccordionDetail>
          <Box className="d-flex flex-column gap-16">
            <Item>
              <CustomUl>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleFirstDot')}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleSecondDot')}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleThirdDot')}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleFourthDot')}
                  </Typography>
                </li>
              </CustomUl>
            </Item>
            <ImageItem>
              <Image
                width={'100%'}
                src={image1}
              />
            </ImageItem>
            <Item>
              <CustomUl>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleFifthDot')}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleSixthDot')}
                  </Typography>
                </li>
              </CustomUl>
            </Item>
            <ImageItem>
              <Image
                width={'100%'}
                src={image2}
              />
            </ImageItem>
            <Item>
              <CustomUl>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleSeventhDot')}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="bodyMedium"
                    color={primaryColors.primary800}>
                    {t('configMaster:secondTitleEighthDot')}
                  </Typography>
                </li>
              </CustomUl>
            </Item>
          </Box>
        </PAccordionDetail>
      </PAccordion>
    </AccordionContainer>
  );
};

export default AccordionPart;
