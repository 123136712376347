import { httpRequest } from 'config/api/AxiosInterceptors';
import { API, COMMON_API, USER_API } from 'constants/Keys';

const PERMISSIONS = '/permissions';

const USER = '/user';

export const getAllPermissions = () => httpRequest.get(`${COMMON_API}${API}${PERMISSIONS}`);

export const addUser = ({ name, surname, email, mobile, phone, expireDate, isActive, profit, permissionTypes, restrictedIps, sapCustomerId }) =>
  httpRequest.post(`${USER_API}${API}${USER}`, {
    name,
    surname,
    email,
    mobile,
    phone,
    expireDate,
    isActive,
    profit,
    permissionTypes,
    restrictedIps,
    isAdmin: false,
    sapCustomerId,
  });

export const updateUser = ({
  id,
  name,
  surname,
  email,
  mobile,
  phone,
  expireDate,
  isActive,
  profit,
  permissionTypes,
  restrictedIps,
  sapCustomerId,
}) =>
  httpRequest.put(`${USER_API}${API}${USER}`, {
    id,
    name,
    surname,
    email,
    mobile,
    phone,
    expireDate,
    isActive,
    profit,
    permissionTypes,
    restrictedIps,
    isAdmin: false,
    sapCustomerId,
  });

export const getUser = (userId) => httpRequest.get(`${USER_API}${API}${USER}/${userId}`);
