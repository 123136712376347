import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, Typography, styled } from '@mui/material';
import { greenColors, primaryColors } from 'config/theme/Colors';
const SuccessContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${greenColors.green300}`,
  backgroundColor: greenColors.green50,
  padding: '10px 12px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  minHeight: '40px',
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    flexDirection: 'column',
  },
}));

const SuccessAlert = ({ label }) => {
  return (
    <SuccessContainer>
      <CheckCircleOutlineOutlinedIcon sx={{ color: greenColors.green600 }} />
      <Typography
        variant="bodyMedium"
        color={primaryColors.primary}>
        {label}
      </Typography>
    </SuccessContainer>
  );
};
export default SuccessAlert;
