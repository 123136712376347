import { Box, styled } from '@mui/material';
import LoadingSpinner from 'components/loadingSpinner/LoadingSpinner';
import { primaryColors } from 'config/theme/Colors';
import { approveTestRequest, cancelRequest } from 'pages/pentaIntegration/api/IntegrationApi';
import ActiveRequestFound from 'pages/pentaIntegration/components/activeRequest/ActiveRequestFound';
import ActiveRequestNotFound from 'pages/pentaIntegration/components/activeRequest/ActiveRequestNotFound';
import EntegrationLiveRequestModal from 'pages/pentaIntegration/components/modals/EntegrationLiveRequestModal';
import EntegrationTestRequestModal from 'pages/pentaIntegration/components/modals/EntegrationTestRequestModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ActiveRequestContainer = styled(Box)(() => ({
  width: '100%',
  minHeight: '160px',
  border: `1px solid ${primaryColors.primary200}`,
  borderRadius: '12px',
}));

const ActiveRequest = ({ activeRequest, getActiveRequest, serviceId, refreshFunction }) => {
  const { t } = useTranslation();
  const [showTestModal, setShowTestModal] = useState(false);
  const [showLiveModal, setShowLiveModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const toogleShowTestModal = () => {
    setShowTestModal((prevState) => !prevState);
  };

  const toogleShowLiveModal = () => {
    setShowLiveModal((prevState) => !prevState);
  };

  const approveTest = () => {
    setIsLoading(true);
    approveTestRequest(activeRequest)
      .then(() => {
        toast.success(t('integration:testCompleted'));
        getActiveRequest();
        refreshFunction();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancel = () => {
    setIsLoading(true);
    cancelRequest(activeRequest)
      .then(() => {
        toast.success(t('integration:requestCanceled'));
        getActiveRequest();
        refreshFunction();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ActiveRequestContainer>
      {isLoading && <LoadingSpinner />}
      {showTestModal && (
        <EntegrationTestRequestModal
          getActiveRequest={getActiveRequest}
          onClose={toogleShowTestModal}
          serviceId={serviceId}
          refreshFunction={refreshFunction}
        />
      )}
      {showLiveModal && (
        <EntegrationLiveRequestModal
          getActiveRequest={getActiveRequest}
          onClose={toogleShowLiveModal}
          refreshFunction={refreshFunction}
          activeRequest={activeRequest}
        />
      )}
      {!activeRequest && <ActiveRequestNotFound toogleShowTestModal={toogleShowTestModal} />}
      {activeRequest && (
        <ActiveRequestFound
          toogleShowLiveModal={toogleShowLiveModal}
          approveTest={approveTest}
          activeRequest={activeRequest}
          cancel={cancel}
        />
      )}
    </ActiveRequestContainer>
  );
};

export default ActiveRequest;
