import PTextField from './PTextField';

const allowedChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

const PPositiveTextField = ({ onChange, ...rest }) => {
  const handleKeyPress = (event) => {
    if (!allowedChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const onChangeHandler = (event) => {
    if (event.target.value !== '0') {
      onChange(event);
    }
  };

  return (
    <PTextField
      {...rest}
      onChange={onChangeHandler}
      onKeyPress={handleKeyPress}
    />
  );
};

export default PPositiveTextField;
